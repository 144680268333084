import React from "react";
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../config/redux/action';
import { withRouter } from "react-router";

import {Image, Container, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import queryString from 'query-string';

import LoadingCampaign from '../../../containers/organisms/CampaignCard/Loading'
import successDonation from '../../../assets/img/successdonation.png'
import { CampaignCard, Header } from '../../../containers/organisms';


import './style.css';
const texts = {text1:'',text2:'',text3:''}
class ResultDonation extends React.Component {
  state = {
    resultType: {},
    texts:texts,
    mainClass:'',
    
    /** Campaigns */
    campaigns: [],
    isFetchingCampaigns: false,
  }

  apiCampaigns = async () => {
    /** Fetching Campaigns */
    this.setState({ isFetchingCampaigns: true })

    const { API } = this.props

    /** Rebuild Filter for API Params */
    const filter = '';
    const params = '?page=1&per_page=5' + filter
    const config = {
        method: 'get',
        url: '/campaign' + params,
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const res = await API(config, false).catch(err=>err);
    if (res)
        this.setState({ campaigns: res.data.data })

    this.setState({ isFetchingCampaigns: false })
  }
  
  componentDidMount (){
    let url = this.props.location.pathname;
    let params = queryString.parse(this.props.location.search)
    let pathArr = url.split('/');
    let resultType = pathArr[pathArr.length - 1];
   
    let custText = texts
    let mainClass = '';
    if(resultType === 'success'){
      custText = {
        text1: "Terimakasih Dermawan", 
        text2: "Donasi Anda telah kami terima",
        text3: "Semoga Kebaikan anda dibalas lebih oleh Allah SWT",
      };
    }else if(resultType === 'error'){
      custText = {
        text1: "Maaf terjadi kesalahan", 
        text2: "Donasi anda tidak bisa diproses",
        text3: "Silahkan coba lagi beberapa saat kemudian",
      };
      mainClass = 'splash-danger';
    
    }else if(resultType === 'pending'){
      custText = {
        text1: "Terimakasih Dermawan", 
        text2: "Data anda berhasil disimpan",
        text3: "Silahkan lakukan pembayaran sesuai metode yang sudah dipilih",
      };
      mainClass = 'splash-warning';
    }
    this.setState({resultType:resultType, code:params.code, texts: custText, mainClass: mainClass }); 
    this.apiCampaigns();
  }

  render() {
    const state = this.state;
    const params = queryString.parse(this.props.location.search);
    return(
      <>
      <Header splash={true}/>
      <main>  
        <Container className="splash-screen no-padding">
          <div className="section text-center pb-0">
            {/* <div className="sample-img"></div> */}
            <Image className="mt-4 mb-5" src={successDonation} />
          </div>
          <div className="section txt-body-m text-center mb-10 result-donation">
              <div className="txt-subtitle-m fw-6 ct-title mb-2">Terima Kasih, Tetangga Baik</div>
              <div className="txt-body-m mb-4">Donasi Anda telah kami terima. Semoga kebaikan Anda dibalas oleh Allah SWT.</div>
              <Link to={"/bukti-donasi/"+params.code+"?prevPage=campaign"} className="btn btn-primary btn-block mb-4">Bukti Donasi</Link>
              <Link to="/">Kembali ke Beranda</Link>
          </div>
          <div className="content-space"></div>
          <div className="section">
            <div className="txt-title-m fw-6 mt-2">Bantu Tetangga Lainnya</div>

            <div className="campaign-home-list">
              {!state.isFetchingCampaigns && 
                  state.campaigns.map(
                      campaign => <CampaignCard campaign={campaign} key={campaign.slug} />
                  )
              }
              {!state.isFetchingCampaigns && 
                  <div className="btn-home-showall">
                      <Link to={"/campaign" + this.props.location.search}>
                          <Button className="btn-show-more" variant="primary">
                              Lihat Semua 
                              <Image className="arrow-right" src='/assets/img/ic-arrow-right.svg' />
                          </Button>
                      </Link>
                  </div>
              }
              {state.isFetchingCampaigns && 
                  <>
                      <LoadingCampaign/>
                      <LoadingCampaign/>
                      <LoadingCampaign/>
                  </>
              }
            </div>
          </div>
        </Container>
      </main>
      </>
    )
  }
}


const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
  clearDispatch: () => dispatch(clearDispatch()),
  API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResultDonation));
