import React, {useEffect} from 'react';
import  { Redirect } from 'react-router-dom'
import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";
import StatePostDonasi from ".././../../pages/Donasi/PostDonasi/postData";
import StateGalangDana from ".././../../pages/Campaign/GalangDana/postData";
import StateVerifLembaga from ".././../../pages/Campaigner/VerifikasiLembaga/postData";
import StateVerifPersonal from ".././../../pages/Campaigner/VerifikasiPersonal/postData";

const Logout =  () => {
    const {action} = useStateMachine(updateAction);
    useEffect(() => {
        action(StatePostDonasi);
        action(StateGalangDana);
        action(StateVerifLembaga);
        action(StateVerifPersonal);
        localStorage.removeItem("userData");
        localStorage.removeItem("detailCampaign");
        // firebase.auth().signOut().then(function() {
        //     // Sign-out successful.
        // }).catch(function(error) {
        //     // An error happened.
        // });
        
    });
    return (
        <Redirect to='/login'/>
    )
}


export default Logout
