import React from 'react'
import { Modal, Spinner } from 'react-bootstrap';
import './style.css';

export default function ConfirmAlert ({ show, onHide, title, body, lblCancel, lblSubmit, handleSubmit, onProcessing }) {
    return(
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            animation={false}
            className="confirm-alert"
            centered>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    <div className="txt-title fw-6">{title}</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="txt-body">{body}</div>
            </Modal.Body>
            <Modal.Footer>
                <div className="content-grid grid-2">
                    <div>
                        <button size="sm" className="btn ct-body" onClick={onHide}>
                            {lblCancel}
                        </button>
                    </div>
                    <div>
                        <button size="sm" className="btn ct-red" onClick={handleSubmit}>
                            {!onProcessing && <>{lblSubmit}</>}
                            {onProcessing && <Spinner animation="border" role="status" variant="secondary" />}
                        </button>
                    </div>
                </div>
                
            </Modal.Footer>
        </Modal>
    )
}
