import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import {Container, Image} from 'react-bootstrap';
import { withRouter } from "react-router";

import { useForm } from "react-hook-form";
import { Header } from '../../../organisms';
import icAsk from '../../../../assets/svg/ic-btnhelp.svg';
import icSilang from '../../../../assets/svg/ic-silang.svg';
import icCheckGreen from '../../../../assets/svg/ic-check-green.svg';
import './style.css';

const Pages = (props) => {
    const { handleSubmit } = useForm();
    const { push } = useHistory();
    const [isAgree, setIsAgree] = useState(false);

    const handleChange = (e) =>{
      let field = e.target.name;
      if(field === 'is_agree'){
        setIsAgree(e.target.checked)
      }
    }

    const onSubmit = data => {
      push("/preverification/type");
    };

    return (
      <>
        <Header title="Persetujuan" prevPage="/galang-dana" cols={3} linkPost3={{pathname: "https://wa.me/6281120211194"}} rel="noopener noreferrer" target="_blank" position3={icAsk} headerclass="header-bg-white"/>
        <main className="onboarding">  
          <Container>
            <div className="txt-title">Halo, Tetangga Baik</div>
            <div className="txt-body mb-3">Sebelum mulai galang dana perhatikan dulu ya</div>
            <div className="info-green"><Image src={icCheckGreen} className="mr-3"/> Pastikan informasi program yang digalang lengkap dan akurat.</div>
            <div className="info-green"><Image src={icCheckGreen} className="mr-3"/> Data diri penerima manfaat lengkap dan dapat dipertanggungjawabkan.</div>
            <div className="info-green"><Image src={icCheckGreen} className="mr-3"/> Pastikan penerima manfaat sudah memberi izin untuk dipublikasikan dalam penggalangan dana.</div>
            <div className="info-green"><Image src={icCheckGreen} className="mr-3"/> Tujuan penggalangan dana sesuai dan realistis dengan target donasi yang yang dituliskan.</div>
            <div className="info-red"><Image src={icSilang} className="mr-3"/> Tidak menggalang dana untuk hal yang fiktif dan tidak realistis.</div>
            <div className="info-red"><Image src={icSilang} className="mr-3"/> Tidak menggalang dana untuk membayar hutang pribadi Anda.</div>
            <div className="info-red"><Image src={icSilang} className="mr-3"/> Tidak menggalang dana untuk mendanai aksi terorisme dan radikalisme.</div>

            <form onSubmit={handleSubmit(onSubmit)} className="form-hook mt-4">
              <div className="form-group ml-2">
                <input 
                  className="styled-checkbox" 
                  id="styled-checkbox-1" 
                  type="checkbox" 
                  name="is_agree"
                  onChange={handleChange}
                />
                <label htmlFor="styled-checkbox-1" className="label txt-body-m">
                  Saya mengerti dan menyetujui segala peraturan penggalangan dana
                </label>
              </div>


              <button type="submit" className={"btn btn-block btn-next mt-0 "+(!isAgree ? "btn-disabled" : "btn-primary")} disabled={(!isAgree ? true : false)}>
                <span>Lanjutkan</span>
              </button>
            </form>
          </Container>
        </main>
      </>
    );
};

class OnBoarding extends React.Component {
  constructor(props){
      super(props);
      this.state = {
          auth: {}
      }
  }
  componentDidMount() {
      const {history} = this.props;
      if(localStorage.getItem("userData")){
          const auth = JSON.parse(localStorage.getItem("userData"))
          if(!auth.campaigner){
            history.push('/preverification/onboarding');
          }
          this.setState ({ auth: auth  });
      }else{
          history.push('/login?ref=preverification/onboarding');
      }
  }
  render() {
      return(
        <Pages/>
      )
  }
}

export default withRouter(OnBoarding);
