import React from 'react'
import Moment from 'react-moment';
import { Link } from "react-router-dom";

export default function CampaignAjuanCard({ campaign }) {
    const firstName = campaign.creator.split(' ')[0]
    return (
        <Link to={"/campaign-ajuan/" + campaign.slug} className="campaign-ajuan-card">
            <h6 className="campaign-title">{campaign.title}</h6>
            <div className="campaign-info">
                <div className="text-left">
                    <p>Wilayah Donasi</p>
                    <p className="text-primary">{campaign.area}</p>
                </div>
                <div className="text-right ml-auto">
                    <p><Moment format="DD MMMM YYYY">{campaign.created_at}</Moment></p>
                    <p>
                        Diajukan oleh <span className="text-primary">{firstName}</span>
                    </p>
                </div>
            </div>
        </Link>
    )
}