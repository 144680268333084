import React from 'react'
const Loading =  (props) => {
    return (
        <div className="card-container">
            <div className="cp-card">
                <div>
                    <div className="content-grid grid-2">
                        <div className="gradient text-loading text-loading-60"></div>
                        <div><div className="gradient text-loading text-loading-30 height-10 float-right"></div></div>
                    </div>
                    <hr/>

                    <div className="content-grid grid-2-8">
                        <div className="gradient text-loading height-30 text-loading-90"></div>
                        <div className="gradient text-loading height-30"></div>
                    </div>
                    <div className="progress">
                        <span className="progress-bar" style={{"width":0}}></span>
                    </div>

                    <div className="gradient text-loading text-loading-30 height-5"></div>
                    <div className="gradient text-loading text-loading-40"></div>

                    <div className="content-grid grid-2">
                        <div className="gradient text-loading text-loading-90 height-30"></div>
                        <div className="gradient text-loading text-loading-90 height-30 float-right text-right"></div>
                    </div> 
                    <hr/>
                </div>
            </div>
        </div>
    )
}

export default Loading
