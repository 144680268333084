import React  from "react"
import { useHistory } from "react-router-dom";

const Wizard = (props) => {
    const { push } = useHistory();
    const goToPage = (link) => {
        push(link);
    }
    return (
        <div className="wizard">
            <div className="steps txt-caption-m">
                <div className={props.initialSteps.class1} onClick={()=> goToPage(props.initialSteps.link1)}>
                    <div className="step-number"><span>{props.initialSteps.number1}</span></div>
                    <div className="step-label">{props.initialSteps.label1}</div>
                </div>
                <div className={props.initialSteps.class2} onClick={()=> goToPage(props.initialSteps.link2)}>
                    <div className="step-number"><span>{props.initialSteps.number2}</span></div>
                    <div className="step-label">{props.initialSteps.label2}</div>
                </div>
                <div className={props.initialSteps.class3} onClick={()=> goToPage(props.initialSteps.link3)}>
                    <div className="step-number"><span>{props.initialSteps.number3}</span></div>
                    <div className="step-label">{props.initialSteps.label3}</div>
                </div>
            </div>
        </div>
    )
}
export default Wizard;