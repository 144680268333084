import React, {Fragment} from 'react'
import {Image, Modal, Spinner} from 'react-bootstrap'
import CurrencyFormat from 'react-currency-format';
import icDonaturAnonim from '../../../../assets/svg/ic-donatur-anonim.svg';
import TimeAgo from 'timeago-react';
import * as timeago from 'timeago.js';
import id_ID from 'timeago.js/lib/lang/id_ID';

import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';
import './style.css'

const ListDonatur = ({ donor }) => {
    timeago.register('id_ID', id_ID);
    return (
        <div>
            <div className="content-grid grid-2 grid-top">
                <div>
                    <div className="content-grid content-avatar">
                        <div>
                            <div className="wrapper-img">
                                <Image src={donor.avatar && donor.donor_name !== 'Anonim' ? donor.avatar : icDonaturAnonim}/>
                            </div>
                        </div>
                        <div className="text-left">
                            <div className="txt-body-m">{donor.donor_name === "Anonim" ? "Tetangga Baik" : donor.donor_name}</div>
                            <div className="text-blue">
                                <CurrencyFormat 
                                    value={donor.amount} 
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    prefix={'Rp '} 
                                    displayType={'text'}
                                    className="txt-body-m ct-primary1 fw-6"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="txt-caption-m">
                        <TimeAgo datetime={donor.time} locale='id_ID' />
                    </div>
                </div>
            </div>
            <div className="txt-body-m mt-1">{donor.support ? donor.support : 'Tidak ada komentar'}</div>
        </div>
    )
}

class MyVerticallyCenteredModal extends React.Component {
    state = {
        isFetching: false,
        donors: [],
        currentPage: 1,
        total: -1
    }
    async apiLoadDonatur () {
        const { campaignSlug } = this.props
        const { donors, currentPage } = this.state

        const params = `?page=${currentPage}&perPage=5`
        const config = {
            method: 'get',
            url: '/campaign/'+campaignSlug+'/donations' + params,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);

        this.setState({
            currentPage: currentPage + 1,
            donors: [...donors, ...res.data.data],
            total: res.data.total,
            isFetching: false
        })
    }
    handleScroll = (e) => {
        const isScrolled = e.target.scrollHeight - Math.ceil(e.target.scrollTop + e.target.clientHeight);
        if (isScrolled > 100 || this.state.isFetching)
            return;
        this.setState({isFetching: true})
    }
    componentDidMount() {
        this.setState({ isFetching: true });
    }
    componentDidUpdate(_, prevState) {
        if(this.state.isFetching !== prevState.isFetching) {
            const { isFetching, donors, total } = this.state;
            if (!isFetching || (donors.length === total && total !== -1)) {
                this.setState({isFetching: false});
                return;
            }
            this.apiLoadDonatur();
        }
    }
    render() {
        const { donors, isFetching } = this.state
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                animation={false}
                className="list-donatur"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Daftar Donasi
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body onScroll={this.handleScroll}>
                    {donors.map((donor, i) => (
                        <ListDonatur donor={donor} key={donor.name + '_' + donor.time} />
                    ))}
                    {isFetching && 
                        <div className="list-donatur-loader text-center no-border mt-3">
                            <Spinner animation="border" role="status"/>
                        </div>
                    }
                    {(!isFetching && donors.length === 0) && 
                        <p className="text-center">Jadilah donatur pertama :)</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {/* Code Modal Footer Here */}
                </Modal.Footer>
            </Modal>
        )
    }
}

const ModalDonors =  (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <Fragment>
            <div className="txt-body fw-6 ct-primary1 cursor-pointer" onClick={() => setModalShow(true)}>
                Lihat Semua
            </div>

            <MyVerticallyCenteredModal
                show={modalShow}
                campaignSlug={props.campaignSlug}
                onHide={() => setModalShow(false)}
                {...props}
            />
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})
  
export default connect(mapStateToProps, mapDispatchToProps)(ModalDonors);
