import React, { Fragment } from "react";
import {Container, Nav, Image} from "react-bootstrap";
import {Redirect, Link} from "react-router-dom";
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { Header } from '../../../organisms';

import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import * as QueryString from "query-string";
import moment from 'moment/min/moment-with-locales';
import Moment from 'react-moment';
import {FbPixel} from '../../../../config/Tracker/FbPixel'
import { ExternalLink } from 'react-external-link';

import logo from '../../../../assets/svg/logo-bangga-color.svg';
import bannerOperational from '../../../../assets/img/banner-operational.png';
import { Helmet } from "react-helmet-async";
import {v4 as uuidv4} from 'uuid';

// Sets the moment instance to use.
Moment.globalMoment = moment;
 
// Set the locale for every react-moment instance to French.
Moment.globalLocale = 'id';
 
// Set the output format for every react-moment instance.
Moment.globalFormat = 'D MMMM YYYY, H:m';
 
// Set the output timezone for local for every instance.
Moment.globalLocal = true;


const PageStep3 = (props) => {
  const detailCampaign = JSON.parse(localStorage.getItem("detailCampaign"));
  const linkPrev = "/donasi-beras/tetangga-bantu-tetangga/step2";
  const { state } = useStateMachine(updateAction);
  const copyText = (str) => {
    toast.success(str+' telah di copy', {
      position: "top-center",
      hideProgressBar: true,
      autoClose:2000,
    });
  };

  const currencyFormat = (num) => {
    num = parseInt(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    let pointarr = num.split('.');
    pointarr.splice(-1,1);
    let newnum = pointarr.join('.');
    return 'Rp ' + newnum;
  };

  const lastThreeDigit = (num) => {
    num = parseInt(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    let pointarr = num.split('.');
    return pointarr[pointarr.length-1];
  };
  let myuuid = uuidv4();

  return (
    <Fragment>
      {(!state.postData.stepdon2) && 
        <Redirect to={"/donasi-beras/tetangga-bantu-tetangga/step2"} />
      }
      <Helmet>
        <script>
          {
            `window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              "event":"donationCheckout",
              "purchase": ${state.postData.resultDonation.total_donation === undefined ? state.postData.amount : state.postData.resultDonation.total_donation},
              "uuid": "${myuuid}",
              "campaignName": "${detailCampaign.title}",
              "campaignSlug": "${detailCampaign.slug}",
              "campaignCategory": "${detailCampaign.category_name}"
            })
          `}
        </script>
      </Helmet>
      <main className="donate-tbt checkout-beras no-padding">  
        <div className="top-logo">
          <Image src={logo}/>
            <div className="mb-1 mt-2">Terima kasih atas bantuan Anda!</div>
            <div className="mb-1">Kami akan menghubungi Anda untuk pengambilan beras.</div>
        </div>
        <Container className="container-donate">
          <div className="section txt-body-m ">
            <div className="box-bordered">
              <div>
                <span className="txt-short-desc">{state.postData.type_donation === 'pickup' ? 'Ambil di Rumah' : 'Kirim ke Bantu Tetangga'}</span>
                <div>
                  <span className="txt-subtitle fw-6 ct-title">{state.postData.amount} Kg</span>
                </div>
                {state.postData.type_donation === 'pickup' ?
                  <span className="txt-short-desc">Dijemput pada <span className="txt-time"><Moment format="DD MMM">{state.postData.date}</Moment>, {state.postData.pickup_time_id ===1 ? '08:00 - 10:00 WIB' : state.postData.pickup_time_id === 2 ? '10:00 - 12:00 WIB' : '12:00 - 14:00 WIB'}</span></span> :
                  <span className="txt-short-desc">Dikirim pada <span className="txt-time"><Moment format="DD MMM">{state.postData.date}</Moment>, {state.postData.deliver_time_id ===1 ? '08:00 - 10:00 WIB' : state.postData.deliver_time_id === 2 ? '10:00 - 12:00 WIB' : '12:00 - 14:00 WIB'}</span></span> 
                }
              </div>
            </div>
            <div className="content-grid grid-2 box-bordered mb-10">
              <div>
                <span className="txt-short-desc">{state.postData.type_donation === 'pickup' ? 'Ambil di Rumah' : 'Kirim ke Bantu Tetangga'}</span>
                <p>{state.postData.resultDonation.address}</p>
              </div>
              <div>
                {state.postData.type_donation === 'deliver' &&
                  <CopyToClipboard
                    onCopy={state.postData.copyAddress}
                    text={state.postData.resultDonation.address}>
                    <div className="ct-primary1 cursor-pointer fw-6 bg-copy" onClick={()=> copyText('Address')}>Salin</div>
                  </CopyToClipboard>
                }
              </div>
            </div>
            <div className="content-grid grid-2 mt-2">
              <div>Kode Donasi</div>
              <div className="ct-title">{state.postData.resultDonation.code}</div>
            </div>
            <Image src={bannerOperational} className="img-fluid img-banner"/>
            <div className="mt-2 mb-2">Anda dapat menghubungi kami untuk pengambilan bantuan beras Anda. </div>
            {state.postData.stepdon3 && 
              <div className="float-btn-donate">
                <Link to={{ pathname: "https://wa.me/6281221127728" }} rel="noopener noreferrer" target="_blank" className="btn btn-primary mt-1 mb-3"><span>Hubungi Kami</span></Link>
                <Link to="/" className="btn btn-secondary">
                  Lihat Penggalangan Lainnya
                </Link>
              </div>
            }
          </div>
        </Container>
      </main>
    </Fragment>
  );
};

class Step3 extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        codeDonate:null
      }
  }

  checkDonasi = async (codeDonate) => {
    const { API } = this.props;
    const config = {
      method: 'get',
      url: '/donation/detail/'+codeDonate+'?is_donation_rice=1',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const res = await API(config, false).catch(err=>err);
    if (res) {
      setTimeout(() =>{
        this.checkDonasi(codeDonate);
      },10000 );
    }
  }

  componentDidMount() {
    FbPixel('InitiateCheckout');
    const params = QueryString.parse(this.props.location.search);
    if(params.code){
      this.setState({codeDonate:params.code})
      this.checkDonasi(params.code);
    }
  }
  render() {
      const state = this.state
      return(
          <PageStep3 stateStep3={state}/>
      )
  }
}

const mapStateToProps = (state) => ({
  errorMessage: state.errorMessage,
})

const mapDispatchToProps = (dispatch) => ({
clearDispatch: () => dispatch(clearDispatch()),
API: (path, data) => dispatch(API(path, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Step3);
