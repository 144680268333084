import React from "react";
import Moment from 'react-moment';
import CurrencyFormat from "react-currency-format";
import {Link} from "react-router-dom";

import './style.css'
import { Image } from "react-bootstrap";

export default function DisbursementCard ({ data }) {
    data = data ? data : {}

    data.className = ''
    data.status = data.status ? data.status : '-'
    if (
        data.status === 'Dalam Proses Verifikasi' ||
        data.status === 'Verifikasi Berhasil, Mencairkan Dana' ||
        data.status === 'Dana Telah Diterima'
    ) {
        data.className = 'state-green';
    }

    return (
        <Link to={"/detail-pencairan/"+data.code}>
            <div className="cp-card">
                <div className="d-flex">
                    <div className="mr-auto txt-caption">
                        <Moment format="DD MMMM YYYY">{data.created_at}</Moment>
                    </div>
                    <div className="text-right txt-caption-m">
                        <span className={"card-state " + data.className}>
                            {data.status}
                        </span>
                    </div>
                </div>
                <hr className="mt-0" />
                <div className="d-flex mb-2">
                    <div className="disbursement-image">
                        <Image src={data.image} widtth="64px" height="36px" />
                    </div>
                    <div className="txt-subtitle-s fw-6">{data.campaign}</div>
                </div>
                <div className="txt-caption">Dana yang dicairkan</div>
                <div className="txt-subtitle-s fw-6">
                    <CurrencyFormat 
                        value={data.amount} 
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={'Rp '} 
                        displayType={'text'}
                    />
                </div>
            </div>
        </Link>
    )
}