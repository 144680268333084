import React, {useState} from 'react';
import { API, clearDispatch, reloadUser } from '../../../../config/redux/action';
import { Header, ConfirmUpdate } from '../../../organisms';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Container, Form, Image, Spinner } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import avatar from "../../../../assets/img/avatar/no-img.png";
import './style.css';
import { CropFile } from '../../../../component/molecules';
import { toast } from 'react-toastify'
import IcWarningYellow from '../../../../assets/svg/ic-warning-yellow.svg';
import IcPencilGray from '../../../../assets/svg/ic-pencil-gray.svg';

function EditProfilCampaignerForm({ handleChange, updateProfile, state, errorMessage, isLoading }) {
    const { handleSubmit, register, errors } = useForm()
    const [show, setShow] = useState(false);
    const closeConfirmUpdate = () => setShow(false);
    const showConfirmUpdate = () => setShow(true);
    return (
        <Form onSubmit={handleSubmit(updateProfile)} className="form-hook">
            <Form.Group>
                <Form.Label>
                    {state.auth.campaigner.is_foundation ? 'Nama Lembaga' : 'Nama Lengkap Penggalang'}
                </Form.Label>
                <Form.Control
                    type="text" 
                    name="name"
                    placeholder="Masukkan Nama Lembaga" 
                    ref={register({ required: "Nama Lembaga wajib diisi" })}
                    onChange={(e) => handleChange(e)} 
                    defaultValue={state.formEdit.name}
                />
                <ErrorMessage errors={errors} name="name" as={<p className="error-input text-left"></p>} />
                {errorMessage.name && <p className="error-input">{errorMessage.name}</p>}
            </Form.Group>

            <div className="txt-caption mb-1">{state.auth.campaigner.is_foundation ? 'Email Lembaga' : 'Email Penggalang'}</div>
            <div className="content-grid grid-2 txt-body">
                <div>{state.formEdit.email}</div>
                <div><Link to="/profile-campaigner/update-email"><Image src={IcPencilGray} /></Link></div>
            </div>

            <div className="txt-caption mt-20">{state.auth.campaigner.is_foundation ? 'Nomor Ponsel Lembaga' : 'Nomor Ponsel Penggalang'}</div>
            <div className="content-grid grid-2 txt-body mb-16">
                <div>{state.formEdit.phone}</div>
                <div><Link to="/profile-campaigner/update-phone"><Image src={IcPencilGray} /></Link></div>
            </div>
            
            {state.auth.campaigner.is_foundation && 
            <>
                <Form.Group>
                    <Form.Label>Deskripsi Lembaga</Form.Label>
                    <Form.Control
                        type="text" 
                        name="bio"
                        as="textarea"
                        rows="5"
                        placeholder="Masukkan Deskripsi Lembaga" 
                        ref={register({ required: "Deskripsi Lembaga wajib diisi" })}
                        onChange={(e) => handleChange(e)} 
                        defaultValue={state.formEdit.bio}
                    />
                    <ErrorMessage errors={errors} name="bio" as={<p className="error-input text-left"></p>} />
                    {errorMessage.bio && <p className="error-input">{errorMessage.bio}</p>}
                </Form.Group>
                <Form.Group>
                    <Form.Label>Social Media Lembaga</Form.Label>
                    <Form.Control 
                        className="w-100" 
                        as="select" 
                        name="social_media"
                        ref={register({ required: "Social Media Lembaga wajib diisi" })}
                        onChange={(e) => handleChange(e)} 
                        value={state.formEdit.social_media} 
                        custom >
                        <option value="instagram">Instagram</option>
                        <option value="twitter">Twitter</option>
                        <option value="facebook">Facebook</option>
                    </Form.Control>
                    <ErrorMessage errors={errors} name="social_media" as={<p className="error-input text-left"></p>} />
                    {errorMessage.social_media && <p className="error-input">{errorMessage.social_media}</p>}
                </Form.Group>
                <Form.Group>
                    <Form.Label>URL</Form.Label>
                    <Form.Control
                        type="text" 
                        name="url"
                        placeholder="Masukkan URL" 
                        ref={register({ required: "URL wajib diisi" })}
                        onChange={(e) => handleChange(e)} 
                        defaultValue={state.formEdit.url}
                    />
                    <ErrorMessage errors={errors} name="url" as={<p className="error-input text-left"></p>} />
                    {errorMessage.url && <p className="error-input">{errorMessage.url}</p>}
                </Form.Group>
            </>
            }
            <Button onClick={showConfirmUpdate} className="btn btn-primary btn-block">
                Simpan Perubahan
            </Button>
            {/* <Button type="submit" variant="primary" block disabled={(isLoading ? true : false)}>
                { !isLoading && 'Simpan Perubahan' }
                { isLoading && <Spinner animation="border" role="status"/> }
            </Button> */}
            <ConfirmUpdate
                onHide={closeConfirmUpdate} 
                show={show}
                action={handleSubmit(updateProfile)}
                title="Ganti Data Penggalang?"
                body="Mengganti Nama, Email, atau Nomor akan membutuhkan verifikasi kembali dan akan menghilangkan status verifikasi untuk beberapa waktu. Apakah Anda yakin?"
                label_cancel="Batalkan"
                label_yes="Ya, Lanjutkan"
                cancelModal={closeConfirmUpdate}
            />
        </Form>
    )
}

class EditProfilCampaigner extends React.Component {
    state = {
        loadProfile: false,
        auth: {
            campaigner: {}
        },
        formEdit: {
            name: '',
            email: '',
            phone: '',
            bio: '',
            social_media: '',
            url: '',
        },
        isSavingUpdate: false,
        isSavingPhoto: false,
        showModal: false
    }
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeBirth = this.handleChangeBirth.bind(this)
        this.handleModal = this.handleModal.bind(this)
        this.updateProfile = this.updateProfile.bind(this)

        this.state.auth = JSON.parse(localStorage.getItem("userData"))
    }
    handleChange(e) {
        let formEdit = this.state.formEdit
        formEdit[e.target.name] = e.target.value
        this.setState({formEdit})

        if(this.props.errorMessage[e.target.name]){
            let fieldChanges = {};
            fieldChanges = this.props.errorMessage;
            fieldChanges[e.target.name] = '';
            this.props.clearDispatch(fieldChanges);
        }
    }
    handleChangeBirth(birth) {
        this.setState({ birth: birth })
    }
    async handleModal(imageCropped) {
        this.setState({showModal: !this.state.showModal})
        if(imageCropped && imageCropped.filePost){
            /** Do Save Here */
            this.setState({ isSavingPhoto: true })

            const access_token = `Bearer ${this.state.auth.access_token}`;
            let formData = new FormData();
            formData.append("image", imageCropped.filePost);

            const confStoreImage = {
                method: 'post',
                url: '/image',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': access_token
                },
                data: formData
            };
            const savePhoto = await this.props.API(confStoreImage, true).catch(err=>err);
            if (savePhoto) {
                this.setState({ auth: {...this.state.auth} })

                const { campaigner } = this.state.auth

                const access_token = `Bearer ${this.state.auth.access_token}`;
                const confEditProfile = {
                    method: 'put',
                    url: '/campaigner',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': access_token
                    },
                    data: {
                        slug: campaigner.slug,
                        address: campaigner.address,
                        coverage_area: campaigner.coverage_area,
                        name: campaigner.name,
                        email: campaigner.email,
                        phone: campaigner.phone,
                        bio: campaigner.bio,
                        social_media: campaigner.social_media,
                        logo: savePhoto.data.file_url,
                    }
                };
        
                const res = await this.props.API(confEditProfile, true).catch(err=>err);
                if (res) {
                    toast.info('Profil anda berhasil diperbaharui', {
                        position: "top-center",
                        hideProgressBar: true,
                        autoClose: 3000,
                    });

                    const auth = this.state.auth
                    auth.campaigner.logo = savePhoto.data.file_url

                    this.setState({ auth })
                    localStorage.setItem('userData', JSON.stringify(auth));
                }

                this.setState({ isSavingPhoto: false })
            }
        }else{
            this.setState({storeImage:null});
        }
    }
    async updateProfile() {
        this.setState({ isSavingUpdate: true })
        const access_token = `Bearer ${this.state.auth.access_token}`;

        const formPost = {
            ...this.state.formEdit,
            address: this.state.auth.campaigner.address,
            coverage_area: this.state.auth.campaigner.coverage_area,
            slug: this.state.auth.campaigner.slug,
        }

        let social_media = { instagram: '', facebook: '', twitter: '' }
        if(this.state.auth.campaigner.is_foundation) {
            social_media[this.state.social_media] = this.state.formEdit.url
            formPost.social_media = JSON.stringify(social_media)
        }
        
        const confEditProfile = {
            method: 'put',
            url: '/campaigner'+(!this.state.auth.campaigner.is_foundation ? '/personal' : ''),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': access_token
            },
            data: formPost
        };

        const res = await this.props.API(confEditProfile, true).catch(err=>err);
        if (res) {
            toast.info('Profil anda berhasil diperbaharui', {
                position: "top-center",
                hideProgressBar: true,
                autoClose: 3000,
            });
            setTimeout(() =>{
                this.props.history.push('/campaigner/' + this.state.auth.campaigner.slug);
            },1000 );

            const auth = this.state.auth
            auth.campaigner.name = this.state.formEdit.name
            auth.campaigner.email = this.state.formEdit.email
            auth.campaigner.phone = this.state.formEdit.phone
            
            if(auth.campaigner.is_foundation) {
                auth.campaigner.bio = this.state.formEdit.bio
                auth.campaigner.social_media = JSON.stringify(social_media)
            }

            this.setState({ auth })
            localStorage.setItem('userData', JSON.stringify(auth));
        }
        this.setState({ isSavingUpdate: false })
    }
    async componentDidMount() {
        if(this.state.auth){
            if(this.state.auth.campaigner) {
                const { campaigner } = this.state.auth
    
                const formEdit = {
                    name: campaigner.name,
                    email: campaigner.email,
                    phone: campaigner.phone,
                }
                if(campaigner.is_foundation) {
                    let socialMedia = {}
                    const socialMediaData = JSON.parse(campaigner.social_media)
                    for(let index in socialMediaData) {
                        if (socialMediaData[index]) {
                            socialMedia.type = index
                            socialMedia.url = socialMediaData[index]
                            break
                        }
                    }
                    formEdit.social_media = socialMedia.type
                    formEdit.url = socialMedia.url
                    formEdit.bio = campaigner.bio ? campaigner.bio : ''
                }else{
                    formEdit.area_id = campaigner.area_id;
                    formEdit.identity = campaigner.identity;
                    formEdit.identity_face = campaigner.identity_face;
                    formEdit.tax = campaigner.tax;
                }
                this.setState({ formEdit, loadProfile: true })
            } else {
                this.props.history.push('/profile')
            }
        } else {
            this.props.history.push('/login')
        }
    }
    render() {
        const { auth , showModal, confirmUpdate} = this.state
        return (
            <React.Fragment>
                <Header title={"Edit Data "+(auth.campaigner.is_foundation ? 'Lembaga' : 'Penggalang')} prevPage={"/campaigner/" + (auth && auth.campaigner ? auth.campaigner.slug : '/profile')} />
                <main>
                    {this.state.loadProfile &&
                        <div className="personal-data">
                            <div className="section bg-gray">
                                <div className="content-grid grid-2 info-form">
                                    <div><Image className="loader-img" src={IcWarningYellow}/></div>
                                    <div className="text-left ml-3">
                                        Mengganti data {auth.campaigner.is_foundation ? 'lembaga':'penggalang'} selain foto profil akan mencabut status verifikasinya selama beberapa waktu.
                                        <Link to="/faq"> Pelajari lebih lanjut</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="section">
                                <CropFile avatarFileName={'avatar_' + auth.id} title="Atur Foto Profil" label="Pilih Foto" show={showModal} handleModal={this.handleModal}/>
                                <div className="setting-preview-card-profile">
                                    <div>
                                        {!this.state.isSavingPhoto && <Image src={auth.campaigner && auth.campaigner.logo ? auth.campaigner.logo : avatar } width="65"/>}
                                        {this.state.isSavingPhoto && <Spinner animation="border" role="status"/> }
                                    </div>
                                    <div className="text-left">
                                        <div className="txt-subtitle-m fw-6 ct-title">Gambar Profil {(auth.campaigner.is_foundation ? 'Lembaga' : 'Penggalang')}</div>
                                        <div className="txt-caption-m">
                                            Ukuran file maksimum 500KB dengan format .jpg atau .png
                                        </div>
                                        <div className="btn btn-secondary btn-sm ct-primary2 mt-2" onClick={this.handleModal}>Ganti</div>
                                    </div>
                                </div>
                            </div>
                            <div className="content-space"></div>
                            <Container className="">
                                {this.state.auth !== null &&
                                    <EditProfilCampaignerForm 
                                        handleChange={this.handleChange}
                                        handleChangeBirth={this.handleChangeBirth}
                                        errorMessage={this.props.errorMessage}
                                        isLoading={this.props.isLoading}
                                        updateProfile={this.updateProfile}
                                        state={this.state}
                                    />
                                }
                            </Container>
                        </div>
                    }
                </main>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: (fieldChanges) => dispatch(clearDispatch(fieldChanges)),
    API: (path, data) => dispatch(API(path, data)),
    reloadUser: (config, auth) => dispatch(reloadUser(config, auth))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditProfilCampaigner));