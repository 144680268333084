import React from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { useHistory, Redirect } from "react-router-dom";
import {Image} from "react-bootstrap";

import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";

import UploadFile from "./UploadFile";
import RedirectStep from "./RedirectStep";

import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { clearDispatch, API, reloadUser } from '../../../../config/redux/action';
import { Header, Wizard } from '../../../organisms';
import IcWarningYellow from '../../../../assets/svg/ic-warning-new.svg';
import borderProfil from '../../../../assets/svg/border-photo-profile.svg';
import borderPhoto from '../../../../assets/svg/border-photo.svg';
import borderFileAktif from '../../../../assets/svg/border-file-aktif.svg';
import borderFile from '../../../../assets/svg/border-file.svg';

const Step3 = props => {

  const linkPrev = "/verifikasi-lembaga/step2";
  const { state, action } = useStateMachine(updateAction);
  const { handleSubmit, register, errors } = useForm({
    defaultValues: state.postData
  });
  const { push } = useHistory();
  const onSubmit = async (data) => {
    data['step3'] = true;
    action(data);
    push("/verifikasi-lembaga/step4");
  };

  const hideBtnSubmit = () => {
    let data = {};
    action(data);
  }
  const returnFile = (file) => {
    let data = {};
    data[file.field] = file.file_name;
    action(data);
  }
  
  const stateLogo = state.postData.logo;
  const stateLegal = state.postData.legal;
  const stateTax = state.postData.tax;
  const stateCertificate = state.postData.certificate;
  const stateIdentity = state.postData.identity;

  const icon_logo = (state.postData.logo ? state.postData.logo : false);
  const icon_legal = (state.postData.legal ? borderFileAktif : borderFile);
  const icon_tax = (state.postData.tax ? state.postData.tax : false);
  const icon_certificate = (state.postData.certificate ? borderFileAktif: borderFile);
  const icon_identity = (state.postData.identity ? state.postData.identity : false);
  const initialSteps = {
    class1:"",
    number1:"2",
    label1:"Lokasi",
    link1:"/verifikasi-lembaga/step2",

    class2:"step-active",
    number2:3,
    label2:"Dokumentasi",
    link2:"/verifikasi-lembaga/step3",

    class3:"",
    number3:"4",
    label3:"Ringkasan",
    link3:"/verifikasi-lembaga/step4",
  }
  return (
    <>
      {(!state.postData.step2 && !state.postData.step3) && 
        <Redirect to="/verifikasi-lembaga/step2" />
      }
      <Header title="Verifikasi Lembaga" prevPage={linkPrev} ic_white={true}/>
      <Wizard initialSteps={initialSteps} />
      <form onSubmit={handleSubmit(onSubmit)} className="form-hook">
        <div className="mb-15">
          <UploadFile  
            bingkai={borderProfil}
            title="Logo Lembaga" 
            field="logo" 
            fileName="LOGO_" 
            icon={icon_logo} 
            classFile={stateLogo ? 'file' : ''} 
            classCoverImg={stateLogo ? 'cover-img' : 'cover-img border-radius50'} 
            info={"format .jpg atau .png maksimal 2MB"} 
            returnFile={returnFile} 
            hideBtnSubmit={hideBtnSubmit} />
          <input name="logo" type="hidden" 
            ref={register({ required: "Logo Lembaga belum diupload" })} 
            value={state.postData.logo} />
          <ErrorMessage errors={errors} name="logo" as={<p className="error-input"></p>} />
          {state.postData.errorMessages.logo && 
          <p className="error-input">{state.postData.errorMessages.logo}</p>}
        </div>
        <div className="mb-15">  
          <UploadFile 
            bingkai={borderPhoto}
            title="KTP Ketua Lembaga" 
            field="identity" 
            fileName="KTPPIC_" 
            icon={icon_identity} 
            classFile={stateIdentity ? 'file' : ''} 
            classCoverImg={stateIdentity ? 'cover-img' : 'cover-img border-radius50'} 
            info={"format .jpg atau .png maksimal 2MB"}
            returnFile={returnFile} 
            hideBtnSubmit={hideBtnSubmit} />
          <input name="identity" type="hidden" 
            ref={register({ required: "KTP Ketua Lembaga belum diupload" })} 
            value={state.postData.identity}/>
          <ErrorMessage errors={errors} name="identity" as={<p className="error-input"></p>} />
          {state.postData.errorMessages.identity && 
          <p className="error-input">{state.postData.errorMessages.identity}</p>}
        </div>
        <div className="mb-15">  
          <UploadFile 
            bingkai={borderPhoto}
            title="NPWP Lembaga" 
            field="tax" 
            fileName="NPWP_" 
            icon={icon_tax} 
            classFile={stateTax ? 'file' : ''} 
            classCoverImg={stateTax ? 'cover-img' : 'cover-img border-radius50'}
            info={"format .jpg atau .png maksimal 2MB"} 
            returnFile={returnFile} 
            hideBtnSubmit={hideBtnSubmit} />
          <input name="tax" type="hidden" 
            ref={register({ required: "NPWP Lembaga belum diupload" })} 
            value={state.postData.tax} />
          <ErrorMessage errors={errors} name="tax" as={<p className="error-input"></p>} />
          {state.postData.errorMessages.tax && 
          <p className="error-input">{state.postData.errorMessages.tax}</p>}
        </div>
        <div className="mb-15">  
          <UploadFile 
           fileType="document"
            bingkai={borderFileAktif}
            title="Akte Lembaga" 
            field="certificate" 
            fileName="certificate_" 
            icon={icon_certificate} 
            classFile={stateCertificate ? 'file' : ''} 
            classCoverImg={stateCertificate ? 'cover-img' : 'cover-img border-radius50'}
            info={"format .pdf maksimal 5MB"} 
            returnFile={returnFile} 
            hideBtnSubmit={hideBtnSubmit} />
          <input name="certificate" type="hidden" 
            ref={register({ required: "Akte Lembaga belum diupload" })} 
            value={state.postData.certificate} />
          <ErrorMessage errors={errors} name="certificate" as={<p className="error-input"></p>} />
          {state.postData.errorMessages.certificate && 
          <p className="error-input">{state.postData.errorMessages.certificate}</p>}
        </div>
        <div className="mb-15">
          <UploadFile 
            fileType="document"
            bingkai={borderFileAktif}
            title="SK Kemenkumham" 
            field="legal" 
            fileName="SKK_" 
            icon={icon_legal} 
            classFile={stateLegal ? 'file' : ''} 
            classCoverImg={stateLegal ? 'cover-img' : 'cover-img border-radius50'} 
            info={"format .pdf maksimal 5MB"} 
            returnFile={returnFile} 
            hideBtnSubmit={hideBtnSubmit} />
          <input name="legal" type="hidden" 
            ref={register({ required: "SK Kemenkumham belum diupload" })} 
            value={state.postData.legal} />
          <ErrorMessage errors={errors} name="legal" as={<p className="error-input"></p>} />
          {state.postData.errorMessages.legal && 
          <p className="error-input">{state.postData.errorMessages.legal}</p>}
        </div>

        <div className="txt-body-m">
          <div className="content-grid grid-2 info-form">
            <div><Image className="loader-img notice" src={IcWarningYellow}/></div>
            <div className="text-left ml-2">
              <p>Dokumentasi ini diperlukan untuk memastikan keaslian<br/> informasi penggalang, Data ini kami verifikasi secara<br/> manual.</p>
            </div>
          </div>
        </div>
        
        <button type="submit" className="btn btn-primary btn-block btn-next">Lanjutkan</button>
        
        {state.postData.checkMessage &&
          <RedirectStep errorMessage={props.errorMessage} />
        }
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
  reloadUser: (config, auth) => dispatch(reloadUser(config, auth)),
  clearDispatch: () => dispatch(clearDispatch()),
  API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step3));

