import React from 'react'
const Loading =  () => {
    return (
        <>
           <div className="content-grid grid-2-8 mt-3">
                <div className="gradient text-loading text-loading-90 height-50"></div>
                <div className="float-right clear-both">
                    <div className="gradient text-loading text-loading-50 height-20 "></div>
                    <div className="gradient text-loading text-loading-30"></div>
                </div>
            </div>
            <div className="gradient text-loading height-20 "></div>
            <div className="gradient text-loading height-100 "></div>
            
            <hr/>
            <div className="gradient text-loading height-30"></div>
            <hr/>
            <div className="gradient text-loading height-30"></div>
            <hr/>
            <div className="gradient text-loading height-30"></div>
            <hr/>
            <div className="gradient text-loading height-30"></div> 
        </>
    )
}

export default Loading
