import ReactTagManager from 'react-gtm-module'
import ReactGA from 'react-ga';
export const TagManager = () => 
{
  if(process.env.REACT_APP_GTM_ID){
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID
    }
    ReactTagManager.initialize(tagManagerArgs)
  }
  if(process.env.REACT_APP_GA_ID){
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  return null;
}
