import React,{useState, useEffect} from 'react';
import {Route} from "react-router-dom";
import * as QueryString from "query-string";
import { useHistory } from "react-router-dom";
import updateAction from "../../../../config/FormWizard/updateAction";

//Load Component
import ScrollToTop from '../../../../config/Routes/ScrollToTop';

import { StateMachineProvider, createStore } from "little-state-machine";
import { useStateMachine } from "little-state-machine";
import { withRouter } from "react-router";
import Loading from "./Loading";
import Intro from "./Intro";
import Intro2 from "./Intro2";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Result from "./Result";
import postData from "./postData";

import "../../../../config/FormWizard/style.css";

createStore({
  postData: {
    backPage:0,
    base64: {cover:""},
    loadKota:true,
    loadKecamatan:true,
    intro:false,
    // expired_at_date:(new Date()),
    // publish_at_date:(new Date()),
    stepgal1:false,
    stepgal2:false,
    stepgal3:false,
    stepgal4:false,
    stepgal5:false,
    editCampaign:'',
    editCampaignSlug:'',
    errorMessages:{},
  }
});

const Pages = (props) => {
  const { action } = useStateMachine(updateAction);
  const { location } = useHistory();
  const qs = QueryString.parse(location.search);
  const queryParamsEdit = (qs.edit ? qs.edit : '');
  const queryParams = (queryParamsEdit ? ('?edit='+queryParamsEdit) : '');
  const [loading, setLoading] = useState(true);

	const pathArr = location.pathname.split('/');
  const uri = pathArr[pathArr.length - 1];
  
  const [ locationKeys, setLocationKeys ] = useState([])
  const history = useHistory()
  useEffect(() => {
    return history.listen(location => {
      switch (history.action) {
        case 'PUSH':
          console.log('push');
          setLocationKeys([ location.key ])
          break;
        
        case 'POP':
          console.log('pop');
          if (locationKeys[1] === location.key) {
            setLocationKeys(([ _, ...keys ]) => keys)
          } else {
            setLocationKeys((keys) => [ location.key, ...keys ])
            if(queryParams){
              if(uri === 'step1'){
                action(postData);
                window.history.go(-2);
              }
            }
    
          }
          break;
      
        default:
          console.log({ history: history.action });
          break;
      }
      
    })
  }, [ locationKeys, action, queryParams, uri, history ])
  
  const defaultRedirect = () => {
    if (queryParams !== '' && uri === 'galang-dana') {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
      return loading ? Loading : window.location.reload()
    }

    return Intro
  }

  return (
    <>
      <Route exact path="/galang-dana" component={(defaultRedirect())} />
      <Route exact path="/galang-dana/intro2" component={Intro2} />
      <Route exact path="/galang-dana/step1" component={Step1} />
      <Route exact path="/galang-dana/step2" component={Step2} />
      <Route exact path="/galang-dana/step3" component={Step3} />
      <Route exact path="/galang-dana/step4" component={Step4} />
      <Route exact path="/galang-dana/step5" component={Step5} />
      <Route exact path="/galang-dana/result" component={Result} />
    </>
  );
};

class GalangDana extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            auth: {}
        }
    }
    componentDidMount() {
        const {history} = this.props;
        if(localStorage.getItem("userData")){
            const auth = JSON.parse(localStorage.getItem("userData"))
            if(!auth.campaigner){
              history.push('/preverification');
            }
            this.setState ({ auth: auth  });
        }else{
            history.push('/login?ref=galang-dana');
        }
    }
    render() {
        return(
          <div className={window.location.pathname === '/galang-dana' ? "" : "galang-dana"}>
            <StateMachineProvider store={createStore}>
                <ScrollToTop />
                <Pages/>
            </StateMachineProvider>
          </div>
        )
    }
}

export default withRouter(GalangDana);