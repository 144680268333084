import React, {Fragment, useEffect} from 'react';
import {Routes} from "../../../config";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import './App.css';
import '../../../assets/css/style.css'
import '../../../assets/css/fonts.css'
import '../../../assets/css/controls.css'
import {FbPixel} from '../../../config/Tracker/FbPixel'
import {TagManager} from '../../../config/Tracker/TagManager'
import { hotjar } from 'react-hotjar';

const App  = () => {
  FbPixel('pageView');
  TagManager();


  useEffect(() => {
    var setupTimeUTM = localStorage.getItem('setupTimeUTM');
    var today = new Date();
    var strTotimeToday = today.setDate(today.getDate());
    if (setupTimeUTM == null) {
      if(strTotimeToday >= setupTimeUTM){
        localStorage.removeItem('setupTimeUTM');
        localStorage.removeItem('cacheParamUTM');
      }
    } 
    hotjar.initialize(2311443, 6)
  },[])
  return (
    <Fragment>
      <ToastContainer/>
      <Routes/>
    </Fragment>
  );
}

export default App;
