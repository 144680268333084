import React, {useEffect} from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { useHistory, Link } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";
import {Nav, Spinner, Container, Image} from "react-bootstrap";
import { Header } from '../../../organisms';

import "react-datepicker/dist/react-datepicker.css";

import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';
import postData from './postData';
import {FbPixel} from '../../../../config/Tracker/FbPixel'
import IcWarningInfo from '../../../../assets/svg/ic-info-small.svg';

const IntitateFirst = () => {
  
  const { state, action } = useStateMachine(updateAction);
  const nextLink = "/rekomendasi-tetangga/tetangga-bantu-tetangga";  
  const { push } = useHistory();
  useEffect(()=>{
    FbPixel('AddToCart');
  },[])
  useEffect(() => {
    let data = {};
    if(state.postData.stepdon3 ){
      data = postData;
      data['stepdon3'] = false;
      action(data);
      push(nextLink+"/penerima-manfaat");
    }
  },[action, nextLink, push, state])
  return null;
}


const Step1 = (props ) => {
  useEffect(()=>{
    apiGetArea();
  },[])
  const linkPrev = "/tetangga-bantu-tetangga/category";
  const nextLink = "/rekomendasi-tetangga/tetangga-bantu-tetangga/penerima-manfaat";
  const { state, action } = useStateMachine(updateAction);
  const { handleSubmit, errors, register } = useForm({
    defaultValues: state.postData
  });
  const { push } = useHistory();
  const onSubmit = data => {
    nextPage(data);
  };

  const nextPage = (data) => {
    checkValues(data);
  }

  const checkValues = async (data) => {
    let formData = {
      area_id: state.postData.kecamatan,
      address: state.postData.address,
      just_validate:true
    };
    
    const config = {
        method: 'post',
        url: '/public/recommendation',
        headers: {
            'Content-Type': 'application/json'
        },
        data: formData
    };

    const res = await props.API(config, true, true).catch(err=>err);
    if (res) {
      data['stepdon1'] = true;
      data['resultDonation'] = {snapToken:false};
      push(nextLink);
    }else{
      let data={};
      data['checkMessage'] = true;
      action(data);

    }
  }

  const handleChange = (e) =>{
    let val = e.target.value;
    let field = e.target.name;
    let data = {};
    data[field] = val;
    data['stepdon2'] = false;
    if((field === 'provinsi' && val !== "")) {
      if(state.postData.provinsi !== val){
        data['loadKota'] = false;
        data['dataKota'] = false;
        data['loadKecamatan'] = true;
        data['dataKecamatan'] = false;
        apiGetArea('Kota', val)
      }
    }else if(field === 'provinsi' && val === ""){
      data['loadKota'] = true;
      data['dataKota'] = false;
      data['loadKecamatan'] = true;
      data['dataKecamatan'] = false;
    }

    if((field === 'kota' && val !== "")) {
      if(state.postData.kota !== val){
        data['loadKecamatan'] = false;
        data['dataKecamatan'] = false;
        apiGetArea('Kecamatan', val)
      }
    }
    action(data);
  }

  const apiGetArea = async (type) => {

    let params = '&type=Kecamatan';
    const config = {
        method: 'get',
        url: '/area?is_donation_rice=1'+params,
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const res = await props.API(config, false).catch(err=>err);
    if (res) {
        let data = {};
        data['loadKecamatan'] = true;
        data['dataKecamatan'] = res.data.data;
        action(data);
    }
  }
  return (
    <>
      <Header title="Tetangga Bantu Tetangga" prevPage={linkPrev}/>
      <main className="donate-tbt donate-beras">
        <Container className="container-donate form-penyaluran">
          <div className="txt-title mb-3">Tetangga saya berada di...</div> 
          <div className="txt-body-m">
            <div className="content-grid grid-2 info-form mb-10">
                <Image className="ic-info" src={IcWarningInfo}/>
                <div className="text-left ml-4">
                  Saat ini, penerima bantuan dari tetangga baik hanya yang berada di area Bandung dan sekitarnya.  
                </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="form-hook">
            <IntitateFirst/>
            <div className="form-group">
              <label className="form-label">Kecamatan</label>
              <select 
                className="form-control mb-2" 
                name="kecamatan" onChange={handleChange}
                ref={register({ required: "Kecamatan Wajib dipilih" })}
                >
                <option value="">{!state.postData.loadKecamatan ? 'Sedang mengambil data..' : (!state.postData.dataKecamatan ? '--' : 'Pilih')}</option>
                { state.postData.dataKecamatan &&
                    state.postData.dataKecamatan.map((value, key) =>
                      <option key={key} value={value.id}>{value.name}</option>
                    )
                }
              </select>
              <ErrorMessage errors={errors} name="kecamatan" as={<p className="error-input"></p>} />
            {state.postData.errorMessages.area_id && 
            <p className="error-input">{state.postData.errorMessages.area_id}</p>}
            </div>
              <div className="form-group">
                <label className="form-label">Alamat Penerima Manfaat</label>
                <textarea
                  row="30"
                  name="address"
                  className="form-control"
                  placeholder="Contoh: Jl. Bantu Tetangga No. 1 RT/RW..."
                  ref={register({ required: "Alamat wajib diisi" })}
                  onChange={handleChange}
                />
                <ErrorMessage errors={errors} name="address" as={<p className="error-input"></p>} />
                {state.postData.errorMessages.address && 
                <p className="error-input">{state.postData.errorMessages.address}</p>}
            </div>
            <nav>
                <Nav className="navbar">
                  {!state.postData.stepdon3 && 
                      <button type="submit" className="btn btn-donate btn-block mt-0" disabled={props.isLoading}>
                        { (!props.isLoading) && <span>Selanjutnya</span> }
                        { props.isLoading && <Spinner animation="border" role="status"/> }
                      </button>
                  }
                  {state.postData.stepdon3 && 
                    <Link to="/campaign" className="btn btn-donate btn-block mt-0"><span>Ayo Berdonasi Lagi</span></Link>
                  }
                </Nav>
              </nav>
          </form>
        </Container>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
  clearDispatch: () => dispatch(clearDispatch()),
  API: (path, data, showError) => dispatch(API(path, data, showError))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step1));
