import React from 'react'
import Moment from 'react-moment'
import { Button, Container, Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import { clearDispatch, API } from '../../../../config/redux/action';
import { Header } from "../../../organisms";
import Loading from './Loading'
import icArrowBottom from '../../../../assets/svg/ic-arrow-bottom.svg';
import icArrowTop from '../../../../assets/svg/ic-arrow-top.svg';
import './style.css'

class CampaignApproval extends React.Component {
    state = {
        campaign: [],
        isFetching: true,
        readMore: false,
        auth: null,
        token: null,
        comment: ''
    }
    constructor(props) {
        super(props)
        this.state.auth = JSON.parse(localStorage.getItem("userData"));
        this.state.token = this.state.auth ? `Bearer ${this.state.auth.access_token}`:null;
        this.handleComment = this.handleComment.bind(this)
        this.handleApproval = this.handleApproval.bind(this)
        this.handleRejection = this.handleRejection.bind(this)
    }
    apiCampaign = async () => {
        this.setState({ isFetching: true })

        const config = {
            method: 'get',
            url: '/campaigner/fund-application/'+this.props.match.params.slug,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            this.setState({ campaign: res.data });
        }

        this.setState({ isFetching: false })
    }
    async apiApproval(status) {
        this.setState({ isFetching: true })

        const config = {
            method: 'post',
            url: '/campaigner/fund-application/approval',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            },
            data: {
                fund_application_slug: this.state.campaign.slug,
                notes: this.state.comment,
                status,
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            this.props.history.push('/campaign-ajuan')
            return
        }

        this.setState({ isFetching: false })
    }
    handleComment(e) {
        this.setState({ comment: e.target.value })
    }
    handleRejection() {
        confirmAlert({
            title: 'Pemberitahuan!',
            message: 'Apakah anda yakin MENOLAK pengajuan dana ini?',
            buttons: [
                { label: 'Batal' },
                { label: 'Ya, Lanjutkan', onClick: () => this.apiApproval('rejected')},
            ]
        });
    }
    handleApproval() {
        confirmAlert({
            title: 'Pemberitahuan!',
            message: 'Apakah anda yakin MENERIMA pengajuan dana ini?',
            buttons: [
                { label: 'Batal' },
                { label: 'Ya, Lanjutkan', onClick: () => this.apiApproval('approved')},
            ]
        });
    }
    componentDidMount (){
        const { history, clearDispatch } = this.props;
        const { auth, token } = this.state

        clearDispatch();

        if(token) {
            if(!auth.campaigner){
                history.push('/verifikasi-lembaga');
                return
            }

            this.apiCampaign();
            this.setState({ isFetching: true })
        } else {
            history.push('/login')
        }
    }
    render() {
        const { campaign, isFetching, readMore, comment } = this.state
        let address = []
        if(!isFetching){
            if(campaign.address.detail)
                address.push(campaign.address.detail)
            if(campaign.address.district)
                address.push(campaign.address.district)
            if(campaign.address.city)
                address.push(campaign.address.city)
            if(campaign.address.province)
                address.push(campaign.address.province)
        }
        return(
            <React.Fragment>
                <Header title={!isFetching ? campaign.title : '...'} prevPage="/campaign-ajuan" />
                {isFetching && <Loading />}
                {!isFetching && 
                    <main className="no-padding">
                        <div className="campaign-approval">
                            <div className="campaign-approval-banner">
                                <Image src={campaign.image ? campaign.image : '/assets/img/banner.png'} />
                            </div>
                            <Container className="campaign-approval-title">
                                <h5>{campaign.title}</h5>
                                <div className="d-flex">
                                    <p><Moment format="DD MMMM YYYY">{campaign.created_at}</Moment></p>
                                    <div className="ml-auto">
                                        <Button className="mt-0" variant="outline-primary" size="sm">{campaign.category}</Button>
                                    </div>
                                </div>
                            </Container>
                            <div className="content-space"></div>
                            <Container className="campaign-approval-section">
                                <h6 className="fw-7">Deskripsi Program</h6>

                                <div className={(!readMore) ? 'content-less' : ''} dangerouslySetInnerHTML={{__html: campaign.description}} />
                                
                                <div className="text-center">
                                    {!readMore && 
                                        <button className="read-more mb-0" onClick={() => this.setState({ readMore: true })}>
                                            Lihat Selengkapnya <Image src={icArrowBottom} />
                                        </button>
                                    }
                                    {readMore && 
                                        <button className="read-more mb-0" onClick={() => this.setState({ readMore: false })}>
                                            Lihat lebih sedikit <Image src={icArrowTop} />
                                        </button>
                                    }
                                </div>
                            </Container>
                            <div className="content-space"></div>
                            <Container className="campaign-approval-section">
                                <h6 className="fw-7">Alamat</h6>
                                <p>{address.join(', ')}</p>

                                <h6 className="fw-7">Nomor Handphone PIC</h6>
                                <p>{campaign.pic_phone} - {campaign.creator.name}</p>
                            </Container>
                            <div className="content-space"></div>
                            {campaign.status === 'pending' && 
                                <Container className="campaign-approval-section">
                                    <div className="form-group">
                                        <label>Catatan</label>
                                        <textarea 
                                            className="form-control"
                                            placeholder="Catatan"
                                            onChange={this.handleComment}>{comment}</textarea>
                                    </div>
                                    <div className="d-flex btn-action">
                                        <Button onClick={this.handleRejection} variant="outline-primary" block>Tolak</Button>
                                        <Button onClick={this.handleApproval} variant="primary" block>Terima</Button>
                                    </div>
                                </Container>
                            }
                        </div>
                    </main>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignApproval));