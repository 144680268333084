import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useHistory, Link } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import updateAction from "../../../../config/FormWizard/updateAction";
import { Nav, Image, Spinner, Container } from "react-bootstrap";
import { Header } from "../../../organisms";

import "react-datepicker/dist/react-datepicker.css";

import { clearDispatch, API } from "../../../../config/redux/action";
import avatar from "../../../../assets/img/avatar/no-img.png";
import RedirectStep from "./RedirectStep";
import Script from "react-load-script";
import midtrans from "../../../../config/Midtrans";
import { FbPixel } from "../../../../config/Tracker/FbPixel";
import { removeSymbol, validateEmail, validatePhone } from "../../../../utils";

const Step2 = (props) => {
  const linkPrev = "/rekomendasi-tetangga/tetangga-bantu-tetangga/penerima-manfaat";
  const nextLink = "/rekomendasi-tetangga/tetangga-bantu-tetangga";
  const { state, action } = useStateMachine(updateAction);
  const auth = JSON.parse(localStorage.getItem("userData"));
  const { handleSubmit, errors, register, clearErrors, setError, setValue } =
    useForm({
      defaultValues: state.postData,
    });
  const { push } = useHistory();
  const onSubmit = (data) => {
    if (state.postData.typeof_payment_type !== "transfer") {
      FbPixel("InitiateCheckout");
    }
    submitForm();
  };
  const FbPixell = FbPixel;
  useEffect(() => {
    FbPixell("AddPaymentInfo");
  }, [FbPixell]);

  const submitForm = async () => {
    let formData = {
      area_id: state.postData.kecamatan,
      address: state.postData.address,
      receiver_name: state.postData.receiver_name,
      relation: state.postData.relation,
      reason: state.postData.reason,
      url_image: state.postData.url_image,
      support: state.postData.support,
      donor_name: auth ? auth.name : state.postData.donor_name,
      contact: auth
        ? auth.email
          ? auth.email
          : auth.phone
        : state.postData.contact,
    };
    localStorage.setItem('nama-lengkap-donasi', auth ? auth.name : state.postData.donor_name);
    localStorage.setItem('contact-donasi', auth ? auth.email ? auth.email : auth.phone : state.postData.contact);
    window.sessionStorage.setItem('paymentMethod', state.postData.title_payment_type);
    if (localStorage.getItem("cacheParamUTM")) {
      const paramUTM = JSON.parse(localStorage.getItem("cacheParamUTM"));

      Object.keys(paramUTM).forEach(function (field) {
        formData[field] = paramUTM[field];
      });
    }

    let config = {
      method: "post",
      url: "/public/recommendation",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    if (auth) {
      const token = `Bearer ${auth.access_token}`;
      config["headers"]["Authorization"] = token;
    }

    const res = await props.API(config).catch((err) => err);
    if (res) {
      let data = {};
      data["resultDonation"] = res.data;

      data["stepdon2"] = true;
      data["stepdon3"] = true;
      action(data);

      setTimeout(() => {
        push(nextLink + "/step3");
      }, 1000);
    } else {
      let data = {};
      data["checkMessage"] = true;
      action(data);
    }
  };

  const handleChange = (e) => {
    let val = e.target.value;
    let field = e.target.name;
    let data = {};
    data[field] = val;
    data["stepdon2"] = false;
    if (e.target.type === "checkbox") {
      if (!e.target.checked) {
        data["is_anonim"] = false;
      } else {
        data["is_anonim"] = true;
      }
    }
    if (field === "payment_type") {
      data["typeof_payment_type"] =
        e.target[e.target.selectedIndex].getAttribute("data-type");
    }
    if (field === "contact") {
      if (val.includes("@") || val.includes(".")) {
        const isEmailValid = validateEmail(val);
        if (isEmailValid) {
          clearErrors("contact");
        } else {
          setError("contact", {
            type: "manual",
            message: "Email tidak valid",
            shouldFocus: true,
          });
        }
      } else {
        const valuePhone = removeSymbol(val);
        setValue("contact", valuePhone);
        data.contact = valuePhone;
        const isPhoneValid = validatePhone(valuePhone);
        if (isPhoneValid && val && val !== "12345678") {
          clearErrors("contact");
        } else if (val === "") {
          clearErrors("contact");
        } else {
          setError("contact", {
            type: "manual",
            message: "Nomor whatsapp tidak valid",
            shouldFocus: true,
          });
        }
      }
    }

    console.log({ data });

    action(data);
  };

  const checkDonasi = async (codeDonate) => {
    const config = {
      method: "get",
      url: "/donation/detail/" + codeDonate + '?is_donation_rice=1',
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await props.API(config, false).catch((err) => err);
    if (res) {
      if (res.data.status === "paid") {
        push("/donation/result/success?code=" + codeDonate);
      } else {
        push("/bukti-donasi/" + codeDonate + "?prevPage=campaign");
      }
    }
  };

  const handleScriptLoad = () => {
    const snapToken = state.postData.resultDonation.snapToken;
    const code = state.postData.resultDonation.code;
    let data = {};
    data["resultDonation"] = { snapToken: false };
    data["stepdon2"] = true;
    data["stepdon3"] = true;
    action(data);
    window.snap.pay(snapToken, {
      onSuccess: function (result) {
        checkDonasi(code);
      },
      onPending: function (result) {
        checkDonasi(code);
      },
      onError: function (result) {
        data["stepdon2"] = false;
        data["stepdon3"] = false;
        action(data);
        checkDonasi(code);
      },
      onClose: function () {
        data["stepdon2"] = false;
        data["stepdon3"] = false;
        action(data);
      },
      gopayMode: state.postData.title_payment_type === "QRIS" ? "qr" : "auto",
    });
  };

  return (
    <>
      <Header title="Tetangga Bantu Tetangga" prevPage={linkPrev} />
      <main className="donate-tbt donate-beras">
          {/* {(!state.postData.stepdon1 && !state.postData.amount && !state.postData.stepdon2) && 
            <Redirect to={"/donation/"+detailCampaign.slug+"/step1"} />
          } */}
          <form onSubmit={handleSubmit(onSubmit)} className="form-hook">
            <Container className="container-donate">
              {auth && (
                <div className="suggest-donate content-grid wrap-auth-login">
                  <div className="wrapper-img">
                    <Image src={!auth.photo ? avatar : auth.photo} width="65" />
                  </div>
                  <div className="text-left">
                    <h6 className="mb-0">{auth.name}</h6>
                    <div>{auth.email}</div>
                  </div>
                </div>
              )}
              {!auth && (
                <div>
                  <div className="txt-body mb-20">
                    <Link to={"/login?donasiUang=tetangga-bantu-tetangga"}>
                      Masuk
                    </Link>{" "}
                    atau lengkapi data berikut
                  </div>
                  <div className="form-group">
                    <input
                      name="donor_name"
                      className="form-control"
                      ref={register({ required: "Nama wajib diisi" })}
                      onChange={handleChange}
                      autoFocus={true}
                      placeholder="Nama Lengkap"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="donor_name"
                      as={<p className="error-input"></p>}
                    />
                    {state.postData.errorMessages.name && (
                      <p className="error-input">
                        {state.postData.errorMessages.donor_name}
                      </p>
                    )}
                  </div>

                  <div className="form-group">
                    <input
                      name="contact"
                      className="form-control"
                      ref={register({ required: "Kontak wajib diisi" })}
                      onChange={handleChange}
                      autoFocus={true}
                      placeholder="Nomor HP atau Email"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="contact"
                      as={<p className="error-input"></p>}
                    />
                    {state.postData.errorMessages.contact && (
                      <p className="error-input">
                        {state.postData.errorMessages.contact}
                      </p>
                    )}
                  </div>
                </div>
              )}
              <div className="form-group">
                <label className="form-label">
                  Tulis Doa atau Dukungan (Optional)
                </label>
                <textarea
                  placeholder="Tuliskan pesan maupun doa"
                  name="support"
                  className="form-control"
                  onChange={handleChange}
                  ref={register({
                    maxLength: {
                      value: 140,
                      message: "Tidak boleh lebih dari 140 karakter",
                    },
                  })}
                  rows={5}
                />
                <ErrorMessage
                  errors={errors}
                  name="support"
                  as={<p className="error-input"></p>}
                />
              </div>
            </Container>
            {!state.postData.resultDonation.snapToken && (
              <nav>
                <Nav className="navbar">
                  {!state.postData.stepdon3 && (
                    <button
                      type="submit"
                      className="btn btn-donate btn-block mt-0"
                      id="btn-donation"
                      disabled={props.isLoading}
                    >
                      {!props.isLoading && <span>Ajukan Sekarang</span>}
                      {props.isLoading && (
                        <Spinner animation="border" role="status" />
                      )}
                    </button>
                  )}

                  {state.postData.stepdon3 &&
                    state.postData.typeof_payment_type === "transfer" && (
                      <Link
                        to="/campaign"
                        className="btn btn-donate btn-block mt-0"
                      >
                        <span>Ayo Berdonasi Lagi</span>
                      </Link>
                    )}
                </Nav>
              </nav>
            )}

            {state.postData.checkMessage && !state.postData.resultDonation && (
              <RedirectStep errorMessage={props.errorMessage} />
            )}
          </form>
          {state.postData.resultDonation.snapToken && (
            <Script
              url={midtrans.url}
              attributes={{ "data-client-key": midtrans.clientKey }}
              // onCreate={this.handleScriptCreate.bind(this)}
              // onError={this.handleScriptError.bind(this)}
              onLoad={handleScriptLoad.bind(this)}
            />
          )}
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  errorMessage: state.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  clearDispatch: () => dispatch(clearDispatch()),
  API: (path, data) => dispatch(API(path, data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step2));
