export default {
    name: "",
    email: "",
    phone: "",
    vlAutofocus:"",
    bio: "",
    address: "",
    social_media: "",
    coverage_area: "",
    area_id: "",
    provinsi: "",
    loadProvinsi:false,
    dataProvinsi:false,
    kota: "",
    loadKota:true,
    dataKota:false,
    akunsosmed: "",
    sosmed: "",
    urlsosmed: "",
    logo:"",
    legal:"",
    tax:"",
    identity:"",

    step1:false,
    step2:false,
    step3:false,
    step4:false,
    checkMessage:false,
    errorMessages:{},

    editCampaigner: '',
    editCampaignerSlug: '',
};