import React, { Fragment } from "react";
import {Container, Nav, Image} from "react-bootstrap";
import {Redirect, Link} from "react-router-dom";
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { Header } from '../../../organisms';

import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import * as QueryString from "query-string";
import moment from 'moment/min/moment-with-locales';
import Moment from 'react-moment';
import {FbPixel} from '../../../../config/Tracker/FbPixel'
import { ExternalLink } from 'react-external-link';

import IcWarningInfo from '../../../../assets/svg/ic-info-small.svg';
import logo from '../../../../assets/svg/logo-bangga-color.svg';
import { Helmet } from "react-helmet-async";
import {v4 as uuidv4} from 'uuid';

// Sets the moment instance to use.
Moment.globalMoment = moment;
 
// Set the locale for every react-moment instance to French.
Moment.globalLocale = 'id';
 
// Set the output format for every react-moment instance.
Moment.globalFormat = 'D MMMM YYYY, H:m';
 
// Set the output timezone for local for every instance.
Moment.globalLocal = true;


const PageStep3 = (props) => {
  const detailCampaign = JSON.parse(localStorage.getItem("detailCampaign"));
  const donationCampaign = JSON.parse(localStorage.getItem("donationCampaign"));
  const linkPrev = "/donation/"+detailCampaign?.slug??donationCampaign?.slug +"/step2";
  const { state } = useStateMachine(updateAction);
  const copyText = (str) => {
    toast.success(str+' telah di copy', {
      position: "top-center",
      hideProgressBar: true,
      autoClose:2000,
    });
  };

  const currencyFormat = (num) => {
    num = parseInt(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    let pointarr = num.split('.');
    pointarr.splice(-1,1);
    let newnum = pointarr.join('.');
    return 'Rp ' + newnum;
  };

  const lastThreeDigit = (num) => {
    num = parseInt(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    let pointarr = num.split('.');
    return pointarr[pointarr.length-1];
  };
  let myuuid = uuidv4();

  return (
    <Fragment>
        <main className="donate-tbt no-padding">  
          <Helmet>
          <script>
            {
              `window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                "event":"donationCheckout",
                "purchase": ${state.postData.resultDonation.total_donation === undefined ? state.postData.amount : state.postData.resultDonation.total_donation},
                "uuid": "${myuuid}",
                "campaignName": "${detailCampaign.title}",
                "campaignSlug": "${detailCampaign.slug}",
                "campaignCategory": "${detailCampaign.category_name}"
              })
            `}
          </script>
        </Helmet>
        <div className="top-logo">
            <Image src={logo}/>
              <div className="mb-1 mt-2">Selesaikan pembayaran sebelum</div>
              <div className="txt-date-payment mb-2">
                <Moment>
                  {state.postData.resultDonation.expire_at}
                </Moment>
              </div>
          </div>
          <Container className="container-donate no-padding">
            <div className="section txt-body-m ">
              <div className="content-transfer">
                <p>Transfer {state.postData.title_payment_type}</p>
                <Image src={state.postData.icon_payment_type} className="icon-channel"/>
              </div>
              <div className="content-grid grid-2 box-bordered">
                <div>
                  <span className="txt-short-desc">{state.postData.resultDonation.account_holder}</span>
                  <div>
                    <span className="txt-subtitle fw-6 ct-title">{state.postData.resultDonation.account_number}</span>
                  </div>
                </div>
                <CopyToClipboard
                  onCopy={state.postData.copyAccountNumber}
                  text={state.postData.resultDonation.account_number}>
                  <div className="ct-primary1 cursor-pointer fw-6 txt-copy" onClick={()=> copyText('No Rekening')}>Salin</div>
                </CopyToClipboard>
              </div>

              <div className="box-bordered mb-10">
                <div className="content-grid grid-2 ">
                  <div className="txt-subtitle fw-6 ct-title">
                    <span className="txt-short-desc">Jumlah Donasi</span>
                    <div className="nominal-donate">{currencyFormat(state.postData.resultDonation.total_donation)}.<span className="ct-primary2">{lastThreeDigit(state.postData.resultDonation.total_donation)}</span></div>
                  </div>
                  <div>
                    <CopyToClipboard
                      onCopy={state.postData.copyAmount}
                      text={state.postData.resultDonation.total_donation}>
                      <div className="ct-primary1 cursor-pointer fw-6 txt-copy" onClick={()=> copyText('Nominal')}>Salin</div>
                    </CopyToClipboard>
                  </div>
                </div>
                <div className="content-grid grid-2 info-form mt-3">
                    <div><Image className="loader-img" src={IcWarningInfo}/></div>
                    <div className="text-left ml-3">
                      Penting! Mohon transfer tepat sampai 3 digit terakhir untuk memudahkan verifikasi donasi Anda.
                    </div>
                </div>
                <span className="txt-three-digit">3 digit terakhir atau kode unik akan didonasikan</span>
              </div>
              {state.postData.stepdon3 && 
                <div className="float-btn-donate">
                  <Link to={(localStorage.getItem("userData") ? "/mydonate" : `/bukti-donasi/${state.postData.resultDonation.code}`)} className="btn btn-primary mt-1 mb-3"><span>Cek Status Donasi</span></Link>
                  <Link to="/" className="btn btn-secondary">
                    Lihat Penggalangan Lainnya
                  </Link>
                </div>
              }
              <div className="content-grid grid-2 mt-2">
                <div>Kode Donasi</div>
                <div className="ct-title">{state.postData.resultDonation.code}</div>
              </div>
            </div>
            
            <div className="content-space"></div>

            <div className="section">
              <div className="txt-subtitle-m fw-6 txt-cara-pembayaran">Cara Pembayaran</div>
              <Accordion allowZeroExpanded>
                <AccordionItem key={1}>
                  <AccordionItemHeading>
                      <AccordionItemButton>
                      Via Electronic Bank (E-Bank)
                      </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <ul>
                      <li>Buka aplikasi peramban favorit Anda</li>
                      <li>Kunjungi Website E-Banking Anda</li>
                      <li>Masukan akun E-Banking Anda dengan benar</li>
                      <li>Pilih Transfer</li>
                      <li>Masukan Nomor Rekening tujuan dan pastikan penerima dengan nama Yayasan Bantu Tetangga</li>
                      <li>Masukan Nominal Donasi anda tepat dengan Kode Unik</li>
                      <li>Tunggu sampai proses transfer selesai</li>
                      <li>Pastikan sistem E-Banking Anda berhasil melakukan proses transfer</li>
                      <li>Tunggu konfirmasi otomatis dari sistem Yayasan Bantu Tetangga mengkonfirmasi donasi Anda bia SMS dan EMAIL valid yang dicantumkan saat input data donatur</li>
                    </ul>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem key={2}>
                  <AccordionItemHeading>
                      <AccordionItemButton>
                          Via Anjungan Tunai Mandiri (ATM)
                      </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <ul>
                      <li>Masukan PIN Anda dengan benar</li>
                      <li>Pilih menu transfer</li>
                      <li>Masukan Nomor Rekening atas nama Yayasan Bantu Tetangga</li>
                      <li>Masukan Nominal Donasi anda tepat dengan Kode Unik</li>
                      <li>Tunggu sampai proses transfer selesai</li>
                      <li>Pastikan sistem Banki Anda berhasil melakukan proses transfer</li>
                      <li>Tunggu konfirmasi otomatis dari sistem Yayasan Bantu Tetangga mengkonfirmasi donasi Anda bia SMS dan EMAIL valid yang dicantumkan saat input data donatur</li>
                    </ul>    
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
              <div className="text-center mb-4">Pembayaran anda akan terkonfirmasi secara otomatis. Jika terjadi kesalahan atau kesulitan, silakan <ExternalLink href={"https://wa.me/6281221127728"}>Hubungi Kami</ExternalLink></div>
            </div>
          </Container>
        </main>
    </Fragment>
  );
};

class Step3 extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        codeDonate: null,
        expire_at: null,
        expired_status: null,
        campaign: null,
        campaign_slug: null
      }
  }

  checkDonasi = async (codeDonate) => {
    const {history} = this.props;
    const { API } = this.props;
    const config = {
      method: 'get',
      url: '/donation/detail/'+codeDonate,
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const res = await API(config, false).catch(err=>err);
    if (res) {
      this.setState({codeDonate:res.data.code})
      this.setState({expired_at:res.data.expired_at})
      this.setState({expired_status:res.data.expired_status})
      this.setState({campaign:res.data.campaign})
      this.setState({campaign_slug:res.data.campaign_slug})
      localStorage.setItem("donationCampaign", JSON.stringify(res.data))
      if(res.data.status === "paid"){
        history.push("/donation/result/success?code="+codeDonate);
      }else{
        setTimeout(() =>{
          this.checkDonasi(codeDonate);
        },10000 );
      }
    }
  }

  componentDidMount() {
    FbPixel('InitiateCheckout');
    const params = QueryString.parse(this.props.location.search);
    if(params.code){
      this.setState({codeDonate:params.code})
      this.checkDonasi(params.code);
    }
  }
  render() {
      const state = this.state
      return(
        <React.Fragment>
          {state.expired_status === true ?
            <main className="donate-tbt no-padding">  
              <div className="top-logo">
                <Image src={logo}/>
                <h5 className="txt-thanks">Terima kasih atas niat baik Anda</h5>
                <div className="mb-1 mt-2 txt-expired">
                  Mohon maaf donasi Anda dengan nomor <br/> <span className="color-bold">{state.codeDonate}</span> sudah melewati batas waktu pembayaran di tanggal  <span className="fw-5"><Moment>{state.expired_at}</Moment> WIB</span>
                </div>
              </div>
              <Container className="container-donate no-padding">
                <div className="section txt-body-m ">
                  <div className="txt-program">
                    <p>Silakan klik tombol di bawah untuk ulangi donasi anda ke program <span className="color-bold fw-6">{state.campaign} </span></p>
                  </div>
                  <div className="float-btn-donate">
                    <Link to={(`/campaign/${state?.campaign_slug}`)} className="btn btn-primary mt-1 mb-3"><span>Donasi Ulang</span></Link>
                    <Link to="/" className="btn btn-secondary">
                      Lihat Penggalangan Lainnya
                    </Link>
                    <p className="txt-ket">atau</p>
                    <Link to={{ pathname: "https://wa.me/6281221127728" }} rel="noopener noreferrer" target="_blank" className="btn btn-secondary">
                      Hubungi Admin
                    </Link>
                  </div>
                </div>
              </Container>
            </main> 

          :
            <PageStep3 stateStep3={state}/>
          }
        </React.Fragment>
      )
  }
}

const mapStateToProps = (state) => ({
  errorMessage: state.errorMessage,
})

const mapDispatchToProps = (dispatch) => ({
clearDispatch: () => dispatch(clearDispatch()),
API: (path, data) => dispatch(API(path, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Step3);
