import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";
import {Nav, Image, Container} from "react-bootstrap";
import { Header } from '../../../organisms';

const Step2 = () => {
  const detailCampaign = JSON.parse(localStorage.getItem("detailCampaign"));
  const linkPrev = "/donation/"+detailCampaign.slug+"/step1";
  const linkNext = "/donation/"+detailCampaign.slug+"/step2";
  const { state, action } = useStateMachine(updateAction);
  const { handleSubmit, register } = useForm({
    defaultValues: state.postData
  });
  const { push } = useHistory();
  const onSubmit = data => {
    push(linkNext);
  };
  const handleChange = (e) =>{
    let val = e.target.value;
    let field = e.target.name;
    let data = {};
    data[field] = val;
    data['stepdon2'] = false;
    if(field==='payment_type'){
      data['typeof_payment_type'] = e.target.getAttribute('data-type');
      data['icon_payment_type'] = e.target.getAttribute('data-icon');
      data['title_payment_type'] = e.target.getAttribute('data-title');
    }
    action(data);
    push(linkNext);
  }
  
  return (
    <>
      <Header title="Pilih Metode Pembayaran" prevPage={linkPrev}/>
        <main>
          <Container className="container-donate p-0">
            
            <form onSubmit={handleSubmit(onSubmit)} className="form-hook">
              {state.postData.loadPaymentTypes &&
                state.postData.dataPaymentTypes.map((data, keyGroup) => {
                  return (
                    <div key={keyGroup}>
                      <div className="payment-group ct-title txt-body-m fw-6">{data.group}</div>
                      {
                        data.items.map((value, key) => {
                          const id = (key+'-'+keyGroup);
                          return (  
                            <div key={id} className="item-pay-type"> 
                              <div className="md-radio box-new-payment">
                                { state.postData.payment_type === value ?
                                  <input 
                                    id={id} 
                                    type="radio" 
                                    name="payment_type" 
                                    value={value.value}
                                    data-type={value.type}
                                    data-icon={value.icon}
                                    data-title={value.title}
                                    checked
                                    onChange={e =>  handleChange(e)}
                                    ref={register({ required: "Metode Pembayaran wajib dipilih" })}
                                  />
                                  :
                                  <input 
                                    id={id} 
                                    type="radio" 
                                    name="payment_type" 
                                    value={value.value}
                                    data-type={value.type}
                                    data-icon={value.icon}
                                    data-title={value.title}
                                    onChange={e =>  handleChange(e)}
                                    ref={register({ required: "Metode Pembayaran wajib dipilih" })}
                                  />
                                }
                                <label htmlFor={id} className="donate-tbt">
                                  <div className="box-channel">
                                    <Image className="icon-channel float-left" src={value.icon}/>
                                  </div>
                                  <div className="float-left txt-body ct-title">{value.title}</div>
                                  <Image className="ic-right" src="/assets/img/ic-right.svg"/>
                                </label>
                              </div>
                              <hr className="m-0"/>
                            </div>
                          )
                            
                        })
                      }
                    </div>
                  )
                })
              }
              {/* <nav>
                <Nav className="navbar">
                  <button type="submit" className="btn btn-donate btn-block mt-0">
                    <span>Pilih Pembayaran</span>
                  </button>
                </Nav>
              </nav> */}
            </form>
          </Container>
      </main>
    </>
  );
};

export default Step2;
