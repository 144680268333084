import React from 'react'
import {Image} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';
import avatar from "../../../../assets/img/avatar/no-img.png";
import Moment from 'react-moment';

const Card =  ({ data }) => {
    return (
        <div className="respond-card">
            <div className="card-profile">
                <div className="penggalang-crop"><Image src={!data.user_image ? avatar : data.user_image}/></div>
                <div className="text-left">
                    <div className="txt-body-m fw-6">
                        {data.user_name}
                    </div>
                    <div className="txt-caption-m">
                        Merespon pada
                        <Moment format=" D MMMM YYYY">
                            {data.time}
                        </Moment>
                    </div>
                </div>
            </div>
            <div className="txt-body">{data.text}</div>
        </div>
    )
}

export default withRouter(Card)
