import React from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { useHistory, Redirect } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";
import {Spinner, Container, Image} from "react-bootstrap";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { clearDispatch, API, validateTitle } from '../../../../config/redux/action';
import * as QueryString from "query-string";

import CurrencyFormat from 'react-currency-format';
import { Header, Wizard } from '../../../organisms';
import icCalendar from '../../../../assets/svg/ic-calendar.svg';
import IcWarningBlue from '../../../../assets/svg/ic-warning-blue.svg';
import {StringToSlug} from '../../../../utils';

const isMobileDevice = () => {
  const mobileUserAgent = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  return mobileUserAgent.some((item) => navigator.userAgent.match(item));
};

const PageStep1 = (props) => {
  const [isMobile] = React.useState(isMobileDevice())

  const { state, action } = useStateMachine(updateAction);
  let linkPrev = "/galang-dana/intro2";

  const { handleSubmit, errors, register } = useForm({
    defaultValues: state.postData
  });
  const { push } = useHistory();
  const onSubmit = data => {
    checkValues(data);
  };

  const checkValues = async (data) => {
    const auth = JSON.parse(localStorage.getItem("userData"));
    const token = `Bearer ${auth.access_token}`;

    let formData = {
      category_slug: state.postData.category_id,
      title: state.postData.title,
      target: state.postData.target,
      budget_plan: state.postData.budget_plan,
      slug: StringToSlug(state.postData.slug),
      just_validate:true
    };
    
    const editCampaignSlug = (state.postData.editCampaignSlug ? state.postData.editCampaignSlug : '');
    const config = {
        method: (editCampaignSlug === '' ? 'post' : 'put'),
        url: '/campaign/'+editCampaignSlug,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        data: formData
    };

    const res = await props.propsAPi(config, true, true).catch(err=>err);
    if (res) {
      if(Object.keys(state.postData.errorMessages).length === 0) {
        data['stepgal1'] = true;
        if(!state.postData.dataProvinsi){
          data['loadProvinsi'] = false;
          apiGetArea('Provinsi')
        }
    
        // if(!state.postData.expired_at){
        //   data['expired_at'] = formatDate(state.postData.expired_at_date);
        // }
        action(data);
        push("/galang-dana/step2");
      }
    }else{
      let data={};
      data['checkMessage'] = true;
      action(data);
    }
  }

  const handleChange = (e) =>{
    let errorMessages = state.postData.errorMessages
    delete errorMessages.title
    action({ errorMessages })
    
    let val = e.target.value;
    let field = e.target.name;

    if(field === 'title') {
      const validate = props.propsValidateTitle('title', e.target.value)
      if(!validate.status) {
        errorMessages.title = validate.errorMessage.title
        action({ errorMessages })
      }
    }
    
    if(props.errMessages[field]){
      let fieldChanges = {};
      fieldChanges = props.errMessages;
      fieldChanges[field] = '';
      props.propsClearDispatch(fieldChanges);
    }

    let data = {};
    data[field] = val;
    data['stepgal1'] = false;
    data['checkMessage'] = false;
    action(data);
  }

  const handleCurrency = (field, value) => {
    let data = {};
    data[field] = value
    action(data);
  }

  const apiGetArea = async (type, parent=null) => {
    const auth = JSON.parse(localStorage.getItem("userData"));
    const token = `Bearer ${auth.access_token}`;

    let params = '?type='+(type === 'Kota' ? 'Kabupaten/Kota' : type);
    params += ((type === 'Kota' || type === 'Kecamatan') ? '&parent='+parent : '');
    const config = {
        method: 'get',
        url: '/area'+params,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    };
    const res = await props.propsAPi(config, false).catch(err=>err);
    if (res) {
        let data = {};
        data['load'+type] = true;
        data['data'+type] = res.data.data;
        action(data);
    }
  }

  const checkdate = (date) => {
    let errorMessages = state.postData.errorMessages
    delete errorMessages.expired_at
    action({ errorMessages })

    let data = {};
    data['expired_at_date'] = date;
    data['expired_at'] = formatDate(date);
    
    if(new Date(date) <= new Date()) {
      errorMessages.expired_at = 'Invalid Date'
      action({ errorMessages })
    }

    action(data);
  }
  const formatDate = (date) => {
    let d = (date ? new Date(date) : new Date());
    let mm = d.getMonth() + 1;
    let dd = d.getDate();
    let yy = d.getFullYear(); 
    if(mm < 10)
      mm = '0' + mm
    let myDateString = yy + '-' + mm + '-' + dd;
    return myDateString;
  }
  
  let minDate = new Date()
  minDate.setDate(minDate.getDate() + 1)
  const editCampaign = (state.postData.editCampaign ? state.postData.editCampaign : '');
  const linkStep1 = (state.postData.prevPage ? state.postData.prevPage : editCampaign);
  const initialSteps = {
    class1:"step-hide",
    number1:"",
    label1:"",
    link1:"",

    class2:"step-active",
    number2:1,
    label2:"Detail",
    link2:"/galang-dana/step1"+linkStep1,

    class3:"",
    number3:2,
    label3:"Lokasi",
    link3:"/galang-dana/step2"+editCampaign,  
  }

  const prevFunction = () => {
    let data = {};
    data['backPage'] = 0;
    action(data);

    if(props.queryParams.edit){
      linkPrev = "/detail-campaign/"+props.queryParams.edit;
      if(props.queryParams.prevPage){
        linkPrev = "/"+props.queryParams.prevPage;
      } 
    }
    push(linkPrev);
  }
  return (
    <>
    <Header title={(state.postData.editCampaign ? "Ubah Data Campaign " : "Galang Donasi")} prevPage={linkPrev} prevFunction={prevFunction} ic_white={true}/>
    <Wizard initialSteps={initialSteps} />
    <main className="pb-0">
      <Container>  
        <form onSubmit={handleSubmit(onSubmit)} className="form-hook">
          {(!state.postData.intro && !state.postData.editCampaign) && 
            <Redirect to="/galang-dana" />
          }
          <h6 className="mb-3">Silahkan isi detail penggalangan dana.</h6>
          <div className="form-group">
            <label className="form-label">Judul Penggalang</label>
            <input
              name="title"
              className="form-control"
              ref={register({ required: "Judul wajib diisi" })}
              onChange={handleChange}
              placeholder="Masukkan Judul Campaign"
            />
            <ErrorMessage errors={errors} name="title" as={<p className="error-input"></p>} />
            {props.errMessages.title && 
            <p className="error-input">{props.errMessages.title}</p>}
          </div>

          <div className="form-group">
            <label className="form-label">
              Kategori Program
            </label>
            <select 
              className="form-control mb-2" 
              name="category_id" onChange={handleChange}
              ref={register({ required: "Kategori wajib dipilih" })}
              >
              <option value="">{!state.postData.loadCategory ? 'Sedang mengambil data..' : 'Pilih Kategori'}</option>
              { state.postData.loadCategory &&
                  state.postData.dataCategory.map((value, key) =>
                    <option key={key} value={value.slug}>{value.name}</option>
                  )
              }
            </select>
            <ErrorMessage errors={errors} name="category_id" as={<p className="error-input"></p>} />
            {state.postData.errorMessages.category_id && 
            <p className="error-input">{state.postData.errorMessages.category_id}</p>}
          </div>

          <div className="form-group">
            <label className="form-label">Batas Waktu Penggalangan</label>
            {isMobile && 
              <input 
                type="date" 
                name="expired_at_date"
                className="form-control"
                onChange={e => checkdate(e.target.value)}
                ref={register({ required: "Tanggal wajib diisi" })}
                placeholder="Pilih Tanggal"
                min={formatDate(minDate)}
                defaultValue={state.postData.expired_at ? state.postData.expired_at : ''}
              />
            }
            {!isMobile && 
              <div className="fh-input-group ig-right">
                <ReactDatePicker 
                  className="form-control"
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Pilih Tanggal"
                  selected={(state.postData.expired_at ? new Date(state.postData.expired_at) : '')}
                  onChange={date => checkdate(date)}
                  minDate={minDate}
                />
                <div className="input-right">
                  <Image src={icCalendar} />
                </div>
              </div>
            }
            {!state.postData.expired_at_date && 
              <div>
                <input
                name="expired_at_date"
                className="hide"
                ref={register({ required: "Target Waktu wajib diisi" })}
                />
                <ErrorMessage errors={errors} name="expired_at_date" as={<p className="error-input"></p>} />
              </div>
            }
            
            {state.postData.errorMessages.expired_at && 
            <p className="error-input">{state.postData.errorMessages.expired_at}</p>}
          </div>
          
          <div className="form-group">
            <label className="form-label">Target Donasi</label>
            <div className="fh-input-group ig-left">
              <div className="input-left">
                Rp
              </div>
              <CurrencyFormat 
                value={state.postData.target} 
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={''} 
                className="form-control"
                onValueChange={values => {
                  handleCurrency('target', values.value);
                }}
              />
            </div>
            {!state.postData.target && 
              <div>
                <input
                name="target"
                className="hide"
                ref={register({ required: "Target Donasi wajib diisi" })}
                />
                <ErrorMessage errors={errors} name="target" as={<p className="error-input"></p>} />
              </div>
            }
            {state.postData.errorMessages.target && 
            <p className="error-input">{state.postData.errorMessages.target}</p>}
            {state.postData.target < 1000000 && state.postData.target !== '' && 
              <p className="error-input">Target donasi minimal Rp 1.000.000</p> }
          </div>

          <div className="txt-body-m">
            <div className="content-grid grid-2 info-form2 mb-10">
                <div><Image className="loader-img" src={IcWarningBlue}/></div>
                <div className="text-left ml-3">
                  Batas Waktu dan Target Donasi dapat diubah sewaktu-waktu jika diperlukan.
                </div>
              </div>
          </div>
          <hr/>

          <div className="form-group">
            <label className="form-label">Link Donasi</label>

            <div className="content-url-campaign">
              <div className="link txt-body-m">bantutetangga.com/</div>
              <div className="campaign-slug">
                <input 
                  type="text"
                  name="slug"
                  className="form-control"
                  placeholder="Link url"
                  ref={register({ required: "Link Url" })}
                  onChange={handleChange}
                  />
              </div>
            </div>
            <ErrorMessage errors={errors} name="slug" as={<p className="error-input"></p>} />
            {props.errMessages.slug && 
            <p className="error-input">{props.errMessages.slug}</p>}
          </div>
          <div className="txt-body-m">
            <div className="content-grid grid-2 info-form2 mb-10">
                <div><Image className="loader-img" src={IcWarningBlue}/></div>
                <div className="text-left ml-3">
                  Link Donasi merupakan link yang Anda buat untuk donasi ini agar bisa dibagikan ke media sosial.
                </div>
              </div>
          </div>

          <div className="form-group">
            <label className="form-label">Tujuan Penggalangan</label>
            <textarea
              name="budget_plan"
              className="form-control"
              ref={register({ required: "Kami akan menggunakannya untuk..." })}
              onChange={handleChange}
            />
            <ErrorMessage errors={errors} name="budget_plan" as={<p className="error-input"></p>} />
            {state.postData.errorMessages.budget_plan && 
            <p className="error-input">{state.postData.errorMessages.budget_plan}</p>}
          </div>

          <button type="submit" className="btn btn-primary btn-block btn-next mt-0" disabled={(props.isLoading || state.postData.target < 1000000 ? true : false)}>
              { (!props.isLoading) && <span>Selanjutnya</span> }
              { props.isLoading && <Spinner animation="border" role="status"/> }
            </button>
        </form>
      </Container>
    </main>
  </>
  );
};

class Step1 extends React.Component {
  constructor(props){
      super(props);
      this.state = {
          auth: {}
      }
  }
  componentDidMount() {
      this.props.clearDispatch();
      const {history} = this.props;
      if(localStorage.getItem("userData")){
          const auth = JSON.parse(localStorage.getItem("userData"))
          if(!auth.campaigner){
            history.push('/preverification');
          }
          this.setState ({ auth: auth  });
      }else{
          history.push('/login?ref=galang-dana');
      }
  }
  render() {
      const queryParams = QueryString.parse(this.props.location.search);
      const url = this.props.location.pathname;
      const pathArr = url.split('/');
      const uri = pathArr[pathArr.length - 1];
      const prevPage = (queryParams.edit) ? "/detail-campaign/"+queryParams.edit : "/profile";
      return(
        <PageStep1 
          prevPage={prevPage} 
          queryParams={queryParams} 
          uri={uri} 
          errMessages={this.props.errorMessage} 
          isLoading={this.props.isLoading} 
          propsAPi={this.props.API} 
          propsValidateTitle={this.props.validateTitle}
          propsClearDispatch={this.props.clearDispatch} 
        />
      )
  }
}


const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
  clearDispatch: (fieldChanges) => dispatch(clearDispatch(fieldChanges)),
  API: (path, data, showError) => dispatch(API(path, data, showError)),
  validateTitle: (fieldName, value) => dispatch(validateTitle(fieldName, value))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step1));
