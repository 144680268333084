export default {
    amount: '',
    donor_name: localStorage.getItem('nama-lengkap-donasi'),
    is_anonim: false,
    contact: localStorage.getItem('contact-donasi'),
    support: '',
    payment_type: localStorage.getItem('payment-type-donasi'),
    typeof_payment_type: localStorage.getItem('typeof_payment_type-donasi'),
    icon_payment_type: localStorage.getItem('payment-icon-donasi'),
    title_payment_type: localStorage.getItem('payment-title-donasi'),
    copyAmount: '',
    copyAccountNumber: '',
    resultDonation: {snapToken:false},
    
    dataPaymentTypes: {},
    loadPaymentTypes:false,

    //Form Wizard
    stepdon1:false,
    stepdon2:false,
    stepdon3:false,
    errorMessages:{},
};