import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { API, clearDispatch } from '../../../../config/redux/action';
import { Header } from "../../../organisms";
import icArrowRight from '../../../../assets/svg/ic-arrow-right-gray.svg';

import './style.css';
import { Accordion, Container, Image } from 'react-bootstrap';
import Loading from './Loading';

class FAQDetail extends React.Component {
    state = {
        isFetching: true,
        results: {},
    }
    async apiResult() {
        this.setState({ isFetching: true })
        
        const config = {
            method: 'get',
            url: '/faqs/' + this.props.match.params.slug,
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if(res) {
            this.setState({ results: res.data })
        }

        this.setState({ isFetching: false })
    }
    componentDidMount() {
        this.apiResult()
    }
    render() {
        return (
            <React.Fragment>
                <Header title="FAQs" prevPage="/faq" />
                <main>
                    <Container className="faq-search">
                        <div className="txt-title fw-6 mb-2">{this.state.results.topic ? this.state.results.topic : '-'}</div>
                        <div className="txt-body mb-3">Berikut adalah pertanyaan-pertanyaan mengenai Donasi</div>
                        {!this.state.isFetching && this.state.results.faqs.map((val, key) => (
                            <Accordion className="cp-card" key={key}>
                                <Accordion.Toggle as="div" eventKey="0" className="d-flex">
                                    <div className="w-100">
                                        <div className="txt-subtitle-s mb-2 fw-6 my-auto">{val.question}</div>
                                    </div>
                                    <div className="d-flex px-3">
                                        <Image className="m-auto" src={icArrowRight} />
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <div className="txt-body-s mt-3 mb-2"
                                        dangerouslySetInnerHTML={{
                                            __html: val.answer
                                        }}
                                    ></div>
                                </Accordion.Collapse>
                            </Accordion>
                        ))}
                        {this.state.isFetching && <Loading />}
                    </Container>
                </main>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FAQDetail));