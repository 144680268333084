import React from 'react';
import { withRouter } from "react-router";
import { Nav, Image } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { useLocation } from "react-router-dom";
import queryString from 'query-string';
import logoBangga from '../../../../assets/svg/logo.svg';
import logo3 from '../../../../assets/svg/logo3.svg';
import './style.css';
import propTypes from 'prop-types';

const SearchBar =  (props) => {
    const prevPage = queryString.parse(useLocation().search).prevPage;
    const link = (prevPage ? "/"+prevPage : (props.prevPage ? props.prevPage : '/'));

    const logo = props.isLogoSmall ? logo3 : logoBangga;
    return (
        <header className={"search-bar search-bar-news"}>
            <Nav>
                <Nav.Item>
                    {props.showLogo ? 
                        <Link to={""} className="nav-link mr-2">
                            <Image src={logo}/>
                        </Link>
                        :
                        <Link to={link} className="nav-link">
                            {link && <i className="ic-header ic-arrow-left"></i>}
                        </Link>
                    }
                </Nav.Item>
                <Nav.Item onClick={() => props.history.push(props.to)}>
                    <div className="search-input search-input-gray">
                        <div><Image src="assets/img/ic-search-gray.svg"/></div>
                        <div>Cari Berita</div>
                    </div>
                </Nav.Item>
            </Nav>
        </header>
    
    )
}

SearchBar.defaultProps = {
    title: 'No Title',
    header: 'home',
    isLogoSmall: false,
}

SearchBar.propTypes = {
    isLogoSmall: propTypes.bool,
}

export default withRouter(SearchBar);