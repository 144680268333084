import React from 'react'
const Loading =  () => {
    return (
        <>
            <div className="content-grid grid-2-8 flex-start">
                <div className="gradient text-loading text-loading-90 height-50"></div>
                <div>
                    <div className="gradient text-loading height-30"></div>
                    <div className="gradient text-loading height-10"></div>
                </div>
            </div>
        </>
    )
}

export default Loading
