import React, { useState } from 'react';
import { Image,Modal } from "react-bootstrap";
import icCloseModal from '../../../../assets/svg/ic-close-modal.svg';
import ModalPostRek from './ModalPostRek';
import { withRouter } from "react-router";
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';

const AddRekening =  (props) => {
    const { show, onHide, onLoadRek, errorMessage } = props

    const [formPost, setFormPost] = useState({})
    const [isLoadingRek, setIsLoadingRek] = useState()

    const buildFormPost = (field, value) => {
        let formPostNew = {...formPost};
        formPostNew[field] = value;
        setFormPost(formPostNew)
    }
    const handleFormChange = (e) =>{
        buildFormPost(e.target.name, e.target.value);
    }

    const handleSubmitFormRek = async () => {
        setIsLoadingRek(true)
        const auth = JSON.parse(localStorage.getItem("userData"))
        const token = `Bearer ${auth.access_token}`;

        let postData = formPost;
        const config = {
            method: 'post',
            url: '/campaigner/account-number',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            data: postData
        };
        const res = await props.API(config, false).catch(err=>err);
        if (res) {
            setIsLoadingRek(false)
            toast.info('Rekening berhasil ditambahkan', {
                position: "top-center",
                hideProgressBar: true,
                autoClose:3000,
            });
            onHide();
            onLoadRek();
        }
    }
    return (
        <Modal show={show} onHide={onHide} animation={false} centered className="modal-bottom">
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">
                    <Image src={icCloseModal} onClick={onHide} className="cursor-pointer" /> 
                    <span className="txt-title">Tambah Rekening</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ModalPostRek 
                    handleChange={handleFormChange} 
                    errorMessage={errorMessage}
                    handleSubmitFormRek={handleSubmitFormRek}
                    isLoadingRek={isLoadingRek}
                />
            </Modal.Body>
        </Modal>
    )
}
const mapStateToProps = (state) => ({
    errorMessage: state.errorMessage
})
  
const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddRekening));