import React from 'react';
import { Container } from 'react-bootstrap';
import { Header } from '../../organisms';
import './style.css'

const KebijakanPrivasi = () => {
    return(
        <>
            <Header title="Kebijakan & Privasi" prevPage="/profile"/>
            <main>
                <Container className="info txt-body-m">
                    <h5>KEBIJAKAN PRIVASI</h5>
                    <p>
                        Kebijakan Privasi ini mengatur mengenai cara portal www.bantutetangga.com dalam memperoleh dan menjaga data Pengguna Portal. Penyusunan ketentuan ini memperhatikan keberlakukan Undang-Undang Nomor 19 Tahun 2016 tentang Perubahan Atas Undang-Undang Nomor 11 Tahun 2008 tentang Transaksi Elektronik dan Peraturan Menteri Komunikasi dan Informatika Nomor 20 Tahun 2016 tentang Perlindungan Data Pribadi Dalam Sistem Elektronik. Kami menghargai segala data Pengguna yang telah terdaftar dalam layanan dan sistem elektronik Caripahala (Penyelenggara). Tiap Pengguna yang memanfaatkan layanan Portal ini dianggap telah menyadari dan menyetujui ketentuan ini.
                    </p>

                    <ul>
                        <li><b>SUBJEK PENGGUNA PORTAL</b><br/>
                            Bahwa istilah Pengguna yang digunakan dalam ketentuan ini merujuk pada Peraturan Otoritas Jasa Keuangan Nomor 37/POJK.04/2018 tentang Layanan Urunan Dana Melalui Penawaran Berbasis Teknologi Informasi (Equity Crowdfunding). Berdasarkan Pasal 1 POJK Equity Crowdfunding terdiri dari:
                            <ul>
                                <li><b>Penerbit</b><br/>
                                    Badan hukum Indonesia berbentuk perseroan terbatas yang menawarkan saham melalui layanan dan sistem elektronik penyelenggara.
                                </li>
                                <li><b>Pemodal</b><br/>
                                    Pihak (Perseorangan dan Badan Hukum) yang melakukan pembelian saham Penerbit melalui layanan dan sistem elektronik penyelenggara.
                                </li>
                            </ul>
                        </li>
                        <li><b>KLASIFIKASI DATA PENGGUNA</b><br/>
                            Kami telah menetapkan kategori Data Pengguna yang kami kumpulkan dengan klasifikasi sebagai berikut:
                            <ul>
                                <li><b>Data Pribadi Perorangan</b><br/>
                                    Data Pribadi Perorangan berarti informasi yang mengidentifikasikan Pengguna sebagai seorang individu, seperti:
                                    <ul>
                                        <li>Nama</li>
                                        <li>Tanggal Lahir</li>
                                        <li>ID Nomor Kartu (KTP/SIM/KK/Paspor/NPWP)</li>
                                        <li>Alamat</li>
                                        <li>Kode Pos</li>
                                        <li>Negara</li>
                                        <li>Status Perkawinan</li>
                                        <li>Catatan Kriminal</li>
                                        <li>Gambar Profil</li>
                                        <li>ID Akun Jejaring Sosial</li>
                                        <li>Informasi Kontak (Telepon/Seluler/Email)</li>
                                        <li>Kondisi Keuangan (Penghasilan per tahun)</li>
                                        <li>Informasi lainnya yang mungkin langsung atau tidak langsung digunakan untuk mengidentifikasi pribadi pengguna</li>
                                    </ul>
                                </li>
                                <li><b>Data Badan Hukum</b><br/>
                                    Data Badan Hukum berarti informasi yang mengidentifikasi Pengguna sebagai Badan Hukum seperti:
                                    <ul>
                                        <li>Akta Pendirian/Anggaran Dasar berikut perubahanya</li>
                                        <li>Surat Keputusan Kementerian Hukum dan HAM</li>
                                        <li>Surat Keterangan Domisili</li>
                                        <li>Nomor Pokok Wajib Pajak (NPWP)</li>
                                        <li>Nomor Induk Berusaha (NIB)</li>
                                        <li>Susunan Pemegang Saham dan Pengurus</li>
                                        <li>Sumber Daya Manusia</li>
                                        <li>Asset</li>
                                        <li>Laporan Keuangan</li>
                                        <li>Produk/layanan</li>
                                        <li>Informasi Kontak (Telepon/Email/Fax)</li>
                                        <li>Informasi lainnya yang mungkin langsung atau tidak langsung digunakan untuk mengidentifikasi Data Badan Hukum</li>
                                    </ul>    
                                </li>
                            </ul>
                        </li>
                        <li><b>CARA PENGUMPULAN DATA PENGGUNA</b><br/>
                            Kami sebagai penyelenggara Equity Crowdfunding dapat mengumpulkan Data Pengguna dengan cara yang beragam, yaitu:
                            <ul>
                                <li><b>Online</b><br/>
                                    Kami dapat mengumpulkan data-data saat Pengguna memanfaatkan layanan penyelenggara, misalnya, Pengguna melakukan kunjungan dan pendaftaran (resgitrasi) melalui Portal dan/atau memberikan Data Pengguna melalui sarana komunikasi seperti Live Chat, Email, dan Fitur unggahan dokumen yang dipersyaratkan oleh penyelenggara.
                                </li>
                                <li><b>Offline</b><br/>
                                    Kami dapat mengumpulkan Data Pengguna secara offline, seperti pada saat Pengguna menghubungi layanan Pengguna melalui Telepon maupun dengan tatap muka
                                </li>
                                <li><b>Sumber Lainnya</b><br/>
                                    Kami dapat menerima Data Pengguna dari sumber lainnya, seperti melalui database publik, mitra pemasaran bersama, platform jejaring sosial dari orang-orang dengan siapa Pengguna berteman atau dengan cara lain terhubung pada platform jejaring sosial, serta dari pihak ketiga lainnya.
                                </li>
                            </ul>
                        </li>
                        <li><b>TUJUAN PENGGUNAAN DAN PENGUNGKAPAN DATA PENGGUNA</b><br/>
                            Kami hanya akan menggunakan dan menampilkan Data Pengguna hanya diperlukan untuk tujuan sebagai berikut:
                            <ul>
                                <li>Memproses aplikasi dan/atau segala bentuk pendaftaran dan/atau komunikasi yang diajukan oleh pengguna;</li>
                                <li>Pelaksanaan riset-riset guna pengembangan dan peningkatan pelayanan kami dan/atau memenuhi permintaan pengguna terhadap produk/layanan yang disediakan oleh penyelenggara;</li>
                                <li>Menganalisis tingkat risiko usaha, likuiditas, dilusi saham, hukum, dan kegagalan sistem elektronik yang kemungkinan dihadapi oleh pengguna dalam hal ini penerbit;</li>
                                <li>Publikasi profil pengguna dalam hal ini penerbit yang sedang melakukan penawaran saham melalui portal penyelenggara;</li>
                                <li>Sebagai dasar pengguna dalam hal ini calon pemodal untuk mengambil keputusan membeli atau menjual saham penerbit;</li>
                                <li>Pelaksanaan menyampaikan laporan tahunan secara berkala kepada Otoritas Jasa keuangan;</li>
                                <li>Pengungkapan kepada Pusat Pelaporan dan Analisis Transaksi Keuangan (PPATK) atas indikasi Transaksi Keuangan Mencurigakan (TKM) yang dilakukan oleh pengguna tertentu.</li>
                            </ul>
                        </li>
                        <li><b>PERSETUJUAN DAN PENGECUALIAN PENGALIHAN DATA PENGGUNA KEPADA PIHAK KETIGA</b><br/>
                            Bahwa istilah Pengguna yang digunakan dalam ketentuan ini merujuk pada Peraturan Otoritas Jasa Keuangan Nomor 37/POJK.04/2018 tentang Layanan Urunan Dana Melalui Penawaran Berbasis Teknologi Informasi (Equity Crowdfunding). Berdasarkan Pasal 1 POJK Equity Crowdfunding terdiri dari:
                            <ul>
                                <li>Kami menjamin bahwa segala perolehan, penggunaan, pemanfaatan, pengungkapan data-data berdasarkan persetujuan pengguna. Kami akan meminta tindakan afirmatif pengguna (misalnya tap untuk menerima persetujuan, memberikan centang pada kotak centang, perintah lisan, dll.) sebagai bentuk persetujuan pengguna dalam memberikan data-data kepada kami;</li>
                                <li>Kami tidak akan membuka dan mengalihkan data pengguna kepada pihak ketiga di luar Perseroan tanpa persetujuan terlebih dahulu kepada pengguna. Seluruh data yang Perseroan peroleh hanya akan dibuka kepada karyawan atau manajemen Perseroan yang memang perlu mengetahui data-data tersebut;</li>
                                <li>Kami tidak bertanggung jawab atas pemberian atau pertukaran data yang dilakukan sendiri antar pengguna;</li>
                                <li>Kecuali persetujuan tidak diperlukan apabila diwajibkan oleh ketentuan hukum yang berlaku seperti perintah dari instansi yang berwenang, termasuk dalam hal ini keputusan dari pengadilan, yang memerintahkan Perseroan untuk membuka data-data dimaksud.</li>
                            </ul>
                        </li>
                        <li><b>SUBJEK PENGGUNA PORTAL</b><br/>
                            Bahwa istilah Pengguna yang digunakan dalam ketentuan ini merujuk pada Peraturan Otoritas Jasa Keuangan Nomor 37/POJK.04/2018 tentang Layanan Urunan Dana Melalui Penawaran Berbasis Teknologi Informasi (Equity Crowdfunding). Berdasarkan Pasal 1 POJK Equity Crowdfunding terdiri dari:
                            <ul>
                                <li><b></b><br/>

                                </li>
                            </ul>
                        </li>
                        <li><b>INFORMASI SENSITIF</b><br/>
                            Kami tidak akan mengungkapkan dan menampilkan Data Pengguna yang bersifat sensitif, misalnya, informasi yang terkait dengan asal-usul ras atau etnis, pandangan politik, agama atau kepercayaan lainnya, kesehatan atau kondisi medis, latar belakang kriminal pada Portal kami.
                        </li>
                        <li><b>HAK PERBAIKAN</b><br/>
                            Pengguna memiliki hak untuk mengubah dan memperbaruhi apabila data Pengguna tidak akurat, tidak lengkap, menyesatkan atau tidak up-to-date. Segala perubahan dan pembaharuan Data Pengguna (khususnya Penerbit) bersifat material, kami akan informasikan dalam Portal.
                        </li>
                        <li><b>PENYIMPANAN DAN PENGHAPUSAN DATA PENGGUNA (RETENSI)</b><br/>
                            Pengguna dapat meminta kepada penyelenggara untuk menghapus atau menganonimkan Data Pengguna agar tidak dapat lagi diidentifkasin lagi oleh siapapun maupun sistem elektronik kami. Kecuali apabila kami secara sah diizinkan atau diwajibkan untuk mempertahankan data Pengguna, dalam hal berikut:
                            <ul>
                                <li>Apabila pengguna masih aktif menggunakan layanan Penyelenggara;</li>
                                <li>Apabila Data Pegguna diperlukan dalam proses penyelesaian sengketa yang sedang berjalan;</li>
                                <li>Apabila kami diminta untuk menyimpan Data pengguna untuk kewajiban hukum meliputi kepentingan pajak, audit, laporan PPATK, dan laporan tahunan disampaikan kepada Otoritas Jasa Keuangan. Kami akan menyimpan data pengguna yang diperlukan selama periode yang disyaratkan berdasarkan peraturan perundang-undangan yang berlaku.</li>
                            </ul>
                        </li>
                        <li><b>PEMBAHARUAN KETENTUAN</b><br/>
                            Ketentuan ini sewaktu-waktu dapat mengalami perubahan, penyempurnaan, dan pembaharuan dalam rangka penyesuaian terhadap perkembangan operasional bisnis penyelenggara dan peraturan perundang-undangan yang berlaku.\
                        </li>
                    </ul>
                </Container>
            </main>
            </>
    )
}

export default KebijakanPrivasi