import React, {useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { useHistory, Link } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";
import {Nav, Spinner, Container, Image, Modal} from "react-bootstrap";
import { Header, Operasi } from '../../../organisms';

import "react-datepicker/dist/react-datepicker.css";

import Suggest from './Suggest'
import SuggestOperasional from './SuggestOperasional'
import CurrencyFormat from 'react-currency-format';

import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';
import postData from './postData';
import {FbPixel} from '../../../../config/Tracker/FbPixel'
import icCloseModal from '../../../../assets/svg/ic-close-modal.svg';

const IntitateFirst = () => {
  
  const { state, action } = useStateMachine(updateAction);
  const detailCampaign = JSON.parse(localStorage.getItem("detailCampaign"));
  const nextLink = "/donasi-uang/tetangga-bantu-tetangga";  
  const { push } = useHistory();
  useEffect(()=>{
    FbPixel('AddToCart');
    state.postData.operational_amount = 10000;
    state.postData.totalDonasi = parseInt(state.postData.amount === '' ? 0 : state.postData.amount) + parseInt(state.postData.operational_amount ?? 0);
    state.postData.totalDonasi.toString()
  },[])
  useEffect(() => {
    let data = {};
    if(state.postData.stepdon3 ){
      data = postData;
      data['stepdon3'] = false;
      action(data);
      push(nextLink+"/step1");
    }
  },[action, nextLink, push, state, detailCampaign])
  return null;
}


const Step1 = (props ) => {
  const linkPrev = "/tetangga-bantu-tetangga/category";
  const nextLink = "/donasi-uang/tetangga-bantu-tetangga";
  const { state, action } = useStateMachine(updateAction);
  const [show, setShow] = useState(false);
  const [showDonasi, setShowDonasi] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDonasiClose = () => setShowDonasi(false);
  const handleDonasiShow = () => setShowDonasi(true);
  const { handleSubmit, errors, register } = useForm({
    defaultValues: state.postData
  });
  const { push } = useHistory();
  const onSubmit = data => {
    nextPage(data);
  };

  const nextPage = (data) => {
    loadPaymentTypes(data);
  }

  const handleSuggestion = (value) => {
    if(props.errorMessage['amount']){
      let fieldChanges = {};
      fieldChanges = props.errorMessage;
      fieldChanges['amount'] = '';
      props.clearDispatch(fieldChanges);
    }
    let data = state.postData;
    // if(state.postData.amount < 1){
      data['amount'] = value;
      data['stepdon1'] = true;
      data['totalDonasi'] = parseInt(state.postData.operational_amount??0) + parseInt(value);
      state.postData.totalDonasi.toString()
      action(data);
    // }
    //nextPage(data);
  }
  const handleSuggestionOperasional = (value) => {
    if(props.errorMessage['operational_amount']){
      let fieldChanges = {};
      fieldChanges = props.errorMessage;
      fieldChanges['operational_amount'] = '';
      props.clearDispatch(fieldChanges);
    }
    let data = state.postData;
    // if(state.postData.operational_amount < 1){
      data['operational_amount'] = value;
      data['stepdon1'] = true;
      data['totalDonasi'] = parseInt(state.postData.amount??0) + parseInt(value);
      state.postData.totalDonasi.toString()
      action(data);
    // }
    setShowDonasi(false);
    //nextPage(data);
  }
  const handleCurrency = (field, value) => {
    let data = {};
    data[field] = value
    data['totalDonasi'] = parseInt(state.postData.operational_amount??0) + parseInt(value === '' ? 0 : value);
    state.postData.totalDonasi.toString()
    action(data);
    if(props.errorMessage[field]){
      let fieldChanges = {};
      fieldChanges = props.errorMessage;
      fieldChanges[field] = '';
      props.clearDispatch(fieldChanges);
    }
  }
  const handleCurrencyOperasional = (field, value) => {
    let data = {};
    data[field] = value === '' ? 0 : value
    data['totalDonasi'] = parseInt(state.postData.operational_amount??0) + parseInt(value === '' ? 0 : value);
    state.postData.totalDonasi.toString()
    action(data);
    if(props.errorMessage[field]){
      let fieldChanges = {};
      fieldChanges = props.errorMessage;
      fieldChanges[field] = '';
      props.clearDispatch(fieldChanges);
    }
  }

  const loadPaymentTypes = async (data) => {
    data['loadPaymentTypes'] = false;
    action(data);
    const config = {
        method: 'get',
        url: '/public/payment-types/group',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    const res = await props.API(config).catch(err=>err);
    if (res) {
      data['loadPaymentTypes'] = true;
      data['dataPaymentTypes'] = res.data;
      action(data);
      checkValues(data);
    }
  }

  const checkValues = async (data) => {
    let formData = {
      amount: data.amount,
      operational_amount: state.postData.operational_amount,
      just_validate:true
    };
    
    const config = {
        method: 'post',
        url: '/public/donation',
        headers: {
            'Content-Type': 'application/json'
        },
        data: formData
    };

    const res = await props.API(config, true, true).catch(err=>err);
    if (res) {
      data['stepdon1'] = true;
      data['resultDonation'] = {snapToken:false};
      if (state.postData.payment_type) {
        push(nextLink+"/step2");
      } else {
        push(nextLink+"/payment_channel");
      }
    }else{
      let data={};
      data['checkMessage'] = true;
      action(data);

    }
  }
  return (
    <>
      <Header title="Tetangga Bantu Tetangga" prevPage={linkPrev}/>
      <main className="donate-tbt">
        <Container className="container-donate">
          <form onSubmit={handleSubmit(onSubmit)} className="form-hook">
            <IntitateFirst/>
            <div className="txt-title">Pilih Nominal Donasi</div>
            <p className="txt-subtitle">Ingin dengan apa Anda membantu?</p>
            <div className="row">
            {/* {state.postData.amount <= 100000 &&
              <> */}
                <Suggest nominal={10000} desc="" handleSuggestion={handleSuggestion} amount={state.postData.amount}/>
                <Suggest nominal={25000} desc="" handleSuggestion={handleSuggestion} amount={state.postData.amount}/>
                <Suggest nominal={50000} desc="" handleSuggestion={handleSuggestion} amount={state.postData.amount}/>
                <Suggest nominal={100000} desc="" handleSuggestion={handleSuggestion} amount={state.postData.amount}/>
                <Suggest nominal={500000} handleSuggestion={handleSuggestion} amount={state.postData.amount}/>
                <Suggest nominal={1000000} desc="" handleSuggestion={handleSuggestion} amount={state.postData.amount}/>
              {/* </>
            } */}
            </div>
            <div className="box-nominal">
              <div className="txt-body-m ct-title">Nominal Lainnya </div>
              <div className="content-grid wrap-input-amount txt-title-m">
                <div>Rp</div>
                <div>
                  <CurrencyFormat 
                    value={state.postData.amount} 
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    onValueChange={values => {
                      handleCurrency('amount', values.value);
                    }}
                    placeholder="Masukkan Nominal"
                  />
                </div>
              </div>
              <div>
                <div>
                  <input
                  name="amount"
                  value={state.postData.amount}
                  className="hide"
                  ref={register({ required: "Nominal Donasi wajib diisi" })}
                  />
                  <ErrorMessage errors={errors} name="amount" as={<p className="error-input"></p>} />
                </div>
                {props.errorMessage.amount && 
                <p className="error-input">{props.errorMessage.amount}</p>}
              </div>
            </div>
            <div className="box-operasional">
              <h5>Donasi Operasional</h5>
              <div className="section-nominal">
                <div className="txt-nominal">
                <CurrencyFormat 
                  value={state.postData.operational_amount} 
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={'Rp '} 
                  displayType={'text'}
                  className="str-limit str-limit-1"
                />
                <input
                  name="operational_amount"
                  value={state.postData.operational_amount}
                  className="hide"
                  />
                </div>
                <div onClick={handleDonasiShow} className="btn-ganti">
                  Ganti
                </div>
              </div>
              <p>Seratus persen donasi kami salurkan ke penerima manfaat. Donasi operasional ini membantu kita mengcover biaya operasional distribusi penyaluran dan untuk menjangkau tetangga sampai pelosok negeri.</p>
              <div onClick={handleShow} className="link-operasi">Bagaimana Kami Beroperasi</div>
              <Modal show={showDonasi} animation={false} centered className="modal-bottom modal-operasi">
                <Modal.Header >
                    <Modal.Title id="contained-modal-title-vcenter">
                        <span className="txt-title">Bantu menjangkau lebih banyak tetangga</span>
                    </Modal.Title>
                    <Image src={icCloseModal} onClick={handleDonasiClose} className="cursor-pointer" /> 
                </Modal.Header>
                <Modal.Body>
                    <div className="container-donate no-border">
                        <p>Untuk menjangkau lebih, diperlukan berbagai hal seperti pelatihan relawan sampai pemeliharaan infrastruktur.</p>
                        <div className="box-donate-operasional">
                            <SuggestOperasional desc="Fasilitasi biaya penyaluran dari program ini" nominal={2500} handleSuggestionOperasional={handleSuggestionOperasional} operational_amount={state.postData.operational_amount}/>
                            <hr/>
                            <SuggestOperasional desc="Jangkau lebih banyak tetangga lagi" nominal={15000} handleSuggestionOperasional={handleSuggestionOperasional} operational_amount={state.postData.operational_amount}/>
                            <hr/>
                            <div className="row">
                              <div className="col-7">
                                <div className="desc txt-caption">Tentukan Bantuan Sendiri</div>
                              </div>
                              <div className="col-1"></div>
                              <div className="col-4">
                              </div>
                              <div className="col-8">
                                <div className={"content-flex"}>
                                    <CurrencyFormat 
                                      value={state.postData.operational_amount} 
                                      thousandSeparator={"."}
                                      decimalSeparator={","}
                                      onValueChange={values => {
                                        handleCurrencyOperasional('operational_amount', values.value);
                                      }}
                                      placeholder="Masukkan Nominal"
                                      className="form-control field-donatur-amount"
                                    />
                                </div>
                              </div>
                              <div className="col-4">
                                  <div className="suggest-donate" onClick={() => handleSuggestionOperasional(state.postData.operational_amount)}>
                                      Pilih
                                  </div>
                              </div>
                          </div>
                        </div>
                    </div>
                </Modal.Body>
              </Modal>
            </div>
            <nav>
                <Nav className="navbar">
                  {!state.postData.stepdon3 && 
                    <div className="sum-donate">
                      <div className="text-donate">
                        <p>Jumlah Donasi </p>
                        <h5>
                          <CurrencyFormat 
                            value={state.postData.totalDonasi} 
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={'Rp '} 
                            displayType={'text'}
                            className="str-limit str-limit-1"
                          />
                        </h5>
                      </div>
                      <button type="submit" className="btn btn-donate btn-block mt-0" disabled={props.isLoading || !state.postData.amount}>
                        { (!props.isLoading) && <span>Lanjutkan</span> }
                        { props.isLoading && <Spinner animation="border" role="status"/> }
                      </button>
                    </div>
                  }
                  {state.postData.stepdon3 && 
                    <Link to="/campaign" className="btn btn-donate btn-block mt-0"><span>Ayo Berdonasi Lagi</span></Link>
                  }
                </Nav>
              </nav>
          </form>
          <Operasi
              onHide={handleClose}
              show={show}
              label="Bagaimana Bantu Tetangga Beroperasi"
          />
        </Container>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
  clearDispatch: () => dispatch(clearDispatch()),
  API: (path, data, showError) => dispatch(API(path, data, showError))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step1));
