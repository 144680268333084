import React from 'react'
const Loading =  (props) => {
    return (
        <div className="main-home">
            <div className="img-cover">

            </div>
            <div className="content-space"></div>

            <div className="gradient text-loading text-loading-60 height-20"></div>
            <div className="gradient text-loading text-loading-60 height-20 mt-4"></div>

            
            <div className="gradient text-loading text-loading-60 mt-4"></div>
            <div className="gradient text-loading text-loading-100 height-30 "></div>
            
            <div className="gradient text-loading text-loading-40 mt-4"></div>
            <div className="gradient text-loading text-loading-100 height-30 "></div>
            
            <div className="gradient text-loading text-loading-20 mt-4"></div>
            <div className="gradient text-loading text-loading-100 height-30 "></div>
            
            <div className="gradient text-loading text-loading-60 mt-4"></div>
            <div className="gradient text-loading text-loading-100 height-30 "></div>
            
            <div className="gradient text-loading text-loading-30 mt-4"></div>
            <div className="gradient text-loading text-loading-100 height-100 "></div>
        </div>
    )
}

export default Loading
