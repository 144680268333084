import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { API, clearDispatch } from '../../../../config/redux/action';
import { Header } from '../../../organisms';
import logo2 from '../../../../assets/svg/logo2.svg';
import icAsk from '../../../../assets/svg/ic-ask.svg';
import icArrowRight from '../../../../assets/svg/ic-arrow-right-gray.svg';

import Loading from './Loading';
import './style.css';
import { Button, Container, Image } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import Moment from 'react-moment';

class DetailDonasi extends React.Component {
    constructor(props) {
        super(props)

        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = auth && auth.access_token ? `Bearer ${auth.access_token}` : null;

        this.state = { donation: {}, isFetching: true, token }
    }
    async apiResult () {
        const { API } = this.props
        const config = {
            method: 'get',
            url: '/donation/detail/' + this.props.match.params.code,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            }
        };
        const res = await API(config, false).catch(err=>err);
        if (res) {
            this.setState({ donation: res.data });
        }
        this.setState({ isFetching: false });
    }
    componentDidMount() {
        if(this.state.token) {
            this.apiResult()
        } else {
            this.props.history.push('/login')
        }
    }
    render() {
        const status = { 
            pending: 'Menunggu Pembayaran',
            paid: 'Diterima',
            expired: 'Dibatalkan',
        }
        const donate = this.state.donation;
        return (
            <React.Fragment>
                <Header title="Bukti Donasi" prevPage="/mydonate" />
                <main className="bg-gray">
                    <Container className="no-padding txt-body-m bukti-donasi">
                        <div className="top-logo">
                            <Image src={logo2}/>
                        </div>
                        
                        {!this.state.isFetching ? 
                            <>
                            <div className="amount txt-body-m">
                                <div className="txt-caption">Nominal Donasiss</div>
                                <div className="txt-title fw-6">
                                    <CurrencyFormat 
                                        value={donate.amount}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        prefix={'Rp '} 
                                        displayType={'text'}
                                    />
                                </div>
                            </div>
                            <div className="section pm-0 mt-10">
                                <div className="txt-caption mb-1">Donatur</div>
                                <div className="txt-body-m fw-6 ct-title">{donate.donor_name}</div>
                                <div className="txt-caption ct-body mb-20">{donate.contact.type} {donate.contact.value}</div>

                                <div className="txt-caption mb-1">Program</div>
                                <div className="txt-body-m fw-6 ct-title">{donate.campaign}</div>
                                <div className="txt-caption ct-body">{donate.campaigner.name}</div>

                                <hr></hr>

                                <div className="content-grid grid-2 mb-2">
                                    <div>Status</div>
                                    <div className={"fw-6 "+donate.status}>{status[this.state.donation.status]}</div>
                                </div>
                                <div className="content-grid grid-2 mb-2">
                                    <div>Tanggal Donasi</div>
                                    <div className="fw-6">
                                        <Moment format=" D MMM YYYY - h:mm">
                                            {donate.created_at}
                                        </Moment>
                                    </div>
                                </div>
                                <div className="content-grid grid-2 mb-2">
                                    <div>Kode Donasi</div>
                                    <div className="fw-6">{donate.code}</div>
                                </div>
                                <div className="content-grid grid-2 mb-2">
                                    <div>Pembayaran</div>
                                    <div className="fw-6">{donate.payment_method}</div>
                                </div>
                            
                            </div>
                            </>
                            :
                            <Loading/>
                        }
                    </Container>
                    <Container className="no-padding txt-body-m bukti-donasi">
                        <div className="section mt-10">
                            
                            <Link to={"/faq?prevPage=mydonate/"+donate.code} className="content-grid grid-2 mt-2 mb-1">
                                <div>
                                    <Image src={icAsk} className="mr-2"/>
                                    Bantuan
                                </div>
                                <div><Image src={icArrowRight}/></div>
                            </Link>
                        </div>
                    </Container>
                    <Container>
                        {donate.status === 'pending' && 
                            <Button href={"/payment-guide/"+donate.code} variant="primary" block>
                                Petunjuk Pembayaran
                            </Button>
                        }
                        <Button href={"/campaign/"+donate.campaign_slug+"?prevPage=mydonate/"+donate.code} variant={donate.status === 'pending' ? 'secondary' : 'primary'} block>
                            Lihat Program
                        </Button>
                    </Container>
                </main>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailDonasi));