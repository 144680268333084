import moment from 'moment';
import React from 'react'
import LazyLoad from 'react-lazyload';

export default function BlogCard({ blog, classes }) {
    const defaultClasses = {
        root: 'blog-card',
        cardImage: 'blog-card-image',
        cardInfo: 'blog-card-info',
        cardName: 'blog-card-name',
        progressInfo: 'blog-card-progress-info',
        progressPercentage: 'blog-card-progress-percentage',
        progressNominal: 'blog-card-progress-nominal',
        progressInfoLeft: 'blog-card-progress-info-left',
        progressInfoRight: 'blog-card-progress-info-right',
    }

    const ovClasses = { ...defaultClasses, ...classes ? classes : {} }

    return (
        <a className={ovClasses.root} href={blog.link} target={'_blank'} rel="noreferrer">
            <LazyLoad>
                <img className={ovClasses.cardImage} src={blog?.yoast_head_json?.og_image[0]?.url} alt={blog?.title?.rendered} />
            </LazyLoad>
            <div className={ovClasses.cardInfo}>
                <h5 className={ovClasses.cardName}>{blog?.title?.rendered}</h5>
                <p className="txt-date">{moment(blog?.date).fromNow()}</p>
            </div>
        </a>
    )
}