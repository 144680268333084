import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import { Container,Image } from 'react-bootstrap';
import "./style.css"
import { withRouter } from "react-router";
import Moment from 'react-moment';

import logo from "../../../assets/svg/logo-profile.svg";
import icHelp from "../../../assets/svg/ic-help.svg";
import icAboutCp from "../../../assets/svg/logo-icon.svg";
import icFile from "../../../assets/svg/ic-file.svg";
import icLaporan from "../../../assets/svg/ic-laporan.svg";
import icSetting from "../../../assets/svg/ic-setting.svg";
import icKebijakanPrivacy from "../../../assets/svg/ic-kebijakan-privacy.svg";
import icLove from "../../../assets/svg/ic-love.svg";
import icToa from "../../../assets/svg/ic-toa.svg";
import icFlag from "../../../assets/svg/ic-flag.svg";
import icMyDonate from "../../../assets/svg/ic-mydonate.svg";
import icMyDisburment from "../../../assets/svg/ic-mydisburment.svg";
import icMyDisburmentDisabled from "../../../assets/svg/ic-mydisburment-disabled.svg";
import icMyCampaign from "../../../assets/svg/ic-mycampaign.svg";
import avatar from "../../../assets/img/avatar/no-img.png";
import icCertified from '../../../assets/svg/ic-certified.svg';
import icChevronRight from '../../../assets/svg/ic-chevron-right.svg';

import { connect } from 'react-redux';
import { reloadUser } from '../../../config/redux/action';

//Load Component
import {Header, NavMenu} from '../../../containers/organisms';
import {AccountMenu} from '../../../component/molecules';
import Loading from './Loading';

class AkunSaya extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            auth: false,
            loadProfile:false
        }
    }

    updateAuth = async (auth) => {
        this.setState({loadProfile:true});
        const access_token = `Bearer ${auth.access_token}`;
        const config = {
            method: 'get',
            url: '/profile',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': access_token
            }
        };
        const res = await this.props.reloadUser(config, auth).catch(err=>err);
        if (res) {
            localStorage.setItem('userData', JSON.stringify(res));
            this.setState({loadProfile:false,auth:res})
        }
    }
    componentDidMount() {
        if(localStorage.getItem("userData")){
            const userdata = JSON.parse(localStorage.getItem("userData"))
            this.updateAuth(userdata);
        }
        // else{
        //     const {history} = this.props;
        //     history.push('/login');
        // }
    }
    render() {
        const {loadProfile, auth} = this.state;
        const userdata = JSON.parse(localStorage.getItem("userData"))
        return(
            <Fragment>
                {!userdata ? 
                    <main className="akun-saya">
                        <Container className="splash-screen no-padding">
                        <div className="section txt-body-m mb-10 result-donation">
                            <div className="content-grid grid-1-9">
                                <div><Image src={logo}/></div>
                                <div className="text-left">
                                    <div className="txt-subtitle-m fw-6 ct-title">Salam, Tetangga</div>
                                    <div className="txt-body-m">Silakan masuk untuk menikmati kemudahan berdonasi.</div>
                                </div>
                            </div>
                            <Link to={"/login?prevPage=profile"} className="btn btn-primary btn-block mt-4">Masuk</Link>
                            <div className="text-center mt-10 mb-10">Belum punya akun? <Link to="/register?prevPage=profile" className="fw-6">Daftar Sekarang</Link></div>
                        </div>
                        <div className="content-space"></div>
                        
                        <div className="section account_menus">
                            <AccountMenu link="/tentang-kami" icon={icAboutCp} name="Tentang Bantu Tetangga"/>
                            <AccountMenu link="/faq" icon={icHelp} name="Pusat Bantuan"/>
                            <AccountMenu link="/syarat-ketentuan" icon={icFile} name="Syarat & Ketentuan"/>
                            <AccountMenu link="/kebijakan-privasi" icon={icKebijakanPrivacy} name="Kebijakan Privasi"/>
                        </div>
                        </Container>
                    </main>
                    :

                    <>
                        <Header title="Profile Saya" prevPage={false} cols={1}/>
                        <main className="akun-saya">
                            <Container>
                                {(!loadProfile && auth) ? 
                                    <>
                                        <div className="card-profile">
                                            <div className="penggalang-crop"><Image src={!auth.photo ? avatar : auth.photo}/></div>
                                            <div className="text-left">
                                                <Link to="/pengaturan">
                                                    <div className="txt-subtitle-m fw-6 ct-title">{auth.name}</div>
                                                    <div className="txt-caption-m">
                                                        Aktif sejak
                                                        <Moment format=" D MMMM YYYY">
                                                            {auth.created_at}
                                                        </Moment>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <Link to={(!auth.campaigner ? "/profile" : "/campaigner/"+auth.campaigner.slug+"?prevPage=profile")}>
                                            <div className="category-profile">
                                                <div><Image src={icFlag}/></div>
                                                <div className="txt-body-m fw-6">
                                                    {!auth.campaigner ?
                                                        
                                                        <>Donatur Reguler</>
                                                        : 
                                                        <>
                                                            {auth.campaigner.name}
                                                            {auth.campaigner.approved_at &&
                                                                <Image className="certified" src={icCertified}/>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                                <div>{auth.campaigner && <Image src={icChevronRight}/>}</div>
                                            </div>
                                        </Link>
                                        {auth.campaigner && 
                                            <>
                                                <div className="main-menu">
                                                    <div>
                                                        <Link to="/mydonate">
                                                            <Image src={icMyDonate}/>
                                                            <div className="txt-body-m mt-1 ct-title">Donasi Saya</div>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link to={(!auth.campaigner ? "/profile" : "/pencairan")}>
                                                            <Image src={(!auth.campaigner ? icMyDisburmentDisabled : icMyDisburment)}/>
                                                            <div className={"txt-body-m ct-title"+(!auth.campaigner ? " ct-disabled" : "")}>Riwayat Pencairan</div>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link to="/campaign-saya">
                                                            <Image src={icMyCampaign}/>
                                                            <div className="txt-body-m mt-1 ct-title">Campaign Saya</div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <hr className="hr-am"/>
                                        <div className="account_menus">
                                            {!auth.campaigner && 
                                                <>
                                                    <AccountMenu link="/mydonate" icon={icLove} name="Donasi Saya"/>
                                                    <AccountMenu link="/galang-dana" icon={icToa} name="Jadi Penggalang Dana"/>
                                                </>
                                            }
                                            <AccountMenu link="/tentang-kami" icon={icAboutCp} name="Tentang Bantu Tetangga"/>
                                            <AccountMenu link="/laporan" icon={icLaporan} name="Laporan"/>
                                            <AccountMenu link="/pengaturan" icon={icSetting} name="Pengaturan"/>
                                            <AccountMenu link="/faq" icon={icHelp} name="Pusat Bantuan"/>
                                        </div>
                                    </>
                                    :
                                    <Loading/>
                                }
                            </Container>
                        </main>
                    </>

                }

                <NavMenu/>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
    reloadUser: (config, auth) => dispatch(reloadUser(config, auth))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AkunSaya));