import React, {Fragment} from 'react';
import { Container, Spinner, Image } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { withRouter } from "react-router";
import { toast } from 'react-toastify';
import queryString from 'query-string'
import logo from "../../../../assets/svg/logo-footer.svg"

import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';
import { ExternalLink } from 'react-external-link';

const FormPost = props => {
    const { handleSubmit, register, errors } = useForm();
    return (
        <form className="form-hook mt-4 mb-2" onSubmit={handleSubmit(props.handleSubmit)}>
            <div className="form-group">
                <label className="form-label">Password</label>
                <div className="input-group">
                    <input
                    type={(props.isPasswordShown) ? "text" : "password"}
                    name="password"
                    className="form-control"
                    ref={register({ required: "Password wajib diisi" })}
                    onChange={props.handleChange}
                    />
                    <div className="cursor-pointer input-group-prepend" onClick={props.togglePasswordVisibility}>
                        <span className="input-group-text">
                            <Image src={(props.isPasswordShown) ? "assets/img/eye-off-line.svg" : "assets/img/eye-open.svg"} className=""/>    
                        </span>
                    </div>
                </div>
                
                <ErrorMessage errors={errors} name="password" as={<p className="error-input text-left"></p>} />
                {props.errorMessage.password && 
                <p className="error-input">{props.errorMessage.password}</p>}
            </div>
            
            <div className="form-group">
                <label className="form-label">Konfirmasi Password</label>
                <div className="input-group">
                    <input
                    type={(props.isPasswordConfirmShown) ? "text" : "password"}
                    name="password_confirmation"
                    className="form-control"
                    ref={register({ required: "Konfirmasi Password wajib diisi" })}
                    onChange={props.handleChange}
                    />
                    <div className="cursor-pointer input-group-prepend" onClick={props.togglePasswordConfirmVisibility}>
                        <span className="input-group-text">
                            <Image src={(props.isPasswordConfirmShown) ? "assets/img/eye-off-line.svg" : "assets/img/eye-open.svg"} className=""/>    
                        </span>
                    </div>
                </div>
                
                <ErrorMessage errors={errors} name="password_confirmation" as={<p className="error-input text-left"></p>} />
                {props.errorMessage.password_confirmation && 
                <p className="error-input">{props.errorMessage.password_confirmation}</p>}
            </div>

            <button className="btn btn-primary btn-block" type="submit" disabled={props.isLoading}>
                { (!props.isLoading) && <span>Simpan</span> }
                { props.isLoading && <Spinner animation="border" role="status"/> }
            </button>
        </form>
    );
}
class ResetPassword extends React.Component {
    
    state = {
        formPost: {},
        isPasswordShown:false,
        isPasswordConfirmShown:false,
    }
    handleFormChange = (e) =>{
        let formPostNew = {...this.state.formPost};
        formPostNew[e.target.name] = e.target.value;

        let errorMessageNew = this.props.errorMessage;
        if(errorMessageNew.length > 0){
        }else{
            if(formPostNew[e.target.name]){
                errorMessageNew[e.target.name] = '';
            }
        }

        this.setState({
            formPost: formPostNew,
            errorMessage: errorMessageNew
        })
    }

    handleSubmit = async () => {
        const {history} = this.props;
        const config = {
            method: 'post',
            url: '/auth/reset_password',
            headers: {
                'Content-Type': 'application/json',
            },
            data: this.state.formPost
        };
        const res = await this.props.API(config).catch(err=>err);
        if (res) {
            toast.info('Berhasil mengubah password', {
                position: "top-center",
                hideProgressBar: true,
                autoClose:3000,
            });

            setTimeout(() =>{
                history.push('/login');
            },1000 );
        }
    }
    togglePasswordVisibility = () => {
        const {isPasswordShown} = this.state;
        this.setState({isPasswordShown: !isPasswordShown});
    }
    togglePasswordConfirmVisibility = () => {
        const {isPasswordConfirmShown} = this.state;
        this.setState({isPasswordConfirmShown: !isPasswordConfirmShown});
    }

    componentDidMount(){
        this.props.clearDispatch();

        const queryParams = queryString.parse(this.props.location.search)
        const token = queryParams.token;
        this.setState({formPost:{token:token}});
    }

    render() {
        const {isPasswordShown, isPasswordConfirmShown} = this.state;
        return(
            <Fragment>
                <main className="no-header">
                <Container className="container-login">
                    <div className="text-center mt-3">
                        <Image src={logo} width="150" rounded />
                    </div>
                    <div className="txt-body-m ct-title fw-6 mt-4 mb-2">Akun Anda berhasil diverifikasi<br/>Silahkan masukan password baru anda.</div>
                    <FormPost 
                        handleChange={this.handleFormChange} 
                        errorMessage={this.props.errorMessage}
                        handleSubmit={this.handleSubmit}
                        isLoading={this.props.isLoading}
                        isPasswordShown={isPasswordShown}
                        isPasswordConfirmShown={isPasswordConfirmShown}
                        togglePasswordVisibility = {this.togglePasswordVisibility}
                        togglePasswordConfirmVisibility = {this.togglePasswordConfirmVisibility}
                    />
                    <div className="text-center pt-1">
                        <p className="mt-4">Memiliki Kendala? <ExternalLink href={"https://wa.me/"+process.env.REACT_APP_WA_CS}>Hubungi Kami</ExternalLink></p>
                    </div>
                </Container>
            </main>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));