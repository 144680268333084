import React from 'react'

const Loading =  (props) => {
    return (

        <div className="content-news">
            <div></div>
            <div>
                <div className="content-grid grid-2">
                    <div>
                        <div className="gradient text-loading text-loading-60"></div>
                    </div>
                    <div>
                        <div className="gradient text-loading text-loading-40"></div>
                    </div>
                </div>

                <div className="wrap-content-news">
                    <div className="fw-7">
                        <div className="gradient text-loading text-loading-80"></div>
                    </div>
                    <div className="pb-3">
                        <div className="gradient cover-loading"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Loading
