import React, {useEffect} from 'react';
import { Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import postData from "./postData";
import {useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";
import * as QueryString from "query-string";
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';

const Loading = (props) => {
  const { state, action } = useStateMachine(updateAction);
  const { push, location } = useHistory();
  const qs = QueryString.parse(location.search);
  const queryParamsEdit = (qs.edit ? qs.edit : '');
  const queryParams = (queryParamsEdit ? ('?edit='+queryParamsEdit) : '');
	const pathArr = location.pathname.split('/');
  const uri = pathArr[pathArr.length - 1];
  useEffect(() => {
    if(queryParams !== ''){
      if(localStorage.getItem("detailCampaign")){
        if(uri === 'galang-dana'){
          const detailCampaign = JSON.parse(localStorage.getItem("detailCampaign"))
          let data = {};
          data['title'] = detailCampaign.title;
          data['slug'] = detailCampaign.slug;
          data['body'] = detailCampaign.body;
          data['status'] = detailCampaign.status;
          data['target'] = detailCampaign.target;
          data['budget_plan'] = detailCampaign.budget_plan;
          data['distribution_address'] = detailCampaign.distribution_address;
          data['provinsi'] = (detailCampaign.areas.province_id ? detailCampaign.areas.province_id.id : null);
          data['kota'] = (detailCampaign.areas.city_id ? detailCampaign.areas.city_id.id : null);
          data['kecamatan'] = (detailCampaign.areas.district_id ? detailCampaign.areas.district_id.id : null);
          data['area_id'] = detailCampaign.area_id;
          data['campaigner_id'] = detailCampaign.campaigner.id;
          data['cover'] = detailCampaign.cover_filename;
          data['base64'] = {cover:detailCampaign.cover};
          data['category_id'] = detailCampaign.category_slug;
          data['publish_at'] = detailCampaign.publish_at;
          data['publish_at_date'] = formatDate(detailCampaign.publish_at);
          data['expired_at'] = detailCampaign.expired_at;
          data['expired_at_date'] = formatDate(detailCampaign.expired_at);
          data['edit'] = true;
          data['editCampaign'] = queryParams;
          data['editCampaignSlug'] = queryParamsEdit;
          data['backPage'] = 0;
          data['stepgal1'] = true;
          data['stepgal2'] = true;
					data['stepgal3'] = true;
					data['stepgal4'] = true;
					data['stepgal5'] = true;
					data['prevPage'] = (location.search? location.search : '');
          action(data);
          
          if(!state.postData.editCampaign && !state.postData.editCampaignSlug){
            loadCategories();
            apiGetArea('Provinsi');
            apiGetArea('Kota', data['provinsi']);
						apiGetArea('Kecamatan', data['kota']);
          }
          if(state.postData.dataCategory){
              data['backPage'] = 1;
              action(data);
              push("/galang-dana/step1"+location.search);
          }
        }
      }
    }else{
      if(uri === 'galang-dana'){
        if(state.postData.editCampaign && state.postData.editCampaignSlug){
          action(postData);
        }
      }

    }
  }, [queryParams]);

  const formatDate = (date) => {
    let d = (date ? new Date(date) : new Date());
    let mm = d.getMonth() + 1;
    let dd = d.getDate();
    let yy = d.getFullYear(); 
    if(mm < 10)
      mm = '0' + mm
    if(dd < 10)
      dd = '0' + dd
    let myDateString = yy + '-' + mm + '-' + dd;
    return myDateString;
  }

  const apiGetArea = async (type, parent=null) => {
    const auth = JSON.parse(localStorage.getItem("userData"));
    const token = `Bearer ${auth.access_token}`;

    let params = '?type='+(type === 'Kota' ? 'Kabupaten/Kota' : type);
    params += ((type === 'Kota' || type === 'Kecamatan') ? '&parent='+parent : '');
    const config = {
        method: 'get',
        url: '/area'+params,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    };
    const res = await props.API(config, false).catch(err=>err);
    if (res) {
        let data = {};
        data['load'+type] = true;
        data['data'+type] = res?.data?.data;
        action(data);
    }
  }

  const loadCategories = async () => {
    const auth = JSON.parse(localStorage.getItem("userData"));
    const token = `Bearer ${auth.access_token}`;
    const config = {
        method: 'get',
        url: '/category',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    };
    const res = await props.API(config, false).catch(err=>err);
    if (res) {
        let data = {};
        data['loadCategory'] = true;
        data['dataCategory'] = res.data.data;
        action(data);
    }
	}
	
	return (
		<>
				<main>  
						<Container>
								<div className="main-home">
										<div className="img-cover">

										</div>
										<div className="content-space"></div>

										<div className="gradient text-loading text-loading-60 height-20"></div>
										<div className="gradient text-loading text-loading-60 height-20 mt-4"></div>

										
										<div className="gradient text-loading text-loading-60 mt-4"></div>
										<div className="gradient text-loading text-loading-100 height-30 "></div>
										
										<div className="gradient text-loading text-loading-40 mt-4"></div>
										<div className="gradient text-loading text-loading-100 height-30 "></div>
										
										<div className="gradient text-loading text-loading-20 mt-4"></div>
										<div className="gradient text-loading text-loading-100 height-30 "></div>
										
										<div className="gradient text-loading text-loading-60 mt-4"></div>
										<div className="gradient text-loading text-loading-100 height-30 "></div>
										
										<div className="gradient text-loading text-loading-30 mt-4"></div>
										<div className="gradient text-loading text-loading-100 height-100 "></div>
								</div>

						</Container>
				</main>
		</>
	)
}

const mapStateToProps = (state) => ({
  errorMessage: state.errorMessage,
})

const mapDispatchToProps = (dispatch) => ({
  clearDispatch: () => dispatch(clearDispatch()),
  API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Loading));
