import React from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { useHistory, Redirect } from "react-router-dom";
import {Image} from "react-bootstrap";

import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";

import UploadFile from "./UploadFile";
import RedirectStep from "./RedirectStep";

import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { clearDispatch, API, reloadUser } from '../../../../config/redux/action';
import { Header, Wizard } from '../../../organisms';
import IcWarningYellow from '../../../../assets/svg/ic-warning-new.svg';
import borderProfil from '../../../../assets/svg/border-photo-profile.svg';
import borderPhoto from '../../../../assets/svg/border-photo.svg';

const Step3 = props => {

  const linkPrev = "/verifikasi-komunitas/step2";
  const { state, action } = useStateMachine(updateAction);
  const { handleSubmit, register, errors } = useForm({
    defaultValues: state.postData
  });
  const { push } = useHistory();
  const onSubmit = async (data) => {
    data['step3'] = true;
    action(data);
    push("/verifikasi-komunitas/step4");
  };

  const hideBtnSubmit = () => {
    let data = {};
    action(data);
  }
  const returnFile = (file) => {
    let data = {};
    data[file.field] = file.file_name;
    action(data);
  }
  
  const stateTax = state.postData.tax;
  const stateCampaignerImage = state.postData.campaigner_image;
  const stateIdentity = state.postData.identity;
  const stateIdentityMember1 = state.postData.identity_member_1;
  const stateIdentityMember2 = state.postData.identity_member_2;
  const stateIdentityMember3 = state.postData.identity_member_3;
  const stateIdentityMember4 = state.postData.identity_member_4;
  const stateIdentityFace = state.postData.identity_face;
  const stateIdentityFaceMember1 = state.postData.identity_face_member_1;
  const stateIdentityFaceMember2 = state.postData.identity_face_member_2;
  const stateIdentityFaceMember3 = state.postData.identity_face_member_3;
  const stateIdentityFaceMember4 = state.postData.identity_face_member_4;

  const icon_tax = (state.postData.tax ? state.postData.tax : false);
  const icon_campaigner_image = (state.postData.campaigner_image ? state.postData.campaigner_image : false);
  const icon_identity = (state.postData.identity ? state.postData.identity : false);
  const icon_identity_member_1 = (state.postData.identity_member_1 ? state.postData.identity_member_1 : false);
  const icon_identity_member_2 = (state.postData.identity_member_2 ? state.postData.identity_member_2 : false);
  const icon_identity_member_3 = (state.postData.identity_member_3 ? state.postData.identity_member_3 : false);
  const icon_identity_member_4 = (state.postData.identity_member_4 ? state.postData.identity_member_4 : false);
  const icon_identity_face = (state.postData.identity_face ? state.postData.identity_face : false);
  const icon_identity_face_member_1 = (state.postData.identity_face_member_1 ? state.postData.identity_face_member_1 : false);
  const icon_identity_face_member_2 = (state.postData.identity_face_member_2 ? state.postData.identity_face_member_2 : false);
  const icon_identity_face_member_3 = (state.postData.identity_face_member_3 ? state.postData.identity_face_member_3 : false);
  const icon_identity_face_member_4 = (state.postData.identity_face_member_4 ? state.postData.identity_face_member_4 : false);
  const initialSteps = {
    class1:"",
    number1:"2",
    label1:"Lokasi",
    link1:"/verifikasi-komunitas/step2",

    class2:"step-active",
    number2:3,
    label2:"Dokumen",
    link2:"/verifikasi-komunitas/step3",

    class3:"",
    number3:"4",
    label3:"Ringkasan",
    link3:"/verifikasi-komunitas/step4",
  }
  return (
    <>
      {(!state.postData.step2 && !state.postData.step3) && 
        <Redirect to="/verifikasi-komunitas/step2" />
      }
      <Header title="Verifikasi Penggalang" prevPage={linkPrev} ic_white={true}/>
      <Wizard initialSteps={initialSteps} />
      <form onSubmit={handleSubmit(onSubmit)} className="form-hook">
        <div className="mb-15">
          <UploadFile 
            bingkai={borderProfil}
            title="Foto Profil" 
            field="campaigner_image" 
            fileName="PP_" 
            icon={icon_campaigner_image} 
            classFile={stateCampaignerImage ? 'file' : ''} 
            classCoverImg={stateCampaignerImage ? 'cover-img' : 'cover-img border-radius50'} 
            info={"format .jpg atau .png maksimal 2MB"}
            returnFile={returnFile} 
            hideBtnSubmit={hideBtnSubmit} />
          <input name="campaigner_image" type="hidden" 
            ref={register({ required: "Foto Profil Penggalang belum diupload" })} 
            value={state.postData.campaigner_image}/>
          <ErrorMessage errors={errors} name="campaigner_image" as={<p className="error-input"></p>} />
          {state.postData.errorMessages.campaigner_image && 
          <p className="error-input">{state.postData.errorMessages.campaigner_image}</p>}
        </div>

        <div className="mb-15">
          <UploadFile 
            bingkai={borderPhoto}
            title="Foto KTP Ketua" 
            field="identity" 
            fileName="KTPPIC_" 
            icon={icon_identity} 
            classFile={stateIdentity ? 'file' : ''} 
            classCoverImg={stateIdentity ? 'cover-img' : 'cover-img border-radius50'} 
            info={"format .jpg atau .png maksimal 2MB"}
            returnFile={returnFile} 
            hideBtnSubmit={hideBtnSubmit} />
          <input name="identity" type="hidden" 
            ref={register({ required: "KTP belum diupload" })} 
            value={state.postData.identity}/>
          <ErrorMessage errors={errors} name="identity" as={<p className="error-input"></p>} />
          {state.postData.errorMessages.identity && 
          <p className="error-input">{state.postData.errorMessages.identity}</p>}
        </div>

        <div className="mb-15">
          <UploadFile 
            bingkai={borderPhoto}
            title="NPWP Ketua Komunitas" 
            field="tax" 
            fileName="NPWP_" 
            icon={icon_tax} 
            classFile={stateTax ? 'file' : ''} 
            classCoverImg={stateTax ? 'cover-img' : 'cover-img border-radius50'} 
            info={"format .jpg atau .png maksimal 2MB"}
            returnFile={returnFile} 
            hideBtnSubmit={hideBtnSubmit} />
          <input name="tax" type="hidden" 
            ref={register({ required: "NPWP belum diupload" })} 
            value={state.postData.tax} />
          <ErrorMessage errors={errors} name="tax" as={<p className="error-input"></p>} />
          {state.postData.errorMessages.tax && 
          <p className="error-input">{state.postData.errorMessages.tax}</p>}
        </div>

        <div className="mb-15">
          <UploadFile 
            bingkai={borderPhoto}
            title="KTP Anggota 1" 
            field="identity_member_1" 
            fileName="KTPPIC_" 
            icon={icon_identity_member_1} 
            classFile={stateIdentityMember1 ? 'file' : ''} 
            classCoverImg={stateIdentityMember1 ? 'cover-img' : 'cover-img border-radius50'} 
            info={"format .jpg atau .png maksimal 2MB"}
            returnFile={returnFile} 
            hideBtnSubmit={hideBtnSubmit} />
          <input name="identity_member_1" type="hidden" 
            ref={register({ required: "KTP belum diupload" })} 
            value={state.postData.identity_member_1}/>
          <ErrorMessage errors={errors} name="identity_member_1" as={<p className="error-input"></p>} />
          {state.postData.errorMessages.identity_member_1 && 
          <p className="error-input">{state.postData.errorMessages.identity_member_1}</p>}
        </div>

        <div className="mb-15">
          <UploadFile
            bingkai={borderPhoto} 
            title="KTP Anggota 2" 
            field="identity_member_2" 
            fileName="KTPPIC_" 
            icon={icon_identity_member_2} 
            classFile={stateIdentityMember2 ? 'file' : ''} 
            classCoverImg={stateIdentityMember2 ? 'cover-img' : 'cover-img border-radius50'} 
            info={"format .jpg atau .png maksimal 2MB"}
            returnFile={returnFile} 
            hideBtnSubmit={hideBtnSubmit} />
          <input name="identity_member_2" type="hidden" 
            ref={register({ required: "KTP belum diupload" })} 
            value={state.postData.identity_member_2}/>
          <ErrorMessage errors={errors} name="identity_member_2" as={<p className="error-input"></p>} />
          {state.postData.errorMessages.identity_member_2 && 
          <p className="error-input">{state.postData.errorMessages.identity_member_2}</p>}
        </div>

        <div className="mb-15">
          <UploadFile 
            bingkai={borderPhoto}
            title="KTP Anggota 3" 
            field="identity_member_3" 
            fileName="KTPPIC_" 
            icon={icon_identity_member_3} 
            classFile={stateIdentityMember3 ? 'file' : ''} 
            classCoverImg={stateIdentityMember3 ? 'cover-img' : 'cover-img border-radius50'} 
            info={"format .jpg atau .png maksimal 2MB"}
            returnFile={returnFile} 
            hideBtnSubmit={hideBtnSubmit} />
          <input name="identity_member_3" type="hidden" 
            ref={register({ required: "KTP belum diupload" })} 
            value={state.postData.identity_member_3}/>
          <ErrorMessage errors={errors} name="identity_member_3" as={<p className="error-input"></p>} />
          {state.postData.errorMessages.identity_member_3 && 
          <p className="error-input">{state.postData.errorMessages.identity_member_3}</p>}
        </div>

        <div className="mb-15">
          <UploadFile 
            bingkai={borderPhoto}
            title="KTP Anggota 4" 
            field="identity_member_4" 
            fileName="KTPPIC_" 
            icon={icon_identity_member_4} 
            classFile={stateIdentityMember1 ? 'file' : ''} 
            classCoverImg={stateIdentityMember1 ? 'cover-img' : 'cover-img border-radius50'} 
            info={"format .jpg atau .png maksimal 2MB"}
            returnFile={returnFile} 
            hideBtnSubmit={hideBtnSubmit} />
          <input name="identity_member_4" type="hidden" 
            ref={register({ required: "KTP belum diupload" })} 
            value={state.postData.identity_member_4}/>
          <ErrorMessage errors={errors} name="identity_member_4" as={<p className="error-input"></p>} />
          {state.postData.errorMessages.identity_member_4 && 
          <p className="error-input">{state.postData.errorMessages.identity_member_4}</p>}
        </div>

        <div className="mb-15">
          <UploadFile
            bingkai={borderPhoto} 
            title="Foto Selfie Ketua dengan KTP" 
            field="identity_face" 
            fileName="SKK_" 
            icon={icon_identity_face} 
            classFile={stateIdentityFace ? 'file' : ''} 
            classCoverImg={stateIdentityFace ? 'cover-img' : 'cover-img border-radius50'} 
            info={"format .jpg atau .png maksimal 2MB"}
            returnFile={returnFile} 
            hideBtnSubmit={hideBtnSubmit} />
          <input name="identity_face" type="hidden" 
            ref={register({ required: "Foto + E-KTP belum diupload" })} 
            value={state.postData.identity_face} />
          <ErrorMessage errors={errors} name="identity_face" as={<p className="error-input"></p>} />
          {state.postData.errorMessages.identity_face && 
          <p className="error-input">{state.postData.errorMessages.identity_face}</p>}
        </div>

        <div className="mb-15">
          <UploadFile
            bingkai={borderPhoto} 
            title="Foto Anggota 1 dengan KTP" 
            field="identity_face_member_1" 
            fileName="SKK_" 
            icon={icon_identity_face_member_1} 
            classFile={stateIdentityFaceMember1 ? 'file' : ''} 
            classCoverImg={stateIdentityFaceMember1 ? 'cover-img' : 'cover-img border-radius50'} 
            info={"format .jpg atau .png maksimal 2MB"}
            returnFile={returnFile} 
            hideBtnSubmit={hideBtnSubmit} />
          <input name="identity_face_member_1" type="hidden" 
            ref={register({ required: "Foto + E-KTP belum diupload" })} 
            value={state.postData.identity_face_member_1} />
          <ErrorMessage errors={errors} name="identity_face_member_1" as={<p className="error-input"></p>} />
          {state.postData.errorMessages.identity_face_member_1 && 
          <p className="error-input">{state.postData.errorMessages.identity_face_member_1}</p>}
        </div>

        <div className="mb-15">
          <UploadFile
            bingkai={borderPhoto} 
            title="Foto Anggota 2 dengan KTP" 
            field="identity_face_member_2" 
            fileName="SKK_" 
            icon={icon_identity_face_member_2} 
            classFile={stateIdentityFaceMember2 ? 'file' : ''} 
            classCoverImg={stateIdentityFaceMember2 ? 'cover-img' : 'cover-img border-radius50'} 
            info={"format .jpg atau .png maksimal 2MB"}
            returnFile={returnFile} 
            hideBtnSubmit={hideBtnSubmit} />
          <input name="identity_face_member_2" type="hidden" 
            ref={register({ required: "Foto + E-KTP belum diupload" })} 
            value={state.postData.identity_face_member_2} />
          <ErrorMessage errors={errors} name="identity_face_member_2" as={<p className="error-input"></p>} />
          {state.postData.errorMessages.identity_face_member_2 && 
          <p className="error-input">{state.postData.errorMessages.identity_face_member_2}</p>}
        </div>

        <div className="mb-15">
          <UploadFile
            bingkai={borderPhoto} 
            title="Foto Anggota 3 dengan KTP" 
            field="identity_face_member_3" 
            fileName="SKK_" 
            icon={icon_identity_face_member_3} 
            classFile={stateIdentityFaceMember3 ? 'file' : ''} 
            classCoverImg={stateIdentityFaceMember3 ? 'cover-img' : 'cover-img border-radius50'} 
            info={"format .jpg atau .png maksimal 2MB"}
            returnFile={returnFile} 
            hideBtnSubmit={hideBtnSubmit} />
          <input name="identity_face_member_3" type="hidden" 
            ref={register({ required: "Foto + E-KTP belum diupload" })} 
            value={state.postData.identity_face_member_3} />
          <ErrorMessage errors={errors} name="identity_face_member_3" as={<p className="error-input"></p>} />
          {state.postData.errorMessages.identity_face_member_3 && 
          <p className="error-input">{state.postData.errorMessages.identity_face_member_3}</p>}
        </div>

        <div className="mb-15">
          <UploadFile
            bingkai={borderPhoto} 
            title="Foto Anggota 4 dengan KTP" 
            field="identity_face_member_4" 
            fileName="SKK_" 
            icon={icon_identity_face_member_4} 
            classFile={stateIdentityFaceMember4 ? 'file' : ''} 
            classCoverImg={stateIdentityFaceMember4 ? 'cover-img' : 'cover-img border-radius50'} 
            info={"format .jpg atau .png maksimal 2MB"}
            returnFile={returnFile} 
            hideBtnSubmit={hideBtnSubmit} />
          <input name="identity_face_member_4" type="hidden" 
            ref={register({ required: "Foto + E-KTP belum diupload" })} 
            value={state.postData.identity_face_member_4} />
          <ErrorMessage errors={errors} name="identity_face_member_4" as={<p className="error-input"></p>} />
          {state.postData.errorMessages.identity_face_member_4 && 
          <p className="error-input">{state.postData.errorMessages.identity_face_member_4}</p>}
        </div>
        
        <div className="txt-body-m mb-20">
          <div className="content-grid grid-2 info-form">
            <div><Image className="loader-img notice" src={IcWarningYellow}/></div>
            <div className="text-left ml-2">
              <p>Dokumentasi ini diperlukan untuk memastikan keaslian<br/> informasi penggalang, Data ini kami verifikasi secara<br/> manual.</p>
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-primary btn-block"><span>Lanjutkan</span></button>
        
        {state.postData.checkMessage &&
          <RedirectStep errorMessage={props.errorMessage} />
        }
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
  reloadUser: (config, auth) => dispatch(reloadUser(config, auth)),
  clearDispatch: () => dispatch(clearDispatch()),
  API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step3));

