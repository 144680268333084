import React, {useState} from "react";
import {Container, Image} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import imgOnboarding from '../../../../assets/img/onboarding.png'
import icAsk from '../../../../assets/svg/ic-btnhelp.svg';
import icPerseorangan from '../../../../assets/svg/ic-perseorangan.svg'
import icKomunitas from '../../../../assets/svg/ic-komunitas.svg'
import icLembaga from '../../../../assets/svg/ic-lembaga.svg'
import icPerseoranganActiv from '../../../../assets/svg/ic-perseorangan-active.svg'
import icKomunitasActiv from '../../../../assets/svg/ic-komunitas-active.svg'
import icLembagaActiv from '../../../../assets/svg/ic-lembaga-active.svg'
import icChecked from '../../../../assets/svg/ic-checked.svg'
import { Header } from '../../../organisms';
import './style.css';
const ChooseType =  (props) => {

    const [isChoose, setIsChoose] = useState(false);
    return (
        <>
            <Header title="Verifikasi Penggalang" prevPage="/preverification/onboarding" cols={3} linkPost3={{pathname: "https://wa.me/6281120211194"}} rel="noopener noreferrer" target="_blank" position3={icAsk}/>
            <main>  
                <Container className="preverification choose-campaigner">
                    <div className="text-center">
                        <Image src={imgOnboarding} className="img-main"/>
                        <h5 className="head-main">Pilih tipe penggalang</h5>
                        <p className="paragh-main">Ini menentukan apakah Penggalangan Anda akan dibawa atas nama individu atau kebaikan yang bergerak bersama.</p>
                    </div>
                    <div className="campaigner-type-v1">
                        <div className={(isChoose === 1 ? "active" : "")} onClick={() => setIsChoose(1)}>
                            <div className="row">
                                <div className="col-2">
                                    <Image src={isChoose === 1 ? icPerseoranganActiv : icPerseorangan}/>
                                </div>
                                <div className="col-10">
                                    <div className="txt-subtitle-m fw-6 mb-1">
                                        Personal
                                    </div>
                                    <div className="txt-body">Salurkan kebaikan mulai dari diri sendiri</div>
                                </div>
                            </div>
                        </div>
                        <div className={(isChoose === 3 ? "active" : "")} onClick={() => setIsChoose(3)}>
                            <div className="row">
                                <div className="col-2">
                                    <Image src={isChoose === 3 ? icKomunitasActiv : icKomunitas}/>
                                </div>
                                <div className="col-10">
                                    <div className="txt-subtitle-m fw-6 mb-1">
                                        Komunitas
                                    </div>
                                    <div className="txt-body">Bersama dalam menyalurkan kebaikan</div>
                                </div>
                            </div>
                        </div>
                        <div className={(isChoose === 2 ? "active" : "")} onClick={() => setIsChoose(2)}>
                            <div className="row">
                                <div className="col-2">
                                    <Image src={isChoose === 2 ? icLembagaActiv : icLembaga}/>
                                </div>
                                <div className="col-10">
                                    <div className="txt-subtitle-m fw-6 mb-1">Lembaga</div>
                                    <div className="txt-body">Jadi organisasi kebaikan terpercaya</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Link to={"/verifikasi-"+(isChoose === 1 ? "personal" : isChoose === 2 ? "lembaga" : (isChoose === 3 ? 'komunitas' : ''))} className={"btn btn-block btn-next mt-4 "+(!isChoose ? "btn-disabled" : "btn-primary")} disabled={(!isChoose ? true : false)}>Lanjutkan</Link>
                </Container>
            </main>
        </>
    )
}

export default ChooseType
