import React from 'react'
const Loading =  () => {
    return (
        <>
           <div className="amount">
                <div className="">Nominal Donasi</div>
                <div className="">
                    <div className="gradient text-loading text-loading-50 height-20 ma-0"></div>
                </div>
            </div>
            <div className="section pm-0 mt-10">
                <div className="mb-1">Donatur</div>
                <div className="gradient text-loading text-loading-60 height-20"></div>
                <div className="mb-20 gradient text-loading text-loading-50"> </div>

                <div className="mb-1">Program</div>
                <div className="gradient text-loading height-20"></div>
                <div className=""></div>

                <hr></hr>

                <div className="content-grid grid-2 mb-2">
                    <div>Status</div>
                    <div>
                        <div className="gradient text-loading text-loading-60 float-right clear-both"></div>
                    </div>
                </div>
                <div className="content-grid grid-2 mb-2">
                    <div>Tanggal Donasi</div>
                    <div>
                        <div className="gradient text-loading text-loading-60 float-right clear-both"></div>
                    </div>
                </div>
                <div className="content-grid grid-2 mb-2">
                    <div>Kode Donasi</div>
                    <div>
                        <div className="gradient text-loading text-loading-80 float-right clear-both"></div>
                    </div>
                </div>
                <div className="content-grid grid-2 mb-2">
                    <div>Pembayaran</div>
                    <div>
                        <div className="gradient text-loading text-loading-60 float-right clear-both"></div>
                    </div>
                </div>
            
            </div>
        </>
    )
}

export default Loading
