import React from "react";
import { Container, Image } from "react-bootstrap";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Moment from 'react-moment'
import { toast } from 'react-toastify';

import { Header } from "../../../organisms";
import { clearDispatch, API } from '../../../../config/redux/action';
import Loading from "./Loading";
import './style.css'

class NewsDetail extends React.Component {
    state = {
        token: null,
        news: {},
        isFetching: false
    }
    async apiResult () {
        this.setState({ isFetching: true })

        const { API } = this.props
        const config = {
            method: 'get',
            url: '/news/detail/' + this.props.match.params.slug,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            }
        };
        const res = await API(config, false).catch(err=>err)
        if (res) {
            this.setState({ news: res.data, isFetching: false })
        } else {
            setTimeout(() => this.props.history.push('/news'), 1000)
            toast.error('Berita tidak ditemukan', {
                position: "top-center",
                hideProgressBar: true,
                autoClose: 3000,
            });
        }
    }
    componentDidMount() {
        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = auth && auth.access_token ? `Bearer ${auth.access_token}` : null;

        this.setState({ token })
        this.apiResult()
    }
    render() {
        const { isFetching, news } = this.state
        return (
            <React.Fragment>
                <Header title={isFetching ? ' - ' : news.title} prevPage="/news" />
                <main>
                    {!isFetching &&
                        <> 
                            <div className="news-image w-100">
                                <Image className="w-100" src={news.image_url ? news.image_url : "/assets/img/banner.png"} />
                            </div>
                            <Container className="news-detail">
                                <div className="news-content">
                                    <div className="txt-title-m fw-6">{news.title}</div>
                                    <div className="txt-caption-m mb-20">
                                        <Moment format="DD MMMM, YYYY">{news.created_at}</Moment>
                                        {news && news.campaigner && 
                                            <span className="ct-primary1 campaigner-name">{news.campaigner.name}</span>
                                        }
                                    </div>
                                    <div className="txt-body-m" dangerouslySetInnerHTML={{__html: news.body}}></div>
                                </div>
                            </Container>
                        </>
                    }
                    <div className="text-center">
                        {isFetching && <Loading/>}
                    </div>
                </main>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewsDetail));
