import React, {useEffect} from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "../../../config/FormWizard/updateAction";
import {Image, Container} from "react-bootstrap";
import { Header } from '../../organisms';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import postData from './postData';
import { clearDispatch, API } from '../../../config/redux/action';
//Load Stylesheet
import './style.css'

const Category = (props) => {
  const linkPrev = "/tetangga-bantu-tetangga";
  const { state, action } = useStateMachine(updateAction);
  const { handleSubmit, register } = useForm({
    defaultValues: state.postData
  });
  const { push } = useHistory();
  useEffect(()=>{
    let data = {};
    data = postData;
    data['area_id'] = ''; 
    data['kecamatan'] = ''; 
    action(postData);
  },[])
  const nextDonasiUang = () => {
    action(postData);
    push("/donasi-uang/tetangga-bantu-tetangga/step1");
  };
  const nextDonasiBeras = () => {
    action(postData);
    push("/donasi-beras/tetangga-bantu-tetangga/step1");
  };
  const nextRekomendasiTetangga = () => {
    apiGetArea(postData);
    push("/rekomendasi-tetangga/tetangga-bantu-tetangga/step1");
  };
  const apiGetArea = async () => {

    let params = '&type=Kecamatan';
    const config = {
        method: 'get',
        url: '/area?is_donation_rice=1'+params,
        headers: {
          'Content-Type': 'application/json'
      },
    };
    const res = await props.API(config, false).catch(err=>err);
    let data = {};
    if (res) {
        data = postData;
        data['loadKecamatan'] = true;
        data['dataKecamatan'] = res.data.data;
        action(postData);
    }
  }
  
  return (
    <>
      <Header title="" prevPage={linkPrev}/>
        <main className="main-tbt-category">
          <Container className="container-donate p-0">
            
          <form  className="form-hook">
              
            <div>
                <p className="mt-5 ml-4">Saya ingin membantu melalui...</p>
                <div className="item-pay-type"> 
                  <button className="btn-linkmenu" onClick={nextDonasiUang}>
                    <Image className="icon-channel float-left" src="/assets/img/ic-bantu-uang.svg"/>
                    <div className="float-left txt-body ct-title">
                      <h6>Bantu Melalui Uang</h6>
                      <p>Salurkan uang mengikuti harga beras saat ini</p>
                    </div>
                    
                    <Image className="icon-channel float-right mt-4" src="/assets/img/ic-right.svg"/>
                  </button>
                  <hr className="m-0"/>
                </div>
                <div className="item-pay-type"> 
                  <button className="btn-linkmenu" onClick={nextDonasiBeras}>
                    <Image className="icon-channel float-left" src="/assets/img/ic-bantu-beras.svg"/>
                    <div className="float-left txt-body ct-title">
                      <h6>Bantu Dengan Beras</h6>
                      <p>Salurkan beras yang akan diterima oleh penerima manfaat</p>
                    </div>
                    
                    <Image className="icon-channel float-right mt-4" src="/assets/img/ic-right.svg"/>
                  </button>
                  <hr className="m-0"/>
                </div>
                <div className="item-pay-type"> 
                  {/* <Link to={{ pathname: "https://api.whatsapp.com/send?phone=6281221127728&text=Saya%20ingin%20merekomendasikan%20tetangga" }} rel="noopener noreferrer" target="_blank"> */}
                  <button className="btn-linkmenu" onClick={nextRekomendasiTetangga}>
                    <Image className="icon-channel float-left" src="/assets/img/ic-bantu-tetangga.svg"/>
                    <div className="float-left txt-body ct-title">
                      <h6>Rekomendasikan Tetangga</h6>
                      <p>Informasikan tetangga Anda yang perlu dibantu</p>
                    </div>
                    
                    <Image className="icon-channel float-right mt-4" src="/assets/img/ic-right.svg"/>
                  </button>
                  <hr className="m-0"/>
                </div>
            </div>
            </form>
          </Container>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
  clearDispatch: () => dispatch(clearDispatch()),
  API: (path, data, showError) => dispatch(API(path, data, showError))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Category));
