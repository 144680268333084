import React from 'react'
import { Container } from 'react-bootstrap';
import { Header } from '../../organisms';
import './style.css'

const SyaratKetentuan = () => {
    return(
        <>
            <Header title="Syarat & Ketentuan" prevPage="/profile"/>
            <main>
                <Container className="info txt-body-m">
                    <h5>SYARAT & KETENTUAN</h5>
                    <p>
                    Situs <b>www.bantutetangga.com</b> merupakan platform galang dana yang dikelola oleh 
                    <b>Yayasan Bantu Tetangga</b>. 
                    Bahwa Yayasan Bantu Tetangga telah terdaftar secara legal dari Kementerian Hukum dan Hak Asasi Manusia dengan nomor AHU-0019360.AH.01.04. tahun 2020.  
                    </p>
                    <p>
                    Dengan mengunjungi, mengakses, dan memanfaatkan situs ini, 
                    Pengguna dan/ Pengunjung secara sadar telah membaca, memahami, dan menyetujui untuk tunduk oleh <b>Syarat</b> dan <b>Ketentuan</b> yang ditetapkan, oleh karenanya Anda sangat dianjurkan untuk membaca dengan seksama segala ketentuan di bawah ini.
                    Ketentuan ini disusun dengan sistematika yang meliputi (i) Ketentuan Umum; (ii) Ketentuan Galang Dana; (iii) Ketentuan Donasi; (iv) Ketentuan Lain-lain; dan (v) Penutup.
                    </p>

                    <h5>KETENTUAN UMUM</h5>
                    <ul>
                        <li>
                            <b>DEFINISI</b>
                            <ul>
                                <li><b>Penggalangan Dana (Crowdfunding)</b> adalah suatu usaha pengumpulan kontribusi sukarela dalam bentuk uang dengan menggalang sumbangan dari berbagai Pihak untuk mendukung kegiatan sosial, kemanusiaan, kebudayaan, kesehatan, pendidikan, keagamaan, dan kegiatan lainnya.</li>
                                <li><b>Donasi</b> adalah sumbangan yang dinyatakan dalam mata uang Rupiah.</li>
                                <li><b>Akun</b> adalah channel yang digunakan Pengguna untuk mengakses paltform bantutetangga.com dengan menyertakan informasi berupa Username, email/No Seluler, dan kata sandi (password).</li>
                                <li><b>Konten</b> adalah semua jenis isi, kandungan, muatan informasi yang tersedia dan/atau diunggah ke dalam Platform bantutetangga.com.</li>
                                <li><b>Campaign</b> adalah semua jenis isi, kandungan, muatan informasi yang tersedia dan/atau diunggah ke dalam Platform bantutetangga.com.</li>
                                <li><b>Pihak</b> berarti individu dan/atau yayasan dan/atau badan hukum lainnya</li>
                                <li><b>Pengguna</b> adalah Pihak yang memanfaatkan layanan dan sistem elektronik bantutetangga.com yang terdiri dari Inisiator Progam, Penerima Manfaat, dan Donatur, serta pihak lain yang sekadar berkunjung ke platform bantutetangga.com yang selanjutnya disebut Pengunjung. </li>
                                <li><b>Penggalang Dana</b> adalah Pihak yang berperan sebagai pemilik program penggalangan dana.</li>
                                <li><b>Penerima Manfaat</b> adalah Pihak yang menerima donasi, yang diperoleh dari program penggalangan dana bantutetangga.com.</li>
                                <li><b>Donatur</b> adalah Pihak yang memberikan donasi secara sukarela kepada calon Penerima Manfaat yang dikampanyekan melalui program penggalangan dana.</li>
                                <li><b>bantutetangga.com</b> adalah penyedia platform crowdfunding yang digunakan untuk memfasilitasi dan menyelenggarakan program penggalangan dana melalui sistem elektronik yang disediakan oleh Yayasan Bantu Tetangga.</li>
                                <li><b>Pengunjung</b> adalah pihak yang mengakses, membuka, dan memperoleh informasi program penggalangan dana melalui platform bantutetangga.com.</li>
                                <li><b>Mitra Bank</b> adalah Bank yang bekerjasama dengan YBBI sebagai tempat penyimpanan dan penyaluran dana sumbangan dari berbagai Pihak.</li>
                                <li><b>Mitra Perusahaan Pembayaran</b> adalah perusahaan non bank yang memiliki layanan dompet digital yang berkerjasama dengan YBBI, selanjutnya layanan dompet digital tersebut digunakan sebagai alternatif instrumen pembayaran donasi.</li>
                                <li><b>Larangan</b> adalah segala hal yang bertentangan dengan ketertiban umum, kesusilaan, dan peraturan perundang-undangan.</li>
                                <li><b>Fundraiser</b> adalah seseorang atau organisasi yang menyatakan dukungan terhadap suatu campaign yang dibuat oleh campaigner dalam bentuk pembuatan halaman campaign baru yang terhubung dengan campaign utama. Dana yang masuk dari halaman fundraiser akan masuk di campaign utama.</li>
                            </ul>
                        </li>
                        <li>
                            <b>PRINSIP PROGRAM PENGGALANGAN DANA DAN DONASI </b><br/>
                            Bagi bantutetangga.com, kepercayaan masyarakat adalah hal yang sangat fundamental dalam berbagai program kebaikan yang dilakukan. Sebab itu, dalam penyelenggaraan program penggalangan dana, bantutetangga.com menerapkan prinsip-prinsip fundamental, yaitu: 
                            <ul>
                                <li><b>Transparansi</b> yakni menghadirkan keterbukaan informasi bagi pengunjung dan pengguna yang meliputi kemudahan akses informasi yang disediakan bantutetangga.com dengan memperhatikan prinsip perlindungan data pengguna.</li>
                                <li><b>Akuntabilitas</b> yakni pertanggungjawaban secara terbuka semua program dan kegiatan penggalangan dana yang dilakukan melalui platform bantutetangga.com, dengan dihadirkannya bentuk laporan disertai bukti-bukti pertanggungjawaban.</li>
                                <li><b>Partisipasi</b> berarti setiap Pihak berhak melibatkan diri dalam program penggalangan dana donasi yang diselenggarakan melalui platform bantutetangga.com, termasuk memberikan saran dan kritikan, serta pengaduan. </li>
                                <li><b>Keberlanjutan</b> berarti manfaat yang diperoleh dari program penggalangan dana diharapkan dapat kembali berdaya (penyintas) dalam menjaga keberlangsungan kehidupan sosial, budaya, dan spritualnya secara layak.</li>
                            </ul>
                        </li>
                        <li><b>JENIS PROGRAM PENGGALANGAN DANA DAN DONASI </b>
                            <ul>
                                <li>Program Kesejahteraan Sosial</li>
                                <li>Program Kemanusiaan</li>
                                <li>Program Pendidikan</li>
                                <li>Program Kesehatan</li>
                                <li>Program Keagamaan</li>
                                <li>Program Kebudayaan</li>
                                <li>Program Kesejahteraan Anak-Anak</li>
                                <li>Program Flaura, Fauna, dan Kesejahteraan Hewan</li>
                                <li>Program Bidang Kesejahteraan Sosial lainnya sepanjang tidak bertentangan dengan peraturan perundang-undangan</li>
                            </ul>
                        </li>

                        <li><b>PLATFORM bantutetangga.com</b><br/>
                            Bahwa penyelenggaraan program penggalangan dana yang difasilitasi oleh Yayasan Bantu Tetangga dapat digunakan melalui layanan website www.bantutetangga.com
                        </li>
                        <li><b>KEDUDUKAN DAN PERAN</b><br/>
                            Mengingat Undang Undang-Undang Nomor 9 Tahun 1961 tentang Pengumpulan Uang jo. Peraturan Pemerintah Nomor 29 tahun 1980 tentang Pelaksanaan Pengumpulan Sumbangan jo. Keputusan Menteri Sosial No 56 Tahun 1996 tentang Pelaksanaan Pengumpulan Sumbangan Oleh masyarakat, bahwa penggalangan dana masyarakat hanya boleh dilakukan oleh organisasi atau yayasan yang telah memproleh izin Kementerian Sosial Repulik Indonesia, dan tidak diperkenankan atas nama perseorangan. Oleh karenanya, Yayasan Bantu Tetangga berkedudukan sebagai berikut:
                            <ul>
                                <li>Sebagai penyedia sistem elektronik yang mempertemukan antara Donatur dengan Penerima manfaat, serta Penggalang Dana</li>
                                <li>Menampung dan menyalurkan dana donasi yang berhasil terkumpul dari Donatur yang dana tersebut ditampung di rekening atas nama Yayasan Bantu Tetangga.</li>
                            </ul>
                        </li>
                    </ul>

                    <h5>KETENTUAN GALANG DANA </h5>
                    <ul>
                        <li><b>REGISTRASI AKUN</b><br/>
                            Bahwa Pihak yang bertindak sebagai inisiator program penggalangan dana melalui platform bantutetangga.com berkewajiban mengikuti mekanisme dan ketentuan sebagai berikut:
                            <ul>
                                <li><b>Yayasan</b> sebagai inisiator program penggalangan dana melalui Perjanjian Kerjasama dengan bantutetangga.com, pendaftaran akun dibuat oleh dan atas nama bantutetangga.com.</li>
                                <li><b>Individu</b> sebagai inisiator program penggalangan dana wajib membuat pendaftaran akun dengan menginput hal-hal sebagai berikut:
                                    <ul>
                                        <li>Nama Lengkap;</li>
                                        <li>Nomor Whastapp atau Email;</li>
                                        <li>Kode One Time Password (“OTP”)</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li><b>PERSYARATAN </b><br/>
                            Guna menjamin pelaksanaan program penggalangan dana sesuai fakta, keabsahan, dan kebenaran yang dapat dipertanggungjawabkan, maka insiator program penggalangan dana wajib menyampaikan data-data dan/atau dokumen secara elektronik. Dokumen tersebut digunakan bantutetangga.com untuk melakukan verifikasi dan validasi akun inisiator program penggalangan dana saat melakukan pencairan, sebagai berikut:
                            <ul>
                                <li><b>Yayasan</b> sebagai insiator program penggalangan dana dengan skema Perjanjian Kerjasama
                                    <ul>
                                        <li>Logo Lembaga</li>
                                        <li>SK Kemenkumham</li>
                                        <li>NPWP Lembaga</li>
                                        <li>KTP PIC</li>
                                    </ul>    
                                    <b>Khusus verifikasi dan validasi program penggalangan dana kesehatan (medis), melakukan upload dokumen:</b>
                                    <ul>
                                        <li>Foto surat rujukan;</li>
                                        <li>Foto surat hasil laboratorium;</li>
                                        <li>Foto suran rincian biaya pengobatan (jika ada).</li>
                                    </ul>
                                    <b>Dokumen tersebut dikirim melalui via email sapa@bantutetangga.com</b>
                                </li>
                                <li>
                                    <b>Individu</b> sebagai inisiator program penggalangan dana:
                                    Adalah mereka yang telah dinyatakan cakap hukum terutama memenuhi batas usia dewasa yang diperbolehkan sebagaimana ditentukan dalam peraturan perundang- undangan yang berlaku yaitu berusia 18 Tahun. Apabila dibawah batas usia dewasa wajib diwakilkan orang tua/wali. Dokumen yang wajib disampaikan dan diunggah melalui paltform caripaha.id, sebagai berikut:
                                    <ul>

                                        <li>NPWP</li>
                                        <li>Kartu Tanda Penduduk (KTP)</li>
                                        <li>Karta Tanda Penduduk (KTP) + Foto Diri (Selfie);</li>
                                    </ul>
                                    <ul>
                                        <b>Khusus Verifikasi dan Validasi Program Penggalangan Dana Kesehatan (Medis), melampirkan;</b>
                                        <li>Resume Medis Pasien;</li>
                                        <li>Kartu Keluarga Pasien.</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li><b>KEWAJIBAN</b><br/>
                            <ul>
                                <li>Menjaga kerahasiaan sandi serta keamanan akun;</li>
                                <li>Menyertakan data dan/atau informasi calon penerima manfaat secara detail, lengkap, benar, dan akurat.</li>
                                <li>Menjamin fakta dan kebenaran yang dapat dipertangungjawabkan atas semua data dan/atau konten baik tulisan, foto, dan video yang diinput dan dikampayekan melalu platform bantutetangga.com;</li>
                                <li>Bertanggung jawab sepenuhnya terhadap menyelesaikan program penggalangan dana yang telah berhasil dikumpulkan dan menjamin pengelolaan dana hingga tahap implementasi program kepada penerima manfaat;</li>
                                <li>Memberikan laporan atas perkembangan dan pelaksanaan program penggalangan dana yang transparan dan kredibel, serta dilengkapi dengan dokumentasi dan bukti pendukung yang layak kepada Donatur dan bantutetangga.com melalui Platform bantutetangga.com secara terbuka.</li>
                                <li>Apabila ada perjanjian kerjasama khusus antara Inisiator program dengan bantutetangga.com, maka Inisiator program wajib menjalankan kewajibannya sebagaimana yang telah tertera pada perjanjian kerjasama tersebut.</li>
                            </ul>
                        </li>

                        <li><b>LARANGAN</b><br/>
                            <ul>
                                <li>Penggalang Dana dilarang mencantumkan nomor rekening pribadi atau lainnya dalam halaman utama kampanye dengan alasan apapun.</li>
                                <li>Penggalang Dana dilarang membuat konten kampanye yang teridentifikasi:
                                    <ul>
                                        <li>Menyebarkan kebohongan dan informasi menyesatkan yang dapat merugikan orang lain.</li>
                                        <li>Menyebarkan spam termasuk konten-konten promosi dan marketing di luar isi dan materi yang diperbolehkan oleh bantutetangga.com.</li>
                                        <li>Mendukung kegiatan terorisme, menebar kebencian, kekerasan, pelecehan, bullying, diskriminasi, atau intoleransi berkaitan dengan SARA;</li>
                                        <li>Perdagangan manusia, ekploitasi manusia dan suap;</li>
                                        <li>Pornografi, konten dewasa dan mengandung unsur seksualitas;</li>
                                        <li>Bermuatan politik praktis; dan</li>
                                        <li>Bertentangan dengan ketertiban umum, kesusilaan, dan peraturan-perundang- undangan yang berlaku.</li>
                                    </ul>
                                </li>
                            </ul>
                        
                        </li>
                        <li><b>PENCAIRAN DANA</b> <br/>
                            <ul>
                                <li>Penggalang Dana dapat mengajukan request penarikan dana apabila (i) Target donasi yang dikampanyekan telah terkumpul; atau (ii) Target waktu penggalangan dana telah jatuh tempo sebagaimana waktu yang telah ditentukan.</li>
                                <li>Tim bantutetangga.com akan melakukan verifikasi dan validasi akun beserta kelengkapan data sebagai syarat penarikan dana yang dilakukan oleh Penggalang Dana maupun Penerima Manfaat;</li>
                                <li>Penyerahan dana donasi dapat kirim melalui rekening Penerima Manfaat atau Penggalang Dana atau rekening pihak ketiga. Yang dimaksud dengan pihak ke tiga yaitu keluarga penerima manfaat.</li>
                                <li>Dana donasi dapat dialihkan sewaktu-sewaktu baik sebagian atau seluruhnya kepada keluarga atau penerima manfaat lainnya atau pihak yang berwenang, baik dengan melibatkan dan/atau tanpa kesepakatan antara Penggalang Dana, Donatur, dan bantutetangga.com, apabila ditemukan keadaan sebagai berikut:
                                    <ul>
                                        <li>Penerima manfaat meninggal dunia;</li>
                                        <li>Dana donasi yang terkumpul terbukti merupakan hasil dari kejahatan, pencucian uang dan pendanaan terorisme; dan</li>
                                        <li>Kondisi tertentu lainnya yang menurut bantutetangga.com diperlukan pengalihan.</li>
                                        <li>Dalam hal penggalangan dana telah mencapai target donasi sebelum jangka waktu periode penggalangan berakhir, penggalangan dana tetap dilanjutkan hingga periode penggalang dana berakhir.</li>
                                        <li>Apabila donasi yang terkumpul melebihi target donasi yang dibutuhkan, maka kelebihan dana donasi tersebut tetap menjadi milik Penerima Manfaat</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li><b>BIAYA OPERASIONAL & ADMINISTRASI</b><br/>
                            <ul>
                                <li>Donasi yang berhasil terkumpul akan dipotong sebesar 5% (lima persen) digunakan sebagai biaya sewa platform bantutetangga.com yang dikelola oleh Yayasan Bantu Tetangga.</li>
                                <li>Biaya administrasi yang dikenakan oleh mitra ketiga penunjang transaksi di bantutetangga.com yang dipotong otomatis untuk per transaksi saat donatur melakukan pembayaran donasi, biaya tersebut ditentukan oleh pihak ketiga / penyedia layanan payment.</li>
                                <li>Dalam hal terdapat pajak, retribusi, dan/atau pengutan lain yang timbul atas pelaksanaan penggalangan dana dan penyaluran donasi merupakan tanggung jawab masing-masing Pihak sesuai dengan perundang-undangan dan peraturan pajak yang berlaku.</li>
                            </ul>
                        </li>
                    </ul>
                    <h5 className="fw-7  text-center">KETENTUAN DONASI</h5>
                    <ul>
                        <li><b>BIAYA OPERASIONAL & ADMINISTRASI </b><br/>
                            Donatur dapat berdonasi melalui halaman paltform bantutetangga.com dengan memberikan informasi dan data yang meliputi:
                            <ul>
                                <li>Nama Lengkap;</li>
                                <li>HP/Email; dan</li>
                                <li>jumlah donasi;</li>
                            </ul>
                            
                            Apabila diminta oleh Donatur, penyertaan informasi dan data pribadi dapat dirahasiakan (Anonymous Donation) agar tidak terlihat oleh penerima manfaat maupun publik.

                        </li>

                        <li><b>KEWAJIBAN </b><br/>
                            <ul>
                                <li>Mencermati segala informasi, ide dan/atau kampanye penggalangan dana yang dimuat di dalam platform bantutetangga.com;</li>
                                <li>Berkewajiban tidak melakukan pencucian uang dan pendanaan terorisme</li>
                                <li> dan/atau menggunakan uang yang berasal dari sumber yang tidak sah secara hukum dalam mendonasikan uangnya untuk mendukung ide dan/atau kampanye penggalangan dana yang ada di dalam platform bantutetangga.com.</li>
                                <li>Bertanggung jawab atas donasi yang telah disalurkan dan menyadari konsekuensi bahwa Pemilik Kampanye penggalangan dana berkemungkinan tidak dapat melaksanakan secara sebagian atau sepenuhnya atas kampanye dijanjikan sebelumnya pada kampanyenya;</li>
                                <li>Donatur menyadari bahwa bantutetangga.com hanya bertanggung jawab sebatas pencairan donasi kepada Penerima Manfaat dan/atau Penggaang Dana;</li>
                                <li>Donatur wajib menyampaikan konfrimasi pada Admin bantutetangga.com dalam hal melakukan transfer manual melalui Bank.</li>
                            </ul>
                        </li>
                        <li><b>METODE PEMBAYARAN DONASI </b><br/>
                            Untuk pembayaran donasi secara online dapat dilakukan dengan layanan pembayaran yang telah bekerjasama dengan bantutetangga.com. Adapun beberapa alternatif metode pembayaran yang dapat digunakan oleh Donatur, sebagai berikut:
                            <ul>
                                <li>Transfer bank;</li>
                                <li>Virtual account;</li>
                                <li>Kartu Kredit visa/master;</li>
                                <li>Debit online visa/master; dan</li>
                                <li>Dompet Elektronik.</li>
                            </ul>

                        </li>
                        <li><b><i>REFUND</i> DONASI</b><br/>
                            bantutetangga.com dapat mengembalikan dana donasi kepada Donatur apabila ditemukan keadaan sebagai berikut:
                            <ul>
                                <li>Kampanye program penggalangan dana diduga telah terjadi pelanggaran terhadap ketentuan ini dan peraturan perundang-undangan yang berlaku;</li>
                                <li>Permintaan Penggalang Dana atau Penerima Manfaat untuk mengembalikan Dana Donasi kepada Donatur.</li>
                            </ul>
                        </li>
                    </ul>

                    
                    <h5>KETENTUAN LAIN-LAIN</h5>
                    <ul>
                        <li><b>DONASI KEWENANGAN bantutetangga.com</b><br/>
                            <ul>
                                <li>Tim bantutetangga.com berhak untuk melakukan edit/hapus deskripsi kampanye apabila pihak Penggalang Dana mencantumkan nomor rekening selain daripada atas nama YAYASAN Bantu Tetangga;</li>
                                <li>Tim bantutetangga.com berhak untuk melakukan edit/hapus deskripsi kampanye apabila mengandung unsur konten yang dilarang sebagaimana dimaksud dalam ketentuan ini;</li>
                                <li>Tim bantutetangga.com berhak menolak/menunda verifikasi akun dan pencairan donasi jika belum mendapatkan data-data yang cukup;</li>
                                <li>Tim bantutetangga.com berhak menolak, menahan atau membekukan dana donasi yang diduga terindikasi sebagai dan korupsi ataupun kejahatan pencucian uang dan pendanaan terorisme.</li>
                                <li>Dalam hal donatur melakukan donasi melalui transfer manual bank, namun bantutetangga.com menemukan data donatur tidak sesuai dengan data transfer Bank, termasuk tidak sesuai dengan kode tiket donasi, kemudian donatur tidak melakukan konfirmasi pada Admin bantutetangga.com dalam kurun waktu 7x24 Jam, maka bantutetangga.com berhak melakukan pengalihan dana donasi tersebut pada sumbangan umum.</li>
                            </ul>
                        </li>
                        <li><b>TANGGUNG JAWAB TERBATAS</b><br/>
                            Pengguna memahami bahwa batasan tanggung jawab bantutetangga.com diterima secara proporsional berdasarkan kedudukan dan peran bantutetangga.com sebagaimana yang dimaksud dalam ketentuan ini. Sepanjang diperkenankan oleh hukum yang berlaku, bantutetangga.com tidak dapat diminta pertanggungjawaban, atas:
                            <ul>
                                <li>Segala hal yang diperjanjikan oleh Penggalang Dana selama masa kampanye berlangsung.</li>
                                <li>Kehilangan dana donasi yang sudah ditarik oleh Penggalang Dana atau yang sudah diterima oleh penerima manfaat yang disebabkan pencurian, penggelapan, perampokkan atapun bentuk kejahatan lainnya dan/atau kehilangan atas kelalaian.</li>
                                <li>Penyalahgunaan, penggunaan tanpa izin, pelanggaran Hak Kekayaan Intelektual yang dilakukan baik Penggalang Dana maupun Donatur;</li>
                                <li>Segala kehilangan atau kerusakan langsung, tidak langsung, atau konsekuensial yang ditimbulkan oleh Pengguna sehubungan ketidakmampuan atau kelalaian atau penyimpangan dalam menggunakan layanan dan platform bantutetangga.com</li>
                            </ul>
                        </li>
                        <li><b>HAK KEKAYAAN INTELEKTUAL</b><br/>
                            Seluruh data dan/atau konten yang tersedia dalam platform bantutetangga.com termasuk namun tidak terbatas pada elemen teknologi, text, visual, dan suara dilindungi oleh Hukum Hak Kekayaan Intelektual (“HKI”).
                        </li>
                        <li><b>LAYANAN PENGADUAN</b><br/>
                            Pengguna maupun Pengunjung berhak untuk mengajukan Pengaduan kepada bantutetangga.com mengenai dugaan dan/atau peristiwa yang diduga menyimpang ketentuan ini, termasuk, namun tidak terbatas pada:
                            <ul>
                                <li>Informasi yang tidak lengkap, tidak benar, menyesatkan dan/atau merupakan pemalsuan dalam program penggalangan dana yang sedang berjalan;</li>
                                <li>Pelanggaran konten yang dilarang;</li>
                                <li>Dugaan penyalahgunaan penggalangan dana donasi; dan/atau</li>
                                <li>Dugaan pelangaran ketertiban umum, kesusilaan, dan peraturan perundang- undangan yang berlaku</li>
                            </ul>
                            Pengguna maupun Pengunjung dapat secara langsung menghubungi bantutetangga.com melalui jaringan komunikasi yang tersedia dalam paltform bantutetangga.com. Dengan mengajukan pengaduan, Pengguna maupun Pengunjung menyatakan sepakat dan bersedia untuk dipanggil sebagai saksi untuk dimintakan keterangannya. Bila dipandang perlu inisial pengadu akan dirahasikan.
                        </li>
                        <li><b>PERLINDUNGAN DATA PENGGUNA</b><br/>
                            Kami menghargai segala data Pengguna yang telah terdaftar dalam layanan bantutetangga.com. Oleh karenanya, perlindungan data Pengguna diatur lebih lanjut dalam Kebijakan Privasi.
                        </li>
                        <li><b>PENERAPAN APU-PPT</b><br/>
                            bantutetangga.com tidak menerima, menyimpan, dan menyalurkan dana yang patut diduga terkait dengan kejahatan dan/atau sebagai sasaran kegiatan pencucian uang dan pendanaan terorisme, oleh karenanya kami tunduk pada:
                            <ul>
                                <li>Undang-Undang Nomor 8 Tahun 2010 tentang Pencegahan dan Pemberantasan Tindak Pidana Pencucian Uang; dan</li>
                                <li>Undang-Undang Nomor 9 Tahun 2013 tentang Pencegahan dan Pemberantasan Tindak Pidana Pendanaan Terorisme.</li>
                            </ul>
                        </li>
                        <li><b>KEADAAN MEMAKSA (FORCE MEAJURE)</b><br/>
                            Baik Pengunjung maupun Pengguna memahami dan mengerti bahwa Platform dapat berhenti beroperasi dikarenakan adanya keadaan memaksa yang berupa kejadian diluar kemampuan manusia dan/atau tidak dapat dihindarkan seperti adanya peperangan, kerusuhan, kebakaran, bencana alam, permogokan dan bencana lainnya yang dinyatakan oleh instansi berwenang.
                        </li>
                        <li><b>PILIHAN HUKUM</b><br/>
                            Ketentuan ini akan diatur oleh dan ditafsirkan sesuai dengan hukum Republik Indonesia. Segala sengketa yang mungkin timbul dari, berhubungan dengan, atau berada dalam cara apapun berhubungan dengan situs dan/atau ketentuan ini akan diselesaikan secara musyawarah, dan menundukkan diri secara eksklusif dalam yurisdiksi pengadilan Republik Indonesia.
                        </li>
                    </ul>

                    
                    <h5>PENUTUP</h5>
                    <p>Demikian ketentuan ini ditetapkan dan mengikat bagi Pengguna dan Pengunjung. Ketentuan ini sewaktu-waktu mengalami perubahan, modifikasi, dan pembaharuan untuk mengikuti perkembang operasional dan peraturan perundang-undangan yang berlaku. Segala perubahan, modifikasi, dan pembaharuan yang akan disampaikan kepada Pengguna atau Pengunjung melalui jaringan komunikasi yang tersedia dalam platform bantutetangga.com.</p>

                </Container>
            </main>
        </>
    )
}

export default SyaratKetentuan