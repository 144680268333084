import React, {Fragment} from 'react';
import { Container, Image } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { withRouter } from "react-router";
import CurrencyFormat from 'react-currency-format';
import Moment from 'react-moment';
import icArrowBottom from '../../../../assets/svg/ic-arrow-bottom.svg';
import icArrowTop from '../../../../assets/svg/ic-arrow-top.svg';
import icNews from '../../../../assets/svg/ic-news.svg';
import icShareYellow from '../../../../assets/svg/ic-share-yellow.svg';
import icPencilYellow from '../../../../assets/svg/ic-pencil-yellow.svg';
import icPencilDisabled from '../../../../assets/svg/ic-pencil-disabled.svg';
import icWarningBlue from '../../../../assets/svg/ic-warning-blue.svg';
import icWarningYellow from '../../../../assets/svg/ic-warning-yellow.svg';
import icForbidden from '../../../../assets/svg/ic-forbidden.svg';
import icForbiddenDisabled from '../../../../assets/svg/ic-forbidden-disabled.svg';
import icCalendarYellow from '../../../../assets/svg/ic-calendar-yellow.svg';
import icCalendarDisabled from '../../../../assets/svg/ic-calendar-disabled.svg';
import icBlockRed from '../../../../assets/svg/ic-block-red.svg';
import icNewsDisabled from '../../../../assets/svg/ic-news-disabled.svg';
import icTrashDisabled from '../../../../assets/svg/ic-trash-disabled.svg';
import icDisbursement from '../../../../assets/svg/ic-disbursement.svg';
import icDisbursementDisabled from '../../../../assets/svg/ic-disbursement-disabled.svg';

import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';
// import { Helmet, HelmetProvider  } from "react-helmet-async";
import { HeadProvider, Title, Meta } from 'react-head';

import './style.css';

import LoadingCampaign from './Loading';
import News from '../CampaignNews';
import LoadingNews from '../CampaignNews/Loading';

import { toast } from 'react-toastify';

//Load Component
import { Header, Share } from '../../../organisms';
import { ConfirmAlert } from '../../../../component/atoms';

class DetailCampaign extends React.Component {
    state = {
        readMoreDesc:false,
        readMoreNews:false,
        dataCampaign: {},
        dataNews: {},
        campaignSlug:'',
        isProcessing:'',
        resCheckDisbur:{data:{allowed:false},message:""},
        isDeleting: false,
        showConfirmAlert:false,
        endCampaignProcessing:false,
        confirmDelete:false,
        url: ''
    }
    constructor(props) {
        super(props)
        this.handleChangeStatus = this.handleChangeStatus.bind(this)
        this.handleEndCampaign = this.handleEndCampaign.bind(this)
        this.handleDeleteCampaign = this.handleDeleteCampaign.bind(this)
    }
    loadCampaign = async (slug) => {
        this.setState({loadCampaign:false});
        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = `Bearer ${auth.access_token}`;
        const config = {
            method: 'get',
            url: '/admin/campaign-admin/'+slug,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            this.setState({loadCampaign:true, dataCampaign:res.data});
            if(res.data.is_active && res.data.status !== "Draft"){
                this.cekAllowDisbursement(res.data.slug);
            }
            localStorage.setItem('detailCampaign', JSON.stringify(res.data));
        }else{
            this.setState({loadCampaign:true});
        }
    }
    cekAllowDisbursement = async (slug) => {
        this.setState({checkDisbursement:false});
        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = `Bearer ${auth.access_token}`;
        const config = {
            method: 'get',
            url: '/campaigner/disbursement/campaign-status/'+slug,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            this.setState({checkDisbursement:true, resCheckDisbur:res});
        }else{
            this.setState({checkDisbursement:true});
        }
    }

    loadNews = async (slug) => {
        this.setState({loadNews:false});
        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = `Bearer ${auth.access_token}`;
        const config = {
            method: 'get',
            url: '/campaign-news/'+slug+'/campaign',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            this.setState({loadNews:true, dataNews:res.data});
        }else{
            this.setState({loadNews:true});
        }
    }

    handleDeleteNews = async (slug) => {
        this.setState({isProcessing:slug});
        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = `Bearer ${auth.access_token}`;
        const config = {
            method: 'delete',
            url: '/campaign-news/'+slug,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        };
        const res = await this.props.API(config).catch(err=>err);
        if (res) {
            this.setState({isProcessing:""});
            toast.info('Berita berhasil dihapus', {
                position: "top-center",
                hideProgressBar: true,
                autoClose:3000,
            });

            this.loadNews(this.state.campaignSlug);
        }
    }

    showMoreDesc = () => {
        this.setState({readMoreDesc:true})
    }
    showLessDesc = () => {
        this.setState({readMoreDesc:false})
    }

    showMoreNews = () => {
        this.setState({readMoreNews:true})
    }
    showLessNews = () => {
        this.setState({readMoreNews:false})
    }
    
    componentDidMount (){
        document.documentElement.scrollTop = 0;
        this.props.clearDispatch();
        const {history} = this.props;
        
        if(localStorage.getItem("userData")){
            const auth = JSON.parse(localStorage.getItem("userData"))
            if(!auth.campaigner){
              history.push('/verifikasi-lembaga');
            }
            let url = this.props.location.pathname;
            let pathArr = url.split('/');
            let slug = pathArr[pathArr.length - 1];
            this.setState({campaignSlug:slug});
            this.loadCampaign(slug);
            this.loadNews(slug);
        }else{
            history.push('/login');
        }
    }
    async handleChangeStatus(e) {
        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = `Bearer ${auth.access_token}`;

        let dataCampaign = { ...this.state.dataCampaign }
        dataCampaign.status = e.target.value
        this.setState({ dataCampaign: dataCampaign })

        const config = {
            method: 'put',
            url: '/campaign/status/' + this.state.campaignSlug,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            data: {
                status: e.target.value
            }
        };
        await this.props.API(config, false).catch(err=>err);
    }
    async handleDeleteCampaign() {
        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = `Bearer ${auth.access_token}`;

        this.setState({ isDeleting: true })

        const config = {
            method: 'delete',
            url: '/campaign/' + this.state.campaignSlug,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if(res) {
            toast.info('Campaign berhasil dihapus', {
                position: "top-center",
                hideProgressBar: true,
                autoClose: 3000,
            });
            setTimeout(() =>{
                this.props.history.push('/campaign-saya');
            }, 1000 );
        }

        this.setState({ confirmDelete:false, isDeleting: false })
    }
    
    async handleEndCampaign() {
        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = `Bearer ${auth.access_token}`;

        this.setState({ endCampaignProcessing: true })

        const config = {
            method: 'POST',
            url: '/campaigner/campaign/end',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            data:{campaign_slug:this.state.campaignSlug}
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if(res) {
            toast.info('Campaign berhasil diakhiri', {
                position: "top-center",
                hideProgressBar: true,
                autoClose: 3000,
            });
            setTimeout(() =>{
                this.props.history.push('/campaign-saya');
            }, 1000 );
        }
        this.setState({ showConfirmAlert:false, endCampaignProcessing: false })
    }
    render() {
        const state = this.state;
        const {dataCampaign} = this.state;
        state.urlCampaign = window.location;
        const auth = JSON.parse(localStorage.getItem("userData"));
        const urlShare = '/campaign/'+this.state.campaignSlug;
    
        const expired = new Date(state.dataCampaign.expired_at);
        const now = new Date();
        return(
            <Fragment>
                <Header title={state.dataCampaign.title} prevPage="/campaign-saya" />
                
                <main className="no-padding">
                <Container className="no-padding">
                    {!state.dataCampaign.title && 
                        <LoadingCampaign/>
                    }
                    {state.dataCampaign.title && 
                        <div className="campaign-detail">
                            <HeadProvider>
                                <Title>{state.dataCampaign.title} | Bantu Tetangga</Title>
                                <Meta property="og:title" content={"Bantu Sekarang - "+ state.dataCampaign.title} />
                                <Meta property="og:description" content="Platform terbuka penggalangan dana online untuk kumpulkan tetangga baik dengan semangat gotong-royong membantu tetangga prasejahtera di sekitarnya." />
                                <Meta property="og:url" content={state.urlCampaign} />
                                <Meta property="og:image" content={state.dataCampaign.cover} />
                                <Meta property="og:image:alt" content="Og Image Alt" />
                                <Meta property="og:image:width" content="800" />
                                <Meta property="og:image:height" content="600" />
                            </HeadProvider>
                            <div className="img-cover">
                                <Image src={state.dataCampaign.cover} className="img"/>
                            </div>
                            <div className="section">
                                <div className="txt-caption-m mt-10 mb-2">
                                    {state.dataCampaign.is_active ?
                                        <span className={"card-state "+(state.dataCampaign.status === 'Publish' ? 'state-green' : (state.dataCampaign.status === 'Blokir' ? 'state-red' : ''))}>
                                            {state.dataCampaign.status}
                                        </span>
                                        :
                                        <span className={"card-state state-red"}>
                                            Campaign Diblokir
                                        </span>
                                    }
                                    
                                </div>
                                <h1 className="txt-title-m fw-6">{state.dataCampaign.title}</h1>
                                <div className="content-grid grid-2">
                                    <div>
                                        <div className="txt-caption fw-5">Dana Terkumpul</div>
                                        <CurrencyFormat 
                                            value={state.dataCampaign.donation_collected} 
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix={'Rp '} 
                                            className="txt-subtitle fw-6 txt-terkumpul"
                                            displayType={'text'}
                                        />
                                    </div>
                                    <div>
                                        <div className="txt-caption fw-5">Target</div>
                                        <CurrencyFormat 
                                            value={state.dataCampaign.target} 
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix={'Rp '} 
                                            className="target txt-body-m fw-5"
                                            displayType={'text'}
                                        />
                                    </div>
                                </div>
                                <div className="progress progress-big mt-2 mb-2">
                                    <span className="progress-bar" style={{"width":state.dataCampaign.percentage}}></span>
                                </div>
                                <div className="content-grid grid-2">
                                    <div>
                                        <div className="txt-caption">Sisa Waktu</div>
                                        <div className="txt-body fw-6">
                                            {state.dataCampaign.day_left} Hari
                                        </div>
                                    </div>
                                    <div>
                                        <div className="txt-caption">Capaian</div>
                                        <div className="txt-body fw-6">{state.dataCampaign.percentage}%</div>
                                    </div>
                                </div>
                                
                                {(state.dataCampaign.status === 'Draft') ? 
                                    <>
                                        <div className="content-grid grid-2 info-form2 mt-20 mb-10">
                                            <Image src={icWarningBlue} />
                                            <div className="text-left ml-3">Publish Campaign ini untuk mulai tulis berita dan cairkan dana.</div>
                                        </div>
                                        <button className="btn btn-secondary btn-sm btn-block cursor-pointer" value="Publish" onClick={(e) => { this.handleChangeStatus(e) }}>Publish</button>
                                    </>
                                    :
                                    <>
                                        {!state.dataCampaign.is_active ?
                                            <div className="content-grid grid-2 info-red2 mt-20 mb-10">
                                                <Image src={icBlockRed} />
                                                <div className="text-left ml-3">Penggalangan ini mendapat laporan dan telah diblokir</div>
                                            </div>
                                            :
                                            <>
                                                {state.resCheckDisbur.message &&
                                                    <div className="content-grid grid-2 info-form mt-20 mb-10">
                                                        <Image src={icWarningYellow} />
                                                        <div className="text-left ml-3">{state.resCheckDisbur.message}</div>
                                                    </div>
                                                }
                                            </>
                                           
                                        }
                                        
                                        {now > expired && 
                                            <div className="info-form2 mt-20">Penggalangan Dana telah berakhir. Ubah sisa waktu untuk menggaktifkannya kembali.</div>
                                        }
                                        <div className="action-call">
                                            <div className="content-grid grid-2 mt-20 btn-actions">
                                                <div className="btn btn-secondary btn-sm cursor-pointer" onClick={() => this.setState({ shareModal: true })}>
                                                    <Image src={icShareYellow}/> Bagikan
                                                </div>
                                                {state.dataCampaign.is_active ? 
                                                    <Link to={"/galang-dana?edit="+state.campaignSlug} className="btn btn-sm btn-secondary">
                                                        <Image src={icPencilYellow}/> Ubah
                                                    </Link>
                                                    :
                                                    <button className="btn btn-sm btn-disabled2">
                                                        <Image src={icPencilDisabled}/> Ubah
                                                    </button>
                                                }
                                            </div>
                                            <div className="content-grid grid-2 mt-20 btn-actions">
                                                {state.dataCampaign.is_active && now < expired ? 
                                                    <>
                                                        <Link to={"/tulis-berita/"+state.campaignSlug} className="btn btn-secondary btn-sm">
                                                            <Image src={icNews}/> Tulis Berita
                                                        </Link>
                                                        {state.resCheckDisbur.data.allowed ? 
                                                            // <Link to={"/pencairan/"+state.campaignSlug+"?prevPage=detail-campaign/"+state.campaignSlug}  className="btn btn-secondary btn-sm"><Image src={icDisbursement}/> Cairkan Dana</Link>
                                                            <Link to={"/development"}  className="btn btn-secondary btn-sm"><Image src={icDisbursement}/> Cairkan Dana</Link>
                                                            :
                                                            <button className="btn btn-disabled2 btn-sm">
                                                                <Image src={icDisbursementDisabled}/> Cairkan Dana
                                                            </button>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <button className="btn btn-disabled2 btn-sm">
                                                            <Image src={icNewsDisabled}/> Tulis Berita
                                                        </button>
                                                        <button className="btn btn-disabled2 btn-sm">
                                                            <Image src={icDisbursementDisabled}/> Cairkan Dana
                                                        </button>
                                                    </>
                                                }
                                            </div>
                                            <div className="content-grid grid-2 mt-20 btn-actions">
                                                <div onClick={()=>this.setState({showConfirmAlert:true})} className={"btn btn-sm cursor-pointer "+(now > expired ? "btn-disabled2" : "btn-secondary")}>
                                                    <Image src={(now > expired ? icForbiddenDisabled : icForbidden )}/> Akhiri
                                                </div>
                                                {state.dataCampaign.is_active ?
                                                    <Link to={`/detail-campaign/perpanjang/${state.campaignSlug}`} className="btn btn-secondary btn-sm">
                                                        <Image src={icCalendarYellow}/> Perpanjang
                                                    </Link>
                                                    :
                                                    <Link to={`/detail-campaign/perpanjang/${state.campaignSlug}`} className="btn btn-disabled2 btn-sm">
                                                        <Image src={icCalendarDisabled}/> Perpanjang
                                                    </Link>
                                                }
                                            </div>
                                        </div>
                                    </>

                                }
                            </div>
                            <div className="content-space"></div>
                            <div className="section txt-body-m">
                                <div className="content-grid grid-2 mb-10">
                                    <div className="ct-caption">Kategori</div>
                                    <div className="ct-title">
                                        {dataCampaign?.category_name}
                                    </div>
                                </div>
                                <div className="content-grid grid-2 mb-10">
                                    <div className="ct-caption">Dibuat Pada</div>
                                    <div className="ct-title">
                                        <Moment format="DD MMMM YYYY">
                                            {state.dataCampaign.created_at}
                                        </Moment>
                                    </div>
                                </div>
                                <div className="content-grid grid-2 mb-10">
                                    <div className="ct-caption">Diperbaharui</div>
                                    <div className="ct-title">
                                        <Moment format="DD MMMM YYYY">
                                            {state.dataCampaign.updated_at}
                                        </Moment>
                                    </div>
                                </div>
                            </div>

                            <div className="content-space"></div>

                            <div className="section description">
                                <h2 className="txt-subtitle fw-6">Deskripsi Program</h2>

                                <div 
                                    className={"txt-body-m "+(!state.readMoreDesc ? 'content-less' : '')}
                                    dangerouslySetInnerHTML={{
                                        __html: state.dataCampaign.body
                                    }}
                                    >
                                </div>
                                
                                <div className="text-center">
                                    {!state.readMoreDesc && 
                                        <button className="read-more" onClick={this.showMoreDesc}>
                                            Lihat Selengkapnya <Image src={icArrowBottom} />
                                        </button>
                                    }

                                    {state.readMoreDesc && 
                                        <button className="read-more" onClick={this.showLessDesc}>
                                            Lihat lebih sedikit <Image src={icArrowTop} />
                                        </button>
                                    }
                                </div>
                            </div>
                            
                            <div className="content-space"></div>
                            <div className="section">
                                <h2 className="txt-subtitle fw-6 mb-2">Berita Terbaru</h2>
                                <div className={(!state.readMoreNews) ? 'content-less' : ''}>
                                    { this.state.loadNews &&
                                        this.state.dataNews.map(news => {
                                            return (  
                                                <News 
                                                    key={news.slug}
                                                    data={news}
                                                    token={auth.access_token}
                                                    handleDeleteNews={this.handleDeleteNews}
                                                    isProcessing={(this.state.isProcessing === news.slug) ? true : false}
                                                />
                                            )
                                            
                                        })
                                    }

                                    { !this.state.loadNews &&
                                        <LoadingNews/>
                                    }
                                
                                </div>
                                
                                <div className="text-center">
                                    {!state.readMoreNews && 
                                        <button className="read-more" onClick={this.showMoreNews}>
                                            Lihat Selengkapnya <Image src={icArrowBottom} />
                                        </button>
                                    }
                                    {state.readMoreNews && 
                                        <button className="read-more" onClick={this.showLessNews}>
                                            Lihat lebih sedikit <Image src={icArrowTop} />
                                        </button>
                                    }
                                </div>
                            </div>
                            
                            <div className="content-space"></div>
                            <div className="section">
                                <Link to={"/faq"} className="btn btn-secondary btn-block">Bantuan</Link>
                                <button className="btn btn-gray btn-block" onClick={()=>this.setState({confirmDelete:true})}>
                                    <Image src={icTrashDisabled}/>
                                    <span>Hapus Penggalangan</span>
                                </button>
                            </div>
                        </div>
                    }
                </Container>
                <Share 
                    show={this.state.shareModal} 
                    onHide={() => this.setState({ shareModal: false })}
                    url={urlShare}
                    quote={this.state.dataCampaign.title}
                    slug={this.state.dataCampaign.slug}
                    body={this.state.dataCampaign.body} 
                />
                <ConfirmAlert 
                    show={this.state.showConfirmAlert} 
                    onHide={() => this.setState({ showConfirmAlert: false })}
                    title="Akhiri Campaign?"
                    body="Penggalangan dan tidak akan dilanjutkan setelah ini."
                    lblCancel="Batal"
                    lblSubmit="Akhiri"
                    handleSubmit={this.handleEndCampaign}
                    onProcessing={this.state.endCampaignProcessing}
                />

                <ConfirmAlert 
                    show={this.state.confirmDelete} 
                    onHide={() => this.setState({ confirmDelete: false })}
                    title="Hapus Penggalangan?"
                    body="Data Penerimaan Penggalangan akan terhapus dari akun Anda jika dihapus. Anda yakin ingin menghapus Penggalangan ini?"
                    lblCancel="Batalkan"
                    lblSubmit="Hapus"
                    handleSubmit={this.handleDeleteCampaign}
                    onProcessing={this.state.isDeleting}
                />
            </main>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailCampaign));
