import React, { Fragment } from "react";
import { Container, Image } from "react-bootstrap";
import { withRouter } from "react-router";

import IcPlusHeader from "../../../../assets/svg/ic-plus-header.svg";
import icChevronDown from "../../../../assets/svg/ic-chevron-down.svg";

import { connect } from "react-redux";
import { clearDispatch, API } from "../../../../config/redux/action";
import debounce from "lodash.debounce";

import "./style.css";

//Load Component
import { Header, Filter, FilterDate } from "../../../organisms";
import Card from "./Card";
import Loading from "./Loading";
import ModalCardOptions from "./ModalCardOptions";
import imgCampaignNotFound from "../../../../assets/img/campaign-not-found.png";
import { toast } from "react-toastify";
import { useState } from "react";
import { useDebounce } from "../../../../utils";
import { useEffect } from "react";

class CampaignSaya extends React.Component {
  state = {
    formPost: {},
    loadDetailCampaign: true,
    isDeleting: "",

    categories: [],

    /** Results state */
    results: [],
    isFetchingResults: false,
    currentPage: 1,
    total: -1,

    /** Filter */
    filterStatus: "",
    filterKategori: "",
    filterDate: "",
    filterSearch: "",

    states: [
      {
        name: "Aktif",
        slug: "active",
      },
      {
        name: "Berakhir",
        slug: "expired",
      },
      {
        name: "Draft",
        slug: "draft",
      },
      {
        name: "Diblokir",
        slug: "blocked",
      },
    ],

    showFilterStatus: false,
    showFilterKategori: false,
    showFilterTanggal: false,
    showCardOptions: false,
    startDate: false,
    endDate: false,

    campaignSelected: false,
    campaignSlug: false,
  };

  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.state.auth = JSON.parse(localStorage.getItem("userData"));
    this.state.token =
      this.state.auth && this.state.auth.access_token
        ? `Bearer ${this.state.auth.access_token}`
        : null;

    this.resetResults = this.resetResults.bind(this);
    this.resetResults = debounce(this.resetResults, 500);

    this.handleFilterStatus = this.handleFilterStatus.bind(this);
    this.handleFilterKategori = this.handleFilterKategori.bind(this);
    this.handleFilterTanggal = this.handleFilterTanggal.bind(this);
    this.handleOptionsCard = this.handleOptionsCard.bind(this);
  }

  handleFilterStatus(status) {
    this.setState({ filterStatus: status });
  }
  handleFilterKategori(kategori) {
    this.setState({ filterKategori: kategori });
  }
  handleFilterTanggal(tanggal) {
    this.setState({ filterTanggal: tanggal });
    if (tanggal === 30 || tanggal === 90) {
      let day = new Date();
      let pastDay = new Date(day);
      pastDay.setDate(day.getDate() - tanggal);
      let startDate = new Date(pastDay).toISOString();
      let endDate = new Date().toISOString();
      this.setState({ startDate: startDate, endDate: endDate });
    }
  }

  resetResults() {
    this.setState({
      isFetchingResults: true,
      currentPage: 1,
      total: -1,
      results: [],
      showFilterStatus: false,
      showFilterKategori: false,
      showFilterTanggal: false,
      showCardOptions: false,
    });
  }

  handleScroll() {
    const isScrolled =
      document.documentElement.offsetHeight -
      Math.ceil(window.innerHeight + document.documentElement.scrollTop);
    if (isScrolled > 100 || this.state.isFetchingResults) return;
    this.setState({ isFetchingResults: true });
  }

  onHideModal(container) {
    switch (container) {
      case "status":
        this.setState({ showFilterStatus: false });
        break;
      case "kategori":
        this.setState({ showFilterKategori: false });
        break;
      case "tanggal":
        this.setState({ showFilterTanggal: false });
        break;
      case "cardOptions":
        this.setState({ showCardOptions: false });
        break;
      default:
        break;
    }
  }

  async loadCategories() {
    const config = {
      method: "get",
      url: "/category",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.state.token,
      },
    };
    const res = await this.props.API(config, false).catch((err) => err);
    if (res) {
      this.setState({ categories: res.data.data });
    }
  }

  handleOptionsCard(campaignSelected) {
    this.setState({
      campaignSlug: campaignSelected.slug,
      campaignSelected: campaignSelected,
      showCardOptions: true,
    });
  }

  apiResult = async (type) => {
    const isSearch = type === "search";
    const perPage = 3;
    const { currentPage } = this.state;

    let params = `?per_page=${perPage}&page=${currentPage}&str=${this.state.filterSearch}`;
    if (this.state.filterStatus) {
      params += `&status=` + this.state.filterStatus;
    }
    if (this.state.filterKategori) {
      params += `&category_slug=` + this.state.filterKategori;
    }
    if (isSearch) {
      params = `?str=${this.state.filterSearch}`;
    }
    if (
      this.state.filterTanggal &&
      this.state.startDate &&
      this.state.endDate
    ) {
      params +=
        `&date_range=` + this.state.startDate + "," + this.state.endDate;
    }

    const config = {
      method: "get",
      url: "/campaigns/me" + params,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.state.token,
      },
    };
    const res = await this.props.API(config, false).catch((err) => err);
    if (res) {
      const results = isSearch
        ? res.data.data
        : [...this.state.results, ...res.data.data];
      this.setState({
        results,
        isFetchingResults: false,
        total: res.data.total,
        currentPage: this.state.currentPage + 1,
      });

      if (window.innerHeight > 640 && this.state.results.length <= perPage)
        this.setState({ isFetchingResults: true });
    } else {
      this.setState({ isFetchingResults: true });
    }
  };
  componentDidMount() {
    this.props.clearDispatch();
    const { history } = this.props;

    if (localStorage.getItem("userData")) {
      if (!this.state.auth.campaigner) {
        history.push("/preverification");
        return;
      }

      window.addEventListener("scroll", this.handleScroll);
      this.setState({ isFetchingResults: true });
      this.loadCategories();
    } else {
      history.push("/login");
    }
  }
  componentDidUpdate(_, prevState) {
    if (this.state.filterSearch !== prevState.filterSearch) {
      this.apiResult("search");
    }
    if (this.state.isFetchingResults !== prevState.isFetchingResults) {
      const {
        isFetchingResults,
        results,
        total,
        filterSearch,
        filterStatus,
        filterKategori,
        filterDate,
        startDate,
        endDate,
      } = this.state;

      if (
        filterSearch !== prevState.filterSearch ||
        filterStatus !== prevState.filterStatus ||
        filterKategori !== prevState.filterKategori ||
        filterDate !== prevState.filterDate ||
        startDate !== prevState.startDate ||
        endDate !== prevState.endDate
      ) {
        this.apiResult();
        return;
      }

      if (
        isFetchingResults === false ||
        (results.length === total && total !== -1)
      ) {
        this.setState({ isFetchingResults: false });
        return;
      }
      this.apiResult();
    }
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  resetKeyword = (e) => {
    let keyword = "";
    let filterParams = { ...this.state.filterParams, keyword };
    this.setState({
      filterParams,
      keyword,
      loadCampaign: false,
      currentPage: 1,
    });
  };

  async handleEditCampaign(slug) {
    this.setState({ loadDetailCampaign: false });
    const auth = JSON.parse(localStorage.getItem("userData"));
    const token = `Bearer ${auth.access_token}`;
    const config = {
      method: "get",
      url: "/campaign/" + slug,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    const res = await this.props.API(config, false).catch((err) => err);
    if (res) {
      const { history } = this.props;
      localStorage.setItem("detailCampaign", JSON.stringify(res.data));
      history.push("/galang-dana?edit=" + slug + "&prevPage=campaign-saya");
      this.setState({ loadDetailCampaign: true });
    } else {
      this.setState({ loadDetailCampaign: true });
    }
  }
  async handleEndCampaign(slug) {
    this.setState({ isDeleting: slug });

    const config = {
      method: "POST",
      url: "/campaigner/campaign/end",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.state.token,
      },
      data:{campaign_slug: slug}
    };
    const res = await this.props.API(config, false).catch((err) => err);
    if (res) {
      toast.success(res.message, { position: "top-center" });
      this.onHideModal("cardOptions");
      this.resetKeyword();
      setTimeout(function () {
        window.location.reload();
      }, 3000);
    }

    this.setState({ loadDetailCampaign: true });
  }

  checkdate = (date, type) => {
    let newDate = new Date(date).toISOString();
    if (type === "startDate") {
      this.setState({ startDate: newDate });
    } else {
      this.setState({ endDate: newDate });
    }
  };
  render() {
    function countPercentage(partialValue, totalValue) {
      return ((100 * partialValue) / totalValue).toFixed(2);
    }
    return (
      <Fragment>
        <Header
          title="Penggalangan Saya"
          prevPage="/profile"
          cols={3}
          position3={IcPlusHeader}
          linkPost3="/galang-dana?prevPage=campaign-saya"
        />
        <main className={"campaign-saya"}>
          <div className="filter search-page">
            <SearchBox
              onChange={(value) => {
                this.setState({
                  filterSearch: value,
                });
              }}
            />
            <div className="section pt-0">
              <ul className="modal-filter">
                <li
                  className={this.state.filterStatus === "" ? "" : "active"}
                  onClick={() => this.setState({ showFilterStatus: true })}
                >
                  <div>Status </div>
                  <Image src={icChevronDown} />
                </li>
                <li
                  className={this.state.filterKategori === "" ? "" : "active"}
                  onClick={() => this.setState({ showFilterKategori: true })}
                >
                  <div>Kategori </div>
                  <Image src={icChevronDown} />
                </li>
                <li
                  className={
                    this.state.filterTanggal &&
                    this.state.startDate &&
                    this.state.endDate
                      ? "active"
                      : ""
                  }
                  onClick={() => this.setState({ showFilterTanggal: true })}
                >
                  <div>Waktu </div>
                  <Image src={icChevronDown} />
                </li>
              </ul>
            </div>
            <div className="content-space"></div>
          </div>
          <Container className="list-campaign">
            {this.state.results.map((campaign) => {
              const percentage = countPercentage(
                campaign.funds,
                campaign.target
              );
              return (
                <Card
                  key={campaign.slug}
                  data={campaign}
                  percentage={percentage}
                  handleOptions={this.handleOptionsCard}
                />
              );
            })}

            {this.state.isFetchingResults && <Loading />}
            {!this.state.isFetchingResults && this.state.results.length === 0 && (
              <div className="search-not-found mt-20">
                <div className="text-center">
                  <Image src={imgCampaignNotFound} />
                  <div className="txt-subtitle">Masih Kosong...</div>
                  <div className="txt-body">
                    Galang Dana dan bantu sesama sekarang.
                  </div>
                </div>
              </div>
            )}
          </Container>
          <Filter
            onHide={() => this.onHideModal("status")}
            field={this.state.filterStatus}
            handleFilter={this.handleFilterStatus}
            show={this.state.showFilterStatus}
            resetResults={this.resetResults}
            options={this.state.states}
            label="Status"
          />
          <Filter
            onHide={() => this.onHideModal("kategori")}
            field={this.state.filterKategori}
            handleFilter={this.handleFilterKategori}
            show={this.state.showFilterKategori}
            resetResults={this.resetResults}
            options={this.state.categories}
            label="Kategori"
          />
          <FilterDate
            onHide={() => this.onHideModal("tanggal")}
            filter={this.state.filterTanggal}
            handleFilter={this.handleFilterTanggal}
            show={this.state.showFilterTanggal}
            resetResults={this.resetResults}
            checkdate={this.checkdate}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
          />
          {this.state.campaignSelected && (
            <ModalCardOptions
              onHide={() => this.onHideModal("cardOptions")}
              show={this.state.showCardOptions}
              data={this.state.campaignSelected}
              handleEndCampaign={() =>
                this.handleEndCampaign(this.state.campaignSlug)
              }
              handleEditCampaign={() =>
                this.handleEditCampaign(this.state.campaignSlug)
              }
              isDeleting={this.state.isDeleting}
            />
          )}
        </main>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  errorMessage: state.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  clearDispatch: () => dispatch(clearDispatch()),
  API: (path, data) => dispatch(API(path, data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CampaignSaya)
);

function SearchBox({ onChange }) {
  const [valueSearch, setValueSearch] = useState("");
  const debounceSearch = useDebounce(valueSearch);

  useEffect(() => {
    onChange(debounceSearch);
  }, [debounceSearch]);

  return (
    <div className="wrapper-search">
      <div className="icon">
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M12.8619 13.8045L9.52856 10.4711L10.4714 9.52832L13.8047 12.8617C14.0651 13.122 14.0651 13.5441 13.8047 13.8045C13.5444 14.0648 13.1222 14.0648 12.8619 13.8045Z" fill="#B6B6B6" />
          <path fillRule="evenodd" clipRule="evenodd" d="M7 10.6667C9.02504 10.6667 10.6667 9.02504 10.6667 7C10.6667 4.97496 9.02504 3.33333 7 3.33333C4.97496 3.33333 3.33333 4.97496 3.33333 7C3.33333 9.02504 4.97496 10.6667 7 10.6667ZM7 12C9.76142 12 12 9.76142 12 7C12 4.23858 9.76142 2 7 2C4.23858 2 2 4.23858 2 7C2 9.76142 4.23858 12 7 12Z" fill="#B6B6B6" />
        </svg>
      </div>
      <input
        type="search"
        className="form-control"
        placeholder="Cari Pencairan..."
        onChange={(e) => setValueSearch(e.target.value)}
      />
    </div>
  );
}
