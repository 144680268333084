import React from 'react'
import { API } from '../../../config';
import { ProgressBar, Image } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import icUserDefault from '../../../assets/svg/ic-user-default.svg';
import icPrayerOn from '../../../assets/svg/ic-prayer-on.svg';
import icPrayerOff from '../../../assets/svg/ic-prayer-off.svg';
import { toast } from 'react-toastify'
import LazyLoad from 'react-lazyload';
import moment from 'moment';
import '../../../assets/css/fonts.css'
import './style.css'

export default function DoaCard({ prayer, classes, prevPage }) {
    const campaignsCampaigner = (!prayer?.campaign?.campaigner) ? ' mt-3 mb-2' : '';
    const defaultClasses = {
        root:'prayer-card',
        Image:'prayer-card-image',
        InfoBox:'prayer-card-info-box',
        CampaignName:'prayer-card-campaign-name',
        CampaignerName:'prayer-card-campaigner-name',
        CampaignProgress:('prayer-card-campaign-progress'+campaignsCampaigner),
        ProgressInfo:'prayer-card-progress-info',
        ProgressInfoLeft:'prayer-card-progress-info-left',
        ProgressInfoRight:'prayer-card-progress-info-right',
        cardImage: "campaign-card-image",
        cardInfo: "campaign-card-info",
        cardTitle: "campaign-card-info-title txt-body",
        cardCampaigner: "txt-campaigner",
    }

    const ovClasses = { ...defaultClasses, ...classes ? classes : {} }

    const linkPrev = (prevPage ? prevPage : '');
    const submitPray = async () => {
        try {
          const payload = {
            donation_id: prayer?.id,
            status: true
          }
          const res = await API.post("/public/pray", payload );
          if (res?.status === 200){
            var namesArr = JSON.parse(window.sessionStorage.getItem('listAamiin') || '[]');
            namesArr.push(res?.data?.data?.donation_id);
            window.sessionStorage.setItem('listAamiin', JSON.stringify(namesArr));
            toast.success(res?.data?.message, {position: "top-center", autoClose:2500});
            // setTimeout(function () {
            //     window.location.reload()
            // }, 2500);
          }
        } catch (error) {
          console.log(error)
        }
    }
    const unSubmitPray = async () => {
        try {
          const payload = {
            donation_id: prayer?.id,
            status: false
          }
          const res = await API.post("/public/pray", payload );
          if (res?.status === 200){
            var namesArr = JSON.parse(window.sessionStorage.getItem('listAamiin') || '[]');
            namesArr.pop(res?.data?.data?.donation_id);
            window.sessionStorage.setItem('listAamiin', JSON.stringify(namesArr));
            toast.success(res?.data?.message, {position: "top-center", autoClose:2500});
            // setTimeout(function () {
            //     window.location.reload()
            // }, 2500);
          }
        } catch (error) {
          console.log(error)
        }
    }
    const aamiin = JSON.parse(window.sessionStorage.getItem('listAamiin') || '[]');
    return (
        <div className={ovClasses.root}>
            <LazyLoad>
                <div className={ovClasses.InfoBox}>
                    {prayer?.campaign?.campaigner &&
                        <div className="d-flex">
                            <img className={defaultClasses.cardImage} alt={prayer?.campaign?.title} src={prayer?.user == null ? icUserDefault : prayer?.user} />
                            <div className={defaultClasses.cardInfo}>
                                <h5 className={defaultClasses.cardCampaigner}>
                                    {prayer?.donor_name}
                                </h5>
                                <div className="desc-campaign">
                                    <p className="txt-title">{prayer?.campaign?.title}</p>
                                    <p>
                                        <ul>
                                            <li>{moment(prayer?.date).fromNow()}</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="box-support">
                    <p className="txt-support">{prayer?.support}</p>
                </div>
               
                    {aamiin.find(row=>(prayer.id === row)) ? 
                        <div className="box-doa">
                            <div onClick={unSubmitPray}>
                                <Image src={icPrayerOn} className="img-fluid" />
                                <span className="txt-title">Aamiin</span>
                            </div>
                            <div className="txt-count">
                                {prayer.pray.length === 0 ? 
                                <span className="fw-6">Kamu</span>
                                :
                                <span>
                                    <span className="fw-6">Kamu </span> 
                                    <span>dan </span>
                                    <span className="fw-6"> {prayer?.pray[0]?.count} orang</span>
                                </span>
                                }
                                <span> mengaminkan</span>
                            </div>  
                        </div>
                        :
                         <div className="box-doa">
                            <div onClick={submitPray}>
                                <Image src={icPrayerOff} className="img-fluid" />
                                <span className="txt-title">Aamiin</span>
                            </div>
                            <div className="txt-count">
                                {prayer.pray.length === 0 ? 
                                <span className="fw-6">0 orang</span>
                                :
                                <span className="fw-6">{prayer?.pray[0]?.count} orang</span>
                                }
                                <span> mengaminkan</span>
                            </div> 
                        </div>
                    }
            </LazyLoad>
        </div>
    )
}