import React from "react";
import {Image, Container} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { Header } from '../../../containers/organisms';
import imgAwaitingVerification from '../../../assets/img/awaitingverification.png';

import './style.css';
class AwaitingVerification extends React.Component {
  state = {}
  
  componentDidMount (){
  }

  render() {
    const auth = JSON.parse(localStorage.getItem("userData"))
    return(
      <>
      <Header splash={true}/>
      <main>  
        <Container >
          <div className="section text-center pb-0">
            <Image src={imgAwaitingVerification} className="w-100"/>
            <div className="txt-subtitle-m fw-6 mt-4 mb-2">Lembaga Anda belum kami Verifikasi</div>
            <div className="txt-body-m mb-5">Mohon maaf proses ini masih dalam pengerjaan admin kami. Cek kembali status verifikasi di halaman profil.</div>
            <Link to={(!auth.campaigner ? "/profile" : "/campaigner/"+auth.campaigner.slug+"?prevPage=profile")} className="btn btn-secondary btn-block">Cek Profil Penggalang</Link>
            <Link to="" className="btn btn-secondary btn-block no-border">Kembali ke Beranda</Link>
          </div>
        </Container>
      </main>
      </>
    )
  }
}

export default AwaitingVerification;
