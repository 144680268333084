import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { API, clearDispatch, reloadUser } from '../../../../config/redux/action';
import { Header } from '../../../organisms';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from "react-toastify";

import './style.css';
import { Button, Container, Form, Spinner } from 'react-bootstrap';

function SettingSecurityForm ({ state, handleChange, savePassword, errorMessage }) {
    const { handleSubmit, register, errors } = useForm()
    return (
        <Form onSubmit={handleSubmit(savePassword)} className="form-hook">
            {state.auth.has_password && 
                <Form.Group>
                    <Form.Label>Kata Sandi Lama</Form.Label>
                    <Form.Control
                        type="password" 
                        name="password_old"
                        placeholder="Masukkan Kata Sandi Lama" 
                        ref={register({required: "Kata Sandi lama wajib diisi"})}
                        onChange={(e) => handleChange(e)} 
                        defaultValue={state.password_old}
                    />
                    <ErrorMessage errors={errors} name="password_old" as={<p className="error-input text-left"></p>} />
                    {errorMessage.password_old && <p className="error-input">{errorMessage.password_old}</p>}
                </Form.Group>
            }
            <hr/>
            <Form.Group>
                <Form.Label>Kata Sandi Baru</Form.Label>
                <Form.Control
                    type="password" 
                    name="password"
                    placeholder="Masukkan Kata Sandi Baru" 
                    ref={register({ 
                        required: "Kata Sandi Baru wajib diisi",
                        minLength: {
                            value: 8,
                            message: "Kata Sandi harus terdiri dari minimal 8 karakter"
                        }
                    })}
                    onChange={(e) => handleChange(e)} 
                    defaultValue={state.password}
                />
                <ErrorMessage errors={errors} name="password" as={<p className="error-input text-left"></p>} />
                {errorMessage.password && <p className="error-input">{errorMessage.password}</p>}
            </Form.Group>
            <Form.Group>
                <Form.Label>Ulang Kata Sandi</Form.Label>
                <Form.Control
                    type="password" 
                    name="password_confirmation"
                    placeholder="Masukkan Ulang Kata Sandi" 
                    ref={register({ 
                        required: "Ulang Kata Sandi wajib diisi",
                        validate: value => {
                            return value === state.password || "Password tidak cocok"
                        }
                    })}
                    onChange={(e) => handleChange(e)} 
                    defaultValue={state.password_confirmation}
                />
                <ErrorMessage errors={errors} name="password_confirmation" as={<p className="error-input text-left"></p>} />
                {errorMessage.password_confirmation && <p className="error-input">{errorMessage.password_confirmation}</p>}
            </Form.Group>
            <Button 
                type="submit" 
                variant="primary"
                block
                disabled={(state.isSavingUpdate ? true : false)}
                >
                { !state.isSavingUpdate && 'Simpan Perubahan' }
                { state.isSavingUpdate && <Spinner animation="border" role="status"/> }
            </Button>
        </Form>
    )
}

class SettingSecurity extends React.Component {
    state = {
        auth: {},
        password_old: '',
        password: '',
        password_confirmation: '',
        isSavingUpdate: false
    }
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.savePassword = this.savePassword.bind(this)
    }
    handleChange(e) {
        let change = {}
        change[e.target.name] = e.target.value
        this.setState(change)
    }
    async savePassword() {
        this.setState({ isSavingUpdate: true })
        const access_token = `Bearer ${this.state.auth.access_token}`;
        let formPost = {
            password: this.state.password,
            password_confirmation: this.state.password_confirmation
        }
        if(this.state.auth.has_password){
            formPost['password_old'] = this.state.password_old;
        }
        const confEditProfile = {
            method: 'put',
            url: '/profile/change_password',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': access_token
            },
            data: formPost
        };

        const res = await this.props.API(confEditProfile, true).catch(err=>err);
        if (res) {
            toast.info('Kata sandi anda berhasil diperbaharui', {
                position: "top-center",
                hideProgressBar: true,
                autoClose: 3000,
            });

            this.props.history.push('/pengaturan')
            return
        }
        this.setState({ isSavingUpdate: false })
    }
    componentDidMount() {
        const auth = JSON.parse(localStorage.getItem("userData"))
        if(auth){
            this.setState({ auth })
        } else {
            this.props.history.push('/login')
        }
    }
    render() {
        const {auth} = this.state;
        return (
            <React.Fragment>
                <Header title="Keamanan" prevPage="/pengaturan" />
                <main>
                    <Container>
                        <div className="txt-title fw-6 mb-3">{(auth.has_password ? 'Ganti' : 'Atur')} Kata Sandi</div>
                        <SettingSecurityForm 
                            state={this.state}
                            handleChange={this.handleChange}
                            savePassword={this.savePassword}
                            errorMessage={this.props.errorMessage}
                        />
                    </Container>
                </main>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data)),
    reloadUser: (config, auth) => dispatch(reloadUser(config, auth))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingSecurity));