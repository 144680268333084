import React, {Fragment} from 'react'
import {Image } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import './style.css';

const AccountMenu =  (props) => {
    return (
            <Fragment>
                <Link to={props.link} className="nav-menu">
                    <div><Image src={props.icon}/></div>
                    <div className={props.class}>{props.name}</div>
                    <div>
                        {props.summary &&
                        <div className="summary">{props.summary}</div>}
                    </div>
                </Link>
                {props.borderBottom !== 'hide'  && <hr/>}
            </Fragment>


    )
}

export default AccountMenu;