import React from 'react';
import { withRouter } from "react-router";
import { Nav, Image} from 'react-bootstrap';
import logo from '../../../assets/svg/logo-bangga-color.svg';
import logoWhite from '../../../assets/svg/logo-bangga-white.svg';
import './style.css';

const SearchBar =  (props) => {
    return (
        <header className={(props.isTransparant) ? "search-bar transparant" : "search-bar"}>
            <Nav>
                <Nav.Item>
                    <Nav.Link href="/">
                        <Image src={(props.isTransparant) ? logoWhite : logo} rounded />
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item onClick={() => props.history.push(props.to)}>
                    {/* <div className="search-input">
                        <div><Image src="assets/img/ic-search-gray.svg"/></div>
                        <div>Cari Campaign</div>
                    </div> */}
                    <div className={`search-button ${props.isTransparant ? 'with-shadow' : ''}`}>
                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M19.2929 20.7071L14.2929 15.7071L15.7071 14.2928L20.7071 19.2928C21.0976 19.6834 21.0976 20.3165 20.7071 20.7071C20.3166 21.0976 19.6834 21.0976 19.2929 20.7071Z" fill="#242424" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M10.5 16C13.5376 16 16 13.5376 16 10.5C16 7.46243 13.5376 5 10.5 5C7.46243 5 5 7.46243 5 10.5C5 13.5376 7.46243 16 10.5 16ZM10.5 18C14.6421 18 18 14.6421 18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18Z" fill="#242424" />
                        </svg>
                    </div>
                </Nav.Item>
            </Nav>
        </header>
    
    )
}

SearchBar.defaultProps = {
    title: 'No Title',
    header: 'home',
}

export default withRouter(SearchBar);