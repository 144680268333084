import React from 'react'
import { Container, Image } from 'react-bootstrap';

import { Header, NavMenu } from "../../organisms";

import './style.css'

class Program extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Header title="Berita dan Program" />
                <main>
                    <Container className="program-page">
                        <div className="program-intro">
                            <h6>Sebuah Program untuk Negeri</h6>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Neque, etiam nunc porttitor ultricies non eget maecenas et. Lorem a rhoncus ut augue a tellus massa. Adipiscing congue sapien eget risus, orci diam suspendisse suscipit. Arcu scelerisque scelerisque non nam. Elementum sed ultricies suscipit non quis egestas.
                            </p>
                        </div>
                        <div className="program-list">
                            <div className="program-item d-flex">
                                <div className="program-item-info">
                                    <h6>Pembangunan</h6>
                                    <p>Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet.</p>
                                </div>
                                <div className="program-item-image">
                                    <Image src="assets/img/card-image-kategori.png" />
                                </div>
                            </div>
                            <div className="program-item d-flex">
                                <div className="program-item-info">
                                    <h6>Pembangunan</h6>
                                    <p>Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet.</p>
                                </div>
                                <div className="program-item-image">
                                    <Image src="assets/img/card-image-kategori.png" />
                                </div>
                            </div>
                        </div>
                    </Container>
                </main>
                <NavMenu />
            </React.Fragment>
        )
    }
}

export default Program