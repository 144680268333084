import React, {useState, useEffect} from 'react';
import { Image, Modal, Button } from "react-bootstrap";
import icCloseModal from '../../../assets/svg/ic-close-modal.svg';
import { clearDispatch, API } from '../../../config/redux/action';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import CurrencyFormat from 'react-currency-format';
import './FilterCampaigns.css'

const FilterCampaigns =  (props) => {
    const { show, onHide, label } = props
    const [categories, setCategories] = useState(false)
    const [activeCategory, setActiveCategory] = useState([])
    const orderOptions = [
        { slug: 'latest', name: 'Terbaru' },
        { slug: 'oldest', name: 'Terlama' },
        { slug: 'urgent', name: 'Mendesak' }
    ];
    const [activeOrder, setActiveOrder] = useState('');
    const [min_donate, setMinDonate] = useState('');
    const [max_donate, setMaxDonate] = useState('');
    const [donateRange, setDonateRange] = useState('');
    
    useEffect(() => {
        if(show){
            loadCategories()
        }
        let url = new URL(window.location.href)
        let paramOrder= url.searchParams.get('order')
        let paramCategory= url.searchParams.get('category')
        let paramMinDonate= url.searchParams.get('min_donate')
        let paramMaxDonate= url.searchParams.get('max_donate')
        if(url.searchParams.has('order')){
            setActiveOrder(paramOrder)
        }
        if(url.searchParams.has('category')){
            var temp = [...activeCategory];
            var index = temp.indexOf(paramCategory)
            if (index !== -1) {
                temp.splice(index, 1);
                setActiveCategory(temp);
            }else{
                setActiveCategory([...activeCategory, paramCategory]);
            }
        }
        if(url.searchParams.has('min_donate')){
            setMinDonate(paramMinDonate)
        }
        if(url.searchParams.has('max_donate')){
            setMaxDonate(paramMaxDonate)
        }
    },[show])

    const sendState = () => {
        let params = {
            order: activeOrder,
            categories: activeCategory,
            min_donate,
            max_donate
        }
        props.handleFilter(params)
        
        props.history.push({ search: '?order=' + activeOrder + '&category=' + activeCategory + '&min_donate=' + min_donate + '&max_donate=' + max_donate})
    }

    const loadCategories = async () => {
        const { API } = props
        const config = {
            method: 'get',
            url: '/category',
            headers: {
                'Content-Type': 'application/json',
            }
        };

        const res = await API(config, false).catch(err=>err)
        if (res){
            setCategories(res.data.data);
        }
    }

    const checkCategories = (slug) => {
        return activeCategory.includes(slug)
    }

    const handleSetCategories = (slug) => {
        var temp = [...activeCategory];
        var index = temp.indexOf(slug)
        if (index !== -1) {
            temp.splice(index, 1);
            setActiveCategory(temp);
        }else{
            setActiveCategory([...activeCategory, slug]);
        }

    }

    const handleCurrency = (field, value) => {
        if(field === 'min_donate'){
            setMinDonate(value);
        }else{
            setMaxDonate(value);
        }
    }
    // const handleChangeUrutkan = (slug) => {
    //     var temp = [...activeCategory];
    //     var index = temp.indexOf(slug)
    //     if (index !== -1) {
    //         temp.splice(index, 1);
    //         setActiveCategory(temp);
    //         props.history.push({ search: '?sort=' + slug})
    //     }else{
    //         setActiveCategory([...activeCategory, slug]);
    //         props.history.push({ search: '?sort=' + slug})
    //     }
    // }
    return (
        <Modal show={show} onHide={onHide} animation={false} centered className="modal-bottom">
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">
                    <Image src={icCloseModal} onClick={onHide} className="cursor-pointer" /> 
                    <span className="txt-title">{label}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="no-border">
                    <div className="filter-sort">
                        <div className="txt-subtitle fw-6">Urutkan</div>
                        <div className="d-flex flex-wrap">
                            {orderOptions && 
                                <>
                                {orderOptions.map((option, i) => (
                                    <Button 
                                        size="sm"
                                        key={option.slug} 
                                        className={activeOrder === option.slug ? "btn-filter-active":""} 
                                        variant={activeOrder === option.slug ? "primary":"disabled"} 
                                        onClick={() => setActiveOrder(option.slug)}>
                                            {option.name}
                                    </Button>
                                ))}
                                </>
                            }
                        </div>
                    </div>
                    <div className="filter-category">
                        <div className="txt-subtitle fw-6">Kategori</div>
                        <div className="d-flex flex-wrap">
                            {categories && 
                                <>
                                {categories.map((category, i) => (
                                    <Button 
                                        size="sm"
                                        key={category.slug} 
                                        className={checkCategories(category.slug) ? "btn-filter-active":""} 
                                        variant={checkCategories(category.slug) ? "primary":"disabled"}  
                                        onClick={() => handleSetCategories(category.slug)}>
                                            {category.name}
                                    </Button>
                                ))}
                                </>
                            }
                        </div>
                    </div>

                    <div className="txt-subtitle fw-6 mt-4">Donasi Terkumpul</div>
                    <div className="list-option form-hook">
                        <div className="form-group">
                            <CurrencyFormat 
                                value={min_donate} 
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={'Rp '} 
                                className="form-control"
                                placeholder="Minimal"
                                onValueChange={values => {
                                    handleCurrency('min_donate', values.value);
                                }}
                            />
                        </div>
                        <div>-</div>
                        <div className="form-group">
                            <CurrencyFormat 
                                value={max_donate} 
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={'Rp '} 
                                className="form-control"
                                placeholder="Maksimal"
                                onValueChange={values => {
                                    handleCurrency('max_donate', values.value);
                                }}
                            />
                        </div>
                    </div>

                    <button className="btn btn-primary btn-block mt-4" onClick={sendState}>Tampilkan</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}



const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterCampaigns));