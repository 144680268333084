import React from 'react';
import { Button, Container, Form, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API, clearDispatch, reloadUser } from '../../../../config/redux/action';
import { Header } from '../../../organisms';

import './style.css';

class SettingNotification extends React.Component {
    state = {
        auth: {},
        isFetching: false,
        reminder: false,
        updates: false,
        newsletter: false,
        isSavingUpdate: false
    }
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.saveChanges = this.saveChanges.bind(this)
    }
    async apiNotification() {
        this.setState({ isFetching: true })
        // const access_token = `Bearer ${this.state.auth.access_token}`;
        // const config = {
        //     method: 'get',
        //     url: '/profile/notification',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': access_token
        //     },
        // };

        // const res = await this.props.API(config, true).catch(err=>err);
        const res = { reminder: true, updates: true, newsletter: true }
        if (res) {
            this.setState({ 
                reminder: res.reminder, 
                updates: res.updates, 
                newsletter: res.newsletter, 
            })
        }
        this.setState({ isFetching: false })
    }
    handleChange(e) {
        let change = {}
        change[e.target.id] = e.target.checked
        this.setState(change)
    }
    async saveChanges(e) {
        e.preventDefault()
        this.setState({ isSavingUpdate: true })
        // const access_token = `Bearer ${this.state.auth.access_token}`;
        // const config = {
        //     method: 'put',
        //     url: '/profile/notification',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': access_token
        //     },
        //     data: {
        //         reminder: this.state.reminder,
        //         updates: this.state.updates,
        //         newsletter: this.state.newsletter,
        //     }
        // };

        // const res = await this.props.API(config, true).catch(err=>err);
        const res = true
        if (res) {
            toast.info('Pengaturan notifikasi anda berhasil diperbaharui', {
                position: "top-center",
                hideProgressBar: true,
                autoClose: 3000,
            });

            this.props.history.push('/pengaturan')
            return
        }
        this.setState({ isSavingUpdate: false })
    }
    componentDidMount() {
        const auth = JSON.parse(localStorage.getItem("userData"))
        if(auth){
            this.setState({ auth })
            this.apiNotification()
        } else {
            this.props.history.push('/login')
        }
    }
    render() {
        return (
            <React.Fragment>
                <Header title="Notifikasi" prevPage="/pengaturan" />
                <main>
                    {!this.state.isFetching &&
                        <Form onSubmit={this.saveChanges}>
                            <Container>
                                <div className="txt-title">Donasi</div>
                                <div className="d-flex p-3">
                                    <div className="w-100">
                                        <div className="txt-subtitle">Pengingat</div>
                                        <div className="txt-caption">Kami akan ingatkan Anda soal donasi dan pembayaran</div>
                                    </div>
                                    <div className="text-right w-100">
                                        <Form.Check id="reminder" onChange={this.handleChange} checked={this.state.reminder} type="switch" label="" />
                                    </div>
                                </div>
                                <hr />
                                <div className="d-flex p-3">
                                    <div className="w-100">
                                        <div className="txt-subtitle">Update Campaign</div>
                                    </div>
                                    <div className="text-right w-100">
                                        <Form.Check id="updates" onChange={this.handleChange} checked={this.state.updates} type="switch" label="" />
                                    </div>
                                </div>
                                <hr />
                            </Container>
                            <Container>
                                <div className="txt-title">Marketing</div>
                                <div className="d-flex p-3">
                                    <div className="w-100">
                                        <div className="txt-subtitle">Newsletter Bantu Tetangga</div>
                                    </div>
                                    <div className="text-right w-100">
                                        <Form.Check id="newsletter" onChange={this.handleChange} checked={this.state.newsletter} type="switch" label="" />
                                    </div>
                                </div>
                                <hr />
                            </Container>
                            <Container>
                                <Button 
                                    type="submit" 
                                    variant="primary"
                                    block>
                                    { !this.state.isSavingUpdate && 'Simpan Perubahan' }
                                    { this.state.isSavingUpdate && <Spinner animation="border" role="status"/> }
                                </Button>
                            </Container>
                        </Form>
                    }
                </main>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data)),
    reloadUser: (config, auth) => dispatch(reloadUser(config, auth))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingNotification));