import React from 'react';
import { Button, Container, Image, Modal, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { API, clearDispatch } from '../../../../config/redux/action';
import { Header } from '../../../organisms';
import IcPlus from '../../../../assets/svg/ic-plus.svg';
import IcWarningYellow from '../../../../assets/svg/ic-warning-yellow.svg';
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify'

import './style.css';

function AddRekeningForm ({ handleSubmitForm, handleChange, errorMessage, state }) {
    const { handleSubmit, register, errors } = useForm();
    return (
        <form className="form-hook mt-3" onSubmit={handleSubmit(handleSubmitForm)}>
            <div className="form-group">
                <label className="form-label">Nama Bank</label>
                <input
                    name="bank"
                    className="form-control"
                    ref={register({ required: "Nama Bank wajib diisi" })}
                    placeholder="Masukkan Nama Bank"
                    onChange={handleChange} 
                    value={state.bank}/>
                <ErrorMessage errors={errors} name="bank" as={<p className="error-input"></p>} />
                {errorMessage.bank && 
                <p className="error-input">{errorMessage.bank}</p>}
            </div>
            <div className="form-group">
                <label className="form-label">No Rekening</label>
                <input
                    name="account_number"
                    className="form-control"
                    ref={register({ required: "No Rekening wajib diisi" })}
                    placeholder="Masukkan No Rekening"
                    onChange={handleChange} 
                    value={state.account_number}/>
                <ErrorMessage errors={errors} name="account_number" as={<p className="error-input"></p>} />
                {errorMessage.account_number && 
                <p className="error-input">{errorMessage.account_number}</p>}
            </div>
            <div className="form-group">
                <label className="form-label">Atas Nama</label>
                <input
                    name="account_holder"
                    className="form-control"
                    ref={register({ required: "Atas Nama wajib diisi" })}
                    placeholder="Masukkan Nama Rekening"
                    onChange={handleChange} 
                    value={state.account_holder}/>
                <ErrorMessage errors={errors} name="account_holder" as={<p className="error-input"></p>} />
                {errorMessage.account_holder && 
                <p className="error-input">{errorMessage.account_holder}</p>}
            </div>

            <div className="content-grid grid-2 btn-groups">
                <div>
                    <button className="fw-7" type="submit" disabled={state.isLoadingRek}>
                        { (!state.isLoadingRek) && <span>Simpan</span> }
                        { state.isLoadingRek && <Spinner animation="border" role="status"/> }
                    </button>
                </div>
            </div>
        </form>
    )
}

class ModalPostRekening extends React.Component {
    render() {
        const { state, errorMessage, addRekening, onHide, handleChange } = this.props
        return (
            <Modal show={state.show} onHide={onHide} animation={false} centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Tambah Rekening
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddRekeningForm 
                        handleChange={handleChange} 
                        errorMessage={errorMessage}
                        handleSubmitForm={addRekening}
                        state={state}
                    />
                </Modal.Body>
            </Modal>
        )
    }
}

class ModalConfirmDelete extends React.Component {
    render() {
        const { state, onHide, deleteRekening } = this.props
        return (
            <Modal show={state.showConfirm} onHide={onHide} animation={false} centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Yakin ingin menghapus rekening ini?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Perubahan ini tidak bisa dikembalikan.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={onHide}>
                        Tidak
                    </Button>
                    <Button variant="secondary" size="sm" onClick={deleteRekening}>
                        { (!state.isLoadingDelete) && <span>Hapus</span> }
                        { state.isLoadingDelete && <Spinner animation="border" role="status"/> }
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

class RekeningCampaigner extends React.Component {
    state = {
        auth: {
            campaigner: {}
        },
        is_foundation: true,
        token: '',
        isFetching: false,
        rekening: [],
        show: false,
        showConfirm: false,
        deleteId: '',
        isLoadingRek: false,
        bank: '',
        account_number: '',
        account_holder: '',
    }
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.addRekening = this.addRekening.bind(this)
        this.deleteRekening = this.deleteRekening.bind(this)
        this.onHideModalPostRekening = this.onHideModalPostRekening.bind(this)
        this.onHideModalConfirmDelete = this.onHideModalConfirmDelete.bind(this)
        
        this.state.auth = JSON.parse(localStorage.getItem('userData'))
    }
    async apiRekening() {
        const config = {
            method: 'get',
            url: '/campaigner/account-number',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            }
        };
        const res = await this.props.API(config).catch(err=>err);
        if(res) {
            this.setState({ rekening: res.data })
        }

        this.setState({ isFetching: false })
    }
    handleChange(e) {
        let change = {}
        change[e.target.name] = e.target.value
        this.setState(change)
    }
    onHideModalPostRekening() {
        this.setState({ show: false })
    }
    async addRekening() {
        this.setState({ isLoadingRek: true })

        let postData = { 
            bank: this.state.bank,
            account_number: this.state.account_number,
            account_holder: this.state.account_holder, 
        }
        const config = {
            method: 'post',
            url: '/campaigner/account-number',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            },
            data: postData
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            await this.apiRekening()
            toast.info('Rekening berhasil ditambahkan', {
                position: "top-center",
                hideProgressBar: true,
                autoClose: 3000,
            });
            this.setState({ bank: '', account_number: '', account_holder: '', show: false })
        }
        this.setState({ isLoadingRek: false })
    }
    showConfirmDelete(deleteId) {
        this.setState({ showConfirm: true, deleteId })
    }
    onHideModalConfirmDelete() {
        this.setState({ showConfirm: false, deleteId: '' })
    }
    async deleteRekening() {
        this.setState({ isLoadingDelete: true })
        const config = {
            method: 'delete',
            url: '/campaigner/account-number/' + this.state.deleteId,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            },
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            await this.apiRekening()
            toast.info('Rekening berhasil ditambahkan', {
                position: "top-center",
                hideProgressBar: true,
                autoClose: 3000,
            });
            this.setState({ showConfirm: false, deleteId: '' })
        }
        this.setState({ isLoadingDelete: false })
    }
    componentDidMount() {
        if(this.state.auth){
            if(this.state.auth.campaigner) {
                const { auth } = this.state
                const token = auth && auth.access_token ? `Bearer ${auth.access_token}` : null;
                this.setState({ token, isFetching: true, is_foundation: auth.campaigner.is_foundation })
            } else {
                this.props.history.push('/profile')
            }
        } else {
            this.props.history.push('/login')
        }
    }
    componentDidUpdate(_, prev) {
        if(this.state.isFetching && prev.isFetching !== this.state.isFetching) {
            this.apiRekening()
        }
    }
    render() {
        const { auth, isFetching, rekening } = this.state
        return (
            <React.Fragment>
                <Header 
                    title={`Rekening Pencairan`}
                    prevPage={"/campaigner/" + (auth && auth.campaigner ? auth.campaigner.slug : '-')} />
                <main>
                    {!isFetching && 
                        <Container>
                            {rekening.length === 0 && 
                                <div className="content-grid grid-2 info-form mb-3">
                                    <div><Image className="loader-img" src={IcWarningYellow}/></div>
                                    <div className="text-left ml-3">
                                        Rekening pencairan Anda masih kosong. Tambahkan sekarang untuk melakukan pencairan penggalangan.
                                    </div>
                                </div>
                            }
                            <div className="rekening-list">
                                {rekening.map((val, i) => (
                                    <div className="rekening-item" key={i}>
                                        <div className="rekening-info">
                                            <div className="txt-caption">Bank {val.bank}</div>
                                            <div className="txt-subtitle">{val.account_number}</div>
                                            <div className="txt-caption">a.n. {val.account_holder}</div>
                                        </div>
                                        <div className="rekening-action">
                                            <Button variant="secondary" onClick={() => this.showConfirmDelete(val.id)}>Hapus</Button>
                                        </div>
                                    </div>
                                ))}
                                <ModalConfirmDelete 
                                    onHide={this.onHideModalConfirmDelete}
                                    deleteRekening={this.deleteRekening}
                                    state={this.state}
                                />
                                <ModalPostRekening 
                                    onHide={this.onHideModalPostRekening}
                                    handleChange={this.handleChange} 
                                    errorMessage={this.props.errorMessage}
                                    addRekening={this.addRekening}
                                    state={this.state}
                                />
                            </div>
                            <Button size="sm" variant="secondary" className="border-dashed" onClick={() => this.setState({ show: true })} block>
                                <Image src={IcPlus} /> Tambah Rekening
                            </Button>
                        </Container>
                    }
                </main>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RekeningCampaigner));