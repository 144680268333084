import React from "react";
import {Spinner, Container, Image} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { useHistory, Redirect, Link } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";
import postData from './postData';
import { toast } from 'react-toastify';
import {StringToSlug} from '../../../../utils';

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import RedirectStep from "./RedirectStep";

import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';
import { Header, Wizard } from '../../../organisms';
import icCalendar from '../../../../assets/svg/ic-calendar.svg';

const isMobileDevice = () => {
  const mobileUserAgent = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  return mobileUserAgent.some((item) => navigator.userAgent.match(item));
};

const Step5 = props => {
  const [isMobile] = React.useState(isMobileDevice())
  const { state, action } = useStateMachine(updateAction);
  const { handleSubmit, register, errors } = useForm({
    defaultValues: state.postData
  });
  const { push } = useHistory();
  const onSubmit = async (data) => {
    data['stepgal5'] = true;
    action(data);

    submitForm();
  };

  const today = () => {
    let d = new Date();
    let mm = d.getMonth() + 1;
    let dd = d.getDate();
    let yy = d.getFullYear(); 
    let myDateString = yy + '-' + mm + '-' + dd;
    return myDateString;
  }
  const submitForm = async () => {

    const auth = JSON.parse(localStorage.getItem("userData"));
    const token = `Bearer ${auth.access_token}`;
    const campaigner_id = auth.campaigner_id;

    let formData = {
      title: state.postData.title,
      slug: StringToSlug(state.postData.slug),
      body: state.postData.body,
      status: (!state.postData.status ? 'Draft' : state.postData.status),
      target: state.postData.target,
      budget_plan: state.postData.budget_plan,
      distribution_address: state.postData.distribution_address,
      area_id: state.postData.category_id === 'donasi-umum' ? state.postData.area_id : state.postData.kecamatan,
      cover: state.postData.cover,
      category_slug: state.postData.category_id,
      campaigner_id: state.campaigner_id,
      publish_at: (state.postData.publish_at ? state.postData.publish_at : today()),
      expired_at: (state.postData.expired_at ? state.postData.expired_at : today()),
      link_asset: state.postData.link_asset,
    };
    
    const editCampaignSlug = (state.postData.editCampaignSlug ? state.postData.editCampaignSlug : '');
    const newSlug = formData.slug;
    const config = {
        method: (editCampaignSlug === '' ? 'post' : 'put'),
        url: '/campaign/'+editCampaignSlug,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        data: formData
    };

    const res = await props.API(config, true, true).catch(err=>err);
    if (res) {
        const msg = (editCampaignSlug === '') ? 'ditambahkan' : 'diperbaharui';
        toast.info('Campaign anda berhasil '+msg, {
            position: "top-center",
            hideProgressBar: true,
            autoClose:3000,
        });
        setTimeout(() =>{
          action(postData);
          if(editCampaignSlug !== ''){
            localStorage.removeItem("detailCampaign");
          }
          push("/detail-campaign/"+newSlug);
        },1000 );
    }else{
      let data={};
      data['checkMessage'] = true;
      action(data);
    }
  }
  const checkdate = (date) => {
    let data = {};
    data['publish_at_date'] = date;
    data['publish_at'] = formatDate(date);
    action(data);
  }
  const formatDate = (date) => {
    let d = (date ? new Date(date) : new Date());
    let mm = d.getMonth() + 1;
    let dd = d.getDate();
    let yy = d.getFullYear(); 
    let myDateString = yy + '-' + mm + '-' + dd;
    return myDateString;
  }

  const handleChange = (e) =>{
    let val = e.target.value;
    let field = e.target.name;
    let data = {};
    data[field] = val;
    if(field === 'is_agree'){
      data['is_agree'] = e.target.checked;
    }else{ 
      if(field === 'status' && val==='Publish'){
        data['publish_at'] = new Date();
      }else{
        data['publish_at'] = "";
        data['publish_at_date'] = "";
      }
    }
    data['stepgal5'] = false;
    action(data);
  }

  
  const editCampaign = (state.postData.editCampaign ? state.postData.editCampaign : '');
  const initialSteps = {
    class1:"",
    number1:4,
    label1:"Deskripsi Campaign",
    link1:"/galang-dana/step4"+editCampaign,

    class2:"step-active last-step",
    number2:5,
    label2:"Konfirmasi",
    link2:"/galang-dana/step5"+editCampaign,

    class3:"step-hide",
    number3:"",
    label3:"",
    link3:"",
  }

  const linkPrev = "/galang-dana/step4"+editCampaign;
  
  return (
    <>
      <Header title={(state.postData.editCampaign ? "Ubah Data Campaign " : "Galang Dana")} prevPage={linkPrev} ic_white={true}/>
      <Wizard initialSteps={initialSteps} />
      <main className="pb-0">
        <Container>  
          {(!state.postData.stepgal4 && !state.postData.stepgal5) && 
            <Redirect to="/galang-dana/step4" />
          }
          <form onSubmit={handleSubmit(onSubmit)} className="form-hook">
            <h6 className="mb-3">Kapan penggalangan dana ini di publish</h6>
            <div className="md-radio">
              <input 
                id={'Publish'} 
                type="radio" 
                name="status" 
                value={'Publish'}
                checked={(state.postData.status === "Publish" ? true : false)}
                onChange={e => handleChange(e)}
              />

              <label htmlFor={'Publish'}>
                <div className="txt-body-m">Publish Sekarang</div>
              </label>
            </div>
            <hr/>
            <div className="md-radio">
              <input 
                id={'Draft'} 
                type="radio" 
                name="status" 
                value={'Draft'}
                checked={(state.postData.status === "Draft" ? true : false)}
                onChange={handleChange}
              />

              <label htmlFor={'Draft'}>
                <div className="txt-body-m">Pilih Tanggal Lain</div>
              </label>
            </div>
            
            {state.postData.status === 'Draft' &&
              <div className="form-group">
                <label className="form-label">Waktu Publish</label>
                
                {isMobile && 
                  <input 
                    type="date" 
                    name="publish_at_date"
                    className="form-control"
                    onChange={e => checkdate(e.target.value)}
                    ref={register({ required: "Waktu Publish wajib diisi" })}
                    placeholder="Pilih Tanggal"
                    min={formatDate(new Date())}
                    defaultValue={state.postData.publish_at ? state.postData.publish_at : ''}
                  />
                }
                {!isMobile && 
                  <div className="fh-input-group ig-right">
                    <ReactDatePicker 
                      className="form-control"
                      dateFormat="yyyy-MM-dd"
                      placeholderText="Pilih Tanggal"
                      selected={(state.postData.publish_at ? new Date(state.postData.publish_at) : '')}
                      onChange={date => checkdate(date)}
                      minDate={new Date()}
                    />
                    <div className="input-right">
                      <Image src={icCalendar} />
                    </div>
                  </div>
                }

                {!state.postData.publish_at_date && 
                  <div>
                    <input
                    name="publish_at_date"
                    className="hide"
                    ref={register({ required: "Waktu Publish wajib diisi" })}
                    />
                    <ErrorMessage errors={errors} name="publish_at_date" as={<p className="error-input"></p>} />
                  </div>
                }
                
                {state.postData.errorMessages.publish_at && 
                <p className="error-input">{state.postData.errorMessages.publish_at}</p>}
              </div>
            }
            
            <hr className="hr-last"/>
            
            <div className="form-group alert-info">
              <input 
                className="styled-checkbox" 
                id="styled-checkbox-1" 
                type="checkbox" 
                ref={register({required: "Anda wajib mencentang bagian ini"})}
                name="is_agree"
                onChange={handleChange}
              />
              <label htmlFor="styled-checkbox-1" className="label txt-body-m">
                Saya Setuju dengan <Link to="/syarat-ketentuan">Syarat & Ketentuan</Link> Penggalangan Dana di Bantu Tetangga, termasuk <Link to="/faq/detail/biaya-operasional">biaya operasional platform sebesar 5%</Link> dari total bantuan yang terkumpul.
              </label>
              
              <ErrorMessage errors={errors} name="is_agree" as={<p className="error-input"></p>} />
            </div>
            <button type="submit" className="btn btn-primary btn-block btn-next mt-0" disabled={(props.isLoading || !state.postData.is_agree ? true : false)}>
              { (!props.isLoading) && <span>{(state.postData.status === 'Publish' ?'Publish Campaign' : 'Simpan sebagai Draft')}</span> }
              { props.isLoading && <Spinner animation="border" role="status"/> }
            </button>
            
            {state.postData.checkMessage &&
              <RedirectStep errorMessage={props.errorMessage} />
            }
          </form>
        </Container>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
  clearDispatch: () => dispatch(clearDispatch()),
  API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step5));

