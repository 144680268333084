import React, { useState, useEffect } from 'react';
import { Modal, Image } from 'react-bootstrap';
import { API } from '../../../config';
import Slider from 'react-slick';
import LazyLoad from 'react-lazyload';

function ModalInstallPWA ({ isOpen, onClose }) {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      console.log("we are being triggered :D");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);
  const handleInstallClick = evt => {
    if (supportsPWA) {
      evt.preventDefault();
      if (!promptInstall) {
        return;
      }
      promptInstall.prompt();
    }
    const auth = JSON.parse(localStorage.getItem("userData"));
    try {
      const payload = {
        browser: navigator.userAgent,
        device: navigator.userAgentData.platform,
        ip_address: window.sessionStorage.getItem('ip_address'),
        latitude: window.sessionStorage.getItem('latitude'),
        longitude: window.sessionStorage.getItem('longitude'),
        version: "1.0.0",
        user_id: auth.id,
      }
      const res = API.post("/pwa-install", payload );
      console.log(res, "data");
    } catch (error) {
      console.log(error)
    }
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
    onClose();
    
  };
  // if (!supportsPWA) {
  //     return <div></div>;
  // }
  const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: 'unset',
        border: 0,
        borderRadius: 8,
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
      },
  };
  let image_pwa = [
    {
      "image": "https://space.adaide.co.id/bantutetangga/public/cover-homescreen1.svg",
    },
    {
      "image": "https://space.adaide.co.id/bantutetangga/public/cover-homescreen2.svg",
    },
    {
      "image": "https://space.adaide.co.id/bantutetangga/public/cover-homescreen3.svg",
    }
  ]
  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      contentLabel="Modal"
      style={customStyles}
      ariaHideApp={false}
      animation={false}
      className="modal-content-pwa"
      centered
    >
      <Slider autoplay={true} arrows={false} dots={false} infinite={true} speed={300} autoplaySpeed={3000}>
          {image_pwa.map((row, i) => {
              return <LazyLoad><Image key={row.slug} src={row.image} className="img-fluid img-thumb-pwa" /></LazyLoad>
          })}
      </Slider>
      <div tw="text-center" className="modal-install-pwa">
        <h6>Sudah siap #BantuYangTerdekat ?</h6>
        <p>Sekarang Bantutetangga.com akan selalu dekat dalam genggaman di aplikasi Bantu Tetangga</p>
        <button
            className="btn btn-primary mt-3 mb-3"
            onClick={handleInstallClick}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
              <g clip-path="url(#clip0_6209_33730)">
                <path d="M16.3334 16.6667V14.1667H13.8334V12.5H16.3334V10H18V12.5H20.5V14.1667H18V16.6667H16.3334ZM3.83336 17.5C3.37502 17.5 2.98252 17.3367 2.65586 17.01C2.32919 16.6833 2.16613 16.2911 2.16669 15.8333V4.16667C2.16669 3.70833 2.33002 3.31583 2.65669 2.98917C2.98336 2.6625 3.37558 2.49945 3.83336 2.5H15.5C15.9584 2.5 16.3509 2.66333 16.6775 2.99C17.0042 3.31667 17.1672 3.70889 17.1667 4.16667V8.33334H15.5V6.66667H3.83336V15.8333H14.6667V17.5H3.83336Z" fill="white"/>
              </g>
              <defs>
                <clipPath id="clip0_6209_33730">
                  <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
                </clipPath>
              </defs>
            </svg>
            Install di Homescreen
        </button>
      </div>
      <div className="bg-footer-modal">
        <button onClick={onClose}>
          Tutup bantu di website 
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.43433 3.43353C3.74675 3.12111 4.25328 3.12111 4.5657 3.43353L8.00001 6.86785L11.4343 3.43353C11.7467 3.12111 12.2533 3.12111 12.5657 3.43353C12.8781 3.74595 12.8781 4.25248 12.5657 4.5649L9.13138 7.99922L12.5657 11.4335C12.8781 11.746 12.8781 12.2525 12.5657 12.5649C12.2533 12.8773 11.7467 12.8773 11.4343 12.5649L8.00001 9.13059L4.5657 12.5649C4.25328 12.8773 3.74675 12.8773 3.43433 12.5649C3.12191 12.2525 3.12191 11.746 3.43433 11.4335L6.86864 7.99922L3.43433 4.5649C3.12191 4.25248 3.12191 3.74595 3.43433 3.43353Z" fill="#6B7280"/>
          </svg>
        </button>
      </div>
    </Modal>
  );
};

export default ModalInstallPWA;
