import React from 'react'
import { Container, Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import Moment from 'react-moment';
import { clearDispatch, API } from '../../../../config/redux/action';
import { Header, CampaignerCampaignCard, Filter, FilterSort } from '../../../organisms';
import icCertified from '../../../../assets/svg/ic-certified.svg';
import icExternalLink from '../../../../assets/svg/ic-external-link.svg';
import './style.css';
import avatar from "../../../../assets/img/avatar/no-img.png";
import LoadingCampaign from '../../../organisms/CampaignerCampaignCard/Loading'
import * as QueryString from "query-string";
import { ExternalLink } from 'react-external-link';

import debounce from "lodash.debounce";
import IcWarningYellow from '../../../../assets/svg/ic-warning-yellow.svg';
import icClose from '../../../../assets/svg/ic-close.svg';
import icFile from '../../../../assets/svg/ic-dokumen.svg';
import icPencil from '../../../../assets/svg/ic-pencil-1.svg';
import icRekening from '../../../../assets/svg/ic-pencairan.svg';
import icSearchGray from '../../../../assets/svg/ic-search-gray.svg';
import icChevronDown from '../../../../assets/svg/ic-chevron-down.svg';
import icChevronDownActive from '../../../../assets/svg/ic-chevron-down-active.svg';
import icVerified from '../../../../assets/svg/ic-verified-campaigner.svg';
import icFoundation from '../../../../assets/svg/ic-foundation-small.svg';
import icCommunity from '../../../../assets/svg/ic-community-small.svg';
import icCategory from '../../../../assets/svg/ic-kategori.svg';
import icStatus from '../../../../assets/svg/ic-filter.svg';
import icSort from '../../../../assets/svg/ic-sort.svg';
import icLocation from '../../../../assets/svg/ic-loc.svg';
import icUser from '../../../../assets/svg/ic-user.svg';
import icLinkSosmed from '../../../../assets/svg/ic-linksosmed.svg';
import { Input } from "../../../../component/atoms";
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';

class ProfilCampaigner extends React.Component {
    state = {
        auth: {},
        isCampaignerSelf: false,
        token: '',
        isFetching: true,
        campaigner: {},
        readMore: false,
        categories:[],

        /** Filter */
        states:[{
                name:'Aktif',
                slug:'active'
            },
            {
                name:'Berakhir',
                slug:'expired'
            },
            {
                name:'Draft',
                slug:'draft'
            },
            {
                name:'Diblokir',
                slug:'blocked'
            },
        ],
        orderBy:[{
                name:'Dibuat',
                slug:'created'
            },
            {
                name:'Sisa Waktu',
                slug:'timerest'
            },
            {
                name:'Dana Terkumpul',
                slug:'funds'
            },
        ],
        sort:[{
                name:'Descending',
                slug:'desc'
            },
            {
                name:'Ascending',
                slug:'asc'
            },
        ],
        filterSearch: '',
        filterStatus: '',
        filterKategori: '',

        filterUrutkan:false,
        filterSort: 'desc',
        filterOrderBy: 'created',
        
        showFilterStatus:false,
        showFilterKategori:false,
        showFilterSort:false,
        
        /** Results state */
        results: [],
        isFetchingResults: false,
        currentPage: 1,
        lastPage: null,
        total: -1,

        tabbarActive:'penggalangan'
        // tabbarActive:'informasi'
        
    }
    constructor(props) {
        super(props)
        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = auth && auth.access_token ? `Bearer ${auth.access_token}` : null;
        this.state.auth = auth;
        this.state.token = token;
        if(auth && auth.campaigner && auth.campaigner.slug === props.match.params.slug) {
            this.state.isCampaignerSelf = true;
        } 

        this.handleScroll = this.handleScroll.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.resetResults = this.resetResults.bind(this)
        this.resetResults = debounce(this.resetResults, 500)

        this.handleFilterStatus = this.handleFilterStatus.bind(this)
        this.handleFilterKategori = this.handleFilterKategori.bind(this)
        this.handleFilterSort = this.handleFilterSort.bind(this)
        this.handleFilterOrderBy = this.handleFilterOrderBy.bind(this)
    }

    resetResults() {

        this.setState({ 
            isFetchingResults: true, 
            currentPage: 1, 
            total: -1, 
            results: [],
            showFilterStatus:false,
            showFilterKategori:false,
            showFilterSort:false,
            filterUrutkan:true,
        })
    }
    
    handleSearch = (e) => {
        this.setState({ filterSearch: e.currentTarget.value })
        if(e.currentTarget.value.length > 1 || e.currentTarget.value.length === 0) {
            this.resetResults()
        }
    }

    onHideModal(container){
        switch (container) {
            case 'status':
                this.setState({ showFilterStatus: false })
                break;
            case 'kategori':
                this.setState({ showFilterKategori: false })
                break;
            case 'sort':
                this.setState({ showFilterSort: false })
                break;
            default:
                break;
        }
    }
    handleFilterStatus(status) {
        this.setState({ filterStatus: status })
    }
    handleFilterKategori(kategori) {
        this.setState({ filterKategori: kategori })
    }
    handleFilterSort(sort) {
        this.setState({ filterSort: sort})
    }
    handleFilterOrderBy(orderBy) {
        this.setState({ filterOrderBy:orderBy })
    }

    async apiCampaigner() {
        this.setState({ isFetching: true })

        const { API, match } = this.props
        const config = {
            method: 'get',
            url: '/public/campaigner/' + match.params.slug,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            }
        };

        const res = await API(config, false).catch(err=>err);
        if(res){
            let datasosmed = JSON.parse(res.data.social_media);
            console.log(datasosmed, 'datasosmed');
            let returndata = res.data;
            if(datasosmed.instagram){
                returndata['social_media'] = datasosmed.instagram;
                returndata['sosmed'] = 'Instagram';
                returndata['urlsosmed'] = 'https://www.instagram.com/'+datasosmed.instagram;
            }
            if(datasosmed.facebook){
                returndata['social_media'] = datasosmed.facebook;
                returndata['sosmed'] = 'Facebook';
                returndata['urlsosmed'] = 'https://www.facebook.com/'+datasosmed.facebook;
            }
            if(datasosmed.twitter){
                returndata['social_media'] = datasosmed.twitter;
                returndata['sosmed'] = 'Twitter';
                returndata['urlsosmed'] = 'https://www.twitter.com/'+datasosmed.twitter;
            }
            else {
                returndata['urlsosmed'] = datasosmed.undefined;
            }

            this.setState({ campaigner: returndata })
        }
        this.setState({ isFetching: false })
    }

    async apiResult() {

        let url = this.props.location.pathname;
        let pathArr = url.split('/');
        let campaignerSlug = pathArr[pathArr.length - 1];

        const perPage = 5;
        let params = `?page=${this.state.currentPage}&per_page=${perPage}`
        params += `&sort=` + this.state.filterOrderBy +'-'+this.state.filterSort
        if(this.state.filterStatus) {
            params += `&status=` + this.state.filterStatus
        }
        if(this.state.filterKategori) {
            params += `&category_slug=` + this.state.filterKategori
        }
        if(this.state.filterSearch.length > 1) {
            params += `&str=` + this.state.filterSearch
        }

        const { API } = this.props
        const config = {
            method: 'get',
            url: '/public/campaigner/campaigns/'+campaignerSlug + params,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            }
        };
        const res = await API(config, false).catch(err=>err)
        if (res) {
            this.setState({ 
                results: [ ...this.state.results, ...res.data.data ], 
                isFetchingResults: false,
                total: res.data.total,
                currentPage: this.state.currentPage + 1,
                lastPage: res.data.last_page
            })

            if(window.innerHeight > 640 && this.state.results.length <= perPage)
                this.setState({ isFetchingResults: true })
        }
    }
    componentDidMount() {
        this.apiCampaigner()
        window.addEventListener('scroll', this.handleScroll);
        this.apiResult();

        this.loadCategories();
    }
    resetKeyword = () => {
        this.setState({ filterSearch: '' })
        this.resetResults()
    }

    handleScroll() {
        // const isScrolled = document.documentElement.offsetHeight - Math.ceil(window.innerHeight + document.documentElement.scrollTop);
        // if (isScrolled > 150 || this.state.isFetchingResults)
        //     return;
        // this.setState({isFetchingResults: true})
    }
    componentDidUpdate(_, prevState) {
        if(this.state.isFetchingResults !== prevState.isFetchingResults) {
            const { isFetchingResults, results, total, filterSearch, filterStatus, filterKategori, filterDate, startDate, endDate } = this.state;

            if ( filterSearch !== prevState.filterSearch || filterStatus !== prevState.filterStatus 
                || filterKategori !== prevState.filterKategori || filterDate !== prevState.filterDate 
                || startDate !== prevState.startDate || endDate !== prevState.endDate 
                ) {
                this.apiResult()
                return
            }

            // if (isFetchingResults === false || (results.length === total && total !== -1)) {
            //     this.setState({isFetchingResults: false});
            //     return;
            // }
            // this.apiResult();
        }
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll); 
    }

    async loadCategories() {
        const config = {
            method: 'get',
            url: '/category',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            }
        };
        const res = await this.props.API(config, false).catch(err=>err)
        if (res) {
            this.setState({ categories: res.data.data })
        }
    }
    
    render () {
        const {
            results,
            total,
            campaigner,
            isFetchingResults,
            search,
            tabbarActive,
            isCampaignerSelf,
            lastPage,
            currentPage
        } = this.state
        const queryParams = QueryString.parse(this.props.location.search);
        const prevPage = (queryParams.prevPage ? ("?prevPage="+queryParams.prevPage) : "");

        return (
            <React.Fragment>
                {/* <Header title="Penggalang" prevPage="/profile" cols={3} position3={IcEditHeader} linkPost3="/profile-campaigner" /> */}
                <Header title="Profil Penggalang" prevPage="/profile"/>
                <main className="akun-saya bg-grey">
                        <div className="profil-campaigner">
                            <Container className="main-info">
                                <div className="card-profile">
                                    <div className="penggalang-crop"><Image src={!campaigner.logo ? avatar : campaigner.logo}/></div>
                                    <div className="text-left">
                                        <div className="txt-subtitle-m fw-6 ct-title">
                                            {campaigner.name}
                                            {campaigner.is_verified &&
                                                <Image src={icVerified} className="ml-1 ic-check-campaigner"/>
                                            }
                                            {campaigner.is_foundation &&
                                                <Image src={icFoundation} className="ml-1 ic-check-campaigner"/>
                                            }
                                            {campaigner.is_community &&
                                                <Image src={icCommunity} className="ml-1 ic-check-campaigner"/>
                                            }
                                        </div>
                                        <div className="txt-caption-m">
                                            {campaigner.is_verified &&
                                                "Akun terverifikasi"
                                            }
                                        </div>
                                    </div>
                                    {isCampaignerSelf &&
                                        <Link to="/profile-campaigner" className="btn btn-block fw-5 no-border text-left pb-0">
                                            <Image src={icPencil}/>
                                        </Link>
                                    }
                                </div>
                                {isCampaignerSelf && !campaigner.approved_at &&
                                    <>
                                        {campaigner.status === 'Menunggu Persetujuan' &&
                                            
                                            <div className="content-grid grid-2 info-form mb-16">
                                                <div><Image className="loader-img" src={IcWarningYellow}/></div>
                                                <div className="text-left ml-3">
                                                    {/* Akun Anda belum diverifikasi.<br/><Link to="">Verifikasi disini</Link> untuk melakukan pencairan. */}
                                                    Lembaga Anda sedang dalam proses verifikasi. Cek kembali beberapa saat.
                                                </div>
                                            </div>
                                        }  
                                    </>
                                }  
                                <div className="d-flex informasi-count-campaigner">
                                    <div className="txt-body-m col-6">
                                        <span className="fw-5">{campaigner.campaigns_active_count}</span>
                                        <p>Program Aktif</p>
                                    </div>
                                    <div className="txt-body-m col-6">
                                        <span className="fw-5">{campaigner.donatur_count}</span>
                                        <p>Donatur</p>
                                    </div>
                                </div>     
                            </Container>
                            <div className="content-space-campaigner"></div>
                            <Container>
                                <div className="txt-subtitle-m cp-title fw-6 mb-10">Tentang Penggalang</div>
                                <div className="txt-body-m">
                                    {campaigner.bio ? campaigner.bio : 'Belum ada deskripsi'}
                                </div>
                                <div className="informasi mt-20 mb-16">
                                    <div className="d-flex mb-16">
                                        <Image src={icLocation} className="mr-3" />
                                        <div className="txt-body-m">Kota Bandung, Jawa Barat</div>
                                    </div>
                                    <div className="d-flex mb-16">
                                        <Image src={icUser} className="mr-3" />
                                        <div className="txt-body-m">
                                            Bergabung sejak 
                                            <Moment format=" D MMMM YYYY">
                                                {campaigner.created_at}
                                            </Moment>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <Image src={icLinkSosmed} className="mr-3"/> 
                                        <div className="txt-body-m fw-4">
                                            <ExternalLink href={campaigner.urlsosmed}> 
                                            Media Social 
                                            {campaigner.sosmed}
                                            </ExternalLink>
                                        </div>
                                    </div>
                                </div>
                            </Container>

                            <div className="content-space-campaigner"></div>
                            {isCampaignerSelf &&
                                <>
                                    <div className="container">
                                        <div className="txt-body-m ct-title">
                                            <Link to="/profile-campaigner/rekening" className="btn btn-block fw-5 no-border text-left pb-0">
                                                <Image src={icRekening} className="mr-3" /> Rekening Pencairan
                                            </Link>
                                            <hr />
                                            <Link to="/profile-campaigner/dokumentasi" className="btn btn-block fw-5 no-border text-left pb-0">
                                                <Image src={icFile} className="mr-3" /> Dokumentasi Lembaga
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="content-space-campaigner"></div>
                                </>
                            }
                            <Container>
                                <div className="txt-subtitle-m cp-title fw-6 mb-10">Program</div>
                                <div className="search-page mb-16">
                                    <Input 
                                        type="text" 
                                        name="search"
                                        formGroupClass="search-form txt-body mt-0" 
                                        placeholder="Cari Program..." 
                                        handleFormChange={this.handleSearch}
                                        value={search}
                                        icon_left={icSearchGray}
                                        icon_right={(search ? icClose : '')}
                                        icon_right_click = {this.resetKeyword}
                                    />
                                    <div className="modal-filter mt-16 ">
                                        <div className="col-5" onClick={() => this.setState({showFilterKategori:true})}>
                                            <Image src={icCategory}/>
                                            <span>{this.state.filterKategori === '' ? 'Semua Kategori' : this.state.filterKategori }</span>
                                        </div>
                                        <div className="col-5" onClick={ () => this.setState({showFilterStatus:true})}>
                                            <Image src={icStatus}/>
                                            <span>{this.state.filterStatus === '' ? 'Semua Status' : this.state.filterStatus }</span>
                                        </div>
                                        <div className="col-2" onClick={() => this.setState({showFilterSort:true})}>
                                            <Image src={icSort}/>
                                        </div>
                                    </div>
                                </div>

                                <InfiniteScroll
                                    pageStart={1}
                                    loadMore={()=> this.apiResult()}
                                    hasMore={currentPage <= lastPage}
                                    loader={<LoadingCampaign key={0} />}
                                >
                                    <div className="row mt-3" style={{marginLeft: -4, marginRight: -4}}>
                                        {results.map((campaign, i) => (
                                            <div className="col-6" style={{paddingLeft: 4, paddingRight: 4}}  key={campaign.slug + i}>
                                                <CampaignerCampaignCard campaign={campaign} key={i} prevPage={prevPage} />
                                            </div>
                                        ))}
                                    </div>
                                </InfiniteScroll> 
                            </Container>
                        </div>
                    <Filter 
                        onHide={() => this.onHideModal('status')} 
                        field={this.state.filterStatus} 
                        handleFilter={this.handleFilterStatus} 
                        show={this.state.showFilterStatus}
                        resetResults={this.resetResults}
                        options={this.state.states}
                        label="Status"
                    />
                    <Filter 
                        onHide={() => this.onHideModal('kategori')} 
                        field={this.state.filterKategori} 
                        handleFilter={this.handleFilterKategori} 
                        show={this.state.showFilterKategori}
                        resetResults={this.resetResults}
                        options={this.state.categories}
                        label="Kategori"
                    />
                    <FilterSort 
                        onHide={() => this.onHideModal('sort')} 
                        filterSort={this.state.filterSort} 
                        filterOrderBy={this.state.filterOrderBy} 
                        handleFilterSort={this.handleFilterSort} 
                        handleFilterOrderBy={this.handleFilterOrderBy} 
                        show={this.state.showFilterSort}
                        resetResults={this.resetResults}
                        sort={this.state.sort}
                        orderBy={this.state.orderBy}
                        label="Urutkan"
                    />
                </main>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfilCampaigner));