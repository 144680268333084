import React from 'react'
import { Form, Image, InputGroup } from 'react-bootstrap';

const Input =  (props) => {
    return (
        <Form.Group className={ props.formGroupClass } >
            <Form.Label className={ props.labelClass }>
                {props.label} 
                {props.infoLabel &&
                    <span className={props.classInfoLabel}> (*{props.infoLabel})</span>
                }
            </Form.Label>
            <InputGroup className={ props.errorMessage ? "input-error" : props.inputGroupClass }>
                {props.icon_left && 
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            <Image src={props.icon_left} />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                }
                <Form.Control
                type={props.type}
                name={props.name}
                value={props.value}
                placeholder={props.placeholder}
                onChange={props.handleFormChange}
                autoFocus={props.autoFocus || false}
                />
                {props.icon_right && 
                    <InputGroup.Prepend className="cursor-pointer" onClick={props.icon_right_click} >
                        <InputGroup.Text>
                            <Image src={props.icon_right}/>
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                }
            </InputGroup>   
            { props.errorMessage && 
                <Form.Text className="text-left text-danger">
                    { props.errorMessage }
                </Form.Text>
            }
        </Form.Group>
    )
}

Input.defaultProps = {
    classname: '',
    icon_left: '',
    icon_right: '',
    type: 'text',
    name: 'name',
    placeholder: 'type here..',
    errorMessage: ''
}

export default Input
