import React from 'react'
import CurrencyFormat from 'react-currency-format';
import {Link} from 'react-router-dom';

const RincianDana =  ({charge, campaignSlug, ...props}) => {
  return (
    <>
      <div className="campaign-charge content-grid grid-2">
        <div className={charge.color}>{charge.items}</div>
        <div>
          <CurrencyFormat 
            value={(parseFloat(charge.amount))}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={'- Rp '} 
            className={charge.color}
            displayType={'text'}
          />
        </div>
      </div>
      {charge.slug === 'pending' && 
        <Link to={"/faq/detail/pencairan-1n?prevPage=pencairan/"+campaignSlug} className="txt-caption-m ct-primary1 mt-0">Pelajari lebih lanjut</Link>
      }
    </>
  )
}

export default RincianDana
