import React from 'react';
import { Image,Modal } from "react-bootstrap";
import icCloseModal from '../../../../assets/svg/ic-close-modal.svg';
import {Link} from 'react-router-dom';
import { Share } from "../../../organisms";

const ModalCardOptions =  (props) => {
    const { show, onHide } = props
    const [shareModal, setShareModal] = React.useState(false)
    const handleShare = (e) => {
        e.stopPropagation()
        onHide()
        setShareModal(true)
    }
    return (
        <>
            <Modal show={show} onHide={onHide} animation={false} centered className="modal-bottom">
                <Modal.Header >
                    <Modal.Title id="contained-modal-title-vcenter">
                        <Image src={icCloseModal} onClick={onHide} className="cursor-pointer" /> 
                        <span className="txt-title">Lainnya</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="no-border">
                        <div className="list-option" onClick={(e) => handleShare(e)}>
                            <div>Bagikan</div>
                        </div>
                        { (props.data.status === 'Publish') && 
                            <>
                            <Link className="list-option" to={"/tulis-berita/"+props.data.slug+"?prevPage=campaign-saya"}>
                                <div>Tulis Berita</div>
                            </Link>
                            {/* <Link className="list-option" to={"/pencairan/"+props.data.slug}>
                                <div>Cairkan Dana</div>
                            </Link> */}
                            <Link className="list-option" to={"/development"}>
                                <div>Cairkan Dana</div>
                            </Link>
                            </>
                        }
                        <div className="list-option" onClick={props.handleEditCampaign}>
                            <div>Ubah Data</div>
                        </div>
                        <Link className="list-option" to={"/detail-campaign/perpanjang/"+props.data.slug}>
                            <div>Perpanjangan Waktu</div>
                        </Link>
                        <Link className="list-option" to={"/detail-campaign/"+props.data.slug}>
                            <div>Preview Penggalangan</div>
                        </Link>
                        <div className="list-option" onClick={props.handleEndCampaign}>
                            <div>Akhiri Penggalangan</div>
                        </div>
                    </div>
                    
                    
                </Modal.Body>
            </Modal>
            <Share 
                    show={shareModal} 
                    onHide={() => setShareModal(false)}
                    url={'/campaign/' + props.data.slug}
                    slug={props.data.slug}
                    quote={props.data.title}
                    body={props.data.body} />
        </>
    )
}

export default ModalCardOptions