import React from "react";
import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";
import postData from "./postData";


const Result = props => {
  const { state, action } = useStateMachine(updateAction);

  function resetstate(){
    // let postData = {};
    // postData['provinsi'] = '';
    // postData['loadProvinsi'] = false;
    // postData['dataProvinsi'] = false;
    // postData['kota'] = '';
    // postData['loadKota'] = true;
    // postData['dataKota'] = false;
    // postData['expired_at_date'] = "";
    // localStorage.removeItem("detailCampaign");
    action(postData);
  }

  return (
    <div className="container">
      <h2>Result</h2>
      <pre>{JSON.stringify(state, null, 2)}</pre>
      <button onClick={resetstate}>Reset</button>
    </div>
  );
};

export default Result;
