import React from 'react'

import { ProgressBar, Image } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { Link } from 'react-router-dom';

import icVerified from '../../../assets/svg/ic-verified-campaigner.svg';
import icFoundation from '../../../assets/svg/ic-foundation-small.svg';
import icCommunity from '../../../assets/svg/ic-community-small.svg';

import LazyLoad from 'react-lazyload';

import '../../../assets/css/fonts.css'
import './style.css'

export default function CampaignCard({ campaign, classes, prevPage }) {
    const campaignsCampaigner = (!campaign.campaigner.name) ? ' mt-3 mb-2' : '';
    const defaultClasses = {
        root:'campaign-card',
        Image:'campaign-card-image',
        InfoBox:'campaign-card-info-box',
        CampaignName:'campaign-card-campaign-name',
        CampaignerName:'campaign-card-campaigner-name',
        CampaignProgress:('campaign-card-campaign-progress'+campaignsCampaigner),
        ProgressInfo:'campaign-card-progress-info',
        ProgressInfoLeft:'campaign-card-progress-info-left',
        ProgressInfoRight:'campaign-card-progress-info-right',
    }

    const ovClasses = { ...defaultClasses, ...classes ? classes : {} }

    const linkPrev = (prevPage ? prevPage : '');
    return (
        <Link className={ovClasses.root} to={"/campaign/"+campaign.slug+linkPrev}>
            {/* https://web.dev/aspect-ratio/ */}
            <LazyLoad>
                <div
                    style={{
                        position: "relative",
                        width: "100%",
                        paddingTop: "56.25%",
                        overflow: "hidden"
                    }}
                >
                        <img 
                            src={campaign.cover} 
                            alt="cover" 
                            style={{
                                maxWidth: "100%",
                                position: "absolute",
                                top: 0,
                                borderTopLeftRadius: 5,
                                borderTopRightRadius: 5,
                            }} 
                        />
                </div>
            </LazyLoad>
        
            <div className={ovClasses.InfoBox}>
                <div className={ovClasses.CampaignName}>{campaign.title}</div>
                {campaign.campaigner.name &&
                    <div className="content-flex">
                        <div className={ovClasses.CampaignerName}>
                            {campaign.campaigner.name}
                        </div>
                        {campaign.campaigner.verified &&
                            <div>
                                <LazyLoad>
                                    <Image src={icVerified}/>
                                </LazyLoad>
                            </div>
                        }
                        {campaign.campaigner.is_foundation &&
                            <div><Image src={icFoundation} className="ml-1 icon-lembaga"/></div>
                        }
                        {campaign.campaigner.is_community &&
                            <div><Image src={icCommunity} className="ml-1 icon-lembaga"/></div>
                        }
                    </div>
                }
                <ProgressBar className={ovClasses.CampaignProgress} now={campaign.percentage} />
                <div className={ovClasses.ProgressInfo}>
                    <div className={ovClasses.ProgressInfoLeft}>
                        <div>Terkumpul</div>
                        <div>
                            <CurrencyFormat 
                                value={campaign.funds} 
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={'Rp '} 
                                displayType={'text'}
                                className="str-limit str-limit-1"
                            />
                        </div>
                    </div>
                    <div className={ovClasses.ProgressInfoRight}>
                        <div>Sisa Waktu</div>
                        <div>{campaign.day_left} Hari</div>
                    </div>
                </div>
            </div>
        </Link>
    )
}