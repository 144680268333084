import React from 'react'
import {Container, Image} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import imgPreVerification from '../../../../assets/img/preverification.png'
import icKategoriProg from '../../../../assets/svg/ic-kategori-prog.svg';
import icFormProg from '../../../../assets/svg/ic-form-prog.svg';
import icTerverifikasi from '../../../../assets/svg/ic-terverifikasi.svg';
import icSebar from '../../../../assets/svg/ic-sebar.svg';
import { Header } from '../../../organisms';
import './style.css';
const PreVerification =  (props) => {
    return (
        <>
            <main className="pb-0 onboarding-galangdana">  
                <Header title="" className="bg-back" headerheaderclass="header-bg-white"/>
                <Container className="no-padding">
                    <div className="text-center preverification-utama">
                        <Image src={imgPreVerification}/>
                        <div className="box-gradient-bottom">
                            <h4 className="title-penggalang-dana">Galang dana di <span className="txt-url">bantutetangga.com</span></h4>
                            <p className="desc txt-body-m">Galang dana di bantutetangga.com dapat dilakukan oleh lembaga, komunitas, maupun individu yang memiliki tujuan membantu tetangga yang membutuhkan.</p>
                            <Link to="/preverification/onboarding" className="btn btn-primary btn-block">Galang Dana Sekarang</Link>
                        </div>
                        <div className="box-timeline">
                            <h4 className="title-penggalang-dana">Cara galang dana di bantutetangga.com</h4>
                            <div className="content-timeline">
                                <div>
                                    <div className="boxed mt-0 ">
                                        <Image src={icKategoriProg}/>
                                        <div className="txt-title">
                                            Pilih kategori program
                                        </div>
                                        <div className="txt-body">
                                            Pilih kategori program yang sesuai dengan Anda baik personal, komunitas, maupun lembaga.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content-timeline second">
                                <div>
                                    <div className="boxed mt-0 ">
                                        <Image src={icFormProg}/>
                                        <div className="txt-title">
                                            Isi form pembuatan program
                                        </div>
                                        <div className="txt-body">
                                            Ikuti instruksi data penggalang dan lengkapi data penerima manfaat.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-tipsgalang">
                            <h4>Tips Galang Dana</h4>
                            <p>Ketahui hal berikut agar program Anda dipercaya dan banyak yang bantu.</p>
                            <div className="contentboxed">
                                <Image src={icTerverifikasi}/>
                                <h5>Akun Terverifikasi</h5>
                                <p>Verifikasi akun dengan berkas legalitas yang lengkap agar menjadi penggalang yang dipercaya donatur.</p>
                            </div>
                            <div className="contentboxed">
                                <Image src={icSebar}/>
                                <h5>Sebarkan galang dana</h5>
                                <p>Sebarkan ke #TetanggaBaik agar semakin banyak yang bantu program Anda.</p>
                            </div>
                        </div>
                        <div className="box-galangdana">
                            <p>Yuk, buat galang dana Anda agar puluhan ribu #TetanggaBaik bisa membantu!</p>
                            <Link to="/preverification/onboarding" className="btn btn-primary btn-white btn-block">Galang Dana Sekarang</Link>
                        </div>
                    </div>
                </Container>
            </main>
        </>
    )
}

export default PreVerification
