import React from 'react';
import { Alert, Button, Container, Form, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { API, clearDispatch, reloadUser } from '../../../../config/redux/action';
import { Header } from '../../../organisms';
import {VerifyOTP} from '../../../../containers/pages/Authorization'
import { toast } from "react-toastify";

import './style.css';

function SettingUpdateEmailForm({ state, handleChange, checkVerify, isLoading, errorMessage }) {
    const { handleSubmit, register, errors } = useForm()
    return (
        <Form onSubmit={handleSubmit(checkVerify)} className="form-hook">
            {state.old && 
                <Form.Group>
                    <Form.Label>Email Sekarang</Form.Label>
                    <Form.Control defaultValue={state.old} readOnly />
                </Form.Group>
            }
            <Form.Group>
                <Form.Label>{state.old ? "Email Baru" : "Email" }</Form.Label>
                <Form.Control
                    type="text" 
                    name="contact"
                    placeholder="Masukkan alamat email aktif" 
                    ref={register({ 
                        required: "Email "+(state.old ? "baru" : "")+" wajib diisi",
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Email tidak valid"
                        }
                    })}
                    onChange={(e) => handleChange(e)} 
                    defaultValue={state.contact}
                />
                <ErrorMessage errors={errors} name="contact" as={<p className="error-input text-left"></p>} />
                {errorMessage.contact && <p className="error-input">{errorMessage.contact}</p>}
            </Form.Group>
            <Button type="submit" className="mt-20" variant="primary" block  disabled={(isLoading ? true : false)}>
                { !isLoading && 'Verifikasi' }
                { isLoading && <Spinner animation="border" role="status"/> }
            </Button>
        </Form>
    )
}

class SettingUpdateEmail extends React.Component {
    state = {
        auth: {},
        old: '',
        contact: '',
        isVerify: false,
        isSavingUpdate: false,
        needVerfy: false,
        verifyContact: ''
    }
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.checkVerify = this.checkVerify.bind(this)
    }
    handleChange(e) {
        let verifyContact = this.state.auth.email_verified_at ? this.state.old : '';
        let needVerfy = this.state.auth.email_verified_at ? false : true;
        if(e.target.value && this.state.old !== e.target.value) {
            needVerfy = true
            verifyContact = e.target.value
        }
        
        this.setState({ contact: e.target.value, needVerfy, verifyContact })
    }
    checkVerify = async () => {
        const auth = JSON.parse(localStorage.getItem("userData"));
        const access_token = `Bearer ${auth.access_token}`;
        let formData = { type: "Email", content_type: "code", contact: this.state.contact, action:'user_email' };
        const config = {
            method: 'post',
            url: '/otp',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': access_token
            },
            data: formData
        };
        const res = await this.props.API(config).catch(err=>err);
        // const res = true
        if (res) {   
            this.setState({ isVerify: true })
        }

    }
    nextStep = async (resOtp) => {
        this.setState({isVerify:false});
        const auth = JSON.parse(localStorage.getItem("userData"));
        const access_token = `Bearer ${auth.access_token}`;
        let configSave = {
            method: 'put',
            url: '/user/email',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': access_token
            }
        };
        configSave['data'] = { email: this.state.contact, otp_code: resOtp.data }

        const save = await this.props.API(configSave).catch(err=>err);

        if(save) {
            let newAuth = {...this.state.auth};
            newAuth['email'] = this.state.contact;
            newAuth['email_verified_at'] = new Date();
            localStorage.setItem('userData', JSON.stringify(newAuth));
            
            toast.info('Alamat Email berhasil diubah.', {
                position: "top-center",
                hideProgressBar: true,
                autoClose: 3000,
            });
            setTimeout(() =>{
                this.props.history.push('/pengaturan/personal-data');
            }, 1000 );
        }
    }
    componentDidMount() {
        this.props.clearDispatch();
        if(localStorage.getItem("userData")){
            const res = JSON.parse(localStorage.getItem("userData"))
            this.setState({ 
                auth: res,
                needVerfy: res.email_verified_at ? false : true,
                verifyContact: res.email_verified_at ? res.email : '',
                // auth: { ...res, social_media: true },
                old: res.email,
            })
        } else {
            this.props.history.push('/login')
        }
    }
    render() {
        return (
            <React.Fragment>
                {!this.state.isVerify &&
                    <>
                    <Header title={this.state.old ? "Ganti Email" : "Tambah Email" } prevPage="/pengaturan/personal-data" />
                    <main>
                        {!this.state.auth.social_media &&
                            <Container>
                                <div className="txt-title fw-6">{this.state.old ? "Ganti Email" : "Tambah Email" }</div>
                                <div className="txt-caption">Masukkan email Anda. Kami akan mengirimkan kode verifikasi lewat email.</div>
                                <div className="mt-4">
                                    <SettingUpdateEmailForm
                                        state={this.state}
                                        checkVerify={this.checkVerify}
                                        handleChange={this.handleChange}
                                        errorMessage={this.props.errorMessage}
                                        isLoading={this.props.isLoading}
                                    />
                                </div>
                            </Container>
                        }
                        {this.state.auth.social_media &&
                            <Container>
                                <Alert className="text-left" variant="warning">
                                    <div className="txt-title">Email Tidak Bisa Diganti</div>
                                    <div className="txt-caption">Akun ini masuk atau terdaftar melalui akun media sosial.</div>
                                </Alert>
                                <Form.Group>
                                    <Form.Label>Email Sekarang</Form.Label>
                                    <Form.Control value={this.state.old} readOnly />
                                </Form.Group>
                                <Button variant="primary" onClick={this.checkVerify} block>
                                    Verifikasi
                                </Button>
                            </Container>
                        }
                    </main>
                    </>
                }
                {this.state.isVerify && 
                    <VerifyOTP 
                        title={"Verifikasi Email"} 
                        prevPage="/pengaturan/personal-data" 
                        type="email" 
                        contact={this.state.verifyContact} 
                        action="user_email"
                        nextStep={this.nextStep}
                        sendPost={({type:'Email', contact:this.state.verifyContact, action:'user_email'})}
                    />
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data)),
    reloadUser: (config, auth) => dispatch(reloadUser(config, auth))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingUpdateEmail));