import React from 'react'
import { connect } from 'react-redux';
import { Container, Button } from 'react-bootstrap';
import { withRouter } from "react-router";
import { clearDispatch, API } from '../../../../config/redux/action';
import { Header } from "../../../organisms";

import './style.css'

class FilterCampaign extends React.Component {
    state = {
        prevPage: '/?',
        activeCategory: {
            slug: 'all',
            name: 'All'
        },
        order: 'latest',
        str: '',
        sectionId:'',
        sectionName:'',
        min_donate: '',
        max_donate: '',
        isFetching: false,
        categories: []
    }
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    async apiCategory () {
        this.setState({ isFetching: true })

        const { API } = this.props
        const config = {
            method: 'get',
            url: '/category',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            }
        };

        const res = await API(config, false).catch(err=>err)
        if (res)
            this.setState({ categories: res.data.data })

        this.setState({ isFetching: false })
    }
    componentDidMount() {
        let prevPage = this.state.prevPage
        
        const queryParams = new URLSearchParams(this.props.location.search)
        if (queryParams.has('prev') && queryParams.get('prev') === 'campaign') 
            prevPage = '/campaign?'
        
        let prevPageParams = new URLSearchParams()
        if (queryParams.has('category')) {
            const activeCategory = {
                slug: queryParams.get('category')
            }
            prevPageParams.append('category', activeCategory.slug)
            this.setState({ activeCategory })
        }
        if (queryParams.has('order')) {
            const order = queryParams.get('order')
            prevPageParams.append('order', order)
            this.setState({ order })
        }
        if (queryParams.has('str')) {
            const str = queryParams.get('str')
            prevPageParams.append('str', str)
            this.setState({ str })
        }
        
        if (queryParams.has('sectionId')) {
            const sectionId = queryParams.get('sectionId')
            prevPageParams.append('sectionId', sectionId)
            this.setState({ sectionId })
        }
        if (queryParams.has('sectionName')) {
            const sectionName = queryParams.get('sectionName')
            prevPageParams.append('sectionName', sectionName)
            this.setState({ sectionName })
        }
        if (queryParams.has('min_donate')) {
            const min_donate = queryParams.get('min_donate')
            prevPageParams.append('min_donate', min_donate)
            this.setState({ min_donate })
        }
        if (queryParams.has('max_donate')) {
            const max_donate = queryParams.get('max_donate')
            prevPageParams.append('max_donate', max_donate)
            this.setState({ max_donate })
        }
        
        this.setState({ prevPage: prevPage + prevPageParams.toString() })
        this.apiCategory()
    }
    handleChangeCategory (slug, name) {
        if(this.state.activeCategory.slug !== slug) {
            this.setState({ activeCategory: { slug, name } })
        } else {
            this.setState({ activeCategory: { slug: 'all', name: 'All' }})
        }
    }
    handleChangeOrder (slug) {
        this.setState({ order: slug })
    }
    handleSubmit() {
        const { activeCategory, order, str, sectionId, sectionName, min_donate, max_donate } = this.state

        let params = new URLSearchParams()
        if (activeCategory.slug !== 'all') {
            params.append('category', activeCategory.slug)
        }
        if (order !== 'latest')
            params.append('sort', order)
        if (str)
            params.append('str', str)
        if (sectionId)
            params.append('sectionId', sectionId)
        if (sectionName)
            params.append('sectionName', sectionName)
        if (min_donate)
            params.append('min_donate', min_donate)
        if (max_donate)
            params.append('max_donate', max_donate)

        this.props.history.push({
            pathname: '/campaign',
            search: params.toString()
        })
    }
    render() {
        const { prevPage, categories, activeCategory, order } = this.state
        const orderOptions = [
            { slug: 'latest', name: 'Terbaru' },
            { slug: 'urgent', name: 'Mendesak' },
        ]
        return (
            <React.Fragment>
                <Header title="Filter" prevPage={prevPage} />
                <main className="d-flex flex-column">
                    <Container>
                        <div className="filter-sort">
                            <div className="txt-subtitle fw-6">Urutkan</div>
                            <div className="d-flex flex-wrap">
                                {orderOptions.map((option, i) => (
                                    <Button 
                                        size="sm"
                                        key={option.slug} 
                                        className={order === option.slug ? "btn-filter-active":""} 
                                        variant="disabled" 
                                        onClick={() => this.handleChangeOrder(option.slug)}>
                                            {option.name}
                                    </Button>
                                ))}
                            </div>
                        </div>
                        <div className="filter-category">
                            <div className="txt-subtitle fw-6">Kategori</div>
                            <div className="d-flex flex-wrap">
                                {categories.map((category, i) => (
                                    <Button 
                                        size="sm"
                                        key={category.slug} 
                                        className={activeCategory.slug === category.slug ? "btn-filter-active":""} 
                                        variant="disabled" 
                                        onClick={() => this.handleChangeCategory(category.slug, category.name)}>
                                            {category.name}
                                    </Button>
                                ))}
                            </div>
                        </div>
                    </Container>
                    <Container className="filter-submit">
                        <Button variant="primary" onClick={this.handleSubmit} block>
                            Terapkan Filter
                        </Button>
                    </Container>
                </main>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterCampaign));