import { useState } from "react";
import { useEffect } from "react";

export function StringToSlug (str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

export const formatCurrency = value => { 
    if (value){
        const number = Number(value)
        const formatNumbering = new Intl.NumberFormat("id-ID");
        return formatNumbering.format(number);
    }
    return;
};

export function useDebounce(value, delay = 500) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

export function removeSymbol(string) {
  if (string){
    return string
        .toLowerCase()
        .replace(/[^\w]+/g,'');
  }
  return '';
}

export const validatePhone = (value) => {
  if (value) {
    if (value.length > 13) {
      return false
    }

    const regex = /^(\+62|62|[0-9])?[\s-]?[1-9]{1}\d{1}[\s-]?\d{5}[\s-]?\d{1,7}$/
    return regex.test(String(value));
  }
  return;
}

export const validateEmail = (value) => {
  if (value) {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(String(value).toLowerCase());
  }
  return;
}