import React from 'react'
import { withRouter, Link } from "react-router-dom";
import { Container, Image } from "react-bootstrap";
import icVerifySuccess from '../../../../assets/svg/ic-verify-success.svg';
import { Header } from "../../../organisms";

import './style.css'

class VerifySucces extends React.Component {
    render() {
        const { history, location } = this.props
        const params = new URLSearchParams(location.search)
        const type = params.get('type')
        
        const verifications = {
            phone: {
                title: 'Verifikasi Telepon',
                prevPage: '/profile',
                description: `Nomor Telepon anda berhasil diverifikasi. Pastikan no hp dan email anda aktif untuk menerima informasi lanjutan dari kami`
            },
            disbursment: {
                title: 'Pencairan',
                prevPage: '/pencairan',
                description: `Pengajuan pencairan anda akan kami proses. Pastikan no hp dan email anda aktif untuk menerima informasi lanjutan dari kami`
            }
        }

        if(!type || !verifications[type])
            history.push('/')
        
        setTimeout(() => history.push(verifications[type].prevPage), 2000 );

        return (
            <React.Fragment>
                <Header title={verifications[type].title} prevPage={verifications[type].prevPage} />
                <main>
                    <Container className="verify-success">
                        <div className="verify-image">
                            <Image src={icVerifySuccess} />
                        </div>
                        <div className="verify-description">
                            <p>{verifications[type].description}</p>
                        </div>
                        <Link to={verifications[type].prevPage}>
                            <p className="text-center text-primary">
                                Terima Kasih
                            </p>
                        </Link>
                    </Container>
                </main>
            </React.Fragment>
        )
    }
}

export default withRouter(VerifySucces)