import React, {useEffect} from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { useHistory, Link } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";
import {Nav, Spinner, Container, Image} from "react-bootstrap";
import { Header } from '../../../organisms';

import "react-datepicker/dist/react-datepicker.css";

import Suggest from './Suggest'
import CurrencyFormat from 'react-currency-format';

import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';
import postData from './postData';
import icVerified from '../../../../assets/svg/ic-verified-campaigner.svg';
import {FbPixel} from '../../../../config/Tracker/FbPixel'

const IntitateFirst = () => {
  
  const { state, action } = useStateMachine(updateAction);
  const detailCampaign = JSON.parse(localStorage.getItem("detailCampaign"));
  const nextLink = "/donation/"+detailCampaign.slug;
  const { push } = useHistory();
  useEffect(()=>{
    FbPixel('AddToCart');
  },[])
  useEffect(() => {
    let data = {};
    if(!detailCampaign.is_active){
      push("/campaign/"+detailCampaign.slug);
    }
    if(state.postData.stepdon3 ){
      data = postData;
      data['stepdon3'] = false;
      action(data);
      push(nextLink+"/step1");
    }
  },[action, nextLink, push, state, detailCampaign])
  return null;
}

const Step1 = (props ) => {
  const detailCampaign = JSON.parse(localStorage.getItem("detailCampaign"));
  const linkPrev = "/campaign/"+detailCampaign.slug;
  const nextLink = "/donation/"+detailCampaign.slug;
  const { state, action } = useStateMachine(updateAction);
  const { handleSubmit, errors, register } = useForm({
    defaultValues: state.postData
  });
  const { push } = useHistory();
  const onSubmit = data => {
    nextPage(data);
  };

  const nextPage = (data) => {
    loadPaymentTypes(data);
  }

  const nextPageManual = (data) => {
    loadPaymentTypesManual(data);
  }

  const handleSuggestion = (value) => {
    if(props.errorMessage['amount']){
      let fieldChanges = {};
      fieldChanges = props.errorMessage;
      fieldChanges['amount'] = '';
      props.clearDispatch(fieldChanges);
    }
    let data = state.postData;
    // if(state.postData.amount < 1){
      data['amount'] = value;
      data['stepdon1'] = true;
      action(data);
    // }
    nextPage(data);
  }

  const handleDonate = (value) => {
    if(props.errorMessage['amount']){
      let fieldChanges = {};
      fieldChanges = props.errorMessage;
      fieldChanges['amount'] = '';
      props.clearDispatch(fieldChanges);
    }
    let data = state.postData;
    // if(state.postData.amount < 1){
      data['amount'] = value;
      data['stepdon1'] = true;
      action(data);
    // }
    nextPageManual(data);
  }
  
  const handleCurrency = (field, value) => {
    let data = {};
    data[field] = value
    action(data);

    if(props.errorMessage[field]){
      let fieldChanges = {};
      fieldChanges = props.errorMessage;
      fieldChanges[field] = '';
      props.clearDispatch(fieldChanges);
    }
    handleDonate(value);
  }

  const loadPaymentTypes = async (data) => {
    data['loadPaymentTypes'] = false;
    action(data);
    const config = {
        method: 'get',
        url: '/public/payment-types/group',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    const res = await props.API(config).catch(err=>err);
    if (res) {
      data['loadPaymentTypes'] = true;
      data['dataPaymentTypes'] = res.data;
      action(data);
      checkValues(data);
    }
  }

  const loadPaymentTypesManual = async (data) => {
    data['loadPaymentTypes'] = false;
    action(data);
    const config = {
        method: 'get',
        url: '/public/payment-types/group',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    const res = await props.API(config).catch(err=>err);
    if (res) {
      data['loadPaymentTypes'] = true;
      data['dataPaymentTypes'] = res.data;
      action(data);
      checkValuesManual(data);
    }
  }

  const checkValues = async (data) => {
    let formData = {
      amount: data.amount,
      just_validate:true
    };
    
    const config = {
        method: 'post',
        url: '/public/donation',
        headers: {
            'Content-Type': 'application/json'
        },
        data: formData
    };

    const res = await props.API(config, true, true).catch(err=>err);
    if (res) {
      data['stepdon1'] = true;
      data['resultDonation'] = {snapToken:false};
      if (state.postData.payment_type) {
        push(nextLink+"/step2");
      } else {
        push(nextLink+"/payment_channel");
      }
    }else{
      let data={};
      data['checkMessage'] = true;
      action(data);

    }
  }

  const checkValuesManual = async (data) => {
    let formData = {
      amount: data.amount,
      just_validate:true
    };
    
    const config = {
        method: 'post',
        url: '/public/donation',
        headers: {
            'Content-Type': 'application/json'
        },
        data: formData
    };

    const res = await props.API(config, true, true).catch(err=>err);
    if (res) {
      data['stepdon1'] = true;
      data['resultDonation'] = {snapToken:false};
    }else{
      let data={};
      data['checkMessage'] = true;
      action(data);
    }
  }
  
  let sectionStyle = {
      backgroundImage: `url(${detailCampaign.cover})`
  };
  return (
    <>
      <Header title="Donasi" prevPage={linkPrev}/>
      <main className="donate-main">
        <div className="container-donate">
          <form onSubmit={handleSubmit(onSubmit)} className="form-hook">
            <div className="container">
              <IntitateFirst/>
              <div className="widget-campaign">
                <div className="campaign-card-image">
                  <div className="center-cropped" style={sectionStyle}>
                  </div>
                </div>
                <div>
                  <div className="txt-body-m fw-6 ct-title">{detailCampaign.title}</div>
                  <div className="txt-caption-m campaigner">
                      {detailCampaign.campaigner.name}
                      {detailCampaign.campaigner_verified &&
                        <Image src={icVerified}/>
                      }
                  </div>
                </div>
              </div>
            </div>
            <div class="content-space"></div>
            <div className="container">
              <div className="txt-subtitle-m fw-6 ct-title mt-20 mb-10">Pilih Nominal Donasi</div>
              {/* {state.postData.amount <= 100000 &&
                <> */}
                  <Suggest nominal={15000} desc="" handleSuggestion={handleSuggestion} amount={state.postData.amount}/>
                  <Suggest nominal={50000} desc="" handleSuggestion={handleSuggestion} amount={state.postData.amount}/>
                  <Suggest nominal={250000} desc="" handleSuggestion={handleSuggestion} amount={state.postData.amount}/>
                  <Suggest nominal={1000000} handleSuggestion={handleSuggestion} amount={state.postData.amount}/>
                {/* </>
              } */}
              
              <div className="box-nominal">
                <div className="txt-body-m ct-title">Nominal Lainnya </div>
                <div className="content-grid wrap-input-amount txt-title-m">
                  <div>Rp</div>
                  <div>
                    <CurrencyFormat 
                      value={state.postData.amount} 
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      onValueChange={values => {
                        handleCurrency('amount', values.value);
                      }}
                      placeholder="Masukkan Nominal"
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <input
                    name="amount"
                    value={state.postData.amount}
                    className="hide"
                    ref={register({ required: "Nominal Donasi wajib diisi" })}
                    />
                    <ErrorMessage errors={errors} name="amount" as={<p className="error-input"></p>} />
                  </div>
                  {props.errorMessage.amount && 
                  <p className="error-input">{props.errorMessage.amount}</p>}
                </div>
              </div>
              <nav>
                <Nav className="navbar">
                  {!state.postData.stepdon3 && 
                    <button type="submit" className="btn btn-donate btn-block mt-0" disabled={props.isLoading || !state.postData.amount}>
                      { (!props.isLoading) && <span>Lanjutkan Pembayaran</span> }
                      { props.isLoading && <Spinner animation="border" role="status"/> }
                    </button>
                  }
                  {state.postData.stepdon3 && 
                    <Link to="/campaign" className="btn btn-donate btn-block mt-0"><span>Ayo Berdonasi Lagi</span></Link>
                  }
                </Nav>
              </nav>
            </div>
          </form>
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
  clearDispatch: () => dispatch(clearDispatch()),
  API: (path, data, showError) => dispatch(API(path, data, showError))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step1));
