import React from 'react'
import { Link } from 'react-router-dom';
import {Image} from 'react-bootstrap';
import icVerified from '../../../assets/svg/ic-verified-campaigner.svg';
import icFoundation from '../../../assets/svg/ic-foundation.svg';
import icCommunity from '../../../assets/svg/ic-community.svg';
import avatar from "../../../assets/img/avatar/no-img.png";

import './style.css'

export default function CampaignerNews({ campaign, classes }) {
    const defaultClasses = {
        root: "search-card",
        cardImage: "search-card-image",
        cardInfo: "search-card-info",
        cardTitle: "search-card-info-title txt-body",
        cardCampaigner: "search-card-info-campaigner txt-caption",
        
        // Override classes
        ...classes
    }
    return (
        <Link className={defaultClasses.root} to={"/campaigner/"+campaign.slug+"/news"}>
            <img className={defaultClasses.cardImage} alt={campaign.name} src={campaign.logo || avatar} />
            <div className={defaultClasses.cardInfo}>
                <div className={defaultClasses.cardTitle}>
                    {campaign.name}
                    {campaign.is_verified && <Image src={icVerified}/>}
                    {campaign.is_foundation && <Image src={icFoundation}/>}
                    {campaign.is_community && <Image src={icCommunity}/>}
                </div>
                <div className={defaultClasses.cardCampaigner}>
                    {campaign.campaigns_count} Program
                </div>
            </div>
        </Link>
    )
}