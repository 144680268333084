let sandbox = {
    url : 'https://app.sandbox.midtrans.com/snap/snap.js',
    clientKey : process.env.REACT_APP_SANDBOX_MIDTRANS_CLIENT_KEY
}

let production = {
    url : 'https://app.midtrans.com/snap/snap.js',
    clientKey : process.env.REACT_APP_PRODUCTION_MIDTRANS_CLIENT_KEY
}

let midtrans = sandbox
if (process.env.REACT_APP_IS_PRODUCTION_MIDTRANS === '1') {
    midtrans = production
}

export default midtrans