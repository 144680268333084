import {useEffect} from 'react';
import  { useHistory } from 'react-router-dom'
import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";

const RedirectStep =  (props) => {

    const { action } = useStateMachine(updateAction);
    const postStep1 = 
      (props.errorMessage.category_id || props.errorMessage.title || 
        props.errorMessage.target || props.errorMessage.slug || 
        props.errorMessage.budget_plan || props.errorMessage.expired_at) ? true : false;
    const postStep2 = 
        (props.errorMessage.area_id || props.errorMessage.distribution_address) ? true : false;    
    const postStep3 = 
        (props.errorMessage.cover) ? true : false;
    const postStep4 = 
        (props.errorMessage.description) ? true : false;
    const postStep5 = 
        (props.errorMessage.status || props.errorMessage.publish_at) ? true : false;


    const { push } = useHistory();
    useEffect(() => {
        let urlpush;
        if(postStep1){
            urlpush = "/galang-dana/step1";
        }else{
            if(postStep2){
                urlpush = "/galang-dana/step2";
            }else{
                if(postStep3){
                    urlpush = "/galang-dana/step3";
                }else{
                    if(postStep4){
                        urlpush = "/galang-dana/step4";
                    }else{
                        if(postStep5){
                            urlpush = "/galang-dana/step5";
                        }
                    }
                }
            }
        }
        if(urlpush){
            let data={};
            data['checkMessage'] = false;
            data['errorMessages'] = props.errorMessage;
            action(data);
            push({pathname:urlpush, props:{errorMessage:props.errorMessage}});
        }
        
    });
    return null
}

RedirectStep.defaultProps = {
    errorMessage: ''
}

export default RedirectStep
