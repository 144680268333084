import React from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { Image } from 'react-bootstrap';
import { useHistory, Redirect, Link } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";
import { Container } from "react-bootstrap";

import UploadFile from "./UploadFile";

import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';
import IcWarningBlue from '../../../../assets/svg/ic-warning-blue.svg';

import { Header, Wizard } from '../../../organisms';

const Step3 = props => {
  const { state, action } = useStateMachine(updateAction);
  const { handleSubmit, register, errors } = useForm({
    defaultValues: state.postData
  });
  const { push } = useHistory();
  const onSubmit = data => {
    data['stepgal3'] = true;
    action(data);
    push("/galang-dana/step4");
  };

  const returnFile = (file) => {
    let data = {
      base64: {
        cover: state.postData.base64.cover
      }
    };
    data['base64'][file.field] = file.base64image;
    data[file.field] = file.file_name;
    console.log({ data })
    action(data);
  }

  const stateCover = state.postData.base64.cover;
  const icon_cover = (state.postData.base64.cover ? state.postData.base64.cover : false);

  const editCampaign = (state.postData.editCampaign ? state.postData.editCampaign : '');
  const initialSteps = {
    class1: "",
    number1: 2,
    label1: "Lokasi",
    link1: "/galang-dana/step2" + editCampaign,

    class2: "step-active",
    number2: 3,
    label2: "Foto & Aset",
    link2: "/galang-dana/step3" + editCampaign,

    class3: "",
    number3: 4,
    label3: "Deskripsi Campaign",
    link3: "/galang-dana/step4" + editCampaign,
  }

  const linkPrev = "/galang-dana/step2" + editCampaign;

  return (
    <>
      <Header title={(state.postData.editCampaign ? "Ubah Data Campaign " : "Galang Dana")} prevPage={linkPrev} ic_white={true} />
      <Wizard initialSteps={initialSteps} />
      <main className="pb-0">
        <Container>
          {(!state.postData.stepgal2 && !state.postData.stepgal3) &&
            <Redirect to="/galang-dana/step2" />
          }
          <form onSubmit={handleSubmit(onSubmit)} className="form-hook">
            <h6 className="mb-3">Upload gambar penggalangan</h6>
            <p className="galang-desc">Gambar yang diupload akan menjadi gambar utama di halaman galang dana.</p>
            <div className="form-group">
              <UploadFile
                title="Upload Foto Cover"
                field="cover"
                fileName="campaign_cover_"
                classFile={stateCover ? 'file' : ''}
                classCoverImg={stateCover ? 'cover-img' : 'cover-img border-radius50'}
                returnFile={returnFile}
                icon={icon_cover}
              />

              {!state.postData.cover &&
                <div>
                  <input name="cover" type="hidden"
                    ref={register({ required: "Cover Campaign belum diupload" })} />
                  <ErrorMessage errors={errors} name="cover" as={<p className="error-input"></p>} />
                </div>
              }
              {state.postData.errorMessages.cover &&
                <p className="error-input">{state.postData.errorMessages.cover}</p>}
            </div>

            <div className="txt-body-m mb-3">
              <div className="content-grid grid-2 info-conditions mb-5">
                <div className="text-left ml-1">
                  <p> <strong>Kriteria Gambar yang baik</strong> </p>
                  <ul className="pl-3">
                    <li> Ukuran 664 × 374px (ukuran max 2 mb)</li>
                    <li> Resolusi 720p atau lebih </li>
                  </ul>
                </div>
              </div>
            </div>
            <h6 className="mb-3">Tambahkan Link Aset</h6>
            <p className="galang-desc">Link berisi foto dan video dari penerima manfaat agar penggalangan Anda dapat kami bantu optimasi ✨</p>
            <div className="form-group">
              <input 
                type="text"
                name="link_asset"
                className="form-control"
                placeholder="contoh: https;//drive.google.com/asetprogramx"
                ref={register({ required: "Link Aset wajib diisi" })}
              />
              <ErrorMessage errors={errors} name="link_asset" as={<p className="error-input"></p>} />
                {state.postData.errorMessages.link_asset && 
                <p className="error-input">{state.postData.errorMessages.link_asset}</p>}
            </div>
            <button type="submit" className="btn btn-primary btn-block btn-next" disabled={(props.isLoading)}>Selanjutnya</button>
          </form>
        </Container>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
  clearDispatch: () => dispatch(clearDispatch()),
  API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step3));
