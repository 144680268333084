
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import icPlus from '../../../../assets/svg/ic-plus.svg';
import CurrencyFormat from 'react-currency-format';
import {Spinner, Image} from 'react-bootstrap';
import { ExternalLink } from 'react-external-link';
import icUpload from '../../../../assets/svg/ic-upload.svg';
import icWarningBlue from '../../../../assets/svg/ic-warning-blue.svg';
import icRadioActive from '../../../../assets/svg/ic-radio-active.svg';
import icRadio from '../../../../assets/svg/ic-radio.svg';
import {Link} from 'react-router-dom';
import icFieldCorrected from "../../../../assets/svg/ic-field-corrected.svg";
import icWarningYellow from '../../../../assets/svg/ic-warning-yellow.svg';
import AddRekening from './AddRekening';

const FormPost = ({states, errorMessage, handleChange, isLoading, ...props}) => {
  const [showAddRekening, setShowAddRekening] = useState(false)
  const RootPath = process.env.REACT_APP_API_URL;
  const { handleSubmit, register, formState: { errors } } = useForm({
    mode: "onBlur",
  });

  const {formPost} = states;
  const amount = (formPost.amount !== 0 ? (parseFloat(formPost.amount)) : '');

  return (
    <>
      <form key={1} className="form-hook mt-3" onSubmit={handleSubmit(props.handleSubmitForm)}>
        <div className="section mt-10 bg-light-brown txt-title-s fw-6">
            <div>Berapa yang akan dicairkan?</div>
        
            <div className="content-grid wrap-input-amount mt-3">
                <div className="fw-bold ct-caption">Rp</div>
                <div>
                    <CurrencyFormat 
                        value={amount} 
                        thousandSeparator={"."}
                        decimalSeparator={","} 
                        className="fw-bold ct-caption"
                        onValueChange={values => {
                            props.handleCurrency('amount', values.value);
                        }}
                        autoFocus={true}
                    />
                </div>
            </div>
            {!formPost.amount && !states.wrongAmount && 
              <div>
                <input
                name="amount"
                className="hide"
                ref={register({ required: "Jumlah pencairan wajib diisi" })}
                />
                <ErrorMessage errors={errors} name="amount" as={<p className="error-input"></p>} />
              </div>
            }
            {states.wrongAmount &&
              <p className="error-input">
                Maaf, jumlah donasi yang anda input melebihi nominal yang telah ditentukan.
              </p>
            }
            {errorMessage.amount && 
              <p className="error-input">{errorMessage.amount}</p>
            }
        </div>
        <div className="content-space cs-lb"></div>
        <div className="section">
          <div className="txt-title-s fw-6">Rekening Pencairan</div>
          <div className="content-banks mb-2 p-0">
            {states.loadRekening &&
              states.dataRekening.map(rek => {
                return (  
                  <div className="list-option" key={rek.id} onClick={() =>  props.choosingRek(rek.id)}>
                      <div>
                        <div className="txt-body-m ct-title">{rek.account_number}</div>
                        <div className="txt-caption-m ct-body">{rek.bank}</div>
                        <div className="txt-caption-m ct-body">a/n {rek.account_holder}</div>
                      </div>
                      <div><Image src={(formPost.account_number_id === rek.id ? icRadioActive : icRadio)} /></div>
                  </div>
                )
                  
              })
            }
          </div>
          
          <button onClick={() => setShowAddRekening(true)}  className="btn btn-block btn-secondary btn-dashed"><Image src={icPlus}/> Tambah Rekening</button>
          {!formPost.account_number_id && 
            <>
              <input
              name="account_number_id"
              className="hide"
              ref={register({ required: "Rekening belum dipilih" })}
              />
              <ErrorMessage errors={errors} name="account_number_id" as={<p className="error-input"></p>} />
            </>
          }
          {errorMessage.account_number_id && 
          <p className="error-input">{errorMessage.account_number_id}</p>}

          
        </div>

        <div className="content-space"></div>
        <div className="section">
          <div className="form-group">
            <div className="txt-title-s fw-6 mb-3">Rencana Penggunaan Dana</div>
            <textarea
            name="description"
            className="form-control"
            ref={register({ required: "Rencana Penggunaan Dana belum diisi" })}
            onChange={handleChange}
            defaultValue={formPost.description}
            placeholder="Dana ini akan digunakan untuk...,"
            />
            <ErrorMessage errors={errors} name="description" as={<p className="error-input"></p>} />
            {errorMessage.description && 
            <p className="error-input">{errorMessage.description}</p>}
          </div>

          <div className="form-group">
            <div className="form-label txt-body-m">Lampirkan file rincian anggaran dana sebagai pendukung laporan penggunaan dana. <ExternalLink href={RootPath+'/rab/sample'}>Contoh File</ExternalLink></div>
            <div>
              {formPost.code &&
                <div className="mb-3">
                  Klik <ExternalLink href={formPost.link_rab}>disini</ExternalLink> untuk melihat / mengunduh file RAB yang sudah diupload.
                </div>
              }
              {states.fileRab && 
                <div className="input-file mt-10 mb-10">
                  <div className="container-file">
                      <div className="content-grid grid-2 txt-body-m text-left">
                        <div><Image src={icFieldCorrected}/><span className="txt-body-m">{states.fileRab}</span></div>
                        <div><div className="cursor-pointer ct-primary1" onClick={props.resetFile}>Ganti</div></div>
                      </div>
                  </div>
                </div>
              }
              <div className="form-upload mt-3">
                <div className="form-upload">
                  {(formPost.code) ? 
                    <>
                      <input
                        name="rab"
                        type="file"
                        className="file"
                        onChange={handleChange}
                        ref={register()}
                      />
                      {!states.fileRab && 
                        <div className="container-file">
                          <div className="btn btn-block btn-secondary btn-dashed"><Image src={icUpload}/> Unggah Lampiran</div>
                        </div>
                      }
                    </>
                    : 
                    <>
                      <input
                        name="rab"
                        type="file"
                        className="file"
                        ref={register({ required: "File Rincian Anggaran belum diisi" })}
                        onChange={handleChange}
                      />
                      {!states.fileRab && 
                        <div className="container-file">
                          <div className="btn btn-block btn-secondary btn-dashed"><Image src={icUpload}/> Unggah Lampiran</div>
                        </div>
                      }
                      <ErrorMessage errors={errors} name="rab" as={<p className="error-input"></p>} />
                      {errorMessage.rab && 
                      <p className="error-input">{errorMessage.rab}</p>}
                    </>
                  }
                </div>
              </div>

              <div className="content-grid grid-2 info-form2 mt-3">
                  <Image src={icWarningBlue} />
                  <div className="text-left ml-3">Ukuran file max {props.fileSizeUpload} dengan format pdf</div>
                  {/* Format File: <b>.pdf .xls .xlsx .doc .docs</b>, dengan ukuran maksimal <b>{props.fileSizeUpload}</b> */}
              </div>

              <div className="close-campaign">
                <input 
                  className="styled-checkbox" 
                  id="styled-checkbox-2" 
                  type="checkbox" 
                  ref={register()}
                  name="closing"
                  onChange={handleChange}
                />
                <label htmlFor="styled-checkbox-2" className="text-body-m">Penggalangan ini telah berakhir</label>
              </div>
              <ErrorMessage errors={errors} name="closing" as={<p className="error-input"></p>} />
              {props.showInfoClosing &&
                <div className="content-grid info-form grid-2">
                  <Image src={icWarningYellow} />
                  <div className="text-left ml-3">Penggalangan ini tidak akan dilanjutkan. Jangan ceklis jika Anda masih ingin melanjutkan penggalangan ini.</div>
                </div>
              }
            </div>
          </div>
        </div>
        <div className="content-space"></div>

        <div className="section">
          <div className="form-group confirm-form alert alert-info text-left mb-0 pl-4 pr-3 pt-2 pb-0">
            <input 
              className="styled-checkbox" 
              id="styled-checkbox-1" 
              type="checkbox" 
              ref={register({required: "Anda wajib mencentang bagian ini"})}
              name="is_read"
              onChange={handleChange}
            />
            <label htmlFor="styled-checkbox-1">Saya Setuju dengan <Link to="/faq/">Syarat & Ketentuan</Link> Pencairan Dana di Bantu Tetangga.</label>
          </div>
          <ErrorMessage errors={errors} name="is_read" as={<p className="error-input"></p>} />

          <div className="mt-20">
            {states.wrongAmount &&
              <p className="error-input">
                Maaf, jumlah donasi yang anda input melebihi nominal yang telah ditentukan.
              </p>
            }
            <button className="btn btn-primary btn-block" type="submit" disabled={isLoading}>
              { (!isLoading) && <span>Ajukan Pencairan</span> }
              { isLoading && <Spinner animation="border" role="status"/> }
            </button>
          </div>
        </div>
      </form>

      <AddRekening
          onHide={() => setShowAddRekening(false)} 
          show={showAddRekening}
          onLoadRek={props.onLoadRek}
      />
    </>
  );
}

export default (FormPost);