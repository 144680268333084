import React, {Fragment} from 'react';
import { Container, Form } from 'react-bootstrap';
import { withRouter } from "react-router";
import { toast } from 'react-toastify';

import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';

//Load Component
import { Header } from '../../../organisms';
import { Input, BtnSubmit } from '../../../../component/atoms';
class ChangePassword extends React.Component {
    
    state = {
        formPost: {},
        auth:null,
        access_token:null,
        isPasswordShown:false,
        isPasswordConfirmShown:false,
    }
    handleFormChange = (e) =>{
        let formPostNew = {...this.state.formPost};
        formPostNew[e.target.name] = e.target.value;

        let errorMessageNew = this.props.errorMessage;
        if(errorMessageNew.length > 0){
        }else{
            if(formPostNew[e.target.name]){
                errorMessageNew[e.target.name] = '';
            }
        }

        this.setState({
            formPost: formPostNew,
            errorMessage: errorMessageNew
        })
    }

    handleSubmit = async () => {
        const {history} = this.props;
        const config = {
            method: 'put',
            url: '/profile/change_password',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.access_token
            },
            data: this.state.formPost
        };
        const res = await this.props.API(config).catch(err=>err);
        if (res) {
            toast.info('Berhasil mengubah password', {
                position: "top-center",
                hideProgressBar: true,
                autoClose:3000,
            });

            setTimeout(() =>{
                history.push('/logout');
            },1000 );
        }
    }
    togglePasswordVisibility = () => {
        const {isPasswordShown} = this.state;
        this.setState({isPasswordShown: !isPasswordShown});
    }
    togglePasswordConfirmVisibility = () => {
        const {isPasswordConfirmShown} = this.state;
        this.setState({isPasswordConfirmShown: !isPasswordConfirmShown});
    }

    componentDidMount(){
        this.props.clearDispatch();

        this.props.clearDispatch();

        if(localStorage.getItem("userData")){
            const auth = JSON.parse(localStorage.getItem("userData"));
            this.setState({auth:auth})
            this.setState({access_token: `Bearer ${auth.access_token}`})

        }else{
            const {history} = this.props;
            history.push('/login');
        }

    }

    render() {
        const {isPasswordShown, isPasswordConfirmShown} = this.state;
        return(
            <Fragment>
                <Header title="Ubah Password" prevPage="/edit-profile"/>
                <main>
                <Container>
                    <Form>
                        <Input 
                            label="Password Baru"
                            type={(isPasswordShown) ? "text" : "password"} 
                            name="password"
                            icon_left="assets/img/password.svg" 
                            icon_right={(isPasswordShown) ? "assets/img/eye-off-line.svg" : "assets/img/eye-open.svg"} 
                            icon_right_click = {this.togglePasswordVisibility}
                            placeholder="Password Baru" 
                            handleFormChange={this.handleFormChange}
                            errorMessage = { this.props.errorMessage.password }  
                        />
                        <Input 
                            label="Konfirmasi Password Baru"
                            type={(isPasswordConfirmShown) ? "text" : "password"} 
                            name="password_confirmation"
                            icon_left="assets/img/password.svg" 
                            icon_right={(isPasswordConfirmShown) ? "assets/img/eye-off-line.svg" : "assets/img/eye-open.svg"} 
                            icon_right_click = {this.togglePasswordConfirmVisibility}
                            placeholder="Konfirmasi Password Baru" 
                            handleFormChange={this.handleFormChange}
                            errorMessage = { this.props.errorMessage.password_confirmation }  
                        />
                        <BtnSubmit label="Simpan" isLoading={this.props.isLoading} handleSubmit={this.handleSubmit}/>
                    </Form>
                </Container>
            </main>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePassword));