import React from "react";
import {Image } from 'react-bootstrap';
import "cropperjs/dist/cropper.min.css";

import { withRouter } from "react-router";

import { connect } from 'react-redux';
import { API } from '../../../../config/redux/action';

import IcSpinner from '../../../../assets/svg/ic-spinner.svg';
import icFieldCorrected from "../../../../assets/svg/ic-field-corrected.svg";
import icUploadImage from "../../../../assets/svg/ic-upload-image.svg";

class UploadFile extends React.Component {
    constructor() {
        super();
        this.fileInputRef = React.createRef()
        this.state = {
            isLoading:false,
            show:true,
        };
    }

    verifyFile = (files) => {
        
        const imageMaxSize = 2000000 // bytes
        const acceptedFileTypes = (this.props.field === 'logo' ? 'image/png, image/jpg, image/jpeg' : 'image/png, image/jpg, image/jpeg, application/pdf');
        const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {return item.trim()})
        if (files && files.length > 0){
            const currentFile = files[0]
            const currentFileType = currentFile.type
            const currentFileSize = currentFile.size
            if(currentFileSize > imageMaxSize) {
                this.setState({msgProcess:"Ukuran file terlalu besar"})
                return false
            }
            if (!acceptedFileTypesArray.includes(currentFileType)){
                this.setState({msgProcess:"Format file tidak sesuai ketentuan"})
                return false
            }
            return true
        }
    }

    handleLoading = () => {
        const {isLoading} = this.state;
        this.setState({isLoading: !isLoading});
    }

    handleFileSelect = event => {
        this.setState({show:true})
        const files = event.target.files

        this.setState({originalImage:files[0]});
        if (files && files.length > 0){
            const isVerified = this.verifyFile(files)
            if (isVerified){
                this.handleLoading();
                const currentFile = files[0]
                const myFileItemReader = new FileReader()
                myFileItemReader.addEventListener("load", ()=>{
                    this.handleFile();
                }, false)

                myFileItemReader.readAsDataURL(currentFile)

             }
        }
        
    }

    handleFile = () => {
        const {originalImage} =  this.state
        let returnFileCropped = {filePost:originalImage, field:this.props.field};
        this.props.hideBtnSubmit();
        this.submitFile(returnFileCropped);

        
    }

    submitFile = async (returnFileCropped) => {
        this.setState({msgProcess:'Sedang mengirim foto...', isLoading:true});
        let formData = new FormData();
        // let typeFile = (returnFileCropped.filePost.type === 'application/pdf' ? 'document' : 'image');
        let typeFile = (returnFileCropped.field === 'logo' ? 'image' : 'image');
        formData.append(typeFile, returnFileCropped.filePost);
        const config = {
            method: 'post',
            url: '/'+typeFile,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.access_token
            },
            data: formData
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            this.setState({msgProcess:'', isLoading:false, show:true});
            returnFileCropped['file_name'] = res.data.file_url;
            this.props.returnFile(returnFileCropped);
        }else{
            this.setState({msgProcess:'Gagal Mengirim Foto', isLoading:false});
        }
    }

    componentDidMount(){
        if(localStorage.getItem("userData")){
            const auth = JSON.parse(localStorage.getItem("userData"));
            this.setState({access_token: `Bearer ${auth.access_token}`})
        }
    }

    render() {

        const {isLoading, msgProcess, show} = this.state;
        return (
            <div>
                <div className="input-file">
                    <div className="container-file">
                        <div className="content-grid grid-2 label" onClick={()=>this.setState({show:(show ? true : true)})}>
                            <div>
                                <span className="txt-body-m">{this.props.title}</span>
                            </div>
                            <div>
                                {isLoading && 
                                    <Image className="loader-img" src={IcSpinner}/>
                                }
                                {(!isLoading && !msgProcess && this.props.icon) &&  
                                    <Image src={icFieldCorrected}/>
                                }
                            </div>
                        </div>
                        {show &&
                            <div className="content-info">
                                <div>

                                    <input type="file" ref={this.fileInputRef} onChange={this.handleFileSelect}/>
                                    <div className="content-upload-image">
                                        <Image className="img-upload" src={icUploadImage}/>
                                        <span className="txt-caption">Upload Gambar</span>
                                    </div>
                                </div>
                                <div className="txt-caption">{this.props.info}</div>
                            </div>
                        }
                    </div>
                    {(!isLoading && msgProcess) &&
                        <p className="error-input">{msgProcess}</p>
                    }
                </div>
            </div>
        );
    }

}
const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UploadFile));