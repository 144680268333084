export default {
    name: "",
    email: "",
    phone: "",
    bio:"",
    vlAutofocus: "",
    area_id: "",
    provinsi: "",
    loadProvinsi:false,
    dataProvinsi:false,
    kota: "",
    loadKota:true,
    dataKota:false,
    address:"",
    tax:"",
    identity:"",

    step1:false,
    step2:false,
    step3:false,
    step4:false,
    checkMessage:false,
    errorMessages:{},

    editCampaigner: '',
    editCampaignerSlug: '',
};