import React from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { useHistory, Redirect, Link } from "react-router-dom";
import { Nav, Spinner, Image, Container } from "react-bootstrap";

import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";

import UploadFile from "./UploadFile";
import RedirectStep from "./RedirectStep";

import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { clearDispatch, API, reloadUser } from '../../../../config/redux/action';
import { Header, Wizard } from '../../../organisms';

const PenerimaManfaat = props => {

  const linkPrev = "/rekomendasi-tetangga/tetangga-bantu-tetangga/step1";
  const { state, action } = useStateMachine(updateAction);
  const { handleSubmit, register, errors } = useForm({
    defaultValues: state.postData
  });
  const { push } = useHistory();
  const onSubmit = data => {
    nextPage(data);
  };

  const nextPage = (data) => {
    checkValues(data);
  }
  const checkValues = async (data) => {
    let formData = {
      area_id: state.postData.kecamatan,
      address: state.postData.address,
      receiver_name: state.postData.receiver_name,
      relation: state.postData.relation,
      reason: state.postData.reason,
      url_image: state.postData.url_image,
      just_validate:true
    };
    
    const config = {
        method: 'post',
        url: '/public/recommendation',
        headers: {
            'Content-Type': 'application/json'
        },
        data: formData
    };

    const res = await props.API(config, true, true).catch(err=>err);
    if (res) {
      data['stepdon1'] = true;
      data['resultDonation'] = {snapToken:false};
      push("/rekomendasi-tetangga/tetangga-bantu-tetangga/step2");
    }else{
      let data={};
      data['checkMessage'] = true;
      action(data);

    }
  };

  const handleChange = (e) =>{
    let val = e.target.value;
    let field = e.target.name;
    let data = {};
    data[field] = val;
    data['stepdon2'] = false;
    action(data);
  }

  const hideBtnSubmit = () => {
    let data = {};
    action(data);
  }
  const returnFile = (file) => {
    let data = {};
    data[file.field] = file.file_name;
    action(data);
  }
  
  const stateFotoPenerima = state.postData.url_image;

  const icon_url_image = (state.postData.url_image ? state.postData.url_image : false);
  return (
    <>
      {/* {(!state.postData.step2 && !state.postData.step3) && 
        <Redirect to="/verifikasi-personal/step2" />
      } */}
      <Header title="Tetangga Bantu Tetangga" prevPage={linkPrev} />
      {/* <Wizard initialSteps={initialSteps} /> */}
      <main className="donate-tbt donate-beras penerima-manfaat">
        <Container className="container-donate form-penyaluran">
          <div className="txt-title mb-3">Ceritakan tentang tetangga yang dibantu</div> 
          <form onSubmit={handleSubmit(onSubmit)} className="form-hook">
            <div className="form-group">
              <label className="form-label">Nama Penerima</label>
              <input type="text"
                name="receiver_name"
                className="form-control"
                placeholder="Contoh: Shinah Marsinah"
                ref={register({ required: "Nama penerima wajib diisi" })}
                onChange={handleChange}
              />
              <ErrorMessage errors={errors} name="receiver_name" as={<p className="error-input"></p>} />
              {state.postData.errorMessages.receiver_name && 
              <p className="error-input">{state.postData.errorMessages.receiver_name}</p>}
            </div>
            <div className="form-group">
              <label className="form-label">Hubungan Anda dengan Penerima</label>
              <input type="text"
                name="relation"
                className="form-control"
                placeholder="Contoh: Shinah Marsinah"
                ref={register({ required: "Hubungan dengan penerima wajib diisi" })}
                onChange={handleChange}
              />
              <ErrorMessage errors={errors} name="relation" as={<p className="error-input"></p>} />
              {state.postData.errorMessages.relation && 
              <p className="error-input">{state.postData.errorMessages.relation}</p>}
            </div>
            <div className="form-group">
              <label className="form-label">Alasan Penerima perlu dibantu</label>
              <textarea
                row="20"
                name="reason"
                className="form-control"
                placeholder="Contoh: Penerima sudah lanjut usia dan tidak dapat memenuhi kebutuhan sehari-hari"
                ref={register({ required: "Alasan penerima wajib diisi" })}
                onChange={handleChange}
              />
              <ErrorMessage errors={errors} name="reason" as={<p className="error-input"></p>} />
              {state.postData.errorMessages.reason && 
              <p className="error-input">{state.postData.errorMessages.reason}</p>}
            </div>
            <div className="form-group">
              <label className="form-label">Foto Penerima</label>
              <p className="txt-desc">Lampirkan foto keadaan penerima</p>
              {state.postData.url_image && 
              <span className="desc-penerima mb-2">{state.postData.url_image.slice(-28)}</span> }
              <UploadFile title="" 
                field="url_image" 
                fileName="PP_" 
                icon={icon_url_image} 
                classFile={stateFotoPenerima ? 'file' : ''} 
                classCoverImg={stateFotoPenerima ? 'cover-img' : 'cover-img border-radius50'} 
                info={"format .jpg dan .png tidak lebih dari 2MB"}
                returnFile={returnFile} 
                hideBtnSubmit={hideBtnSubmit} />
                <input name="url_image" type="hidden" ref={register({ required: "Foto Penerima belum diupload" })} value={state.postData.url_image}/>
              <ErrorMessage errors={errors} name="url_image" as={<p className="error-input"></p>} />
              {state.postData.errorMessages.url_image && 
              <p className="error-input">{state.postData.errorMessages.url_image}</p>}
            </div>
            <nav>
              <Nav className="navbar">
                <div className="group-btn">
                  <Link to="/rekomendasi-tetangga/tetangga-bantu-tetangga/step1" className="btn btn-secondary btn-block first-btn">Sebelumnya</Link>
                  <button type="submit" className="btn btn-donate btn-block second-btn" disabled={props.isLoading}>
                    { (!props.isLoading) && <span>Selanjutnya</span> }
                    { props.isLoading && <Spinner animation="border" role="status"/> }
                  </button>
                </div>
              </Nav>
            </nav>
          </form>
        </Container>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
  reloadUser: (config, auth) => dispatch(reloadUser(config, auth)),
  clearDispatch: () => dispatch(clearDispatch()),
  API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PenerimaManfaat));

