import React from 'react'
import CurrencyFormat from 'react-currency-format';

const Suggest =  (props) => {
    return (
        <div className="row">
            <div className="col-7">
                {props.desc && 
                    <div className="desc txt-caption">{props.desc}</div>
                }
            </div>
            <div className="col-1"></div>
            <div className="col-4">
                <div className="suggest-donate" onClick={() => props.handleSuggestionOperasional(props.nominal)}>
                    <div className={"content-flex"}>
                        <div>
                            <CurrencyFormat 
                                value={props.nominal} 
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={'Rp '} 
                                displayType={'text'}
                                className="amount"
                            />
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Suggest
