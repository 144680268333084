import React from 'react'
import {useHistory} from 'react-router-dom';
import {Image, Spinner, Dropdown} from 'react-bootstrap';
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';
import icOptions from '../../../../assets/svg/ic-options.svg';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import Moment from 'react-moment';
import parser from 'react-html-parser';

import { Share } from "../../../organisms";

const News =  (props) => {
    const status = props.data.status;
    const [shareModal, setShareModal] = React.useState(false)

    const { push } = useHistory();
    const goToEditForm = () => {
        localStorage.setItem('detailNews', JSON.stringify(props.data));
        push("/ubah-berita/"+props.data.slug);
    }
    const deleteNews = () => {
        confirmAlert({
            title: 'Pemberitahuan!',
            message: 'Apakah anda yakin menghapus berita ini?',
            buttons: [
              {
                label: 'Ya, Lanjutkan',
                onClick: () => props.handleDeleteNews(props.data.slug)
              },
              {
                label: 'Batal'
              }
            ]
        });
    }
    return (
        <div className="content-news">
            <div></div>
            <div>
                {props.isCampaigner &&
                    <div className="content-grid grid-2 mt-0 manage-news">
                        <div className={"card-state "+(status === 'Publish' ? 'state-green' : '')}>{status}</div>
                        
                        <div className="content-grid grid-8-2">
                            {props.isProcessing ?
                                <>
                                    <Spinner
                                    animation="border"
                                    size="sm"
                                    className="mb-0"
                                    />&nbsp;sedang dihapus..
                                </>
                                :
                                <div></div>
                            }
                            {!props.isProcessing &&
                                <Dropdown className="btn-dropdown">
                                    <Dropdown.Toggle>
                                        <Image src={icOptions}/>
                                    </Dropdown.Toggle>
                                
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={goToEditForm}><span className="txt-body-m">Ubah</span></Dropdown.Item>
                                        <Dropdown.Item onClick={() => setShareModal(true)}><span className="txt-body-m">Bagikan</span></Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item  onClick={deleteNews}><span className="txt-body-m">Hapus</span></Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                        </div>
                        <Share 
                            show={shareModal} 
                            onHide={() => setShareModal(false)}
                            url={'/campaign/news/' + props.data.slug}
                            slug={props.data.slug}
                            quote={props.data.title}
                            body={props.data.body} />
                    </div>
                }

                <div className={"wrap-content-news mt-0 "+(props.data.is_disbursement ? "wrap-content-disbursement" : "")}>
                    <div className="news-title txt-body-m fw-6">
                        <Moment format="dddd, DD MMMM YYYY">{props.data.created_at}</Moment> - {props.data.title}
                    </div>
                    <div 
                        className="txt-body-m description"
                    >
                        {parser(props.data.body)}
                    </div>
                </div>
            </div>
        </div>
    )
}

News.defaultProps = {
    isCampaigner:true
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(News);