import React from "react";
import {  Link, useHistory } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";
import { Container, Image} from 'react-bootstrap'

import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';

import { useForm } from "react-hook-form";
// import imgPascaVerify from '../../../../assets/img/pascaverify.png'
import imgPreVerification from '../../../../assets/img/preverification.png'
import { Header } from '../../../organisms';
import postData from "./postData";
import icKategoriProg from '../../../../assets/svg/ic-kategori-prog.svg';
import icFormProg from '../../../../assets/svg/ic-form-prog.svg';
import icTerverifikasi from '../../../../assets/svg/ic-terverifikasi.svg';
import icSebar from '../../../../assets/svg/ic-sebar.svg';
import './style.css';

const Intro = (props) => {
    const { state, action } = useStateMachine(updateAction);
    const { push } = useHistory();

    const loadCategories = async () => {
        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = `Bearer ${auth.access_token}`;
        const config = {
            method: 'get',
            url: '/category',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        };
        const res = await props.API(config, false).catch(err=>err);
        if (res) {
            let data = {};
            data['loadCategory'] = true;
            data['dataCategory'] = res.data.data;
            action(data);
        }
    }

    const { handleSubmit} = useForm();

    const onSubmit = data => {
        action(postData);
        data['intro'] = true;
        if(!state.postData.dataCategory){
            data['loadCategory'] = false;
            loadCategories();
        }
        action(data);
        push("/galang-dana/intro2");
    };

    return (
        <>
            <main className="pb-0 onboarding-galangdana">  
                <Header title="" className="bg-back" headerheaderclass="header-bg-white"/>
                <Container className="no-padding">
                    <div className="text-center preverification-utama">
                        <form className="form-hook" onSubmit={handleSubmit(onSubmit)}>
                            <Image src={imgPreVerification}/>
                            <div className="box-gradient-bottom">
                                <h4 className="title-penggalang-dana">Galang dana di <span className="txt-url">bantutetangga.com</span></h4>
                                <p className="desc txt-body-m">Galang dana di bantutetangga.com dapat dilakukan oleh lembaga, komunitas, maupun individu yang memiliki tujuan membantu tetangga yang membutuhkan.</p>
                                <button type="submit" className="btn btn-primary btn-block">Galang Dana Sekarang</button>
                            </div>
                            <div className="box-timeline">
                                <h4 className="title-penggalang-dana">Cara galang dana di bantutetangga.com</h4>
                                <div className="content-timeline">
                                    <div>
                                        <div className="boxed mt-0 ">
                                            <Image src={icKategoriProg}/>
                                            <div className="txt-title">
                                                Pilih kategori program
                                            </div>
                                            <div className="txt-body">
                                                Pilih kategori program yang sesuai dengan Anda baik personal, komunitas, maupun lembaga.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-timeline second">
                                    <div>
                                        <div className="boxed mt-0 ">
                                            <Image src={icFormProg}/>
                                            <div className="txt-title">
                                                Isi form pembuatan program
                                            </div>
                                            <div className="txt-body">
                                                Ikuti instruksi data penggalang dan lengkapi data penerima manfaat.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-tipsgalang">
                                <h4>Tips Galang Dana</h4>
                                <p>Ketahui hal berikut agar program Anda dipercaya dan banyak yang bantu.</p>
                                <div className="contentboxed">
                                    <Image src={icTerverifikasi}/>
                                    <h5>Akun Terverifikasi</h5>
                                    <p>Verifikasi akun dengan berkas legalitas yang lengkap agar menjadi penggalang yang dipercaya donatur.</p>
                                </div>
                                <div className="contentboxed">
                                    <Image src={icSebar}/>
                                    <h5>Sebarkan galang dana</h5>
                                    <p>Sebarkan ke #TetanggaBaik agar semakin banyak yang bantu program Anda.</p>
                                </div>
                            </div>
                            <div className="box-galangdana">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <p>Yuk, buat galang dana Anda agar puluhan ribu #TetanggaBaik bisa membantu!</p>
                                    <button type="submit" className="btn btn-primary btn-white btn-block">Galang Dana Sekarang</button>
                                </form>
                            </div>
                        </form>
                    </div>
                </Container>
            </main>
        </>
    );
};


const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Intro));
