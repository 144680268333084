import React, {Fragment} from 'react';
import { Container, Image } from 'react-bootstrap';
import { withRouter } from "react-router";
import CurrencyFormat from 'react-currency-format';
import {Link} from 'react-router-dom';
import icDocument from '../../../assets/svg/ic-document-fill.svg';

import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../config/redux/action';

// import './style.css';

//Load Component
import { Header } from '../../organisms';
import Moment from 'react-moment';
import Loading from './Loading';

class Detail extends React.Component {
    state = {
        detailDisbursement: {},
        loadDisbursement: false,
    }

    loadDisbursement = async (code) => {
        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = `Bearer ${auth.access_token}`;
        this.setState({loadDisbursement:false});
        const config = {
            method: 'get',
            url: '/campaigner/disbursement/'+code,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            this.setState({loadDisbursement:true, dataDisbursement:res.data});
        }else{
            this.setState({loadDisbursement:true});
        }
    }
    
    componentDidMount (){
        if(localStorage.getItem("userData")){
            this.setState({code: this.props.match.params.code});
            this.loadDisbursement(this.props.match.params.code);
        }else{
            const {history} = this.props;
            history.push('/login');
        }
    }
    render() {
        const { dataDisbursement, loadDisbursement } = this.state;
        return(
            <Fragment>
                <Header title="Detail Pencairan" prevPage="/pencairan"/>
                <main className="history-detail">
                    {!loadDisbursement && <Loading />}
                    {loadDisbursement &&
                        <>
                            <div className="filter search-page">
                                <div className="section">
                                    <div className="txt-caption-m">Status</div>
                                    <div className="d-flex">
                                        <div className="txt-body fw-6 mr-auto">
                                            <span className="state-green">
                                                {dataDisbursement.status}
                                            </span>
                                        </div>
                                        <div className="text-right">
                                            <Link to={"/detail-pencairan/"+dataDisbursement.code+"/status"} className="text-left fw-6">
                                                Lihat
                                            </Link>
                                        </div>
                                    </div>
                                    <hr className="mt-2" />
                                    <div className="d-flex mb-2">
                                        <div className="txt-caption mr-auto">Tanggal Pengajuan</div>
                                        <div className="txt-caption fw-6 text-right">
                                            <Moment format="DD MMMM YYYY, H:m">{dataDisbursement.created_at}</Moment> WIB
                                        </div>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="txt-caption mr-auto">Kode Pencairan</div>
                                        <div className="txt-caption fw-6 text-right">{dataDisbursement.code}</div>
                                    </div>
                                </div>
                                <div className="content-space"></div>
                            </div>
                            <Container className="p-4 bg-gray">
                                <div className="cp-card">
                                    <div className="d-flex mb-2">
                                        <div className="disbursement-image">
                                            <Image src={dataDisbursement.campaign_image} widtth="64px" height="36px" />
                                        </div>
                                        <div className="txt-subtitle-s fw-6">{dataDisbursement.campaign_name}</div>
                                    </div>
                                    <div className="txt-caption-m mt-3">Dana yang dicairkan</div>
                                    <div className="txt-subtitle-s fw-6">
                                        <CurrencyFormat 
                                            value={dataDisbursement.amount} 
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix={'Rp '} 
                                            displayType={'text'}
                                        />
                                    </div>

                                    <div className="txt-caption-m mt-3">Rencana Penganggaran</div>
                                    <div className="txt-body">{dataDisbursement.description}</div>
                                </div>
                            </Container>
                            <Container className="bg-white">
                                <div className="d-flex">
                                    <div className="txt-caption mr-auto">Rekening Penerima</div>
                                    <div className="text-right">
                                        <div className="txt-caption fw-6">{dataDisbursement.bank.bank}</div>
                                        <div className="txt-caption fw-6">{dataDisbursement.bank.account_number}</div>
                                        <div className="txt-caption fw-6">{dataDisbursement.bank.account_holder}</div>
                                    </div>
                                </div>
                                <hr />
                                <div className="txt-caption">Lampiran</div>
                                <div className="d-flex mt-2">
                                    <div className="logo-lampiran">
                                        <Image src={icDocument} />
                                    </div>
                                    <div className="ml-3">
                                        <div className="txt-caption fw-6 mb-2">{dataDisbursement.rab}</div>
                                        <a href={dataDisbursement.rab_path} className="text-left fw-6">
                                            Lihat File
                                        </a>
                                    </div>
                                </div>
                            </Container>
                        </>
                    }
                </main>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Detail));
