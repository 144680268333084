import React from 'react';
import {BrowserRouter as Router, Switch, Redirect} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

import {Home, AkunSaya, Search, SearchNews, NewsList, NewsDetail, Disbursement, Program, PerpanjangCampaign} from '../../containers/pages';
import {Login, Logout, Register, ForgotPassword, ResetPassword, EditProfile, ChangePassword,
    VerifyOTP, VerifyEmail, VerifySuccess
} from '../../containers/pages/Authorization';

import {VerifikasiLembaga, VerifikasiPersonal, VerifikasiKomunitas, DetailLembaga, ProfilCampaigner, PreVerification, OnBoarding, ChooseType, PascaVerify, EditProfilCampaigner, 
    EditEmailCampaigner, EditPhoneCampaigner, RekeningCampaigner, DocumentationCampaigner, NewsCampaigner} from '../../containers/pages/Campaigner';
import {GalangDana, CampaignSaya, DetailCampaign, Preview, AddNews, CampaignReport,
    EditNews, Pencairan, AllCampaign, FilterCampaign, CampaignAjuan, CampaignApproval, CampaignReportSuccess} from '../../containers/pages/Campaign';
import AllCampaignSection from '../../containers/pages/Campaign/AllCampaignSection';

import {PostDonasi, PostDonasiNew, CekDonasi, MyDonasi, BuktiDonasi, DetailDonasi, PaymentGuide} from '../../containers/pages/Donasi';
import {TBTPostDonasi, TBTPostDonasiBeras, TBTPostRekomendasiTetangga} from '../../containers/pages/TBTDonasi';
import {FAQHome, FAQDetail, FAQSearch} from '../../containers/pages/FAQ';
import ResultDonation from '../../containers/pages/SplashScreen/ResultDonation';
import AwaitingVerification from '../../containers/pages/SplashScreen/AwaitingVerification';
import SuccessDisbursement from '../../containers/pages/SplashScreen/SuccessDisbursement';

import DisbursementDetail from '../../containers/pages/Disbursement/Detail';
import DisbursementStatus from '../../containers/pages/Disbursement/Status';

import AddRekening from '../../containers/pages/Rekening/Campaigner/AddRekening';

import {Provider} from 'react-redux';
import {store} from '../redux';
import { HelmetProvider } from "react-helmet-async";
import Route from './RouteWithResetMeta';
import { SettingNotification, SettingPersonalData, SettingPreview, SettingSecurity, SettingUpdateEmail, SettingUpdatePhone } from '../../containers/pages/Setting';
import ReportList from '../../containers/pages/Report/ReportList';
import ReportDetail from '../../containers/pages/Report/ReportDetail';
import RespondReport from '../../containers/pages/Report/ReportDetail/RespondReport';

import {SyaratKetentuan, TentangKami, KebijakanPrivasi} from '../../containers/pages';
import TBTLanding from '../../containers/pages/TBTLanding';
import TBTCategory from '../../containers/pages/TBTCategory';
import NotFound from '../../containers/pages/SplashScreen/NotFound';
import LostConnection from '../../containers/pages/SplashScreen/LostConnection';
import Development from '../../containers/pages/Development';

const Routes =  (props) => {
    return (
        <HelmetProvider>
            <Provider store={store}>
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <Home/>
                        </Route>
                        <Route exact path="/search">
                            <Search/>
                        </Route>
                        <Route exact path="/search-news">
                            <SearchNews/>
                        </Route>
                        <Route exact path="/login">
                            <Login/>
                        </Route>
                        <Route exact path="/logout">
                            <Logout/>
                        </Route>
                        <Route exact path="/register">
                            <Register/>
                        </Route>
                        <Route exact path="/forgot-password">
                            <ForgotPassword/>
                        </Route>
                        <Route exact path="/reset-password">
                            <ResetPassword/>
                        </Route>
                        <Route exact path="/profile">
                            <AkunSaya/>
                        </Route>
                        <Route exact path="/edit-profile">
                            <EditProfile/>
                        </Route>
                        <Route exact path="/change-password">
                            <ChangePassword/>
                        </Route>
                        <Route exact path="/verify-otp">
                            <VerifyOTP/>
                        </Route>
                        <Route exact path="/verify-email">
                            <VerifyEmail/>
                        </Route>
                        <Route exact path="/verify-success">
                            <VerifySuccess/>
                        </Route>

                        <Route exact path="/verifikasi-lembaga">
                            <VerifikasiLembaga/>
                        </Route>

                        <Route exact path="/preverification">
                            <PreVerification/>
                        </Route>

                        <Route exact path="/preverification/onboarding">
                            <OnBoarding/>
                        </Route>

                        <Route exact path="/preverification/type">
                            <ChooseType/>
                        </Route>
                        <Route exact path="/pascaverify">
                            <PascaVerify/>
                        </Route>

                        <Route exact path="/detail-lembaga">
                            <DetailLembaga/>
                        </Route>

                        <Route exact path="/verifikasi-lembaga/step1">
                            <VerifikasiLembaga/>
                        </Route>
                        <Route exact path="/verifikasi-lembaga/step2">
                            <VerifikasiLembaga/>
                        </Route>
                        <Route exact path="/verifikasi-lembaga/step3">
                            <VerifikasiLembaga/>
                        </Route>
                        <Route exact path="/verifikasi-lembaga/step4">
                            <VerifikasiLembaga/>
                        </Route>
                        <Route exact path="/verifikasi-lembaga/document">
                            <VerifikasiLembaga/>
                        </Route>
                        <Route exact path="/verifikasi-lembaga/result">
                            <VerifikasiLembaga/>
                        </Route>

                        <Route exact path="/verifikasi-personal">
                            <VerifikasiPersonal/>
                        </Route>
                        <Route exact path="/verifikasi-personal/step1">
                            <VerifikasiPersonal/>
                        </Route>
                        <Route exact path="/verifikasi-personal/step2">
                            <VerifikasiPersonal/>
                        </Route>
                        <Route exact path="/verifikasi-personal/step3">
                            <VerifikasiPersonal/>
                        </Route>
                        <Route exact path="/verifikasi-personal/step4">
                            <VerifikasiPersonal/>
                        </Route>
                        <Route exact path="/verifikasi-personal/document">
                            <VerifikasiPersonal/>
                        </Route>
                        <Route exact path="/verifikasi-personal/result">
                            <VerifikasiPersonal/>
                        </Route>

                        <Route exact path="/verifikasi-komunitas">
                            <VerifikasiKomunitas/>
                        </Route>
                        <Route exact path="/verifikasi-komunitas/step1">
                            <VerifikasiKomunitas/>
                        </Route>
                        <Route exact path="/verifikasi-komunitas/step2">
                            <VerifikasiKomunitas/>
                        </Route>
                        <Route exact path="/verifikasi-komunitas/step3">
                            <VerifikasiKomunitas/>
                        </Route>
                        <Route exact path="/verifikasi-komunitas/step4">
                            <VerifikasiKomunitas/>
                        </Route>
                        <Route exact path="/verifikasi-komunitas/document">
                            <VerifikasiKomunitas/>
                        </Route>
                        <Route exact path="/verifikasi-komunitas/result">
                            <VerifikasiKomunitas/>
                        </Route>

                        <Route exact path="/galang-dana">
                            <GalangDana/>
                        </Route>
                        <Route exact path="/galang-dana/intro2">
                            <GalangDana/>
                        </Route>
                        <Route exact path="/galang-dana/step1">
                            <GalangDana/>
                        </Route>
                        <Route exact path="/galang-dana/step2">
                            <GalangDana/>
                        </Route>
                        <Route exact path="/galang-dana/step3">
                            <GalangDana/>
                        </Route>
                        <Route exact path="/galang-dana/step4">
                            <GalangDana/>
                        </Route>
                        <Route exact path="/galang-dana/step5">
                            <GalangDana/>
                        </Route>
                        <Route exact path="/galang-dana/result">
                            <GalangDana/>
                        </Route>

                        <Route exact path="/campaign/:campaignSlug">
                            <Preview/>
                        </Route>

                        <Route exact path="/campaign-saya">
                            <CampaignSaya/>
                        </Route>
                        <Route exact path="/detail-campaign/:campaignSlug">
                            <DetailCampaign/>
                        </Route>
                        <Route exact path="/tulis-berita/:campaignSlug">
                            <AddNews/>
                        </Route>
                        <Route exact path="/ubah-berita/:newsSlug">
                            <EditNews/>
                        </Route>
                        <Route exact path="/detail-campaign/perpanjang/:slug">
                            <PerpanjangCampaign/>
                        </Route>

                        
                        <Route exact path="/pencairan/:campaignSlug">
                            <Pencairan/>
                        </Route>

                        <Route exact path="/donation/:campaignSlug/step1">
                            <PostDonasiNew/>
                        </Route>
                        <Route exact path="/donation/:campaignSlug/step2">
                            <PostDonasiNew/>
                        </Route>
                        <Route exact path="/donation/:campaignSlug/payment_channel">
                            <PostDonasiNew/>
                        </Route>
                        <Route exact path="/donation/:campaignSlug/step3">
                            <PostDonasiNew/>
                        </Route>
                        <Route exact path="/donation/result/:resultdonation">
                            <ResultDonation/>
                        </Route>

                        <Route exact path="/cek_donasi">
                            <CekDonasi/>
                        </Route>
                        <Route exact path="/mydonate">
                            <MyDonasi/>
                        </Route>
                        <Route exact path="/mydonate/:code">
                            <DetailDonasi/>
                        </Route>
                        <Route exact path="/payment-guide/:code">
                            <PaymentGuide/>
                        </Route>
                        <Route exact path="/bukti-donasi/:donationcode">
                            <BuktiDonasi/>
                        </Route>

                        <Route exact path="/news">
                            <NewsList/>
                        </Route>
                        <Route exact path="/news/:slug">
                            <NewsDetail/>
                        </Route>

                        <Route exact path="/pencairan">
                            <Disbursement/>
                        </Route>

                        <Route exact path="/awaiting-verification">
                            <AwaitingVerification/>
                        </Route>
                        
                        <Route exact path="/success-disbursement">
                            <SuccessDisbursement/>
                        </Route>

                        <Route exact path="/detail-pencairan/:code">
                            <DisbursementDetail/>
                        </Route>

                        <Route exact path="/detail-pencairan/:code/status">
                            <DisbursementStatus/>
                        </Route>

                        <Route exact path="/program">
                            <Program />
                        </Route>

                        <Route exact path="/campaign">
                            <AllCampaign />
                        </Route>
                        <Route exact path="/campaign-section">
                            <AllCampaignSection />
                        </Route>
                        <Route exact path="/filter">
                            <FilterCampaign />
                        </Route>

                        <Route exact path="/campaign-ajuan">
                            <CampaignAjuan />
                        </Route>
                        <Route exact path="/campaign-ajuan/:slug">
                            <CampaignApproval />
                        </Route>
                        
                        <Route exact path="/campaigner/:slug">
                            <ProfilCampaigner/>
                        </Route>

                        <Route exact path="/campaigner/:slug/news">
                            <NewsCampaigner/>
                        </Route>

                        <Route exact path="/campaign-report/:slug">
                            <CampaignReport />
                        </Route>
                        <Route exact path="/campaign-report/:slug/success">
                            <CampaignReportSuccess />
                        </Route>
                        
                        <Route exact path="/pengaturan">
                            <SettingPreview/>
                        </Route>
                        <Route exact path="/pengaturan/personal-data">
                            <SettingPersonalData/>
                        </Route>
                        <Route exact path="/pengaturan/update-email">
                            <SettingUpdateEmail/>
                        </Route>
                        <Route exact path="/pengaturan/update-phone">
                            <SettingUpdatePhone/>
                        </Route>
                        <Route exact path="/pengaturan/keamanan">
                            <SettingSecurity/>
                        </Route>
                        <Route exact path="/pengaturan/notifikasi">
                            <SettingNotification/>
                        </Route>
                        
                        <Route exact path="/profile-campaigner">
                            <EditProfilCampaigner/>
                        </Route>
                        <Route exact path="/profile-campaigner/update-email">
                            <EditEmailCampaigner/>
                        </Route>
                        <Route exact path="/profile-campaigner/update-phone">
                            <EditPhoneCampaigner/>
                        </Route>
                        <Route exact path="/profile-campaigner/rekening">
                            <RekeningCampaigner/>
                        </Route>
                        
                        <Route exact path="/add-rekening/:slug">
                            <AddRekening/>
                        </Route>
                        <Route exact path="/profile-campaigner/dokumentasi">
                            <DocumentationCampaigner/>
                        </Route>
                        <Route exact path="/laporan">
                            <ReportList/>
                        </Route>
                        <Route exact path="/laporan/:slug">
                            <ReportDetail/>
                        </Route>
                        <Route exact path="/respond-report/:slug">
                            <RespondReport/>
                        </Route>

                        <Route exact path="/faq">
                            <FAQHome/>
                        </Route>
                        <Route exact path="/faq/search">
                            <FAQSearch/>
                        </Route>
                        <Route exact path="/faq/detail/:slug">
                            <FAQDetail/>
                        </Route>
                        
                        <Route exact path="/lost-connection">
                            <LostConnection />
                        </Route>

                        <Route exact path="/syarat-ketentuan">
                            <SyaratKetentuan />
                        </Route>
                        <Route exact path="/tentang-kami">
                            <TentangKami />
                        </Route>
                        <Route exact path="/kebijakan-privasi">
                            <KebijakanPrivasi />
                        </Route>
                        <Route exact path="/tetangga-bantu-tetangga">
                            <TBTLanding/>
                        </Route>
                        <Route exact path="/tetangga-bantu-tetangga/category">
                            <TBTCategory/>
                        </Route>
                        
                        <Route exact path="/donasi-uang/tetangga-bantu-tetangga/step1">
                            <TBTPostDonasi/>
                        </Route>
                        <Route exact path="/donasi-uang/tetangga-bantu-tetangga/step2">
                            <TBTPostDonasi/>
                        </Route>
                        <Route exact path="/donasi-uang/tetangga-bantu-tetangga/payment_channel">
                            <TBTPostDonasi/>
                        </Route>
                        <Route exact path="/donasi-uang/tetangga-bantu-tetangga/step3">
                            <TBTPostDonasi/>
                        </Route>
                        <Route exact path="/tetangga-bantu-tetangga/donation/result/:resultdonation">
                            <ResultDonation/>
                        </Route>

                        <Route exact path="/donasi-beras/tetangga-bantu-tetangga/step1">
                            <TBTPostDonasiBeras/>
                        </Route>
                        <Route exact path="/donasi-beras/tetangga-bantu-tetangga/atur-penyaluran">
                            <TBTPostDonasiBeras/>
                        </Route>
                        <Route exact path="/donasi-beras/tetangga-bantu-tetangga/step2">
                            <TBTPostDonasiBeras/>
                        </Route>
                        <Route exact path="/donasi-beras/tetangga-bantu-tetangga/step3">
                            <TBTPostDonasiBeras/>
                        </Route>

                        <Route exact path="/rekomendasi-tetangga/tetangga-bantu-tetangga/step1">
                            <TBTPostRekomendasiTetangga/>
                        </Route>
                        <Route exact path="/rekomendasi-tetangga/tetangga-bantu-tetangga/penerima-manfaat">
                            <TBTPostRekomendasiTetangga/>
                        </Route>
                        <Route exact path="/rekomendasi-tetangga/tetangga-bantu-tetangga/step2">
                            <TBTPostRekomendasiTetangga/>
                        </Route>
                        <Route exact path="/rekomendasi-tetangga/tetangga-bantu-tetangga/step3">
                            <TBTPostRekomendasiTetangga/>
                        </Route>

                        <Route exact path="/development">
                            <Development />
                        </Route>
                        <Route exact path="/not-found">
                            <NotFound />
                        </Route>
                        <Redirect to="/not-found" />
                    </Switch>
                </Router>
            </Provider>
        </HelmetProvider>
    )
}

export default Routes;