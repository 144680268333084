import React from 'react'

export default function Loading () {
    return (
        <div className="campaign-ajuan-card">
            <div className="title"><div className="gradient text-loading text-loading-80"></div></div>
            <div className="campaign-info">
                <div>
                    <div className="gradient text-loading" style={{"width":"80px"}}></div>
                    <div className="gradient text-loading" style={{"width":"80px"}}></div>
                </div>
                <div>
                    <div className="gradient text-loading" style={{"width":"80px"}}></div>
                    <div className="gradient text-loading" style={{"width":"80px"}}></div>
                </div>
            </div>
        </div>
    )
}