import React, { Component, Fragment } from 'react'
import {Container, Spinner, Nav} from 'react-bootstrap'
import './style.css'

import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import { API } from '../../../../config/redux/action';

//Load Component
export class VerifyOTPGuest extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            wrongOtp: false,
            time:{m:1, s:0},
            seconds:60,
            otp:(new Array(4).fill("")),
            otpEntries:0,
            isLoading:false,
            label:'',
            type:'',
            isSended:true,
            isValidation:false
        }
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }

    secondsToTime(secs){
        let hours = Math.floor(secs / (60 * 60));
    
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
    
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
    
        let obj = {
          "h": hours,
          "m": minutes,
          "s": seconds
        };
        return obj;
    }
    
    startTimer() {
        if (this.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }
    
    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
          time: this.secondsToTime(seconds),
          seconds: seconds,
        });
        
        // Check if we're at zero.
        if (seconds === 0) { 
          clearInterval(this.timer);
          this.timer = 0;
        }
    }
    
    handleKeyDown(e, index) {
        /** Handle Pressing Backspace */
        let otp = [...this.state.otp]
        if(e.keyCode === 8 && !otp[index] && index > 0) {
            if(e.target.previousSibling)
                e.target.previousSibling.focus()
            otp[index-1] = ''
            this.setState({ otp })
        }
    }

    handleChange = (element, index) => {
        let value = element.value
        let otp = [...this.state.otp]
        if(value.length > 1){
            return;
        }

        /** Handle Input to Next Field while Prev Field is Empty */
        for(let i = 0; i < index; i++)
            if(!otp[i]) {
                for(let j = index; j > i; j--)
                    element = element.previousSibling
                
                element.focus()
                index = i
                break
            }

        if (isNaN(value) || value === "") {
            /** Clear Next Field when Deleting Prev Field */
            for(let i = index; i < 4; i++ )
                otp[i] = ""
            
            if(element.previousSibling)
                element.previousSibling.focus()
        } else {
            /** Go to Next Input when Typing */
            otp[index] = value
            if (element.nextSibling)
                element.nextSibling.focus()
        }

        let countOTP = 0;
        for(let i = index; i < 4; i++ ){
            let valOTP = otp[index];
            if(valOTP !== ""){
                countOTP++;
            }

        }
        this.setState({otp:otp, otpEntries:countOTP });
        if(countOTP === 1){
            this.checkingOtp(otp)
        }
    };

    callSendOTP = (e) => {
        e.preventDefault();
        this.sendOTP();
    }

    sendOTP = async () => {
        this.nameInput.focus();
        this.setState({isLoading:true, isSended:false, wrongOtp:false, otp:(new Array(4).fill(""))})
        let formData = this.props.sendPost;
        const config = {
            method: 'post',
            url: '/otp',
            headers: {
                'Content-Type': 'application/json',
            },
            data: formData
        };
        const res = await this.props.API(config).catch(err=>err);
        if (res) {
            // let timeLeftVar = this.secondsToTime(this.state.seconds);
            // this.setState({ time: timeLeftVar, isLoading:false, isSended:true });
            let timeLeftVar = this.secondsToTime(60);
            this.setState({ time: timeLeftVar, seconds:60, isLoading:false, isSended:true });

            this.startTimer();
        }else{
            this.setState({isLoading:false, isSended:false})
        }
        
    }

    callCheckOTP = (e) =>  {
        e.preventDefault();
        this.checkingOtp(this.state.otp);
    }
    
    checkingOtp = async (otpArr) => {
        let otp = otpArr.join("");
        this.setState({isLoading:false, wrongOtp:false, isValidation:true})
        let formData = {otp: otp, code: this.props.code};
        const config = {
            method: 'post',
            url: '/otp/guest/validation',
            headers: {
                'Content-Type': 'application/json'
            },
            data: formData
        };

        const res = await this.props.API(config).catch(err=>err);
        if (res) {
            this.setState({isValidation:false})
            this.props.nextStep(res);
            
        }else{
            this.nameInput.focus();
            this.setState({isValidation:false, wrongOtp:true, otp:(new Array(4).fill(""))})
        }
    }

    componentDidMount() {
        const label = (this.props.type === 'email' ? "Email" : "Phone");
        const type = this.props.type;
        this.setState({label:label, type:type});
        if(this.props.sendOTPFirst){
            this.sendOTP();
        }else{
            let timeLeftVar = this.secondsToTime(this.state.seconds);
            this.setState({ time: timeLeftVar, isLoading:false, isSended:true });
            this.startTimer();
        }
    }
    render() {
        const {otp, isLoading, isSended, wrongOtp} = this.state
        return (
            <Fragment>
                <header className="">
                    <div className="nav">
                        <div className="nav-item">
                            <div className="nav-link cursor-pointer" onClick={this.props.hideContentOTP}>
                                <i className="ic-header ic-arrow-left"></i>
                            </div>
                        </div>
                        <div className="nav-item">
                            <div className="head-title txt-body-m">{this.props.title}</div>
                        </div>
                    </div>
                </header>
                <main>
                    <Container className="page-otp">
                        <div className="text-center mt-0">
                            { isLoading &&
                                <div className="text-middle">
                                    <Spinner animation="border"/>
                                    <p className="mt-2">Sedang mengirim kode verifikasi</p>
                                </div>
                            }
                            {!isSended &&
                                <div className="text-middle">
                                    gagal, <div className="cursor-pointer ct-primary1 fw-6" onClick={this.callSendOTP}>Kirim Lagi.</div>
                                </div>
                            }
                            {isSended &&
                                <div>
                                    <div className="text-left mb-4">
                                        <div className="txt-title-s fw-6">Masukkan Kode Verifikasi</div>
                                        <div className="txt-body-m mt-10">Masukkan 4 digit kode verifikasi yang telah dikirimkan melalui {(this.props.type === 'phone' ? 'SMS' : 'Email')} ke {this.props.contact}</div>
                                    </div>
                                    <div className="text-center alert alert-danger">
                                        {this.state.seconds > 0 ?
                                            <div>Masukkan kode sebelum  <span className="ct-red fw-6">{this.state.time.m}:{this.state.time.s}</span></div>
                                            :
                                            <div>Masa berlaku kode verifikasi telah habis,<span className="cursor-pointer ct-primary1" onClick={this.callSendOTP}> Kirim Ulang</span></div>

                                        }
                                    </div>
                                    <div className='mt-3'>
                                        {wrongOtp && <div className="text-danger">{this.props.errorMessage}</div>}
                                        {otp.map((data, index) => {
                                            if(index === 0){
                                                return (
                                                    <input
                                                        className="otp-field"
                                                        type="number"
                                                        name="otp"
                                                        ref={(input) => { this.nameInput = input; }}
                                                        maxLength="1"
                                                        key={index}
                                                        value={data}
                                                        onKeyDown={(e) => this.handleKeyDown(e, index)}
                                                        onChange={(e) => this.handleChange(e.target, index)}
                                                        onFocus={(e) => e.target.select()}
                                                        autoFocus={true}
                                                    />

                                                )
                                            }else{
                                                return (
                                                    <input
                                                        className="otp-field"
                                                        type="number"
                                                        name="otp"
                                                        maxLength="1"
                                                        key={index}
                                                        value={data}
                                                        onKeyDown={(e) => this.handleKeyDown(e, index)}
                                                        onChange={(e) => this.handleChange(e.target, index)}
                                                        onFocus={(e) => e.target.select()}
                                                    />
                                                );
                                            }
                                        })}
                                    </div>
                                    
                                    <nav>
                                        <Nav className="navbar no-bs">
                                            <div className="ma-0 mb-3 txt-body-m fw-6">{(this.props.type === "phone" ? "Nomor Ponsel" : "Alamat Email")} salah? <Link to="#" onClick={this.props.hideContentOTP}>Ganti {(this.props.type === "phone" ? "Nomor" : "Email")}</Link></div>
                                            <button type="submit" className="btn btn-primary btn-block mb-4" disabled={(this.state.otpEntries === 0 || this.props.isLoading) } onClick={this.callCheckOTP}>
                                            { (!this.props.isLoading ) && <span>Verifikasi</span> }
                                            { this.props.isLoading  && <Spinner animation="border" role="status"/> }
                                            </button>
                                        </Nav>
                                    </nav>
                                    {/* <div className="mt-2">
                                        {this.props.isLoading && <Spinner animation="border"/>}
                                        {!this.props.isLoading && 
                                            <div>
                                                <div>Belum menerima kode OTP? <span className="cursor-pointer ct-primary1" onClick={this.callSendOTP}> Kirim Ulang</span></div>
                                            </div>
                                        }
                                    </div> */}
                                </div>
                            }
                            
                        </div>
                    </Container>
                </main>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    API: (config, isDispatch) => dispatch(API(config, isDispatch))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyOTPGuest));