import React from 'react';
import { Image, Modal } from "react-bootstrap";
import icCloseModal from '../../../assets/svg/ic-close-modal.svg';

const Penyaluran =  (props) => {
    const { show, onHide, label, description } = props
    return (
        <Modal show={show} onHide={onHide} animation={false} centered className="modal-bottom modal-operasi">
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">
                    <span className="txt-title">{label}</span>
                </Modal.Title>
                <Image src={icCloseModal} onClick={onHide} className="cursor-pointer" /> 
            </Modal.Header>
            <Modal.Body>
                <div className="container no-border">
                    <p>{description}</p>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default Penyaluran;