import React from 'react'
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { clearDispatch, API } from '../../../../config/redux/action';
import { Header } from "../../../organisms";
import Card from './Card'
import Loading from './Loading'
import './style.css'

class CampaignAjuan extends React.Component {
    state = {
        campaigns: [],
        isFetching: false,
        currentPage: 1,
        total: -1,
        categories: [],
        isFetchingCategory: false,
        filter: {
            category: '',
            status: ''
        },
        auth: null,
        token: null,
    }
    constructor(props) {
        super(props)
        this.state.auth = JSON.parse(localStorage.getItem("userData"));
        this.state.token = this.state.auth ? `Bearer ${this.state.auth.access_token}`:null;

        this.handleScroll = this.handleScroll.bind(this)
        this.handleFilterCategory = this.handleFilterCategory.bind(this)
        this.handleFilterStatus = this.handleFilterStatus.bind(this)
    }
    apiCategory = async () => {
        this.setState({ isFetchingCategory: true })

        const config = {
            method: 'get',
            url: '/category',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            this.setState({ categories: res.data.data });
        }
        this.setState({ isFetchingCategory: false })
    }
    apiCampaigns = async () => {
        const { filter, currentPage, campaigns, token } = this.state
        
        let params = '?perPage=5&page='+currentPage;
        if(filter.category || filter.status){
            params += '&filter=true';
            if(filter.status){
                params += '&status='+filter.status;
            }
            if(filter.category){
                params += '&category='+filter.category;
            }
        }
        const config = {
            method: 'get',
            url: '/campaigner/fund-applications'+params,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            this.setState({
                campaigns: [ ...campaigns, ...res.data.data ], 
                currentPage: currentPage + 1, 
                total: res.data.total
            });
        }
        this.setState({ isFetching: false });
    }
    handleFilterCategory = (e) => {
        this.setState({
            filter: {
                status: this.state.filter.status,
                category: e.target.value
            },
            campaigns: [],
            currentPage: 1,
            isFetching: true,
        })
    }
    handleFilterStatus = (e) => {
        this.setState({
            filter: {
                category: this.state.filter.category,
                status: e.target.checked ? e.target.value : ''
            },
            campaigns: [],
            currentPage: 1,
            isFetching: true,
        })
    }
    handleScroll() {
        const isScrolled = document.documentElement.offsetHeight - Math.ceil(window.innerHeight + document.documentElement.scrollTop);
        if (isScrolled > 100 || this.state.isFetching)
            return;
        this.setState({isFetching: true})
    }
    componentDidMount (){
        const { history, clearDispatch } = this.props;
        const { auth, token } = this.state

        clearDispatch();

        if(token) {
            if(!auth.campaigner){
                history.push('/verifikasi-lembaga');
                return
            }

            this.apiCategory();
            window.addEventListener('scroll', this.handleScroll);
            this.setState({ isFetching: true })
        } else {
            history.push('/login')
        }
    }
    componentDidUpdate(_, prevState) {
        const { isFetching, campaigns, total, filter } = this.state;
        if(isFetching !== prevState.isFetching) {
            if (filter.status !== prevState.filter.status ||
                filter.category !== prevState.filter.category) {
                this.apiCampaigns()
                return
            }
            if (!isFetching || (campaigns.length === total && total !== -1)) {
                this.setState({ isFetching: false });
                return;
            }
            this.apiCampaigns();
        }

    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll); 
    }
    render() {
        const { campaigns, categories, filter, isFetchingCategory, isFetching } = this.state
        return(
            <React.Fragment>
                <Header title="Campaign Ajuan" prevPage="/" />
                <main>
                    <Container className="campaign-ajuan">
                        <div className="form-group">
                            <select 
                                className="form-control mb-2" 
                                onChange={this.handleFilterCategory}
                                value={filter.category}>
                                <option value="">{isFetchingCategory ? '...' : 'Pilih'}</option>
                                {categories.map((value, key) =>
                                    <option key={key} value={value.slug}>{value.name}</option>
                                )}
                            </select>
                        </div>
                        <div className="campaign-ajuan-filter form-check">
                            <input id="filterStatus" className="form-check-input" type="checkbox" value="approved" onChange={this.handleFilterStatus} />
                            <label className="form-check-label" htmlFor="filterStatus">
                                Sudah Disetujui
                            </label>
                        </div>
                        <div className="campaign-ajuan-result">
                            {campaigns.map((value, key) => <Card key={key} campaign={value} /> )}
                            {isFetching && <Loading />}
                            {!isFetching && campaigns.length === 0 && <p className="text-center">Belum ada Ajuan</p>}
                        </div>
                    </Container>
                </main>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignAjuan));