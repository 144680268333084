import React from 'react';
import './style.css';
import { Nav, Button } from 'react-bootstrap';

const NavMenu =  (props) => {
    return (
        <nav>
            <Nav className="navbar nav-tbt">
                <Button href="/tetangga-bantu-tetangga/category" className="w-100">Bantu Tetangga Sekarang</Button>
            </Nav>
        </nav>
    
    )
}

export default NavMenu;