module.exports = function CreateMeta ({
    title = 'Bantu Tetangga - Platform Penggalangan Dana Online',
    telephone = '+62 813 1175 4698',
    appname = 'Bantu Tetangga',
    titleColor = '#000',
    titleImage = 'https://bantutetangga.com/logo192.png',
    squareLogo = 'https://bantutetangga.com/logo192.png',
    twitter = '',
    twitterLabel = 'Bantu Tetangga',
    streetAddress = 'Jalan Setra Dago Utama No. 27',
    locality = 'Antapani',
    region = 'Bandung',
    postalCode = '40291',
    country = 'Indonesia',
    url = 'https://bantutetangga.com/',
    description = 'Platform terbuka penggalangan dana online untuk kumpulkan tetangga baik dengan semangat gotong-royong membantu tetangga prasejahtera di sekitarnya.',
}) {
    return {
        title,
        telephone,
        appname,
        titleColor,
        titleImage,
        squareLogo,
        twitter,
        twitterLabel,
        streetAddress,
        locality,
        region,
        postalCode,
        country,
        url,
        description,
    }
}