import React from "react";
import Cropper from "cropperjs";
import {Image, Button } from 'react-bootstrap';
import "cropperjs/dist/cropper.min.css";
import imageCompression from "browser-image-compression";
import {base64StringtoFile,extractImageFileExtensionFromBase64} from '../../../../config/Files';


import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { API } from '../../../../config/redux/action';

import IcSpinner from '../../../../assets/svg/ic-spinner.svg';
import IcUploadCover from '../../../../assets/svg/ic-upload-cover.svg';

const imageMaxSize = 5000000 // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {return item.trim()})

class UploadFile extends React.Component {
    constructor() {
        super();
        this.fileInputRef = React.createRef()
        this.state = {
            imgSrc: null,
            imgSrcExt: null,
            imageDestination: "",
            isLoading:false,
            msgProcess: "",
            showInputFile:true
        };
    }

    verifyFile = (files) => {
        if (files && files.length > 0){
            const currentFile = files[0]
            const currentFileType = currentFile.type
            const currentFileSize = currentFile.size
            if(currentFileSize > imageMaxSize) {
                alert("This file is not allowed. " + currentFileSize + " bytes is too large")
                return false
            }
            if (!acceptedFileTypesArray.includes(currentFileType)){
                alert("This file is not allowed. Only images are allowed.")
                return false
            }
            return true
        }
    }

    handleLoading = () => {
        const {isLoading} = this.state;
        this.setState({isLoading: !isLoading});
    }

    handleFileSelect = event => {
        this.handleLoading();
        const files = event.target.files

        this.setState({imgSrc:null, originalImage:files[0]});
        if (files && files.length > 0){
            const isVerified = this.verifyFile(files)
            if (isVerified){
                const currentFile = files[0]

                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 700,
                    useWebWorker: true,
                    onProgress: Function
                };
              
                imageCompression(currentFile, options).then(output => {
                    var reader = new FileReader();
                    reader.readAsDataURL(output); 
                    reader.addEventListener("load", ()=>{
                        var myResult = reader.result;
                        this.setState({
                        imgSrc: myResult,
                        imgSrcExt: extractImageFileExtensionFromBase64(myResult)
                        });
          
                        const cropper = new Cropper(this.fileInputRef.current, {
                            zoomable: false,
                            scalable: false,
                            aspectRatio: 16/9,
                            responsive: true,
                            zoomOnWheel: false,
                            zoomOnTouch: false,
                            dragMode:"none",
                            background:false,
                            toggleDragModeOnDblclick:false,
                            crop: () => {
                                const canvas = cropper.getCroppedCanvas();
                                this.setState({ imageDestination: canvas.toDataURL("image/png") });
                            }
                        });

                        this.handleLoading();
                    });
                      
                });
             }
        }
        
    }
    handleFile = (event) => {
        event.preventDefault();
        const {imgSrcExt, imageDestination} =  this.state
        const myFilename = this.props.fileName +'.'+ imgSrcExt
        const myNewCroppedFile = base64StringtoFile(imageDestination, myFilename)
        // downloadBase64File(imageDestination, myFilename)
        let returnFileCropped = {base64image: imageDestination, filePost:myNewCroppedFile, field:this.props.field, file_name:""};
        // this.setState({imgSrc:null});
        
        this.submitFile(returnFileCropped);
        this.setState({imgSrc:returnFileCropped.base64image});
        
    }

    submitFile = async (returnFileCropped) => {
        this.setState({msgProcess:'Sedang mengirim foto...', isLoading:true});
        let formData = new FormData();
        formData.append("image", returnFileCropped.filePost);
        const config = {
            method: 'post',
            url: '/image',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.access_token
            },
            data: formData
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            this.setState({showInputFile:false, imgSrc:null, msgProcess:'', isLoading:false});
            returnFileCropped['file_name'] = res.data.file_name;
            this.props.returnFile(returnFileCropped);
        }else{
            this.setState({msgProcess:'Gagal Mengirim Foto', isLoading:false});
        }
    }

    showingInputFile = () => {
        // this.setState({showInputFile:true});
        this.setState({showInputFile:true, imgSrc:false});
        this.props.onCancel && this.props.onCancel({
            base64image: "",
            file_name: "",
        })
    }

    componentDidMount(){
        if(localStorage.getItem("userData")){
            const auth = JSON.parse(localStorage.getItem("userData"));
            this.setState({access_token: `Bearer ${auth.access_token}`})

            if(this.props.icon){
                this.setState({showInputFile:false});
            }
        }
    }

    render() {

        const {imgSrc, isLoading, msgProcess, showInputFile} = this.state;
        return (
            <div>
                <div className="card-upload cover-upload text-center">
                    {(!imgSrc && !isLoading && !showInputFile) && 
                        <div>
                            {this.props.icon &&
                                <Image src={this.props.icon} className="cover-priview w-100"/>
                            }
                            {!showInputFile &&
                                <Button className="mt-3" variant="secondary"  onClick={this.showingInputFile} size="sm">Ganti Cover</Button>
                            }
                        </div>
                    }

                    {showInputFile && !imgSrc &&
                        <div className="form-upload">
                            <input type="file" ref={this.fileInputRef} accept={acceptedFileTypes} onChange={this.handleFileSelect}/>
                            <div className="container-file">
                                <div className="btn btn-secondary txt-body-m ct-primary2">Unggah <Image src={IcUploadCover} /></div>
                                <div className="txt-caption-m">ukuran Max 2MB<br/>jpg atau png</div>
                            </div>
                            {(!isLoading && msgProcess) &&
                                <p className="error-input">{msgProcess}</p>
                            }
                        </div>
                    }
                    {imgSrc && 
                        <div>
                            <div className="img-container">
                                <Image ref={this.fileInputRef} src={imgSrc} alt="Source" />
                            </div>

                            {/* <div className="img-preview">
                                <div>Gambar yang akan ditampilkan :</div>
                                <Image src={this.state.imageDestination} className="avatar" alt="Destination" />
                            </div> */}
                            {isLoading && 
                                <Image className="loader-img" src={IcSpinner}/>
                            }
                            {(!isLoading && msgProcess) &&
                                <p className="error-input">{msgProcess}</p>
                            }
                            {!isLoading && 
                                <div className="text-right">
                                    <Button className="mt-3 mr-3" variant="secondary" size="sm" onClick={this.showingInputFile}>
                                        Ganti Foto
                                    </Button>
                                    <Button className="mt-3" variant="primary" size="sm" onClick={this.handleFile}>
                                        Simpan
                                    </Button>
                                </div>
                            }
                        </div> 
                    }
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UploadFile));