import React from 'react'

const Loading =  (props) => {
    return (
        <div className="slide-wrap">
            <div className="slide-donate">
                <div className="content-grid content-avatar">
                    <div>
                        <div className="wrapper-img">
                            <div className="gradient text-loading text-loading-60"></div>
                        </div>
                    </div>
                    <div className="text-left">
                        <div><div className="gradient text-loading text-loading-80"></div></div>
                        <div className="text-blue"><div className="gradient text-loading text-loading-80"></div></div>
                        <div className="text-small"><div className="gradient text-loading text-loading-80"></div></div>
                    </div>
                </div>
                <div className="comment"><div className="gradient text-loading text-loading-80"></div></div>
            </div>
        </div>
    )
}

export default Loading
