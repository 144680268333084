import React from 'react'
import { Header, SearchCard, SearchCardCampaigner, CampaignCard } from "../../organisms";
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../config/redux/action';
import { Input } from "../../../component/atoms";
import { Link } from "react-router-dom";
import { Container, Image } from "react-bootstrap";
import debounce from "lodash.debounce";
import icCloseDialog from '../../../assets/svg/ic-close-dialog.svg';
import imgCampaignNotFound from '../../../assets/img/campaign-not-found.png';

import './style.css'
import Loading from '../../organisms/SearchCard/Loading';

class SearchComponent extends React.Component {
    state = {
        token: null,
        search: '',
        isFetchingResults: false,
        loadData: false,
        results: [],
        isFetchingPopularCampaigns: false,
        populars: [],
        campaigns: [],
        campaigners: [],
        campaignsCount: '',
        campaignersCount: '',
        favouriteCampaigns: [],
        isFetchingFavouriteCampaigns: false,
        lembagaPerPage: 5
    }
    constructor() {
        super()
        this.handleSearchRequest = debounce(this.handleSearchRequest, 500)
    }
    handleSearch = async (e) => {
        this.setState({ search: e.currentTarget.value, loadData: false, isFetchingResults: true })
        this.handleSearchRequest()
    }
    handleSearchRequest = async () => {
        /** Do Search Here */
        // if(this.state.search.length < 2)
        //     return

        const { API } = this.props

        const config = {
            method: 'get',
            url: '/search?search=' + this.state.search,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            }
        };
        const res = await API(config, false).catch(err => err);
        if (res) {
            this.setState({ results: res.data.campaigns, campaignsCount: res.data.campaignsCount, campaignersCount: res.data.campaignersCount, campaigners: res.data.campaigners })
        }
        this.setState({ lembagaPerPage: this.state.lembagaPerPage + 5 });
        this.setState({ isFetchingResults: false, loadData: true })
    }
    componentDidMount() {
        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = auth && auth.access_token ? `Bearer ${auth.access_token}` : null;
        this.setState({ loadData: false, isFetchingResults: true, token })
        this.handleSearchRequest()
    }
    resetKeyword = () => {
        this.setState({ search: '', isFetchingResults: true, loadData: false, results: [] });
        this.handleSearchRequest()
    }
    loadMore = async () => {
        /** Do Search Here */
        // if(this.state.search.length < 2)
        //     return

        const { API } = this.props

        const config = {
            method: 'get',
            url: '/search?search=' + this.state.search + '&perPageCampaigner=' + this.state.lembagaPerPage,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            }
        };
        const res = await API(config, false).catch(err => err);
        if (res) {
            this.setState({ results: res.data.campaigns, campaignsCount: res.data.campaignsCount, campaignersCount: res.data.campaignersCount, campaigners: res.data.campaigners })
        }
        this.setState({ lembagaPerPage: this.state.lembagaPerPage + 5 });
        this.setState({ isFetchingResults: false, loadData: true })
    }
    render() {
        const { campaigners, campaignsCount, campaignersCount, results, isFetchingResults, search, loadData } = this.state
        return (
            <div className="search-page">
                <Header
                    prevPage='/'
                    title={
                        <Input
                            type="text"
                            name="search"
                            formGroupClass="search-form txt-body"
                            placeholder="Cari Program..."
                            handleFormChange={this.handleSearch}
                            value={search}
                            icon_left="assets/img/ic-search-gray.svg"
                            icon_right={(search ? icCloseDialog : '')}
                            icon_right_click={this.resetKeyword}
                            autoFocus={true}
                        />
                    }
                />
                <main>
                    {!loadData &&
                        <Container>
                            <div className="search-results"><Loading /></div>
                        </Container>
                    }
                    {loadData && !isFetchingResults &&
                        <Container>
                            {results.length === 0 && campaigners.length === 0 ?
                                <div className="section search-not-found">
                                    <div className="text-center">
                                        <Image src={imgCampaignNotFound} />
                                        <div className="txt-subtitle">Maaf, Program Tidak Ditemukan</div>
                                        <div className="txt-body">Coba cari dengan kata kunci yang lain atau bantu saudara kita di bawah.</div>

                                    </div>
                                </div>
                                :
                                <div className="search-results">
                                    <div className="search-loader text-left">
                                        {search !== '' ?
                                            <>
                                                {results.length > 0 ?
                                                    <>
                                                        {results.length > 0 &&
                                                            <>
                                                                <div className="popular-title txt-subtitle ct-title text-left">Program</div>
                                                                <div className="txt-body text-left">Menampilkan {campaignsCount} program dengan kata kunci <b>“{search}”</b></div>
                                                                {results.map((campaign, i) => <SearchCard campaign={campaign} key={campaign.slug + campaign.index} />)}
                                                                <Link className="btn btn-primary btn-sm btn-show-more" to={"/campaign?prevPage=search&str=" + search}>
                                                                    Muat Lebih Banyak
                                                                </Link>
                                                                {/* <button onClick={this.loadMore} className="btn btn-primary btn-sm btn-show-more">
                                                                    Muat Lebih Banyak
                                                                </button> */}
                                                            </>
                                                        }
                                                        {campaigners.length > 0 &&
                                                            <>
                                                                <div className="popular-title txt-subtitle text-left mt-3">Penggalang</div>
                                                                <div className="txt-body text-left">Menampilkan {campaignersCount} penggalang dengan kata kunci <b>“{search}”</b></div>
                                                                <div className="search-result-campaigner">
                                                                    {campaigners.map((campaign, i) => <SearchCardCampaigner campaign={campaign} key={campaign.slug + campaign.index} />)}
                                                                </div>
                                                                {campaignersCount > 4 &&
                                                                    <button onClick={this.loadMore} className="btn btn-primary btn-sm btn-show-more">
                                                                        Muat Lebih Banyak
                                                                    </button>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                    :

                                                    <>
                                                        <div className="popular-title txt-subtitle ct-title text-left">Program</div>
                                                        <div classname="txt-body">Program tidak ditemukan</div>
                                                        {campaigners.length > 0 &&
                                                            <>
                                                                <div className="popular-title txt-subtitle text-left mt-3">Penggalang</div>
                                                                <div className="search-result-campaigner">
                                                                    {campaigners.map((campaign, i) => <SearchCardCampaigner campaign={campaign} key={campaign.slug + campaign.index} />)}
                                                                </div>
                                                                <hr />
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                {results.length > 0 &&
                                                    <>
                                                        <div className="popular-title txt-subtitle ct-title text-left">Pencarian Populer</div>
                                                        
                                                        {results.map((campaign, i) => <SearchCard campaign={campaign} key={campaign.slug + campaign.index} />)}
                                                        <Link className="btn btn-primary btn-sm btn-show-more" to={"/campaign?prevPage=search&str=" + search}>
                                                            Muat Lebih Banyak
                                                        </Link>
                                                        {/* <button onClick={this.loadMore} className="btn btn-primary btn-sm btn-show-more">
                                                            Muat Lebih Banyak
                                                        </button> */}
                                                    </>
                                                }
                                                {campaigners.length > 0 &&
                                                    <>
                                                        <div className="popular-title txt-subtitle text-left mt-3">Penggalang</div>
                                                        <div className="search-result-campaigner">
                                                            {campaigners.map((campaign, i) => <SearchCardCampaigner campaign={campaign} key={campaign.slug + campaign.index} />)}
                                                        </div>
                                                        {campaignersCount > 4 &&
                                                            <button onClick={this.loadMore} className="btn btn-primary btn-sm btn-show-more">
                                                                Muat Lebih Banyak
                                                            </button>
                                                        }
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                        </Container>
                    }
                </main>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchComponent);