import React from 'react';
import { Container, Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { API, clearDispatch } from '../../../../config/redux/action';
import { Header } from "../../../organisms";
import icArrowRight from '../../../../assets/svg/ic-arrow-right-gray.svg';

import './style.css';
import { Input } from '../../../../component/atoms';
import Loading from './Loading';

class FAQParent extends React.Component {
    state = {
        isFetching: false,
        results: [],
    }
    async apiResult () {
        this.setState({ isFetching: true })
        
        const config = {
            method: 'get',
            url: '/faq-topics',
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if(res) {
            this.setState({ results: res.data })
        }

        this.setState({ isFetching: false })
    }
    componentDidMount() {
        this.apiResult()
    }
    render() {
        return (
            <React.Fragment>
                <Header title="FAQs" prevPage='/profile' />
                <main>
                    <div className="faq-header text-center">
                        <div className="txt-title-m fw-6">Ada yang bisa kami bantu?</div>
                        <Link to="/faq/search">
                            <Input 
                                type="text" 
                                name="search"
                                formGroupClass="search-form search-form-pointer txt-body" 
                                placeholder="Cari Topik Pertanyaan..." 
                                icon_left="assets/img/ic-search-gray.svg"
                            />
                        </Link>
                    </div>
                    <Container>
                        {!this.state.isFetching && this.state.results.map((val, key) => (
                            <Link to={"/faq/detail/" + val.slug} key={key}>
                                <div className="cp-card d-flex">
                                    <div className="w-100">
                                        <div className="txt-subtitle-s mb-2 fw-6">{val.title}</div>
                                        <div className="txt-body-s" 
                                            dangerouslySetInnerHTML={{
                                                __html: val.preview
                                            }}
                                        ></div>
                                    </div>
                                    <div className="d-flex px-2">
                                        <Image className="m-auto" src={icArrowRight} />
                                    </div>
                                </div>
                            </Link>
                        ))}
                        {this.state.isFetching && <Loading />}
                    </Container>
                </main>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FAQParent));