import React from 'react'
import {Container, Image} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import imgPascaVerify from '../../../../assets/img/pascaverify.png'
import { Header } from '../../../organisms';
import './style.css';
const PascaVerify =  (props) => {
    return (
        <>
            <Header splash={true}/>
            <main className="pb-0">  
                <Container className="no-padding">
                    <div className="text-center preverification">
                        <Image src={imgPascaVerify}/>
                        <div className="box-gradient-bottom">
                            <div className="title txt-subtitle-m cp-title fw-6">Selamat Datang di Bantu Tetangga</div>
                            <div className="desc txt-body-m">Selamat menggalang dana, semoga kebaikan orang-orang di luar sana dapat membantu Indonesia.</div>
                            <Link to="/galang-dana" className="btn btn-secondary btn-block">Mulai Galang Dana</Link>
                            <Link to="/" className="btn btn-secondary btn-block no-border">Beranda</Link>
                        </div>
                    </div>
                </Container>
            </main>
        </>
    )
}

export default PascaVerify
