export default {
    // Galang Dana
    backPage: 0,
    title: '',
    slug: '',
    body: '',
    status: 'Publish',
    target: '',
    budget_plan: '',
    distribution_address: '',
    coverage_area: "",
    area_id: "",
    
    provinsi: "",
    loadProvinsi:false,
    dataProvinsi:false,
    kota: "",
    loadKota:true,
    dataKota:false,
    kecamatan: "",
    loadKecamatan:true,
    dataKecamatan:false,
    link_asset: '',
    cover: '',

    category_id: '',
    loadCategory: false,
    dataCategory: false,
    
    campaigner_id: '',

    //Form Wizard
    base64: {cover:""},
    intro:false,
    // expired_at_date:(new Date()),
    // publish_at_date:(new Date()),
    expired_at:"",
    expired_at_date:"",
    publish_at:"",
    publish_at_date:"",
    stepgal1:false,
    stepgal2:false,
    stepgal3:false,
    stepgal4:false,
    stepgal5:false,
    is_agree:false,
    errorMessages:{},

    editCampaign: '',
    editCampaignSlug: '',
    prevPage:''
};