import React from 'react'
import { Row, Col } from "react-bootstrap";

const Loading =  (props) => {
    return (
        <main>
            <div className="main-home">
                <div className="img-cover">
                    <div><div className="gradient cover-loading"></div></div>
                </div>
                <div className="content-space"></div>

                <div className="section">
                    
                    <div className="gradient text-loading text-loading-100 height-50"></div>
                    <div className="gradient text-loading text-loading-100 height-50"></div>
                    
                    <div className="gradient text-loading text-loading-60 height-20"></div>

                    <div className="content-grid grid-2">
                        <div>
                            <div className="gradient text-loading text-loading-90 height-100"></div>
                            <div className="gradient text-loading text-loading-60"></div>
                            <div className="gradient text-loading text-loading-90"></div>
                        </div>
                        <div>
                            <div className="gradient text-loading text-loading-90 height-100"></div>
                            <div className="gradient text-loading text-loading-90"></div>
                            <div className="gradient text-loading text-loading-40"></div>
                        </div>
                    </div>
                    
                    <div className="mt-3 gradient text-loading text-loading-50 height-20"></div>
                    <Row>
                        <Col><div className="mt-3 gradient text-loading text-loading-100 height-30"></div></Col>
                        <Col><div className="mt-3 gradient text-loading text-loading-100 height-30"></div></Col>
                        <Col><div className="mt-3 gradient text-loading text-loading-100 height-30"></div></Col>
                    </Row>

                    <div className="content-grid grid-2">
                        <div>
                            <div className="gradient text-loading text-loading-90 height-100"></div>
                        </div>
                        <div>
                            <div className="gradient text-loading text-loading-90"></div>
                            <div className="gradient text-loading text-loading-90 mb-3"></div>
                            <div className="gradient text-loading text-loading-60"></div>
                            <div className="content-grid grid-2">
                                <div className="gradient text-loading text-loading-90"></div>
                                <div className="gradient text-loading text-loading-90"></div>
                            </div>
                        </div>
                    </div>

                    <div className="content-grid grid-2">
                        <div>
                            <div className="gradient text-loading text-loading-90 height-100"></div>
                        </div>
                        <div>
                            <div className="gradient text-loading text-loading-90"></div>
                            <div className="gradient text-loading text-loading-90 mb-3"></div>
                            <div className="gradient tex]t-loading text-loading-60"></div>
                            <div className="content-grid grid-2">
                                <div className="gradient text-loading text-loading-90"></div>
                                <div className="gradient text-loading text-loading-90"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Loading
