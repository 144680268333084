import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import {Link} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { clearDispatch, API } from '../../../../config/redux/action';
import { Container, Spinner } from "react-bootstrap";
import { Header, NavMenu } from '../../../../containers/organisms';

import './style.css';

const FormCheck = props => {
    const { handleSubmit, register, errors } = useForm();
    return (
        <form className="form-hook mt-4" onSubmit={handleSubmit(props.handleSubmitForm)}>
            <div className="form-group">
                <input
                name="donation_code"
                className="form-control"
                placeholder="Kode Donasi"
                ref={register({ required: "Kode Donasi wajib diisi" })}
                onChange={props.handleChange}
                />
                <ErrorMessage errors={errors} name="donation_code" as={<p className="error-input text-left"></p>} />
                {props.errorMessage.donation_code && 
                <p className="error-input">{props.errorMessage.donation_code}</p>}
            </div>

            <button className="btn btn-primary btn-block" type="submit" disabled={props.isLoading}>
                { (!props.isLoading) && <span>Lihat Donasi</span> }
                { props.isLoading && <Spinner animation="border" role="status"/> }
            </button>
        </form>
    );
}
class CekDonasi extends React.Component {
    state = {
        formPost: {},
        code: '',
        errorCode: '',
        donation: {},
        isFetching: false,
        modal: false
    }
    handleSubmitForm = async () => {
        const {history} = this.props;

        let postData = this.state.formPost;

        const config = {
            method: 'get',
            url: '/donation/detail/'+postData.donation_code,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const res = await this.props.API(config).catch(err=>err);
        if (res) {
            history.push('/bukti-donasi/'+res.data.code);
        }
    }

    buildFormPost = (field, value) => {
        let formPostNew = {...this.state.formPost};
        formPostNew[field] = value;
        this.setState({
            formPost: formPostNew
        })
    }
    handleFormChange = (e) =>{
        this.buildFormPost(e.target.name, e.target.value);
    }
    componentDidMount (){
        this.props.clearDispatch();

        if(localStorage.getItem("userData")) {
            this.props.history.push('/mydonate')
        }
    }
    render() {
        return (
            <>
                <Header splash={true}/>
                <main className="donate-check">  
                    <Container className="no-padding txt-body-m">
                        <div className="section text-center">
                            <div className="">Masukan Kode Donasi untuk melihat Detail Donasi Anda.</div>
                            <FormCheck 
                                handleChange={this.handleFormChange} 
                                errorMessage={this.props.errorMessage}
                                handleSubmitForm={this.handleSubmitForm}
                                isLoading={this.props.isLoading}
                            />
                        </div>
                        <div className="content-space"></div>
                        <div className="section text-center">
                            Masuk untuk kelola Donasi Anda dengan mudah
                            <Link to="/login?prevPage=cek_donasi" className="btn btn-secondary btn-block mb-10 mt-10">Masuk Sekarang</Link>
                            Belum punya akun? <Link to="/register?prevPage=cek_donasi" className="fw-6">Daftar Sekarang</Link>
                        </div>
                    </Container>
                </main>
                <NavMenu/>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CekDonasi));
