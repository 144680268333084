import axios from "axios";
import { toast } from "react-toastify";
import env from "../env";

const instance = axios.create({
  baseURL: env.API_URL
});

instance.interceptors.response.use(
  (response) => response,
  errorResponseHandler
);

export default instance;

function errorResponseHandler(error) {
  if (error) {
    if (error.response) {
      // window.location.href = "/404"
      toast.error(error?.message);
      // return Promise.reject(error);
    }
  }
}