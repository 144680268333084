import React from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { useHistory, Redirect } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";
import {Container} from "react-bootstrap";
import CkEditor from './CkEditor';

import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { clearDispatch, API, validateDescription } from '../../../../config/redux/action';

import { Header, Wizard } from '../../../organisms';
import { htmlToText } from 'html-to-text';

const Step4 = props => {
  const { state, action } = useStateMachine(updateAction);
  const { handleSubmit, register, errors, setError, clearErrors } = useForm({
    defaultValues: state.postData
  });
  const { push } = useHistory();
  const onSubmit = data => {
    validateDesc(data.body)
    if(Object.keys(errors).length === 0) {
      data['stepgal4'] = true;
      action(data);
      push("/galang-dana/step5");
    }
  };
  const validateDesc = (data) => {
    const validate = props.validateDescription('body', htmlToText(data))
    if(!validate.status) {
      setError('body', { type: 'maxLength', message: validate.errorMessage.body })
    }
  }
  const handleDesc = (event, editor) => {
    clearErrors()

    let data = {};
    data['body'] = editor.getData();

    validateDesc(data.body)

    action(data);
  }

  const editCampaign = (state.postData.editCampaign ? state.postData.editCampaign : '');
  const initialSteps = {
    class1:"",
    number1:3,
    label1:"Foto & Aset",
    link1:"/galang-dana/step3"+editCampaign,
    
    class2:"step-active",
    number2:4,
    label2:"Deskripsi Campaign",
    link2:"/galang-dana/step4"+editCampaign,

    class3:"",
    number3:5,
    label3:"Konfirmasi",
    link3:"/galang-dana/step5"+editCampaign,
  }

  const linkPrev = "/galang-dana/step3"+editCampaign;

  return (
    <>
      <Header title={(state.postData.editCampaign ? "Ubah Data Campaign " : "Galang Dana")} prevPage={linkPrev} ic_white={true}/>
      <Wizard initialSteps={initialSteps} />
      <main className="pb-0">
        <Container>  
          {(!state.postData.stepgal3 && !state.postData.stepgal4) && 
            <Redirect to="/galang-dana/step3" />
          }
          <form onSubmit={handleSubmit(onSubmit)} className="form-hook">
            <h6 className="mb-3">Deskripsikan cerita penggalangan dana ini.</h6>
            <div className="form-group">
              <label className="form-label ml-0">({htmlToText(state.postData.body).length}/5000)</label>
              <CkEditor handleCKEditor={handleDesc} value={state.postData.body}/>
              {!state.postData.body && 
                <div>
                  <textarea
                    name="body"
                    ref={register({ required: "Deskripsi Wajib disi" })} 
                    className="hide"
                  />
                </div>
              }
              <ErrorMessage errors={errors} name="body" as={<p className="error-input"></p>} />
            </div>
            <button type="submit" className="btn btn-primary btn-block btn-next">Selanjutnya</button>
          </form>
        </Container>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
  clearDispatch: () => dispatch(clearDispatch()),
  API: (path, data) => dispatch(API(path, data)),
  validateDescription: (fieldName, value) => dispatch(validateDescription(fieldName, value))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step4));
