import React from "react";
import Moment from 'react-moment';
import CurrencyFormat from "react-currency-format";
import { Link } from "react-router-dom";

import './style.css'
import { Image } from "react-bootstrap";
import icHeartDonor from '../../../assets/svg/ic-heart-donor.svg';
import icOptions from '../../../assets/svg/ic-options.svg';

export default function DonationCard ({ donation, handleOptions }) {
    const DonationStatus = { 
        'pending': {
            status: 'Pending',
            className: 'state-yellow'
        }, 
        'paid': {
            status: 'Berhasil',
            className: 'state-green'
        }, 
        'expired': {
            status: 'Expired',
            className: 'state-red'
        } 
    }
    return (
        <div className="cp-card">
            <div className="d-flex">
                <div className="mr-auto txt-caption">
                    <Image src={icHeartDonor} className="mr-2"/>
                    <Moment format="DD MMMM YYYY">{donation.created_at}</Moment>
                </div>
                <div className="text-right txt-caption-m">
                    <span className={"card-state " + DonationStatus[donation.status].className}>
                        {DonationStatus[donation.status].status}
                    </span>
                    <Image src={icOptions} className="ml-1 cursor-pointer" onClick={()=>handleOptions(donation.code)}/>
                </div>
            </div>
            <hr className="mt-0" />

            <Link to={'/mydonate/'+donation.code}>
                <div className="d-flex mb-2 cursor-pointer">
                    <div className="donation-image">
                        <Image src={donation.campaign_image} width="64px" height="36px" />
                    </div>
                    <div className="txt-subtitle-s fw-6">{donation.campaign}</div>
                </div>
                <div className="txt-caption">Donasi</div>
                <div className="txt-subtitle fw-6 mb-10">
                    <CurrencyFormat 
                        value={donation.amount} 
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={'Rp '} 
                        displayType={'text'}
                    />
                </div>
            </Link>
        </div>
    )
}