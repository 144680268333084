import React, {Fragment} from 'react';
import { Alert, Container, Form, Spinner } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { withRouter } from "react-router";
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { clearDispatch, API, validateTitle, validateDescription } from '../../../../config/redux/action';
import {CkEditor} from '../../../../component/atoms';
import {StringToSlug} from '../../../../utils';
import { htmlToText } from 'html-to-text';
import './style.css';
import UploadFile from "./UploadFile";
import Toggle from "react-toggle";
import "react-toggle/style.css" // for ES6 modules

//Load Component
import { Header } from '../../../organisms';
import { useState } from 'react';

const FormAdd = props => {
    const { handleSubmit, register, errors, setValue } = useForm();
    const [cover, setCover] = useState({
        base64image: "",
        file_name: ""
    });

    const onChange = e => {
        setCover({
            base64image: e?.base64image || "",
            file_name: e?.file_name || "",
        })
        setValue("cover", e?.file_name)
        props.onChangeCover && props.onChangeCover(e);
    }
    
    return (
        <form className="form-hook mt-3" onSubmit={handleSubmit(props.handleSubmitForm)}>
            <div className="form-group">
                <label className="form-label">
                    Status
                </label>
                <select 
                    className="form-control mb-2" 
                    name="status" onChange={props.handleChange}
                    ref={register({ required: "Status wajib dipilih" })}
                    >
                    <option value="">Pilih</option>
                    <option>Publish</option>
                    <option>Draft</option>
                </select>
                <ErrorMessage errors={errors} name="status" as={<p className="error-input"></p>} />
                {props.errorMessage.status && 
                <p className="error-input">{props.errorMessage.status}</p>}
            </div>
            <div className="form-group">
                <label className="form-label">Judul</label>
                <input
                name="title" 
                className="form-control"
                ref={register({ required: "Judul wajib diisi" })}
                onChange={props.handleChange}
                placeholder="Masukkan Judul Berita"
                />
                <ErrorMessage errors={errors} name="title" as={<p className="error-input"></p>} />
                {props.errorMessage.title && 
                <p className="error-input">{props.errorMessage.title}</p>}
                {props.errorMessage.slug && 
                <p className="error-input">{props.errorMessage.slug}</p>}
            </div>

            <div className="galang-dana">
                <div className="form-group">
                    <label className="form-label">Cover Berita</label>
                    <input 
                        type="text" 
                        name="cover"
                        ref={register({ required: "Cover wajib dipilih" })}
                        hidden
                    />
                    <UploadFile 
                        title="Upload Foto Cover" 
                        field="cover" 
                        fileName="campaign_cover_" 
                        classFile={cover.base64image ? 'file' : ''} 
                        classCoverImg={cover.base64image ? 'cover-img' : 'cover-img border-radius50'} 
                        returnFile={onChange}
                        icon={cover.base64image}
                        onCancel={onChange}
                    />
                    <ErrorMessage errors={errors} name="cover" as={<p className="error-input"></p>} />
                    
                </div>
            </div>

            <div className="form-group mb-10">
                <label className="form-label ml-0">{htmlToText(props.body).length}/5000</label>
                <CkEditor handleCKEditor={props.handleDesc} value={props.body}/>
                {!props.body && 
                    <div>
                    <textarea
                        name="body"
                        ref={register({ required: "Deskripsi wajib disi" })} 
                        className="hide"
                    />
                    <ErrorMessage errors={errors} name="body" as={<p className="error-input"></p>} />
                    </div>
                }
                {props.errorMessage.body && 
                <p className="error-input">{props.errorMessage.body}</p>}
            </div>

            <div className="form-group mt-3">
                <div className='txt-subtitle-m cp-title fw-6 mb-6'>Kirim Notifikasi</div>
                <div className='form-label'>Berita ini akan dikirim ke donatur</div>
                <div className="mt-3">
                <div className='d-flex justify-content-between align-items-center mb-3'>
                    <div className='txt-subtitle-m'>Whatsapp</div>
                    <Toggle
                        className="custom-toggle-react"
                        icons={false}
                        name='broadcast_whatsapp'
                        defaultChecked={false}
                        onChange={props.handleChange}
                    />
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='txt-subtitle-m'>Email</div>
                    <Toggle
                        className="custom-toggle-react"
                        icons={false}
                        name='broadcast_email'
                        defaultChecked={false}
                        onChange={props.handleChange}
                    />
                </div>
                </div>
            </div>

            <div className="">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" name="expired" label="Penggalangan ini telah berakhir" defaultChecked={false}  onChange={props.handleChange} />
                </Form.Group>

                <Alert variant="warning">
                    <div className="d-flex align-items-center">
                        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.0003 17.4983C11.5528 17.4983 12.0007 17.0506 12.0007 16.4983C12.0007 15.9461 11.5528 15.4983 11.0003 15.4983C10.4479 15.4983 10 15.9461 10 16.4983C10 17.0506 10.4479 17.4983 11.0003 17.4983Z" fill="#C2913A"/>
                            <path d="M12.0036 5.49835H10.003V13.4983H12.0036V5.49835Z" fill="#C2913A"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 11.5C0 17.5751 4.92487 22.5 11 22.5C17.0751 22.5 22 17.5751 22 11.5C22 5.42487 17.0751 0.5 11 0.5C4.92487 0.5 0 5.42487 0 11.5ZM20 11.5C20 16.4706 15.9706 20.5 11 20.5C6.02944 20.5 2 16.4706 2 11.5C2 6.52944 6.02944 2.5 11 2.5C15.9706 2.5 20 6.52944 20 11.5Z" fill="#C2913A"/>
                        </svg>
                        <div className="ml-3 text-left" style={{flex: '1 1 0%'}}>
                            Penggalanan ini tidak akan dilanjutkan. Jangan ceklis jika Anda masih ingin melanjutkan penggalangan ini.
                        </div>
                    </div>
                </Alert>
            </div>

            
            
            <button className="btn btn-primary btn-block mt-20" type="submit" disabled={props.isLoading}>
                { (!props.isLoading) && <>Simpan Berita</> }
                { props.isLoading && <Spinner animation="border" role="status"/> }
            </button>
        </form>
    );
}
class AddNews extends React.Component {
    state = {
        formPost: {},
        detailCampaign:''
    }

    buildFormPost = (field, value) => {
        this.props.clearDispatch()
        if(field === 'title') {
            this.props.validateTitle('title', value)
        } else {
            this.props.validateTitle('title', this.state.formPost.title ? this.state.formPost.title : '')
        }
        if(field === 'body') {
            this.props.validateDescription('body', htmlToText(value))
        } else {
            this.props.validateDescription('body', htmlToText(this.state.formPost.body ? this.state.formPost.body : ''))
        }

        let formPostNew = {...this.state.formPost};
        formPostNew[field] = value;
        this.setState({
            formPost: formPostNew
        })
    }
    handleFormChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.buildFormPost(e.target.name, value);
    }
    handleDesc = (event, editor) => {
        this.buildFormPost('body', editor.getData());
    }
    resetBody = () => {
        this.buildFormPost('body', '');
    }

    handleSubmitForm = async () => {
        if(Object.keys(this.props.errorMessage).length === 0) {
            const {history} = this.props;
            const auth = this.state.auth;
            const token = `Bearer ${auth.access_token}`;

            let postData = this.state.formPost;
            postData['slug'] = StringToSlug(this.state.formPost.title);
            // postData['status'] = 'Publish';
            if (postData?.expired){
                postData.set_campaign_expired = true;
            } else {
                postData.set_campaign_expired = false;
            }

            const config = {
                method: 'post',
                url: '/campaign-news',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                data: postData
            };
            const res = await this.props.API(config).catch(err=>err);
            if (res) {
                toast.info('Berita berhasil ditambahkan', {
                    position: "top-center",
                    hideProgressBar: true,
                    autoClose:3000,
                });
                setTimeout(() =>{
                    history.push('/detail-campaign/'+this.state.campaignSlug);
                },1000 );
            }
        }
    }

    loadCampaign = async (slug) => {
        this.setState({loadCampaign:false});
        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = `Bearer ${auth.access_token}`;
        const config = {
            method: 'get',
            url: '/campaign/'+slug,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            this.setState({loadCampaign:true, detailCampaign:res.data});
        }else{
            this.setState({loadCampaign:true});
        }
    }
    
    componentDidMount (){
        this.props.clearDispatch();
        const {history} = this.props;
        
        if(localStorage.getItem("userData")){
            const auth = JSON.parse(localStorage.getItem("userData"))
            if(!auth.campaigner){
              history.push('/verifikasi-lembaga');
            }
            let url = this.props.location.pathname;
            let pathArr = url.split('/');
            let slug = pathArr[pathArr.length - 1];
            this.loadCampaign(slug);
            this.buildFormPost('campaign_slug',slug);
            this.setState({campaignSlug:slug, auth:auth});
        }else{
            history.push('/login');
        }
    }
  
    render() {
        const linkPrevPage = '/detail-campaign/'+this.state.campaignSlug;
        const detailCampaign = this.state.detailCampaign;
        const formPost = this.state.formPost;
        let sectionStyle = {
            backgroundImage: `url(${detailCampaign.cover})`
        };
        return(
            <Fragment>
                <Header title="Tulis Berita" prevPage={linkPrevPage}/>
                <main className="form-news">
                <Container className="no-padding">
                    <div className="section container-donate mb-2">
                        <div className="widget-campaign">
                            <div className="campaign-card-image">
                                <div className="center-cropped" style={sectionStyle}>
                                </div>
                            </div>
                            <div>
                                <div className="txt-body-m fw-6 ct-title">{detailCampaign.title}</div>
                            </div>
                        </div>
                    </div>
                    <div className="content-space"></div>
                    <div className="section">
                        <div className="txt-title-s fw-6">Tulis Berita Kebaikan</div>
                        <FormAdd 
                            handleChange={this.handleFormChange} 
                            errorMessage={this.props.errorMessage}
                            handleDesc={this.handleDesc}
                            handleSubmitForm={this.handleSubmitForm}
                            body={formPost.body}
                            resetBody={this.resetBody}
                            isLoading={this.props.isLoading}
                            onChangeCover={ e =>
                                this.setState({
                                    formPost: {
                                        ...formPost,
                                        image: e.file_name
                                    }
                                })
                            }
                        />
                    </div>
                </Container>
            </main>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data)),
    validateTitle: (fieldName, value) => dispatch(validateTitle(fieldName, value)),
    validateDescription: (fieldName, value) => dispatch(validateDescription(fieldName, value)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddNews));
