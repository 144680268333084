import React from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { useHistory, Redirect } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";
import {Container} from "react-bootstrap";

import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';
import { Header, Wizard } from '../../../organisms';

const Step2 = props => {
  const { state, action } = useStateMachine(updateAction);
  const { handleSubmit, register, errors } = useForm({
    defaultValues: state.postData
  });
  const { push, location } = useHistory();
  const onSubmit = data => {
    data['stepgal2'] = true;
    action(data);
    push("/galang-dana/step3");
  };
  
  const handleChange = (e) =>{
    let val = e.target.value;
    let field = e.target.name;
    let data = {};
    data[field] = val;
    data['stepgal2'] = false;
    if(field === 'coverage_area' && val !== ""){
      if(!state.postData.dataProvinsi){
        data['loadProvinsi'] = false;
        apiGetArea('Provinsi')
      }
    }

    if((field === 'provinsi' && val !== "")) {
      if(state.postData.provinsi !== val){
        data['loadKota'] = false;
        data['dataKota'] = false;
        data['loadKecamatan'] = true;
        data['dataKecamatan'] = false;
        apiGetArea('Kota', val)
      }
    }else if(field === 'provinsi' && val === ""){
      data['loadKota'] = true;
      data['dataKota'] = false;
      data['loadKecamatan'] = true;
      data['dataKecamatan'] = false;
    }

    if((field === 'kota' && val !== "")) {
      if(state.postData.kota !== val){
        data['loadKecamatan'] = false;
        data['dataKecamatan'] = false;
        apiGetArea('Kecamatan', val)
      }
    }
    data['area_id'] = e.nativeEvent.target.selectedIndex;
    action(data);
  }

  const apiGetArea = async (type, parent=null) => {
    const auth = JSON.parse(localStorage.getItem("userData"));
    const token = `Bearer ${auth.access_token}`;

    let params = '?type='+(type === 'Kota' ? 'Kabupaten/Kota' : type);
    params += ((type === 'Kota' || type === 'Kecamatan') ? '&parent='+parent : '');
    const config = {
        method: 'get',
        url: '/area'+params,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    };
    const res = await props.API(config, false).catch(err=>err);
    if (res) {
        let data = {};
        data['load'+type] = true;
        data['data'+type] = res.data.data;
        action(data);
    }
  }


  const editCampaign = (location.search ? location.search : '');
  const linkStep1 = (state.postData.prevPage ? state.postData.prevPage : editCampaign);
  const initialSteps = {
    class1:"",
    number1:"1",
    label1:"Detail",
    link1:"/galang-dana/step1"+linkStep1,

    class2:"step-active",
    number2:2,
    label2:"Lokasi",
    link2:"/galang-dana/step2"+editCampaign,

    class3:"",
    number3:3,
    label3:"Foto & Aset",
    link3:"/galang-dana/step3"+editCampaign,
  }

  const linkPrev = "/galang-dana/step1"+linkStep1;

  return (
    <>
      <Header title={(state.postData.editCampaign ? "Ubah Data Campaign " : "Galang Dana")} prevPage={linkPrev} ic_white={true}/>
      <Wizard initialSteps={initialSteps} />
      <main className="pb-0">
        <Container>  
          {(!state.postData.stepgal1 && !state.postData.stepgal2) && 
            <Redirect to="/galang-dana/step1" />
          }
          {state.postData.category_id === 'donasi-umum' ?
            <form onSubmit={handleSubmit(onSubmit)} className="form-hook">
              <h6 className="mb-3">Silahkan isi lokasi penerima manfaat berada.</h6>
              <div className="form-group">
                <label className="form-label">Jangkauan Wilayah</label>
                <select 
                  className="form-control mb-2" 
                  name="coverage_area" onChange={handleChange}
                  ref={register({ required: "Wilayah Jangkauan Wajib dipilih" })}
                  >
                  <option value="" disabled selected hidden>Pilih Jangkauan</option>
                  <option value="all">Seluruh Indonesia</option>
                  <option value="Provinsi">Tingkat Provinsi</option>
                  <option value="Kota">Tingkat Kab/Kota</option>
                </select>
                <ErrorMessage errors={errors} name="coverage_area" as={<p className="error-input"></p>} />
                {state.postData.errorMessages.coverage_area && 
                <p className="error-input">{state.postData.errorMessages.coverage_area}</p>}
              </div>
              { state.postData.coverage_area && state.postData.coverage_area !== "all" && 
                <div className="form-group">
                  <label className="form-label">
                    Provinsi
                  </label>
                  <select 
                    className="form-control mb-2" 
                    name="provinsi" onChange={handleChange}
                    ref={register({ required: "Provinsi Wajib dipilih" })}
                    >
                    <option value="">{!state.postData.loadProvinsi ? 'Sedang mengambil data..' : 'Pilih'}</option>
                    { state.postData.loadProvinsi &&
                        state.postData.dataProvinsi.map((value, key) =>
                          <option key={key} value={value.id}>{value.name}</option>
                        )
                    }
                  </select>
                  <ErrorMessage errors={errors} name="provinsi" as={<p className="error-input"></p>} />
                </div>
              }
              { state.postData.coverage_area === "Kota" && state.postData.loadProvinsi && 
                <div className="form-group">
                  <label className="form-label">Kab/Kota</label>
                  <select 
                    className="form-control mb-2" 
                    name="kota" onChange={handleChange}
                    ref={register({ required: "Kab/Kota Wajib dipilih" })}
                    >
                    <option value="">{!state.postData.loadKota ? 'Sedang mengambil data..' : 'Pilih'}</option>
                    { state.postData.loadKota && state.postData.dataKota &&
                        state.postData.dataKota.map((value, key) =>
                          <option key={key} value={value.id}>{value.name}</option>
                        )
                    }
                  </select>
                  <ErrorMessage errors={errors} name="kota" as={<p className="error-input"></p>} />
                </div>
              }

              <div className="form-group">
                <label className="form-label">Alamat Lengkap Penyaluran</label>
                <textarea
                  name="distribution_address"
                  className="form-control"
                  ref={register({ required: "Alamat Lengkap Penyaluran wajib diisi" })}
                  onChange={handleChange}
                />
                <ErrorMessage errors={errors} name="distribution_address" as={<p className="error-input"></p>} />
                {state.postData.errorMessages.distribution_address && 
                <p className="error-input">{state.postData.errorMessages.distribution_address}</p>}
              </div>
              {state.postData.errorMessages.area_id && 
              <p className="error-input">{state.postData.errorMessages.area_id}</p>}
            
              <button type="submit" className="btn btn-primary btn-block btn-next">Lanjutkan</button>
            </form>
            :
            <form onSubmit={handleSubmit(onSubmit)} className="form-hook">
              <h6 className="mb-3">Silahkan isi lokasi penerima manfaat berada.</h6>
              <div className="form-group">
                <label className="form-label">
                  Provinsi
                </label>
                <select 
                  className="form-control mb-2" 
                  name="provinsi" onChange={handleChange}
                  ref={register({ required: "Provinsi Wajib dipilih" })}
                  >
                  <option value="">{!state.postData.loadProvinsi ? 'Sedang mengambil data..' : 'Pilih'}</option>
                  { state.postData.loadProvinsi &&
                      state.postData.dataProvinsi.map((value, key) =>
                        <option key={key} value={value.id}>{value.name}</option>
                      )
                  }
                </select>
                <ErrorMessage errors={errors} name="provinsi" as={<p className="error-input"></p>} />
              </div>
              
              <div className="form-group">
                <label className="form-label">Kab/Kota</label>
                <select 
                  className="form-control mb-2" 
                  name="kota" onChange={handleChange}
                  ref={register({ required: "Kab/Kota Wajib dipilih" })}
                  >
                  
                  <option value="">{!state.postData.loadKota ? 'Sedang mengambil data..' : (!state.postData.dataKota ? '--' : 'Pilih')}</option>
                  { state.postData.dataKota &&
                      state.postData.dataKota.map((value, key) =>
                        <option key={key} value={value.id}>{value.name}</option>
                      )
                  }
                </select>
                <ErrorMessage errors={errors} name="kota" as={<p className="error-input"></p>} />
              </div>

              <div className="form-group">
                <label className="form-label">Kecamatan</label>
                <select 
                  className="form-control mb-2" 
                  name="kecamatan" onChange={handleChange}
                  ref={register({ required: "Kecamatan Wajib dipilih" })}
                  >
                  <option value="">{!state.postData.loadKecamatan ? 'Sedang mengambil data..' : (!state.postData.dataKecamatan ? '--' : 'Pilih')}</option>
                  { state.postData.dataKecamatan &&
                      state.postData.dataKecamatan.map((value, key) =>
                        <option key={key} value={value.id}>{value.name}</option>
                      )
                  }
                </select>
                <ErrorMessage errors={errors} name="kecamatan" as={<p className="error-input"></p>} />
              </div>
            
              <div className="form-group">
                <label className="form-label">Alamat Lengkap Penyaluran</label>
                <textarea
                  name="distribution_address"
                  className="form-control"
                  ref={register({ required: "Alamat Lengkap Penyaluran wajib diisi" })}
                  onChange={handleChange}
                />
                <ErrorMessage errors={errors} name="distribution_address" as={<p className="error-input"></p>} />
                {state.postData.errorMessages.distribution_address && 
                <p className="error-input">{state.postData.errorMessages.distribution_address}</p>}
              </div>
              <button type="submit" className="btn btn-primary btn-block btn-next">Selanjutnya</button>
            </form>
          }
        </Container>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
  clearDispatch: () => dispatch(clearDispatch()),
  API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step2));
