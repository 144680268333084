import React, {Fragment} from 'react';
import { Container, Image, Spinner } from 'react-bootstrap';
import { withRouter } from "react-router";
import CurrencyFormat from 'react-currency-format';
import { toast } from 'react-toastify';
import * as QueryString from "query-string";

import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';

import icArrowBottom from '../../../../assets/svg/ic-arrow-bottom.svg';
import icArrowTop from '../../../../assets/svg/ic-arrow-top.svg';
import icTotalDonasi from '../../../../assets/svg/ic-total-donasi.svg';
import FormPost from './FormPost';
import {VerifyOTP} from '../../../../containers/pages/Authorization'

import './style.css';

//Load Component
import { Header } from '../../../organisms';
import RincianDana from './RincianDana';


const fileMaxSize = 5000000 // bytes
const acceptedFileTypes = 'application/pdf, application/xls, application/xlsx, application/doc, application/docs, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {return item.trim()})

class Pencairan extends React.Component {
    state = {
        checkDisbursement:false,
        readMoreFund:false,
        formPost: {amount:0},
        loadCampaign:{},
        detailCampaign:{},
        dataRekening: {},
        dataDisbursement: {},
        dataDetailPotongan: {},
        wrongAmount: false,
        otpStep: false,
        otp: new Array(4).fill(""),
        isValidation: false,
        wrongOtp: false,
        fileRab:"",
        fileRab2:"",
        showInfoClosing:false,
    }
    constructor(props) {
        super(props)
        this.nextStep = this.nextStep.bind(this)
    }
    hideContentOTP = () => {
        this.props.history.push('/profile-campaigner/update-phone?prevPage=pencairan/'+this.state.detailCampaign.slug);
    }
    loadRekening = async (slug) => {
        this.setState({loadRekening:false});
        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = `Bearer ${auth.access_token}`;
        const config = {
            method: 'get',
            url: '/campaigner/account-number',
            headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            const formPost = this.state.formPost
            if(res.data.length === 1) {
                formPost.account_number_id = res.data[0].id
            }
            this.setState({loadRekening:true, dataRekening:res.data, formPost});
        }else{
            this.setState({loadRekening:true});
        }
    }

    loadCampaign = async (slug) => {
        this.setState({loadCampaign:false});
        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = `Bearer ${auth.access_token}`;
        const config = {
            method: 'get',
            url: '/campaign/'+slug,
            headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            this.setState({loadCampaign:true, detailCampaign:res.data});
            localStorage.setItem('detailCampaign', JSON.stringify(res.data));
        }else{
            this.setState({loadCampaign:true});
        }
    }
    showMoreFund = () => {
        this.setState({readMoreFund:true})
    }
    showLessFund = () => {
        this.setState({readMoreFund:false})
    }

    buildFormPost = (field, value) => {
        if(field==='amount'){
            value = (value === '' ? 0 : value);
            const detailCampaign = this.state.detailCampaign;
            if(value > detailCampaign.withdrawable_funds){
                this.setState({wrongAmount:true})
            }
        }
        let formPostNew = {...this.state.formPost};
        formPostNew[field] = value;
        this.setState({
            formPost: formPostNew
        })
    }

    verifyFile = (files) => {
        if (files && files.length > 0){
                const currentFile = files[0]
                const currentFileType = currentFile.type
                const currentFileSize = currentFile.size
                if(currentFileSize > fileMaxSize) {
                    toast.error("Ukuran file upload maksimal "+this.bytesToSize(fileMaxSize), {
                        position: "top-center",
                        hideProgressBar: true,
                        autoClose:3000,
                    });
                    return false
                }
                if (!acceptedFileTypesArray.includes(currentFileType)){
                    
                    toast.error("Tidak dapat mengupload file jenis ini", {
                        position: "top-center",
                        hideProgressBar: true,
                        autoClose:3000,
                    });
                    return false
                }
                return true
        }
    }
    handleFormChange = (e) =>{
        let field = e.target.name;
        let value = e.target.value;
        if(field === 'rab'){
            if(this.verifyFile(e.target.files)){
                value = e.target.files[0];
                this.setState({fileRab:e.target.files[0].name, fileRab2: value});
            }
        }

        if(field === 'closing'){
            if(e.target.checked){
                this.setState({showInfoClosing:true})
            }else{

                this.setState({showInfoClosing:false})
            }
        }
        this.buildFormPost(field, value);
    }
    
    choosingRek = (value) => {
        this.buildFormPost('account_number_id', value);
    }

    resetFile = (e) =>{
        const file = document.querySelector('.file'); 
        file.value = ''; 
        this.setState({
            fileRab: ''
        })
    }

    bytesToSize = (bytes) => {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    handleSubmitForm = async () => {
        let dataChecking = this.state.formPost;
        dataChecking['campaign_slug'] = this.state.detailCampaign.slug;

        if(dataChecking.amount > parseInt(this.state.detailCampaign.withdrawable_funds)){
            this.setState({wrongAmount:true})
        }else{
            const auth = this.state.auth;
            const token = `Bearer ${auth.access_token}`;
            const url = (dataChecking.code ? dataChecking.code : 'submission');
            const method = (dataChecking.code ? 'put' : 'post');
            dataChecking['just_validate'] = true;
            dataChecking['rab'] = 'just_validate';
            dataChecking['set_campaign_expired'] = this.state.showInfoClosing;

            const configPencairan = {
                method: method,
                url: '/campaigner/disbursement/'+url,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                data: dataChecking
            };
            const resPencairan = await this.props.API(configPencairan).catch(err=>err);
            if (resPencairan) {
                this.setState({ otpStep: true })
            } else {
                this.setState({ otpStep: false })
            }

        }
    }
    
    handleCurrency = (field, value) => {
        this.setState({wrongAmount:false})
        this.buildFormPost(field, value);
    }

    loadDisbursement = async (code) => {
        const auth = JSON.parse(localStorage.getItem("userData"))
        const token = `Bearer ${auth.access_token}`;
        this.setState({loadDisbursement:false});
        const config = {
            method: 'get',
            url: '/campaigner/disbursement/'+code,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            const newFormPost = {
                "code": res.data.code,
                "account_number_id": res.data.account_number_id,
                "amount" : res.data.amount,
                "link_rab": res.data.rab,
                "description": res.data.description
            }
            this.setState({loadDisbursement:true, dataDisbursement:res.data, formPost:newFormPost});
        }else{
            this.setState({loadDisbursement:true});
        }
    }

    loadDetailPotongan = async (slug) => {
        const auth = JSON.parse(localStorage.getItem("userData"))
        const token = `Bearer ${auth.access_token}`;
        this.setState({loadDetailPotongan:false});
        const config = {
            method: 'get',
            url: '/campaigner/campaign-charges/group/'+slug,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            this.setState({loadDetailPotongan:true, dataDetailPotongan:res.data});
        }else{
            this.setState({loadDetailPotongan:true});
        }
    }
    
    async nextStep(res) {
        const otp_code = res.data;
        const auth = this.state.auth;
        const token = `Bearer ${auth.access_token}`;

        let postData = this.state.formPost;
        postData['just_validate'] = false;
        if(!postData.code){
            let formData = new FormData();
            formData.append("document", this.state.fileRab2);
            formData.append("token", otp_code)
            const confStoreImage = {
                    method: 'post',
                    url: '/document',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token
                    },
                    data: formData
            };
            const res = await this.props.API(confStoreImage, true).catch(err=>err);
            if (res) {
                postData['rab'] = res.data.file_name;
            } else {
                this.setState({ isValidation: true, wrongOtp: false })
                return false
            }
        }

        const url = (postData.code ? postData.code : 'submission');
        const method = (postData.code ? 'put' : 'post');
        const configPencairan = {
            method: method,
            url: '/campaigner/disbursement/'+url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            data: { ...postData, otp_code: otp_code }
        };
        const resPencairan = await this.props.API(configPencairan).catch(err=>err);
        if (resPencairan) {
            this.props.history.push({
                pathname: '/success-disbursement'
            })
        } else {
            this.setState({ otpStep: false })
        }
    }

    cekAllowDisbursement = async (slug) => {
        this.setState({checkDisbursement:false});
        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = `Bearer ${auth.access_token}`;
        const config = {
            method: 'get',
            url: '/campaigner/disbursement/campaign-status/'+slug,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            if(!res.data.allowed){
                toast.error(res.message, {
                    position: "top-center",
                    hideProgressBar: true,
                    autoClose:3000,
                });
                
                setTimeout(() =>{
                    this.props.history.push('/campaign-saya');
                },1000 );
            }else{
                this.setState({checkDisbursement:true});
                this.loadCampaign(slug);
                this.loadRekening();
                this.loadDetailPotongan(slug);

                const queryParams = QueryString.parse(this.props.location.search);
                if(queryParams.edit){
                    const code = queryParams.edit;
                    this.setState({code:code});
                    this.loadDisbursement(code);
                }
            }
        }
        
    }
    componentDidMount (){
        this.props.clearDispatch();
        const {history} = this.props;
        
        if(localStorage.getItem("userData")){
            const auth = JSON.parse(localStorage.getItem("userData"))
            if(!auth.campaigner.approved_at){
                history.push('/awaiting-verification');
            }
            let url = this.props.location.pathname;
            let pathArr = url.split('/');
            let slug = pathArr[pathArr.length - 1];
            this.setState({campaignSlug:slug, auth:auth});
            this.cekAllowDisbursement(slug);
        }else{
            history.push('/login');
        }
    }
    render() {
        const {auth, detailCampaign, checkDisbursement,  readMoreFund, code, otpStep, loadDetailPotongan, dataDetailPotongan} = this.state;
        const title = !otpStep ? (code ? 'Ubah Data Pencairan' : 'Pencairan Dana') : 'Verifikasi Pencairan';
        let sectionStyle = {
            backgroundImage: `url(${detailCampaign.cover})`
        };
        return(
            <Fragment>
                {!otpStep && 
                    <>
                    <Header title={title} prevPage="/campaign-saya"/>
                    <main>
                        <Container className="withdrawal no-padding">
                            
                            {checkDisbursement ? 
                                <>
                                <div className="section">
                                    <div className="container-donate">
                                        <div className="widget-campaign">
                                            <div className="campaign-card-image">
                                                <div className="center-cropped" style={sectionStyle}>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="txt-body-m fw-6 ct-title">{detailCampaign.title}</div>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div className="fund txt-body-m">
                                        <div>
                                            <div className="content-grid grid-2">
                                                <div className="ct-title"><Image src={icTotalDonasi}/> Total Donasi</div>
                                                <div>
                                                    <CurrencyFormat 
                                                        value={detailCampaign.donation_collected} 
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        prefix={'Rp '} 
                                                        className="fw-6 ct-title"
                                                        displayType={'text'}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"no-border "+((!readMoreFund) ? 'content-less' : '')}>
                                            {loadDetailPotongan && 
                                                <>
                                                    {dataDetailPotongan.map( (detailPotongan, i) => {
                                                        return <RincianDana charge={detailPotongan} campaignSlug={detailCampaign.slug} key={i}/>
                                                    })}
                                                </>
                                            }
                                        </div>
                                        
                                        <div className="content-grid grid-2 dana-cair">
                                            <div className="ct-title">Dana yang dapat dicairkan</div>
                                            <div>
                                                <CurrencyFormat 
                                                    value={detailCampaign.withdrawable_funds} 
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    prefix={'Rp '} 
                                                    className="fw-6 ct-title"
                                                    displayType={'text'}
                                                />
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            {!readMoreFund ? 
                                                <button className="read-more" onClick={this.showMoreFund}>
                                                        Detail Donasi <Image src={icArrowBottom} />
                                                </button>
                                                :
                                                <button className="read-more" onClick={this.showLessFund}>
                                                    Tutup <Image src={icArrowTop} />
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                                
                                {this.state.dataDisbursement && 
                                    <FormPost 
                                        resetFile={this.resetFile} 
                                        handleChange={this.handleFormChange} 
                                        errorMessage={this.props.errorMessage}
                                        handleSubmitForm={this.handleSubmitForm}
                                        states={this.state}
                                        handleCurrency={this.handleCurrency}
                                        isLoading={this.props.isLoading}
                                        onLoadRek={this.loadRekening}
                                        fileSizeUpload={this.bytesToSize(fileMaxSize)}
                                        dataDisbursement={this.state.dataDisbursement}
                                        showInfoClosing={this.state.showInfoClosing}
                                        choosingRek={this.choosingRek}
                                    />
                                }
                                </>
                                :
                                <div className="text-center mt-5"><Spinner animation="border"/></div>
                            }
                        </Container>
                    </main>
                    </>
                }
                {otpStep && 
                    <VerifyOTP 
                        title={"Verifikasi Pencairan"} 
                        prevPage="/pencairan" 
                        sendOTPFirst={true}
                        contact={auth.phone} 
                        type="phone" 
                        nextStep={this.nextStep}
                        hideContentOTP={this.hideContentOTP}
                        sendPost={({type:'Phone'})}
                    />
                }
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pencairan));
