import React from 'react'
import { Link } from 'react-router-dom';
import {Image} from 'react-bootstrap';
import icVerified from '../../../assets/svg/ic-verified-campaigner.svg';
import icFoundation from '../../../assets/svg/ic-foundation.svg';
import icCommunity from '../../../assets/svg/ic-community.svg';

import './style.css'

export default function SearchCard({ campaign, classes }) {
    const defaultClasses = {
        root: "search-card",
        cardImage: "search-card-image",
        cardInfo: "search-card-info",
        cardTitle: "search-card-info-title txt-body",
        cardCampaigner: "search-card-info-campaigner txt-caption",
        
        // Override classes
        ...classes
    }
    return (
        <Link className={defaultClasses.root} to={"/campaign/"+campaign.slug+"?prevPage=search"}>
            <img className={defaultClasses.cardImage} alt={campaign.title} src={campaign.cover} />
            <div className={defaultClasses.cardInfo}>
                <div className={defaultClasses.cardTitle}>{campaign.title}</div>
                <div className={defaultClasses.cardCampaigner}>
                    {campaign.campaigner.name} 
                    {campaign.campaigner.verified &&
                        <Image src={icVerified}/>
                    }
                    {campaign.campaigner.is_foundation && <Image src={icFoundation}/>}
                    {campaign.campaigner.is_community && <Image src={icCommunity}/>}
                </div>
            </div>
        </Link>
    )
}