import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';
import { useForm } from "react-hook-form";

import './style.css';
import { Container, Image, Spinner } from 'react-bootstrap';
import Loading from '../ReportList/Loading';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import { Header } from '../../../organisms';
import { ErrorMessage } from '@hookform/error-message';

const FormPost = props => {
    const { handleSubmit, register, errors } = useForm();
    return (
        <form className="form-hook mt-3" onSubmit={handleSubmit(props.sendRespond)}>
            <div className="form-group">
                <textarea
                    name="respon"
                    className="form-control"
                    ref={register({ required: "Wajib diisi" })}
                    onChange={props.handleChange}
                />
                <ErrorMessage errors={errors} name="respon" as={<p className="error-input text-left"></p>} />
                {props.errorMessage.respon && 
                <p className="error-input">{props.errorMessage.respon}</p>}
            </div>
            <button className="btn btn-primary btn-block" type="submit" disabled={props.isLoading}>
                { (!props.isLoading) && <span>Kirim Respon</span> }
                { props.isLoading && <Spinner animation="border" role="status"/> }
            </button>
        </form>
    );
}

class RespondReport extends React.Component {
    state = {
        report: {},
        auth: {},
        token: '',
        isFetchingReport: true,
        isDeleting: false,
        formPost: {},
    }

    sendRespond = async () => {
        const {history} = this.props;
        const newData =  {
            "text": this.state.formPost.respon,
            "campaign_report_code": this.state.report.code
        };
        const config = {
            method: 'post',
            url: '/campaign-report/response',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            },
            data: newData
        };
        const res = await this.props.API(config).catch(err=>err);
        if (res) {
            toast.info('Respon anda berhasil dikirim', {
                position: "top-center",
                hideProgressBar: true,
                autoClose:3000,
            });
            setTimeout(() =>{
                history.push('/laporan/'+this.props.match.params.slug);
            },1000 );
        }
    }
    handleFormChange = (e) =>{
        let formPostNew = {...this.state.formPost};
        formPostNew[e.target.name] = e.target.value;

        let errorMessageNew = this.props.errorMessage;
        if(errorMessageNew.length > 0){
        }else{
            if(formPostNew[e.target.name]){
                errorMessageNew[e.target.name] = '';
            }
        }

        this.setState({
            formPost: formPostNew,
            errorMessage: errorMessageNew
        })
    }
    async apiReport (token) {
        this.setState({ isFetchingReport: true })
        const config = {
            method: 'get',
            url: '/campaign-report/detail/' + this.props.match.params.slug,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            this.setState({ report: res.data });
        }
        this.setState({ isFetchingReport: false })
    }
    componentDidMount (){
        this.props.clearDispatch();
        const { history } = this.props;
        
        if(localStorage.getItem("userData")){
            const auth = JSON.parse(localStorage.getItem("userData"))
            const token = `Bearer ${auth.access_token}`
            this.setState({ auth, token })
            this.apiReport(token)
        }else{
            history.push('/login');
        }
    }
    async handleDeleteReport() {
        this.setState({ isDeleting: true })

        const config = {
            method: 'delete',
            url: '/campaign-report/' + this.props.match.params.slug,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            },
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if(res) {
            toast.info('Laporan berhasil dihapus', {
                position: "top-center",
                hideProgressBar: true,
                autoClose: 3000,
            });
            setTimeout(() =>{
                this.props.history.push('/laporan');
            }, 1000 );
            return
        }

        this.setState({ isDeleting: false })
    }
    render() {
        let sectionStyle = {
            backgroundImage: (this.state.report ? (this.state.report.campaign ? `url(${this.state.report.campaign.cover})` : '') : '')
        };
        return (
            <React.Fragment>
                <Header title="Respon Laporan" prevPage={"/laporan/"+this.props.match.params.slug}/>
                <main className="report-detail">
                    {!this.state.isFetchingReport && 
                        <>
                        <Container>
                            <div className="txt-subtitle fw-6 ct-title mb-3">{this.state.report.category || 'Tidak ada kategori'}</div>
                            <div className="txt-caption">
                                <span>Dibuat pada </span>
                                <Moment format=" D MMM YYYY" className="txt-caption">
                                    {this.state.report.created_at}
                                </Moment>
                            </div>
                            <div className="widget-campaign mt-2">
                                <div className="campaign-card-image">
                                    <div className="center-cropped" style={sectionStyle}>
                                    </div>
                                </div>
                                <div>
                                    <div className="txt-body-m">{this.state.report.campaign.title}</div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="txt-caption">Penggalang Dana</div>
                                <div className="txt-body-m ct-title">{this.state.report.campaigner}</div>
                            </div>
                            <hr />
                            <div className="mt-3">
                                <div className="txt-caption">Keterangan</div>
                                <div className="txt-body ct-title">{this.state.report.description}</div>
                            </div>
                            <div className="mt-3">
                                <div className="txt-caption">Lampiran</div>
                                <div className="report-evidence">
                                    {this.state.report.images.map((val, key) => (
                                        <Image src={val} key={key} />
                                    ))}
                                </div>
                            </div>
                        </Container>
                        <div className="content-space"></div>
                        <div className="section">
                            <div className="txt-subtitle fw-6 ct-title mb-2">Respon Anda</div>
                            <FormPost 
                                handleChange={this.handleFormChange} 
                                errorMessage={this.props.errorMessage}
                                sendRespond={this.sendRespond}
                                isLoading={this.props.isLoading}
                            />
                        </div>
                        </>
                    }
                    {this.state.isFetchingReport && <Loading />}
                </main>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RespondReport));