import React, {Fragment} from 'react';
import { Container, Spinner, Image } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { withRouter } from "react-router";
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import logo from "../../../../assets/svg/logo-footer.svg"

import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';
import { ExternalLink } from 'react-external-link';

//Load Component
import { Header } from '../../../organisms';
import {VerifyOTPGuest, SetPassword} from '../../../../containers/pages/Authorization'

const FormPost = props => {
    const { handleSubmit, register, errors } = useForm();
    return (
        <form className="form-hook mt-3" onSubmit={handleSubmit(props.checkVerify)}>
            <div className="form-group">
                <label className="form-label">Masukkan Nomor Ponsel atau Email</label>
                <input
                type="text"
                name="contact"
                className="form-control"
                ref={register({ required: "Nomor Ponsel atau Email wajib diisi" })}
                onChange={props.handleChange}
                />
                <ErrorMessage errors={errors} name="contact" as={<p className="error-input text-left"></p>} />
                {props.errorMessage.contact && 
                <p className="error-input">{props.errorMessage.contact}</p>}
            </div>
            <button className="btn btn-primary btn-block" type="submit" disabled={props.isLoading}>
                { (!props.isLoading) && <span>Kirim Kode Verifikasi</span> }
                { props.isLoading && <Spinner animation="border" role="status"/> }
            </button>
        </form>
    );
}

class ForgotPassword extends React.Component {

    state = {
        formPost: {},
        isVerify:false,
        setPassword:false,
        isPasswordShown:false,
        isPasswordConfirmShown:false,
    }
    handleFormChange = (e) =>{
        let formPostNew = {...this.state.formPost};
        formPostNew[e.target.name] = e.target.value;

        let errorMessageNew = this.props.errorMessage;
        if(errorMessageNew.length > 0){
        }else{
            if(formPostNew[e.target.name]){
                errorMessageNew[e.target.name] = '';
            }
        }

        this.setState({
            formPost: formPostNew,
            errorMessage: errorMessageNew
        })
    }

    handleSubmit = async () => {
        this.setState({isVerify:false});
        const {history} = this.props;
        const newData =  {
            "password_confirmation": this.state.formPost.password,
            "password": this.state.formPost.password,
            "token" : this.state.formPost.otp_code
        };
        const config = {
            method: 'post',
            url: '/auth/reset_password',
            headers: {
                'Content-Type': 'application/json',
            },
            data: newData
        };
        const res = await this.props.API(config).catch(err=>err);
        if (res) {
            toast.info('Password berhasil dirubah', {
                position: "top-center",
                hideProgressBar: true,
                autoClose:3000,
            });
            setTimeout(() =>{
                history.push('/login');
            },1000 );
        }
    }

    checkVerify = async () => {
        let formData = { 
            type: "Contact", 
            content_type: "code", 
            contact: this.state.formPost.contact, 
            action:'forgot_password' 
        };
        const config = {
            method: 'post',
            url: '/otp',
            headers: {
                'Content-Type': 'application/json'
            },
            data: formData
        };
        const res = await this.props.API(config).catch(err=>err);
        if (res) {   
            const type = res.data.type;
            const lowerType = (type.toLowerCase());
            let newFormPost = this.state.formPost;
            newFormPost[lowerType]= this.state.formPost.contact;
            this.setState({
                isVerify: true, 
                action:'forgot_password',
                type:type, 
                lowerType:lowerType, 
                code:res.data.code,
                formPost:newFormPost
            });
        }
    }

    togglePasswordVisibility = () => {
        const {isPasswordShown} = this.state;
        this.setState({isPasswordShown: !isPasswordShown});
    }
    togglePasswordConfirmVisibility = () => {
        const {isPasswordConfirmShown} = this.state;
        this.setState({isPasswordConfirmShown: !isPasswordConfirmShown});
    }

    nextStep = (resOtp) => {
        let newFormPost = this.state.formPost;
        newFormPost['otp_code']= resOtp.data;
        this.setState({isVerify:false, setPassword:true, formPost:newFormPost});
    }
    
    hideContentOTP = () => {
        this.setState({isVerify:false});
    }
    hideContentPassword = () => {
        this.setState({isVerify:false, setPassword:false});
    }

    componentDidMount(){
        this.props.clearDispatch();
    }

    render() {
        const {isPasswordShown, isPasswordConfirmShown} = this.state;
        return(
            <Fragment>
                
                {!this.state.setPassword ? 
                <>
                    {!this.state.isVerify ?
                    <>
                        <Header title="Lupa Kata Sandi" prevPage="/login"/>
                        <main>
                            <Container className="container-login mt-3">
                                <div className="text-center">
                                    <Image src={logo} rounded />
                                </div>
                                <div className="txt-title-m fw-6 mt-4 mb-2">Lupa Kata Sandi</div>
                                <FormPost 
                                    handleChange={this.handleFormChange} 
                                    errorMessage={this.props.errorMessage}
                                    checkVerify={this.checkVerify}
                                    isLoading={this.props.isLoading}
                                />
                                <div className="text-center txt-body-m pt-1">
                                    <div>Sudah ingat password? <Link to="/login">Silahkan Login</Link></div>
                                    <div className="mt-10">Memiliki Kendala? <ExternalLink href={"https://wa.me/"+process.env.REACT_APP_WA_CS}>Hubungi Kami</ExternalLink></div>
                                </div>
                            </Container>
                        </main>
                        </>
                    :
                    <VerifyOTPGuest 
                        title={"Verifikasi Kode OTP"}
                        type={this.state.lowerType} 
                        contact={this.state.formPost.contact} 
                        action={this.state.action}
                        nextStep={this.nextStep}
                        code={this.state.code}
                        hideContentOTP={this.hideContentOTP}
                        sendPost={({type:this.state.type, contact:this.state.formPost.contact, action:this.state.action})}
                    />
                    }
                </>
                :
                    <SetPassword 
                        handleChange={this.handleFormChange} 
                        errorMessage={this.props.errorMessage}
                        handleSubmit={this.handleSubmit}
                        formPost = {this.state.formPost}
                        isLoading={this.props.isLoading}
                        isPasswordShown={isPasswordShown}
                        hideContentPassword={this.hideContentPassword}
                        isPasswordConfirmShown={isPasswordConfirmShown}
                        togglePasswordVisibility = {this.togglePasswordVisibility}
                        togglePasswordConfirmVisibility = {this.togglePasswordConfirmVisibility}
                        title="Buat Kata Sandi Baru"
                        btnName="Simpan"
                    />
                }
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (config, isDispatch) => dispatch(API(config, isDispatch))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword));