import React, {Fragment} from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { withRouter } from "react-router";
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';
import './style.css';

//Load Component
import { Header } from '../../../organisms';

const FormAdd = props => {
    const { handleSubmit, register, errors } = useForm();
    return (
        <form className="form-hook mt-3" onSubmit={handleSubmit(props.handleSubmitForm)}>
            <div className="form-group">
                <label className="form-label">Nama Bank</label>
                <input
                name="bank"
                className="form-control"
                ref={register({ required: "Nama Bank wajib diisi" })}
                onChange={props.handleChange}
                />
                <ErrorMessage errors={errors} name="bank" as={<p className="error-input"></p>} />
                {props.errorMessage.bank && 
                <p className="error-input">{props.errorMessage.bank}</p>}
            </div>
            <div className="form-group">
                <label className="form-label">No Rekening</label>
                <input
                name="account_number"
                className="form-control"
                ref={register({ required: "No Rekening wajib diisi" })}
                onChange={props.handleChange}
                />
                <ErrorMessage errors={errors} name="account_number" as={<p className="error-input"></p>} />
                {props.errorMessage.account_number && 
                <p className="error-input">{props.errorMessage.account_number}</p>}
            </div>
            <div className="form-group">
                <label className="form-label">Atas Nama</label>
                <input
                name="account_holder"
                className="form-control"
                ref={register({ required: "Atas Nama wajib diisi" })}
                onChange={props.handleChange}
                />
                <ErrorMessage errors={errors} name="account_holder" as={<p className="error-input"></p>} />
                {props.errorMessage.account_holder && 
                <p className="error-input">{props.errorMessage.account_holder}</p>}
            </div>
            
            <button className="btn btn-primary btn-block mt-20" type="submit" disabled={props.isLoading}>
                { (!props.isLoading) && <>Simpan Rekening</> }
                { props.isLoading && <Spinner animation="border" role="status"/> }
            </button>
        </form>
    );
}
class AddRekening extends React.Component {
    state = {
        formPost: {},
        detailCampaign:''
    }

    buildFormPost = (field, value) => {
        let formPostNew = {...this.state.formPost};
        formPostNew[field] = value;
        this.setState({
            formPost: formPostNew
        })
    }
    handleFormChange = (e) =>{
        this.buildFormPost(e.target.name, e.target.value);
    }

    handleSubmitForm = async () => {
        const {history} = this.props;
        const auth = this.state.auth;
        const token = `Bearer ${auth.access_token}`;

        let postData = this.state.formPost;

        const config = {
            method: 'post',
            url: '/campaigner/account-number',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            data: postData
        };
        const res = await this.props.API(config).catch(err=>err);
        if (res) {
            toast.info('Rekening berhasil ditambahkan', {
                position: "top-center",
                hideProgressBar: true,
                autoClose:3000,
            });
            setTimeout(() =>{
                history.push('/pencairan/'+this.state.campaignSlug);
            },1000 );
        }
    }
    
    componentDidMount (){
        this.props.clearDispatch();
        const {history} = this.props;
        
        if(localStorage.getItem("userData")){
            const auth = JSON.parse(localStorage.getItem("userData"))
            if(!auth.campaigner){
              history.push('/awaiting-verification');
            }
            let url = this.props.location.pathname;
            let pathArr = url.split('/');
            let slug = pathArr[pathArr.length - 1];
            this.buildFormPost('campaign_slug',slug);
            this.setState({campaignSlug:slug, auth:auth});
        }else{
            history.push('/login');
        }
    }
  
    render() {
        const linkPrevPage = '/pencairan/'+this.state.campaignSlug;
        return(
            <Fragment>
                <Header title="Tambah Rekening" prevPage={linkPrevPage}/>
                <main>
                    <Container>
                        <FormAdd 
                            handleChange={this.handleFormChange} 
                            errorMessage={this.props.errorMessage}
                            handleSubmitForm={this.handleSubmitForm}
                            isLoading={this.props.isLoading}
                        />
                    </Container>
                </main>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddRekening));
