import React, {useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { useHistory, Link } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import Moment from 'react-moment';
import updateAction from "../../../../config/FormWizard/updateAction";
import {Nav, Spinner, Container, Image} from "react-bootstrap";
import { Header, Penyaluran, AturWaktu } from '../../../organisms';

import "react-datepicker/dist/react-datepicker.css";

import CurrencyFormat from 'react-currency-format';

import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';
import postData from './postData';
import {FbPixel} from '../../../../config/Tracker/FbPixel'
import IcInfoDefault from '../../../../assets/svg/ic-info-default.svg';
import icArrowRight from '../../../../assets/svg/ic-arr-right.svg';

const IntitateFirst = () => {
  
  const { state, action } = useStateMachine(updateAction);
  const nextLink = "/donasi-beras/tetangga-bantu-tetangga";  
  const { push } = useHistory();
  useEffect(()=>{
    FbPixel('AddToCart');
  },[])
  useEffect(() => {
    let data = {};
    if(state.postData.stepdon3 ){
      data = postData;
      data['stepdon3'] = false;
      action(data);
      push(nextLink+"/step2");
    }
  },[action, nextLink, push, state])
  return null;
}


const AturPenyaluran = (props ) => {
  const linkPrev = "/donasi-beras/tetangga-bantu-tetangga/step1";
  const nextLink = "/donasi-beras/tetangga-bantu-tetangga/step2";
  const { state, action } = useStateMachine(updateAction);
  const [show, setShow] = useState(false);
  const [showAturWaktu, setShowAturWaktu] = useState(false);
  const [showDonasi, setShowDonasi] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseAturWaktu = () => setShowAturWaktu(false);
  const handleShowAturWaktu = () => setShowAturWaktu(true);
  const [isChoose, setIsChoose] = useState(false);
  const { handleSubmit, errors, register } = useForm({
    defaultValues: state.postData
  });
  const { push } = useHistory();
  const onSubmit = data => {
    nextPage(data);
  };

  const nextPage = (data) => {
    loadPaymentTypes(data);
  }

  const loadPaymentTypes = async (data) => {
    data['loadPaymentTypes'] = false;
    action(data);
    const config = {
        method: 'get',
        url: '/public/payment-types/group',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    const res = await props.API(config).catch(err=>err);
    if (res) {
      data['loadPaymentTypes'] = true;
      data['dataPaymentTypes'] = res.data;
      action(data);
      checkValues(data);
    }
  }

  const checkValues = async (data) => {
    let formData = {
      amount: state.postData.amount,
      type_donation: state.postData.type_donation,
      area_id: state.postData.kecamatan,
      date: state.postData.date,
      address: state.postData.type_donation === 'deliver' ? 'Jl. Setra Dago Utama No 27 Antapani, Kota Bandung, 40291 6281221127728' : state.postData.address,
      zip_code: state.postData.type_donation === 'deliver' ? '40291' : state.postData.zip_code,
      pickup_time_id: state.postData.pickup_time_id,
      deliver_time_id: state.postData.deliver_time_id,
      just_validate:true
    };
    
    const config = {
        method: 'post',
        url: '/public/donation-rice',
        headers: {
            'Content-Type': 'application/json'
        },
        data: formData
    };

    const res = await props.API(config, true, true).catch(err=>err);
    if (res) {
      data['stepdon1'] = true;
      data['resultDonation'] = {snapToken:false};
      push(nextLink);
    }else{
      let data={};
      data['checkMessage'] = true;
      action(data);

    }
  }

  const handleChange = (e) =>{
    let val = e.target.value;
    let field = e.target.name;
    let data = {};
    data[field] = val;
    data['stepdon2'] = false;
    if((field === 'provinsi' && val !== "")) {
      if(state.postData.provinsi !== val){
        data['loadKota'] = false;
        data['dataKota'] = false;
        data['loadKecamatan'] = true;
        data['dataKecamatan'] = false;
        apiGetArea('Kota', val)
      }
    }else if(field === 'provinsi' && val === ""){
      data['loadKota'] = true;
      data['dataKota'] = false;
      data['loadKecamatan'] = true;
      data['dataKecamatan'] = false;
    }

    if((field === 'kota' && val !== "")) {
      if(state.postData.kota !== val){
        data['loadKecamatan'] = false;
        data['dataKecamatan'] = false;
        apiGetArea('Kecamatan', val)
      }
    }
    action(data);
  }

  const handleGetTime = async () => {
    const config = {
        method: 'get',
        url: '/public/date-range',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const res = await props.API(config, false).catch(err=>err);
    if (res) {
        let data = {};
        data['dataDateRange'] = res;
        action(data);
        apiGeneralTime();
    }
  }

  const handleChangeDate = (value) =>{
    let data = {};
    data['date'] = value;
    state.postData.pickup_time_id = ''
    state.postData.deliver_time_id = ''
    action(data);
  }

  const handleCancel = () =>{
    let data = {};
    data['date'] = state.postData.dataDateRange[1];
    action(data);
    if (state.postData.type_donation === 'pickup') {
      data['pickup_time_id'] = 1;
      data['deliver_time_id'] = '';
      action(data);
    }
    else if (state.postData.type_donation === 'deliver') {
      data['deliver_time_id'] = 1;
      data['pickup_time_id'] = '';
      action(data);
    }
    setShowAturWaktu(false);
  }

  const handleChangeTime = (value) =>{
    let data = {};
    if (state.postData.type_donation === 'pickup') {
      data['pickup_time_id'] = value;
      data['deliver_time_id'] = '';
      action(data);
    }
    else if (state.postData.type_donation === 'deliver') {
      data['deliver_time_id'] = value;
      data['pickup_time_id'] = '';
      action(data);
    }
  }

  const apiGeneralTime = async () => {
    const config = {
        method: 'get',
        url: '/public/general-times',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const res = await props.API(config, false).catch(err=>err);
    if (res) {
        let data = {};
        data['dataGeneralTime'] = res.data;
        action(data);
        handleShowAturWaktu();
    }
  }

  const apiGetArea = async (type) => {

    let params = '&type=Kecamatan';
    const config = {
        method: 'get',
        url: '/area?is_donation_rice=1'+params,
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const res = await props.API(config, false).catch(err=>err);
    if (res) {
        let data = {};
        data['loadKecamatan'] = true;
        data['dataKecamatan'] = res.data.data;
        action(data);
    }
  }
  return (
    <>
      <Header title="Tetangga Bantu Tetangga" prevPage={linkPrev}/>
      <main className="donate-tbt donate-beras">
        {state.postData.type_donation === 'pickup' ? 
          <Container className="container-donate">
            <div className="txt-title mb-3">Atur Waktu Penjemputan <Image src={IcInfoDefault} className="ml-1 btn-link" onClick={handleShow} />
            </div>
            <div className="sec-setting-time btn-link" onClick={handleGetTime}>
              {state.postData.date === '' ? <span className="txt-time">Pilih tanggal dan jam</span> : 
                <span className="txt-time">{<Moment format="DD MMM">{state.postData.date}</Moment>}, {state.postData.pickup_time_id ===1 ? '08:00 - 10:00 WIB' : state.postData.pickup_time_id === 2 ? '10:00 - 12:00 WIB' : state.postData.pickup_time_id === 3 ?'12:00 - 14:00 WIB' : ''}</span>
              }
              <Image src={icArrowRight} className="ml-2 mt-1 float-right"/>
            </div>
            {!state.postData.date && 
              <p className="error-input">Tanggal dan jam wajib di pilih</p>}
            <Penyaluran
                onHide={handleClose}
                show={show}
                label="Perkiraan Penjemputan"
                description="Perkiraan penjemputan adalah waktu yang Anda harapkan untuk mengambil beras Anda oleh petugas kami. Namun harap dicatat bahwa ini hanya perkiraan dan waktu yang sebenarnya dapat sedikit berbeda."
            />
            <AturWaktu
                onHide={handleCloseAturWaktu}
                show={showAturWaktu}
                label="Atur Waktu Penjemputan"
                date={state.postData.dataDateRange}
                time={state.postData.dataGeneralTime}
                nameField="pickup_time_id"
                setDate={state.postData.date}
                setTime={state.postData.pickup_time_id}
                checkDate={handleChangeDate}
                checkTime={handleChangeTime}
                onCancel={handleCancel}
            />
          </Container> : 
          <Container className="container-donate">
            <div className="txt-title mb-3">Estimasi Pengiriman <Image src={IcInfoDefault} className="ml-1 btn-link" onClick={handleShow} />
            </div>
            <div className="sec-setting-time btn-link" onClick={handleGetTime}>
              {state.postData.date === '' ? <span className="txt-time">Pilih tanggal dan jam</span> : 
                <span className="txt-time">{<Moment format="DD MMM">{state.postData.date}</Moment>}, {state.postData.deliver_time_id ===1 ? '08:00 - 10:00 WIB' : state.postData.deliver_time_id === 2 ? '10:00 - 12:00 WIB' : state.postData.deliver_time_id === 3 ?'12:00 - 14:00 WIB' : ''}</span>
              }
              <Image src={icArrowRight} className="ml-2 mt-1 float-right"/>
            </div>
            {!state.postData.date && 
              <p className="error-input">Tanggal dan jam wajib di pilih</p>}
            <Penyaluran
                onHide={handleClose}
                show={show}
                label="Estimasi Pengiriman"
                description="Estimasi Pengiriman adalah waktu yang Anda harapkan untuk mengirim beras ke Bantu Tetangga. Anda akan dikonfirmasi untuk pengiriman pada jam tersebut."
            />
            <AturWaktu
                onHide={handleCloseAturWaktu}
                show={showAturWaktu}
                label="Atur Estimasi Pengiriman"
                date={state.postData.dataDateRange}
                time={state.postData.dataGeneralTime}
                nameField="deliver_time_id"
                setDate={state.postData.date}
                setTime={state.postData.deliver_time_id}
                checkDate={handleChangeDate}
                checkTime={handleChangeTime}
                onCancel={handleCancel}
            />

          </Container>
        }
        <div className="content-space"></div>
        <Container className="form-penyaluran">
          <form onSubmit={handleSubmit(onSubmit)} className="form-hook">
            <IntitateFirst/>
            <div className="txt-title">Lokasi Pengambilan</div>
            <p className="txt-subtitle">Di mana kami dapat mengambil bantuan beras Anda?</p> 
            <div className="form-group">
              <label className="form-label">Kecamatan</label>
              <select 
                className="form-control mb-2" 
                name="kecamatan" onChange={handleChange}
                ref={register({ required: "Kecamatan Wajib dipilih" })}
                >
                <option value="">{!state.postData.loadKecamatan ? 'Sedang mengambil data..' : (!state.postData.dataKecamatan ? '--' : 'Pilih')}</option>
                { state.postData.dataKecamatan &&
                    state.postData.dataKecamatan.map((value, key) =>
                      <option key={key} value={value.id}>{value.name}</option>
                    )
                }
              </select>
              <ErrorMessage errors={errors} name="kecamatan" as={<p className="error-input"></p>} />
            </div>
            {state.postData.type_donation === 'pickup' &&
              <span>
                <div className="form-group">
                  <label className="form-label">Kode Pos</label>
                  <input type="text"
                    name="zip_code"
                    className="form-control"
                    placeholder=""
                    ref={register({ required: "Kode pos wajib diisi" })}
                    onChange={handleChange}
                  />
                  <ErrorMessage errors={errors} name="zip_code" as={<p className="error-input"></p>} />
                  {state.postData.errorMessages.zip_code && 
                  <p className="error-input">{state.postData.errorMessages.zip_code}</p>}
                </div>
                <div className="form-group">
                  <label className="form-label">Alamat Lengkap</label>
                  <textarea
                    row="30"
                    name="address"
                    className="form-control"
                    placeholder="Contoh: Jl. Bantu Tetangga No. 1 RT/RW..."
                    ref={register({ required: "Alamat wajib diisi" })}
                    onChange={handleChange}
                  />
                  <ErrorMessage errors={errors} name="address" as={<p className="error-input"></p>} />
                  {state.postData.errorMessages.address && 
                  <p className="error-input">{state.postData.errorMessages.address}</p>}
                </div>
              </span>
            }
            {state.postData.errorMessages.area_id && 
            <p className="error-input">{state.postData.errorMessages.area_id}</p>}
            <nav>
                <Nav className="navbar">
                  {!state.postData.stepdon3 && 
                    <div className="sum-donate">
                      <div className="text-donate">
                        <p>Berat Beras</p>
                        <h5>
                          <CurrencyFormat 
                            value={state.postData.amount} 
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            suffix={' Kg'} 
                            displayType={'text'}
                          />
                        </h5>
                      </div>
                      <button type="submit" className="btn btn-donate btn-block mt-0" disabled={props.isLoading || !state.postData.amount || !state.postData.date}>
                        { (!props.isLoading) && <span>Lanjutkan</span> }
                        { props.isLoading && <Spinner animation="border" role="status"/> }
                      </button>
                    </div>
                  }
                  {state.postData.stepdon3 && 
                    <Link to="/campaign" className="btn btn-donate btn-block mt-0"><span>Ayo Berdonasi Lagi</span></Link>
                  }
                </Nav>
              </nav>
          </form>
        </Container>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
  clearDispatch: () => dispatch(clearDispatch()),
  API: (path, data, showError) => dispatch(API(path, data, showError))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AturPenyaluran));
