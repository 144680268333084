import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import { clearDispatch, API } from '../../../../config/redux/action';
import { Header } from "../../../organisms";
import { Nav, Container, Image } from "react-bootstrap";
import logo from '../../../../assets/svg/logo-bangga-color.svg';
import icAsk from '../../../../assets/svg/ic-ask.svg';
import icDownload from '../../../../assets/svg/ic-download.svg';
import icArrowRight from '../../../../assets/svg/ic-arrow-right-gray.svg';
import icCopy from '../../../../assets/svg/ic-salin.svg';
import icVerified from '../../../../assets/svg/ic-verified-campaigner.svg';
import icFoundation from '../../../../assets/svg/ic-foundation.svg';
import icCommunity from '../../../../assets/svg/ic-community.svg';
import icRefresh from '../../../../assets/svg/ic-refresh.svg';
import CurrencyFormat from 'react-currency-format';
import { toast } from 'react-toastify';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Moment from 'react-moment';
import { ExternalLink } from 'react-external-link';
import Loading from './Loading';

import './style.css'

class BuktiDonasi extends React.Component {
    state = {
        donate:false,
        checking:false
    }
    checkDonasi = async (codeDonate) => {
        const config = {
            method: 'get',
            url: '/donation/detail/'+codeDonate,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            this.setState({donate:res.data});
        }
    }
    checkStatusDonasi = async (codeDonate) => {
        this.setState({checking: true});
        const config = {
            method: 'get',
            url: '/donation/detail/'+codeDonate,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            this.setState({donate:res.data, checking: false});
        }
    }
    copyText = (str) => {
        toast.success(str+' telah di copy', {
            position: "top-center",
            hideProgressBar: true,
            autoClose:2000,
        });
    };
    componentDidMount() {
        let url = this.props.location.pathname;
        let pathArr = url.split('/');
        let codeDonate = pathArr[pathArr.length - 1];
        this.checkDonasi(codeDonate);
    }
    render() {
        const {donate} = this.state;
        const isPaid = donate?.status === "paid";
        const RootPath = process.env.REACT_APP_API_URL;
        const totals = parseInt(donate.amount) + parseInt(donate.operational_amount??0);
        let status;
        switch (donate.status) {
            case 'pending':
                status='Menunggu Verifikasi'
                break;
            case 'paid':
                status='Donasi Selesai'
                break;
            case 'expired':
                status='Dibatalkan'
                break;
            default:
                status='-'
                break;
        }
        let sectionStyle = {
            backgroundImage: `url(${donate.cover})`
        };
        return (
            <>
                <Header title="Bukti Donasi" prevPage="/cek_donasi"/>
                <main className="bg-gray donate-tbt">  
                    <Container className="no-padding txt-body-m bukti-donasi">
                        <div className="top-logo">
                            <Image src={logo}/>
                            {this.state.checking === true ?
                                <div className="txt-body-m fw-6 ct-title mb-2 mt-2">
                                   <Image src={icRefresh} alt="img-perbarui" className="mr-2 img-rotate"/> Memperbarui
                                </div> 
                                :
                                <div className="txt-body-m fw-6 ct-title mb-2 mt-2">
                                    { donate.status === "pending" ? "Menunggu Pembayaran" : "Donasi Berhasil" }
                                </div>
                            }
                            <div className="txt-caption ct-body mb-20">
                                <Moment format=" D MMM YYYY - h:mm">
                                    {donate.created_at}
                                </Moment>
                            </div>
                        </div>
                    </Container>
                    <Container className="txt-body-m bukti-donasi">
                        <div className="content-grid grid-2">
                            <div>Kode Donasi</div>
                            <div className="fw-6">{donate.code}
                                <CopyToClipboard
                                    text={donate.code}
                                    onCopy={() => (donate.code)}>
                                    <Image src={icCopy} onClick={()=> this.copyText('Kode donasi')} alt="salin-kode-donasi" className="ml-2"/>
                                </CopyToClipboard>
                            </div>
                        </div>
                        {!isPaid &&
                            <div className="float-btn-donate mt-3">
                                <button onClick={()=>this.checkStatusDonasi(donate.code)} className="btn btn-secondary">
                                    Perbarui Status
                                </button>
                            </div>
                        }
                    </Container>
                    <div class="content-space"></div>
                    <Container className="txt-body-m bukti-donasi container-donate">
                        <div className="widget-campaign">
                            <div className="campaign-card-image">
                                <div className="center-cropped" style={sectionStyle}>
                                </div>
                            </div>
                            <div className="ct-title">
                                {donate.campaign}
                                <div className="txt-campaigner">
                                    {donate?.campaigner?.name}
                                    {donate.is_verified &&
                                        <Image src={icVerified} className="ml-1" alt="ic-verified"/>
                                    }
                                    {donate.is_community && 
                                        <Image src={icCommunity} className="ml-1" alt="ic-community"/>
                                    }
                                    {donate.is_foundation && 
                                        <Image src={icFoundation} className="ml-1" alt="ic-foundation"/>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="mt-2">
                            <div className="txt-caption mb-1">Donatur</div>
                            <div className="txt-body-m fw-6 ct-title">{donate.donor_name}</div>
                            <div className="txt-caption ct-body mb-20">{donate?.contact?.value}</div>
                        </div>
                    </Container>
                    <div class="content-space"></div>   
                    <Container className="no-padding txt-body-m bukti-donasi">
                        <div className="section">
                            <div className="txt-subtitle-m fw-6">Rincian Donasi</div>
                            <div className="mt-20">
                                <div className="content-grid grid-8-2">
                                    <div>Metode Pembayaran</div>
                                    <div className="txt-body-m fw-6 ct-title">{donate.payment_method}</div>
                                </div>
                                <hr/>
                                <div className="content-grid grid-8-2">
                                    <div>Nominal Donasi</div>
                                    <div className="txt-body-m fw-6 ct-title">
                                        <CurrencyFormat 
                                            value={donate.amount}
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix={'Rp '} 
                                            displayType={'text'}
                                        />
                                    </div>
                                </div>
                                <hr/>
                                <div className="content-grid grid-8-2">
                                    <div>Kode Unik</div>
                                    <div className="txt-body-m fw-6 ct-title">
                                        <CurrencyFormat 
                                            value={donate.unique_value}
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix={'Rp '} 
                                            displayType={'text'}
                                        />
                                    </div>
                                </div>
                                <hr/>
                                <div className="content-grid grid-8-2">
                                    <div>Total Donasi</div>
                                    <div className="txt-body-m fw-6 ct-title">
                                        <CurrencyFormat 
                                            value={donate.total_donation}
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix={'Rp '} 
                                            displayType={'text'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                    <nav>
                        <Nav className="navbar bukti-donasi">
                            {isPaid ? (
                                <>
                                    <div className="group-btn">
                                        <Link to={`/campaign/${donate.campaign_slug}`} className="btn btn-primary w-100">Lihat Program</Link>
                                    </div>
                                </>
                            ):(
                                <div className="group-btn">
                                    <Link to="/faq" className="btn btn-secondary first-btn">
                                        Bantuan
                                    </Link>
                                    <Link to={`/donation/${donate?.campaign_slug}/step2`} className="btn btn-primary second-btn">Cara Pembayaran</Link>
                                </div>
                            )}
                        </Nav>
                    </nav>
                </main>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BuktiDonasi));