import React from 'react';
import { Button, Container, Form, Spinner, Image } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { API, clearDispatch, reloadUser } from '../../../../config/redux/action';
import { Header } from '../../../organisms';
import { toast } from 'react-toastify';
import IcWarningYellow from '../../../../assets/svg/ic-warning-yellow.svg';

import './style.css';
import { VerifyOTP } from '../../Authorization';

function EditEmailCampaignerForm({ state, handleChange, checkVerify, errorMessage, isLoading }) {
    const { campaigner } = state.auth
    const { handleSubmit, register, errors } = useForm()
    return (
        <Form onSubmit={handleSubmit(checkVerify)} className="form-hook">
            <Form.Group>
                <Form.Label>Email Sekarang</Form.Label>
                <Form.Control value={state.auth.campaigner.email} readOnly />
            </Form.Group>
            <Form.Group>
                <Form.Label>Email Baru</Form.Label>
                <Form.Control
                    type="text" 
                    name="contact"
                    placeholder={`Masukkan Email ${campaigner.is_foundation ? 'Lembaga' : 'Penggalang'} Baru`} 
                    ref={register({ 
                        required: `Email ${campaigner.is_foundation ? 'Lembaga' : 'Penggalang'} Baru wajib diisi`,
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: `Email ${campaigner.is_foundation ? 'Lembaga' : 'Penggalang'} tidak valid`
                        }
                    })}
                    onChange={(e) => handleChange(e)} 
                    defaultValue={state.contact}
                />
                <ErrorMessage errors={errors} name="contact" as={<p className="error-input text-left"></p>} />
                {errorMessage.contact && <p className="error-input">{errorMessage.contact}</p>}
            </Form.Group>
            <Button type="submit" className="mt-20" variant="primary" block  disabled={(isLoading ? true : false)}>
                { !isLoading && 'Verifikasi' }
                { isLoading && <Spinner animation="border" role="status"/> }
            </Button>
        </Form>
    )
}

class EditEmailCampaigner extends React.Component {
    state = {
        auth: {
            campaigner: {}
        },
        isFetching: true,
        contact: '',
        isVerify: false,
        isSavingUpdate: false,
    }
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.checkVerify = this.checkVerify.bind(this)

        this.state.auth = JSON.parse(localStorage.getItem("userData"))
    }
    handleChange(e) {
        this.setState({ contact: e.target.value })
    }
    checkVerify = async () => {
        let formData = { type: "Email", content_type: "code", contact: this.state.contact, action:'campaigner_email' };
        const config = {
            method: 'post',
            url: '/otp',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.state.auth.access_token}`
            },
            data: formData
        };
        const res = await this.props.API(config).catch(err=>err);
        if (res) {   
            this.setState({ isVerify: true })
        }
    }
    nextStep = async (resOtp) => {
        this.setState({isVerify:false});

        const confEditProfileCampaigner = {
            method: 'put',
            url: '/campaigner/email',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.state.auth.access_token}`
            },
            data: { email: this.state.contact, otp_code: resOtp.data }
        };
        
        const save = await this.props.API(confEditProfileCampaigner, false).catch(err=>err);
        if(save) {
            const auth = this.state.auth;
            auth.campaigner.email = this.state.contact;
            auth.campaigner.email_verified_at = new Date();
            localStorage.setItem('userData', JSON.stringify(auth));
            
            toast.info('Aktifasi email berhasil.', {
                position: "top-center",
                hideProgressBar: true,
                autoClose: 3000,
            });

            setTimeout(() => this.props.history.push('/profile-campaigner'), 1000);
        }
    }
    componentDidMount() {
        if(this.state.auth) {
            if(this.state.auth.campaigner) {
                this.setState({ isFetching: false })
            } else {
                this.props.history.pus('/profile')
            }
        } else {
            this.props.history.push('/login')
        }
    }
    render() {
        const { auth } = this.state
        return (
            <React.Fragment>
                {!this.state.isVerify &&
                    <>
                    <Header title="Ganti Email" prevPage="/profile-campaigner" />
                    <main>
                        {!this.state.isFetching && 
                            <Container>
                                <div className="txt-title fw-6">Ganti Email {auth.campaigner.is_foundation ? 'Lembaga' : 'Penggalang'}</div>
                                <div className="ct-body mb-4">
                                    Masukkan email {auth.campaigner.is_foundation ? 'lembaga' : 'penggalang'} Anda. 
                                    Kami akan mengirimkan kode verifikasi lewat email.
                                </div>
                                <div className="content-grid grid-2 info-form">
                                    <div><Image className="loader-img" src={IcWarningYellow}/></div>
                                    <div className="text-left ml-3">
                                        Mengganti data {auth.campaigner.is_foundation ? 'lembaga':'penggalang'} selain foto profil akan mencabut status verifikasinya selama beberapa waktu.
                                        <Link to="/faq"> Pelajari lebih lanjut</Link>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <EditEmailCampaignerForm
                                        state={this.state}
                                        checkVerify={this.checkVerify}
                                        handleChange={this.handleChange}
                                        errorMessage={this.props.errorMessage}
                                        isLoading={this.props.isLoading}
                                    />
                                </div>
                            </Container>
                        }
                    </main>
                    </>
                }
                {this.state.isVerify && 
                    <VerifyOTP
                        title={"Verifikasi Email"} 
                        prevPage="/profile-campaigner" 
                        type="email" 
                        contact={this.state.contact} 
                        action="campaigner_email"
                        nextStep={this.nextStep}
                        sendPost={({type:'Email', contact:this.state.contact, action:'campaigner_email'})}
                    />
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data)),
    reloadUser: (config, auth) => dispatch(reloadUser(config, auth))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditEmailCampaigner));