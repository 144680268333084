import React from 'react'
import {Image} from 'react-bootstrap';
import {Link, withRouter} from 'react-router-dom';
import icReport from '../../../../assets/svg/ic-report.svg';
import icOptions from '../../../../assets/svg/ic-options.svg';
import Moment from 'react-moment';

const Card =  ({ data, handleOptions, tabActive }) => {
    return (
        <div className="card-container">
            <div className="cp-card">
                <div className="content-grid grid-2">
                    <div className="txt-caption-m">
                        <Image src={icReport} className="mr-2"/>
                        <Moment format=" D MMMM YYYY">
                            {data.created_at}
                        </Moment>
                    </div>
                    <div className="text-right txt-caption-m">
                        <span className={"card-state "+(data.status === 'Aktif' ? 'state-red' : '')}>
                            {(data.status  === 'Aktif' ? 'Penanganan' : 'Selesai')}
                        </span>
                        <Image src={icOptions} className="ml-1 cursor-pointer" onClick={()=>handleOptions(data.code)}/>
                    </div>
                </div>
                <hr/>
                <Link to={'/laporan/' + data.code +'?type='+tabActive}>
                    <div className="">
                        <div className="txt-subtitle ct-title fw-6 mb-1">{data.category}</div>
                        <div className="txt-caption-m">Penggalang Dana</div>
                        <div className="txt-body-m ct-title">{data.campaigner}</div>
                    </div>
                    <hr/>
                    <div className="content-grid grid-1-9">
                        <div><Image src={data.campaign.cover} className="img-campaign" /></div>
                        <div className="txt-body-s text-left">{data.campaign.title}</div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default withRouter(Card)
