import React, {Fragment} from 'react';
import { Route } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';

//Load Component
import ScrollToTop from '../../../../config/Routes/ScrollToTop';

import { StateMachineProvider, createStore } from "little-state-machine";
import Step1 from "./Step1";
import Step2 from "./Step2";
import PaymentChannel from "./PaymentChannel";
import Step3 from "./Step3";

import "../../../../config/FormWizard/style.css";
import "./style.css";

createStore({
  postData: {
    base64: {cover:""},
    loadKota:true,
    loadKecamatan:true,
    intro:false,
    expired_at_date:"",
    publish_at_date:"",
    step1:false,
    step2:false,
    step3:false,

    resultDonation: {snapToken:false},
    stepdon1:false,
    stepdon2:false,
    stepdon3:false,
    errorMessages:{},
  }
});

const Pages = (props) => {
  return (
    <>
      <Route exact path="/donation/:campaignSlug/step1" component={Step1} />
      <Route exact path="/donation/:campaignSlug/payment_channel" component={PaymentChannel} />
      <Route exact path="/donation/:campaignSlug/step2" component={Step2} />
      <Route exact path="/donation/:campaignSlug/step3" component={Step3} />
    </>
  );
}

class Donasi extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            campaignSlug: {}
        }
    }

    componentDidMount() {
        this.props.clearDispatch();
        let url = this.props.location.pathname;
        let pathArr = url.split('/');
        let slug = pathArr[pathArr.length - 2];
        this.setState({campaignSlug:slug});
    }
    render() {
        const state = this.state;
        return(
            <Fragment>
              <StateMachineProvider store={createStore}>
                  <ScrollToTop />
                  <Pages campaignSlug={state.campaignSlug}/>
              </StateMachineProvider>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
  errorMessage: state.errorMessage,
})

const mapDispatchToProps = (dispatch) => ({
  clearDispatch: () => dispatch(clearDispatch()),
  API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Donasi));