import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../config/redux/action';
import { withRouter } from "react-router";
import Moment from 'react-moment';
import parser from 'react-html-parser';

//Load Component
import { ButtonTBT, Header } from '../../organisms';
import { Image, Tab, Tabs } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import News from '../Campaign/CampaignNews';
import LoadingNews from '../Campaign/CampaignNews/Loading';
import Loading from './Loading';

import bannerTBT1 from '../../../assets/img/tbt-landing-page-1.webp';
import bannerTBT2 from '../../../assets/img/tbt-landing-page-2.webp';
import bannerTBT3 from '../../../assets/img/tbt-landing-page-3.webp';
import bannerTBT4 from '../../../assets/img/tbt-landing-page-4.webp';
import icDonaturAnonim from '../../../assets/svg/ic-donatur-anonim.svg';

import mitraLaz from '../../../assets/img/logo-lazsakinah.webp';
import mitraIkra from '../../../assets/img/logo-ikra.webp';

// React Slick
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ExternalLink } from 'react-external-link';
import LazyLoad from 'react-lazyload';
import TimeAgo from 'timeago-react';
import * as timeago from 'timeago.js';
import id_ID from 'timeago.js/lib/lang/id_ID';

//Load Stylesheet
import './style.css'

const ListDonatur = ({ donor }) => {
    timeago.register('id_ID', id_ID);
    return (
        <div className="line-border-bottom">
            <div className="content-grid grid-2 grid-top">
                <div>
                    <div className="content-flex content-avatar">
                        <div>
                            <div className="wrapper-img">
                                <Image src={donor.avatar && donor.donor_name !== 'Anonim' ? donor.avatar : icDonaturAnonim}/>
                            </div>
                        </div>
                        <div className="text-left">
                            <div className="txt-donatur">{donor.donor_name === "Anonim" ? "Tetangga Baik" : donor.donor_name}</div>
                            <div className="text-blue">
                                <CurrencyFormat 
                                    value={donor.amount} 
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    prefix={'Rp '} 
                                    displayType={'text'}
                                    className="txt-body-m ct-primary1 fw-6"
                                />
                            </div>
                            <div className="txt-caption-m">
                                <TimeAgo datetime={donor.time} locale='id_ID' />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <div className="txt-pesan mt-1 mb-3">{donor.support ? donor.support : 'Tidak ada komentar'}</div>
        </div>
    )
}
class Home extends React.Component {
    state = {
        /** Token */
        token: null,

        /** Banners */
        banners: [],
        isFetchingBanners: false,

        results: [],
        isFetchingNews: false,

        /** Transparent Header */
        isTransparant:true,

        /** Preloader */
        preload: true,
        isFetching: false,
        donors: [],
        currentPage: 1,
        total: -1,

        /** Stats */
        stats: [],
        isFetchingStats: false,
    }
    constructor(props) {
        super(props)
        this.handleScroll = this.handleScroll.bind(this)
        /** Build filter state */
        const queryParams = new URLSearchParams(this.props.location.search)
        if (queryParams.has('category')){
            this.state.activeCategory.slug = queryParams.get('category')
        }
    }
    async apiLoadDonatur () {
        const { donors, currentPage } = this.state

        const params = `?page=${currentPage}&perPage=5`
        const config = {
            method: 'get',
            url: '/campaign/tetangga-bantu-tetangga/donations' + params,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);

        this.setState({
            currentPage: currentPage + 1,
            donors: [...donors, ...res.data.data],
            total: res.data.total,
            isFetching: false
        })
    }
    apiBanner = async () => {
        /** Fetching Banners */
        this.setState({ isFetchingBanners: true })

        const { API } = this.props
        const config = {
            method: 'get',
            url: '/public/home/banners/tbt',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            }
        };

        const res = await API(config, false).catch(err=>err);
        if(res)
            this.setState({ banners: res.data })

        this.setState({ isFetchingBanners: false })
    }
    apiStats = async () => {
        /** Fetching Stats */
        this.setState({ isFetchingStats: true })

        const { API } = this.props
        const config = {
            method: 'get',
            url: '/public/home/stats/tbt',
            headers: {
                'Content-Type': 'application/json',
            }
        };

        const res = await API(config, false).catch(err=>err);
        if(res)
            this.setState({ stats: res.data })

        this.setState({ isFetchingStats: false })
    }
    async loadNews () {
        let params = `?page=${this.state.currentPage}`

        const { API } = this.props
        const config = {
            method: 'get',
            url: '/campaign/tetangga-bantu-tetangga/news' + params,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const res = await API(config, false).catch(err=>err);
        if (res) {
            this.setState({ 
                results: [ ...this.state.results, ...res.data.data ], 
                isFetchingNews: false,
                total: res.data.total,
                currentPage: this.state.currentPage + 1
            })
        }
        // if (res) {
        //     this.setState({loadNews:true, dataNews:res.data.data, currentPage: res.data.current_page + 1});
        // }else{
        //     this.setState({loadNews:true});
        // }
    }
    // handleScroll = () => {
    //     /** Handling transparent header event */
    //     this.setState({
    //         isTransparant: document.documentElement.scrollTop <= 150
    //     });
    // }
    handleScroll() {
        const isScrolled = document.documentElement.offsetHeight - Math.ceil(window.innerHeight + document.documentElement.scrollTop);
        if (isScrolled > 100 || this.state.isFetchingNews)
            return;
        this.setState({isFetchingNews: true})
    }
    componentDidMount() {
        /** Build Authorization Token */
        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = auth && auth.access_token ? `Bearer ${auth.access_token}` : null;

        /** Setting Token State for Component use */
        this.setState({ token });
        
        /** Create Scroll Event */
        window.addEventListener('scroll', this.handleScroll); 

        /** Do Fetching All Needed Data */
        this.apiBanner(); 
        this.apiStats();
        this.setState({ isFetchingNews: true, isFetching: true });
    }
    componentDidUpdate(_, prevState) {
        const {
            preload,
            isFetchingBanners,
        } = this.state
        //this.apiLoadDonatur()
        /** Handling Preloader State */
        if (preload && !isFetchingBanners)
            this.setState({preload: false})
        
        if(this.state.isFetching !== prevState.isFetching) {
            const { isFetching, donors, total } = this.state;
            if (!isFetching || (donors.length === total && total !== -1)) {
                this.setState({isFetching: false});
                return;
            }
            this.apiLoadDonatur();
        }
        if(this.state.isFetchingNews !== prevState.isFetchingNews) {
            const { isFetchingNews, results, total } = this.state;
            if (isFetchingNews === false || (results.length === total && total !== -1)) {
                this.setState({isFetchingNews: false});
                return;
            }
            this.loadNews();
        }
    }
    componentWillUnmount() {
        /** Remove Scroll Event when Component is Unmounted */
        window.removeEventListener('scroll', this.handleScroll); 
        /** Fix can't perform unmounted react state update */
        this.setState = () => {};
    }
    render() {
        const { 
            preload,
            banners, 
            stats,
            donors
        } = this.state
        
        return (
            <Fragment>
                <div className="header-landing-tbt">
                    <Header title="Tetangga Bantu Tetangga" prevPage="/" />
                </div>
                {preload && <Loading />}
                {!preload &&
                
                    <main className="main-landing-tbt">
                        <div className="section-banner">
                            <Image src={bannerTBT1} alt="banner-tetangga-bantu-tetangga-1" />
                            <Image src={bannerTBT2} alt="banner-tetangga-bantu-tetangga-2" />
                        </div>

                        <div className="section-tbt-stats">
                            <div className="stats-first">
                                <div className="col-6">
                                    <h4>
                                        <CurrencyFormat 
                                            value={stats.donation_rice} 
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            suffix={' Kg'} 
                                            displayType={'text'}
                                            className="str-limit str-limit-1"
                                        />
                                    </h4>
                                    <p>Beras Tersalur</p>
                                </div>
                                <div className="col-6">
                                    <h4>
                                        <CurrencyFormat 
                                            value={stats.receiver_final} 
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            suffix={''} 
                                            displayType={'text'}
                                            className="str-limit str-limit-1"
                                        />
                                    </h4>
                                    <p>Penerima Beras</p>
                                </div>
                            </div>
                            <div className="stats-second">
                                <div>
                                    <h4>
                                        <CurrencyFormat 
                                            value={stats.donation_final} 
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix={'Rp '} 
                                            displayType={'text'}
                                            className="str-limit str-limit-1"
                                        />
                                    </h4>
                                    <p>Donasi Terhimpun</p>
                                </div>
                            </div>
                        </div>

                        <div className="banner-home">
                            <h6>Aktivitas Tetangga Baik</h6>
                            <Slider autoplay={true} arrows={false} dots={true} infinite={true} speed={300} autoplaySpeed={5000}>
                                {banners.map((banner, i) => {
                                    return <ExternalLink key={i} href={banner.url}><LazyLoad><Image key={banner.slug} src={banner.image} /></LazyLoad></ExternalLink>
                                })}
                            </Slider>
                        </div>
                        
                        <div className="content-banner">
                            <Image src={bannerTBT3} alt="banner-tetangga-bantu-tetangga-3" />
                            <Image src={bannerTBT4} alt="banner-tetangga-bantu-tetangga-4" />
                        </div>

                        <div className="section-mitra-tbt">
                            <h5>Mitra Kami</h5>
                            <div className="list-logo">
                                <Image src={mitraIkra} alt="mitra-tetangga-bantu-tetangga-1" className="img-fluid" />
                                <Image src={mitraLaz} alt="mitra-tetangga-bantu-tetangga-2" className="img-fluid" />
                            </div>
                        </div>
                        <div className="container">
                            <Tabs
                                defaultActiveKey="berita"
                                className="tabs mb-3"
                                justify
                                >
                                <Tab eventKey="berita" title="Berita" className="mb-5">
                                    <div className="section-berita">
                                        <div 
                                            className={this.state.results?.length > 0 ? 'content-less' : ''}
                                        >
                                            { this.state.results &&
                                                <Fragment>
                                                    {this.state.results?.length > 0 ? (
                                                        this.state.results.map(news => {
                                                            return (  
                                                                <News 
                                                                    key={news.slug}
                                                                    data={news}
                                                                    handleDeleteNews={this.handleDeleteNews}
                                                                    isProcessing={(this.state.isProcessing === news.slug) ? true : false}
                                                                    isCampaigner={false}
                                                                />
                                                            )
                                                            
                                                        })
                                                    ):(
                                                        <div className="text-center text-secondary mt-3">
                                                            Tidak ada kabar terbaru
                                                        </div>
                                                    )}

                                                </Fragment>
                                            }

                                            { this.state.total === this.state.currentPage &&
                                                <LoadingNews/>
                                            }
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="riwayat" title="Riwayat" className="mb-5">
                                    {donors.map((donor, i) => (
                                        <ListDonatur donor={donor} key={donor.name + '_' + donor.time} />
                                    ))}
                                </Tab>
                                
                            </Tabs>
                        </div>
                    </main>
                }
                <ButtonTBT/>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));