import React from 'react'
const PreviewLoading =  (props) => {
    return (
        <div className="campaign-detail">
            <div className="img-cover">
                <div><div className="gradient cover-loading"></div></div>
            </div>
            <div className="section">
                <div className="gradient text-loading text-loading-100 height-20"></div>
                <div className="content-grid grid-2">
                    <div>
                        <div className="gradient text-loading text-loading-50 height-10"></div>
                        <div className="gradient text-loading text-loading-60 height-20"></div>
                    </div>
                    <div>
                        <div className="gradient text-loading text-loading-60 height-10 float-right"></div>
                        <div className="gradient text-loading text-loading-90 height-20 float-right clear-both"></div>
                    </div>
                </div>
                <div className="gradient text-loading text-loading-100 height-10"></div>
                <div className="content-grid grid-2 mt-0">
                    <div>
                        <div className="gradient text-loading text-loading-30 height-10"></div>
                        <div className="gradient text-loading text-loading-20 height-20"></div>
                    </div>
                    <div>
                        <div className="gradient text-loading text-loading-30 height-10 float-right"></div>
                        <div className="gradient text-loading text-loading-20 height-20 float-right clear-both"></div>
                    </div>
                </div>
                <div className="content-grid grid-3-7 mt-0">
                    <div className="gradient text-loading text-loading-90 height-50"></div>
                    <div className="gradient text-loading text-loading-100 height-50 float-right clear-both"></div>
                </div>
            </div>
            <div className="content-space"></div>  
            <div className="section">
                <div className="gradient text-loading text-loading-40 height-20"></div>
                <div className="content-grid grid-2-8 mt-0">
                    <div>
                        <div className="gradient text-loading text-loading-90 height-50"></div>
                    </div>
                    <div>
                        <div className="gradient text-loading text-loading-30 height-10"></div>
                        <div className="gradient text-loading text-loading-60 height-10"></div>
                    </div>
                </div>
            </div>
            <div className="content-space"></div>  
            <div className="section">
                <div className="gradient text-loading text-loading-40 height-20"></div>
                <div className="gradient text-loading text-loading-100 height-5"></div>
                <div className="gradient text-loading text-loading-100 height-5 mt-2"></div>
                <div className="gradient text-loading text-loading-90 height-5 mt-2"></div>
                <div className="gradient text-loading text-loading-80 height-5 mt-2"></div>
                <div className="gradient text-loading text-loading-80 height-5 mt-2"></div>
                <div className="gradient text-loading text-loading-90 height-5 mt-2"></div>
            </div>
            <div className="content-space"></div>  
            <div className="section">
                <div className="gradient text-loading text-loading-40 height-20"></div>
                <div className="gradient text-loading text-loading-100 height-5"></div>
                <div className="gradient text-loading text-loading-100 height-5 mt-2"></div>
                <div className="gradient text-loading text-loading-90 height-5 mt-2"></div>
                <div className="gradient text-loading text-loading-80 height-5 mt-2"></div>
                <div className="gradient text-loading text-loading-80 height-5 mt-2"></div>
                <div className="gradient text-loading text-loading-90 height-5 mt-2"></div>
            </div>
            <div className="content-space"></div>  
        </div>
    )
}

export default PreviewLoading
