import React from 'react'
export default function Loading () {
    return (
        <div className="campaign-detail">
            <div className="img-cover">
                <div><div className="gradient cover-loading"></div></div>
            </div>
            <div className="content-space"></div>

            <div className="section">
                <div className="content-grid grid-2">
                    <div className="gradient text-loading text-loading-60"></div>
                    <div>
                        <div className="gradient text-loading text-loading-50"></div>
                    </div>
                </div>
                <div className="content-grid grid-2">
                    <div>
                        <div className="gradient text-loading text-loading-50"></div>
                        <div className="gradient text-loading text-loading-60"></div>
                    </div>
                    <div>
                        <div className="gradient text-loading text-loading-40"></div>
                        <div className="gradient text-loading text-loading-50"></div>
                    </div>
                </div>
                <div className="content-grid grid-2">
                    <div className="gradient text-loading text-loading-40"></div>
                    <div className="gradient text-loading text-loading-60"></div>
                </div>
                <div className="content-grid grid-2">
                    <div>
                        <div className="gradient text-loading text-loading-50"></div>
                        <div className="gradient text-loading text-loading-60"></div>
                    </div>
                    <div>
                        <div className="gradient text-loading text-loading-50"></div>
                        <div className="gradient text-loading text-loading-60"></div>
                    </div>
                </div>
            </div>
            <div className="content-space"></div>

            <div className="section">
                <div className="gradient text-loading text-loading-40"></div>
                <div className="campaign-content">
                    <div className="gradient text-loading text-loading-80"></div>
                    <div className="gradient text-loading text-loading-70"></div>
                    <div className="gradient text-loading text-loading-60"></div>
                    <div className="gradient text-loading text-loading-80"></div>
                    <div className="gradient text-loading text-loading-70"></div>
                </div>
                <div className="text-center">
                    <button className="read-more">
                    </button>
                </div>
            </div>
        </div>
    )
}