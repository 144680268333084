import React, {useEffect} from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { useHistory, Link } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";
import { Nav, Image, Spinner, Container, Form } from "react-bootstrap";
import { Header } from '../../../organisms';

import "react-datepicker/dist/react-datepicker.css";
import Script from "react-load-script";
import midtrans from "../../../../config/Midtrans";

import Suggest from './Suggest'
import CurrencyFormat from 'react-currency-format';

import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';
import postData from './postData';
import icVerified from '../../../../assets/svg/ic-verified-campaigner.svg';
import {FbPixel} from '../../../../config/Tracker/FbPixel';
import { removeSymbol, validateEmail, validatePhone } from "../../../../utils";
import avatar from "../../../../assets/img/avatar/no-img.png";
import icArrowRight from "../../../../assets/svg/ic-arr-right.svg";

const IntitateFirst = () => {
  
  const { state, action } = useStateMachine(updateAction);
  const detailCampaign = JSON.parse(localStorage.getItem("detailCampaign"));
  const nextLink = "/donation/"+detailCampaign.slug;
  const { push } = useHistory();
  useEffect(()=>{
    FbPixel('AddToCart');
  },[])
  useEffect(() => {
    let data = {};
    if(!detailCampaign.is_active){
      push("/campaign/"+detailCampaign.slug);
    }
    if(state.postData.stepdon2 ){
      data = postData;
      data['stepdon2'] = false;
      action(data);
      push(nextLink+"/step1");
    }
  },[action, nextLink, push, state, detailCampaign])
  return null;
}

const Step1 = (props ) => {
  const detailCampaign = JSON.parse(localStorage.getItem("detailCampaign"));
  const linkPrev = "/campaign/"+detailCampaign.slug;
  const nextLink = "/donation/"+detailCampaign.slug;
  const auth = JSON.parse(localStorage.getItem("userData"));
  const { state, action } = useStateMachine(updateAction);
  const { handleSubmit, errors, register, clearErrors, setError, setValue } =
    useForm({
      defaultValues: state.postData,
    });
  
  const { push } = useHistory();
  const onSubmit = (data) => {
    if (state.postData.typeof_payment_type !== "transfer") {
      FbPixel("InitiateCheckout");
    }
    submitForm();
  };

  const submitForm = async () => {
    let formData = {
      campaign_slug: detailCampaign.slug,
      amount: state.postData.amount,
      is_anonim: state.postData.is_anonim ? state.postData.is_anonim : false,
      support: state.postData.support,
      payment_type: state.postData.payment_type,
      donor_name: auth ? auth.name : state.postData.donor_name,
      contact: auth
        ? auth.email
          ? auth.email
          : auth.phone
        : state.postData.contact,
    };
    localStorage.setItem('nama-lengkap-donasi', auth ? auth.name : state.postData.donor_name);
    localStorage.setItem('contact-donasi', auth ? auth.email ? auth.email : auth.phone : state.postData.contact);
    localStorage.setItem('payment-icon-donasi', state.postData.icon_payment_type);
    localStorage.setItem('payment-type-donasi', state.postData.payment_type);
    localStorage.setItem('payment-title-donasi', state.postData.title_payment_type);
    localStorage.setItem('typeof_payment_type-donasi', state.postData.typeof_payment_type);
    window.sessionStorage.setItem('paymentMethod', state.postData.title_payment_type);
    if (localStorage.getItem("cacheParamUTM")) {
      const paramUTM = JSON.parse(localStorage.getItem("cacheParamUTM"));

      Object.keys(paramUTM).forEach(function (field) {
        formData[field] = paramUTM[field];
      });
    }

    let config = {
      method: "post",
      url: "/public/donation",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    if (auth) {
      const token = `Bearer ${auth.access_token}`;
      config["headers"]["Authorization"] = token;
    }

    const res = await props.API(config).catch((err) => err);
    if (res) {
      let data = {};
      data["resultDonation"] = res.data;

      switch (state.postData.typeof_payment_type) {
        case "midtrans":
          action(data);
          // push(nextLink+"/step2");
          break;

        case "transfer":
          data["stepdon2"] = true;
          action(data);
          setTimeout(() => {
            window.location.assign(nextLink + "/step2");
          }, 1000);
          break;

        case "faspay":
          data["stepdon2"] = true;
          action(data);
          window.location.href = res.data.faspay_redirect_url;
          break;

        default:
          break;
      }
    } else {
      let data = {};
      data["checkMessage"] = true;
      action(data);
    }
  };

  const choosePayment = () => {
    let data = state.postData;
    loadPaymentTypes(data);
  }

  const nextPageManual = (data) => {
    loadPaymentTypesManual(data);
  }

  const handleSuggestion = (value) => {
    if(props.errorMessage['amount']){
      let fieldChanges = {};
      fieldChanges = props.errorMessage;
      fieldChanges['amount'] = '';
      props.clearDispatch(fieldChanges);
    }
    let data = state.postData;
    // if(state.postData.amount < 1){
      data['amount'] = value;
      data['stepdon1'] = true;
      action(data);
    // }
    //nextPage(data);
  }

  const handleDonate = (value) => {
    if(props.errorMessage['amount']){
      let fieldChanges = {};
      fieldChanges = props.errorMessage;
      fieldChanges['amount'] = '';
      props.clearDispatch(fieldChanges);
    }
    let data = state.postData;
    // if(state.postData.amount < 1){
      data['amount'] = value;
      data['stepdon1'] = true;
      action(data);
    // }
    nextPageManual(data);
  }
  
  const handleCurrency = (field, value) => {
    let data = {};
    data[field] = value
    action(data);

    if(props.errorMessage[field]){
      let fieldChanges = {};
      fieldChanges = props.errorMessage;
      fieldChanges[field] = '';
      props.clearDispatch(fieldChanges);
    }
    handleDonate(value);
  }

  const handleChange = (e) => {
    let val = e.target.value;
    let field = e.target.name;
    let data = {};
    data[field] = val;
    data["stepdon1"] = false;
    if (e.target.type === "checkbox") {
      if (!e.target.checked) {
        data["is_anonim"] = false;
      } else {
        data["is_anonim"] = true;
      }
    }
    if (field === "payment_type") {
      data["typeof_payment_type"] =
        e.target[e.target.selectedIndex].getAttribute("data-type");
    }
    if (field === "contact") {
      if (val.includes("@") || val.includes(".")) {
        const isEmailValid = validateEmail(val);
        if (isEmailValid) {
          clearErrors("contact");
        } else {
          setError("contact", {
            type: "manual",
            message: "Isi dengan nomor Email yang benar",
            shouldFocus: true,
          });
        }
      } else {
        const valuePhone = removeSymbol(val);
        setValue("contact", valuePhone);
        data.contact = valuePhone;
        const isPhoneValid = validatePhone(valuePhone);
        if (isPhoneValid && val && val !== "12345678") {
          clearErrors("contact");
        } else if (val === "") {
          clearErrors("contact");
        } else {
          setError("contact", {
            type: "manual",
            message: "Isi dengan nomor HP yang benar",
            shouldFocus: true,
          });
        }
      }
    }

    console.log({ data });

    action(data);
  };

  const loadPaymentTypes = async (data) => {
    data['loadPaymentTypes'] = false;
    action(data);
    const config = {
        method: 'get',
        url: '/public/payment-types/group',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    const res = await props.API(config).catch(err=>err);
    if (res) {
      data['loadPaymentTypes'] = true;
      data['dataPaymentTypes'] = res.data;
      action(data);
      checkValues(data);
    }
  }

  const loadPaymentTypesManual = async (data) => {
    data['loadPaymentTypes'] = false;
    action(data);
    const config = {
        method: 'get',
        url: '/public/payment-types/group',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    const res = await props.API(config).catch(err=>err);
    if (res) {
      data['loadPaymentTypes'] = true;
      data['dataPaymentTypes'] = res.data;
      action(data);
      checkValuesManual(data);
    }
  }

  const checkValues = async (data) => {
    let formData = {
      amount: data.amount,
      just_validate:true
    };
    
    const config = {
        method: 'post',
        url: '/public/donation',
        headers: {
            'Content-Type': 'application/json'
        },
        data: formData
    };

    const res = await props.API(config, true, true).catch(err=>err);
    if (res) {
      data['stepdon1'] = true;
      data['resultDonation'] = {snapToken:false};
      if (state.postData.payment_type) {
        push(nextLink+"/step1");
      } else {
        push(nextLink+"/payment_channel");
      }
    }else{
      let data={};
      data['checkMessage'] = true;
      action(data);

    }
  }

  const checkValuesManual = async (data) => {
    let formData = {
      amount: data.amount,
      just_validate:true
    };
    
    const config = {
        method: 'post',
        url: '/public/donation',
        headers: {
            'Content-Type': 'application/json'
        },
        data: formData
    };

    const res = await props.API(config, true, true).catch(err=>err);
    if (res) {
      data['stepdon1'] = true;
      data['resultDonation'] = {snapToken:false};
    }else{
      let data={};
      data['checkMessage'] = true;
      action(data);
    }
  }
  
  let sectionStyle = {
      backgroundImage: `url(${detailCampaign.cover})`
  };
  const checkDonasi = async (codeDonate) => {
    const config = {
      method: "get",
      url: "/donation/detail/" + codeDonate,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await props.API(config, false).catch((err) => err);
    if (res) {
      if (res.data.status === "paid") {
        push("/donation/result/success?code=" + codeDonate);
      } else {
        push("/bukti-donasi/" + codeDonate + "?prevPage=campaign");
      }
    }
  };
  const handleScriptLoad = () => {
    const snapToken = state.postData.resultDonation.snapToken;
    const code = state.postData.resultDonation.code;
    let data = {};
    data["resultDonation"] = { snapToken: false };
    data["stepdon2"] = true;
    action(data);
    window.snap.pay(snapToken, {
      onSuccess: function (result) {
        checkDonasi(code);
      },
      onPending: function (result) {
        checkDonasi(code);
      },
      onError: function (result) {
        data["stepdon2"] = false;
        action(data);
        checkDonasi(code);
      },
      onClose: function () {
        data["stepdon2"] = false;
        action(data);
      },
      gopayMode: state.postData.title_payment_type === "QRIS" ? "qr" : "auto",
    });
  };
  return (
    <>
      <Header title={detailCampaign.title} prevPage={linkPrev}/>
      <main className="donate-main donate-tbt">
        <div className="container-donate">
          <form onSubmit={handleSubmit(onSubmit)} className="form-hook">
            <div className="container">
              <div className="txt-subtitle-m fw-6 ct-title mt-20 mb-10">Masukkan Nominal Donasi</div>
              <div className="content-grid wrap-input-amount txt-title-m">
                <div>Rp</div>
                <div>
                  <CurrencyFormat 
                    value={state.postData.amount} 
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    onValueChange={values => {
                      handleCurrency('amount', values.value);
                    }}
                    placeholder="Masukkan Nominal"
                  />
                </div>
              </div>
              <div>
                <div>
                  <input
                  name="amount"
                  value={state.postData.amount}
                  className="hide"
                  ref={register({ required: "Nominal Donasi wajib diisi" })}
                  />
                  <ErrorMessage errors={errors} name="amount" as={<p className="error-input"></p>} />
                </div>
                {props.errorMessage.amount && 
                <p className="error-input">{props.errorMessage.amount}</p>}
              </div>
              <div className="box-nominal-donasi mt-2">
                <div className="pr-1">
                  <Suggest nominal={15000} desc="" handleSuggestion={handleSuggestion} amount={state.postData.amount}/>
                </div>
                <div className="pr-1">
                  <Suggest nominal={50000} desc="" handleSuggestion={handleSuggestion} amount={state.postData.amount}/>
                </div>
                <div>
                  <Suggest nominal={250000} desc="" handleSuggestion={handleSuggestion} amount={state.postData.amount}/>
                </div>
              </div>
              {!state.postData.payment_type ? (
                <div className="row">
                  <div className="col-12">
                    <div className="box-payment" onClick={choosePayment}>
                      <Image className="img-fluid" src="/assets/img/ic-payment-method.svg" />
                      <div className="ct-body">Metode Pembayaran</div>
                      <div>
                        <Image className="m-auto arr-right" src={icArrowRight} />
                      </div>
                    </div>
                    {!state.postData.payment_type &&
                      <p className="error-input">
                        Pilih metode pembayaran terlebih dahulu
                      </p>
                    }
                  </div>
                </div>
              ) : (
                <div className="content-grid grid-1-8-1 mt-1">
                  <div className="box-channel">
                    <Image
                      src={state.postData.icon_payment_type}
                      className="icon-channel"
                    />
                  </div>
                  <div className="txt-body ct-title">
                    {state.postData.title_payment_type}
                  </div>
                  <Link
                    to={
                      `/donation/${detailCampaign.slug}/payment_channel`
                    }
                    className="btn-ganti"
                  >
                    Ganti
                  </Link>
                </div>
              )}
            </div>
            <div className="content-space"></div>
            <div className="container">
              {auth && (
                <div className="suggest-donate content-grid wrap-auth-login">
                  <div className="wrapper-img">
                    <Image src={!auth.photo ? avatar : auth.photo} width="65" />
                  </div>
                  <div className="text-left">
                    <h6 className="mb-0">{auth.name}</h6>
                    <div>{auth.email}</div>
                  </div>
                </div>
              )}
              {!auth && (
                <div>
                  <div className="txt-body-m mb-20">
                    <Link to={"/login?donation=" + detailCampaign.slug}>
                      Masuk
                    </Link>{" "}
                    atau lengkapi data dibawah ini
                  </div>
                  <div className="form-group">
                    <input
                      name="donor_name"
                      className="form-control"
                      placeholder="Nama Lengkap"
                      ref={register({ required: "Isi terlebih dulu nama Anda" })}
                      onChange={handleChange}
                      autoFocus={true}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="donor_name"
                      as={<p className="error-input"></p>}
                    />
                    {state.postData.errorMessages.name && (
                      <p className="error-input">
                        {state.postData.errorMessages.donor_name}
                      </p>
                    )}
                  </div>

                  <div className="form-group">
                    <input
                      name="contact"
                      className="form-control"
                      placeholder="Nomor HP atau Email"
                      ref={register({ required: "Isi terlebih dahulu Email atau nomor HP Anda" })}
                      onChange={handleChange}
                      autoFocus={true}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="contact"
                      as={<p className="error-input"></p>}
                    />
                    {state.postData.errorMessages.contact && (
                      <p className="error-input">
                        {state.postData.errorMessages.contact}
                      </p>
                    )}
                  </div>
                </div>
              )}
              <div className="form-group d-flex">
                <label htmlFor="styled-checkbox-1" className="txt-checkbox">
                  Sembunyikan nama saya (donasi dari Tetangga baik)
                </label>
                <Form.Check id="styled-checkbox-1" ref={register()} onChange={handleChange} checked={state.postData.is_anonim} type="switch" autoFocus={true} label="" />
              </div>
              <div className="form-group">
                <label className="form-label">
                  Tulis Doa atau Dukungan (Optional)
                </label>
                <textarea
                  placeholder="Tulis do’a dan dukungan kamu disini, agar semakin banyak yang mengaminkan"
                  name="support"
                  className="form-control"
                  onChange={handleChange}
                  ref={register({
                    maxLength: {
                      value: 140,
                      message: "Tidak boleh lebih dari 140 karakter",
                    },
                  })}
                  rows={5}
                />
                <ErrorMessage
                  errors={errors}
                  name="support"
                  as={<p className="error-input"></p>}
                />
              </div>
              <nav>
                <Nav className="navbar">
                  <button type="submit" className="btn btn-donate btn-block mt-0" disabled={props.isLoading || !state.postData.amount || !state.postData.payment_type}>
                    { (!props.isLoading) && <span>Lanjutkan Pembayaran</span> }
                    { props.isLoading && <Spinner animation="border" role="status"/> }
                  </button>
                </Nav>
              </nav>
            </div>
          </form>
          {state.postData.resultDonation.snapToken && (
            <Script
              url={midtrans.url}
              attributes={{ "data-client-key": midtrans.clientKey }}
              // onCreate={this.handleScriptCreate.bind(this)}
              // onError={this.handleScriptError.bind(this)}
              onLoad={handleScriptLoad.bind(this)}
            />
          )}
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
  clearDispatch: () => dispatch(clearDispatch()),
  API: (path, data, showError) => dispatch(API(path, data, showError))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step1));
