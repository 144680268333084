import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
const config = {
    apiKey: "AIzaSyAVXKCa_vuxMjBpP4Sj-ovbWmUppyftE9I",
    authDomain: "cari-pahala-2396d.firebaseapp.com",
    projectId: "cari-pahala-2396d",
    storageBucket: "cari-pahala-2396d.appspot.com",
    messagingSenderId: "85539423474",
    appId: "1:85539423474:web:f7c369a96c1099c419bc6b",
    measurementId: "G-8XTNBS9EY9"
};
firebase.initializeApp(config);

export default firebase;