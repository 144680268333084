import React from 'react'
import {Image} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import Moment from 'react-moment';

import icOptions from '../../../../assets/svg/ic-options.svg';
import icToaYellow from '../../../../assets/svg/ic-toa-yellow.svg';
import { formatCurrency } from '../../../../utils';

const Card =  (props) => {
    const handleCardClick = (e) => {
        e.stopPropagation()
        props.history.push("/detail-campaign/"+props.data.slug)
    }
    return (
        <div className="card-container">
            <div className="cp-card">
                <div className="content-grid grid-2">
                    <div className="txt-caption-m">
                        <Image src={icToaYellow} />
                        <Moment format=" D MMMM YYYY">
                            {props.data.created_at}
                        </Moment>
                    </div>
                    <div className="text-right txt-caption-m">
                        <span className={"card-state "+(props.data.status === 'Publish' ? 'state-green' : (props.data.status === 'Blokir' ? 'state-red' : ''))}>{props.data.status}
                        </span>
                        <Image src={icOptions} className="ml-1 cursor-pointer" onClick={()=>props.handleOptions(props.data)}/>
                    </div>
                </div>
                <hr/>

                <div onClick={(e) => handleCardClick(e)}>
                    <div className="content-grid grid-1-9">
                        <div><Image src={props.data.image} className="img-campaign" /></div>
                        <div className="txt-body-m ct-title fw-6 text-left">{props.data.title}</div>
                    </div>
                    <div className="progress">
                        <span className="progress-bar" style={{"width":props.percentage+'%', backgroundColor: '#D68605'}}></span>
                    </div>

                    <div className="content-grid grid-2">
                        <div>
                            <div className="txt-caption-m">Terkumpul {props.percentage}%</div>
                            <div className="txt-body-m ct-title fw-6">
                                <CurrencyFormat 
                                    value={props.data.funds} 
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    prefix={'Rp '} 
                                    displayType={'text'}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="txt-caption-m">Sisa Hari</div>
                            <div className="txt-body-m ct-title fw-6">
                                {(props.data.day_left ? formatCurrency(props.data.day_left) : '-')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Card)
