import React from "react";
import { Container, Image } from "react-bootstrap";
import { connect } from 'react-redux';

import { NavMenu, NewsCard } from "../../../organisms";
import Loading from "./Loading";
import { clearDispatch, API } from '../../../../config/redux/action';

import './style.css'

import HeaderClose from "../../../organisms/HeaderClose";

class NewsCampaigner extends React.Component {
    state = {
        token: null,
        results: [],
        isFetchingNews: false,
        currentPage: 1,
        total: -1,

        favNews:{
            results:[],
            isFetching:false,
            currentPage:1,
            total:-1
        },

        /** Blogs */
        blogs: [],
        isFetchingBlogs: false,
    }
    constructor(props) {
        super(props)
        this.handleScroll = this.handleScroll.bind(this)
    }
    async apiResult () {
        let pathRaw = window.location.pathname.split('/')
        let campaignerSlug = pathRaw[2]
        let params = `?page=${this.state.currentPage}&per_page=5&campaigner_slug=${campaignerSlug}`

        const { API } = this.props
        const config = {
            method: 'get',
            url: '/news' + params,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            }
        };
        const res = await API(config, false).catch(err=>err)
        if (res) {
            this.setState({ 
                results: [ ...this.state.results, ...res.data.data ], 
                isFetchingNews: false,
                total: res.data.total,
                currentPage: this.state.currentPage + 1
            })
        }
    }

    async apiFavNews () {
        let pathRaw = window.location.pathname.split('/')
        let campaignerSlug = pathRaw[2]
        let params = `?page=${this.state.currentPage}&per_page=5&campaigner_slug=${campaignerSlug}`

        const { API } = this.props
        const config = {
            method: 'get',
            url: '/news' + params,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            }
        };
        const res = await API(config, false).catch(err=>err)
        if (res) {
            this.setState({ 
                favNews:{
                    results: [ ...this.state.results, ...res.data.data ], 
                    isFetching: false,
                    total: res.data.total,
                    currentPage: this.state.currentPage + 1
                }
                
            })
        }
    }
    handleScroll() {
        const isScrolled = document.documentElement.offsetHeight - Math.ceil(window.innerHeight + document.documentElement.scrollTop);
        if (isScrolled > 100 || this.state.isFetchingNews)
            return;
        this.setState({isFetchingNews: true})
    }
    componentDidMount() {
        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = auth && auth.access_token ? `Bearer ${auth.access_token}` : null;

        window.addEventListener('scroll', this.handleScroll);
        this.setState({ isFetchingNews: true, token })
        this.apiFavNews();
    }
    componentDidUpdate(_, prevState) {
        if(this.state.isFetchingNews !== prevState.isFetchingNews) {
            const { isFetchingNews, results, total } = this.state;
            if (isFetchingNews === false || (results.length === total && total !== -1)) {
                this.setState({isFetchingNews: false});
                return;
            }
            this.apiResult();
        }
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll); 
    }
    render() {
        const {
        } = this.state
        return (
            <React.Fragment>
                <HeaderClose title="Berita Penggalang" prevPage="/search-news?prevPage=news"/>
                <main>
                    <Container className="news news-campaigner">
                        <div className="txt-title-s fw-6">Berita</div>
                        <div className="txt-body">Menampilkan berita dari penggalang <span className="fw-6">“{this?.state?.results[0]?.campaigner?.name}”</span></div>
                        <div className="news-result">
                            {this.state.results.map(news => {
                                return <NewsCard key={news.slug} news={news} />
                            })}
                            <div className="text-center">
                                {this.state.isFetchingNews && <Loading/>}
                            </div>
                        </div>
                    </Container>
                </main>
                <NavMenu />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(NewsCampaigner);
