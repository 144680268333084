import React from 'react';
import { Container, Image } from 'react-bootstrap';
import { Header } from '../../organisms';
import icIgWhite from "../../../assets/svg/ic-ig-new.svg";
import icFbWhite from "../../../assets/svg/ic-fb-new.svg";
import icTiktok from "../../../assets/svg/ic-tiktok-new.svg";
import icYtubeWhite from "../../../assets/svg/ic-youtube-new.svg";
import icInWhite from "../../../assets/svg/ic-linkedin-new.svg";
import logoFooter from "../../../assets/svg/new-logo.svg";
import './style.css'
import { ExternalLink } from 'react-external-link';
import YouTube from 'react-youtube';
import { Link } from 'react-router-dom';

class TentangKami extends React.Component {
    render() {
      const opts = {
        height: '230',
        width: '100%',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
        },
      };
  
      return (
        <>
            <Header title="Tentang Bantu Tetangga" prevPage="/profile"/>
            <main className="pb-3">
                <Container className="no-padding about">
                    <div className="section bg-primary3 txt-body-m ct-title">
                      <div className="txt-subtitle fw-6 mt-10">Berbagi Kebajikan Lebih Mudah Bersama Bantu Tetangga</div>
                      <div className="txt-body-m mt-10">
                            <div>Berdiri sejak 2020, bantutetangga.com hadir sebagai media untuk membantu orang terdekat di sekitar kita yang kami analogikan dengan kata "Tetangga" dengan cara yang sangat praktis. Terima kasih kepada semua tetangga baik yang telah mewujudkan terciptanya kebaikan bagi lebih dari 4ribu orang-orang yang membutuhkan. </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="txt-subtitle fw-6">Yayasan Bantu Tetangga Mulia</div>
                        <div className="txt-body-m mt-10">
                            <div>Badan hukum yayasan yang menjalankan fungsi sebagai operator seluruh kegiatan galang dana dan donasi di platform bantutetangga.com, mulai dari pendaftaran, verifikasi galang dana, hingga pelaporan kepada donatur. Bantu Tetangga sudah memiliki izin dari Kemenkumham RI Nomor AHU-0029173.AH.01.12.Tahun 2020.</div>
                        </div>
                    </div>
                    <div className="section">
                      <div className="txt-subtitle fw-6">Terima kasih telah menjadi tetangga baik buat mereka</div>
                      <div className="txt-body-m mt-10">
                          <div>Terima kasih karena telah bersama-sama ringankan tetangga kita yang membutuhkan melalui bantutetangga.com. Banyak orang membutuhkan di luar sana yang akan merasakan efek baik dari donasi yang kamu bagikan hari ini. Terima kasih telah menjadi tetangga baik buat mereka.</div>
                      </div>
                    </div>
                    <div className="section">
                      <div className="txt-subtitle fw-6">Siapa tim dibalik Bantu Tetangga?</div>
                      <div className="txt-body-m mt-10">
                          <div>Kami menyebut orang-orang yang berkarya di Bantu Tetangga dengan Pak RT dan para warga, karena kami ingin kebaikan yang dilakukan terasa lebih dekat seperti tetangga yang saling gotong royong demi kebaikan bersama di lingkungan sekitar. Kami akan terus berinovasi untuk memudahkan para #tetanggabaik bergotong-royong dalam kebaikan.</div>
                      </div>
                    </div>
                    <div className="section">
                      <div className="txt-subtitle fw-6">Layanan Kami</div>
                      <div className="txt-body-m mt-10">
                          <article className="mb-2">
                            <li>Amanah</li>
                            <div>Donasimu disalurkan secara transparan sesuai peruntukan.</div>
                          </article>
                          <article className="mb-2">
                            <li>Kemudahan Donasi</li>
                            <div>Tahapan donasi sangat mudah dilakukan bahkan untuk pengguna baru.</div>
                          </article>
                          <article className="mb-2">
                            <li>Metode Donasi Bervariasi</li>
                            <div>Kamu bisa berdonasi menggunakan berbagai macam metode pembayaran mulai dari bank transfer, virtual account, e-wallet, hingga kartu kredit.</div>
                          </article>
                          <article className="mb-2">
                            <li>Akses kapan saja</li>
                            <div>Kamu bisa berdonasi kapan saja tanpa memandang waktu</div>
                          </article>
                          <article>
                            <li>Sinergi</li>
                            <div>Menjalin kerjasama dan silaturahim dalam rangka menyebarluaskan kebermanfaatan untuk sesama.</div>
                          </article>
                      </div>
                    </div>
                    <div className="section mb-4">
                      <Link className="btn btn-primary btn-block" to='/campaign'>
                          <span>Mulai Bantu Tetangga</span>
                      </Link>
                    </div>
                    <div className="section content-report">
                      <div className="txt-subtitle fw-6">Laporan Tahunan</div>
                      <div className="txt-body-m mt-10">
                        <div>Semua laporan tahunan dapat diakses publik secara transparan.</div>
                      </div>
                      <div className="section-all-report">
                        <div className="list-report">
                          <a className="btn btn-primary btn-block btn-report" href='https://online.fliphtml5.com/xdhif/ijnv/' target="_blank"> 2022 - Annual Report <img src="/assets/img/ic-arrow-right-default.svg" className="img-right"></img></a>
                        </div>
                      </div>
                    </div>
                </Container>
                <div className="main-home">
                  <div className="footer">
                    <div className="container">
                        <div className="line-footer">
                            <Image src={logoFooter} className="footer-logo" />
                            <div className="about">Platform bagi semua yang aktif menyalurkan kebaikan sobat baik ke lebih banyak jangkauan menjadi lebih dekat.</div>
                            <div className="sosmed">
                                <a href="https://www.facebook.com/bantutetanggadotcom" target="_blank" rel="noopener noreferrer">
                                    <Image src={icFbWhite}/>
                                </a>
                                {/* <a href="https://www.linkedin.com/in/bantu-tetangga-68227921b/" target="_blank" rel="noopener noreferrer">
                                    <Image src={icInWhite}/>
                                </a> */}
                                <a href="https://www.instagram.com/bantutetanggacom/" target="_blank" rel="noopener noreferrer">
                                    <Image src={icIgWhite}/>
                                </a>
                                <a href="https://vt.tiktok.com/ZSeG1hCXx/" target="_blank" rel="noopener noreferrer">
                                    <Image src={icTiktok}/>
                                </a>
                                {/* <a href="https://www.youtube.com/channel/UCbpgIZHxgQXHe_4DydVM8ow" target="_blank" rel="noopener noreferrer">
                                    <Image src={icYtubeWhite}/>
                                </a> */}
                            </div>
                            <div className="menu-footer">
                                <Link to="/tentang-kami">Tentang Kami</Link>
                                <Link to="/syarat-ketentuan">Syarat & Ketentuan</Link>
                                <Link to="/kebijakan-privasi">Privasi</Link>
                            </div>
                            <div className="menu-footer-contact">
                                <Link to={{ pathname: "https://wa.me/6281221127728" }} rel="noopener noreferrer" target="_blank">+62 812 2112 7728</Link>
                                <Link to={{ pathname: "mailto:salam@bantutetangga.com" }} rel="noopener noreferrer" target="_blank">salam@bantutetangga.com</Link>
                            </div>
                        </div>
                    </div>
                    <div className="copyright">© 2023 Yayasan Bantu Tetangga</div>
                  </div>
                </div>
            </main>
        </>
      );
    }
  
    _onReady(event) {
      // access to player in all event handlers via event.target
      event.target.pauseVideo();
    }
}

export default TentangKami