import React, { Component } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

class CkEditor extends Component {
    constructor(props){
        super(props);
        this.state = {
            token: null
        }
    }

    componentDidMount() {
        if(localStorage.getItem("userData")){
            const auth = JSON.parse(localStorage.getItem("userData"))
            const token = `Bearer ${auth.access_token}`;
            this.setState ({ token: token  });
        }
    }
    render() {
        return (
            <div className="container-ckeditor">
                <CKEditor
                    onInit={ editor => {
                        // Insert the toolbar before the editable area.
                        editor.ui.getEditableElement().parentElement.insertBefore(
                            editor.ui.view.toolbar.element,
                            editor.ui.getEditableElement()
                        );
                    } }
                    onChange={this.props.handleCKEditor}
                    editor={ DecoupledEditor }
                    data={(this.props.value ? this.props.value : '<p></p>')}
                    config={{
                        image: {
                            resize:true,
                        },
                        ckfinder: {
                    
                            uploadUrl: (process.env.REACT_APP_API_URL+'/image/campaign'),
                            // uploadUrl: 'https://ckeditor.com/apps/ckfinder/3.5.0/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json',
                            options: {
                                resourceType: 'Images'
                            },
                        },
                        toolbar: {
                            items: [
                                "heading","bold","italic","underline","alignment",
                                "numberedList","bulletedList","link","imageUpload",
                                "insertTable","mediaEmbed"
                            ]
                        }
                    }}
                />
            </div>
        );
    }
}

export default CkEditor;