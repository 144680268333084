import React from 'react'
const Loading =  () => {
    return (
        <>
            <div>
                <div className="gradient text-loading height-100"></div>
                <div className="section">
                    <div className="gradient text-loading text-loading-60 height-10"></div>
                    <div className="gradient text-loading text-loading-40 height-5 mt-2"></div>
                    <div className="gradient text-loading mt-3"></div>
                    <div className="gradient text-loading"></div>
                </div>
            </div>
        </>
    )
}

export default Loading
