import React from 'react'
const Loading =  (props) => {
    return (
        <>
        <div className="content-grid grid-2">
            <div>
                <div className="gradient text-loading text-loading-90 height-100"></div>
            </div>
            <div>
                <div className="gradient text-loading text-loading-90"></div>
                <div className="gradient text-loading text-loading-90 mb-3"></div>
                <div className="gradient text-loading text-loading-60"></div>
                <div className="content-grid grid-2">
                    <div className="gradient text-loading text-loading-90"></div>
                    <div className="gradient text-loading text-loading-90"></div>
                </div>
            </div>
        </div>
        <div className="content-grid grid-2">
            <div>
                <div className="gradient text-loading text-loading-90 height-100"></div>
            </div>
            <div>
                <div className="gradient text-loading text-loading-90"></div>
                <div className="gradient text-loading text-loading-90 mb-3"></div>
                <div className="gradient text-loading text-loading-60"></div>
                <div className="content-grid grid-2">
                    <div className="gradient text-loading text-loading-90"></div>
                    <div className="gradient text-loading text-loading-90"></div>
                </div>
            </div>
        </div>
        <div className="content-grid grid-2">
            <div>
                <div className="gradient text-loading text-loading-90 height-100"></div>
            </div>
            <div>
                <div className="gradient text-loading text-loading-90"></div>
                <div className="gradient text-loading text-loading-90 mb-3"></div>
                <div className="gradient text-loading text-loading-60"></div>
                <div className="content-grid grid-2">
                    <div className="gradient text-loading text-loading-90"></div>
                    <div className="gradient text-loading text-loading-90"></div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Loading
