import React from "react";
import {Spinner, Image} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory, Redirect, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import {StringToSlug} from '../../../../utils';

import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";
import postData from './postData';

import icFieldCorrected from "../../../../assets/svg/ic-field-corrected.svg";
import icWarningBlue from "../../../../assets/svg/ic-warning-blue.svg";

import RedirectStep from "./RedirectStep";
import { ExternalLink } from 'react-external-link';

import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { clearDispatch, API, reloadUser } from '../../../../config/redux/action';
import { Header, Wizard } from '../../../organisms';

const Step4 = props => {

  const linkPrev = "/verifikasi-komunitas/step3";
  const { state, action } = useStateMachine(updateAction);
  const { handleSubmit} = useForm({
    defaultValues: state.postData
  });
  const { push } = useHistory();
  const onSubmit = async (data) => {
    data['step4'] = true;
    action(data);

    submitForm();
  };

  const submitForm = async () => {
    let provinsi = state.postData.provinsi;
    let kota = state.postData.kota;
    let formData = {
      name: state.postData.name,
      slug: StringToSlug(state.postData.name),
      email: state.postData.email,
      phone: state.postData.phone,
      address: state.postData.address,
      coverage_area: state.postData.coverage_area,
      identity: state.postData.identity,
      identity_member_1: state.postData.identity_member_1,
      identity_member_2: state.postData.identity_member_2,
      identity_member_3: state.postData.identity_member_3,
      identity_member_4: state.postData.identity_member_4,
      identity_face: state.postData.identity_face,
      identity_face_member_1: state.postData.identity_face_member_1,
      identity_face_member_2: state.postData.identity_face_member_2,
      identity_face_member_3: state.postData.identity_face_member_3,
      identity_face_member_4: state.postData.identity_face_member_4,
      tax: state.postData.tax
    };

    if(state.postData.coverage_area !== 'all'){
      formData['area_id'] = (state.postData.coverage_area === 'Kota' ? kota : provinsi);
    }
    console.log(state.postData.coverage_area)
    console.log(state.postData.provinsi)
    console.log(state.postData.kota)
    const editCampaignerSlug = (state.postData.editCampaignerSlug ? state.postData.editCampaignerSlug : '');;

    const auth = JSON.parse(localStorage.getItem("userData"));
    const token = `Bearer ${auth.access_token}`;
    const config = {
        method: (editCampaignerSlug === '' ? 'post' : 'put'),
        url: '/campaigner/community',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        data: formData
    };

    const res = await props.API(config, true, true).catch(err=>err);
    if (res) {
      setTimeout(() =>{
        updateAuth(auth);
      },1000 );
    }else{
      let data={};
      data['checkMessage'] = true;
      updateAuth(auth);
      action(data);

    }
  }

  const updateAuth = async (auth) => {
    const access_token = `Bearer ${auth.access_token}`;
    const config = {
        method: 'get',
        url: '/profile',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': access_token
        }
    };
    const res = await props.reloadUser(config, auth).catch(err=>err);
    if (res) {
      localStorage.setItem('userData', JSON.stringify(res));
      const editCampaignerSlug = state.postData.editCampaignerSlug;
      const msg = (editCampaignerSlug === '') ? 'Data anda berhasil dikirim untuk diverifikasi' : 'Data berhasil diperbaharui';
      toast.info(msg, {
        position: "top-center",
        hideProgressBar: true,
        autoClose:3000,
      });
      
      action(postData);
      setTimeout(() =>{
        window.location.assign("/galang-dana");
      },3000 );
    }
  }
  const initialSteps = {
    class1:"",
    number1:"3",
    label1:"Dokumen",
    link1:"/verifikasi-komunitas/step3",

    class2:"step-active last-step",
    number2:4,
    label2:"Ringkasan",
    link2:"/verifikasi-komunitas/step4",

    class3:"step-hide",
    number3:"",
    label3:"",
    link3:"",
  }
  return (
    <>
      {(!state.postData.step3) && 
        <Redirect to="/verifikasi-komunitas/step3" />
      }
      <Header title="Verifikasi Penggalang" prevPage={linkPrev} ic_white={true}/>
      <Wizard initialSteps={initialSteps} />
      <form onSubmit={handleSubmit(onSubmit)} className="form-hook container-step4">
        <div className="txt-body-m mb-5">
          <div className="content-grid grid-2 info-form2">
            <div><Image className="loader-img" src={icWarningBlue}/></div>
            <div className="text-left ml-3">
              Berikut data yang telah Anda isi. Mohon review sekali lagi untuk memastikan tidak ada kesalahan pada data ini.
            </div>
          </div>
        </div>

        <div>
          <div className="label txt-caption">Nama Lengkap</div>
          <div className="field txt-body mb-20">{state.postData.name}</div>
        </div>

        <div>
          <div className="label txt-caption">Email Penggalang</div>
          <div className="field txt-body mb-20">{state.postData.email}</div>
        </div>

        <div>
          <div className="label txt-caption">Nomor Ponsel</div>
          <div className="field txt-body mb-20">{state.postData.phone}</div>
        </div>
        <hr/>


        <div>
          <div className="label txt-caption">Alamat</div>
          <div className="field txt-body mb-20">{state.postData.address}</div>
        </div>
        <div>
          <div className="label txt-caption">Provinsi</div>
          <div className="field txt-body mb-20">{state.postData.name_provinsi}</div>
        </div>
        <div>
          <div className="label txt-caption">Kota</div>
          <div className="field txt-body mb-20">{state.postData.name_kota}</div>
        </div>

        <hr/>

        <div className="input-file mb-20">
          <div className="container-file">
              <div className="content-grid grid-2 txt-body-m review">
                <div><Image src={icFieldCorrected}/><span className="txt-body-m">Foto Profil Komunitas</span></div>
                <div>
                  <ExternalLink href={state.postData.campaigner_image} className="fw-6">Lihat</ExternalLink>
                </div>
              </div>
          </div>
        </div>

        <div className="input-file mb-20">
          <div className="container-file">
              <div className="content-grid grid-2 txt-body-m review">
                <div><Image src={icFieldCorrected}/><span className="txt-body-m">KTP Ketua Komunitas</span></div>
                <div>
                  <ExternalLink href={state.postData.identity} className="fw-6">Lihat</ExternalLink>
                </div>
              </div>
          </div>
        </div>

        <div className="input-file mb-20">
          <div className="container-file">
              <div className="content-grid grid-2 txt-body-m review">
                <div><Image src={icFieldCorrected}/><span className="txt-body-m">NPWP Ketua Komunitas</span></div>
                <div>
                  <ExternalLink href={state.postData.tax} className="fw-6">Lihat</ExternalLink>
                </div>
              </div>
          </div>
        </div>

        <div className="input-file mb-20">
          <div className="container-file">
              <div className="content-grid grid-2 txt-body-m review">
                <div><Image src={icFieldCorrected}/><span className="txt-body-m">KTP Anggota 1</span></div>
                <div>
                  <ExternalLink href={state.postData.identity_member_1} className="fw-6">Lihat</ExternalLink>
                </div>
              </div>
          </div>
        </div>

        <div className="input-file mb-20">
          <div className="container-file">
              <div className="content-grid grid-2 txt-body-m review">
                <div><Image src={icFieldCorrected}/><span className="txt-body-m">KTP Anggota 2</span></div>
                <div>
                  <ExternalLink href={state.postData.identity_member_2} className="fw-6">Lihat</ExternalLink>
                </div>
              </div>
          </div>
        </div>

        <div className="input-file mb-20">
          <div className="container-file">
              <div className="content-grid grid-2 txt-body-m review">
                <div><Image src={icFieldCorrected}/><span className="txt-body-m">KTP Anggota 3</span></div>
                <div>
                  <ExternalLink href={state.postData.identity_member_3} className="fw-6">Lihat</ExternalLink>
                </div>
              </div>
          </div>
        </div>

        <div className="input-file mb-20">
          <div className="container-file">
              <div className="content-grid grid-2 txt-body-m review">
                <div><Image src={icFieldCorrected}/><span className="txt-body-m">KTP Anggota 4</span></div>
                <div>
                  <ExternalLink href={state.postData.identity_member_4} className="fw-6">Lihat</ExternalLink>
                </div>
              </div>
          </div>
        </div>

        <div className="input-file mb-20">
          <div className="container-file">
              <div className="content-grid grid-2 txt-body-m review">
                <div><Image src={icFieldCorrected}/><span className="txt-body-m">Foto Ketua dengan KTP</span></div>
                <div>
                  <ExternalLink href={state.postData.identity_face} className="fw-6">Lihat</ExternalLink>
                </div>
              </div>
          </div>
        </div>

        <div className="input-file mb-20">
          <div className="container-file">
              <div className="content-grid grid-2 txt-body-m review">
                <div><Image src={icFieldCorrected}/><span className="txt-body-m">Foto Anggota 1 dengan KTP</span></div>
                <div>
                  <ExternalLink href={state.postData.identity_face_member_1} className="fw-6">Lihat</ExternalLink>
                </div>
              </div>
          </div>
        </div>

        <div className="input-file mb-20">
          <div className="container-file">
              <div className="content-grid grid-2 txt-body-m review">
                <div><Image src={icFieldCorrected}/><span className="txt-body-m">Foto Anggota 2 dengan KTP</span></div>
                <div>
                  <ExternalLink href={state.postData.identity_face_member_2} className="fw-6">Lihat</ExternalLink>
                </div>
              </div>
          </div>
        </div>

        <div className="input-file mb-20">
          <div className="container-file">
              <div className="content-grid grid-2 txt-body-m review">
                <div><Image src={icFieldCorrected}/><span className="txt-body-m">Foto Anggota 3 dengan KTP</span></div>
                <div>
                  <ExternalLink href={state.postData.identity_face_member_3} className="fw-6">Lihat</ExternalLink>
                </div>
              </div>
          </div>
        </div>

        <div className="input-file mb-20">
          <div className="container-file">
              <div className="content-grid grid-2 txt-body-m review">
                <div><Image src={icFieldCorrected}/><span className="txt-body-m">Foto Anggota 4 dengan KTP</span></div>
                <div>
                  <ExternalLink href={state.postData.identity_face_member_4} className="fw-6">Lihat</ExternalLink>
                </div>
              </div>
          </div>
        </div>

        <div className="text-center txt-body-m mb-5">
          Dengan mengklik tombol di bawah ini, Anda dianggap telah menyetujui 
          <Link to="/syarat-ketentuan" className="fw-6"> Syarat & Ketentuan</Link> serta 
          <Link to="/kebijakan-privasi" className="fw-6"> Kebijakan Privasi</Link> dari Bantu Tetangga.
        </div>
        
        <button type="submit" className="btn btn-primary btn-block" disabled={props.isLoading}>
          { (!props.isLoading) && <span>Verifikasi Penggalang</span> }
          { props.isLoading && <Spinner animation="border" role="status"/> }
        </button>
        
        {state.postData.checkMessage &&
          <RedirectStep errorMessage={props.errorMessage} />
        }
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
  reloadUser: (config, auth) => dispatch(reloadUser(config, auth)),
  clearDispatch: () => dispatch(clearDispatch()),
  API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step4));

