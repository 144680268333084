
import React from 'react';
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import {Spinner, Container} from 'react-bootstrap';

const ModalPostRek = props => {
    const { handleSubmit:handleSubmitRek, register:registerRek, formState: { errors: errorsRek } } = useForm({
        mode: "onBlur",
      });
    return (
        <Container>
            <form key={2} className="form-hook" onSubmit={handleSubmitRek(props.handleSubmitFormRek)}>
                <div className="form-group">
                    <input
                    name="bank"
                    className="form-control"
                    ref={registerRek({ required: "Nama Rekening wajib diisi" })}
                    onChange={props.handleChange}
                    placeholder="Nama Rekening"
                    />
                    <div className="txt-body-s mt-1">Contoh: Bank Mandiri, BNI, BTPN dll</div>
                    <ErrorMessage errors={errorsRek} name="bank" as={<p className="error-input"></p>} />
                    {props.errorMessage.bank && 
                    <p className="error-input">{props.errorMessage.bank}</p>}
                </div>
                <div className="form-group">
                    <input
                    name="account_number"
                    className="form-control"
                    ref={registerRek({ required: "No Rekening wajib diisi" })}
                    onChange={props.handleChange}
                    placeholder="Nomor Rekening"
                    />
                    <ErrorMessage errors={errorsRek} name="account_number" as={<p className="error-input"></p>} />
                    {props.errorMessage.account_number && 
                    <p className="error-input">{props.errorMessage.account_number}</p>}
                </div>
                <div className="form-group">
                    <input
                    name="account_holder"
                    className="form-control"
                    ref={registerRek({ required: "Atas Nama wajib diisi" })}
                    onChange={props.handleChange}
                    placeholder="Nama yang terdaftar sesuai KTP"
                    />
                    <ErrorMessage errors={errorsRek} name="account_holder" as={<p className="error-input"></p>} />
                    {props.errorMessage.account_holder && 
                    <p className="error-input">{props.errorMessage.account_holder}</p>}
                </div>

                <button className="btn btn-primary btn-block" type="submit" disabled={props.isLoadingRek}>
                    { (!props.isLoadingRek) && <>Tambah</> }
                    { props.isLoadingRek && <Spinner animation="border" role="status"/> }
                </button>
            </form>
        </Container>
    );
}

export default ModalPostRek;