import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import debounce from "lodash.debounce";

//Load Component
import { clearDispatch, API } from '../../../../config/redux/action';
import { Header, CampaignCard, FilterCampaigns } from '../../../../containers/organisms';
import { Container, Image, Button } from 'react-bootstrap';
import LoadingCampaign from '../../../../containers/organisms/CampaignCard/Loading'
import imgCampaignNotFound from '../../../../assets/img/campaign-not-found.png';

import IcSearch from '../../../../assets/svg/ic-search.svg';

//Load Stylesheet
import './style.css'

class AllCampaign extends React.Component {
    state = {
        /** Token */
        token: null,
        
        /** Campaigns */
        campaigns: [],
        campaignPriority: [],
        isFetchingCampaign: false,
        currentPage: 1,
        total: -1,
        lastPage: null,
        
        /** filter */
        filter: {
            keyword: '',
            category: {
                slug: 'all',
                name: 'All'
            },
            order: 'latest',
            sectionId:'',
            sectionName:'',
            categories:'',
            donateRange:'',
            min_donate: '',
            max_donate: ''
        },
    }
    constructor(props) {
        super(props)
        this.handleFilter = this.handleFilter.bind(this)
        this.handleKeywordChange = this.handleKeywordChange.bind(this)
        this.filterKeyword = debounce(this.filterKeyword, 500)

        /** Build Filter State */
        const params = new URLSearchParams(this.props.location.search)
        if (params.has('category')) {
            this.state.filter.category.slug = params.get('category')
        }
        if (params.has('order'))
            this.state.filter.order = params.get('order')
        if (params.has('str'))
            this.state.filter.keyword = params.get('str')
        if (params.has('min_donate'))
            this.state.filter.min_donate = params.get('min_donate')
        if (params.has('max_donate'))
            this.state.filter.max_donate = params.get('max_donate')
        if (params.has('sectionId'))
            this.state.filter.sectionId = params.get('sectionId')
        if (params.has('sectionName'))
            this.state.filter.sectionName = params.get('sectionName')
    }
    handleKeywordChange(e) {
        this.setState({ filter: { ...this.state.filter, keyword: e.target.value }})
        if(e.target.value.length !== 1){
            this.filterKeyword()
        }
    }
    filterKeyword() {
        this.setState({ isFetchingCampaign: true, campaigns: [], currentPage: 1 })
        
        const { history, location } = this.props

        let params = new URLSearchParams(location.search)
        if(this.state.filter.keyword) {
            params.set('str', this.state.filter.keyword)
        } else {
            params.delete('str')
        }

        history.push({ search: params.toString() })
    }
    handleFilterModal = (params) => {
        const {filter} = this.state
        let category = params.categories.join(',');
        let temp = filter;
        temp['categories'] = category;
        temp['order'] = params.order;
        temp['donateRange'] = (params.min_donate !== "" ? params.min_donate : (params.max_donate ? 0 : ''))+(params.max_donate !== "" ?(','+params.max_donate):'')
        temp['min_donate'] = params.min_donate
        temp['max_donate'] = params.max_donate
        this.setState({filter:temp})
        this.resetData();
    }
    resetData = () => {
        this.setState({
            campaigns: [],
            campaignPriority: [],
            isFetchingCampaign: true,
            currentPage: 1,
            total: -1,
            showFilterCampaigns:false
        })
    }
    apiLazyLoad = async () => {
        const { API } = this.props
        const { token, currentPage, campaigns, filter } = this.state
        const { category, order, keyword, sectionId, categories, donateRange, min_donate, max_donate } = filter
        //const perPage = 5

        let params = new URLSearchParams()
        params.append('page', currentPage)
        //params.append('per_page', perPage)

        if (category.slug !== 'all')
            params.append('category', category.slug)
        if (keyword)
            params.append('str', keyword)
        if (order)
            params.append('sort', order)
        if (sectionId)
            params.append('home_section_id', sectionId)
        if (categories)
            params.append('category', categories)
        if (donateRange)
            params.append('donate_range', donateRange)
        if (min_donate)
            params.append('min_donate', min_donate)
        if (max_donate)
            params.append('max_donate', max_donate)
        

        console.log(params);
        const config = {
            method: 'get',
            url: '/campaign/curation?' + params.toString(),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        };

        const res = await API(config, false).catch(err=>err);
        if (res) {
            //console.log(res, 'response')
            this.setState({
                currentPage: currentPage + 1,
                campaigns: [ ...campaigns, ...res.data.campaigns ],
                total: res.total,
                lastPage: res.last_page
            })
        }

        this.setState({ isFetchingCampaign: false })
        
        if(window.innerHeight > 640 && this.state.total <= this.state.lastPage)
        // if(window.innerHeight > 640 && this.state.campaigns.length <= currentPage)
            this.setState({ isFetchingCampaign: true })
    }

    handleFilter () {
        const { history, location } = this.props
        
        let params = location.search ? location.search + '&' : '?'
        history.push({
            pathname: "/filter",
            search: params + 'prev=campaign'
        })
    }
    handleScroll = () => {
        const isScrolled = document.documentElement.offsetHeight - Math.ceil(window.innerHeight + document.documentElement.scrollTop);
        if (isScrolled > 100 || this.state.isFetchingCampaign)
            return;
        this.setState({isFetchingCampaign: true})
    }
    componentDidMount() {
        /** Initialize token */
        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = auth && auth.access_token ? `Bearer ${auth.access_token}` : null;

        /** Lazy Load Event */
        this.setState({ token, isFetchingCampaign: true });
        window.addEventListener('scroll', this.handleScroll);
    }
    componentDidUpdate(_, prevState) {
        const { isFetchingCampaign, total, campaigns } = this.state
        if(isFetchingCampaign !== prevState.isFetchingCampaign) {
            if (!isFetchingCampaign || (campaigns.length === total && total !== -1)) {
                this.setState({isFetchingCampaign: false});
                return;
            }
            this.apiLazyLoad();
        }
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll); 
    }
    render() {
        const { campaigns, campaignPriority, isFetchingCampaign, filter } = this.state
        const orders = { latest: 'Terbaru', urgent: 'Mendesak' }
        let title = 'Semua Program'
        if(filter.category.slug !== 'all' || filter.sectionId || (filter.order !== 'latest' && orders[filter.order])) {
            title = []
            if(filter.category.slug !== 'all') 
                title.push(filter.category.name)
            if(filter.order !== 'latest' && orders[filter.order])
                title.push(orders[filter.order])

            if(filter.sectionId){
                title.push(filter.sectionName);
            }
            title = title.join(', ')
        }
        return (
            <Fragment>
                <Header title={title} prevPage="/"  cols={3} position3={IcSearch} linkPost3="/search?prevPage=campaign" />
                <main>
                    <Container>
                        {/* <div className="form-group">
                            <Input 
                                type="text" 
                                name="search"
                                formGroupClass="mb-0"
                                labelClass="d-none"
                                icon_right="assets/img/ic-search-gray.svg" 
                                placeholder="Masukkan Kata Kunci" 
                                handleFormChange={this.handleKeywordChange}
                                value={filter.keyword}
                            />
                        </div> */}
                        <div className="row mt-3" style={{marginLeft: -4, marginRight: -4}}>
                            {campaignPriority.map((campaign, i) => (
                                <div className="col-6" style={{paddingLeft: 4, paddingRight: 4}}  key={campaign.slug + i}>
                                    <CampaignCard campaign={campaign} prevPage="?prevPage=campaign" />
                                </div>
                            ))}
                            {campaigns.map((campaign, i) => (
                                <div className="col-6" style={{paddingLeft: 4, paddingRight: 4}}  key={campaign.slug + i}>
                                    <CampaignCard campaign={campaign} prevPage="?prevPage=campaign" />
                                </div>
                            ))}
                        </div>

                        {isFetchingCampaign && <LoadingCampaign /> }

                        {campaigns.length === 0 && !isFetchingCampaign &&
                            <div className="section search-not-found">
                                <div className="text-center">
                                    <Image src={imgCampaignNotFound}/>
                                    <div className="txt-subtitle">Maaf, Program Tidak Ditemukan</div>
                                    <div className="txt-body">Coba cari dengan pilihan yang lain.</div>
                                </div>
                            </div>
                        }
                    </Container>

                    <div className="fliter-all-campaign">
                        <Button className="btn-actions" size="sm" variant="primary" onClick={() => this.setState({showFilterCampaigns:true})}>
                            <Image className="filter-icon" src='/assets/img/ic-filter.svg' /> 
                            Filter
                        </Button>
                    </div>

                    <FilterCampaigns
                        onHide={() => this.setState({showFilterCampaigns:false})}
                        show={this.state.showFilterCampaigns}
                        label="Filter"
                        handleFilter = {this.handleFilterModal}
                    />

                </main>
                {/*/> */}
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AllCampaign));