import React from "react";
import {Image, Container} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { Header } from '../../organisms';
import imgLostConnection from '../../../assets/img/campaign-not-found.png';

import './style.css';
class LostConnection extends React.Component {
  state = {}
  
  componentDidMount (){
  }

  render() {
    return(
      <>
      <Header splash={true}/>
      <main>  
        <Container >
          <div className="section text-center pb-0">
            <Image src={imgLostConnection} className="w-100"/>
            <div className="txt-subtitle-m fw-6 mt-4 mb-2">Koneksi Anda Terputus</div>
            <div className="txt-body-m mb-5">Maaf koneksi Anda terputus, coba lagi untuk memuat ulang.</div>
            <Link to="" className="btn btn-secondary btn-block">Coba Lagi</Link>
          </div>
        </Container>
      </main>
      </>
    )
  }
}

export default LostConnection;
