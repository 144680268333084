import React, { Fragment } from "react";
import {Container, Image} from "react-bootstrap";
import {Redirect, Link} from "react-router-dom";
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';
import 'react-accessible-accordion/dist/fancy-example.css';
import { Header } from '../../../organisms';

import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";
import * as QueryString from "query-string";
import moment from 'moment/min/moment-with-locales';
import Moment from 'react-moment';
import {FbPixel} from '../../../../config/Tracker/FbPixel';

import logo from '../../../../assets/svg/logo-bangga-color.svg';
import bannerOperational from '../../../../assets/img/banner-operational.png';

// Sets the moment instance to use.
Moment.globalMoment = moment;
 
// Set the locale for every react-moment instance to French.
Moment.globalLocale = 'id';
 
// Set the output format for every react-moment instance.
Moment.globalFormat = 'D MMMM YYYY, H:m';
 
// Set the output timezone for local for every instance.
Moment.globalLocal = true;


const PageStep3 = (props) => {
  const { state } = useStateMachine(updateAction);

  return (
    <Fragment>
      {(!state.postData.stepdon2) && 
        <Redirect to={"/rekomendasi-tetangga/tetangga-bantu-tetangga/step2"} />
      }
      <main className="donate-tbt checkout-beras no-padding">  
        <div className="top-logo">
          <Image src={logo}/>
            <div className="mb-1 mt-2 txt-medium">Terima kasih telah menginformasikan kebaikan</div>
            <div className="mb-1">Tetangga Anda akan kami survey untuk menentukan bantuan yang dibutuhkan.</div>
        </div>
        <Container className="container-donate">
          <div className="section txt-body-m ">
            <div className="box-bordered">
              <div>
                <span className="txt-short-desc">Nama Penerima</span>
                <p>{state.postData.receiver_name}</p>
              </div>
              <div>
                <span className="txt-short-desc">Alamat</span>
                <p>{state.postData.address} </p>
              </div>
            </div>
            <div className="content-grid grid-2 mt-2">
              <div>Kode Donasi</div>
              <div className="ct-title">{state.postData.resultDonation.code}</div>
            </div>
            <Link to="/donasi-uang/tetangga-bantu-tetangga/step1">
              <Image src={bannerOperational} className="img-fluid img-banner"/>
            </Link>
            <div className="mt-2 mb-2">Anda dapat menghubungi kami untuk bantu menghubungkan ke penerima manfaat. </div>
            {state.postData.stepdon3 && 
              <div className="float-btn-donate">
                <Link to="/" className="btn btn-primary mt-1 mb-3"><span>Bantu Tetangga Lainnya</span></Link>
                <Link to="/campaign" className="btn btn-secondary">
                  Lihat Penggalangan Lainnya
                </Link>
              </div>
            }
          </div>
        </Container>
      </main>
    </Fragment>
  );
};

class Step3 extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        codeDonate:null
      }
  }

  checkDonasi = async (codeDonate) => {
    const { API } = this.props;
    const config = {
      method: 'get',
      url: '/donation/detail/'+codeDonate+'?is_recommend_neighbor=1',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const res = await API(config, false).catch(err=>err);
    if (res) {
      setTimeout(() =>{
        this.checkDonasi(codeDonate);
      },10000 );
    }
  }

  componentDidMount() {
    FbPixel('InitiateCheckout');
    const params = QueryString.parse(this.props.location.search);
    if(params.code){
      this.setState({codeDonate:params.code})
      this.checkDonasi(params.code);
    }
  }
  render() {
      const state = this.state
      return(
          <PageStep3 stateStep3={state}/>
      )
  }
}

const mapStateToProps = (state) => ({
  errorMessage: state.errorMessage,
})

const mapDispatchToProps = (dispatch) => ({
clearDispatch: () => dispatch(clearDispatch()),
API: (path, data) => dispatch(API(path, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Step3);
