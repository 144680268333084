const intialState = {
    resultAPI:{},
    errorMessage: {},
    popup:false,
    isLogin:false,
    isLoading:false,
    dataUser:{}
}
const reducer = (state=intialState, action) => {
    switch(action.type){
        case 'CHANGE_USER':
            return {
                ...state,
                dataUser: action.value
            }
        case 'CHANGE_POPUP':
            return {
                ...state,
                popup: action.value
                // popUp: {show:action.newValue.show, message: action.newValue.message}
            }
  
        case 'CHANGE_ISLOGIN':
            return{
                ...state,
                isLogin: action.value
                
            }
        case 'CHANGE_LOADING':
            return{
                ...state,
                isLoading: action.value
                
            }

        case 'CHANGE_ERROR_POST':
            return{
                ...state,
                errorMessage: action.value
            }
        default :
            return state
  
    }
}

export default reducer