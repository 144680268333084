import React from "react";
import { Modal, Image } from 'react-bootstrap';
import "cropperjs/dist/cropper.min.css";

import { withRouter } from "react-router";

import { connect } from 'react-redux';
import { API } from '../../../../config/redux/action';

import IcSpinner from '../../../../assets/svg/ic-spinner.svg';
import icFieldCorrected from "../../../../assets/svg/ic-field-corrected.svg";
import icRightArr from "../../../../assets/svg/ic-right-arr.svg";
import checkKTP from "../../../../assets/img/ktp-check.png";
import uncheckKTP from "../../../../assets/img/ktp-uncheck.png";
import checkSelfieKTP from "../../../../assets/img/foto-benar.png";
import uncheckSelfieKTP from "../../../../assets/img/foto-salah.png";
import checkNPWP from "../../../../assets/img/npwp-check.png";
import uncheckNPWP from "../../../../assets/img/npwp-uncheck.png";

class UploadFile extends React.Component {
    constructor() {
        super();
        this.fileInputRef = React.createRef()
        this.state = {
            isLoading:false,
            show:false,
        };
    }

    verifyFile = (files) => {
        
        const imageMaxSize = 2000000 // bytes
        const acceptedFileTypes = (this.props.field === 'logo' ? 'image/png, image/jpg, image/jpeg' : 'image/png, image/jpg, image/jpeg, application/pdf');
        const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {return item.trim()})
        if (files && files.length > 0){
            const currentFile = files[0]
            const currentFileType = currentFile.type
            const currentFileSize = currentFile.size
            if(currentFileSize > imageMaxSize) {
                this.setState({msgProcess:"Ukuran file terlalu besar"})
                return false
            }
            if (!acceptedFileTypesArray.includes(currentFileType)){
                this.setState({msgProcess:"Format file tidak sesuai ketentuan"})
                return false
            }
            return true
        }
    }

    handleLoading = () => {
        const {isLoading} = this.state;
        this.setState({isLoading: !isLoading});
    }

    handleFileSelect = event => {
        this.setState({show:true})
        const files = event.target.files

        this.setState({originalImage:files[0]});
        if (files && files.length > 0){
            const isVerified = this.verifyFile(files)
            if (isVerified){
                this.handleLoading();
                const currentFile = files[0]

                const myFileItemReader = new FileReader()
                myFileItemReader.addEventListener("load", ()=>{
                    this.handleFile();
                }, false)
                
                myFileItemReader.readAsDataURL(currentFile)
                
             }
        }
        
    }

    handleFile = () => {
        const {originalImage} =  this.state
        let returnFileCropped = {filePost:originalImage, field:this.props.field};
        this.props.hideBtnSubmit();
        this.submitFile(returnFileCropped);

        
    }

    submitFile = async (returnFileCropped) => {
        this.setState({msgProcess:'Sedang mengirim foto...', isLoading:true});
        let formData = new FormData();
        // let typeFile = (returnFileCropped.filePost.type === 'application/pdf' ? 'document' : 'image');
        let typeFile = (returnFileCropped.field === 'logo' ? 'image' : 'document');
        formData.append(typeFile, returnFileCropped.filePost);
        const config = {
            method: 'post',
            url: '/'+typeFile,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.access_token
            },
            data: formData
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            this.setState({msgProcess:'', isLoading:false, show:false});
            returnFileCropped['file_name'] = res.data.file_url;
            this.props.returnFile(returnFileCropped);

            console.log(returnFileCropped['file_name'], 'dataat')
        }else{
            this.setState({msgProcess:'Gagal Mengirim Foto', isLoading:false});
        }
    }

    componentDidMount(){
        if(localStorage.getItem("userData")){
            const auth = JSON.parse(localStorage.getItem("userData"));
            this.setState({access_token: `Bearer ${auth.access_token}`})
        }
    }

    render() {

        const {isLoading, msgProcess, show} = this.state;
        return (
            <div>
                <div className="input-file">
                    <div className="container-file">
                        <div className="content-grid grid-1-8-1 label" onClick={()=>this.setState({show:(show ? false : true)})}>
                            <div>
                                {this.props.fileName === "PP_" ?
                                    <Image src={this.props.icon} className="img-profile-cropped"/> :
                                    <Image src={this.props.icon} className="img-card-cropped"/>
                                }
                            </div>
                            <div>
                                <span className="txt-body-m">{this.props.title}</span>
                                <div className="txt-caption">{this.props.info}</div>
                            </div>
                            <div>
                                {isLoading && 
                                    <Image className="loader-img" src={IcSpinner}/>
                                }
                                {!show &&
                                    <Image src={icRightArr}/> 
                                }
                            </div>
                        </div>
                        {show && this.props.fileName !== "KTPPIC_" && this.props.fileName !== "identity_face" &&
                            <div className="btn-upload-penggalang">
                                <input type="file" ref={this.fileInputRef} onChange={this.handleFileSelect}/>
                                <div className="btn btn-outline-primary btn-sm btn-white">Upload</div>
                            </div>
                        }
                        {show && this.props.fileName === "KTPPIC_" &&
                            <Modal
                                show={show}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                animation={false}
                                className="list-donatur"
                            >
                                <Modal.Header>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                        Panduan Upload KTP
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body onScroll={this.handleScroll}>
                                    <section className="container">
                                        <article className="nav">
                                            <Image src={checkKTP} className="ml-5"/> 
                                            <Image src={uncheckKTP} className="ml-2"/> 
                                        </article>
                                    </section>
                                    <ul>
                                        <li>Pastikan upload foto KTP asli. Bukan hasil scan atau fotokopi.</li>
                                        <li>Pastikan KTP masih berlaku.</li>
                                        <li>Pastikan KTP terlihat jelas dalam kota foto.</li>
                                        <li>Pastikan foto yang diupload tidak blur dan dalam pencahayaan bagus.</li>
                                    </ul>
                                    <article className="input-file">
                                        <div>
                                            <input type="file" ref={this.fileInputRef} onChange={this.handleFileSelect}/>
                                            <div className="btn btn-primary btn-sm btn-block">Upload</div>
                                        </div>
                                    </article>
                                    <button onClick={()=>this.setState({show:(false)})} className="mt-4 btn btn-sm btn-white btn-block">Batal</button>
                                </Modal.Body>
                            </Modal>
                        }
                        {show && this.props.fileName === "SKK_" &&
                            <Modal
                                show={show}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                animation={false}
                                className="list-donatur"
                            >
                                <Modal.Header>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                        Panduan Upload Selfie
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body onScroll={this.handleScroll}>
                                    <section className="container">
                                        <article className="nav">
                                            <Image src={checkSelfieKTP} className="ml-5"/> 
                                            <Image src={uncheckSelfieKTP} className="ml-2"/> 
                                        </article>
                                    </section>
                                    <ul>
                                        <li>Pastikan Anda dan KTP terlihat jelas dalam kota foto.</li>
                                        <li>Pastikan foto yang diupload tidak blur dan dalam pencahayaan bagus.</li>
                                    </ul>
                                    <article className="input-file">
                                        <div>
                                            <input type="file" ref={this.fileInputRef} onChange={this.handleFileSelect}/>
                                            <div className="btn btn-primary btn-sm btn-block">Upload</div>
                                        </div>
                                    </article>
                                    <button onClick={()=>this.setState({show:(false)})} className="mt-4 btn btn-sm btn-white btn-block">Batal</button>
                                </Modal.Body>
                            </Modal>
                        }
                        {show && this.props.fileName === "NPWP_" &&
                            <Modal
                                show={show}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                animation={false}
                                className="list-donatur"
                            >
                                <Modal.Header>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                        Panduan Upload NPWP
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body onScroll={this.handleScroll}>
                                    <section className="container">
                                        <article className="nav">
                                            <Image src={checkNPWP} className="ml-5"/> 
                                            <Image src={uncheckNPWP} className="ml-2"/> 
                                        </article>
                                    </section>
                                    <ul>
                                        <li>Pastikan upload foto NPWP asli. Bukan hasil scan atau fotokopi.</li>
                                        <li>Pastikan NPWP masih berlaku.</li>
                                        <li>Pastikan NPWP terlihat jelas dalam kota foto.</li>
                                        <li>Pastikan foto yang diupload tidak blur dan dalam pencahayaan bagus.</li>
                                    </ul>
                                    <article className="input-file">
                                        <div>
                                            <input type="file" ref={this.fileInputRef} onChange={this.handleFileSelect}/>
                                            <div className="btn btn-primary btn-sm btn-block">Upload</div>
                                        </div>
                                    </article>
                                    <button onClick={()=>this.setState({show:(false)})} className="mt-4 btn btn-sm btn-white btn-block">Batal</button>
                                </Modal.Body>
                            </Modal>
                        }
                    </div>
                    {(!isLoading && msgProcess) &&
                        <p className="error-input">{msgProcess}</p>
                    }
                </div>
            </div>
        );
    }

}
const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UploadFile));