import React from 'react';
import { Container, Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { API, clearDispatch, reloadUser } from '../../../../config/redux/action';
import { Header, Confirm } from "../../../organisms";
import icPersonalData from "../../../../assets/svg/ic-personal-data.svg";
import icShield from "../../../../assets/svg/ic-shield.svg";
// import icNotification from "../../../../assets/svg/ic-notification.svg";
import icLogout from "../../../../assets/svg/ic-logout.svg";

import './style.css';

class Setting extends React.Component {
    state = {
        auth: {},
        confirmLogout: false
    }
    updateAuth = async (auth) => {
        this.setState({loadProfile:true});
        const access_token = `Bearer ${auth.access_token}`;
        const config = {
            method: 'get',
            url: '/profile',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': access_token
            }
        };
        const res = await this.props.reloadUser(config, auth).catch(err=>err);
        if (res) {
            localStorage.setItem('userData', JSON.stringify(res));
            this.setState({loadProfile:false,auth:res})
        }
    }
    componentDidMount() {
        if(localStorage.getItem("userData")){
            const userdata = JSON.parse(localStorage.getItem("userData"))
            this.updateAuth(userdata);
        } else {
            this.props.history.push('/login')
        }
    }
    render() {
        return (
            <React.Fragment>
                <Header title="Pengaturan" prevPage="/profile" />
                <main>
                    <Container className="setting-preview">
                        <div className="setting-preview-menu">
                            <Link to="/pengaturan/personal-data" className="setting-preview-menu-item">
                                <Image src={icPersonalData} />
                                <div className="menu-info">
                                    <div className="txt-body-m ct-title">Data Diri</div>
                                    <div className="txt-caption">Foto Profil, Nama, Nomor Ponsel</div>
                                </div>
                            </Link>
                            <Link to="/pengaturan/keamanan" className="setting-preview-menu-item">
                                <Image src={icShield} />
                                <div className="menu-info">
                                    <div className="txt-body-m ct-title">Keamanan</div>
                                    <div className="txt-caption">Perubahan Kata Sandi</div>
                                </div>
                            </Link>
                            {/* <Link to="/pengaturan/notifikasi" className="setting-preview-menu-item">
                                <Image src={icNotification} />
                                <div className="menu-info">
                                    <div className="txt-body-m ct-title">Notifikasi</div>
                                    <div className="txt-caption">Pengingat, Update Campaign, Newsletter</div>
                                </div>
                            </Link> */}
                            <Link to="/pengaturan" onClick={() => this.setState({ confirmLogout: true })} className="setting-preview-menu-item">
                                <Image src={icLogout} />
                                <div className="menu-info">
                                    <div className="txt-body-m ct-title">Keluar</div>
                                </div>
                            </Link>
                        </div>
                        <Confirm
                            onHide={() => this.setState({confirmLogout:false})} 
                            show={this.state.confirmLogout}
                            action={() => this.props.history.push('/logout')}
                            title="Yakin ingin keluar?"
                            body="Anda perlu masuk kembali setelah ini."
                            label_cancel="Tidak"
                            label_yes="Keluar"
                            cancelModal={() => this.setState({confirmLogout:false})}
                        />
                    </Container>
                </main>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data)),
    reloadUser: (config, auth) => dispatch(reloadUser(config, auth))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Setting));