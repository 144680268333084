import React from 'react';
import {Modal, Spinner } from "react-bootstrap";

const Confirm =  (props) => {
    const { title, body, label_cancel, label_yes, show, onHide, action, cancelModal, isProcessing } = props
    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            animation={false}
            className="modal-confirm confirm-update"
            centered>
            <Modal.Header>
                <Modal.Title className="txt-title">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="txt-body">
                {body}
            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => {cancelModal()}}className="btn btn-outline-secondary btn-block">
                    {label_cancel}
                </button>
                {isProcessing && 
                    <div className="ct-red fw-6">{label_yes} <Spinner animation="border" role="status" variant="secondary"/></div>
                }
                {!isProcessing &&
                    <button onClick={() => action()} className="btn btn-primary btn-block">
                        {label_yes}
                    </button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default Confirm