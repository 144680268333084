import React from 'react';
import { Button, Container, Dropdown, Image, Modal, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { API, clearDispatch, reloadUser } from '../../../../config/redux/action';
import { Header } from "../../../organisms";
import icOptions from '../../../../assets/svg/ic-options.svg';
import icIdentity from '../../../../assets/svg/ic-identity.svg';
import icLegal from '../../../../assets/svg/ic-legal.svg';
import IcWarningYellow from '../../../../assets/svg/ic-warning-yellow.svg';
import icTax from '../../../../assets/svg/ic-tax.svg';
import { ExternalLink } from 'react-external-link';

import './style.css';
import { toast } from 'react-toastify';
import Loading from './Loading';

const fileMaxSize = 2000000 // bytes
const acceptedFileTypes = 'image/png, image/jpg, image/jpeg, application/pdf'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {return item.trim()})

class DocumentationCampaigner extends React.Component {
    state = {
        auth: {
            campaigner: {}
        },
        is_foundation: true,
        token: '',
        campaigner: {},
        isFetching: true,
        show: false,
        showTitle: '',
        showSrc: '',
        isDeleting: {
            identity: false,
            tax: false,
            certificate: false,
            legal: false,
        },
        isUploading: {
            identity: false,
            tax: false,
            certificate: false,
            legal: false,
        },
        showUpload: false,
        showUploadSlug: '',
        showUploadTitle: '',
        storeImage: null,
        documents: []
    }
    fileInputRef = React.createRef()
    foundationDocuments = [
        { slug: 'identity', display: 'E-KTP Ketua Lembaga', icon: icIdentity },
        { slug: 'tax', display: 'NPWP Ketua Lembaga', icon: icTax },
        { slug: 'certificate', display: 'Akte Lembaga', icon: icLegal },
        { slug: 'legal', display: 'SK-Kemenkumham', icon: icLegal },
    ]
    personalDocuments = [
        { slug: 'identity', display: 'E-KTP Penggalang', icon: icIdentity },
        { slug: 'identity_face', display: 'Foto Penggalang Bersama E-KTP', icon: icLegal },
        { slug: 'tax', display: 'NPWP Penggalang', icon: icTax },
    ]
    constructor(props) {
        super(props)
        this.state.auth = JSON.parse(localStorage.getItem("userData"))
    }
    showDocument (slug, title) {
        this.setState({ show: true, showTitle: title, showSrc: this.state.campaigner[slug] })
    }
    async deleteDocument(slug) {
        let isDeleting = this.state.isDeleting
        isDeleting[slug] = true
        this.setState({ isDeleting })

        let deleteData = {}
        deleteData[slug] = ''
        const confEditProfile = {
            method: 'put',
            url: '/campaigner',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            },
            data: {
                slug: this.state.campaigner.slug,
                address: this.state.campaigner.address,
                coverage_area: this.state.campaigner.coverage_area,
                name: this.state.campaigner.name,
                email: this.state.campaigner.email,
                phone: this.state.campaigner.phone,
                bio: this.state.campaigner.bio,
                social_media: this.state.campaigner.social_media,
                ...deleteData
            }
        };

        const res = await this.props.API(confEditProfile, true).catch(err=>err);
        if (res) {
            toast.info('Profil anda berhasil diperbaharui', {
                position: "top-center",
                hideProgressBar: true,
                autoClose: 3000,
            });

            let auth = this.state.auth
            auth.campaigner[slug] = ''
            auth.campaigner.filenames[slug] = ''

            this.setState({ auth, campaigner: auth.campaigner })
            localStorage.setItem('userData', JSON.stringify(auth));
        }

        isDeleting[slug] = false
        this.setState({ isDeleting })
    }
    showUpload(slug, title) {
        this.setState({ showUpload: true, showUploadSlug: slug, showUploadTitle: title })
        this.fileInputRef.current.click()
    }
    verifyFile = (files) => {
        if (files && files.length > 0){
            const currentFile = files[0]
            const currentFileType = currentFile.type
            const currentFileSize = currentFile.size
            if(currentFileSize > fileMaxSize) {
                this.setState({msgProcess:"Ukuran file terlalu besar"})
                alert("Ukuran file tidak boleh lebih dari " + fileMaxSize)
                return false
            }
            if (!acceptedFileTypesArray.includes(currentFileType)){
                this.setState({msgProcess:"Format file tidak sesuai ketentuan"})
                alert("Format file tidak sesuai ketentuan")
                return false
            }
            return true
        }
    }
    async handleFileSelect(event) {
        const files = event.target.files

        const isVerified = this.verifyFile(files)
        if(isVerified) {
            /** Do Save Here */
            const slug = this.state.showUploadSlug
            this.setState({showUpload: !this.state.showUpload})

            let isUploading = this.state.isUploading
            isUploading[slug] = true
            this.setState({ isUploading })
            
            // let typeFile = (files[0].type === 'application/pdf' ? 'document' : 'image');
            let typeFile = 'document';

            let formData = new FormData();
            formData.append(typeFile, files[0]);

            const confStoreImage = {
                method: 'post',
                url: '/'+typeFile,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },
                data: formData
            };
            const saveDocument = await this.props.API(confStoreImage, true).catch(err=>err);
            if (saveDocument) {
                let savedDocument = this.state.campaigner.filenames
                savedDocument[slug] = saveDocument.data.file_url
                const confEditProfile = {
                    method: 'put',
                    url: '/campaigner' + (!this.state.is_foundation ? '/personal' : ''),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': this.state.token
                    },
                    data: {
                        slug: this.state.campaigner.slug,
                        area_id: this.state.campaigner.area_id,
                        address: this.state.campaigner.address,
                        coverage_area: this.state.campaigner.coverage_area,
                        name: this.state.campaigner.name,
                        email: this.state.campaigner.email,
                        phone: this.state.campaigner.phone,
                        bio: this.state.campaigner.bio,
                        social_media: this.state.campaigner.social_media,
                        ...savedDocument
                    }
                };

                const res = await this.props.API(confEditProfile, true).catch(err=>err);
                if (res) {
                    toast.info('Profil anda berhasil diperbaharui', {
                        position: "top-center",
                        hideProgressBar: true,
                        autoClose: 3000,
                    });

                    let auth = this.state.auth
                    auth.campaigner[slug] = ''
                    auth.campaigner.filenames[slug] = ''
                    
                    this.setState({ auth, campaigner: auth.campaigner })
                    localStorage.setItem('userData', JSON.stringify(auth));
                    await this.updateAuth()
                }

                isUploading[slug] = false
                this.setState({ isUploading })
            }
        }
    }
    updateAuth = async () => {
        this.setState({ isFetching: true })
        const config = {
            method: 'get',
            url: '/profile',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            }
        };
        const res = await this.props.reloadUser(config, this.state.auth).catch(err=>err);
        if (res) {
            localStorage.setItem('userData', JSON.stringify(res));
            this.setState({ auth: res, campaigner: res.campaigner })
        }
        this.setState({ isFetching: false })
    }
    componentDidMount() {
        if(this.state.auth) {
            if(this.state.auth.campaigner) {
                const { auth } = this.state
                const token = auth && auth.access_token ? `Bearer ${auth.access_token}` : null;
    
                const documents = auth.campaigner.is_foundation ? this.foundationDocuments : this.personalDocuments;
                this.setState({ 
                    auth, 
                    token, 
                    campaigner: auth.campaigner, 
                    documents,
                    is_foundation: auth.campaigner.is_foundation,
                    isFetching: false
                });
            } else {
                this.props.history.push('/profile')
            }
        } else {
            this.props.history.push('/login')
        }
    }
    render() {
        const { auth, is_foundation } = this.state
        return (
            <React.Fragment>
                <Header 
                    title={`Dokumentasi ${is_foundation ? 'Lembaga':'Penggalang'}`} 
                    prevPage={"/campaigner/" + (auth && auth.campaigner ? auth.campaigner.slug : '')} />
                <main>
                    {!this.state.isFetching &&
                        <Container>
                            <div className="content-grid grid-2 info-form mb-3">
                                <div><Image className="loader-img" src={IcWarningYellow}/></div>
                                <div className="text-left ml-3">
                                    Mengganti data {auth.campaigner.is_foundation ? 'lembaga':'penggalang'} selain foto profil akan mencabut status verifikasinya selama beberapa waktu.
                                    <Link to="/faq"> Pelajari lebih lanjut</Link>
                                </div>
                            </div>
                            <div className="documentation-list">
                                {this.state.documents.map((doc, key) => {
                                    const filename = this.state.campaigner[doc.slug];
                                    const file = filename.split('.');
                                    return (
                                        <div className="documentation-item" key={key}>
                                            <div className="txt-body-m ct-title">
                                                <Image src={doc.icon} className="mr-2" /> {doc.display}
                                            </div>
                                            {this.state.campaigner.filenames && this.state.campaigner.filenames[doc.slug] && 
                                                <div className="documentation-action">
                                                    {file[file.length - 1] === 'pdf' && <ExternalLink href={filename} className="btn btn-block btn-secondary btn-sm">Lihat</ExternalLink>}
                                                    {file[file.length - 1] !== 'pdf' && <Button size="sm" variant="secondary" onClick={() => this.showDocument(doc.slug, doc.display)} block>Lihat</Button>}
                                                    <Button size="sm" variant="secondary" onClick={() => this.showUpload(doc.slug, doc.display)} block>
                                                        {!this.state.isUploading[doc.slug] && "Ganti"}
                                                        {this.state.isUploading[doc.slug] && <Spinner animation="border" role="status"/>}
                                                    </Button>
                                                    <Dropdown navbar={true}>
                                                        <Dropdown.Toggle className="btn-dropdown" size="sm" variant="default">
                                                            {!this.state.isDeleting[doc.slug] && <Image src={icOptions} height="25px" />}
                                                            {this.state.isDeleting[doc.slug] && <Spinner animation="border" role="status"/>}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => this.deleteDocument(doc.slug)}>Hapus</Dropdown.Item>
                                                            <Dropdown.Item href={"/pusat-bantuan"}>Pusat Bantuan</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            }
                                            {this.state.campaigner.filenames && !this.state.campaigner.filenames[doc.slug] && 
                                                <div className="documentation-action">
                                                    <Button size="sm" variant="primary" onClick={() => this.showUpload(doc.slug, doc.display)} block>
                                                        {!this.state.isUploading[doc.slug] && "Upload"}
                                                        {this.state.isUploading[doc.slug] && <Spinner animation="border" role="status"/>}
                                                    </Button>
                                                </div>
                                            }
                                        </div>
                                    )
                                })}
                                <input type="file" ref={this.fileInputRef} onChange={(e) => this.handleFileSelect(e)} className="d-none" />
                                <Modal show={this.state.show} onHide={() => this.setState({ show: false })} animation={false} centered>
                                    <Modal.Header closeButton>
                                        <Modal.Title id="contained-modal-title-vcenter">
                                            {this.state.showTitle}
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Image src={this.state.showSrc} width="100%" />
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" size="sm" onClick={() => this.setState({ show: false })}>
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </Container>
                    }
                    {this.state.isFetching && <Loading />}
                </main>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data)),
    reloadUser: (config, auth) => dispatch(reloadUser(config, auth))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentationCampaigner));