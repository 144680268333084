import {useEffect} from 'react';
import  { useHistory } from 'react-router-dom'
import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";

const RedirectStep =  (props) => {
    
    const routeLink = "/donasi-beras/tetangga-bantu-tetangga";
    const { action } = useStateMachine(updateAction);
    const postStep1 = 
      (props.errorMessage.amount) ? true : false;
    const postStep2 = 
        (props.errorMessage.payment_type || props.errorMessage.donor_name || props.errorMessage.contact) ? true : false;    
    const { push } = useHistory();
    useEffect(() => {
        let urlpush;
        if(postStep1){
            urlpush = routeLink+"/step1";
        }else{
            if(postStep2){
                urlpush = routeLink+"/step2";
            }
        }
        if(urlpush){
            let data={};
            data['checkMessage'] = false;
            data['errorMessages'] = props.errorMessage;
            action(data);
            push({pathname:urlpush, props:{errorMessage:props.errorMessage}});
        }
        
    });
    return null
}

RedirectStep.defaultProps = {
    errorMessage: ''
}

export default RedirectStep
