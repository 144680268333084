import React, {Fragment} from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import {Link} from 'react-router-dom';
import { withRouter } from "react-router";
import { toast } from 'react-toastify';
import { ErrorMessage } from '@hookform/error-message';
import firebase from "../../../../config/Firebase"
import { GoogleLogin } from 'react-google-login';

import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';
import * as QueryString from "query-string";
import { Header } from '../../../organisms';
import {VerifyOTPGuest, SetPassword} from '../../../../containers/pages/Authorization'

import './style.css';

//Load Component
const FormRegister = props => {
    const { handleSubmit, register, errors } = useForm();
    return (
        <form className="form-hook mt-4 mb-2" onSubmit={handleSubmit(props.checkVerify)}>
            <div className="form-group">
                <label className="form-label">Nama Lengkap</label>
                <input
                type="text"
                name="name"
                className="form-control"
                ref={register({ required: "Nama Lengkap wajib diisi" })}
                onChange={props.handleChange}
                defaultValue={props.formPost.name}
                />
                <ErrorMessage errors={errors} name="name" as={<p className="error-input text-left"></p>} />
                {props.errorMessage.name && 
                <p className="error-input">{props.errorMessage.name}</p>}
            </div>
            <div className="form-group">
                <label className="form-label">Nomor Ponsel atau Email</label>
                <input
                type="text"
                name="contact"
                className="form-control"
                ref={
                    register({
                        required: "Nomor Ponsel atau Email wajib diisi",
                    })
                }
                onChange={props.handleChange}
                defaultValue={props.formPost.contact}
                />
                <ErrorMessage errors={errors} name="contact" as={<p className="error-input text-left"></p>} />
                {props.errorMessage.contact && 
                <p className="error-input">{props.errorMessage.contact}</p>}
            </div>

            <button className="btn btn-primary btn-block" type="submit" disabled={props.isLoading}>
                { (!props.isLoading) && <span>Selanjutnya</span> }
                { props.isLoading && <Spinner animation="border" role="status"/> }
            </button>

            <div className="txt-body mt-4">Dengan mendaftar, Anda dianggap menyetujui <Link to="/faq" className="fw-6">Syarat & Ketentuan</Link> serta <Link to="/faq" className="fw-6">Kebijakan Privasi</Link></div>
        </form>
    );
}

class Register extends React.Component {
    state = {
        formPost: {},
        isVerify:false,
        type:null,
        action:null,
        lowerType:null,
        setPassword:false,
        isPasswordShown:false,
        isPasswordConfirmShown:false,
        isLoadingFirebase: false,
        uidFireBase:''
    }
    uiConfig = {
        signInFlow: "popup",
        signInOptions: [
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        //   firebase.auth.FacebookAuthProvider.PROVIDER_ID
        ],
        callbacks: {
            signInSuccessWithAuthResult : () => false
        }
    }
    handleFormChange = (e) =>{
        let formPostNew = {...this.state.formPost};
        formPostNew[e.target.name] = e.target.value;

        let errorMessageNew = this.props.errorMessage;
        if(errorMessageNew.length > 0){
        }else{
            if(formPostNew[e.target.name]){
                errorMessageNew[e.target.name] = '';
            }
        }

        this.setState({
            formPost: formPostNew,
            errorMessage: errorMessageNew
        })
    }

    handleSubmit = async () => {
        this.setState({isVerify:false});
        const {history} = this.props;
        const newData =  {
            "name": this.state.formPost.name,
            "contact": this.state.formPost.contact,
            "password": this.state.formPost.password,
            "otp_code" : this.state.formPost.otp_code
        };
        const config = {
            method: 'post',
            url: '/auth/register/verify',
            headers: {
                'Content-Type': 'application/json',
            },
            data: newData
        };
        const res = await this.props.API(config).catch(err=>err);
        if (res) {
            toast.info('Registrasi berhasil, silahkan login', {
                position: "top-center",
                hideProgressBar: true,
                autoClose:3000,
            });
            setTimeout(() =>{
                history.push('/login');
            },1000 );
        }
    }

    checkVerify = async () => {
        let formData = { type: "Contact", content_type: "code", contact: this.state.formPost.contact, action:'register' };
        const config = {
            method: 'post',
            url: '/otp',
            headers: {
                'Content-Type': 'application/json'
            },
            data: formData
        };
        const res = await this.props.API(config).catch(err=>err);
        if (res) {   
            const type = res.data.type;
            const lowerType = (type.toLowerCase());
            let newFormPost = this.state.formPost;
            newFormPost[lowerType]= this.state.formPost.contact;
            this.setState({
                isVerify: true, 
                action:'register',
                type:type, 
                lowerType:lowerType, 
                code:res.data.code,
                formPost:newFormPost
            });
        }
    }

    togglePasswordVisibility = () => {
        const {isPasswordShown} = this.state;
        this.setState({isPasswordShown: !isPasswordShown});
    }
    togglePasswordConfirmVisibility = () => {
        const {isPasswordConfirmShown} = this.state;
        this.setState({isPasswordConfirmShown: !isPasswordConfirmShown});
    }

    redirectLogin = () => {
        const params = QueryString.parse(this.props.location.search);
        let url = '/profile';
        if(params.campaign){
            url = "/campaign/"+params.campaign;
        }
        if(params.donation){
            url = "/donation/"+params.donation+"/step2";
        }
        if(params.ref){
            url = "/"+params.ref;
        }
        if(params.prevPage){
            url = "/"+params.prevPage;
        }
        const {history} = this.props;
        history.push(url);
    }

    loginFirebase = async () => {
        this.setState({isLoadingFirebase:true});

        const config = {
            method: 'post',
            url: '/auth/login-firebase',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {uid:this.state.uidFireBase}
        };
        const res = await this.props.API(config).catch(err => err);
        if (res) {
            localStorage.setItem('userData', JSON.stringify(res.data));
            this.redirectLogin();
        }else{
            this.setState({isLoadingFirebase:false});
            firebase.auth().signOut().then(function() {
                // Sign-out successful.
            }).catch(function(error) {
            // An error happened.
            });
        }
    }

    responseGoogle = async (response) => {
        console.log(response);
        if(response.accessToken){
            const access_token = response.accessToken;
            console.log(access_token)

            this.setState({isLoadingFirebase:true});

            const config = {
                method: 'post',
                url: '/auth/login-google',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {accessToken: access_token}
            };
            const res = await this.props.API(config).catch(err => err);
            if (res) {
                localStorage.setItem('userData', JSON.stringify(res.data));
                this.redirectLogin();
            }else{
                this.setState({isLoadingFirebase:false});
                // firebase.auth().signOut().then(function() {
                //     // Sign-out successful.
                // }).catch(function(error) {
                // // An error happened.
                // });
            }
        }
    }

    nextStep = (resOtp) => {
        let newFormPost = this.state.formPost;
        newFormPost['otp_code']= resOtp.data;
        this.setState({isVerify:false, setPassword:true, formPost:newFormPost});
    }

    componentDidMount (){
        this.props.clearDispatch();
        if(localStorage.getItem("userData")){
            this.redirectLogin();
        }else{
            firebase.auth().onAuthStateChanged(user => {
                if(user){
                    this.setState({ uidFireBase: user.uid});
                    this.loginFirebase();
                }
                
            })
        }
        window.addEventListener('verifyOTP', this.hideContentOTP); 
    }
    componentWillUnmount() {
        window.removeEventListener('verifyOTP', this.hideContentOTP); 
    }
    hideContentOTP = () => {
        this.setState({isVerify:false});
    }
    hideContentPassword = () => {
        this.setState({isVerify:false, setPassword:false});
    }
  
    render() {
        const {isPasswordShown, isPasswordConfirmShown} = this.state;
        return(
            <Fragment>
                
                {!this.state.setPassword ? 
                <>
                    {!this.state.isVerify ?
                    <>
                    <Header title=" " prevPage="/login" headerclass="no-bs"/>
                    <main>
                        <Container className="container-login">
                            <div className="mb-3 text-left">
                                <div className="txt-title-m fw-6 mb-1">Salam Kenal</div>
                                <div><span className="txt-body">Sudah punya akun?</span> <Link to="/login" className="fw-6">Masuk</Link></div>
                            </div>
                            {!this.state.setPassword &&
                                <>
                                    <div className="container-firebase txt-body-m text-center">
                                        {/* <StyledFirebaseAuth
                                            uiConfig={this.uiConfig}
                                            firebaseAuth={firebase.auth()}
                                        /> */}
                                        <GoogleLogin
                                            clientId={process.env.REACT_APP_GOOGLE_APP_KEY}
                                            buttonText="Google"
                                            onSuccess={this.responseGoogle}
                                            onFailure={this.responseGoogle}
                                            cookiePolicy={'single_host_origin'}
                                            className="btn-google-login"
                                        />
                                        <div className="or txt-caption ct-body">atau Daftar dengan</div>
                                    </div>
                                    <FormRegister 
                                        handleChange={this.handleFormChange} 
                                        errorMessage={this.props.errorMessage}
                                        handleSubmit={this.handleSubmit}
                                        checkVerify={this.checkVerify}
                                        formPost = {this.state.formPost}
                                        isLoading={this.props.isLoading}
                                    />
                                </>
                            }
                        </Container>
                    </main>
                    </>
                    :
                    <VerifyOTPGuest 
                        title={"Verifikasi Pendaftaran"}
                        type={this.state.lowerType} 
                        contact={this.state.formPost.contact} 
                        action={this.state.action}
                        nextStep={this.nextStep}
                        code={this.state.code}
                        hideContentOTP={this.hideContentOTP}
                        sendPost={({type:this.state.type, contact:this.state.formPost.contact, action:this.state.action})}
                    />
                    }
                </>
                :

                    <SetPassword 
                        handleChange={this.handleFormChange} 
                        errorMessage={this.props.errorMessage}
                        handleSubmit={this.handleSubmit}
                        formPost = {this.state.formPost}
                        isLoading={this.props.isLoading}
                        isPasswordShown={isPasswordShown}
                        hideContentPassword={this.hideContentPassword}
                        isPasswordConfirmShown={isPasswordConfirmShown}
                        togglePasswordVisibility = {this.togglePasswordVisibility}
                        togglePasswordConfirmVisibility = {this.togglePasswordConfirmVisibility}
                        title="Buat Kata Sandi"
                        btnName="Daftar"
                    />
                }
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Register));
