import React, {useEffect,Fragment} from 'react';
import {
  Route
} from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';
import * as QueryString from "query-string";
import { useHistory } from "react-router-dom";

//Load Component
import ScrollToTop from '../../../../config/Routes/ScrollToTop';

import { StateMachineProvider, createStore, useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";
import Loading from "./Loading";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Result from "./Result";
import postData from "./postData";

import "../../../../config/FormWizard/style.css";
import "./style.css";

createStore({
  postData: {
    step1:false,
    step2:false,
    step3:false,
    step4:false,
    editCampaignSlug:'',
    editCampaigner: '',
    checkMessage:false,
    errorMessages:{},
  }
});

const Pages = (props) => {
  const { state, action } = useStateMachine(updateAction);
  const queryParams = (props.queryParams.edit ? ('?edit='+props.queryParams.edit) : '');

  const { push } = useHistory();
  useEffect(() => {
    if(queryParams !== ''){
      if(localStorage.getItem("userData")){
        if(props.uri === 'verifikasi-personal'){
          const auth = JSON.parse(localStorage.getItem("userData"));
          const detailCampaigner = auth.campaigner
          let data = {};
          data['name'] = detailCampaigner.name;
          data['slug'] = detailCampaigner.slug;
          data['email'] = detailCampaigner.email;
          data['phone'] = detailCampaigner.phone;

          data['provinsi'] = (detailCampaigner.areas.province_id ? detailCampaigner.areas.province_id.id : null);
          data['kota'] = (detailCampaigner.areas.city_id ?  detailCampaigner.areas.city_id.id : null);
          data['area_id'] = detailCampaigner.area_id;

          data['identity'] = detailCampaigner.filenames.identity;
          data['identity_face'] = detailCampaigner.filenames.identity_face;
          data['tax'] = detailCampaigner.filenames.tax;
          data['base64'] = {identity:detailCampaigner.identity, identity_face:detailCampaigner.identity_face, tax:detailCampaigner.tax};

          data['editCampaigner'] = queryParams;
          data['editCampaignerSlug'] = props.queryParams.edit;
          data['step1'] = true;
          data['step2'] = true;
          data['step3'] = true;
          data['step4'] = true;
          action(data);
          
          if(!state.postData.editCampaigner && !state.postData.editCampaignerSlug){
            apiGetArea('Provinsi');
            if(detailCampaigner.areas.provinsi_id != null){
                apiGetArea('Kota', data['provinsi']);
            }
          }
          let pushPage = false;
          if(!detailCampaigner.coverage_area){
            pushPage = true;
          }
          if(detailCampaigner.coverage_area === 'Provinsi'){
            if(state.postData.dataKota){
              pushPage = true;
            }
          }
          if(detailCampaigner.coverage_area === 'Kota'){
            if(state.postData.dataKecamatan){
              pushPage = true;
            }
          }

          if(pushPage){
            push("/verifikasi-personal/step1"+queryParams);
          }
          
        }
      }
    }else{
      
      if(props.uri === 'verifikasi-personal'){
        if(!state.postData.dataProvinsi){
            apiGetArea('Provinsi');
        }
        if(state.postData.editCampaigner && state.postData.editCampaignerSlug){
          action(postData);
        }
      }

    }
  });

  const apiGetArea = async (type, parent=null) => {
    const auth = JSON.parse(localStorage.getItem("userData"));
    const token = `Bearer ${auth.access_token}`;

    let params = '?type='+(type === 'Kota' ? 'Kabupaten/Kota' : type);
    params += ((type === 'Kota' || type === 'Kecamatan') ? '&parent='+parent : '');
    const config = {
        method: 'get',
        url: '/area'+params,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    };
    const res = await props.propsAPi(config, false).catch(err=>err);
    if (res) {
        let data = {};
        data['load'+type] = true;
        data['data'+type] = res.data.data;
        action(data);
    }
  }
  return (
    <>
      <Route exact path="/verifikasi-personal"  component={(queryParams === '' ? Step1 : Loading)} />
      <Route exact path="/verifikasi-personal/step1" component={Step1} />
      <Route exact path="/verifikasi-personal/step2" component={Step2} />
      <Route exact path="/verifikasi-personal/step3" component={Step3} />
      <Route exact path="/verifikasi-personal/step4" component={Step4} />
      <Route exact path="/verifikasi-personal/result" component={Result} />
    </>
  );
};

class VerifikasiPersonal extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            auth: {}
        }
    }
    componentDidMount() {
        this.props.clearDispatch();
        if(localStorage.getItem("userData")){
            const auth = JSON.parse(localStorage.getItem("userData"))
            this.setState ({ auth: auth  });
        }else{
            const {history} = this.props;
            history.push('/login');
        }
    }
    render() {
        const queryParams = QueryString.parse(this.props.location.search);
        const url = this.props.location.pathname;
        const pathArr = url.split('/');
        const uri = pathArr[pathArr.length - 1];
        return(
            <Fragment>
              <main>
                  <div className="container verifikasi-personal">
                    <StateMachineProvider store={createStore}>
                        <ScrollToTop />
                        <Pages queryParams={queryParams} uri={uri} propsAPi={this.props.API} />
                    </StateMachineProvider>
                  </div>
              </main>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
  errorMessage: state.errorMessage,
})

const mapDispatchToProps = (dispatch) => ({
  clearDispatch: () => dispatch(clearDispatch()),
  API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifikasiPersonal));