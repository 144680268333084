import React from "react";
import {Image, Container} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { Header } from '../../../containers/organisms';
import imgNotFound from '../../../assets/img/not-found.png';

import './style.css';
class NotFound extends React.Component {
  state = {}
  
  componentDidMount (){
  }

  render() {
    return(
      <>
      <Header splash={true}/>
      <main>  
        <Container >
          <div className="section text-center pb-0">
            <Image src={imgNotFound} className="w-100"/>
            <div className="txt-subtitle-m fw-6 mt-4 mb-2">Anda Sepertinya Tersesat...</div>
            <div className="txt-body-m mb-5">Apa yang Anda cari tidak tersedia di sini.<br/>Silakan kembali ke Beranda.</div>
            <Link to="" className="btn btn-secondary btn-block">Kembali</Link>
          </div>
        </Container>
      </main>
      </>
    )
  }
}

export default NotFound;
