import React from 'react'
import CurrencyFormat from 'react-currency-format';

const Suggest =  (props) => {
    return (
        <div className="col-6">
            <div className={"suggest-donate "+(props.amount > 0 && props.amount === props.nominal ? 'sd-active' : '')} onClick={() => props.handleSuggestion(props.nominal)}>
                <div className={"content-flex"}>
                    <div>
                        <CurrencyFormat 
                            value={props.nominal} 
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            suffix={' Kg'} 
                            displayType={'text'}
                            className="amount"
                        />
                    </div>
                    <div>
                        {props.desc && 
                            <div className="desc txt-caption-m">{props.desc}</div>
                        }
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default Suggest
