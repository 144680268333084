import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import debounce from "lodash.debounce";
import { Container, Image,Modal } from "react-bootstrap";

import IcPlusHeader from '../../../../assets/svg/ic-plus-header.svg';
import icClose from '../../../../assets/svg/ic-close.svg';
import icCloseModal from '../../../../assets/svg/ic-close-modal.svg';
import icChevronDown from '../../../../assets/svg/ic-chevron-down.svg';
import { clearDispatch, API } from '../../../../config/redux/action';
import { Header, DonationCard, Filter, FilterDate, Confirm } from "../../../organisms";
import { Input } from "../../../../component/atoms";
import Loading from './Loading';
import imgCampaignNotFound from '../../../../assets/img/campaign-not-found.png';
import { toast } from 'react-toastify';

import './style.css'

const ModalCardOptions =  (props) => {
    const { show, onHide, donationCode, confirmDelete } = props
    return (
        <Modal show={show} onHide={onHide} animation={false} centered className="modal-bottom">
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">
                    <Image src={icCloseModal} onClick={onHide} className="cursor-pointer" /> 
                    <span className="txt-title">Lainnya</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="no-border">

                    <div className="list-option" onClick={()=>confirmDelete()}>
                        <div>Batalkan Donasi</div>
                    </div>
                    <Link className="list-option" to={'/mydonate/'+donationCode}>
                        <div>Panduan Pembayaran</div>
                    </Link>
                    <Link className="list-option" to="/faq">
                        <div>Bantuan</div>
                    </Link>
                </div>
            </Modal.Body>
        </Modal>
    )
}

class MyDonasi extends React.Component {
    state = {
        token: null,
        /** Filter */
        filterSearch: '',
        filterStatus: '',
        filterKategori: '',
        
        /** Summary state */
        summary: {},
        isFetchingSummary: false,

        /** Results state */
        results: [],
        isFetchingResults: false,
        currentPage: 1,
        total: -1,

        categories:[],

        /** Filter */
        states:[
            {
                name:'Berhasil',
                slug:'paid'
            },
            {
                name:'Pending',
                slug:'pending'
            },
            {
                name:'Dibatalkan',
                slug:'expired'
            },
        ],
        
        showConfirm:false,
        showFilterStatus:false,
        showFilterKategori:false,
        showFilterTanggal:false,
        showCardOptions:false,
        startDate:false,
        endDate:false,
        donationCode:false,
    }
    constructor(props) {
        super(props)
        this.handleScroll = this.handleScroll.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.resetKeyword = this.resetKeyword.bind(this)
        this.resetResults = this.resetResults.bind(this)
        this.resetResults = debounce(this.resetResults, 500)

        this.handleFilterStatus = this.handleFilterStatus.bind(this)
        this.handleFilterKategori = this.handleFilterKategori.bind(this)
        this.handleFilterTanggal = this.handleFilterTanggal.bind(this)
        this.handleOptionsCard = this.handleOptionsCard.bind(this)
        this.confirmDelete = this.confirmDelete.bind(this)
        this.donationCancel = this.donationCancel.bind(this)

        this.state.auth = JSON.parse(localStorage.getItem("userData"));
        this.state.token = this.state.auth && this.state.auth.access_token ? `Bearer ${this.state.auth.access_token}` : null;
    }

    onHideModal(container){
        switch (container) {
            case 'status':
                this.setState({ showFilterStatus: false })
                break;
            case 'kategori':
                this.setState({ showFilterKategori: false })
                break;
            case 'tanggal':
                this.setState({ showFilterTanggal: false })
                break;
            case 'cardOptions':
                this.setState({ showCardOptions: false })
                break;
            case 'confirm':
                this.setState({ showConfirm: false })
                break;
            default:
                break;
        }
    }
    handleSearch(e) {
        this.setState({ filterSearch: e.currentTarget.value })
        if(e.currentTarget.value.length > 1 || e.currentTarget.value.length === 0) {
            this.resetResults()
        }
    }
    handleFilterStatus(status) {
        this.setState({ filterStatus: status })
        // this.resetResults()
    }
    handleFilterKategori(kategori) {
        this.setState({ filterKategori: kategori })
        // this.resetResults()
    }
    handleFilterTanggal(tanggal) {
        this.setState({ filterTanggal: tanggal })
        if(tanggal === 30 || tanggal === 90){
            let day = new Date();
            let pastDay = new Date(day);
            pastDay.setDate(day.getDate() - tanggal);
            let startDate = new Date(pastDay).toISOString()
            let endDate = new Date().toISOString()
            this.setState({startDate: startDate, endDate: endDate})
        }
    }
    handleOptionsCard(code){
        this.setState({donationCode:code, showCardOptions:true})
    }

    confirmDelete(){
        this.setState({showConfirm:true })
        this.onHideModal('cardOptions');
    }

    async donationCancel(){
        const code = this.state.donationCode
        const newData =  {
            "code": code
        };
        const config = {
            method: 'post',
            url: '/user/donation/cancel',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.state.auth.access_token}`
            },
            data: newData
        };
        const res = await this.props.API(config).catch(err=>err);
        if (res) {
            toast.info('Donasi berhasil dibatalkan', {
                position: "top-center",
                hideProgressBar: true,
                autoClose:3000,
            });
            setTimeout(() =>{
                this.resetResults()
            },1000 );
        }
    }

    resetKeyword(e) {
        this.setState({ filterSearch: '' })
        this.resetResults()
    }
    resetResults() {
        this.setState({ 
            isFetchingResults: true, 
            currentPage: 1, 
            total: -1, 
            results: [],
            showFilterStatus:false,
            showFilterKategori:false,
            showFilterTanggal:false,
            showCardOptions:false,
            showConfirm:false,
        })
    }
    async loadCategories() {
        const config = {
            method: 'get',
            url: '/category',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            }
        };
        const res = await this.props.API(config, false).catch(err=>err)
        if (res) {
            this.setState({ categories: res.data.data })
        }
    }
    async apiResult() {
        const perPage = 5;
        let params = `?page=${this.state.currentPage}&per_page=${perPage}`
        if(this.state.filterStatus) {
            params += `&status=` + this.state.filterStatus
        }
        if(this.state.filterKategori) {
            params += `&category_slug=` + this.state.filterKategori
        }
        if(this.state.filterTanggal && this.state.startDate && this.state.endDate) {
            params += `&date_range=` + this.state.startDate +','+this.state.endDate
        }
        if(this.state.filterSearch.length > 1) {
            params += `&str=` + this.state.filterSearch
        }

        const { API } = this.props
        const config = {
            method: 'get',
            url: '/user/donations/me' + params,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            }
        };
        const res = await API(config, false).catch(err=>err)
        if (res) {
            this.setState({ 
                results: [ ...this.state.results, ...res.data.data ], 
                isFetchingResults: false,
                total: res.data.total,
                currentPage: this.state.currentPage + 1
            })

            if(window.innerHeight > 640 && this.state.results.length <= perPage)
                this.setState({ isFetchingResults: true })
        }
    }


    checkdate = (date, type) => {
        let newDate = new Date(date).toISOString();
        if(type === 'startDate'){
            this.setState({startDate: newDate})
        }else{
            this.setState({endDate: newDate})
        }
    }

    formatDate = (date) => {
        let d = (date ? new Date(date) : new Date());
        let mm = d.getMonth() + 1;
        let dd = d.getDate();
        let yy = d.getFullYear(); 
        if(mm < 10)
          mm = '0' + mm
        let myDateString = yy + '-' + mm + '-' + dd;
        return myDateString;
    }

    handleScroll() {
        const isScrolled = document.documentElement.offsetHeight - Math.ceil(window.innerHeight + document.documentElement.scrollTop);
        if (isScrolled > 150 || this.state.isFetchingResults)
            return;
        this.setState({isFetchingResults: true})
    }
    componentDidMount() {
        if(!this.state.auth){
            const {history} = this.props;
            history.push('/login?prevPage=mydonate');
        }

        window.addEventListener('scroll', this.handleScroll);
        this.setState({ isFetchingResults: true })
        this.loadCategories();
    }
    componentDidUpdate(_, prevState) {
        if(this.state.isFetchingResults !== prevState.isFetchingResults) {
            const { isFetchingResults, results, total, filterSearch, filterStatus, filterKategori, filterDate, startDate, endDate } = this.state;

            if ( filterSearch !== prevState.filterSearch || filterStatus !== prevState.filterStatus 
                || filterKategori !== prevState.filterKategori || filterDate !== prevState.filterDate 
                || startDate !== prevState.startDate || endDate !== prevState.endDate 
                ) {
                this.apiResult()
                return
            }

            if (isFetchingResults === false || (results.length === total && total !== -1)) {
                this.setState({isFetchingResults: false});
                return;
            }
            this.apiResult();
        }
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll); 
    }
    render() {
        return (
            <React.Fragment>
                <Header title="Donasi Saya" prevPage="/profile" cols={3} position3={IcPlusHeader} linkPost3="/campaign?prevPage=mydonate" />
                <main className="my-donate">
                    <div className="filter search-page">
                        <div className="section">
                            <Input 
                                type="text" 
                                name="search"
                                formGroupClass="search-form txt-body" 
                                placeholder="Cari Campaign..." 
                                handleFormChange={this.handleSearch}
                                value={this.state.filterSearch}
                                icon_left="assets/img/ic-search-gray.svg"
                                icon_right={(this.state.filterSearch ? icClose : '')}
                                icon_right_click = {this.resetKeyword}
                            />
                            <ul className="modal-filter">
                                <li className={(this.state.filterStatus === '' ? '' : 'active')} onClick={() => this.setState({showFilterStatus:true})}>
                                    <div>Status </div>
                                    <Image src={icChevronDown}/>
                                </li>
                                <li className={(this.state.filterKategori === '' ? '' : 'active')} onClick={() => this.setState({showFilterKategori:true})}>
                                    <div>Kategori </div>
                                    <Image src={icChevronDown}/>
                                </li>
                                <li className={(this.state.filterTanggal && this.state.startDate && this.state.endDate ? 'active' : '')} onClick={() => this.setState({showFilterTanggal:true})}>
                                    <div>Waktu </div>
                                    <Image src={icChevronDown}/>
                                </li>
                                
                            </ul>
                        </div>
                        <div className="content-space"></div>
                    </div>

                    <Container className="my-donate-result">
                        {this.state.results.map((donation, i) => {
                            return (
                                <DonationCard key={i} donation={donation} handleOptions={this.handleOptionsCard} />
                            )
                        })}
                        {this.state.isFetchingResults && <Loading /> }
                        {!this.state.isFetchingResults && this.state.results.length === 0 &&
                            // {this.state.results.length > 0 &&
                            //     <p className="text-center mt-3 mb-3">Menampilkan {this.state.results.length} dari {this.state.total}</p>
                            // }
                            <div className="search-not-found mt-20">
                                <div className="text-center">
                                    <Image src={imgCampaignNotFound}/>
                                    <div className="txt-subtitle">Masih Kosong...</div>
                                    <div className="txt-body">Mulai donasi dan bantu saudara kita sekarang juga</div>
                                    <Link to="/campaign" className="btn btn-secondary btn-block mt-20">Donasi Sekarang</Link>
                                </div>
                            </div>
                        }
                    </Container>
                    <Filter 
                        onHide={() => this.onHideModal('status')} 
                        field={this.state.filterStatus} 
                        handleFilter={this.handleFilterStatus} 
                        show={this.state.showFilterStatus}
                        resetResults={this.resetResults}
                        options={this.state.states}
                        label="Status"
                    />
                    <Filter 
                        onHide={() => this.onHideModal('kategori')} 
                        field={this.state.filterKategori} 
                        handleFilter={this.handleFilterKategori} 
                        show={this.state.showFilterKategori}
                        resetResults={this.resetResults}
                        options={this.state.categories}
                        label="Kategori"
                    />
                    <FilterDate 
                        onHide={() => this.onHideModal('tanggal')} 
                        filter={this.state.filterTanggal} 
                        handleFilter={this.handleFilterTanggal} 
                        show={this.state.showFilterTanggal}
                        resetResults={this.resetResults}
                        checkdate={this.checkdate}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                    />
                    <ModalCardOptions
                        onHide={() => this.onHideModal('cardOptions')} 
                        show={this.state.showCardOptions}
                        donationCode={this.state.donationCode}
                        confirmDelete={this.confirmDelete}
                    />
                    <Confirm
                        onHide={() => this.onHideModal('confirm')} 
                        show={this.state.showConfirm}
                        action={this.donationCancel}
                        title="Batalkan Donasi?"
                        body="Kebaikan Anda jangan sampai terhenti sampai disini"
                        label_cancel="Kembali"
                        label_yes="Batalkan"
                        cancelModal={() => this.setState({showConfirm:false, showCardOptions:true})}
                    />
                </main>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyDonasi));