import React from 'react'

import { ProgressBar, Image } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { Link } from 'react-router-dom'
import icVerified from '../../../assets/svg/ic-verified-campaigner.svg';
import icFoundation from '../../../assets/svg/ic-foundation-small.svg';
import icCommunity from '../../../assets/svg/ic-community-small.svg';
import LazyLoad from 'react-lazyload';

import './style.css'

export default function FavouriteCampaignCard({ campaign, classes }) {
    const defaultClasses = {
        root: 'favourite-card',
        cardImage: 'favourite-card-image',
        cardInfo: 'favourite-card-info',
        cardName: 'favourite-card-name',
        campaignerName:'favorite-card-campaigner-name',
        progressInfo: 'favourite-card-progress-info',
        progressPercentage: 'favourite-card-progress-percentage',
        progressNominal: 'favourite-card-progress-nominal',
        progressInfoLeft: 'favourite-card-progress-info-left',
        progressInfoRight: 'favourite-card-progress-info-right',
    }

    const ovClasses = { ...defaultClasses, ...classes ? classes : {} }

    return (
        <Link className={ovClasses.root} to={"/campaign/"+campaign.slug}>
            <LazyLoad>
                <img className={ovClasses.cardImage} src={campaign.cover} alt={campaign.title} />
            </LazyLoad>
            <div className={ovClasses.cardInfo}>
                <div className={ovClasses.cardName}>{campaign.title}</div>
                <div className={ovClasses.progressInfo}>
                    {campaign.campaigner.name &&
                        <div className="content-flex">
                            <div className={ovClasses.campaignerName}>
                                {campaign.campaigner.name}
                            </div>
                            {campaign.campaigner.verified &&
                                <div>
                                    <LazyLoad className="icon-verified-fav">
                                        <Image src={icVerified}/>
                                    </LazyLoad>
                                </div>
                            }
                            {campaign.campaigner.is_foundation &&
                                <div><Image src={icFoundation} className="ml-1 icon-lembaga"/></div>
                            }
                            {campaign.campaigner.is_community &&
                                <div><Image src={icCommunity} className="ml-1 icon-lembaga"/></div>
                            }
                        </div>
                    }
                    <ProgressBar className={ovClasses.progressPercentage} now={campaign.percentage} />
                    <div className={ovClasses.progressNominal}>
                        <div className={ovClasses.progressInfoLeft}>
                            <p>
                                <CurrencyFormat 
                                    value={campaign.funds} 
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    prefix={'Rp '} 
                                    displayType={'text'}
                                />
                            </p>
                        </div>
                        <div className={ovClasses.progressInfoRight}>
                            <p>{campaign.day_left} Hari</p>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}