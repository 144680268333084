import React from "react";
import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";
import postData from './postData';


const Result = props => {
  const { state, action } = useStateMachine(updateAction);

  function resetstate(){
    action(postData);
  }

  return (
    <div className="container">
      <h2>Result</h2>
      <pre>{JSON.stringify(state, null, 2)}</pre>
      <button onClick={resetstate}>Reset</button>
    </div>
  );
};

export default Result;
