import React from 'react'
import {Image} from 'react-bootstrap'
import CurrencyFormat from 'react-currency-format';
import icDonaturAnonim from '../../../../assets/svg/ic-donatur-anonim.svg';
import './style.css'

import TimeAgo from 'timeago-react';
import * as timeago from 'timeago.js';
import id_ID from 'timeago.js/lib/lang/id_ID';

const SliderDonors =  (props) => {
    timeago.register('id_ID', id_ID);
    return (
        <div className="slide-wrap">
            <div className="slide-donate">
                <div className="content-grid content-avatar">
                    <div>
                        <div className="wrapper-img">
                            <Image src={(props.data.avatar && props.data.donor_name !== 'Anonim'  ? props.data.avatar : icDonaturAnonim)}/>
                        </div>
                    </div>
                    <div className="text-left">
                        <div className="txt-body-m str-limit str-limit-1">{props.data.donor_name === "Anonim" ? "Tetangga Baik" : props.data.donor_name}</div>
                        <CurrencyFormat 
                            value={props.data.amount} 
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={'Rp '} 
                            className="txt-body-m ct-primary1 fw-6 str-limit str-limit-1"
                            displayType={'text'}
                        />
                        <div className="txt-caption-m str-limit str-limit-1">
                            <TimeAgo datetime={props.data.time} locale='id_ID'/>
                        </div>
                    </div>
                </div>
                <div className="txt-body-m mt-2 str-limit str-limit-3">{props.data.support}</div>
            </div>
        </div>
    )
}

export default SliderDonors
