import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router";
import {Link} from 'react-router-dom';
import queryString from 'query-string'

import { Header } from '../../../organisms';
import { Container, Spinner } from 'react-bootstrap';
import { API } from '../../../../config/redux/action';

export class VerifyEmail extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            isLoading:false
        }
    }

    sendEmailLink = async () => {
        this.setState({isLoading:true})
        let formData = {type:"Email"};
        const auth = JSON.parse(localStorage.getItem("userData"));
        const access_token = `Bearer ${auth.access_token}`;
        const config = {
            method: 'post',
            url: '/otp',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': access_token
            },
            data: formData
        };

        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            this.setState({isLoading:false})
        }else{
            this.setState({isLoading:false})
            const {history} = this.props;
            setTimeout(() =>{
                history.push('/edit-profile');
            },1000 );
        }
    }

    checkingEmailVerified = async (token, otp) => {
        this.setState({isLoadingChecking:true})
        let formData = {type:"Email", otp:otp};
        const access_token = `Bearer ${token}`;
        const config = {
            method: 'post',
            url: '/otp/validation',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': access_token
            },
            data: formData
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            this.setState({isLoadingChecking:false})
        }else{
            this.setState({isLoadingChecking:false})
            const {history} = this.props;
            setTimeout(() =>{
                history.push('/edit-profile');
            },1000 );
        }
    }
    
    componentDidMount = () => {
        if(localStorage.getItem("userData")){
            const auth = JSON.parse(localStorage.getItem("userData"));
            const queryParams = queryString.parse(this.props.location.search)
            const otp = queryParams.otp;
            if(otp){
                this.setState({isChecking:true});
                this.checkingEmailVerified(auth.access_token, otp);
            }else{
                this.sendEmailLink();
            }
        }else{
            const {history} = this.props;
            history.push('/login');
        }
    }
    render() {
        const {isChecking,isLoadingChecking, isLoading} = this.state;
        return (
            <main>
                <Header title="Email Verification" prevPage="/edit-profile"/>
                <Container className="pt-4 text-middle">
                    {!isChecking &&
                    
                    
                        <div>
                            { !isLoading &&
                                <div>Kami Telah mengirimkan Email berisi link untuk memverifikasi Email anda. </div>
                            }
                            { isLoading &&
                                <div>
                                    <Spinner animation="border"/>
                                    <p className="mt-2">Sedang mengirim email</p>
                                </div>
                            }
                        </div>
                    }

                    {isChecking &&
                    
                        <div>

                            { !isLoadingChecking &&
                                <div>
                                    <div className="pb-2">Email anda berhasil diverifikasi</div>
                                    <Link to="/edit-profile">Lihat Profil</Link>
                                </div>
                            }
                            { isLoadingChecking &&
                                <div>
                                    <Spinner animation="border"/>
                                    <p className="mt-2">Sedang mengecek data</p>
                                </div>
                            }
                        </div>
                    }

                </Container>
            </main>
        )
    }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
    API: (config, isDispatch) => dispatch(API(config, isDispatch))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyEmail));
