import React from 'react'
import { Helmet } from "react-helmet-async";

export default function BuildHelmet ({ meta, children }) {
    return (
        <>
            <Helmet>
                <title>{meta.title}</title>
                <meta name="msapplication-TileColor" content={meta.titleColor}/>
                <meta name="msapplication-TileImage" content={meta.titleImage}/>
                <meta name="msapplication-square310x310logo" content={meta.squareLogo}/>
                <meta name="twitter:app:name:googleplay" content={meta.appname}/>
                <meta name="twitter:site" content={meta.twitter}/>
                <meta name="twitter:label2" content={meta.twitterLabel}/>
                <meta property="og:url" content={meta.url}/>
                <meta property="og:image" content={meta.titleImage}/>
                <meta property="og:title" content={meta.title}/>
                <meta property="al:ios:url" content={meta.url}/>
                <meta property="og:description" content={meta.description}/>
                <meta name="description" content={meta.description}/>
                <meta name="twitter:description" content={meta.description}/>
                <script>
                    {`
                        !function (w, d, t) {
                            w.TiktokAnalyticsObject = t; var ttq = w[t] = w[t]; ttq.methods = ["page", "track", "identify", "instances", "debug", "on", "off", "once", "ready", "alias", "group", "enableCookie", "disableCookie"], ttq.setAndDefer = function (t, e) { t[e] = function () { t.push([e].concat(Array.prototype.slice.call(arguments, 0))) } }; for (var i = 0; i < ttq.methods.length; i++)ttq.setAndDefer(ttq, ttq.methods[i]); ttq.instance = function (t) { for (var e = ttq._i[t], n = 0; n < ttq.methods.length; n++)ttq.setAndDefer(e, ttq.methods[n]); return e }, ttq.load = function (e, n) { var i = "https://analytics.tiktok.com/i18n/pixel/events.js"; ttq._i = ttq._i || {}, ttq._i[e] = [], ttq._i[e]._u = i, ttq._t = ttq._t || {}, ttq._t[e] = +new Date, ttq._o = ttq._o || {}, ttq._o[e] = n || {}; var o = document.createElement("script"); o.type = "text/javascript", o.async = !0, o.src = i + "?sdkid=" + e + "&lib=" + t; var a = document.getElementsByTagName("script")[0]; a.parentNode.insertBefore(o, a) };
                        
                            ttq.load('C1QK7R9LRI5O97LI9U80');
                            ttq.page();
                        }
                    `}
                </script>
            </Helmet>
            {children}
        </>
    )
}