import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useHistory, Link } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";
import { Nav, Image, Spinner, Container, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { Header } from "../../../organisms";

import "react-datepicker/dist/react-datepicker.css";
import CurrencyFormat from 'react-currency-format';

import { withRouter } from "react-router";
import { connect } from "react-redux";
import { clearDispatch, API } from "../../../../config/redux/action";
import avatar from "../../../../assets/img/avatar/no-img.png";
import RedirectStep from "./RedirectStep";
import Script from "react-load-script";
import midtrans from "../../../../config/Midtrans";
import { FbPixel } from "../../../../config/Tracker/FbPixel";
import icArrowRight from "../../../../assets/svg/ic-arrow-right-gray.svg";
import { removeSymbol, validateEmail, validatePhone } from "../../../../utils";
import { Helmet } from "react-helmet-async";
import {v4 as uuidv4} from 'uuid';

const Step2 = (props) => {
  const detailCampaign = JSON.parse(localStorage.getItem("detailCampaign"));
  const linkPrev = "/donation/" + detailCampaign.slug + "/step1";
  const nextLink = "/donation/" + detailCampaign.slug;
  const { state, action } = useStateMachine(updateAction);
  const auth = JSON.parse(localStorage.getItem("userData"));
  const { handleSubmit, errors, register, clearErrors, setError, setValue } =
    useForm({
      defaultValues: state.postData,
    });
  const { push } = useHistory();
  const onSubmit = (data) => {
    if (state.postData.typeof_payment_type !== "transfer") {
      FbPixel("InitiateCheckout");
    }
    submitForm();
  };
  const FbPixell = FbPixel;
  useEffect(() => {
    FbPixell("AddPaymentInfo");
  }, [FbPixell]);

  const submitForm = async () => {
    let formData = {
      campaign_slug: detailCampaign.slug,
      amount: state.postData.amount,
      is_anonim: state.postData.is_anonim ? state.postData.is_anonim : false,
      support: state.postData.support,
      payment_type: state.postData.payment_type,
      donor_name: auth ? auth.name : state.postData.donor_name,
      contact: auth
        ? auth.email
          ? auth.email
          : auth.phone
        : state.postData.contact,
    };
    localStorage.setItem('nama-lengkap-donasi', auth ? auth.name : state.postData.donor_name);
    localStorage.setItem('contact-donasi', auth ? auth.email ? auth.email : auth.phone : state.postData.contact);
    localStorage.setItem('payment-icon-donasi', state.postData.icon_payment_type);
    localStorage.setItem('payment-type-donasi', state.postData.payment_type);
    localStorage.setItem('payment-title-donasi', state.postData.title_payment_type);
    localStorage.setItem('typeof_payment_type-donasi', state.postData.typeof_payment_type);
    window.sessionStorage.setItem('paymentMethod', state.postData.title_payment_type);
    if (localStorage.getItem("cacheParamUTM")) {
      const paramUTM = JSON.parse(localStorage.getItem("cacheParamUTM"));

      Object.keys(paramUTM).forEach(function (field) {
        formData[field] = paramUTM[field];
      });
    }

    let config = {
      method: "post",
      url: "/public/donation",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    if (auth) {
      const token = `Bearer ${auth.access_token}`;
      config["headers"]["Authorization"] = token;
    }

    const res = await props.API(config).catch((err) => err);
    if (res) {
      let data = {};
      data["resultDonation"] = res.data;

      switch (state.postData.typeof_payment_type) {
        case "midtrans":
          action(data);
          // push(nextLink+"/step2");
          break;

        case "transfer":
          data["stepdon2"] = true;
          data["stepdon3"] = true;
          action(data);
          setTimeout(() => {
            window.location.assign(nextLink + "/step3");
          }, 1000);
          break;

        case "faspay":
          data["stepdon2"] = true;
          data["stepdon3"] = true;
          action(data);
          window.location.href = res.data.faspay_redirect_url;
          break;

        default:
          break;
      }
    } else {
      let data = {};
      data["checkMessage"] = true;
      action(data);
    }
  };

  const handleChange = (e) => {
    let val = e.target.value;
    let field = e.target.name;
    let data = {};
    data[field] = val;
    data["stepdon2"] = false;
    if (e.target.type === "checkbox") {
      if (!e.target.checked) {
        data["is_anonim"] = false;
      } else {
        data["is_anonim"] = true;
      }
    }
    if (field === "payment_type") {
      data["typeof_payment_type"] =
        e.target[e.target.selectedIndex].getAttribute("data-type");
    }
    if (field === "contact") {
      if (val.includes("@") || val.includes(".")) {
        const isEmailValid = validateEmail(val);
        if (isEmailValid) {
          clearErrors("contact");
        } else {
          setError("contact", {
            type: "manual",
            message: "Isi dengan nomor Email yang benar",
            shouldFocus: true,
          });
        }
      } else {
        const valuePhone = removeSymbol(val);
        setValue("contact", valuePhone);
        data.contact = valuePhone;
        const isPhoneValid = validatePhone(valuePhone);
        if (isPhoneValid && val && val !== "12345678") {
          clearErrors("contact");
        } else if (val === "") {
          clearErrors("contact");
        } else {
          setError("contact", {
            type: "manual",
            message: "Isi dengan nomor HP yang benar",
            shouldFocus: true,
          });
        }
      }
    }

    console.log({ data });

    action(data);
  };

  const checkDonasi = async (codeDonate) => {
    const config = {
      method: "get",
      url: "/donation/detail/" + codeDonate,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await props.API(config, false).catch((err) => err);
    if (res) {
      if (res.data.status === "paid") {
        push("/donation/result/success?code=" + codeDonate);
      } else {
        push("/bukti-donasi/" + codeDonate + "?prevPage=campaign");
      }
    }
  };

  const handleScriptLoad = () => {
    const snapToken = state.postData.resultDonation.snapToken;
    const code = state.postData.resultDonation.code;
    let data = {};
    data["resultDonation"] = { snapToken: false };
    data["stepdon2"] = true;
    data["stepdon3"] = true;
    action(data);
    window.snap.pay(snapToken, {
      onSuccess: function (result) {
        checkDonasi(code);
      },
      onPending: function (result) {
        checkDonasi(code);
      },
      onError: function (result) {
        data["stepdon2"] = false;
        data["stepdon3"] = false;
        action(data);
        checkDonasi(code);
      },
      onClose: function () {
        data["stepdon2"] = false;
        data["stepdon3"] = false;
        action(data);
      },
      gopayMode: state.postData.title_payment_type === "QRIS" ? "qr" : "auto",
    });
  };
  let myuuid = uuidv4();

  return (
    <>
      <Header title={detailCampaign.title} prevPage={linkPrev} />
      <Helmet>
        <script>
          {
            `window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              "event":"donationCheckout",
              "purchase": ${state.postData.resultDonation.total_donation === undefined ? state.postData.amount : state.postData.resultDonation.total_donation},
              "uuid": "${myuuid}",
              "campaignName": "${detailCampaign.title}",
              "campaignSlug": "${detailCampaign.slug}",
              "campaignCategory": "${detailCampaign.category_name}"
            })
          `}
        </script>
      </Helmet>
      <main className="donate-tbt">
        <Container className="container-donate">
          <div className="form-group mt-3">
            <div className="txt-subtitle mb-10">Nominal Donasi</div>
            <div className="content-grid">
              <div className="accordion-donasi">
                <div className="txt-body ct-title">
                  <h5>
                    <CurrencyFormat 
                      value={state.postData.amount} 
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={'Rp '} 
                      displayType={'text'}
                      className="txt-donasi"
                    />
                  </h5>
                </div>
              </div>
              <Link
                to={
                  `/donation/${detailCampaign.slug}/step1`
                }
                className="btn-ganti section-accordion"
              >
                Ganti
              </Link>
            </div>
            <hr/>
            {!state.postData.payment_type ? (
              <>
                <Link
                  to={`/donation/${detailCampaign.slug}/payment_channel`}
                >
                  <div className="content-grid grid-2 box-bordered">
                    <div className="ct-body">Pilih Metode Pembayaran</div>
                    <div>
                      <Image className="m-auto" src={icArrowRight} />
                    </div>
                  </div>
                </Link>
                <input
                  type="hidden"
                  name="payment_type"
                  className="form-control"
                  ref={register({
                    required: "Metode pembayaran wajib dipilih",
                  })}
                  onChange={handleChange}
                  autoFocus={true}
                  autoComplete="on"
                />
                <ErrorMessage
                  errors={errors}
                  name="payment_type"
                  as={<p className="error-input"></p>}
                />
                {state.postData.errorMessages.payment_type && (
                  <p className="error-input">
                    {state.postData.errorMessages.payment_type}
                  </p>
                )}
              </>
            ) : (
              <div className="content-grid grid-1-8-1">
                <div className="box-channel">
                  <Image
                    src={state.postData.icon_payment_type}
                    className="icon-channel"
                  />
                </div>
                <div className="txt-body ct-title">
                  {state.postData.title_payment_type}
                </div>
                <Link
                  to={
                    `/donation/${detailCampaign.slug}/payment_channel`
                  }
                  className="btn-ganti"
                >
                  Ganti
                </Link>
              </div>
            )}
          </div>
        </Container>
        <div className="content-space"></div>
        <Container className="container-donate">
          {/* {(!state.postData.stepdon1 && !state.postData.amount && !state.postData.stepdon2) && 
            <Redirect to={"/donation/"+detailCampaign.slug+"/step1"} />
          } */}
          <form onSubmit={handleSubmit(onSubmit)} className="form-hook">
            {auth && (
              <div className="suggest-donate content-grid wrap-auth-login">
                <div className="wrapper-img">
                  <Image src={!auth.photo ? avatar : auth.photo} width="65" />
                </div>
                <div className="text-left">
                  <h6 className="mb-0">{auth.name}</h6>
                  <div>{auth.email}</div>
                </div>
              </div>
            )}
            {!auth && (
              <div>
                <div className="txt-body-m mb-20">
                  <Link to={"/login?donation=" + detailCampaign.slug}>
                    Masuk
                  </Link>{" "}
                  atau lengkapi data dibawah ini
                </div>
                <div className="form-group">
                  <input
                    name="donor_name"
                    className="form-control"
                    placeholder="Nama Lengkap"
                    ref={register({ required: "Isi terlebih dulu nama Anda" })}
                    onChange={handleChange}
                    autoFocus={true}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="donor_name"
                    as={<p className="error-input"></p>}
                  />
                  {state.postData.errorMessages.name && (
                    <p className="error-input">
                      {state.postData.errorMessages.donor_name}
                    </p>
                  )}
                </div>

                <div className="form-group">
                  <input
                    name="contact"
                    className="form-control"
                    placeholder="Nomor HP atau Email"
                    ref={register({ required: "Isi terlebih dahulu Email atau nomor HP Anda" })}
                    onChange={handleChange}
                    autoFocus={true}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="contact"
                    as={<p className="error-input"></p>}
                  />
                  {state.postData.errorMessages.contact && (
                    <p className="error-input">
                      {state.postData.errorMessages.contact}
                    </p>
                  )}
                </div>
              </div>
            )}
            <div className="form-group d-flex">
              <label htmlFor="styled-checkbox-1" className="txt-checkbox">
                Sembunyikan nama saya (donasi dari Tetangga baik)
              </label>
              <Form.Check id="styled-checkbox-1" ref={register()} onChange={handleChange} checked={state.postData.is_anonim} type="switch" autoFocus={true} label="" />
            </div>
            <div className="form-group">
              <label className="form-label">
                Tulis Doa atau Dukungan (Optional)
              </label>
              <textarea
                placeholder="Tulis do’a dan dukungan kamu disini, agar semakin banyak yang mengaminkan"
                name="support"
                className="form-control"
                onChange={handleChange}
                ref={register({
                  maxLength: {
                    value: 140,
                    message: "Tidak boleh lebih dari 140 karakter",
                  },
                })}
                rows={5}
              />
              <ErrorMessage
                errors={errors}
                name="support"
                as={<p className="error-input"></p>}
              />
            </div>

            {!state.postData.resultDonation.snapToken && (
              <nav>
                <Nav className="navbar">
                  {!state.postData.stepdon3 && (
                    <button
                      type="submit"
                      className="btn btn-donate btn-block mt-0"
                      id="btn-donation"
                      disabled={props.isLoading}
                    >
                      {!props.isLoading && <span>Lanjutkan Pembayaran</span>}
                      {props.isLoading && (
                        <Spinner animation="border" role="status" />
                      )}
                    </button>
                  )}

                  {state.postData.stepdon3 &&
                    state.postData.typeof_payment_type === "transfer" && (
                      <Link
                        to="/campaign"
                        className="btn btn-donate btn-block mt-0"
                      >
                        <span>Ayo Berdonasi Lagi</span>
                      </Link>
                    )}
                </Nav>
              </nav>
            )}

            {state.postData.checkMessage && !state.postData.resultDonation && (
              <RedirectStep errorMessage={props.errorMessage} />
            )}
          </form>
          {state.postData.resultDonation.snapToken && (
            <Script
              url={midtrans.url}
              attributes={{ "data-client-key": midtrans.clientKey }}
              // onCreate={this.handleScriptCreate.bind(this)}
              // onError={this.handleScriptError.bind(this)}
              onLoad={handleScriptLoad.bind(this)}
            />
          )}
        </Container>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  errorMessage: state.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  clearDispatch: () => dispatch(clearDispatch()),
  API: (path, data) => dispatch(API(path, data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step2));
