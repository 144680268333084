import React, {Fragment} from 'react';
import { Container } from 'react-bootstrap';
import { withRouter } from "react-router";
import {Link} from 'react-router-dom';

import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';

// import './style.css';

//Load Component
import { Header } from '../../../organisms';

class DetailLembaga extends React.Component {
	state = {
		detailCampaigner: {}
	}
	1
	componentDidMount (){
		if(localStorage.getItem("userData")){
			
			const auth = JSON.parse(localStorage.getItem("userData"));
			this.setState({detailCampaigner:auth.campaigner});
		}else{
			const {history} = this.props;
			history.push('/login');
		}
			
	}
  
	render() {
		const data = this.state.detailCampaigner;
		return(
			<Fragment>
				<Header title="Detail Campaigner" prevPage="/pencairan"/>
				<main>
					<Container>
						{data && 
							<>
							<div className="mb-2">Nama Lembaga</div>
							<h5 className="text-blue">{data.name}</h5>

							<div className="content-grid grid-2 btn-groups">
								<div><Link className="fw-7" to={"/verifikasi-lembaga?edit=1"}>Edit</Link></div>
							</div>
							</>
						}
					</Container>
				</main>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	isLoading: state.isLoading,
	errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
	clearDispatch: () => dispatch(clearDispatch()),
	API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailLembaga));
