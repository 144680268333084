import React from 'react'
import CurrencyFormat from 'react-currency-format';
import { Image } from "react-bootstrap";

const Suggest =  (props) => {
    return (
        <div className={"suggest-donate "+(props.amount > 0 && props.amount === props.nominal ? 'sd-active' : '')} onClick={() => props.handleSuggestion(props.nominal)}>
            <div className={"content-grid grid-2"}>
                <div>
                    <CurrencyFormat 
                        value={props.nominal} 
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={'Rp '} 
                        displayType={'text'}
                        className="amount"
                    />
                    {props.amount > 0 && props.amount === props.nominal ?
                        <Image className="ic-right" src="/assets/img/ic-right-white.svg"/>
                        :
                        <Image className="ic-right" src="/assets/img/ic-right.svg"/>
                    }
                </div>
                <div>
                    {props.desc && 
                        <div className="desc txt-caption-m">{props.desc}</div>
                    }
                </div>
            </div>
            
        </div>
    )
}

export default Suggest
