import React, {Fragment, useState} from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { withRouter } from "react-router";
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { clearDispatch, API, validateTitle, validateDescription } from '../../../../config/redux/action';
import {CkEditor} from '../../../../component/atoms';
import {StringToSlug} from '../../../../utils';
import { htmlToText } from 'html-to-text';
import './style.css';

//Load Component
import { Header } from '../../../organisms';
import UploadFile from './UploadFile';

const FormAdd = props => {
    const detailNews = JSON.parse(localStorage.getItem("detailNews"));
    const { handleSubmit, register, errors, setValue } = useForm();
    const [cover, setCover] = useState({
        base64image: detailNews?.image_url || "",
        file_name: detailNews?.image || ""
    });

    const onChange = e => {
        setCover({
            base64image: e?.base64image || "",
            file_name: e?.file_name || "",
        })
        setValue("cover", e?.file_name)
        props.onChangeCover && props.onChangeCover(e);
    }
    return (
        <form className="form-hook mt-3" onSubmit={handleSubmit(props.handleSubmitForm)}>
            <div className="form-group">
                <label className="form-label">
                    Status
                </label>
                <select 
                    className="form-control mb-2" 
                    name="status" onChange={props.handleChange}
                    value={props.status}
                    ref={register({ required: "Status Wajib dipilih" })}
                    >
                    <option value="">Pilih</option>
                    <option>Publish</option>
                    <option>Draft</option>
                </select>
                <ErrorMessage errors={errors} name="status" as={<p className="error-input"></p>} />
                {props.errorMessage.status && 
                <p className="error-input">{props.errorMessage.status}</p>}
            </div>
            <div className="form-group">
                <label className="form-label">Judul</label>
                <input
                name="title"
                className="form-control"
                ref={register({ required: "Judul wajib diisi" })}
                onChange={props.handleChange}
                defaultValue={props.defaultValues.title}
                />
                <ErrorMessage errors={errors} name="title" as={<p className="error-input"></p>} />
                {props.errorMessage.title && 
                <p className="error-input">{props.errorMessage.title}</p>}
            </div>

            <div className="galang-dana">
                <div className="form-group">
                    <label className="form-label">Cover Berita</label>
                    <input 
                        type="text" 
                        name="cover"
                        ref={register({ required: "Cover wajib dipilih" })}
                        defaultValue={cover.file_name}
                        hidden
                    />
                    <UploadFile
                        title="Upload Foto Cover" 
                        field="cover" 
                        fileName="campaign_cover_" 
                        classFile={cover.base64image ? 'file' : ''} 
                        classCoverImg={cover.base64image ? 'cover-img' : 'cover-img border-radius50'} 
                        returnFile={onChange}
                        icon={cover.base64image}
                        onCancel={onChange}
                    />
                    <ErrorMessage errors={errors} name="cover" as={<p className="error-input"></p>} />
                    
                </div>
            </div>

            <div className="form-group">
                <label className="form-label">Deskripsi ({htmlToText(props.body).length}/5000)</label>
                <CkEditor handleCKEditor={props.handleDesc} value={props.body}/>
                {!props.body && 
                    <div>
                    <textarea
                        name="body"
                        ref={register({ required: "Deskripsi Wajib disi" })} 
                        className="d"
                        defaultValue={props.body}
                    />
                    <ErrorMessage errors={errors} name="body" as={<p className="error-input"></p>} />
                    </div>
                }
                {props.errorMessage.body && 
                <p className="error-input">{props.errorMessage.body}</p>}
            </div>

            <button className="btn btn-primary btn-block mt-20" type="submit" disabled={props.isLoading}>
                { (!props.isLoading) && <>Simpan Perubahan</> }
                { props.isLoading && <Spinner animation="border" role="status"/> }
            </button>
        </form>
    );
}
class EditNews extends React.Component {
    state = {
        formPost: {}
    }
    constructor(props) {
        super(props)

        const detailNews = JSON.parse(localStorage.getItem("detailNews"));
        this.state.formPost = { 
            title: detailNews.title, 
            body: detailNews.body, 
            status: detailNews.status,
            image: detailNews.image
        }
    }
    buildFormPost = (field, value) => {
        this.props.clearDispatch()
        if(field === 'title') {
            this.props.validateTitle('title', value)
        } else {
            this.props.validateTitle('title', this.state.formPost.title ? this.state.formPost.title : '')
        }
        if(field === 'body') {
            this.props.validateDescription('body', htmlToText(value))
        } else {
            this.props.validateDescription('body', htmlToText(this.state.formPost.body ? this.state.formPost.body : ''))
        }

        let formPostNew = {...this.state.formPost};
        formPostNew[field] = value;
        this.setState({
            formPost: formPostNew,
        })
    }
    handleFormChange = (e) =>{
        this.buildFormPost(e.target.name, e.target.value);
    }
    handleDesc = (event, editor) => {
        this.buildFormPost('body', editor.getData());
    }
    resetBody = () => {
        this.buildFormPost('body', '');
    }

    handleSubmitForm = async () => {
        const {history} = this.props;
        const auth = this.state.auth;
        const token = `Bearer ${auth.access_token}`;

        let postData = this.state.formPost;
        postData['slug'] = StringToSlug(this.state.formPost.title);
        postData['set_campaign_expired'] = false;

        const config = {
            method: 'put',
            url: '/campaign-news/'+this.state.newsSlug,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            data: postData
        };
        const res = await this.props.API(config).catch(err=>err);
        if (res) {
            toast.info('Berita berhasil diubah', {
                position: "top-center",
                hideProgressBar: true,
                autoClose:3000,
            });

            const detailCampaign = JSON.parse(localStorage.getItem("detailCampaign"));
            setTimeout(() =>{
                history.push('/detail-campaign/'+detailCampaign.slug);
            },1000 );
        }
    }
    
    componentDidMount (){
        this.props.clearDispatch();
        const {history} = this.props;
        
        if(localStorage.getItem("userData")){
            const auth = JSON.parse(localStorage.getItem("userData"))
            if(!auth.campaigner){
              history.push('/verifikasi-lembaga');
            }
            let url = this.props.location.pathname;
            let pathArr = url.split('/');
            let newsSlug = pathArr[pathArr.length - 1];

            this.setState({newsSlug, auth});
        }else{
            history.push('/login');
        }
    }
  
    render() {
        const detailCampaign = JSON.parse(localStorage.getItem("detailCampaign"));
        const detailNews = JSON.parse(localStorage.getItem("detailNews"));
        const linkPrevPage = '/detail-campaign/'+detailCampaign.slug;
        const formPost = this.state.formPost;
        let sectionStyle = {
            backgroundImage: `url(${detailCampaign.cover})`
        };
        return(
            <Fragment>
                <Header title="Ubah Berita" prevPage={linkPrevPage}/>
                <main className="form-news">
                <Container>
                    <div className="container-donate">
                        <div className="widget-campaign">
                            <div className="campaign-card-image">
                                <div className="center-cropped" style={sectionStyle}>
                                </div>
                            </div>
                            <div>
                                <div className="txt-body-m fw-6 ct-title">{detailCampaign.title}</div>
                            </div>
                        </div>
                    </div>
                    <FormAdd 
                        handleChange={this.handleFormChange} 
                        errorMessage={this.props.errorMessage}
                        handleDesc={this.handleDesc}
                        handleSubmitForm={this.handleSubmitForm}
                        body={formPost.body}
                        status={formPost.status}
                        image_url={formPost.image_url}
                        resetBody={this.resetBody}
                        isLoading={this.props.isLoading}
                        defaultValues={detailNews}
                        onChangeCover={ e =>{
                            let temp = this.state.formPost;
                            temp.image = e.file_name
                            this.setState({
                                formPost: temp
                            })
                        }}
                    />
                </Container>
            </main>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data)),
    validateTitle: (fieldName, value) => dispatch(validateTitle(fieldName, value)),
    validateDescription: (fieldName, value) => dispatch(validateDescription(fieldName, value)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditNews));
