import React from 'react';
import { Image, Modal, Tab, Tabs  } from "react-bootstrap";
import Moment from 'react-moment';
import icCloseModal from '../../../assets/svg/ic-close-modal.svg';

const AturWaktu =  (props) => {
    const { show, onHide, label, nameField, setDate, setTime, date, time, checkDate, checkTime, onCancel } = props
    let currentDate = new Date().toISOString().slice(0, 10);
    return (
        <Modal show={show} onHide={onHide} animation={false} centered className="modal-bottom modal-settingtime">
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">
                    <span className="txt-title">{label}</span>
                </Modal.Title>
                <Image src={icCloseModal} onClick={onHide} className="cursor-pointer" /> 
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="grid-settingtime">
                        <p>Tanggal</p>
                        {date.map((row, i) => (
                            <div className={(setDate === row ? "radio-settingtime active" : "radio-settingtime ")} onChange={() => checkDate(row)}>
                                <input id={row} type="radio" name="date" data-type={row}/>
                                <label htmlFor={row}>
                                    <Moment format="DD MMM">{row}</Moment>
                                </label>
                            </div>
                        ))}
                    </div>
                    <div className="grid-settingtime">
                        <p>Jam</p>
                        {setDate === currentDate ?
                            <span>
                                {time.map((row, i) => (
                                    <div className={setTime === row.id ? "radio-settingtime active" : "radio-settingtime "} onChange={() => checkTime(row.id)}>
                                    <input id={row.id} type="radio" name={nameField} data-type={row.id} disabled={row.status==='false'}/>
                                    <label htmlFor={row.id} className={row.status === 'false' ? "disabled" : ""} >
                                        {row.start_time} - {row.end_time} WIB
                                    </label>
                                </div>
                                ))}
                            </span> :
                            <span>
                                <div className={(setTime === 1 ? "radio-settingtime active" : "radio-settingtime ")} onChange={() => checkTime(1)}>
                                    <input id="1" type="radio" name={nameField} data-type="1"/>
                                    <label htmlFor="1">
                                        08.00 - 10.00 WIB
                                    </label>
                                </div>
                                <div className={setTime === 2 ? "radio-settingtime active" : "radio-settingtime "} onChange={() => checkTime(2)}>
                                    <input id="2" type="radio" name={nameField} data-type="2"/>
                                    <label htmlFor="2">
                                        10:00 - 12:00 WIB
                                    </label>
                                </div>
                                <div className={setTime === 3 ? "radio-settingtime active" : "radio-settingtime "} onChange={() => checkTime(3)}>
                                    <input id="3" type="radio" name={nameField} data-type="3"/>
                                    <label htmlFor="3">
                                        12:00 - 14:00 WIB
                                    </label>
                                </div>
                            </span>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <button className="btn btn-secondary btn-block" onClick={onCancel}>Batal</button>
                    </div>
                    <div className="col-6">
                        <button className="btn btn-primary btn-block" onClick={onHide} disabled={setDate === '' || setTime === ''}>Pilih</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AturWaktu;