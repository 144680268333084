import React from "react";
import { useHistory } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";
import { ErrorMessage } from '@hookform/error-message';
import {Container, Spinner, Image} from 'react-bootstrap'

import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';

import { useForm } from "react-hook-form";
import { Header } from '../../../organisms';
import icAsk from '../../../../assets/svg/ic-btnhelp.svg';
import icSilang from '../../../../assets/svg/ic-silang.svg';
import icCheckGreen from '../../../../assets/svg/ic-check-green.svg';
import './style.css';

const Intro2 = (props) => {
    const { state, action } = useStateMachine(updateAction);
    const { handleSubmit, register, errors } = useForm({
      defaultValues: state.postData
    });
    const { push } = useHistory();

    const handleChange = (e) =>{
      let val = e.target.value;
      let field = e.target.name;
      let data = {};
      data[field] = val;
      if(field === 'is_agree'){
        data['is_agree'] = e.target.checked;
      }
      data['intro2'] = false;
      action(data);
    }

    const onSubmit = data => {
      data['intro'] = true;
      data['intro2'] = true;
      action(data);
      push("/galang-dana/step1");
    };

    return (
      <>
        {/* <Header splash={true} headerclass="header-bg-white" /> */}
        <Header title="Galang Donasi" prevPage="/galang-dana" cols={3} linkPost3={{pathname: "https://wa.me/6281120211194"}} rel="noopener noreferrer" target="_blank" position3={icAsk} headerclass="header-bg-white"/>
        <main className="onboarding">  
          <Container>
            <div className="txt-title">Halo, Tetangga Baik</div>
            <div className="txt-body mb-3">Sebelum mulai galang dana perhatikan dulu ya</div>
            <div className="info-green"><Image src={icCheckGreen} className="mr-3"/> Pastikan informasi program yang digalang lengkap dan akurat.</div>
            <div className="info-green"><Image src={icCheckGreen} className="mr-3"/> Data diri penerima manfaat lengkap dan dapat dipertanggungjawabkan.</div>
            <div className="info-green"><Image src={icCheckGreen} className="mr-3"/> Pastikan penerima manfaat sudah memberi izin untuk dipublikasikan dalam penggalangan dana.</div>
            <div className="info-green"><Image src={icCheckGreen} className="mr-3"/> Tujuan penggalangan dana sesuai dan realistis dengan target donasi yang yang dituliskan.</div>
            <div className="info-red"><Image src={icSilang} className="mr-3"/> Tidak menggalang dana untuk hal yang fiktif dan tidak realistis.</div>
            <div className="info-red"><Image src={icSilang} className="mr-3"/> Tidak menggalang dana untuk membayar hutang pribadi Anda.</div>
            <div className="info-red"><Image src={icSilang} className="mr-3"/> Tidak menggalang dana untuk mendanai aksi terorisme dan radikalisme.</div>

            <form onSubmit={handleSubmit(onSubmit)} className="form-hook mt-4">
              <div className="form-group">
                <input 
                  className="styled-checkbox" 
                  id="styled-checkbox-1" 
                  type="checkbox" 
                  ref={register({required: "Anda wajib mencentang bagian ini"})}
                  name="is_agree"
                  onChange={handleChange}
                />
                <label htmlFor="styled-checkbox-1" className="label txt-body-m">
                  Saya mengerti dan menyetujui segala peraturan penggalangan di Bantu Tetangga
                </label>
                
                <ErrorMessage errors={errors} name="is_agree" as={<p className="error-input"></p>} />
              </div>


              <button type="submit" className={"btn btn-block btn-next mt-0 "+(props.isLoading || !state.postData.is_agree ? "btn-disabled" : "btn-primary")} disabled={(props.isLoading || !state.postData.is_agree ? true : false)}>
                { (!props.isLoading) && <span>Lanjutkan</span> }
                { props.isLoading && <Spinner animation="border" role="status"/> }
              </button>
            </form>
          </Container>
        </main>
      </>
    );
};


const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Intro2));
