import React from "react";

import {Image, Container} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { Header } from '../../../containers/organisms';
import imgSuccessDisbursement from '../../../assets/img/successdisbursement.png';


import './style.css';
class SuccessDisbursement extends React.Component {
  state = {}
  
  componentDidMount (){
  }

  render() {
    return(
      <>
      <Header splash={true}/>
      <main>  
        <Container >
          <div className="section text-center pb-0">
            <Image src={imgSuccessDisbursement} className="w-100"/>
            <div className="txt-subtitle-m fw-6 mt-4 mb-2">Pencairan akan segera kami proses</div>
            <div className="txt-body-m mb-5">Pastikan nomor ponsel dan email Anda aktif untuk menerima konfirmasi pencairan.</div>
            <Link to="/pencairan" className="btn btn-secondary btn-block">Cek Riwayat Pencairan</Link>
            <Link to="" className="btn btn-secondary btn-block no-border">Kembali ke Beranda</Link>
          </div>
        </Container>
      </main>
      </>
    )
  }
}

export default SuccessDisbursement;
