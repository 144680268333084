import React, {Fragment} from "react";
import { CropFile } from "../../../../component/molecules";
import {Container, Form, Image} from 'react-bootstrap';
import avatar from "../../../../assets/img/avatar/no-img.png";

import {Link} from 'react-router-dom';
import { withRouter } from "react-router";
import { toast } from 'react-toastify';

import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';

import './style.css';

//Load Component
import { Header } from '../../../organisms';
import { Input, BtnSubmit } from '../../../../component/atoms';

class EditProfile extends React.Component {

    constructor() {
        super();
        this.state = {
            uid:null,
            access_token:{},
            formPost:{},
            showModal:false,
            newAvatar:null,
            needVerify:{},
            verifyMsg:{
                email:null,
                phone:null
            }
        };
    }

    verifyEmail = () =>{
        const {history} = this.props;
        history.push('/verify-email');


    }

    verifyOtp = () =>{
        const {history} = this.props;
        history.push('/verify-otp');
    }

    handleModal = (imageCropped) => {
        this.setState({showModal: !this.state.showModal})
        if(imageCropped){
            this.setState({newAvatar:imageCropped.base64image, storeImage:{name:'image', file:imageCropped.filePost}});
        }else{
            this.setState({storeImage:null});
        }
    }

    handleFormChange = (e) =>{
        let formPostNew = {...this.state.formPost};
        formPostNew[e.target.name] = e.target.value;

        let errorMessageNew = this.props.errorMessage;
        if(errorMessageNew.length > 0){
        }else{
            if(formPostNew[e.target.name]){
                errorMessageNew[e.target.name] = '';
            }
        }

        this.setState({
            formPost: formPostNew,
            errorMessage: errorMessageNew
        })
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        let formPostNew = {...this.state.formPost};
        if(this.state.storeImage){
            let formData = new FormData();
            formData.append("image", this.state.storeImage.file);

            const confStoreImage = {
                method: 'post',
                url: '/image',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.access_token
                },
                data: formData
            };
            const res = await this.props.API(confStoreImage, true).catch(err=>err);
            if (res) {
                formPostNew['photo'] = res.data.file_name;
            }
        }
       
        const confEditProfile = {
            method: 'put',
            url: '/profile',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.access_token
            },
            data: formPostNew
        };

        const res = await this.props.API(confEditProfile, true).catch(err=>err);
        if (res) {
            toast.info('Profil anda berhasil diperbaharui', {
                position: "top-center",
                hideProgressBar: true,
                autoClose:3000,
            });
            let newAuth = {...this.state.auth};
            newAuth['name'] = res.data.name;
            newAuth['email'] = res.data.email;
            newAuth['email_verified_at'] = res.data.email_verified_at;
            newAuth['phone'] = res.data.phone;
            newAuth['phone_verified_at'] = res.data.phone_verified_at;
            newAuth['photo'] = res.data.photo;
            newAuth['updated_at'] = res.data.updated_at;
            this.setState({ auth:newAuth })

            localStorage.setItem('userData', JSON.stringify(newAuth));

            const {history} = this.props;
            setTimeout(() =>{
                history.push('/profile');
            },1000 );
        }
    }

    componentDidMount() {

        this.props.clearDispatch();

        if(localStorage.getItem("userData")){
            const auth = JSON.parse(localStorage.getItem("userData"));
            
            let verifyMsgNew = {...this.state.verifyMsg};
            if(!auth.email_verified_at && auth.email){
                verifyMsgNew['email'] = (auth.email) ? 'Membutuhkan Verifikasi' : null;
            }
            if(!auth.phone_verified_at){
                verifyMsgNew['phone'] = (auth.phone) ? 'Membutuhkan Verifikasi' : null;
            }
            this.setState({verifyMsg: verifyMsgNew});

            const dumpFormData = {
                name: auth.name,
                email: auth.email,
                phone: auth.phone
            }
            this.setState({
                formPost: dumpFormData,
                needVerify: {email:(auth.email ? auth.email_verified_at : 'not-available'), phone: (auth.phone ? auth.phone_verified_at : 'not-available')},
                uid:auth.id,
                auth:auth,
                photo:(!auth.photo ? avatar : auth.photo)
                
            })
            this.setState({access_token: `Bearer ${auth.access_token}`})

        }else{
            const {history} = this.props;
            history.push('/login');
        }
    }


    render() {

        const {auth, photo, showModal, uid} = this.state;
        return (
            <Fragment>
                <Header title="Edit Profile" prevPage="/profile"/>
                <main>
                <CropFile avatarFileName={'avatar_'+uid} title="Atur Foto Profil" label="Pilih Foto" show={showModal} handleModal={this.handleModal}/>
                <Container>
                    <div className="profil-avatar">
                        <div className="wrapper-img">
                            <Image src={this.state.newAvatar ? this.state.newAvatar : photo}/>
                            <div className="icon-upload" onClick={this.handleModal}><Image src="assets/img/ic-photo.svg"/></div>
                        </div>
                    </div>
                    <Form>
                        <Input 
                            label="Nama Lengkap"
                            type="text" 
                            name="name"
                            value={ this.state.formPost.name ? this.state.formPost.name : '' }
                            icon_left="assets/img/ic-user.svg" 
                            placeholder="Nama Lengkap"
                            handleFormChange={this.handleFormChange}
                            errorMessage = { this.props.errorMessage.name }  
                        />

                        <Input 
                            label="Email"
                            infoLabel={this.state.verifyMsg.email ? this.state.verifyMsg.email : ''}
                            classInfoLabel="text-warning"
                            type="email"
                            inputGroupClass={ !this.state.needVerify.email ? 'input-warning': (auth.email ? 'input-info' : '') }  
                            name="email"
                            value={ this.state.formPost.email ? this.state.formPost.email : '' }
                            icon_left="assets/img/ic-mail.svg" 
                            icon_right={ !this.state.needVerify.email ? 'assets/img/ic-warning.svg': (auth.email ? 'assets/img/ic-verified-sm.svg' : '') }
                            icon_right_click = {this.verifyEmail}
                            placeholder="email@site.com" 
                            handleFormChange={this.handleFormChange}
                            errorMessage = { this.props.errorMessage.email}  
                        />
                        <Input 
                            label="No Telepon"
                            infoLabel={this.state.verifyMsg.phone ? this.state.verifyMsg.phone : ''}
                            classInfoLabel="text-warning"
                            type="text" 
                            name="phone"
                            inputGroupClass={ !this.state.needVerify.phone ? 'input-warning': (auth.phone ? 'input-info' : '') } 
                            value={ this.state.formPost.phone ? this.state.formPost.phone : '' }
                            icon_left="assets/img/ic-phone.svg"
                            icon_right={ !this.state.needVerify.phone ? 'assets/img/ic-warning.svg': (auth.phone ? 'assets/img/ic-verified-sm.svg' : '') }
                            icon_right_click = {this.verifyOtp}
                            placeholder="+62xxxxxxxx" 
                            handleFormChange={this.handleFormChange}
                            errorMessage = { this.props.errorMessage.phone}  
                        />

                        <div className="link-forgot-password"><Link to="/change-password">Ubah Password</Link></div>
                        <div className="link-forgot-password"><Link to="/detail-lembaga">Lihat Data Lembaga</Link></div>
                        <BtnSubmit label="Simpan" isLoading={this.props.isLoading} handleSubmit={this.handleSubmit}/>
                    </Form>
                </Container>
            </main>
            </Fragment>
        );
    }

}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditProfile));