import React from 'react';
import { Container, Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { API, clearDispatch } from '../../../config/redux/action';
import { Header } from '../../organisms';
import icCheck from '../../../assets/svg/ic-check.svg';
import icDisbursement1 from '../../../assets/svg/ic-disbursement-1.svg';
import icDisbursement2 from '../../../assets/svg/ic-disbursement-2.svg';
import icDisbursement3 from '../../../assets/svg/ic-disbursement-3.svg';

import './style.css';
import icDisbursement2Primary from '../../../assets/svg/ic-disbursement-2-primary.svg';
import icDisbursement3Primary from '../../../assets/svg/ic-disbursement-3-primary.svg';
import Loading from './Loading';
import Moment from 'react-moment';

class DisbursementStatus extends React.Component {
    state = {
        auth: {},
        token: '',
        donation: [],
        isFetching: true,
        last: {},
        status: {}
    }
    constructor(props) {
        super(props)

        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = auth && auth.access_token ? `Bearer ${auth.access_token}` : null;
        const status = {
            pending: 1,
            approved: 2,
            processed: 3,
            finished: 4,
        }

        this.state = { donation: [], isFetching: true, token, status }
    }
    async apiResult () {
        const { API } = this.props
        const config = {
            method: 'get',
            url: '/campaigner/disbursement/timeline/' + this.props.match.params.code,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            }
        };
        const res = await API(config, false).catch(err=>err);
        if (res) {
            const donation = res.data.reverse();
            let last = donation.length ? donation[0] : {};
            last.status = this.state.status[last.slug];
            this.setState({ donation, last });
        }
        this.setState({ isFetching: false });
    }
    componentDidMount() {
        if(this.state.token) {
            this.apiResult()
        } else {
            this.props.history.push('/login')
        }
    }
    render() {
        return (
            <React.Fragment>
                <Header title="Detail Status" prevPage={"/detail-pencairan/"+this.props.match.params.code} />
                <main>
                    {this.state.isFetching && <Loading />}
                    {!this.state.isFetching && 
                        <>
                            <Container className="bg-gray px-5">
                                <div className="d-flex">
                                    <div className="w-100 ml-2">
                                        <Image src={icDisbursement1} width="40px" />
                                    </div>
                                    <div className="w-100 text-center ml-3">
                                        <Image src={this.state.last.status > 1 ? icDisbursement2Primary : icDisbursement2} width="40px" />
                                    </div>
                                    <div className="w-100 text-right mr-2">
                                        <Image src={this.state.last.status > 2 ? icDisbursement3Primary : icDisbursement3} width="40px" />
                                    </div>
                                </div>
                                <Container className="history-steps">
                                    <div className="w-100">
                                        <div className="point point-primary"><Image src={icCheck} /></div>
                                        <div className={"line " + (this.state.last.status > 1 ? 'line-primary': '')}></div>
                                    </div>
                                    <div className="w-100">
                                        {this.state.last.status === 1 && <div className="point"></div>}
                                        {this.state.last.status === 2 && 
                                            <div className="point point-border-primary">
                                                <div className="point-sm"></div>
                                            </div>
                                        }
                                        {this.state.last.status > 2 &&
                                            <div className="point point-primary"><Image src={icCheck} /></div>
                                        }

                                        <div className={"line " + (this.state.last.status > 2 ? 'line-primary': '')}></div>
                                        
                                        {this.state.last.status < 3  && <div className="point point-right"></div>}
                                        {this.state.last.status === 3 && 
                                            <div className="point point-right point-border-primary">
                                                <div className="point-sm"></div>
                                            </div>
                                        }
                                        {this.state.last.status === 4 &&
                                            <div className="point point-right point-primary"><Image src={icCheck} /></div>
                                        }
                                    </div>
                                </Container>
                            </Container>
                            <Container>
                                <div className="txt-title fw-6">Status Pencairan</div>
                                <hr />
                                <div className="history-steps-detail">
                                    {this.state.donation.map((val, key) => (
                                        <div className="history-steps-items" key={key}>
                                            <div></div>
                                            <div className="mb-4">
                                                <div className="txt-body-m fw-6 steps-title">{val.title}</div>
                                                <div className="txt-body-m">
                                                    <Moment format="dddd, DD MMMM YYYY, H:m">{val.time}</Moment>, Perkiraan proses selesai 1 hari kerja
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Container>
                        </>
                    }
                </main>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DisbursementStatus));