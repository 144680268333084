import React from 'react';
import { Image,Modal } from "react-bootstrap";
import icRadio from '../../../assets/svg/ic-radio.svg';
import icRadioActive from '../../../assets/svg/ic-radio-active.svg';
import icCloseModal from '../../../assets/svg/ic-close-modal.svg';

const Filter =  (props) => {
    const { show, onHide, handleFilter, field, resetResults, options, label } = props
    return (
        <Modal show={show} onHide={onHide} animation={false} centered className="modal-bottom">
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">
                    <Image src={icCloseModal} onClick={onHide} className="cursor-pointer" /> 
                    <span className="txt-title">{label}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="no-border">
                    <div className="list-option" onClick={() => handleFilter('')}>
                        <div>Semua {label}</div>
                        <div><Image src={(field === '' ? icRadioActive : icRadio)} /></div>
                    </div>
                    { options &&
                        options.map((value, key) =>
                            <div className="list-option" key={key} onClick={() => handleFilter(value.slug)}>
                                <div>{value.name}</div>
                                <div><Image src={(field === value.slug ? icRadioActive : icRadio)} /></div>
                            </div>
                        )
                    }

                    <button className="btn btn-primary btn-block mt-4" onClick={resetResults}>Terapkan</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default Filter