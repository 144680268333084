import React from 'react'

import './style.css'

export default function SearchCard() {
    return (
        <div className="search-card mt-0 mb-3">
            <div className="search-card-image">
                <div className="gradient text-loading height-50"></div> 
            </div>
            <div className="search-card-info">
                <div className="search-card-info-title txt-body">
                    <div className="gradient text-loading text-loading-50 height-10"></div>
                </div>
                <div className="search-card-info-campaigner txt-caption">
                    <div className="gradient text-loading text-loading-30"></div>
                </div>
            </div>
        </div>
    )
}