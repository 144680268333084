import React from 'react';
import { Image,Modal } from "react-bootstrap";
import icRadio from '../../../assets/svg/ic-radio.svg';
import icRadioActive from '../../../assets/svg/ic-radio-active.svg';
import icCloseModal from '../../../assets/svg/ic-close-modal.svg';

const FilterSort =  (props) => {
    const { show, onHide, handleFilterSort, handleFilterOrderBy, filterSort, filterOrderBy, resetResults, sort, orderBy, label } = props
    return (
        <Modal show={show} onHide={onHide} animation={false} centered className="modal-bottom">
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">
                    <Image src={icCloseModal} onClick={onHide} className="cursor-pointer" /> 
                    <span className="txt-title">{label}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="no-border">
                    { orderBy &&
                        orderBy.map((value, key) =>
                            <div className="list-option" key={key} onClick={() => handleFilterOrderBy(value.slug)}>
                                <div>{value.name}</div>
                                <div><Image src={(filterOrderBy === value.slug ? icRadioActive : icRadio)} /></div>
                            </div>
                        )
                    }
                    <div className="list-option mt-16">
                        <div className="fw-6">Urutan</div>
                    </div>
                    { sort &&
                        sort.map((value, key) =>
                            <div className="list-option" key={key} onClick={() => handleFilterSort(value.slug)}>
                                <div>{value.name}</div>
                                <div><Image src={(filterSort === value.slug ? icRadioActive : icRadio)} /></div>
                            </div>
                        )
                    }

                    <button className="btn btn-primary btn-block mt-4" onClick={resetResults}>Terapkan</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default FilterSort