import React from 'react';
import { Route } from "react-router-dom";
import CreateMeta from '../Meta/CreateMeta';
import Meta from '../Meta';
import ResetMeta from './ResetMeta';

export default function RouteWithMeta({ children, meta = null, path }) {
    const metaData = meta ? Meta[meta] : CreateMeta({})
    return (
        <Route path={path}>
            <ResetMeta meta={metaData}>
                {children}
            </ResetMeta>
        </Route>
    )
}