import React from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { useHistory, Redirect } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";
import { Header, Wizard } from '../../../organisms';

import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';
import {VerifyOTP} from '../../../../containers/pages/Authorization';
import { toast } from 'react-toastify';

const Step2 = props => {
  const { state, action } = useStateMachine(updateAction);
  const auth = JSON.parse(localStorage.getItem("userData"));
  const linkPrev = "/verifikasi-komunitas";
  const { handleSubmit, register, errors } = useForm({
    defaultValues: state.postData
  });
  const { push } = useHistory();
  const onSubmit = data => {
    data['step2'] = true;
    action(data);
    push("/verifikasi-komunitas/step3");
  };
  
  const nextStep = async (resOtp) => {
    const access_token = `Bearer ${auth.access_token}`;
    let configSave = {
        method: 'put',
        url: '/campaigner/phone',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': access_token
        }
    };
    configSave['data'] = { phone: state.postData.phone, otp_code: resOtp.data }

    const save = await props.API(configSave).catch(err=>err);

    if(save) {
        let newAuth = {...auth};
        newAuth['phone'] = state.postData.phone;
        newAuth['phone_verified_at'] = new Date();
        localStorage.setItem('userData', JSON.stringify(newAuth));
        
        toast.info('No Handphone berhasil diverifikasi.', {
            position: "top-center",
            hideProgressBar: true,
            autoClose: 3000,
        });
        setTimeout(() =>{
            push('/verifikasi-komunitas/step2');
        }, 1000 );
    }
  }
  const handleChange = (e) =>{
    let val = e.target.value;
    let field = e.target.name;
    let data = {};
    data[field] = val;
    data['step2'] = false;
    if(field === 'coverage_area' && val !== ""){
      if(!state.postData.dataProvinsi){
        data['loadProvinsi'] = false;
        apiGetArea('Provinsi')
      }
    }

    if((field === 'provinsi' && val !== "")) {
      if(state.postData.provinsi !== val){
        data['loadKota'] = false;
        apiGetArea('Kota', val)
      }
      let index = e.nativeEvent.target.selectedIndex;
      let txt = e.nativeEvent.target[index].text;
      data['nameProvinsi'] = txt;
    }

    if(field === 'provinsi' || field === 'kota'){
      let index = e.nativeEvent.target.selectedIndex;
      let txt = e.nativeEvent.target[index].text;
      data['name_'+field] = txt;
    }
    action(data);
  }

  const apiGetArea = async (type, parent=null) => {
    const auth = JSON.parse(localStorage.getItem("userData"));
    const token = `Bearer ${auth.access_token}`;

    let params = '?type='+(type === 'Kota' ? 'Kabupaten/Kota' : type);
    params += ((type === 'Kota' || type === 'Kecamatan') ? '&parent='+parent : '');
    const config = {
        method: 'get',
        url: '/area'+params,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    };
    const res = await props.API(config, false).catch(err=>err);
    if (res) {
        let data = {};
        data['load'+type] = true;
        data['data'+type] = res.data.data;
        action(data);
    }
  }

  const initialSteps = {
    class1:"",
    number1:"1",
    label1:"Detail",
    link1:"/verifikasi-komunitas",

    class2:"step-active",
    number2:2,
    label2:"Lokasi",
    link2:"/verifikasi-komunitas/step2",

    class3:"",
    number3:3,
    label3:"Dokumen",
    link3:"/verifikasi-komunitas/step3",
  }

  const hideContentOTP = () => {
    let data={};
    data['step1'] = false;
    data['phone'] = '';
    data['vlAutofocus'] = 'phone';
    action(data);
    push('/verifikasi-komunitas');
  }
  return (
    <>
      {auth.phone_verified_at ?
        <>
          {(!state.postData.step1 && !state.postData.step2) && 
            <Redirect to="/verifikasi-komunitas" />
          }
          
          <Header title="Verifikasi Penggalang" prevPage={linkPrev} ic_white={true}/>
          <Wizard initialSteps={initialSteps} />
          <form onSubmit={handleSubmit(onSubmit)} className="form-hook">
            <div className="txt-subtitle-m fw-6 mb-20">Dimana saja jangkauan Lembaga Anda ?</div>
            <div className="form-group">
              <label className="form-label">Jangkauan Wilayah</label>
              <select 
                className="form-control mb-2" 
                name="coverage_area" onChange={handleChange}
                ref={register({ required: "Wilayah Jangkauan Wajib dipilih" })}
                >
                <option value="" disabled selected hidden>Pilih Jangkauan</option>
                <option value="all">Seluruh Indonesia</option>
                <option value="Provinsi">Tingkat Provinsi</option>
                <option value="Kota">Tingkat Kab/Kota</option>
              </select>
              <ErrorMessage errors={errors} name="coverage_area" as={<p className="error-input"></p>} />
              {state.postData.errorMessages.coverage_area && 
              <p className="error-input">{state.postData.errorMessages.coverage_area}</p>}
            </div>
            { state.postData.coverage_area && state.postData.coverage_area !== "all" && 
              <div className="form-group">
                <label className="form-label">
                  Provinsi
                </label>
                <select 
                  className="form-control mb-2" 
                  name="provinsi" onChange={handleChange}
                  ref={register({ required: "Provinsi Wajib dipilih" })}
                  >
                  <option value="">{!state.postData.loadProvinsi ? 'Sedang mengambil data..' : 'Pilih'}</option>
                  { state.postData.loadProvinsi &&
                      state.postData.dataProvinsi.map((value, key) =>
                        <option key={key} value={value.id}>{value.name}</option>
                      )
                  }
                </select>
                <ErrorMessage errors={errors} name="provinsi" as={<p className="error-input"></p>} />
              </div>
            }
            { state.postData.coverage_area === "Kota" && state.postData.loadProvinsi && 
              <div className="form-group">
                <label className="form-label">Kab/Kota</label>
                <select 
                  className="form-control mb-2" 
                  name="kota" onChange={handleChange}
                  ref={register({ required: "Kab/Kota Wajib dipilih" })}
                  >
                  <option value="">{!state.postData.loadKota ? 'Sedang mengambil data..' : 'Pilih'}</option>
                  { state.postData.loadKota && state.postData.dataKota &&
                      state.postData.dataKota.map((value, key) =>
                        <option key={key} value={value.id}>{value.name}</option>
                      )
                  }
                </select>
                <ErrorMessage errors={errors} name="kota" as={<p className="error-input"></p>} />
              </div>
            }

            <div className="form-group">
              <label className="form-label">Alamat Lengkap</label>
              <textarea
                row="30"
                name="address"
                className="form-control"
                placeholder="Jalan Bantu Tetangga No. 1 RT/RW..."
                ref={register({ required: "Alamat wajib diisi" })}
                onChange={handleChange}
              />
              <ErrorMessage errors={errors} name="address" as={<p className="error-input"></p>} />
              {state.postData.errorMessages.address && 
              <p className="error-input">{state.postData.errorMessages.address}</p>}
            </div>
            {state.postData.errorMessages.area_id && 
            <p className="error-input">{state.postData.errorMessages.area_id}</p>}
          
            <button type="submit" className="btn btn-primary btn-block btn-next">Lanjutkan</button>
          </form>
        </>
        :
        <>
          <VerifyOTP 
            title={"Verifikasi Pendaftaran"} 
            prevPage="/verifikasi-komunitas/step1" 
            type="phone" 
            contact={state.postData.phone??auth.phone} 
            action="user_phone"
            sendOTPFirst={true}
            nextStep={nextStep}
            hideContentOTP={hideContentOTP}
            sendPost={({type:'Phone', contact:state.postData.phone??auth.phone, action:'campaigner_phone'})}
          />
        </>
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
  clearDispatch: () => dispatch(clearDispatch()),
  API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step2));
