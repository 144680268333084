import React from 'react';
import { Image,Modal } from "react-bootstrap";
import icRadio from '../../../assets/svg/ic-radio.svg';
import icRadioActive from '../../../assets/svg/ic-radio-active.svg';
import icCloseModal from '../../../assets/svg/ic-close-modal.svg';
import icCalendar from '../../../assets/svg/ic-calendar.svg';
import { useForm } from "react-hook-form";
import ReactDatePicker from "react-datepicker";

const FilterDate =  (props) => {
    const { show, onHide, handleFilter, filter, resetResults, checkdate, startDate, endDate } = props
    const { register } = useForm();
    const isMobileDevice = () => {
        const mobileUserAgent = [
          /Android/i,
          /webOS/i,
          /iPhone/i,
          /iPad/i,
          /iPod/i,
          /BlackBerry/i,
          /Windows Phone/i
        ];
      
        return mobileUserAgent.some((item) => navigator.userAgent.match(item));
    };
    const [isMobile] = React.useState(isMobileDevice())
    return (
        <Modal show={show} onHide={onHide} animation={false} centered className="modal-bottom">
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">
                    <Image src={icCloseModal} onClick={onHide} className="cursor-pointer" /> 
                    <span className="txt-title">Waktu</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{overflow:'visible'}}>
                <div className="no-border">
                    <div className="list-option" onClick={() => handleFilter('')}>
                        <div>Semua Tanggal Donasi</div>
                        <div><Image src={(filter === '' ? icRadioActive : icRadio)} /></div>
                    </div>
                    <div className="list-option" onClick={() => handleFilter(30)}>
                        <div>30 Hari Terakhir</div>
                        <div><Image src={(filter === 30 ? icRadioActive : icRadio)} /></div>
                    </div>
                    <div className="list-option" onClick={() => handleFilter(90)}>
                        <div>90 Hari Terakhir</div>
                        <div><Image src={(filter === 90 ? icRadioActive : icRadio)} /></div>
                    </div>
                    <div className="list-option" onClick={() => handleFilter('dateRange')}>
                        <div>Pilih Tanggal Sendiri</div>
                        <div><Image src={(filter === 'dateRange' ? icRadioActive : icRadio)} /></div>
                    </div>
                    {filter === 'dateRange' && 
                        <div className="list-option form-hook">
                            <div className="form-group">
                                {isMobile && 
                                    <input 
                                        type="date" 
                                        name="startDate"
                                        className="form-control"
                                        onChange={e => checkdate(e.target.value, 'startDate')}
                                        ref={register({ required: (filter === 'dateRange' ? "Tanggal wajib diisi" :false) })}
                                        placeholder="Mulai Dari"
                                        defaultValue={startDate ? startDate : ''}
                                    />
                                }
                                {!isMobile && 
                                    <div className="fh-input-group ig-right">
                                        <ReactDatePicker 
                                        className="form-control"
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="Mulai Dari"
                                        selected={(startDate ? new Date(startDate) : '')}
                                        onChange={date => checkdate(date, 'startDate')}
                                        />
                                        <div className="input-right">
                                        <Image src={icCalendar} />
                                        </div>
                                    </div>
                                }
                            </div>
                            <div>-</div>
                            <div className="form-group">
                                {isMobile && 
                                    <input 
                                        type="date" 
                                        name="endDate"
                                        className="form-control"
                                        onChange={e => checkdate(e.target.value, 'endDate')}
                                        ref={register({ required: (filter === 'dateRange' ? "Tanggal wajib diisi" :false) })}
                                        placeholder="Sampai"
                                        defaultValue={endDate ? endDate : ''}
                                    />
                                }
                                {!isMobile && 
                                    <div className="fh-input-group ig-right">
                                        <ReactDatePicker 
                                        className="form-control"
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="Sampai"
                                        selected={(endDate ? new Date(endDate) : '')}
                                        onChange={date => checkdate(date, 'endDate')}
                                        />
                                        <div className="input-right">
                                        <Image src={icCalendar} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    <button className="btn btn-primary btn-block mt-4" onClick={resetResults}>Terapkan</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default FilterDate