import React from 'react';
import { API, clearDispatch, reloadUser } from '../../../../config/redux/action';
import { Header } from '../../../organisms';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Container, Form, Image, Spinner } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import avatar from "../../../../assets/img/avatar/no-img.png";
import DatePicker from "react-datepicker";
import './style.css';
import { CropFile } from '../../../../component/molecules';
import { toast } from 'react-toastify'
import icCalendar from '../../../../assets/svg/ic-calendar.svg';
import IcPencilGray from '../../../../assets/svg/ic-pencil-gray.svg';

function SettingPersonalDataForm({ handleChange, handleChangeBirth, updateProfile, state, errorMessage, isLoading }) {
    const { handleSubmit, register, errors } = useForm()
    return (
        <Form onSubmit={handleSubmit(updateProfile)} className="form-hook">
            <Form.Group>
                <Form.Label>Nama Lengkap</Form.Label>
                <Form.Control
                    type="text" 
                    name="name"
                    placeholder="Masukkan Nama Lengkap" 
                    ref={register({ required: "Nama Lengkap wajib diisi" })}
                    onChange={(e) => handleChange(e)} 
                    defaultValue={state.formEdit.name}
                />
                <ErrorMessage errors={errors} name="name" as={<p className="error-input text-left"></p>} />
                {errorMessage.name && <p className="error-input">{errorMessage.name}</p>}
            </Form.Group>
            <div className="form-group">
                <label className="form-label">Tanggal Lahir</label>
                <div className="fh-input-group ig-right">
                <DatePicker 
                    className="form-control"
                    dateFormat="yyyy-MM-dd"
                    maxDate={new Date()}
                    placeholderText="Pilih Tanggal Lahir"
                    value={state.formEdit.birth_date}
                    selected={(state.formEdit.birth ? new Date(state.formEdit.birth) : '')}
                    onChange={date => handleChangeBirth(date)}
                />
                <div className="input-right">
                    <Image src={icCalendar} />
                </div>
                </div>
                {!state.formEdit.birth_date && 
                <div>
                    <input
                    name="birth_date"
                    className="hide"
                    defaultValue={state.formEdit.birth_date}
                    ref={register({ required: "Tanggal Lahir wajib diisi" })}
                    />
                    <ErrorMessage errors={errors} name="birth_date" as={<p className="error-input"></p>} />
                </div>
                }
                
                {errorMessage.birth_date && 
                <p className="error-input">{errorMessage.birth_date}</p>}
            </div>
            <Form.Group>
                <Form.Label>Jenis Kelamin</Form.Label>
                <Form.Control 
                    as="select" 
                    name="gender"
                    ref={register({ required: "Jenis Kelamin wajib diisi" })}
                    onChange={(e) => handleChange(e)} 
                    value={state.formEdit.gender}>
                    <option value="">Pilih</option>
                    <option value="pria">Pria</option>
                    <option value="wanita">Wanita</option>
                </Form.Control>
                <ErrorMessage errors={errors} name="gender" as={<p className="error-input text-left"></p>} />
                {errorMessage.gender && <p className="error-input">{errorMessage.gender}</p>}
            </Form.Group>
            <div className="txt-caption mb-1">Email</div>
            <div className="content-grid grid-2 txt-body">
                <div>{state.formEdit.email}</div>
                <div><Link to="/pengaturan/update-email"><Image src={IcPencilGray} /></Link></div>
            </div>

            <div className="txt-caption mt-20">Nomor Ponsel</div>
            <div className="content-grid grid-2 txt-body mb-16">
                <div>{state.formEdit.phone}</div>
                <div><Link to="/pengaturan/update-phone"><Image src={IcPencilGray} /></Link></div>
            </div>
            <Button type="submit" className="mt-20" variant="primary" block disabled={(isLoading ? true : false)}>
                { !isLoading && 'Simpan Perubahan' }
                { isLoading && <Spinner animation="border" role="status"/> }
            </Button>
        </Form>
    )
}

class SettingPersonalData extends React.Component {
    state = {
        loadProfile: false,
        auth: {},
        formEdit:{name:'',birth:'', birth_date:'', gender:0, email:'', phone:''},
        isSavingUpdate: false,
        isSavingPhoto: false,
        showModal: false
    }
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeBirth = this.handleChangeBirth.bind(this)
        this.handleModal = this.handleModal.bind(this)
        this.updateProfile = this.updateProfile.bind(this)
    }
    handleChange(e) {
        let change = this.state.formEdit;
        change[e.target.name] = e.target.value
        this.setState({formEdit:change})

        if(this.props.errorMessage[e.target.name]){
            let fieldChanges = {};
            fieldChanges = this.props.errorMessage;
            fieldChanges[e.target.name] = '';
            this.props.clearDispatch(fieldChanges);
        }
    }
    handleChangeBirth = (date) => {
        let change = this.state.formEdit;
        change['birth'] = date;
        change['birth_date'] = this.formatDate(date);
        this.setState({formEdit:change})
    }
    formatDate = (date) => {
        let d = (date ? new Date(date) : new Date());
        let mm = d.getMonth() + 1;
        let dd = d.getDate();
        let yy = d.getFullYear(); 
        let myDateString = yy + '-' + mm + '-' + dd;
        return myDateString;
    }
    async handleModal(imageCropped) {
        this.setState({showModal: !this.state.showModal})
        const {formEdit} = this.state;
        if(imageCropped && imageCropped.filePost){
            /** Do Save Here */
            this.setState({ isSavingPhoto: true })

            const access_token = `Bearer ${this.state.auth.access_token}`;
            let formData = new FormData();
            formData.append("image", imageCropped.filePost);

            const confStoreImage = {
                method: 'post',
                url: '/image',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': access_token
                },
                data: formData
            };
            const savePhoto = await this.props.API(confStoreImage, true).catch(err=>err);
            if (savePhoto) {
                this.setState({ auth: {...this.state.auth} })

                const access_token = `Bearer ${this.state.auth.access_token}`;
                const confEditProfile = {
                    method: 'put',
                    url: '/profile',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': access_token
                    },
                    data: {
                        name: formEdit.name,
                        birth_date: formEdit.birth_date,
                        gender: formEdit.gender,
                        email: formEdit.email,
                        phone: formEdit.phone,
                        photo: savePhoto.data.file_url
                    }
                };

                const res = await this.props.API(confEditProfile, true).catch(err=>err);
                if (res) {
                    toast.info('Profil anda berhasil diperbaharui', {
                        position: "top-center",
                        hideProgressBar: true,
                        autoClose: 3000,
                    });

                    let auth = {
                        ...this.state.auth,
                        name: res.data.name,
                        email: res.data.email,
                        email_verified_at: res.data.email_verified_at,
                        phone: res.data.phone,
                        phone_verified_at: res.data.phone_verified_at,
                        photo: res.data.photo,
                        updated_at: res.data.updated_at,
                    };
                    
                    this.setState({ auth })

                    localStorage.setItem('userData', JSON.stringify(auth));
                }
                this.setState({ isSavingPhoto: false })
            }
        }else{
            this.setState({storeImage:null});
        }
    }
    updateAuth = async (auth) => {
        this.setState({loadProfile:true});
        const access_token = `Bearer ${auth.access_token}`;
        const config = {
            method: 'get',
            url: '/profile',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': access_token
            }
        };
        const res = await this.props.reloadUser(config, auth).catch(err=>err);
        if (res) {
            res['birth_date'] = (res.birth_date ? res.birth_date : "1994-12-11");
            res['gender'] = (res.gender ? res.gender : "pria");
            localStorage.setItem('userData', JSON.stringify(res));
            this.setState({ 
                loadProfile: false, 
                auth: res,
                formEdit:{
                    name:res.name, 
                    birth:res.birth_date, 
                    birth_date:this.formatDate(res.birth_date), 
                    gender:(res.gender ? res.gender : "pria"), 
                    email:res.email , 
                    phone:res.phone
                }
            })
        }
    }
    async updateProfile() {
        this.setState({ isSavingUpdate: true })
        const access_token = `Bearer ${this.state.auth.access_token}`;
        const confEditProfile = {
            method: 'put',
            url: '/profile',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': access_token
            },
            data: this.state.formEdit
        };

        const res = await this.props.API(confEditProfile, true).catch(err=>err);
        if (res) {
            toast.info('Profil anda berhasil diperbaharui', {
                position: "top-center",
                hideProgressBar: true,
                autoClose: 3000,
            });
        }
        this.setState({ isSavingUpdate: false })
    }
    componentDidMount() {
        this.props.clearDispatch();
        if(localStorage.getItem("userData")){
            const userdata = JSON.parse(localStorage.getItem("userData"))
            this.updateAuth(userdata);
        } else {
            this.props.history.push('/login')
        }
    }
    render() {
        const { auth , showModal} = this.state
        return (
            <React.Fragment>
                <Header title="Data Diri" prevPage="/pengaturan" />
                <main>
                    <Container className="personal-data">
                        <CropFile avatarFileName={'avatar_' + auth.id} title="Atur Foto Profil" label="Pilih Foto" show={showModal} handleModal={this.handleModal}/>
                        <div className="setting-preview-card-profile mb-20">
                            {!this.state.isSavingPhoto ? 
                                <div><Image src={!auth.photo ? avatar : auth.photo} width="65"/></div>
                                :
                                <div className="load-avatar"><Spinner animation="border" role="status"/></div>
                            }
                            <div className="text-left">
                                <div className="txt-subtitle-m fw-6 ct-title">Gambar Profil</div>
                                <div className="txt-caption-m">
                                    Ukuran file maksimum 500KB dengan format .jpg atau .png
                                </div>
                                <div className="cursor-pointer txt-body-m ct-primary1 mt-10" onClick={this.handleModal}>Ganti Gambar</div>
                            </div>
                        </div>
                        
                        {this.state.auth !== null &&
                            <SettingPersonalDataForm 
                                handleChange={this.handleChange}
                                handleChangeBirth={this.handleChangeBirth}
                                checkdate={this.checkdate}
                                errorMessage={this.props.errorMessage}
                                isLoading={this.props.isLoading}
                                updateProfile={this.updateProfile}
                                state={this.state}
                            />
                        }
                    </Container>
                </main>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: (fieldChanges) => dispatch(clearDispatch(fieldChanges)),
    API: (path, data) => dispatch(API(path, data)),
    reloadUser: (config, auth) => dispatch(reloadUser(config, auth))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingPersonalData));