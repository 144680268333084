import {useEffect} from 'react';
import  { useHistory } from 'react-router-dom'
import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";

const RedirectStep =  (props) => {

    const { action } = useStateMachine(updateAction);
    const postStep1 = 
      (props.errorMessage.name || props.errorMessage.email || 
        props.errorMessage.phone) ? true : false;
    const postStep2 = 
        (props.errorMessage.area_id || props.errorMessage.address) ? true : false;    
    const postStep3 = 
        (props.errorMessage.identity || props.errorMessage.identity_face || props.errorMessage.tax) ? true : false;

    const { push } = useHistory();
    useEffect(() => {
        let urlpush;
        if(postStep1){
            urlpush = "/verifikasi-personal";
        }else{
            if(postStep2){
                urlpush = "/verifikasi-personal/step2";
            }else{
                if(postStep3){
                    urlpush = "/verifikasi-personal/step3";
                }
            }
        }
        if(urlpush){
            let data={};
            data['checkMessage'] = false;
            data['errorMessages'] = props.errorMessage;
            action(data);
            push({pathname:urlpush, props:{errorMessage:props.errorMessage}});
        }
        
    });
    return null
}

RedirectStep.defaultProps = {
    errorMessage: ''
}

export default RedirectStep
