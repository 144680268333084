import {useEffect} from 'react';
import  { useHistory } from 'react-router-dom'
import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";

const RedirectStep =  (props) => {

    const { action } = useStateMachine(updateAction);
    const postStep1 = 
      (props.errorMessage.name || props.errorMessage.email || 
        props.errorMessage.phone || props.errorMessage.address || 
        props.errorMessage.social_media) ? true : false;
    const postStep2 = 
        (props.errorMessage.coverage_area || props.errorMessage.area_id) ? true : false;    
    const postStep3 = 
        (props.errorMessage.logo || props.errorMessage.legal || props.errorMessage.tax || props.errorMessage.identity) ? true : false;

    const { push } = useHistory();
    useEffect(() => {
        let urlpush;
        if(postStep1){
            urlpush = "/verifikasi-lembaga";
        }else{
            if(postStep2){
                urlpush = "/verifikasi-lembaga/step2";
            }else{
                if(postStep3){
                    urlpush = "/verifikasi-lembaga/step3";
                }
            }
        }
        if(urlpush){
            let data={};
            data['checkMessage'] = false;
            data['errorMessages'] = props.errorMessage;
            action(data);
            push({pathname:urlpush, props:{errorMessage:props.errorMessage}});
        }
        
    });
    return null
}

RedirectStep.defaultProps = {
    errorMessage: ''
}

export default RedirectStep
