import axios from 'axios';
import { toast } from 'react-toastify';
import * as Sentry from "@sentry/react";

const RootPath = process.env.REACT_APP_API_URL;

export const clearDispatch = (fieldChanges = {}) => (dispatch) => {
    return (
        dispatch({type: 'CHANGE_ERROR_POST', value: fieldChanges}),
        dispatch({type: 'CHANGE_LOADING', value: false})
    )
}

export const API = (config, setDispatch=true, showingToastError=false) => (dispatch) => {
    return new Promise((resolve, reject)=>{
        if(setDispatch)
            dispatch({type: 'CHANGE_LOADING', value: true})
        config.url = (!config.fullUrl) ? RootPath+config.url : config.fullUrl;
        axios(config)
        .then(function (response) {
            const result = response.data;
            if(setDispatch)
                dispatch({type: 'CHANGE_LOADING', value: false})
            resolve(result);
        })
        .catch(function (error) {
            /** Stop Fetching Status */
            if(setDispatch)
                dispatch({type: 'CHANGE_LOADING', value: false})

            /** Non axios error */
            if(error.response) {
                /** Unauthorized */
                if(error.response.status === 401)
                    window.location = '/login'
                
                /** Remove toast popup */
                if(typeof error.response.data.message === 'string'){
                    toast.error(error.response.data.message, {
                        position: "top-center",
                        hideProgressBar: true,
                        autoClose: 3000,
                    });
                    
                    Sentry.withErrorBoundary(error.response.data.message, { fallback:"an error has occurred"});
                    if(setDispatch)
                        dispatch({type: 'CHANGE_POPUP', value: error.response.data.message})
                    
                    return
                }else{
                    if(showingToastError){
                        // let messages = error.response.data.message;
                        // let htmlMsg = '';
                        // for (const [key, value] of Object.entries(messages)) {
                        //     htmlMsg += value+'<br>';
                        // }
                        // toast.error('Maaf, ada inputan yang belum sesuai. Silahkan cek kembali', {
                        //     position: "top-center",
                        //     hideProgressBar: true,
                        //     autoClose: 3000,
                        // });
                    }
                    
                }

                /** Field validation error */
                dispatch({type: 'CHANGE_ERROR_POST', value: error.response.data.message})
                
                reject();
                return
            }

            /** Axios Error */
            // if(error.isAxiosError)
                // window.location = '/lost-connection'
        })
    })
}

export const reloadUser = (config, authExist) => (dispatch) => {
    return new Promise((resolve, reject)=>{
        config.url = RootPath+config.url;
        axios(config)
        .then(function (response) {
            const result = response.data.data;
            let newAuth = authExist;
            newAuth['uid'] = result.uid;
            newAuth['name'] = result.name;
            newAuth['gender'] = result.gender;
            newAuth['birth_date'] = result.birth_date;
            newAuth['email'] = result.email;
            newAuth['email_verified_at'] = result.email_verified_at;
            newAuth['phone'] = result.phone;
            newAuth['phone_verified_at'] = result.phone_verified_at;
            newAuth['birth_date'] = result.birth_date;
            newAuth['gender'] = result.gender;
            newAuth['photo'] = result.photo;
            newAuth['donor_id'] = result.donor_id;
            newAuth['campaigner'] = result.campaigner;
            newAuth['created_at'] = result.created_at;
            newAuth['updated_at'] = result.updated_at;
            newAuth['deleted_at'] = result.deleted_at;
            resolve(newAuth);
        })
        .catch(function (error) {
            localStorage.removeItem('userData')
            window.location = '/login'
            return
            // if(error.response){
            //     if (error.response.status === 401) {
            //     }
            //     var msg = error.response.data.message;
            //     if(msg.length > 0){
            //         toast.error(msg, {
            //             position: "top-center",
            //             hideProgressBar: true,
            //             autoClose:3000,
            //         });
            //     }
            //     reject();
            // }
        })
    })
}

export const validateTitle = (fieldName, value, maxLength = 50) => (dispatch, getState) => {
    if(value.length > maxLength) {
        // const validationError = {}
        const validationError = getState().errorMessage
        validationError[fieldName] = `Maksimal ${maxLength} Karakter`
        dispatch({type: 'CHANGE_ERROR_POST', value: validationError})
        return { status: false, errorMessage: validationError }
    }
    return { status: true }
}

export const validateDescription = (fieldName, value, maxLength = 5000) => (dispatch, getState) => {
    if(value.length > maxLength) {
        // const validationError = {}
        const validationError = getState().errorMessage
        validationError[fieldName] = `Maksimal ${maxLength} Karakter`
        dispatch({type: 'CHANGE_ERROR_POST', value: validationError})
        return { status: false, errorMessage: validationError }
    }
    return { status: true }
}