import React, { Fragment } from 'react'
import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Header } from '../../organisms';
import { API } from '../../../config';
import { useState } from 'react';
import './style.css';
import moment from 'moment';
import 'moment/locale/id';
import { toast } from 'react-toastify';
// moment(new Date()).add(60, 'days').format("YYYY-MM-DD")}

const getDistanceDate = (date, distance) => {
  if (date){
    return  moment(new Date(date)).add(Number(distance), 'days').format("YYYY-MM-DD");
  }
  return;
}

function PerpanjangCampaign() {
  const auth = JSON.parse(localStorage.getItem("userData"));
  const history = useHistory();
  const { slug } = useParams();
  const [detailCampaign, setDetailCampaign] = useState(null);
  const [isOtherDate, setIsOtherDate] = useState(false);
  const [date, setDate] = useState(null);
  const [extend_time, setExtendTime] = useState(null);

  useEffect(() => {
    async function getDetailCampaign(params) {
      const res = await API.get(`/campaign/${slug}`)
      if (res?.status === 200){
        setDetailCampaign(res?.data?.data)
      }
    }
    getDetailCampaign()
  }, [slug])

  const onChangeSelect = (event) => {
    setExtendTime(event);
    if (event === "other") {
      setIsOtherDate(true);
      setDate(null);
    } else {
      setIsOtherDate(false);
      const newValue = getDistanceDate(detailCampaign?.expired_at, event);
      console.log(newValue)
      setDate(newValue);
    }
  }
  
  const onSubmit = async () => {
    try {
      const payload = {
        campaign_slug: detailCampaign?.slug,
        date,
      }
      const config = {
        headers: {
          'Authorization': `Bearer ${auth.access_token}` 
        }
      }
      const res = await API.put("/campaigner/campaign/expire/extend", payload, config );
      if (res?.status === 200){
        toast.success(res?.data?.message, {position: "top-center"});
        history.push(`/detail-campaign/${detailCampaign?.slug}`)
      }
    } catch (error) {
      console.log(error)
    }
  }
  
  return (
    <Fragment>
      <Header title="Perpanjang Batas Waktu" prevPage={`/detail-campaign/${slug}`}  />
      <main 
        className="no-padding extend-main" 
        style={{
          minHeight: 'calc(100vh - 56px)' ,
          position: 'relative'
        }}
      >
        <Container 
          style={{marginTop:56, padding: 0}} 
          className="extend-campaign"
        >
          {detailCampaign ? (
            <>
              <div className="campaign-overview">
                <img 
                  src={detailCampaign?.thumbnail} 
                  alt="thumbnail" 
                  className="thumbnail"
                />
                <div className="title">
                  {detailCampaign?.title}
                </div>
              </div>

              <div className="overview">
                <p className="label">Batas waktu sekarang</p>
                <div className="early-deadline">{
                  detailCampaign?.expired_at ? moment(detailCampaign?.expired_at).format("DD MMMM YYYY") : "-"}
                </div>

                <div className="mt-4">
                  <h1 className="title mb-3">Perpanjang Batas Waktu</h1>
                  <div className="mb-3">
                    <label htmlFor="deadline" className="label">Batas Waktu Penggalangan</label>
                    <div className={(extend_time === 60 ? "item-salur-type active" : "item-salur-type ")} onChange={() => onChangeSelect(60)}>
                      <div className="md-radio">
                        {extend_time === 60 ?
                          <input id="60" type="radio" name="type_donation" data-type="60" checked/> :
                          <input id="60" type="radio" name="type_donation" data-type="60"/>
                        }
                        <label htmlFor="60">
                          <div className="txt-extend">60 Hari</div>
                        </label>
                      </div>
                    </div>
                    <div className={(extend_time === 90 ? "item-salur-type active" : "item-salur-type ")} onChange={() => onChangeSelect(90)}>
                      <div className="md-radio">
                        {extend_time === 90 ?
                          <input id="90" type="radio" name="type_donation" data-type="90" checked/> :
                          <input id="90" type="radio" name="type_donation" data-type="90"/>
                        }
                        <label htmlFor="90">
                          <div className="txt-extend">90 Hari</div>
                        </label>
                      </div>
                    </div>
                    <div className={(extend_time === 'other' ? "item-salur-type active" : "item-salur-type ")} onChange={() => onChangeSelect('other')}>
                      <div className="md-radio">
                        {extend_time === 'other' ?
                          <input id="other" type="radio" name="type_donation" data-type="other" checked/> :
                          <input id="other" type="radio" name="type_donation" data-type="other"/>
                        }
                        <label htmlFor="other">
                          <div className="txt-extend">Tanggal Lain</div>
                        </label>
                      </div>
                    </div>
                  </div>
                  {isOtherDate && (
                    <div className="">
                      <label htmlFor="newDate" className="label">Tanggal Publish</label>
                      <input 
                        type="date" 
                        name="newDate" 
                        id="newDate" 
                        className="form-control" 
                        onChange={event => setDate(event.target.value)}
                        min={
                          detailCampaign?.expired_at ? moment(detailCampaign?.expired_at).add(1, 'days').format("YYYY-MM-DD") : ""
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
          ):(
            <div>Loading</div>
          ) }
        </Container>
        <Container 
          style={{
            padding: '0 20px 32px 20px',
            position: 'absolute',
            bottom: 0,
            maxWidth: 480,
          }} 
        >
          <button 
            className="btn btn-primary btn-block"
            disabled={!date}
            onClick={onSubmit}
          >
            Perpanjang
          </button>
        </Container>
      </main>
    </Fragment>
  )
}

export default PerpanjangCampaign