import ReactPixel from 'react-facebook-pixel';
export const FbPixel = (eventTracker, data='') => 
{
  if(process.env.REACT_APP_FB_PIXEL_ID){
    ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID, {}, {autoConfig: true, debug: false});
    if(eventTracker === 'pageView'){
      ReactPixel.pageView(); 
    }else{
      ReactPixel.track(eventTracker, data);
    }
  }
  return null;
}
