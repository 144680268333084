import React from 'react';
import {Container} from "react-bootstrap";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { API, clearDispatch } from '../../../../config/redux/action';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
  } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import CurrencyFormat from 'react-currency-format';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import { Header } from '../../../organisms';
import { ExternalLink } from 'react-external-link';

class PaymentGuide extends React.Component {
    constructor(props) {
        super(props)

        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = auth && auth.access_token ? `Bearer ${auth.access_token}` : null;

        this.state = { donation: {}, isFetching: true, token }
    }
    async apiResult () {
        const { API } = this.props
        const config = {
            method: 'get',
            url: '/donation/guide/' + this.props.match.params.code,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            }
        };
        const res = await API(config, false).catch(err=>err);
        if (res) {
            this.setState({ donation: res.data });
        }
        this.setState({ isFetching: false });
    }
    componentDidMount() {
        if(this.state.token) {
            this.apiResult()
        } else {
            this.props.history.push('/login')
        }
    }
    copyText = (str) => {
        toast.success(str+' telah di copy', {
          position: "top-center",
          hideProgressBar: true,
          autoClose:2000,
        });
    };
    render() {
        const {donation, isFetching} = this.state;
        return (
            <React.Fragment>
                <Header title="Petunjuk Pembayaran" prevPage={"/mydonate/"+this.props.match.params.code} />
                <main>  
                    <Container className="container-donate no-padding">
                        
                        <div className="section txt-body-m">
                            <div className="content-grid grid-2 mb-10">
                            <div className="txt-caption-m">Tanggal Donasi</div>
                            <div className="fw-6 ct-title">
                                {isFetching ? 
                                    <div className="gradient text-loading text-loading-60 float-right clear-both"></div>
                                    :
                                    <Moment>
                                        {donation.created_at}
                                    </Moment>
                                }
                            </div>
                            </div>
                            <div className="content-grid grid-2">
                            <div className="txt-caption-m">Kode Donasi</div>
                            <div className="fw-6 ct-title">
                                {isFetching ? 
                                    <div className="gradient text-loading text-loading-60 float-right clear-both"></div>
                                    :
                                    <>{donation.code}</>
                                }
                            </div>
                            </div>
                        </div>
                        <div className="content-space"></div>

                        <div className="section txt-body-m">
                            <div className="mb-1">Lakukan pembayaran donasi Anda sebelum</div>
                            <div className="ct-red fw-6">
                                {isFetching ? 
                                    <div className="gradient text-loading text-loading-80"></div>
                                    :
                                    <>{donation.expire_at}</>
                                }
                            </div>
                        </div>
                        <div className="content-space"></div>

                        <div className="section txt-body-m ">
                            <div>Transfer donasi Anda ke</div>
                            {isFetching ? 
                                <div className="gradient text-loading height-100"></div>
                                :
                                <div className="content-grid grid-2 box-bordered">
                                    <div>
                                        <div>{donation.bank}</div>
                                        <div className="txt-subtitle fw-6 ct-title">{donation.account_number}</div>
                                        <div>a.n {donation.account_holder}</div>
                                    </div>
                                    <CopyToClipboard
                                        onCopy={() => this.setState({copyAccountNumber: donation.account_number})}
                                        text={donation.account_number}>
                                        <div className="ct-primary1 cursor-pointer fw-6" onClick={()=> this.copyText('No Rekening')}>Salin</div>
                                    </CopyToClipboard>
                                </div>
                            }

                            <div className="mt-20">Jumlah donasi yang perlu dibayar</div>
                            <span className="txt-hide" id="donationCheckout">{donation.total_donation}</span>
                            {isFetching ? 
                                <div className="gradient text-loading height-50"></div>
                                :
                                <div className="content-grid grid-2 box-bordered mb-10">
                                    <div className="txt-subtitle fw-6 ct-title">
                                        <CurrencyFormat 
                                        value={donation.total_donation}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        prefix={'Rp '} 
                                        displayType={'text'}
                                        />
                                    </div>
                                    <div>
                                        <CopyToClipboard
                                        onCopy={() => this.setState({copyTotalDonation: donation.total_donation})}
                                        text={donation.total_donation}>
                                        <div className="ct-primary1 cursor-pointer fw-6" onClick={()=> this.copyText('Nominal')}>Salin</div>
                                        </CopyToClipboard>
                                    </div>
                                </div>
                            }
                        </div>
                        
                        <div className="content-space"></div>

                        <div className="section">
                            <div className="txt-subtitle-m fw-6">Panduan Pembayaran</div>
                            <Accordion allowZeroExpanded>
                            <AccordionItem key={1}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Via Electronic Bank (E-Bank)
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                <ul>
                                    <li>Buka aplikasi peramban favorit Anda</li>
                                    <li>Kunjungi Website E-Banking Anda</li>
                                    <li>Masukan akun E-Banking Anda dengan benar</li>
                                    <li>Pilih Transfer</li>
                                    <li>Masukan Nomor Rekening tujuan dan pastikan penerima dengan nama Yayasan Bantu Tetangga</li>
                                    <li>Masukan Nominal Donasi anda tepat dengan Kode Unik</li>
                                    <li>Tunggu sampai proses transfer selesai</li>
                                    <li>Pastikan sistem E-Banking Anda berhasil melakukan proses transfer</li>
                                    <li>Tunggu konfirmasi otomatis dari sistem Yayasan Bantu Tetangga mengkonfirmasi donasi Anda bia SMS dan EMAIL valid yang dicantumkan saat input data donatur</li>
                                </ul>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem key={2}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Via Anjungan Tunai Mandiri (ATM)
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                <ul>
                                    <li>Masukan PIN Anda dengan benar</li>
                                    <li>Pilih menu transfer</li>
                                    <li>Masukan Nomor Rekening atas nama Yayasan Bantu Tetangga</li>
                                    <li>Masukan Nominal Donasi anda tepat dengan Kode Unik</li>
                                    <li>Tunggu sampai proses transfer selesai</li>
                                    <li>Pastikan sistem Banki Anda berhasil melakukan proses transfer</li>
                                    <li>Tunggu konfirmasi otomatis dari sistem Yayasan Bantu Tetangga mengkonfirmasi donasi Anda bia SMS dan EMAIL valid yang dicantumkan saat input data donatur</li>
                                </ul>    
                                </AccordionItemPanel>
                            </AccordionItem>
                            </Accordion>
                            <div className="text-center">Pembayaran anda akan terkonfirmasi secara otomatis. Jika terjadi kesalahan atau kesulitan, silakan <ExternalLink href={"https://wa.me/"+process.env.REACT_APP_WA_CS}>Hubungi Kami</ExternalLink></div>
                        </div>
                    </Container>
                </main>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentGuide));