import React from 'react'
import Moment from 'react-moment'
import { Link } from 'react-router-dom';
import {Image} from 'react-bootstrap';
import icVerified from '../../../assets/svg/ic-verified-campaigner.svg';
import icFoundation from '../../../assets/svg/ic-foundation-small.svg';
import icCommunity from '../../../assets/svg/ic-community-small.svg';
import noCover from '../../../assets/img/no-cover.png';
import moment from 'moment';

import './style.css'

export default function NewsCard({ news, classes }) {
    const defaultClasses = {
        root: "card-news-update",
        cardImage: "search-card-image",
        cardInfo: "search-card-info",
        cardTitle: "search-card-info-title txt-body",
        cardCampaigner: "search-card-info-campaigner txt-caption",
        ...classes
    }
    return (
        <Link className={defaultClasses.root} to={"/news/" + news.slug}>
            <img className={defaultClasses.cardImage} alt={news.title} src={(news.image_url ? news.image_url : noCover)} />
            <div className={defaultClasses.cardInfo}>
                <div className={defaultClasses.cardTitle}>{news.title}</div>
                <div className={defaultClasses.cardCampaigner}>
                    <span className="mr-1 txt-date">{moment(news?.created_at).fromNow()}</span>
                    <span className="mr-1">.</span>
                    <span className="txt-campaigner">{news.campaigner.name}</span>
                    {news.campaigner.verified &&
                        <Image src={icVerified}/>
                    }
                    {news.campaigner.is_community &&
                        <Image src={icCommunity}/>
                    }
                    {news.campaigner.is_foundation &&
                        <Image src={icFoundation}/>
                    }
                </div>
            </div>
        </Link>
    )
}