import React from 'react';
import { Button, Container, Image } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { Header } from '../../../organisms';
import IcThankYou from '../../../../assets/svg/ic-thank-you.svg';
import './style.css';

class CampaignReportSuccess extends React.Component {
    render () {
        return (
            <React.Fragment>
                <Header splash={true}/>
                <main>
                    <Container className="campaign-report-success">
                        <div className="campaign-report-success-image">
                            <Image src={IcThankYou} />
                        </div>
                        <div className="campaign-report-success-text">
                            <h5>Terima kasih atas Laporan Anda</h5>
                            <p>Laporan Anda akan kami tindaklanjuti maksimal 2 hari kerja. Terima kasih telah membuat platform ini menjadi lebih baik.</p>
                        </div>
                        <div className="text-center">
                            <Button href="/laporan" variant="primary" block className="mb-5">Cek Status Laporan</Button>
                            <Link to="/" className="txt-body-m ct-primary1">Kembali ke Beranda</Link>
                        </div>
                    </Container>
                </main>
            </React.Fragment>
        )
    }
}

export default withRouter(CampaignReportSuccess);