import React, {useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { useHistory, Link } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";
import {Nav, Spinner, Container, Image} from "react-bootstrap";
import { Header, Operasi } from '../../../organisms';

import "react-datepicker/dist/react-datepicker.css";

import Suggest from './Suggest'
import CurrencyFormat from 'react-currency-format';

import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';
import postData from './postData';
import {FbPixel} from '../../../../config/Tracker/FbPixel'
import IcWarningInfo from '../../../../assets/svg/ic-info-small.svg';

const IntitateFirst = () => {
  
  const { state, action } = useStateMachine(updateAction);
  const detailCampaign = JSON.parse(localStorage.getItem("detailCampaign"));
  const nextLink = "/donasi-beras/tetangga-bantu-tetangga";  
  const { push } = useHistory();
  useEffect(()=>{
    FbPixel('AddToCart');
    state.postData.operational_amount = 10000;
    state.postData.totalDonasi = parseInt(state.postData.amount??0) + parseInt(state.postData.operational_amount??0);
  },[])
  useEffect(() => {
    let data = {};
    if(state.postData.stepdon3 ){
      data = postData;
      data['stepdon3'] = false;
      action(data);
      push(nextLink+"/step1");
    }
  },[action, nextLink, push, state, detailCampaign])
  return null;
}


const Step1 = (props ) => {
  const linkPrev = "/tetangga-bantu-tetangga/category";
  const nextLink = "/donasi-beras/tetangga-bantu-tetangga";
  const { state, action } = useStateMachine(updateAction);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const { handleSubmit, errors, register } = useForm({
    defaultValues: state.postData
  });
  const { push } = useHistory();
  const onSubmit = data => {
    nextPage(data);
  };

  const nextPage = (data) => {
    loadData(data);
  }

  const handleSuggestion = (value) => {
    if(props.errorMessage['amount']){
      let fieldChanges = {};
      fieldChanges = props.errorMessage;
      fieldChanges['amount'] = '';
      props.clearDispatch(fieldChanges);
    }
    let data = state.postData;
    // if(state.postData.amount < 1){
      data['amount'] = value;
      data['stepdon1'] = true;
      action(data);
    // }
    nextPage(data);
  }
  const handleCurrency = (field, value) => {
    let data = {};
    data[field] = value
    action(data);

    if(props.errorMessage[field]){
      let fieldChanges = {};
      fieldChanges = props.errorMessage;
      fieldChanges[field] = '';
      props.clearDispatch(fieldChanges);
    }
  }

  const loadData = async (data) => {
    data['deliver_time_id'] = '';
    data['pickup_time_id'] = '';
    data['date'] = '';
    action(data);
    checkValues(data);
  }
  const checkTypeDonate = async (value) => {
    let data = {};
    data['type_donation'] = value;
    action(data);
  }

  const checkValues = async (data) => {
    apiGetTime()
    apiGetArea()
    let formData = {
      amount: data.amount,
      type_donation: state.postData.type_donation,
      just_validate:true
    };
    
    const config = {
        method: 'post',
        url: '/public/donation-rice',
        headers: {
            'Content-Type': 'application/json'
        },
        data: formData
    };

    const res = await props.API(config, true, true).catch(err=>err);
    if (res) {
      data['stepdon1'] = true;
      if(!state.postData.dataProvinsi){
        data['loadProvinsi'] = false;
      }
      data['resultDonation'] = {snapToken:false};
      push(nextLink+"/atur-penyaluran");
    }else{
      let data={};
      data['checkMessage'] = true;
      action(data);

    }
  }
  const apiGetTime = async () => {
    const config = {
        method: 'get',
        url: '/public/date-range',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const res = await props.API(config, false).catch(err=>err);
    if (res) {
        let data = {};
        data['dataDateRange'] = res;
        action(data);
    }
  }
  const apiGetArea = async () => {

    let params = '&type=Kecamatan';
    const config = {
        method: 'get',
        url: '/area?is_donation_rice=1'+params,
        headers: {
          'Content-Type': 'application/json'
      },
    };
    const res = await props.API(config, false).catch(err=>err);
    if (res) {
        let data = {};
        data['loadKecamatan'] = true;
        data['dataKecamatan'] = res.data.data;
        action(data);
    }
  }
  return (
    <>
      <Header title="Tetangga Bantu Tetangga" prevPage={linkPrev}/>
      <main className="donate-tbt donate-beras">
        <Container className="container-donate">
        <div className="txt-body-m mb-3">
          <div className="content-grid grid-2 info-form mb-10">
              <Image className="ic-info" src={IcWarningInfo}/>
              <div className="text-left ml-4">
                Saat ini, bantuan Anda dapat kami ambil jika Anda berada di area Bandung dan sekitarnya.  
              </div>
          </div>
        </div>
        <div className="txt-title mb-3">Dengan apa Anda menyalurkan beras?</div>
        <div className={(state.postData.type_donation === 'pickup' ? "item-salur-type active" : "item-salur-type ")} onChange={() => checkTypeDonate('pickup')}>
          <div className="md-radio">
            {state.postData.type_donation === 'pickup' ?
              <input id="pickup" type="radio" name="type_donation" data-type="pickup" checked/> :
              <input id="pickup" type="radio" name="type_donation" data-type="pickup"/>
            }
            <label htmlFor="pickup">
              <div className="txt-body ct-title">Jemput di Rumah <span className="txt-green">Gratis!</span>
              </div>
              <p className="txt-subtitle">Tunggu di rumah untuk antarkan kebaikan</p>
            </label>
          </div>
        </div>
        <div className={(state.postData.type_donation === 'deliver' ? "item-salur-type active" : "item-salur-type ")} onChange={() => checkTypeDonate('deliver')}>
          <div className="md-radio">
            {state.postData.type_donation === 'deliver' ?
              <input id="deliver" type="radio" name="type_donation" data-type="deliver" checked/> :
              <input id="deliver" type="radio" name="type_donation" data-type="deliver"/>
            }
            <label htmlFor="deliver">
              <div className="txt-body ct-title">Antar ke Bantu tetangga </div>
              <p className="txt-subtitle">Berapa KG yang ingin disalurkan?</p>
            </label>
          </div>
        </div>
        {!state.postData.type_donation && 
          <p className="error-input">Jenis penyaluran wajib di pilih</p>}
        </Container>
        <div className="content-space"></div>
        <Container className="container-donate">
          <form onSubmit={handleSubmit(onSubmit)} className="form-hook">
            <IntitateFirst/>
            <div className="txt-title">Pilih Berat Beras</div>
            <p className="txt-subtitle">Berapa KG yang ingin disalurkan?</p>
            {state.postData.type_donation === 'pickup' ?
              <div className="row">
              {/* {state.postData.amount <= 100000 &&
                <> */}
                  <Suggest nominal={10} desc="" handleSuggestion={handleSuggestion} amount={state.postData.amount}/>
                  <Suggest nominal={15} desc="" handleSuggestion={handleSuggestion} amount={state.postData.amount}/>
                  <Suggest nominal={25} desc="" handleSuggestion={handleSuggestion} amount={state.postData.amount}/>
                  <Suggest nominal={50} desc="" handleSuggestion={handleSuggestion} amount={state.postData.amount}/>
                  <Suggest nominal={75} handleSuggestion={handleSuggestion} amount={state.postData.amount}/>
                  <Suggest nominal={100} desc="" handleSuggestion={handleSuggestion} amount={state.postData.amount}/>
                {/* </>
              } */}
              </div> : 
              state.postData.type_donation === 'deliver' ?
              <div className="row">
              {/* {state.postData.amount <= 100000 &&
                <> */}
                  <Suggest nominal={1} desc="" handleSuggestion={handleSuggestion} amount={state.postData.amount}/>
                  <Suggest nominal={2} desc="" handleSuggestion={handleSuggestion} amount={state.postData.amount}/>
                  <Suggest nominal={5} desc="" handleSuggestion={handleSuggestion} amount={state.postData.amount}/>
                  <Suggest nominal={10} desc="" handleSuggestion={handleSuggestion} amount={state.postData.amount}/>
                  <Suggest nominal={25} handleSuggestion={handleSuggestion} amount={state.postData.amount}/>
                  <Suggest nominal={50} desc="" handleSuggestion={handleSuggestion} amount={state.postData.amount}/>
                {/* </>
              } */}
              </div>
              :
              <div></div>
            }
            <div className="box-nominal">
              <div className="txt-body-m ct-title">Berat Lainnya</div>
              <div className="wrap-input-amount txt-title-m">
                <div>
                  <CurrencyFormat 
                    value={state.postData.amount} 
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    onValueChange={values => {
                      handleCurrency('amount', values.value);
                    }}
                    placeholder="Masukkan Berat"
                  />
                </div>
                <div>Kg</div>
              </div>
              <div>
                <div>
                  <input
                  type="number"
                  name="amount"
                  max="1000"
                  value={state.postData.amount}
                  className="hide"
                  ref={register({ required: "Jumlah beras wajib diisi" })}
                  />
                  <ErrorMessage errors={errors} name="amount" as={<p className="error-input"></p>} />
                </div>
                {props.errorMessage.amount && 
                <p className="error-input">{props.errorMessage.amount}</p>}
                {state.postData.amount > 1000 && 
                <p className="error-input">Maksimal yang dapat di inputkan 1.000 kg</p>}
              </div>
            </div>
            <nav>
                <Nav className="navbar">
                  {!state.postData.stepdon3 && 
                    <div className="sum-donate">
                      <div className="text-donate">
                        <p>Jumlah Donasi</p>
                        <h5>
                          <CurrencyFormat 
                            value={state.postData.amount} 
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            suffix={' Kg'} 
                            displayType={'text'}
                          />
                        </h5>
                      </div>
                      <button type="submit" className="btn btn-donate btn-block mt-0" disabled={props.isLoading || !state.postData.amount || state.postData.amount === '0' || !state.postData.type_donation}>
                        { (!props.isLoading) && <span>Lanjutkan</span> }
                        { props.isLoading && <Spinner animation="border" role="status"/> }
                      </button>
                    </div>
                  }
                  {state.postData.stepdon3 && 
                    <Link to="/campaign" className="btn btn-donate btn-block mt-0"><span>Ayo Berdonasi Lagi</span></Link>
                  }
                </Nav>
              </nav>
          </form>
          <Operasi
              onHide={handleClose}
              show={show}
              label="Bagaimana Bantu Tetangga Beroperasi"
          />
        </Container>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
  clearDispatch: () => dispatch(clearDispatch()),
  API: (path, data, showError) => dispatch(API(path, data, showError))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step1));
