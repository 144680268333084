import React from 'react';
import { Button, Container, Form, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { API, clearDispatch, reloadUser } from '../../../../config/redux/action';
import { Header } from '../../../organisms';
import {VerifyOTP} from '../../../../containers/pages/Authorization'
import { toast } from 'react-toastify';

import './style.css';

function SettingUpdatePhoneForm({ state, handleChange, checkVerify, isLoading, errorMessage }) {
    const { handleSubmit, register, errors } = useForm()
    return (
        <Form onSubmit={handleSubmit(checkVerify)} className="form-hook">
            {state.old && 
                <Form.Group>
                    <Form.Label>Nomor Ponsel Sekarang</Form.Label>
                    <Form.Control defaultValue={state.old} readOnly />
                </Form.Group>
            }
            <Form.Group>
                <Form.Label>Nomor Ponsel {state.old ? "Baru" : "" }</Form.Label>
                <Form.Control
                    type="text" 
                    name="contact"
                    placeholder="Masukkan nomor ponsel aktif" 
                    ref={register({ 
                        required: "Nomor Ponsel "+(state.old ? "baru" : "")+" wajib diisi",
                        pattern: {
                            value: /^[0-9]*$/i,
                            message: "Nomor Ponsel tidak valid"
                        }
                    })}
                    onChange={(e) => handleChange(e)} 
                    defaultValue={state.contact}
                />
                <ErrorMessage errors={errors} name="contact" as={<p className="error-input text-left"></p>} />
                {errorMessage.contact && <p className="error-input">{errorMessage.contact}</p>}
            </Form.Group>

            <Button type="submit" className="mt-20" variant="primary" block  disabled={(isLoading ? true : false)}>
                { !isLoading && 'Verifikasi' }
                { isLoading && <Spinner animation="border" role="status"/> }
            </Button>
        </Form>
    )
}

class SettingUpdatePhone extends React.Component {
    state = {
        auth: {},
        old: '',
        contact: '',
        isVerify: false,
        needVerfy: false,
        verifyContact: '',
        isSavingUpdate: false
    }
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.showVerify = this.showVerify.bind(this)
        this.checkVerify = this.checkVerify.bind(this)
    }
    handleChange(e) {
        let verifyContact = this.state.auth.phone_verified_at ? this.state.old : '';
        let needVerfy = this.state.auth.phone_verified_at ? false : true;
        if(e.target.value && this.state.old !== e.target.value) {
            needVerfy = true
            verifyContact = e.target.value
        }
        
        this.setState({ contact: e.target.value, needVerfy, verifyContact })
    }
    showVerify() {
        this.setState({ isVerify: true })
    }
    checkVerify = async () => {
        const auth = JSON.parse(localStorage.getItem("userData"));
        const access_token = `Bearer ${auth.access_token}`;
        let formData = { type: "Phone", content_type: "code", contact: this.state.contact, action:'user_phone' };
        const config = {
            method: 'post',
            url: '/otp',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': access_token
            },
            data: formData
        };
        const res = await this.props.API(config).catch(err=>err);
        // const res = true
        if (res) {   
            this.setState({ isVerify: true })
        }

    }
    nextStep = async (resOtp) => {
        this.setState({isVerify:false});
        const auth = JSON.parse(localStorage.getItem("userData"));
        const access_token = `Bearer ${auth.access_token}`;
        let configSave = {
            method: 'put',
            url: '/user/phone',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': access_token
            }
        };
        configSave['data'] = { phone: this.state.contact, otp_code: resOtp.data }

        const save = await this.props.API(configSave).catch(err=>err);

        if(save) {
            let newAuth = {...this.state.auth};
            newAuth['phone'] = this.state.contact;
            newAuth['phone_verified_at'] = new Date();
            localStorage.setItem('userData', JSON.stringify(newAuth));
            
            toast.info('No Handphone berhasil diubah.', {
                position: "top-center",
                hideProgressBar: true,
                autoClose: 3000,
            });
            setTimeout(() =>{
                this.props.history.push('/pengaturan/personal-data');
            }, 1000 );
        }
    }
    componentDidMount() {
        this.props.clearDispatch();
        if(localStorage.getItem("userData")){
            const res = JSON.parse(localStorage.getItem("userData"))
            this.setState({ 
                auth: res,
                needVerfy: res.phone_verified_at ? false : true,
                verifyContact: res.phone_verified_at ? res.phone : '',
                // auth: { ...res, social_media: true },
                old: res.phone,
            })
        } else {
            this.props.history.push('/login')
        }
    }
    render() {
        return (
            <React.Fragment>
                {!this.state.isVerify && 
                    <>
                    <Header title={(this.state.old ? "Ganti" : "Tambah")+" Nomor Ponsel"} prevPage="/pengaturan/personal-data" />
                    <main>
                        <Container>
                            <div className="txt-title fw-6">{(this.state.old ? "Ganti" : "Tambah")+" Nomor Ponsel"}</div>
                            <div className="txt-caption">Masukkan nomor ponsel Anda. Kami akan mengirimkan kode verifikasi lewat nomor yang anda daftarkan.</div>
                            <div className="mt-4">
                                <SettingUpdatePhoneForm
                                    state={this.state}
                                    showVerify={this.showVerify}
                                    handleChange={this.handleChange}
                                    errorMessage={this.props.errorMessage}
                                    isLoading={this.props.isLoading}
                                    checkVerify={this.checkVerify}
                                />
                            </div>
                        </Container>
                    </main>
                    </>
                }
                {this.state.isVerify && 
                    <VerifyOTP 
                        title={"Verifikasi Nomor Ponsel"} 
                        prevPage="/pengaturan/personal-data" 
                        type="phone" 
                        contact={this.state.verifyContact} 
                        action="user_phone"
                        nextStep={this.nextStep}
                        sendPost={({type:'Phone', contact:this.state.verifyContact, action:'user_phone'})}
                    />}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data)),
    reloadUser: (config, auth) => dispatch(reloadUser(config, auth))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingUpdatePhone));