import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';

import './style.css';
import { Container, Dropdown, Image } from 'react-bootstrap';
import Loading from '../ReportList/Loading';
import icOptions from '../../../../assets/svg/ic-options.svg';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import Card from './Card';
import { Confirm } from '../../../organisms';

class ReportDetail extends React.Component {
    state = {
        report: {},
        auth: {},
        token: '',
        isFetchingReport: true,
        type:'',
        showConfirm:false,
        isCancelingReport:false
    }

    constructor(props) {
        super(props)
        this.confirmDelete = this.confirmDelete.bind(this)
        this.reportCancel = this.reportCancel.bind(this)
    }
    async apiReport (token) {
        this.setState({ isFetchingReport: true })
        const config = {
            method: 'get',
            url: '/campaign-report/detail/' + this.props.match.params.slug,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            this.setState({ report: res.data });
        }
        this.setState({ isFetchingReport: false })
    }
    componentDidMount (){
        this.props.clearDispatch();
        const { history } = this.props;

        const type = queryString.parse(this.props.location.search).type;
        this.setState({type:type});
        
        if(localStorage.getItem("userData")){
            const auth = JSON.parse(localStorage.getItem("userData"))
            const token = `Bearer ${auth.access_token}`
            this.setState({ auth, token })
            this.apiReport(token)
        }else{
            history.push('/login');
        }
    }
    confirmDelete(){
        this.setState({showConfirm:true })
    }

    async reportCancel(){
        this.setState({isCancelingReport:true});
        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = `Bearer ${auth.access_token}`;

        const code = this.props.match.params.slug;
        const config = {
            method: 'delete',
            url: '/campaign-report/'+code,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        const res = await this.props.API(config).catch(err=>err);
        if (res) {
            toast.info('Laporan berhasil ditarik', {
                position: "top-center",
                hideProgressBar: true,
                autoClose:3000,
            });
            setTimeout(() =>{
                this.props.history.push('/laporan?type'+this.state.type);
            },1000 );
        }
    }
    
    render() {
        let sectionStyle = {
            backgroundImage: (this.state.report ? (this.state.report.campaign ? `url(${this.state.report.campaign.cover})` : '') : '')
        };
        return (
            <React.Fragment>
                {/* <Header title="Detail Laporan" prevPage="/laporan" cols= /> */}
                <header className="campaigns-header">
                    <div className="nav-col-3 nav">
                        <div className="nav-item">
                            <Link className="nav-link" to={"/laporan?type="+this.state.type}>
                                <i className="ic-header ic-arrow-left"></i>
                            </Link>
                        </div>
                        <div className="nav-item">
                            <div className="head-title txt-body-m">Detail Laporan</div>
                        </div>
                        <div className="nav-item">
                            <div className="header-menu-link float-right clear-both">
                                <Dropdown className="btn-dropdown float-right">
                                    <Dropdown.Toggle>
                                        <Image src={icOptions}/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {this.state.type === 'reported' ? 
                                            <Dropdown.Item href="/faq">
                                                <span className="txt-body-m">Pusat Bantuan</span>
                                            </Dropdown.Item>
                                            :
                                            <Dropdown.Item onClick={()=>this.confirmDelete()}>
                                                <span className="txt-body-m">Tarik Laporan</span>
                                            </Dropdown.Item>
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </header>
                <main className="report-detail">
                    {!this.state.isFetchingReport && 
                        <>
                        <div className={(this.state.report.status === 'Aktif' ? "box-gradient-botup-brown" : "box-gradient-botup-gray")}>
                            <div className="report-status">
                                <div className="txt-subtitle fw-6 ct-title mb-2">Laporan {(this.state.report.status === 'Aktif' ? 'Dalam Tindak Lanjut' : 'Selesai')}</div>
                                <div className="txt-body-m">
                                    {this.state.report.status === 'Aktif' ? 
                                        <>
                                            Admin kami sedang menangani laporan ini. Proses ini akan ditangani maksimal 4 hari kerja.
                                        </>
                                        :
                                        <>
                                            Laporan telah kami tindaklanjuti dan penggalangan dana ini telah diblokir
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="content-space"></div>
                        </div>
                        {this.state.type === 'reported' && 
                            <>
                            <div className="section">
                                <div className="txt-subtitle fw-6 ct-title mb-2">Respon Status</div>
                                {!this.state.report.responses.length ? 
                                    <>
                                        <div className="txt-body-m mb-2">Respon Anda diperlukan untuk menyelesaikan masalah ini.</div>
                                        <Link to={"/respond-report/"+this.props.match.params.slug} className="btn btn-primary btn-sm">Respon Sekarang</Link>
                                    </>
                                    :
                                    <>
                                        {this.state.report.responses.map((respond, i) => 
                                            <Card key={i} data={respond} />
                                        )}
                                    </>
                                }
                            </div>
                            <div className="content-space"></div>
                            </>
                        }
                        <Container>
                            <div className="txt-subtitle fw-6 ct-title mb-3">{this.state.report.category || 'Tidak ada kategori'}</div>
                            <div className="txt-caption">
                                <span>Dibuat pada </span>
                                <Moment format=" D MMM YYYY" className="txt-caption">
                                    {this.state.report.created_at}
                                </Moment>
                            </div>
                            <div className="widget-campaign mt-2">
                                <div className="campaign-card-image">
                                    <div className="center-cropped" style={sectionStyle}>
                                    </div>
                                </div>
                                <div>
                                    <div className="txt-body-m">{this.state.report.campaign.title}</div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="txt-caption">Penggalang Dana</div>
                                <div className="txt-body-m ct-title">{this.state.report.campaigner}</div>
                            </div>
                            <hr />
                            <div className="mt-3">
                                <div className="txt-caption">Keterangan</div>
                                <div className="txt-body ct-title">{this.state.report.description}</div>
                            </div>
                            <div className="mt-3">
                                <div className="txt-caption">Lampiran</div>
                                <div className="report-evidence">
                                    {this.state.report.images.map((val, key) => (
                                        <Image src={val} key={key} />
                                    ))}
                                </div>
                            </div>
                        </Container>
                        </>
                    }
                    {this.state.isFetchingReport && <Loading />}

                    <Confirm
                        onHide={() => this.setState({showConfirm:false})} 
                        show={this.state.showConfirm}
                        isProcessing={this.state.isCancelingReport}
                        action={this.reportCancel}
                        title="Tarik Laporan Ini"
                        body="Jika Anda tarik, maka penggalangan ini akan kembali seperti semula."
                        label_cancel="Batalkan"
                        label_yes="Tarik Laporan"
                        cancelModal={() => this.setState({showConfirm:false})}
                    />
                </main>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportDetail));