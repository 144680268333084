import React, {Fragment} from 'react';
import { Container, Image, Modal } from 'react-bootstrap';
import { withRouter, Link } from "react-router-dom";

import icClose from '../../../../assets/svg/ic-close.svg';
import icCloseModal from '../../../../assets/svg/ic-close-modal.svg';
import icChevronDown from '../../../../assets/svg/ic-chevron-down.svg';

import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';
import debounce from "lodash.debounce";
import { Filter, FilterDate, Confirm } from "../../../organisms";

import './style.css';

//Load Component
import { Header } from '../../../organisms';
import { Input } from '../../../../component/atoms';
import Card from './Card';
import Loading from './Loading';
import { toast } from 'react-toastify';
import queryString from 'query-string';

const ModalCardOptions =  (props) => {
    const { show, onHide, reportCode, confirmDelete, tabActive } = props
    return (
        <Modal show={show} onHide={onHide} animation={false} centered className="modal-bottom">
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">
                    <Image src={icCloseModal} onClick={onHide} className="cursor-pointer" /> 
                    <span className="txt-title">Lainnya</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="no-border">

                    {tabActive === 'reported' &&
                        <Link className="list-option" to={"/respond-report/"+reportCode+'?prevPage=laporan'}>
                            <div>Respon</div>
                        </Link>
                    }
                    {tabActive === 'reporter' &&
                        <div className="list-option" onClick={()=>confirmDelete()}>
                            <div>Tarik Laporan</div>
                        </div>
                    }
                    <Link className="list-option" to="/faq">
                        <div>Bantuan</div>
                    </Link>
                </div>
            </Modal.Body>
        </Modal>
    )
}

class CampaignSaya extends React.Component {
    state = {
        filterParams: { 
            keyword: '',
            status: 'Semua' 
        },
        keyword: '',

        /** Results state */
        results: [],
        isFetchingResults: false,
        currentPage: 1,
        total: -1,

        
        /** Filter */
        filterSearch: '',
        filterStatus: '',
        filterKategori: '',

        categories:[],

        /** Filter */
        states:[
            {
                name:'Selesai',
                slug:'processed'
            },
            {
                name:'Aktif',
                slug:'pending'
            },
        ],
        
        showConfirm:false,
        showFilterStatus:false,
        showFilterKategori:false,
        showFilterTanggal:false,
        showCardOptions:false,
        startDate:false,
        endDate:false,
        reportCode:false,
        tabActive:'reporter',
        isCancelingReport:false
    }

    constructor(props) {
        super(props)
        this.handleScroll = this.handleScroll.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.resetKeyword = this.resetKeyword.bind(this)

        this.resetResults = this.resetResults.bind(this)
        this.resetResults = debounce(this.resetResults, 500)

        this.handleFilterStatus = this.handleFilterStatus.bind(this)
        this.handleFilterKategori = this.handleFilterKategori.bind(this)
        this.handleFilterTanggal = this.handleFilterTanggal.bind(this)
        this.handleOptionsCard = this.handleOptionsCard.bind(this)
        this.confirmDelete = this.confirmDelete.bind(this)
        this.reportCancel = this.reportCancel.bind(this)
    }

    handleSearch(e) {
        this.setState({ filterSearch: e.currentTarget.value })
        if(e.currentTarget.value.length > 1 || e.currentTarget.value.length === 0) {
            this.resetResults()
        }
    }
    handleFilterStatus(status) {
        this.setState({ filterStatus: status })
    }
    handleFilterKategori(kategori) {
        this.setState({ filterKategori: kategori })
    }
    handleFilterTanggal(tanggal) {
        this.setState({ filterTanggal: tanggal })
        if(tanggal === 30 || tanggal === 90){
            let day = new Date();
            let pastDay = new Date(day);
            pastDay.setDate(day.getDate() - tanggal);
            let startDate = new Date(pastDay).toISOString()
            let endDate = new Date().toISOString()
            this.setState({startDate: startDate, endDate: endDate})
        }
    }
    checkdate = (date, type) => {
        let newDate = new Date(date).toISOString();
        if(type === 'startDate'){
            this.setState({startDate: newDate})
        }else{
            this.setState({endDate: newDate})
        }
    }

    formatDate = (date) => {
        let d = (date ? new Date(date) : new Date());
        let mm = d.getMonth() + 1;
        let dd = d.getDate();
        let yy = d.getFullYear(); 
        if(mm < 10)
          mm = '0' + mm
        let myDateString = yy + '-' + mm + '-' + dd;
        return myDateString;
    }

    handleOptionsCard(code){
        this.setState({reportCode:code, showCardOptions:true})
    }

    confirmDelete(){
        this.setState({showConfirm:true })
        this.onHideModal('cardOptions');
    }
    
    onHideModal(container){
        switch (container) {
            case 'status':
                this.setState({ showFilterStatus: false })
                break;
            case 'kategori':
                this.setState({ showFilterKategori: false })
                break;
            case 'tanggal':
                this.setState({ showFilterTanggal: false })
                break;
            case 'cardOptions':
                this.setState({ showCardOptions: false })
                break;
            case 'confirm':
                this.setState({ showConfirm: false })
                break;
            default:
                break;
        }
    }

    resetResults() {
        this.setState({ 
            isFetchingResults: true, 
            currentPage: 1, 
            total: -1, 
            results: [],
            showFilterStatus:false,
            showFilterKategori:false,
            showFilterTanggal:false,
            showCardOptions:false,
            showConfirm:false,
            isCancelingReport:false,
        })
    }

    async loadCategories() {
        const config = {
            method: 'get',
            url: '/campaign-report/categories',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            }
        };
        const res = await this.props.API(config, false).catch(err=>err)
        if (res) {
            let data = res.data;
            let newData = []
            Object.keys(data).forEach(function(key) {
                newData.push({
                    name: data[key].name,
                    slug: data[key].id
                })
            })
            this.setState({ categories: newData })
        }
    }

    handleScroll() {
        const isScrolled = document.documentElement.offsetHeight - Math.ceil(window.innerHeight + document.documentElement.scrollTop);
        if (isScrolled > 150 || this.state.isFetchingResults)
            return;
        this.setState({isFetchingResults: true})
    }

    resetKeyword(e) {
        this.setState({ filterSearch: '' })
        this.resetResults()
    }

    apiReports = async () => {
        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = `Bearer ${auth.access_token}`;
        const perPage = 3
        const { filterParams, currentPage } = this.state
        
        let params = '?per_page='+perPage+'&page='+currentPage;

        if(this.state.filterStatus) {
            params += `&status=` + this.state.filterStatus
        }
        if(this.state.filterKategori) {
            params += `&campaign_report_category_id=` + this.state.filterKategori
        }
        if(this.state.filterTanggal && this.state.startDate && this.state.endDate) {
            params += `&date_range=` + this.state.startDate +','+this.state.endDate
        }
        if(this.state.filterSearch.length > 1) {
            params += `&str=` + this.state.filterSearch
        }

        if(filterParams){
            if(filterParams.keyword){
                params += '&str='+filterParams.keyword;
            }
        }
        const typeReport = (this.state.tabActive === 'reporter' ? 'me' : 'my-campaign');
        const config = {
            method: 'get',
            url: '/campaign-reports/'+typeReport+params,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            this.setState({ 
                results: [ ...this.state.results, ...res.data.data ], 
                isFetchingResults: false,
                total: res.data.total,
                currentPage: this.state.currentPage + 1
            })

            if(window.innerHeight > 640 && this.state.results.length <= perPage)
                this.setState({ isFetchingResults: true })
        }
    }

    async reportCancel(){
        this.setState({isCancelingReport:true});
        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = auth.access_token;

        const code = this.state.reportCode;
        const config = {
            method: 'delete',
            url: '/campaign-report/'+code,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        const res = await this.props.API(config).catch(err=>err);
        if (res) {
            toast.info('Laporan berhasil ditarik', {
                position: "top-center",
                hideProgressBar: true,
                autoClose:3000,
            });
            setTimeout(() =>{
                this.resetResults()
            },1000 );
        }
    }
    
    componentDidMount (){
        this.props.clearDispatch();
        const {history} = this.props;

        let type = queryString.parse(this.props.location.search).type;
        type = !type ? 'reporter' : type;
        this.setState({tabActive:type});
        
        if(localStorage.getItem("userData")){
            window.addEventListener('scroll', this.handleScroll);
            this.loadCategories();
            this.setState({ isFetchingResults: true })
        }else{
            history.push('/login');
        }
    }
  
    componentDidUpdate(_, prevState) {
        const { isFetchingResults, results, total, filterSearch, filterStatus, filterKategori, filterDate, startDate, endDate, tabActive } = this.state;
        if(tabActive !== prevState.tabActive){
            this.resetResults()
        }
        if(this.state.isFetchingResults !== prevState.isFetchingResults) {
            if ( filterSearch !== prevState.filterSearch || filterStatus !== prevState.filterStatus 
                || filterKategori !== prevState.filterKategori || filterDate !== prevState.filterDate 
                || startDate !== prevState.startDate || endDate !== prevState.endDate || tabActive !== prevState.tabActive 
                ) {
                this.apiReports()
                return
            }

            if (isFetchingResults === false || (results.length === total && total !== -1)) {
                this.setState({isFetchingResults: false});
                return;
            }
            this.apiReports();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll); 
    }

    setTabActive = (status) => {
        this.setState({tabActive:status});
        const {history} = this.props;
        history.push({
            pathname: '/laporan',
            search: '?type='+status
        })
    }

    render() {
        const { 
            isFetchingResults, 
            results, 
            filterSearch
        } = this.state;
        return(
            <Fragment>
                <Header title="Laporan Saya" prevPage="/profile" />
                <main className="campaign-saya mycampaign-report">
                    <div className="filter search-page">
                        <div className="section">
                            <Input 
                                type="text" 
                                name="search"
                                formGroupClass="search-form txt-body" 
                                placeholder="Cari Laporan..." 
                                handleFormChange={this.handleSearch}
                                value={filterSearch}
                                icon_left="assets/img/ic-search-gray.svg"
                                icon_right={(filterSearch ? icClose : '')}
                                icon_right_click = {this.resetKeyword}
                            />
                            
                            <ul className="modal-filter">
                                <li className={(this.state.filterStatus === '' ? '' : 'active')} onClick={() => this.setState({showFilterStatus:true})}>
                                    <div>Status </div>
                                    <Image src={icChevronDown}/>
                                </li>
                                <li className={(this.state.filterKategori === '' ? '' : 'active')} onClick={() => this.setState({showFilterKategori:true})}>
                                    <div>Kategori </div>
                                    <Image src={icChevronDown}/>
                                </li>
                                <li className={(this.state.filterTanggal && this.state.startDate && this.state.endDate ? 'active' : '')} onClick={() => this.setState({showFilterTanggal:true})}>
                                    <div>Waktu </div>
                                    <Image src={icChevronDown}/>
                                </li>
                                
                            </ul>

                            <div className="tab-menu txt-caption">
                                <div className={(this.state.tabActive === 'reporter' ? 'active' : '')} onClick={() => this.setTabActive('reporter')}>Sebagai Pelapor</div>
                                <div className={(this.state.tabActive === 'reported' ? 'active' : '')} onClick={() => this.setTabActive('reported')}>Program Saya</div>
                            </div>
                        </div>
                        <div className="content-space"></div>
                    </div>
                    <Container className="list-campaign">
                        {results.map(report => <Card key={report.code} data={report} tabActive={this.state.tabActive} handleOptions={this.handleOptionsCard} />)}
                        {isFetchingResults && <Loading/>}
                        {!this.state.isFetchingResults && this.state.results.length === 0 &&
                            <div className="text-center txt-body mt-5 pt-5">Data tidak ditemukan</div>
                        }
                    </Container>

                    <Filter 
                        onHide={() => this.onHideModal('status')} 
                        field={this.state.filterStatus} 
                        handleFilter={this.handleFilterStatus} 
                        show={this.state.showFilterStatus}
                        resetResults={this.resetResults}
                        options={this.state.states}
                        label="Status"
                    />
                    <Filter 
                        onHide={() => this.onHideModal('kategori')} 
                        field={this.state.filterKategori} 
                        handleFilter={this.handleFilterKategori} 
                        show={this.state.showFilterKategori}
                        resetResults={this.resetResults}
                        options={this.state.categories}
                        label="Kategori"
                    />
                    <FilterDate 
                        onHide={() => this.onHideModal('tanggal')} 
                        filter={this.state.filterTanggal} 
                        handleFilter={this.handleFilterTanggal} 
                        show={this.state.showFilterTanggal}
                        resetResults={this.resetResults}
                        checkdate={this.checkdate}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                    />
                    <ModalCardOptions
                        onHide={() => this.onHideModal('cardOptions')} 
                        show={this.state.showCardOptions}
                        reportCode={this.state.reportCode}
                        confirmDelete={this.confirmDelete}
                        tabActive={this.state.tabActive}
                    />
                    <Confirm
                        onHide={() => this.onHideModal('confirm')} 
                        show={this.state.showConfirm}
                        isProcessing={this.state.isCancelingReport}
                        action={this.reportCancel}
                        title="Tarik Laporan Ini"
                        body="Jika Anda tarik, maka penggalangan ini akan kembali seperti semula."
                        label_cancel="Batalkan"
                        label_yes="Tarik Laporan"
                        cancelModal={() => this.setState({showConfirm:false, showCardOptions:true})}
                    />
                </main>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignSaya));