import React from "react";
import Cropper from "cropperjs";
import {Image, Form, Modal, Button } from 'react-bootstrap';
import "cropperjs/dist/cropper.min.css";
import "./style.css";
import imageCompression from "browser-image-compression";
import {base64StringtoFile,extractImageFileExtensionFromBase64} from './ResuableUtils';

const imageMaxSize = 5000000 // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {return item.trim()})

class CropFile extends React.Component {
    constructor() {
        super();
        this.fileInputRef = React.createRef()
        this.state = {
            imgSrc: null,
            imgSrcExt: null,
            imageDestination: "",
            isLoading:false
        };
    }

    verifyFile = (files) => {
        if (files && files.length > 0){
            const currentFile = files[0]
            const currentFileType = currentFile.type
            const currentFileSize = currentFile.size
            if(currentFileSize > imageMaxSize) {
                alert("This file is not allowed. " + currentFileSize + " bytes is too large")
                return false
            }
            if (!acceptedFileTypesArray.includes(currentFileType)){
                alert("This file is not allowed. Only images are allowed.")
                return false
            }
            return true
        }
    }

    handleLoading = () => {
        const {isLoading} = this.state;
        this.setState({isLoading: !isLoading});
    }

    handleFileSelect = event => {
        this.handleLoading();
        const files = event.target.files

        this.setState({imgSrc:null, originalImage:files[0]});
        if (files && files.length > 0){
            const isVerified = this.verifyFile(files)
            if (isVerified){
                const currentFile = files[0]

                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 700,
                    useWebWorker: true,
                    onProgress: Function
                };
              
                imageCompression(currentFile, options).then(output => {
                    var reader = new FileReader();
                    reader.readAsDataURL(output); 
                    reader.addEventListener("load", ()=>{
                        var myResult = reader.result;
                        this.setState({
                        imgSrc: myResult,
                        imgSrcExt: extractImageFileExtensionFromBase64(myResult)
                        });
          
                        const cropper = new Cropper(this.fileInputRef.current, {
                            zoomable: false,
                            scalable: false,
                            ...this.props.aspectRatio === 'auto' ? {} : { aspectRatio: 1 },
                            // responsive: true,
                            // zoomOnWheel: false,
                            // zoomOnTouch: false,
                            // dragMode:"none",
                            // background:false,
                            // toggleDragModeOnDblclick:false,
                            crop: () => {
                                const canvas = cropper.getCroppedCanvas();
                                this.setState({ imageDestination: canvas.toDataURL("image/png") });
                            }
                        });

                        this.handleLoading();
                    });
                      
                });
             }
        }
        
    }

    handleDownloadClick = (event) => {
        event.preventDefault();
        const {imgSrcExt, imageDestination} =  this.state
        const myFilename = this.props.avatarFileName +'.'+ imgSrcExt
        const myNewCroppedFile = base64StringtoFile(imageDestination, myFilename)
        // downloadBase64File(imageDestination, myFilename)
        let returnFileCropped = {base64image: imageDestination, filePost:myNewCroppedFile};
        this.setState({imgSrc:null});
        this.props.handleModal(returnFileCropped);
    }
    closeModal = () => {
        this.setState({imgSrc:null});
        this.props.handleModal(null);
    }

    render() {

        const {imgSrc, isLoading} = this.state;
        return (
            <Modal show={this.props.show} onHide={this.closeModal} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="form-upload">
                            <Form.Label>{this.props.label}</Form.Label>
                            <Form.File
                            className="position-relative"
                            ref={this.fileInputRef} type='file' 
                            accept={acceptedFileTypes} multiple={false} 
                            onChange={this.handleFileSelect} />
                        </Form.Group>
                        {imgSrc && 
                            <div>
                                <div className="img-container">
                                    <Image ref={this.fileInputRef} src={imgSrc} alt="Source" />
                                </div>

                                <div className="img-preview">
                                    <div>Gambar yang akan ditampilkan :</div>
                                    <Image 
                                        src={this.state.imageDestination} 
                                        className={"avatar " + (this.props.previewClass ? this.props.previewClass : "")} 
                                        alt="Destination" />
                                </div>
                            </div> 
                        }

                        { isLoading && <p>Sedang diproses...</p>}

                    </Form>
                </Modal.Body>
                {imgSrc && 
                    <Modal.Footer>
                        <Button variant="primary" block size="lg" onClick={this.handleDownloadClick}>
                            Simpan
                        </Button>
                    </Modal.Footer>
                }
            </Modal>
        );
    }

}

export default CropFile;