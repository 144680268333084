import React, {Fragment} from 'react';
import { Container, Image, Spinner } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { withRouter } from "react-router";
import { ErrorMessage } from '@hookform/error-message';

import firebase from "../../../../config/Firebase"

import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';
import * as QueryString from "query-string";
import { Header } from '../../../organisms';
import { GoogleLogin } from 'react-google-login';



import './style.css';

//Load Component
const FormLogin = props => {
    const { handleSubmit, register, errors } = useForm();
    return (
        <form className="form-hook mt-3" onSubmit={handleSubmit(props.handleSubmit)}>
            <div className="form-group">
                <label className="form-label">Email / Nomor Ponsel</label>
                <input
                type="text"
                name="username"
                className="form-control"
                ref={register({ required: "Email / Nomor Ponsel wajib diisi" })}
                onChange={props.handleChange}
                />
                <ErrorMessage errors={errors} name="username" as={<p className="error-input text-left"></p>} />
                {props.errorMessage.username && 
                <p className="error-input">{props.errorMessage.username}</p>}
            </div>
            <div className="form-group">
                <label className="form-label">Password</label>
                <div className="input-group">
                    <input
                    type={(props.isPasswordShown) ? "text" : "password"}
                    name="password"
                    className="form-control"
                    ref={register({ required: "Password wajib diisi" })}
                    onChange={props.handleChange}
                    />
                    <div className="cursor-pointer input-group-prepend" onClick={props.togglePasswordVisibility}>
                        <span className="input-group-text">
                            <Image src={(props.isPasswordShown) ? "assets/img/eye-off-line.svg" : "assets/img/eye-open.svg"} className=""/>    
                        </span>
                    </div>
                </div>
                
                <ErrorMessage errors={errors} name="password" as={<p className="error-input text-left"></p>} />
                {props.errorMessage.password && 
                <p className="error-input">{props.errorMessage.password}</p>}
            </div>

            <div className="lbl-forgot-pass float-right txt-body-m fw-6 mb-20"><Link to="/forgot-password">Lupa Kata Sandi?</Link></div>
                            

            <button className="btn btn-primary btn-block" type="submit" disabled={props.isLoading}>
                { (!props.isLoading) && <span>Masuk</span> }
                { props.isLoading && <Spinner animation="border" role="status"/> }
            </button>
        </form>
    );
}
class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            formPost: {},
            isPasswordShown:false,
            isLoadingFirebase: false,
            uidFireBase:''
        }
    }
    uiConfig = {
        signInFlow: "popup",
        signInOptions: [
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        //   firebase.auth.FacebookAuthProvider.PROVIDER_ID
        ],
        callbacks: {
            signInSuccessWithAuthResult : () => false
        }
    }

    handleFormChange = (e) =>{
        let formPostNew = {...this.state.formPost};
        formPostNew[e.target.name] = e.target.value;

        let errorMessageNew = this.props.errorMessage;
        if(errorMessageNew.length > 0){
        }else{
            if(formPostNew[e.target.name]){
                errorMessageNew[e.target.name] = '';
            }
        }
        this.setState({
            formPost: formPostNew,
            errorMessage: errorMessageNew
        })
    }

    handleSubmit = async () => {
        const config = {
            method: 'post',
            url: '/auth/login',
            headers: {
                'Content-Type': 'application/json',
            },
            data: this.state.formPost
        };
        const res = await this.props.API(config).catch(err => err);
        if (res) {
            localStorage.setItem('userData', JSON.stringify(res.data));
            this.redirectLogin();
        }
    }

    loginFirebase = async () => {
        this.setState({isLoadingFirebase:true});

        const config = {
            method: 'post',
            url: '/auth/login-firebase',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {uid:this.state.uidFireBase}
        };
        const res = await this.props.API(config).catch(err => err);
        if (res) {
            localStorage.setItem('userData', JSON.stringify(res.data));
            this.redirectLogin();
        }else{
            this.setState({isLoadingFirebase:false});
            firebase.auth().signOut().then(function() {
                // Sign-out successful.
            }).catch(function(error) {
            // An error happened.
            });
        }
    }



    togglePasswordVisibility = () => {
        const {isPasswordShown} = this.state;
        this.setState({isPasswordShown: !isPasswordShown});
    }

    componentDidMount() {
        this.props.clearDispatch();

        if(localStorage.getItem("userData")){
            this.redirectLogin();
        }else{
            firebase.auth().onAuthStateChanged(user => {
                if(user){
                    this.setState({ uidFireBase: user.uid});
                    this.loginFirebase();
                }
                
            })
        }
    }

    redirectLogin = () => {
        const params = QueryString.parse(this.props.location.search);
        let url = '/profile';
        if(params.campaign){
            url = "/campaign/"+params.campaign;
        }
        if(params.donation){
            url = "/donation/"+params.donation+"/step2";
        }
        if(params.donasiUang){
            url = "/donasi-uang/tetangga-bantu-tetangga/step2";
        }
        if(params.ref){
            url = "/"+params.ref;
        }
        if(params.prevPage){
            url = "/"+params.prevPage;
        }
        const {history} = this.props;
        history.push(url);
    }

    responseGoogle = async (response) => {
        console.log(response);
        if(response.accessToken){
            const access_token = response.accessToken;
            console.log(access_token)

            this.setState({isLoadingFirebase:true});

            const config = {
                method: 'post',
                url: '/auth/login-google',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {accessToken: access_token}
            };
            const res = await this.props.API(config).catch(err => err);
            if (res) {
                localStorage.setItem('userData', JSON.stringify(res.data));
                this.redirectLogin();
            }else{
                this.setState({isLoadingFirebase:false});
                // firebase.auth().signOut().then(function() {
                //     // Sign-out successful.
                // }).catch(function(error) {
                // // An error happened.
                // });
            }
        }
    }

    render() {
        const { isPasswordShown } = this.state
        return(

            <Fragment>
                <Header title=" " prevPage="/" headerclass="no-bs"/>
                <main>
                    <Container className="container-login">
                        <div className="mb-5 text-left">
                            <div className="txt-title-m fw-6 mb-1">Salam, Tetangga Baik</div>
                            <div className="txt-body">Masuk ke akun Anda</div>
                        </div>
                        <FormLogin 
                            handleChange={this.handleFormChange} 
                            errorMessage={this.props.errorMessage}
                            handleSubmit={this.handleSubmit}
                            isLoading={this.props.isLoading}
                            isPasswordShown={isPasswordShown}
                            togglePasswordVisibility = {this.togglePasswordVisibility}

                            
                        />
                        <div className="container-firebase txt-body-m mt-10">
                            <div>Belum punya Akun? <Link to="/register" className="fw-6">Daftar Sekarang</Link></div>
                            <div className="or txt-caption ct-body">atau Masuk dengan</div>
                            {/* <StyledFirebaseAuth
                                uiConfig={this.uiConfig}
                                firebaseAuth={firebase.auth()}
                            /> */}
                            <GoogleLogin
                                clientId={process.env.REACT_APP_GOOGLE_APP_KEY}
                                buttonText="Google"
                                onSuccess={this.responseGoogle}
                                onFailure={this.responseGoogle}
                                cookiePolicy={'single_host_origin'}
                                className="btn-google-login"
                            />
                        </div>
                    </Container>
                </main>
            </Fragment>

        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage,
    isLogin: state.isLogin
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (config, isDispatch) => dispatch(API(config, isDispatch)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (Login));
