export default function updateAction(state, payload) {
  return {
    ...state,
    postData: {
      ...state.postData,
      ...payload
    }
  };
}
    
    