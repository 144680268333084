import React from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { useHistory, Link } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "../../../../config/FormWizard/updateAction";
import {Spinner} from "react-bootstrap";

import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';
import { Header, Wizard } from '../../../organisms';
import {StringToSlug} from '../../../../utils';

const PageStep1 = (props) => {
  const { state, action } = useStateMachine(updateAction);
  const auth = JSON.parse(localStorage.getItem("userData"));
  const linkPrev = "/preverification/type";
  const { handleSubmit, errors, register } = useForm({
    defaultValues: state.postData
  });
  const { push } = useHistory();
  const onSubmit = data => {
    checkValues(data);
  };

  const checkValues = async (data) => {
    let formData = {
      name: state.postData.name,
      slug: StringToSlug(state.postData.name),
      email: state.postData.email,
      phone: (state.postData.phone ? state.postData.phone : auth.phone),
      just_validate:true
    };
    
    const editCampaignerSlug = (state.postData.editCampaignerSlug ? state.postData.editCampaignerSlug : '');;
    const token = `Bearer ${auth.access_token}`;
    const config = {
        method: (editCampaignerSlug === '' ? 'post' : 'put'),
        url: '/campaigner',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        data: formData
    };

    const res = await props.propsAPi(config, true, true).catch(err=>err);
    if (res) {
      if(auth.phone_verified_at){
        data['step1'] = true;
        data['phone'] = auth.phone;
      }
      action(data);
      push("/verifikasi-lembaga/step2");
    }else{
      let data={};
      data['checkMessage'] = true;
      action(data);

    }
  }

  const handleChange = (e) =>{
    let val = e.target.value;
    let field = e.target.name;
    let data = {};
    data[field] = val;
    data['step1'] = false;
    if(field === 'sosmed'){
      let urlsosmed;
      if(val === "instagram")
        urlsosmed = 'www.instagram.com';
      if(val === "facebook")
        urlsosmed = 'www.facebook.com';
      if(val === "twitter")
        urlsosmed = 'www.twitter.com'; 
      data['urlsosmed'] = urlsosmed;
    }

    if(props.errMessages[field]){
      let fieldChanges = {};
      fieldChanges = props.errMessages;
      fieldChanges[field] = '';
      props.propsClearDispatch(fieldChanges);
    }

    data['vlAutofocus'] = '';
    action(data);
    
  }
  const initialSteps = {
    // class : step-hide/step-active/""
    // class1:"step-active",
    // number1:1,
    // label1:"Data Lembaga",

    // class2:"",
    // number2:2,
    // label2:"Lokasi Lembaga",

    // class3:"",
    // number3:3,
    // label3:"Dokumentasi",

    class1:"step-hide",
    number1:"",
    label1:"",
    link1:"",

    class2:"step-active",
    number2:1,
    label2:"Detail",
    link2:"/verifikasi-lembaga",

    class3:"",
    number3:2,
    label3:"Lokasi",
    link3:"/verifikasi-lembaga/step2",
  }
  return (
    <>
      <Header title="Verifikasi Lembaga" prevPage={linkPrev} ic_white={true}/>
      <Wizard initialSteps={initialSteps} />
      <form onSubmit={handleSubmit(onSubmit)} className="form-hook">
        <div className="txt-subtitle-m fw-6 mb-20">Isi Detail Lembaga Anda</div>
        <div className="form-group">
          <label className="form-label">Nama Lembaga</label>
          <input
            name="name"
            className="form-control"
            ref={register({ required: "Nama wajib diisi" })}
            onChange={handleChange}
            placeholder="Masukkan nama lembaga sesuai legal"
          />
          <ErrorMessage errors={errors} name="name" as={<p className="error-input"></p>} />
          {props.errMessages.name && 
          <p className="error-input">{props.errMessages.name}</p>}
        </div>

        <div className="form-group">
          <label className="form-label">Email Lembaga</label>
          <input
            name="email"
            className="form-control"
            placeholder="Masukkan alamat email aktif"
            ref={register({ 
              required: "Email Lembaga wajib diisi",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Format email salah"
              } 
            })}
            defaultValue={auth.email}
            onChange={handleChange}
          />
          <ErrorMessage errors={errors} name="email" as={<p className="error-input"></p>} />
          {props.errMessages.email && 
          <p className="error-input">{props.errMessages.email}</p>}
        </div>
        {!auth.phone ? 
          <div className="form-group">
            <label className="form-label">No Ponsel Lembaga</label>
            <input
              name="phone"
              className="form-control"
              placeholder="08xxxxxxx"
              ref={register({ required: "No Ponsel Lembaga wajib diisi" })}
              onChange={handleChange}
              autoFocus={(state.postData.vlAutofocus === 'phone' ? true : false)}
            />
            <ErrorMessage errors={errors} name="phone" as={<p className="error-input"></p>} />
            {props.errMessages.phone && 
            <p className="error-input">{props.errMessages.phone}</p>}
          </div>
          :
          
          <div className="form-group">
            <label className="form-label">No Ponsel lembaga</label>
            <div className="content-grid grid-2 txt-body-m">
              <div className="phone-verified">{auth.phone}</div>
              <div><Link to="/pengaturan/update-phone?prevPage=verifikasi-lembaga" className="fw-6">Ganti</Link></div>
            </div>
            {props.errMessages.phone && 
             <p className="error-input">{props.errMessages.phone}</p>}
          </div>
        }
        
        <div className="form-group">
          <label className="form-label">Deskripsi Lembaga</label>
          <textarea
            row="35"
            name="bio"
            className="form-control"
            ref={register({ required: "Deskripsi lembaga wajib diisi" })}
            onChange={handleChange}
            placeholder="Ceritakan tentang lembaga anda"
          />
          <ErrorMessage errors={errors} name="bio" as={<p className="error-input"></p>} />
          {state.postData.errorMessages.bio && 
          <p className="error-input">{state.postData.errorMessages.bio}</p>}
        </div>
        <hr/>

        <div className="form-group">
          <label className="form-label">Sosial Media Lembaga</label>
          <select 
            className="form-control mb-2" 
            name="sosmed" onChange={handleChange}
            ref={register({ required: "Wajib dipilih" })}
            >
            <option value="">Pilih Salah Satu</option>
            <option value="instagram">Instagram</option>
            <option value="twitter">Twitter</option>
            <option value="facebook">Facebook</option>
          </select>
          <ErrorMessage errors={errors} name="sosmed" as={<p className="error-input"></p>} />

          <div className="content-url-sosmed">
            <div className="url">
              <input type="hidden" disabled name="urlsosmed"/>
              {state.postData.urlsosmed}
            </div>
            <div className="slash">/</div>
            <div className="username">
              <input 
                type="text"
                name="social_media"
                className="form-control"
                placeholder="username"
                ref={register({ required: "Akun Sosial Media wajib diisi" })}
                onChange={handleChange}
                />
            </div>
          </div>
          <ErrorMessage errors={errors} name="social_media" as={<p className="error-input"></p>} />
          {state.postData.errorMessages.social_media && 
          <p className="error-input">{state.postData.errorMessages.social_media}</p>}
        </div>
        <button type="submit" className="btn btn-primary btn-block btn-next mt-0" disabled={(props.isLoading ? true : false)}>
          { (!props.isLoading) && <span>Lanjutkan</span> }
          { props.isLoading && <Spinner animation="border" role="status"/> }
        </button>
      </form>
    </>
  );
};


class Step1 extends React.Component {
  render() {
    return(
      <PageStep1 
        errMessages={this.props.errorMessage} 
        isLoading={this.props.isLoading} 
        propsAPi={this.props.API} 
        propsClearDispatch={this.props.clearDispatch}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
  clearDispatch: (fieldChanges) => dispatch(clearDispatch(fieldChanges)),
  API: (path, data, showError) => dispatch(API(path, data, showError)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step1));
