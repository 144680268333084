import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Modal, Button, Image } from "react-bootstrap";
import { 
    FacebookShareButton, FacebookIcon,
    TwitterIcon, TwitterShareButton,
    LineIcon, LineShareButton,
    WhatsappIcon, WhatsappShareButton,
    TelegramIcon,TelegramShareButton,
} from "react-share";
import icFile from '../../../assets/svg/ic-file.svg';
import { toast } from 'react-toastify';

import './style.css'

export default function ShareModal ({ show, onHide, url, slug, quote, body }) {
    const baseurl = process.env.PUBLIC_URL;
    const plainBody = 'Website penggalangan dana (fundraising) online untuk membantu program sosial bersama Anda dan para #SobatBaik lainnya.';
    const shareUtm = '?utm_source=public=&utm_campaign='+slug
    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animation={false}
            className="share-modal" >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Bagikan Lewat</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex">
                    <FacebookShareButton 
                        url={baseurl+url+shareUtm+'&utm_medium=Facebook'}
                        title={plainBody} 
                        className="share-btn">
                        <FacebookIcon size={48} className="share-icon" />
                        {/* <h6 className="mt-2">Facebook</h6> */}
                    </FacebookShareButton>
                    <TwitterShareButton 
                        url={baseurl+url+shareUtm+'&utm_medium=Twitter'} 
                        title={plainBody} 
                        className="share-btn">
                        <TwitterIcon size={48} className="share-icon" />
                        {/* <h6 className="mt-2">Twitter</h6> */}
                    </TwitterShareButton>
                    <LineShareButton 
                        url={baseurl+url+shareUtm+'&utm_medium=Line'} 
                        title={plainBody} 
                        className="share-btn">
                        <LineIcon size={48} className="share-icon" />
                        {/* <h6 className="mt-2">Line</h6> */}
                    </LineShareButton>
                    <WhatsappShareButton 
                        url={baseurl+url+shareUtm+'&utm_medium=Whatsapp'} 
                        title={plainBody} 
                        separator=" - " 
                        className="share-btn">
                        <WhatsappIcon size={48} className="share-icon" />
                        {/* <h6 className="mt-2">Whatsapp</h6> */}
                    </WhatsappShareButton>
                    
                    <TelegramShareButton 
                        url={baseurl+url+shareUtm+'&utm_medium=Telegram'} 
                        title={plainBody} 
                        separator=" - " 
                        className="share-btn">
                        <TelegramIcon size={48} className="share-icon" />
                        {/* <h6 className="mt-2">Telegram</h6> */}
                    </TelegramShareButton>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="share-copy">
                    <div className="share-copy-icon">
                        <Image src={icFile} />
                    </div>
                    <div className="share-copy-text">
                        <p>{baseurl}{url.substring(0, 5)}...</p>
                    </div>
                    <div className="share-copy-btn">
                        <CopyToClipboard text={baseurl + url + shareUtm+'&utm_medium=Copy' } onCopy={() => { 
                            toast.info("Tautan Berhasil Disalin", {
                                // position: "top-center",
                                hideProgressBar: true,
                                autoClose: 3000,
                            });
                         }}>
                            <Button size="sm">Salin</Button>
                        </CopyToClipboard>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}