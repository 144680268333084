import React from 'react';
import './style.css';
import { NavLink } from "react-router-dom";
import { Nav } from 'react-bootstrap';

const NavMenu =  (props) => {
    return (
        <nav>
            <Nav className="navbar">
                <Nav.Item>
                    <NavLink exact activeClassName="active" to="/">
                        <i className="ic-menu ic-beranda"></i><span>Beranda</span>
                    </NavLink>
                </Nav.Item>
                <Nav.Item>
                    <NavLink exact activeClassName="active" to="/cek_donasi">
                        <i className="ic-menu ic-donasi-saya"></i><span>Donasiku</span>
                    </NavLink>
                </Nav.Item>
                <Nav.Item>
                    <NavLink exact activeClassName="active" to="/galang-dana">
                        <i className="ic-menu ic-donasi"></i><span>Galang Dana</span>
                    </NavLink>
                </Nav.Item>
                <Nav.Item>
                    <NavLink exact activeClassName="active" to="/news">
                        <i className="ic-menu ic-berita"></i><span>Berita</span>
                    </NavLink>
                </Nav.Item>
                <Nav.Item>
                    <NavLink exact activeClassName="active" to="/profile">
                        <i className="ic-menu ic-profil"></i><span>Profile</span>
                    </NavLink>
                </Nav.Item>
            </Nav>
        </nav>
    
    )
}

export default NavMenu;