import React from 'react'
import CurrencyFormat from 'react-currency-format';
import { Image } from "react-bootstrap";

const Suggest =  (props) => {
    return (
        <div className={"suggest-donate new "+(props.amount > 0 && props.amount === props.nominal ? 'sd-active' : '')} onClick={() => props.handleSuggestion(props.nominal)}>
            <div className={"d-flex"}>
                <CurrencyFormat 
                    value={props.nominal} 
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={'Rp '} 
                    displayType={'text'}
                    className="amount"
                />
            </div>
            
        </div>
    )
}

export default Suggest
