import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import debounce from "lodash.debounce";
import { Container, Image } from "react-bootstrap";
import icClose from '../../../assets/svg/ic-close.svg';

import { clearDispatch, API } from '../../../config/redux/action';
import { Header, DisbursementCard } from "../../organisms";
import { Input } from "../../../component/atoms";
import imgCampaignNotFound from '../../../assets/img/campaign-not-found.png';
import Loading from './Loading';
import './style.css'

class Disbursement extends React.Component {
    state = {
        token: null,
        /** Filter */
        filterStatus: 'all',
        filterKeyword: '',

        /** Summary state */
        summary: {},
        isFetchingSummary: false,

        /** Results state */
        results: [],
        isFetchingResults: false,
        currentPage: 1,
        total: -1,
    }
    constructor(props) {
        super(props)
        this.handleChangeKeyword = this.handleChangeKeyword.bind(this)
        this.handleScroll = this.handleScroll.bind(this)
        this.resetResults = debounce(this.resetResults, 500)
        
        this.state.auth = JSON.parse(localStorage.getItem("userData"));
        this.state.token = this.state.auth && this.state.auth.access_token ? `Bearer ${this.state.auth.access_token}` : null;
    }
    handleFilterStatus(status) {
        this.setState({ filterStatus: status })
        this.resetResults()
    }
    resetKeyword(e) {
        this.setState({ filterKeyword: '' })
        this.resetResults()
    }
    handleChangeKeyword(e) {
        this.setState({ filterKeyword: e.target.value })
        if(e.target.value.length !== 1){
            this.resetResults()
        }
    }
    resetResults() {
        this.setState({ isFetchingResults: true, currentPage: 1, total: -1, results: [] })
    }
    async apiSummary() {
        this.setState({ isFetchingSummary: true })

        const config = {
            method: 'get',
            url: '/campaigner/disbursement/summary',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            }
        };

        const res = await this.props.API(config, false).catch(err=>err)
        if (res) {
            this.setState({ summary: res.data })
        }

        this.setState({ isFetchingSummary: false })
    }
    async apiResult() {
        const perPage = 3;
        let params = `?page=${this.state.currentPage}&perPage=${perPage}`
        if(this.state.filterStatus !== 'all') {
            params += `&status=` + this.state.filterStatus
        }
        if(this.state.filterKeyword) {
            params += `&str=` + this.state.filterKeyword
        }

        const { API } = this.props
        const config = {
            method: 'get',
            url: '/campaigner/disbursement/histories' + params,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            }
        };
        const res = await API(config, false).catch(err=>err)
        if (res) {
            this.setState({ 
                results: [ ...this.state.results, ...res.data.data ], 
                isFetchingResults: false,
                total: res.data.total,
                currentPage: this.state.currentPage + 1
            })

            if(window.innerHeight > 640 && this.state.results.length <= perPage)
                this.setState({ isFetchingResults: true })
        }
    }
    handleScroll() {
        const isScrolled = document.documentElement.offsetHeight - Math.ceil(window.innerHeight + document.documentElement.scrollTop);
        if (isScrolled > 150 || this.state.isFetchingResults)
            return;
        this.setState({isFetchingResults: true})
    }
    componentDidMount() {
        if(this.state.auth) {
            window.addEventListener('scroll', this.handleScroll);
            this.setState({ isFetchingResults: true })
            this.apiSummary()
        } else {
            this.props.history.push('/login')
        }
    }
    componentDidUpdate(_, prevState) {
        if(this.state.isFetchingResults !== prevState.isFetchingResults) {
            const { isFetchingResults, results, total } = this.state;
            if (isFetchingResults === false || (results.length === total && total !== -1)) {
                this.setState({isFetchingResults: false});
                return;
            }
            this.apiResult();
        }
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll); 
    }
    render() {
        return (
            <React.Fragment>
                <Header title="Riwayat Pencarian" prevPage="/profile" />
                <main className="history-pencairan">
                    <div className="filter search-page">
                        <div className="section">
                            <Input 
                                type="text" 
                                name="search"
                                formGroupClass="search-form txt-body" 
                                placeholder="Cari Donasi..." 
                                handleFormChange={this.handleChangeKeyword}
                                value={this.state.filterKeyword}
                                icon_left="assets/img/ic-search-gray.svg"
                                icon_right={(this.state.filterKeyword ? icClose : '')}
                                icon_right_click = {this.resetKeyword}
                            />
                            <ul className="state">
                                <li 
                                    className={(this.state.filterStatus === 'all') ? 'active' : ''} 
                                    onClick={() => this.handleFilterStatus('all')} 
                                    data-value="all">
                                        Status
                                </li>
                                <li 
                                    className={(this.state.filterStatus === 'pending') ? 'active' : ''} 
                                    onClick={() => this.handleFilterStatus('pending')} 
                                    data-value="pending">
                                        Menunggu Approval ({this.state.summary.pending ? this.state.summary.pending : '0'})
                                </li>
                                <li 
                                    className={(this.state.filterStatus === 'approved') ? 'active' : ''} 
                                    onClick={() => this.handleFilterStatus('approved')} 
                                    data-value="approved">
                                        Diterima ({this.state.summary.approved ? this.state.summary.approved : '0'})
                                </li>
                                <li 
                                    className={(this.state.filterStatus === 'processed') ? 'active' : ''} 
                                    onClick={() => this.handleFilterStatus('processed')} 
                                    data-value="processed">
                                        Diproses ({this.state.summary.processed ? this.state.summary.processed : '0'})
                                </li>
                                <li 
                                    className={(this.state.filterStatus === 'finished') ? 'active' : ''} 
                                    onClick={() => this.handleFilterStatus('finished')} 
                                    data-value="finished">
                                        Selesai ({this.state.summary.finished ? this.state.summary.finished : '0'})
                                </li>
                                <li 
                                    className={(this.state.filterStatus === 'rejected') ? 'active' : ''} 
                                    onClick={() => this.handleFilterStatus('rejected')} 
                                    data-value="rejected">
                                        Ditolak ({this.state.summary.rejected ? this.state.summary.rejected : '0'})
                                </li>
                            </ul>
                        </div>
                        <div className="content-space"></div>
                    </div>
                    <Container>
                        {this.state.results.map(data => <DisbursementCard key={data.code} data={data} />)}
                        {!this.state.isFetchingResults && this.state.results.length === 0 &&
                            <div className="search-not-found mt-20">
                                <div className="text-center">
                                    <Image src={imgCampaignNotFound}/>
                                    <div className="txt-subtitle">Masih Kosong...</div>
                                    <div className="txt-body">Cairkan dana penggalangan Anda dahulu dari halaman Campaign.</div>
                                    <Link to="/campaign-saya" className="btn btn-secondary btn-block mt-20">Cek Campaign</Link>
                                </div>
                            </div>
                        }
                        {this.state.isFetchingResults && <Loading/>}
                    </Container>
                </main>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Disbursement));