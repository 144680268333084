import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Input } from '../../../../component/atoms';
import { API, clearDispatch } from '../../../../config/redux/action';
import { Header } from "../../../organisms";
import icClose from '../../../../assets/svg/ic-close.svg';
import icArrowRight from '../../../../assets/svg/ic-arrow-right-gray.svg';
import debounce from "lodash.debounce";

import './style.css';
import { Accordion, Container, Image, Spinner } from 'react-bootstrap';

class FAQSearch extends React.Component {
    state = {
        search: '',
        isFetching: false,
        results: [],
    }
    constructor(props) {
        super(props)
        this.handleSearchRequest = debounce(this.handleSearchRequest, 500)
        this.handleSearch = this.handleSearch.bind(this)
        this.resetSearch = this.resetSearch.bind(this)
        this.handleSearchRequest = this.handleSearchRequest.bind(this)
    }
    handleSearch(e) {
        this.setState({ search: e.target.value })
        this.handleSearchRequest()
    }
    resetSearch() {
        this.setState({ search: '' })
        this.handleSearchRequest()
    }
    async handleSearchRequest() {
        this.setState({ isFetching: true })
        
        if(this.state.search.length >= 2) {
            const config = {
                method: 'get',
                url: '/faq/search?search='+this.state.search,
                headers: {
                    'Content-Type': 'application/json',
                }
            };
            const res = await this.props.API(config, false).catch(err=>err);
            if(res) {
                this.setState({ results: res.data })
            }
        }

        this.setState({ isFetching: false })
    }
    componentDidMount() {
        this.handleSearchRequest()
    }
    render() {
        return (
            <React.Fragment>
                <Header title="FAQs" prevPage='/faq' />
                <main>
                    <div className="faq-header text-center">
                        <div className="txt-title-m fw-6">Ada yang bisa kami bantu?</div>
                        <Input 
                            type="text" 
                            name="search"
                            formGroupClass="search-form txt-body" 
                            placeholder="Cari Topik Pertanyaan..." 
                            handleFormChange={this.handleSearch}
                            value={this.state.search}
                            icon_left="/assets/img/ic-search-gray.svg"
                            icon_right={(this.state.search ? icClose : '')}
                            icon_right_click = {this.resetSearch}
                        />
                    </div>
                    <Container className="faq-search">
                        <div className="txt-body-s mb-3">Hasil pencarian dari Topik</div>
                        {!this.state.isFetching && this.state.results.map((topic, key) => (
                            topic.faqs.map((val, key2) => (
                                <Accordion className="cp-card" key={key + '-' + key2}>
                                    <Accordion.Toggle as="div" eventKey="0" className="d-flex">
                                        <div className="w-100">
                                            <div className="txt-subtitle-s mb-2 fw-6 my-auto">{val.question}</div>
                                        </div>
                                        <div className="d-flex px-3">
                                            <Image className="m-auto" src={icArrowRight} />
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <div className="txt-body-s mt-3 mb-2">{val.answer}</div>
                                    </Accordion.Collapse>
                                </Accordion>
                            ))
                        ))}
                        {!this.state.isFetching && this.state.search.length === 0 && 
                            <div className="txt-body-s text-center mt-4">Masukkan Kata Kunci yang Ingin Dicari...</div>
                        }
                        {!this.state.isFetching && this.state.search.length !== 0 && this.state.results.length === 0 && 
                            <div className="txt-body-s text-center mt-4">Pencarian Tidak Ditemukan</div>
                        }
                        <div className="text-center">
                            {this.state.isFetching && <Spinner role="status" animation="border" />}
                        </div>
                    </Container>
                </main>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FAQSearch));