import React from 'react';
import { withRouter } from "react-router-dom";
import { Header } from "../../../organisms";
import { clearDispatch, API } from '../../../../config/redux/action';
import { connect } from "react-redux";
import Cropper from "cropperjs";
import imageCompression from "browser-image-compression";
import {base64StringtoFile, extractImageFileExtensionFromBase64} from '../../../../config/Files';
import IcClose from '../../../../assets/svg/ic-close.svg';
import IcCamera from '../../../../assets/svg/ic-camera.svg';
import IcImage from '../../../../assets/svg/ic-image.svg';
import IcWarningYellow from '../../../../assets/svg/ic-warning-yellow.svg';
import icVerified from '../../../../assets/svg/ic-verified-campaigner.svg';

import './style.css';
import { Alert, Button, Container, Form, Image, Modal, Spinner } from 'react-bootstrap';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

const imageMaxSize = 5000000 // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {return item.trim()})

function CampaignReportForm ({ state, handleChange, handleFileSelect, removeImage, fileInputRef, sendReport, errorMessage }) {
    const { handleSubmit, register, errors } = useForm()
    return (
        <Form onSubmit={handleSubmit(sendReport)} className="form-hook">
            <div className="txt-title-s fw-6 mb-20">Kenapa penggalangan ini melakukan pelanggaran?</div>
            <Form.Group>
                <Form.Control 
                    className="w-100" 
                    as="select" 
                    name="category"
                    ref={register({ required: "Kategori Pelanggaran wajib diisi" })}
                    onChange={(e) => handleChange(e)} 
                    value={state.category} 
                    custom
                >
                    {state.categories.map(
                        category => (<option key={category.id} value={category.id}>{category.name}</option>)
                    )}
                </Form.Control>
                <ErrorMessage errors={errors} name="category" as={<p className="error-input text-left"></p>} />
                {errorMessage.campaign_report_category_id && <p className="error-input">{errorMessage.campaign_report_category_id}</p>}
            </Form.Group>
            <hr className="my-4" />
            <Form.Group>
                <Form.Label>Nama Lengkap</Form.Label>
                <Form.Control
                    type="text" 
                    name="name"
                    placeholder="Masukkan Nama Lengkap" 
                    ref={register({ required: "Nama Lengkap wajib diisi" })}
                    onChange={(e) => handleChange(e)} 
                    value={state.name}
                />
                <ErrorMessage errors={errors} name="name" as={<p className="error-input text-left"></p>} />
                {errorMessage.name && <p className="error-input">{errorMessage.name}</p>}
            </Form.Group>
            <Form.Group>
                <Form.Label>Nomor Ponsel</Form.Label>
                <Form.Control
                    type="text" 
                    name="phone"
                    placeholder="Masukkan Nomor Ponsel" 
                    ref={register({
                        required: "Nomor Ponsel wajib diisi",
                        pattern: {
                          value: /\d+/,
                          message: "Nomor Ponsel harus diisi dengan angka"
                        }
                    })}
                    onChange={(e) => handleChange(e)} 
                    value={state.phone}
                />
                <ErrorMessage errors={errors} name="phone" as={<p className="error-input text-left"></p>} />
                {errorMessage.phone && <p className="error-input">{errorMessage.phone}</p>}
            </Form.Group>
            <Form.Group>
                <Form.Label>Detail Laporan</Form.Label>
                <Form.Control 
                    className="w-100" 
                    name="detail"
                    as="textarea" 
                    rows={5} 
                    placeholder="Campaign ini melakukan pelanggaran dengan..." 
                    ref={register({ required: "Detail Laporan wajib diisi" })}
                    onChange={(e) => handleChange(e)} 
                    value={state.detail}
                />
                <ErrorMessage errors={errors} name="detail" as={<p className="error-input text-left"></p>} />
                {errorMessage.description && <p className="error-input">{errorMessage.description}</p>}
            </Form.Group>
            <Form.Group>
                {state.fileError && <Alert variant="danger">{state.fileError}</Alert>}

                <div className="txt-body-s mb-2">Lampirkan foto bukti sebagai pendukung untuk menunjang laporan Anda.</div>
                <input type="file" className="d-none" ref={fileInputRef} accept={acceptedFileTypes} onChange={handleFileSelect} />
                {!state.photosBase64.length && 
                    <button className="btn btn-dashed btn-block ct-primary2 fw-5" onClick={() => {
                        fileInputRef.current.value = ""
                        fileInputRef.current.click()
                    }}>
                        <Image className="mr-1" src={IcImage} /> 
                        Lampirkan Bukti
                    </button>
                }
                <div className="campaign-report-images">
                    {state.photosBase64.map(
                        (photo, key) => {
                            return (
                                <div className="campaign-report-img-container" key={"image-item-" + key}>
                                    <Image className="img-item" src={photo} />
                                    <div className="img-action" onClick={(e) => removeImage(key)}>
                                        <Image src={IcClose}/>
                                    </div>
                                </div>
                            )
                        }
                    )}
                    {state.photosBase64.length > 0 &&
                        <div key="image-add" className="campaign-report-btn-add" onClick={() => {
                            fileInputRef.current.value = ""
                            fileInputRef.current.click()
                        }}>
                            <div>
                                <Image src={IcCamera} />
                            </div>
                        </div>
                    }
                </div>

                <div className="txt-body-m mt-3">
                    <div className="content-grid grid-2 info-form mb-10">
                        <div><Image className="loader-img" src={IcWarningYellow}/></div>
                        <div className="text-left ml-3">
                            Dengan melaporkan program ini, Anda dianggap dapat mempertanggung jawabkan aksi ini.
                        </div>
                    </div>
                </div>
            </Form.Group>
            <Button type="submit" className="mt-5" variant="primary" block>
                { !state.isSendingReport && 'Kirim Laporan' }
                { state.isSendingReport && <Spinner animation="border" role="status"/> }
            </Button>
        </Form>
    )
}

class CampaignReport extends React.Component {
    state = {
        auth: null,
        token: null,
        isFetchingCategories: false,
        categories: [],
        category: '',
        name: '',
        phone: '',
        detail: '',
        photos: [],
        photosBase64: [],
        cropView: false,
        imageView: null,
        imageViewExt: null,
        imageDestination: null,
        fileError: null,
        isSendingReport: false,
        isSendingImage: false
    }
    fileInputRef = React.createRef()
    canvasImageRef = React.createRef()
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.sendReport = this.sendReport.bind(this)
    }
    handleChange(e) {
        let change = {}
        change[e.target.name] = e.target.value
        this.setState(change)
    }
    async apiCategoryReport() {
        this.setState({ isFetchingCategories: true })

        const { API } = this.props
        const config = {
            method: 'get',
            url: '/campaign-report/categories',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            }
        };
        const res = await API(config, false).catch(err=>err)
        if (res) {
            this.setState({ 
                categories: res.data, 
                category: res.data[0].id
            })
        }
        this.setState({ isFetchingCategories: false })
    }
    verifyFile = (files) => {
        if (files && files.length > 0){
            const currentFile = files[0]
            const currentFileType = currentFile.type
            const currentFileSize = currentFile.size
            if(currentFileSize > imageMaxSize) {
                alert("This file is not allowed. " + currentFileSize + " bytes is too large")
                return false
            }
            if (!acceptedFileTypesArray.includes(currentFileType)){
                alert("This file is not allowed. Only images are allowed.")
                return false
            }
            return true
        }
    }
    handleFileSelect = event => {
        const files = event.target.files

        if (files && files.length > 0){
            const isVerified = this.verifyFile(files)
            if (isVerified){
                this.setState({cropView: true, originalImage:files[0]});
                const currentFile = files[0]

                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 700,
                    useWebWorker: true,
                    onProgress: Function
                };
              
                imageCompression(currentFile, options).then(output => {
                    var reader = new FileReader();
                    reader.readAsDataURL(output); 
                    reader.addEventListener("load", ()=>{
                        var myResult = reader.result;
                        this.setState({
                            imageView: myResult,
                            imageViewExt: extractImageFileExtensionFromBase64(myResult)
                        });
          
                        const cropper = new Cropper(this.canvasImageRef.current, {
                            zoomable: false,
                            scalable: false,
                            // aspectRatio: 1/1,
                            responsive: true,
                            zoomOnWheel: false,
                            zoomOnTouch: false,
                            dragMode:"none",
                            background:false,
                            toggleDragModeOnDblclick:false,
                            crop: () => {
                                const canvas = cropper.getCroppedCanvas();
                                this.setState({ imageDestination: canvas.toDataURL("image/jpg") });
                            }
                        });
                    });
                });
            }
        }
    }
    handleFile = async () => {
        this.setState({ isSendingImage: true })
        let formData = new FormData();
        formData.append('image', base64StringtoFile(this.state.imageDestination, new Date() + '.jpg'))

        const confStoreImage = {
            method: 'post',
            url: '/image',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            },
            data: formData
        };
        const savePhoto = await this.props.API(confStoreImage, true).catch(err=>err)
        if(savePhoto) {
            this.setState({
                photos: [ ...this.state.photos, savePhoto.data.file_name ],
                photosBase64: [ ...this.state.photosBase64, this.state.imageDestination ],
                cropView: false
            })
        }
        
        this.setState({ isSendingImage: false, cropView: false })
    }
    removeImage = (index) => {
        let photos = this.state.photos;
        let photosBase64 = this.state.photosBase64;
        photos.splice(index, 1)
        photosBase64.splice(index, 1)
        this.setState({ photos, photosBase64 })
    }
    async sendReport(e) {
        this.setState({ fileError: null })
        if(this.state.photos.length) {
            this.setState({ isSendingReport: true })

            const formData = {
                campaign_slug: this.props.match.params.slug,
                campaign_report_category_id: this.state.category,
                name: this.state.name,
                phone: this.state.phone,
                description: this.state.detail,
                evidence_images: this.state.photos,
            }

            const config = {
                method: 'post',
                url: '/campaign-report',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },
                data: formData
            }
            const res = await this.props.API(config, false).catch(err=>err);
            if (res) {
                this.props.history.push('/campaign-report/' + this.props.match.params.slug + '/success');
            } else {
                this.setState({ isSendingReport: false })
            }
        } else {
            this.setState({ fileError: 'Foto bukti harus ada' })
        }
    }

    loadCampaign = async (slug) => {
        this.setState({loadCampaign:false});
        const config = {
            method: 'get',
            url: '/campaign/'+slug,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            this.setState({loadCampaign:true, dataCampaign:res.data});
            localStorage.setItem('detailCampaign', JSON.stringify(res.data));
        }else{
            this.setState({loadCampaign:true});
        }
    }
    componentDidMount() {
        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = auth && auth.access_token ? `Bearer ${auth.access_token}` : null;
        const name = auth && auth.name ? auth.name : ''
        const phone = auth && auth.phone ? auth.phone : ''
        this.setState({ auth, token, name, phone })
        this.loadCampaign(this.props.match.params.slug)
        this.apiCategoryReport()
    }
    render() {
        let sectionStyle = {
            backgroundImage: (this.state.dataCampaign ? `url(${this.state.dataCampaign.cover})` : '')
        };
        return (
            <React.Fragment>
                <Header title="Laporkan Penggalangan" prevPage={"/campaign/" + this.props.match.params.slug} />
                <main className="campaign-report">
                    {this.state.dataCampaign && 
                        <Container className="no-padding"> 
                            <div className="section widget-campaign">
                                <div className="campaign-card-image">
                                    <div className="center-cropped" style={sectionStyle}>
                                    </div>
                                </div>
                                <div>
                                    <div className="txt-body-m fw-6 ct-title">{this.state.dataCampaign.title}</div>
                                    <div className="txt-caption-m campaigner">
                                        {this.state.dataCampaign.campaigner.name}
                                        {this.state.dataCampaign.campaigner_verified &&
                                        <Image src={icVerified}/>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="content-space"></div>
                        </Container>
                    }
                    <Container>
                        <CampaignReportForm 
                            state={this.state}
                            fileInputRef={this.fileInputRef}
                            handleChange={this.handleChange}
                            handleFileSelect={this.handleFileSelect}
                            removeImage={this.removeImage}
                            errorMessage={this.props.errorMessage}
                            sendReport={this.sendReport} />
                        <Modal
                            show={this.state.cropView}
                            onHide={() => this.setState({ cropView: false })}
                            aria-labelledby="contained-modal-title-vcenter"
                            className="campaign-report-modal"
                            animation={false}
                            centered>
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Upload Gambar
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="img-container">
                                    {this.state.imageView ?
                                        <><Image ref={this.canvasImageRef} src={this.state.imageView} alt="Source" /></>
                                        :
                                        <><Spinner animation="border" role="status"/></>
                                    }
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button type="button" className="btn btn-secondary btn-sm mt-3 btn-block"  disabled={(this.state.isSendingImage ? true : false)} onClick={this.handleFile}>
                                    { !this.state.isSendingImage && 'Gunakan gambar ini' }
                                    { this.state.isSendingImage && <Spinner animation="border" role="status"/> }
                                </button>
                            </Modal.Footer>
                        </Modal>
                    </Container>
                </main>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignReport));