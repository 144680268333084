import React from 'react'
import { Header,SearchCardCampaigner, NewsCard, SearchCardCampaignerNews } from "../../organisms";
import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../config/redux/action';
import { Input } from "../../../component/atoms";
import { Link } from "react-router-dom";
import {Container } from "react-bootstrap";
import debounce from "lodash.debounce";
import icCloseDialog from '../../../assets/svg/ic-close-dialog.svg';

import './style.css'
import Loading from '../../pages/News/NewsList/Loading';

class SearchComponent extends React.Component {
    state = {
        token: null,
        search: '',
        isFetchingResults: false,
        loadData: false,
        results: [],
        dataCount: '',
        campaigners: [],
        campaignersCount: '',
        perPage: 5,
        lembagaPerPage: 5
    }
    constructor() {
        super()
        this.handleSearchRequest = debounce(this.handleSearchRequest, 500)
    }
    handleSearch = async (e) => {
        this.setState({ search: e.currentTarget.value, loadData: false, isFetchingResults: true })
        this.handleSearchRequest()
    }
    handleSearchRequest = async () => {
        const { API } = this.props

        const config = {
            method: 'get',
            url: '/news?search='+this.state.search +'&per_page=' + this.state.perPage + '&perPageCampaigner=' + this.state.lembagaPerPage,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            }
        };
        const res = await API(config, false).catch(err=>err);
        if(res) {
            this.setState({results: res.data.data, dataCount: res.dataCount, campaignersCount: res.dataCampaignerCount, campaigners: res.dataCampaigner})
        }
        this.setState({ perPage: this.state.perPage + 5 , isFetchingResults: false, loadData: true })
    }
    componentDidMount() {
        const auth = JSON.parse(localStorage.getItem("userData"));
        const token = auth && auth.access_token ? `Bearer ${auth.access_token}` : null;
        this.setState({ loadData: false, isFetchingResults: true, token })
        this.handleSearchRequest()
    }
    resetKeyword = () => {
        this.setState({search:'', isFetchingResults: true, loadData: false, results: []});
        this.handleSearchRequest()
    }
    loadMore = async () => {
        /** Do Search Here */
        // if(this.state.search.length < 2)
        //     return

        const { API } = this.props

        const config = {
            method: 'get',
            url: '/search-news?search=' + this.state.search + '&perPageCampaigner=' + this.state.lembagaPerPage,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            }
        };
        const res = await API(config, false).catch(err => err);
        if (res) {
            this.setState({ results: res.data.campaigns, campaignsCount: res.data.campaignsCount, campaignersCount: res.data.campaignersCount, campaigners: res.data.campaigners })
        }
        this.setState({ lembagaPerPage: this.state.lembagaPerPage + 5 });
        this.setState({ isFetchingResults: false, loadData: true })
    }
    render() {
        const { results, search, loadData, dataCount, campaigners, campaignersCount } = this.state
        return (
            <div className="search-page">
                <Header 
                    prevPage='/'
                    title={
                        <Input 
                            type="text" 
                            name="search"
                            formGroupClass="search-form txt-body" 
                            placeholder="Cari Berita..." 
                            handleFormChange={this.handleSearch}
                            value={search}
                            icon_left="assets/img/ic-search-gray.svg"
                            icon_right={(search ? icCloseDialog : '')}
                            icon_right_click = {this.resetKeyword}
                            autoFocus={true}
                        />
                    }
                />
                <main>
                    <Container>
                            <>
                            {!loadData ?  
                                <div className="search-results"><Loading/></div>
                                :
                                <>
                                    {results.length > 0 ?
                                        <>
                                            {results.length > 0 &&
                                                <>  
                                                <div className="popular-title txt-subtitle ct-title text-left">Berita</div>
                                                <div className="txt-body text-left">Menampilkan {dataCount} berita dengan kata kunci <b>“{search}”</b></div>
                                                <div className="search-results">
                                                    {results.map((news, i) => 
                                                        <NewsCard key={news.slug} news={news} />
                                                    )}
                                                    {dataCount > 4 &&
                                                        <Link className="btn btn-primary btn-sm btn-show-more" to={'/news?search='+ search}>
                                                            Tampilkan lebih banyak
                                                        </Link>
                                                    }
                                                </div>
                                                </>
                                            }
                                            {campaigners.length > 0 &&
                                                <>
                                                    <div className="popular-title txt-subtitle text-left mt-3">Penggalang</div>
                                                    <div className="txt-body text-left">Menampilkan {campaignersCount} penggalang dengan kata kunci <b>“{search}”</b></div>
                                                    <div className="search-result-campaigner">
                                                        {campaigners.map((campaign, i) => <SearchCardCampaignerNews campaign={campaign} key={campaign.slug + campaign.index} />)}
                                                    </div>
                                                    {/* {campaignersCount > 4 &&
                                                        <button onClick={this.loadMore} className="btn btn-primary btn-sm btn-show-more">
                                                            Tampilkan lebih banyak
                                                        </button>
                                                    } */}
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            <div className="popular-title txt-subtitle ct-title text-left">Berita</div>
                                            <div classname="txt-body">Pencarian dengan kata kunci tersebut tidak ditemukan. Cari lagi dengan kata kunci lainnya.</div>
                                            {campaigners.length > 0 &&
                                                <>
                                                    <div className="popular-title txt-subtitle text-left mt-3">Penggalang</div>
                                                    <div className="search-result-campaigner">
                                                        {campaigners.map((campaign, i) => <SearchCardCampaigner campaign={campaign} key={campaign.slug + campaign.index} />)}
                                                    </div>
                                                    <hr />
                                                </>
                                            }
                                        </>
                                    }
                                </>
                            }
                            </>
                        
                    </Container>
                </main>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchComponent);