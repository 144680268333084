import React from 'react'
import { Button, Spinner } from 'react-bootstrap';

const BtnSubmit =  (props) => {
    return (
        <Button variant="primary" type="submit" block size="lg" onClick={props.handleSubmit} disabled={props.isLoading}>
            { (!props.isLoading) && <span>{props.label}</span> }
            { props.isLoading && <Spinner animation="border" role="status"/> }
        </Button>
    )
}

export default BtnSubmit
