import React, {Fragment} from 'react';
import {Nav, Button, Container, Image } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { withRouter } from "react-router";
import CurrencyFormat from 'react-currency-format';
import Moment from 'react-moment';
// import { Helmet, HelmetProvider  } from "react-helmet-async";
import { HeadProvider, Title, Meta } from 'react-head';

import icArrowBottom from '../../../../assets/svg/ic-arrow-bottom.svg';
import icArrowTop from '../../../../assets/svg/ic-arrow-top.svg';
import icUserVerified from '../../../../assets/svg/ic-user-verified.svg';
import icOptions from '../../../../assets/svg/ic-options.svg';

import { connect } from 'react-redux';
import { clearDispatch, API } from '../../../../config/redux/action';
import ScrollToTop from '../../../../config/Routes/ScrollToTop';

import './style.css';

import LoadingCampaign from './PreviewLoading';
import News from '../CampaignNews';
import LoadingNews from '../CampaignNews/Loading';
import LoadingDonors from '../CampaignDonors/Loading';
import SliderDonors from '../CampaignDonors/SliderDonors';
import ModalDonors from '../CampaignDonors/ModalDonors';
import avatar from "../../../../assets/img/avatar/no-img.png";
import icShareBtn from "../../../../assets/svg/ic-btn-share.svg";
import icShareSosmed from "../../../../assets/svg/ic-share-sosmed.svg";
import icVerified from '../../../../assets/svg/ic-verified-campaigner.svg';
import icFoundation from '../../../../assets/svg/ic-foundation-small.svg';
import icCommunity from '../../../../assets/svg/ic-community-small.svg';
import icReport from '../../../../assets/svg/ic-reports.svg';
import icChecked from '../../../../assets/svg/ic-checked-green.svg';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Header } from '../../../organisms';

import {FbPixel} from '../../../../config/Tracker/FbPixel'
import LazyLoad from 'react-lazyload';

//Load Component
import { Share } from '../../../organisms';
import * as QueryString from "query-string";
import moment from 'moment';

class Preview extends React.Component {
    state = {
        readMoreDesc:false,
        readMoreNews:false,
        dataCampaign: {campaigner:{name:''}},
        dataNews: {},
        dataDonatur: {},
        campaignSlug:'',
        shareModal: false,
        hideBtnFlat:true,
        showOptHeader:false,
        urlCampaign: ''
    }

    loadCampaign = async (slug) => {
        this.setState({loadCampaign:false});
        const config = {
            method: 'get',
            url: '/campaign/'+slug,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            this.setState({loadCampaign:true, dataCampaign:res.data});
            localStorage.setItem('detailCampaign', JSON.stringify(res.data));
        }else{
            this.setState({loadCampaign:true});
        }
    }

    loadNews = async (slug) => {
        this.setState({loadNews:false});
        const config = {
            method: 'get',
            url: '/campaign/'+slug+'/news',
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            this.setState({loadNews:true, dataNews:res.data});
        }else{
            this.setState({loadNews:true});
        }
    }

    loadDonatur = async (slug) => {
        this.setState({loadDonatur:false});
        const config = {
            method: 'get',
            url: '/campaign/'+slug+'/donations',
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const res = await this.props.API(config, false).catch(err=>err);
        if (res) {
            this.setState({loadDonatur:true, dataDonatur:res.data});
        }else{
            this.setState({loadDonatur:true});
        }
    }

    showMoreDesc = () => {
        this.setState({readMoreDesc:true})
    }
    showLessDesc = () => {
        this.setState({readMoreDesc:false})
    }

    showMoreNews = () => {
        this.setState({readMoreNews:true})
    }
    showLessNews = () => {
        this.setState({readMoreNews:false})
    }

    handleScroll = () => {
        /** Handling transparent header event */
        this.setState({
            hideBtnFlat: document.documentElement.scrollTop <= 450
        });
    }
    
    goToReportCampaign = () => {
        const {history} = this.props;
        history.push("/campaign-report/"+this.state.campaignSlug);
    }

    componentDidMount (){
        document.documentElement.scrollTop = 0;
        this.props.clearDispatch();
        window.addEventListener('scroll', this.handleScroll); 
        
        let url = this.props.location.pathname;
        let pathArr = url.split('/');
        let slug = pathArr[pathArr.length - 1];

        this.setState({campaignSlug:slug});
        this.loadCampaign(slug);
        this.loadNews(slug);
        this.loadDonatur(slug);
        
        FbPixel('ViewContent');
        this.setUTM();
    }

    setUTM(){
        const qs = QueryString.parse(this.props.location.search);
        let paramUTM = {};
        let counter = 0;
        Object.keys(qs).forEach(function(field) {
            let val = qs[field];
            let arrSplitUTM = field.split('utm_');
            let param = arrSplitUTM[1];
            if(arrSplitUTM[0] === ''){
                if(param === 'source' || param === 'medium' || param === 'name' || param === 'term' || param === 'content' || param === 'campaign'){
                    paramUTM['channel_'+param] = val;
                    counter++;
                }
            }
            
        });
        if(counter > 0){
            var today = new Date();
            var strTotimeLastDay = today.setDate(today.getDate()+3);
            localStorage.setItem('setupTimeUTM', strTotimeLastDay);
            localStorage.setItem('cacheParamUTM',JSON.stringify(paramUTM));
        }
    }

    componentWillUnmount() {
        /** Remove Scroll Event when Component is Unmounted */
        window.removeEventListener('scroll', this.handleScroll); 
        
    }
    
    goTo = (link) => {
        this.props.history.push(link);
    }
    
    render() {
        const state = this.state;
        state.urlCampaign = window.location;
        const settings = {
            dots: false,
            autoplay: true,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        
        const isCampaignExpired = state.dataCampaign.day_left < 1;
        
        return(
            <Fragment>
                <Header title={state.dataCampaign.title} prevpage="/" cols={3} dropdownPost3={true} position3={icOptions} linkPost3={"/campaign-report/"+state.dataCampaign.slug} />
                {/* "/campaign-report/"+state.dataCampaign.slug */}


                {/* <header className="campaign-header">
                    <div className="nav-col-3 nav">
                        <div className="nav-item">
                            <div className="nav-link cursor-pointer" onClick={()=>{ window.history.back() }}>
                                <i className="ic-header ic-arrow-left"></i>
                            </div>
                        </div>
                        <div className="nav-item">
                            <div className="head-title txt-body-m">{state.dataCampaign.title}</div>
                        </div>
                        <div className="nav-item">
                            <Dropdown className="btn-dropdown">
                                <Dropdown.Toggle>
                                    <Image src={icOptions}/>
                                </Dropdown.Toggle>
                            
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={this.goToReportCampaign}><span className="txt-body-m">Laporkan</span></Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </header> */}
                <main className="no-padding">
                <Container className="no-padding">
                    <ScrollToTop/>
                    {!state.dataCampaign.title && 
                        <LoadingCampaign/>
                    }
                    {state.dataCampaign.title && 
                        <div className="campaign-detail">
                            <HeadProvider>
                                <Title>{state.dataCampaign.title} | Bantu Tetangga</Title>
                                <Meta property="og:title" content={"Bantu Sekarang - "+ state.dataCampaign.title} />
                                <Meta property="og:description" content="Platform terbuka penggalangan dana online untuk kumpulkan tetangga baik dengan semangat gotong-royong membantu tetangga prasejahtera di sekitarnya." />
                                <Meta property="og:url" content={state.urlCampaign} />
                                <Meta property="og:image" content={state.dataCampaign.cover} />
                                <Meta property="og:image:alt" content="Og Image Alt" />
                                <Meta property="og:image:width" content="800" />
                                <Meta property="og:image:height" content="600" />
                            </HeadProvider>
                            <div className="img-cover">
                                <LazyLoad>
                                    <Image src={state.dataCampaign.cover} className="img"/>
                                </LazyLoad>
                            </div>
                            <div className="section">
                                <h1 className="txt-title-m fw-6">{state.dataCampaign.title}</h1>
                                <div className="content-grid grid-2">
                                    <div>
                                        <div className="txt-caption">Dana Terkumpul</div>
                                        <CurrencyFormat 
                                            value={state.dataCampaign.donation_collected} 
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix={'Rp '} 
                                            className="txt-subtitle fw-6 txt-terkumpul"
                                            displayType={'text'}
                                        />
                                    </div>
                                    <div>
                                        <div className="txt-caption">Target</div>
                                        <CurrencyFormat 
                                            value={state.dataCampaign.target} 
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix={'Rp '} 
                                            className="target txt-body-m fw-5"
                                            displayType={'text'}
                                        />
                                    </div>
                                </div>
                                <div className="progress progress-big mt-2 mb-2">
                                    <span className="progress-bar" style={{"width": state.dataCampaign.percentage+'%',  backgroundColor: '#D68605'}}></span>
                                </div>
                                <div className="content-grid grid-2">
                                    <div>
                                        <div className="txt-caption">Sisa Waktu</div>
                                        <div className="txt-body fw-6">
                                            {state.dataCampaign.day_left} Hari
                                        </div>
                                    </div>
                                    <div>
                                        <div className="txt-caption">Capaian</div>
                                        <div className="txt-body fw-6">{state.dataCampaign.percentage}%</div>
                                    </div>
                                </div>
                                {!state.dataCampaign.is_active &&
                                    <div className="info-form2 mt-20">Campaign ini mendapat laporan dan sekarang sedang dalam proses pemeriksaan.</div>
                                }
                                {isCampaignExpired && 
                                    <div className="info-campaign-expired mt-20">
                                        <Image src={icChecked} className="mb-1"/>
                                        Program ini sudah berakhir. Terima kasih #TetanggaBaik. Temukan tetangga lainnya yang membutuhkan uluran tanganmu.</div>
                                }
                                
                                <div className="action-campaign-donate">
                                    {/* <button onClick={() => this.setState({ shareModal: true })} className="btn btn-secondary">
                                        <Image src={icShareBtn}/> Bagikan
                                    </button> */}
                                    
                                    {!isCampaignExpired ?
                                        <Link to="#" onClick={(e) => this.goTo("/donation/"+state.dataCampaign.slug+"/step1")} className="btn btn-donate">Donasi Sekarang</Link>                               
                                        :
                                        <span>
                                            <Link to={"/campaign?utm_source=campaign_off"} className="btn btn-secondary btn-block">Cari Penggalangan Lain</Link>
                                            <Button className="btn btn-cta-disabled" disabled>Donasi Sekarang</Button>
                                        </span>
                                    }
                                </div>
                            </div>
                            <div className="content-space"></div>

                            <div className="section">
                                <Link to={
                                    "/campaigner/" + 
                                    this.state.dataCampaign.campaigner.slug + 
                                    '?prevPage=campaign/' + this.state.campaignSlug
                                } className="content-grid wrap-campaigner">
                                    <div>
                                        <div className="wrapper-img">
                                            <LazyLoad className="profile-crop">
                                                <Image src={this.state.dataCampaign.campaigner.logo ? this.state.dataCampaign.campaigner.logo : avatar}/>
                                            </LazyLoad>
                                            {/* {this.state.dataCampaign.campaigner.approved_at && 
                                                <div className="ic-legal">
                                                    <Image src={icUserVerified}/>
                                                </div>
                                            } */}
                                        </div>
                                    </div>
                                    <div className="text-left">
                                        <div className="content-flex">
                                            <div className="txt-body-m fw-6">
                                                {state.dataCampaign.campaigner.name}
                                            </div>
                                            {state.dataCampaign.campaigner.is_verified &&
                                                <div>
                                                    <LazyLoad>
                                                        <Image src={icVerified} className="mb-1"/>
                                                    </LazyLoad>
                                                </div>
                                            }
                                            {state.dataCampaign.campaigner.is_foundation &&
                                                <div><Image src={icFoundation} className="ml-1 icon-lembaga"/></div>
                                            }
                                            {state.dataCampaign.campaigner.is_community &&
                                                <div><Image src={icCommunity} className="ml-1 icon-lembaga"/></div>
                                            }
                                        </div>
                                        {state.dataCampaign.campaigner_verified ? 
                                            <div className="txt-caption-m">
                                                Akun terverifikasi
                                            </div> :
                                            <div className="txt-caption-m">
                                                Belum terverifikasi
                                            </div>
                                        }
                                    </div>
                                </Link>
                            </div>
                            <div className="content-space"></div>

                            <div className="section description">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h2 className="txt-subtitle fw-6">Deskripsi Program</h2>
                                    <small className="text-secondary">{moment(state.dataCampaign?.publish_at).format("DD MMM YYYY")}</small>
                                </div>

                                <div 
                                    className={"txt-body-m "+(!state.readMoreDesc ? 'content-less' : '')}
                                    dangerouslySetInnerHTML={{
                                        __html: state.dataCampaign.body
                                      }}
                                    >
                                </div>

                                
                                <div className="text-center">
                                    {!state.readMoreDesc &&
                                        <button className="read-more" onClick={this.showMoreDesc}>
                                            Lihat Selengkapnya <Image src={icArrowBottom} />
                                        </button>
                                    }

                                    {state.readMoreDesc && 
                                        <span>
                                            <div className="box-disclaimer">
                                                <span className="fw-6">Disclaimer</span>: Informasi dan opini yang tertulis di halaman penggalangan ini adalah milik dan tanggung jawab penggalang dana.
                                            </div>
                                            <hr/>
                                            <div className="section-report">
                                                <Image src={icReport} /> Masalah dengan penggalangan dana ini? <Link to={"/campaign-report/" + this.state.campaignSlug}> Laporkan </Link>
                                            </div>
                                            <button className="read-more" onClick={this.showLessDesc}>
                                                Lihat lebih sedikit <Image src={icArrowTop} />
                                            </button>
                                        </span>
                                    }
                                </div>
                            </div>
                            
                            <div className="content-space"></div>
                            <div className="section">
                                <h2 className="txt-subtitle fw-6 mb-2">Berita Terbaru</h2>
                                <div 
                                    className={!state.readMoreNews && this.state.dataNews?.length > 0 ? 'content-less' : ''}
                                >
                                    { this.state.loadNews &&
                                        <Fragment>
                                            {this.state.dataNews?.length > 0 ? (
                                                this.state.dataNews.map(news => {
                                                    return (  
                                                        <News 
                                                            key={news.slug}
                                                            data={news}
                                                            handleDeleteNews={this.handleDeleteNews}
                                                            isProcessing={(this.state.isProcessing === news.slug) ? true : false}
                                                            isCampaigner={false}
                                                        />
                                                    )
                                                    
                                                })
                                            ):(
                                                <div className="text-center text-secondary mt-3">
                                                    Tidak ada kabar terbaru
                                                </div>
                                            )}

                                        </Fragment>
                                    }

                                    { !this.state.loadNews &&
                                        <LoadingNews/>
                                    }
                                </div>

                                {this.state.dataNews?.length > 0 && (
                                    <div className="text-center">
                                        {!state.readMoreNews && 
                                            <button className="read-more" onClick={this.showMoreNews}>
                                                Lihat Selengkapnya <Image src={icArrowBottom} />
                                            </button>
                                        }
                                        {state.readMoreNews && 
                                            <button className="read-more" onClick={this.showLessNews}>
                                                Lihat lebih sedikit <Image src={icArrowTop} />
                                            </button>
                                        }
                                    </div>
                                )}
                                
                            </div>
                            

                            <div className="content-space"></div>
                            <div className="section donors">
                                <div className="content-grid grid-2 mb-3">
                                    <h2 className="txt-subtitle fw-6">Riwayat Donasi ({this.state.dataDonatur.total})</h2>
                                    <ModalDonors campaignSlug={state.campaignSlug}/>
                                </div>
                                
                                <div>
                                    { this.state.loadDonatur &&
                                            <Slider autoplay={true} arrows={false} variableWidth={true}  slidesToScroll={1} slidesToShow={1} dots={false}>
                                                {this.state.dataDonatur.data.map(donor => {
                                                    return (  
                                                        <SliderDonors key={donor.time} data={donor} />
                                                    )
                                                    
                                                })}
                                            </Slider>
                                    }
                                    
                                    { !this.state.loadDonatur &&
                                        <Slider {...settings}>
                                            <LoadingDonors/>
                                            <LoadingDonors/>
                                            <LoadingDonors/>
                                            <LoadingDonors/>
                                        </Slider>
                                    }
                                </div>
                            </div>
                            <div className="content-space"></div>
                            {!state.hideBtnFlat && 
                                <nav>
                                    <Nav className="navbar">
                                        <p className="txt-orang-donasi">
                                        <CurrencyFormat 
                                            value={state.dataCampaign.total_donatur} 
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix={''}
                                            displayType={'text'}
                                        /> Orang Berdonasi</p>
                                        <div className="content-grid float-btn-donate">
                                            <button onClick={() => this.setState({ shareModal: true })} className="btn btn-share-sosmed">
                                                <Image src={icShareSosmed}/>
                                            </button>
                                            {!isCampaignExpired ?
                                                <Link to="#" onClick={(e) => this.goTo("/donation/"+state.dataCampaign.slug+"/step1")} className="btn btn-donate">Donasi Sekarang</Link>                               
                                                :
                                                <Button className="btn btn-cta-disabled" disabled>Donasi Sekarang</Button>
                                            }
                                        </div>
                                    </Nav>
                                </nav>
                            }
                        </div>
                    }
                </Container>
                <Share 
                    show={this.state.shareModal} 
                    onHide={() => this.setState({ shareModal: false })}
                    url={this.props.location.pathname}
                    slug={this.state.dataCampaign.slug}
                    quote={this.state.dataCampaign.title}
                    body={this.state.dataCampaign.body} />
            </main>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
    clearDispatch: () => dispatch(clearDispatch()),
    API: (path, data) => dispatch(API(path, data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Preview));
