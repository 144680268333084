import React from 'react';
import { useForm } from "react-hook-form";
import {Link} from 'react-router-dom';
import { Container, Image, Spinner } from 'react-bootstrap';
import { ErrorMessage } from '@hookform/error-message';
const SetPassword = props => {
    const { handleSubmit, register, errors } = useForm();
    return (
        <>
            <header className="no-bs">
                <div className="nav">
                    <div className="nav-item">
                        <div className="nav-link cursor-pointer" onClick={props.hideContentPassword}>
                            <i className="ic-header ic-arrow-left"></i>
                        </div>
                    </div>
                    <div className="nav-item">
                        <div className="head-title txt-body-m"></div>
                    </div>
                </div>
            </header>
            <main>
                <Container className="container-login">
                    <div className="text-center">
                        <div className="txt-title-m fw-6 mt-4 mb-2">{props.title}</div>
                        <div className="txt-body-m text-center">Kata sandi minimal 8 karakter atau lebih</div>
                    </div>
                    <form className="form-hook mt-4 mb-2" onSubmit={handleSubmit(props.handleSubmit)}>
                        <div className="form-group">
                            <label className="form-label">Kata Sandi</label>
                            <div className="input-group">
                                <input
                                type={(props.isPasswordShown) ? "text" : "password"}
                                name="password"
                                className="form-control"
                                ref={register({ required: "Kata Sandi wajib diisi" })}
                                onChange={props.handleChange}
                                />
                                <div className="cursor-pointer input-group-prepend" onClick={props.togglePasswordVisibility}>
                                    <span className="input-group-text">
                                        <Image src={(props.isPasswordShown) ? "assets/img/eye-off-line.svg" : "assets/img/eye-open.svg"} className=""/>    
                                    </span>
                                </div>
                            </div>
                            
                            <ErrorMessage errors={errors} name="password" as={<p className="error-input text-left"></p>} />
                            {props.errorMessage.password && 
                            <p className="error-input">{props.errorMessage.password}</p>}
                        </div>
                        
                        <div className="form-group">
                            <label className="form-label">Ulangi Kata Sandi</label>
                            <div className="input-group">
                                <input
                                type={(props.isPasswordConfirmShown) ? "text" : "password"}
                                name="password_confirmation"
                                className="form-control"
                                ref={register({
                                    validate: value =>
                                        value === props.formPost.password || "Kata Sandi tidak sesuai"
                                })}
                                onChange={props.handleChange}
                                />
                                <div className="cursor-pointer input-group-prepend" onClick={props.togglePasswordConfirmVisibility}>
                                    <span className="input-group-text">
                                        <Image src={(props.isPasswordConfirmShown) ? "assets/img/eye-off-line.svg" : "assets/img/eye-open.svg"} className=""/>    
                                    </span>
                                </div>
                            </div>
                            
                            <ErrorMessage errors={errors} name="password_confirmation" as={<p className="error-input text-left"></p>} />
                            {props.errorMessage.password_confirmation && 
                            <p className="error-input">{props.errorMessage.password_confirmation}</p>}
                        </div>

                        <button className="btn btn-primary btn-block" type="submit" disabled={props.isLoading}>
                            { (!props.isLoading) && <span>{props.btnName}</span> }
                            { props.isLoading && <Spinner animation="border" role="status"/> }
                        </button>
                    </form>
                    
                    <div className="text-center txt-body-m fw-6">
                        Dengan mendaftar, Anda dianggap menyetujui <Link to="/syarat-ketentuan" className="fw-6">Syarat & Ketentuan</Link> serta <Link to="/kebijakan-privasi" className="fw-6">Kebijakan Privasi</Link>
                    </div>
                </Container>
            </main>
        </>
    );
}


export default SetPassword;