export default {
    amount: '',
    donor_name: localStorage.getItem('nama-lengkap-donasi'),
    is_anonim: false,
    contact: localStorage.getItem('contact-donasi'),
    support: '',
    payment_type: localStorage.getItem('payment-type-donasi'),
    typeof_payment_type: localStorage.getItem('typeof_payment_type-donasi'),
    icon_payment_type: localStorage.getItem('payment-icon-donasi'),
    title_payment_type: localStorage.getItem('payment-title-donasi'),
    copyAmount: '',
    copyAddress: '',
    resultDonation: {snapToken:false},
    relation: '',
    receiver_name: '',
    url_image: '',
    name_image: '',
    area_id: '',
    address: '',
    reason: '',
    zip_code: '',
    provinsi: '',
    loadProvinsi:false,
    dataProvinsi:false,
    kota: '',
    loadKota:true,
    dataKota:false,
    kecamatan: '',
    loadKecamatan:true,
    dataKecamatan:false,

    loadDateRange:true,
    dataDateRange:false,

    loadGeneralTime:true,
    dataGeneralTime:false,
    
    dataPaymentTypes: {},
    loadPaymentTypes:false,

    //Form Wizard
    stepdon1:false,
    stepdon2:false,
    stepdon3:false,
    errorMessages:{},
};